import {useQuery} from "react-query";
import {useState} from "react";
import {getRolePermissions, getUserRoles} from "../api";
import {UserRoleContext} from "../../context"

const UserRoleProvider = ({children}) => {
    const [userRoles, setUserRoles] = useState([]);
    const [rolePermissions, setRolePermissions] = useState([]);
    const [showRoleForm, setShowRoleForm] = useState(false);
    const [showRoleDetails, setShowRoleDetails] = useState(false);
    const [activeRole, setActiveRole] = useState({});
    const [showPermissionForm, setShowPermissionForm] = useState(false);

    const {isLoading} = useQuery('user-roles', getUserRoles, {
        onSuccess: ({data: roles}) => {
            setUserRoles(roles);
        },
    })

    const {isLoading: isLoadingPermissions} = useQuery('role-permissions', getRolePermissions, {
        onSuccess: ({data: permissions}) => {
            setRolePermissions(permissions);
        }
    })
    return (
        <UserRoleContext.Provider value={{
            isLoading, userRoles, showRoleDetails,
            isLoadingPermissions, activeRole, setActiveRole,
            rolePermissions, showRoleForm, setShowRoleForm,
            setShowRoleDetails, showPermissionForm,
            setShowPermissionForm
        }}>
            {children}
        </UserRoleContext.Provider>
    )
}
export default UserRoleProvider;