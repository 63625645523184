import {Link, useOutletContext} from "react-router-dom";
import CreateProjectForm from "./form/create-project-form";
import Button from "../../../components/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDown, faPlus} from "@fortawesome/free-solid-svg-icons";
import Workspace from "../../../components/workspace";
import ProjectItem from "../../../components/project-item";
import React, {Fragment} from "react";
import CreateWorkspaceForm from "./form/create-workspace";
import SearchBox from "../../../components/search-box";
import {Menu} from "@headlessui/react";

const Projects = () => {
    const {
        showForm: showProjectForm,
        setShowForm: setShowProjectForm,
        projectList,
        setShowWorkspaceForm,
        showWorkspaceForm,
        workspaces = []
    } = useOutletContext()


    const handleSearchResult = () => {
        // ...
    }

    const handleMenuClick = (menuTitle) => {
        if (menuTitle === "New Project") setShowProjectForm(true)
        else setShowWorkspaceForm(true)
    }
    return (
        <Fragment>
            <CreateProjectForm
                showForm={showProjectForm}
                setShowForm={setShowProjectForm}
            />

            <CreateWorkspaceForm
                showForm={showWorkspaceForm}
                setShowForm={setShowWorkspaceForm}
            />
            <section className="min-h-full bg-white border rounded-lg s">
                {/* show this when there is not a project or workspace */}
                <div className="flex hidden space-x-5">
                    {["Project", "Workspace"].map((title) => (
                        <div
                            key={title}
                            className="flex-col px-10 py-5 space-y-5 text-center border-2 rounded-lg border-primary center"
                        >
                            <div className="space-y-1">
                                <h5 className="text-sm font-semibold">
                                    No {title} Found.
                                </h5>
                                <p className="text-sm tracking-wide text-dark2">
                                    Getting started by Create new <br/> {title}
                                    .
                                </p>
                            </div>
                            <div>
                                <Button
                                    outline={title === "Workspace"}
                                    className="space-x-2"
                                >
                                    <FontAwesomeIcon icon={faPlus}/>
                                    <span>Create {title}</span>
                                </Button>
                            </div>
                        </div>
                    ))}
                </div>

                {/* workspaces */}
                <div className="p-5 space-y-3">
                    <div className={"flex space-x-2 justify-end items-center"}>
                        <SearchBox
                            searchContext={{
                                data: [],
                                searchFun: handleSearchResult,
                                searchProperty: "name"
                            }}

                            inputClass={'text-sm'}
                            wrapperClass={'h-9'}
                        />
                        {/*<Button onClick={() => setShowProjectForm(true)}>Create</Button>*/}
                        <Menu as="div" className="relative">
                            <Menu.Button
                                className={
                                    "bg-primary flex items-center hover:opacity-90 flex space-x-2 text-white text-sm px-4 py-2 rounded"
                                }
                            >
                                <span>Create </span>
                                <FontAwesomeIcon icon={faAngleDown}/>
                            </Menu.Button>
                            <Menu.Items
                                as="ul"
                                className="absolute z-50 w-fit overflow-hidden border divide-y-[1px] right-0 mt-1 top-full bg-white/50 backdrop-blur-xl"
                            >
                                {["New Project", "New Workspace"].map((menu) => (
                                    /* Use the `active` state to conditionally style the active item. */
                                    <Menu.Item key={menu} as={"li"}>
                                        {({active}) => (
                                            <p
                                                onClick={() => handleMenuClick(menu)}
                                                className={`${
                                                    active ? "bg-black/5 " : " "
                                                } p-2 text-sm whitespace-nowrap text-black cursor-pointer`}
                                            >
                                                {menu}
                                            </p>
                                        )}
                                    </Menu.Item>
                                ))}
                            </Menu.Items>
                        </Menu>

                    </div>
                    <div className={'items-between'}>
                        <h2 className="text-lg font-medium text-zinc-600">
                            Workspace
                        </h2>
                        <Link to={'workspaces'} className={'text-sm hover:underline text-accent'}>Show All</Link>
                    </div>

                    <div className="grid grid-cols-4 gap-5">
                        {workspaces.slice(0, 4).map((workspace) => (
                            <Workspace key={workspace?._id} workspace={workspace}/>
                        ))}
                    </div>
                </div>

                {/* projects */}
                <div>
                    {/* table header */}
                    <div
                        className="grid grid-cols-6 px-5 py-2.5 mt-5 text-sm font-semibold uppercase text-black/60 bg-secondary/10">
                        <div className="col-span-3 ">Projects</div>
                        <div className="flex justify-center">PIU</div>
                        <div className="flex justify-center">Created On</div>
                        <div className="flex justify-center"></div>
                    </div>
                    {/* table body */}
                    <div className="divide-y-[1px]">
                        {projectList.map((project) => (
                            <ProjectItem project={project} key={project?._id}/>
                        ))}
                    </div>
                </div>
            </section>
        </Fragment>
    )
}

export default Projects;
