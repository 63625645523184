import { useMutation } from "react-query";
import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { RadioGroup } from "@headlessui/react";
import * as Yup from "yup";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
    createProjectEvent,
    updateProjectEvent,
} from "../../../../../../provider/api";
import ParticipantComponent from "../../../../../../components/ParticipantComponent";
import { getValueFromLocalStorage } from "../../../../../../hooks/useLocalStorageState";
import EventChannelCard from "./meeting/event-channel-card";
import useGlobalContext from "../../../../../../hooks/useGlobalContext";
import ActionButtons from "./meeting/steps/_partials/action-buttons";
import dayjs from "dayjs";
import UnstableInput from "../../../../../../components/unstable/input";
import clearForm from "./meeting/steps/_partials/clear-form";
import { toast, ToastContainer } from "react-toastify";

const Event = () => {
    const initialEventInfo = {
        title: "",
        eventDate: "",
        participants: [],
        channel: { name: "Email", value: "" },
    };

    const meetingBasicInfo = getValueFromLocalStorage("activeProjectEvent", {});
    const isEditingEvent = getValueFromLocalStorage("isEditingProjectEvent", 0);

    const navigate = useNavigate();
    const [formError, setFormError] = useState("");
    const { projectId } = useGlobalContext();
    const [eventInfo, setEventInfo] = useState(initialEventInfo);
    const [comChannel, setComChannel] = useState(
        meetingBasicInfo?.channel?.name ?? "Email"
    );
    const labelClasses = "text-dark  font-medium opacity-100";
    const channelList = [
        {
            name: "Email",
            icon: faEnvelope,
            description: "Communicate via email message",
        },

        {
            name: "Phone",
            icon: faPhone,
            description: "Communicate via Phone Call",
        },

        {
            name: "Whatsapp",
            icon: faWhatsapp,
            description: "Communicate via whatsapp message",
        },
    ];

    const {
        handleSubmit,
        register,
        formState: { errors, isSubmitted },
    } = useForm({
        resolver: yupResolver(
            Yup.object({
                title: Yup.string().required(),
                eventDate: Yup.string().required(),
            })
        ),
    });

    const [participantList, setParticipantList] = useState(
        meetingBasicInfo?.participants?.map(
            (participant) => participant?.stakeholder
        ) ?? []
    );

    const submitForm = (formData) => {
        if (participantList.length) {
            const action = isEditingEvent
                ? buildPayloadAndUpdateEventDetails
                : buildPayloadAndCreateNewEvent;
            return action(formData);
        }
    };

    const clearFormAndNavigateToEventList = async () => {
        await clearForm();
        navigate("/engagement/activities/events/upcoming");
    };

    const { mutate: createEvent, isLoading: isCreatingEvent } = useMutation(
        createProjectEvent,
        {
            onSuccess: () => clearFormAndNavigateToEventList(),
            onError: ({ message }) => toast.error(message),
        }
    );

    const { mutate: updateEvent, isLoading: isUpdatingEvent } = useMutation(
        updateProjectEvent,
        {
            onSuccess: () => clearFormAndNavigateToEventList(),
            onError: ({ message }) => toast.error(message),
        }
    );

    const buildPayloadAndCreateNewEvent = ({ title, eventDate }) => {
        const payload = {
            status: 0,
            isMeeting: 0,
            ...meetingBasicInfo,
            title,
            channel: { name: comChannel },
            project: projectId,
            channelType: "Online",
            description: title,
            participants: getParticipants(),
            eventDate: new Date(eventDate).setHours(23, 59, 59),
        };
        createEvent(payload);
    };

    const buildPayloadAndUpdateEventDetails = ({ title, eventDate }) => {
        const payload = {
            ...meetingBasicInfo,
            title,
            channel: { name: comChannel },
            participants: getParticipants(),
            eventDate: new Date(eventDate).setHours(23, 59, 59),
        };
        updateEvent(payload);
    };

    const getParticipants = () => {
        return participantList.map((person) => {
            return { stakeholder: person?._id, notify: 0 };
        });
    };

    const fields = [
        {
            name: "title",
            hasError: !!errors?.title?.message,
            defaultValue: meetingBasicInfo.title,
            placeholder: "Eg.. Send monthly report",
            register,
        },
        {
            name: "eventDate",
            type: "date",
            hasError: !!errors?.eventDate?.message,
            defaultValue: meetingBasicInfo?.eventDate
                ? dayjs(meetingBasicInfo?.eventDate).format("YYYY-MM-DD")
                : "",
            register,
        },
    ];

    return (
        <Fragment>
            <ToastContainer />
            <div className={"space-y-10 py-0"}>
                <div className={" py-2 top-0 backdrop-blur-lg bg-white/20"}>
                    <h2 className={"text-lg font-semibold"}>
                        {isEditingEvent
                            ? "Edit Event Details"
                            : "Create New Event"}
                    </h2>
                </div>
                <form
                    onSubmit={handleSubmit(submitForm)}
                    className={"pr-40 space-y-7 "}
                >
                    <div className="space-y-5">
                        {fields.map((props) => (
                            <UnstableInput props={props} />
                        ))}
                    </div>

                    {/* Channel */}
                    <div className={"space-y-2 py-4"}>
                        <p className={labelClasses}>
                            Select communication channel.
                        </p>
                        <RadioGroup
                            className={"space-y-2"}
                            value={comChannel}
                            onChange={setComChannel}
                        >
                            {channelList.map((channelContext) => (
                                <RadioGroup.Option value={channelContext.name}>
                                    {({ checked }) => (
                                        <EventChannelCard
                                            isActive={checked}
                                            key={channelContext.name}
                                            context={channelContext}
                                        />
                                    )}
                                </RadioGroup.Option>
                            ))}
                        </RadioGroup>
                    </div>

                    <div>
                        <div>
                            <h6 className={labelClasses}>
                                Select participants bellow
                            </h6>
                            {isSubmitted && !participantList.length ? (
                                <p className={"text-red-500 text-sm"}>
                                    Please select participant at least 1
                                </p>
                            ) : null}
                        </div>
                        <ParticipantComponent
                            currentList={participantList}
                            setSelectedList={setParticipantList}
                        />
                    </div>
                    <span className="text-red-600">{formError}</span>

                    <ActionButtons
                        isLoading={isCreatingEvent || isUpdatingEvent}
                        text={isEditingEvent ? "Save" : "Finish"}
                        onCancel={clearFormAndNavigateToEventList}
                    />
                </form>
            </div>
        </Fragment>
    );
};

export default Event;
