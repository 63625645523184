import {useState} from "react";
import {useMutation} from "react-query";
import {useNavigate, useOutletContext} from "react-router-dom";

import ActionButtons from "../_partials/action-buttons";
import {CreateMarkup} from "../../../../../../../../../utils";
import {
    getValueFromLocalStorage,
    saveMultiValueToLocalStorage,
    saveValueToLocalStorage,
} from "hooks/useLocalStorageState";
import {updateProjectEvent} from "provider/api";
import Checkbox from "../../../../../../../../../components/form-control/checkbox";
import {localStorageKeys} from "../../../../../../../../../constants";
// import { set } from "animejs";

const SetReminder = () => {
    const navigate = useNavigate();
    const [setCurrStep, toast] = useOutletContext();
    const { meetingForm: meetingLSKeys } = localStorageKeys;

    const authUser = getValueFromLocalStorage("authUser", {});
    const meetingTime = getValueFromLocalStorage(
        meetingLSKeys.MEETING_TIME,
        {}
    );
    const meetingChannel = getValueFromLocalStorage(
        meetingLSKeys.MEETING_CHANNEL,
        {}
    );
    const meetingRemainder = getValueFromLocalStorage(
        meetingLSKeys.REMAINDER,
        []
    );
    const meetingBasicInfo = getValueFromLocalStorage(
        meetingLSKeys.BASIC_DETAILS,
        {}
    );

    const [selectedReminders, setSelectedReminders] =
        useState(meetingRemainder);
    const isEditing = getValueFromLocalStorage(
        meetingLSKeys.IS_UPDATING_DETAILS,
        false
    );

    const reminderIntervals = [
        { number: 1, name: "1 day before" },
        { number: 2, name: "2 days before" },
        { number: 3, name: "3 days before" },
    ];

    const goBack = () => {
        navigate("/event-registration/meeting/add-attachment");

        setCurrStep(5);
        saveValueToLocalStorage("meetingFormCurrentStep", 5);
    };

    const goNext = (e) => {
        e.preventDefault();

        saveValueToLocalStorage("meetingRemainder", selectedReminders);

        setCurrStep(7);
        saveValueToLocalStorage("meetingFormCurrentStep", 7);
        navigate("/event-registration/meeting/set-meeting-budget");
    };

    const saveDetailsAndAdvanceToNextStep = async ({ data }) => {
        await saveMultiValueToLocalStorage({
            [meetingLSKeys.BASIC_DETAILS]: data,
            [meetingLSKeys.CURRENT_STEP]: 7,
            [meetingLSKeys.REMAINDER]: selectedReminders,
        });
        toast.success("Data has been saved successfully");
        navigate("/event-registration/meeting/set-meeting-budget");
    };

    const { mutate: updateEvent, isLoading: isUpdatingEvent } = useMutation(
        updateProjectEvent,
        {
            onSuccess: saveDetailsAndAdvanceToNextStep,
            onError: ({ message }) => toast.error(message),
        }
    );

    const handleSaveChanges = () => {
        const payload = {
            ...meetingBasicInfo,
            reminderInterval: selectedReminders,
        };
        updateEvent(payload);
    };

    const manageReminders = (reminder) => {
        const present = selectedReminders.includes(reminder);

        if (present) {
            const remainingRemainders = selectedReminders.filter(
                (r) => r !== reminder
            );

            setSelectedReminders(remainingRemainders);
        } else {
            setSelectedReminders([...selectedReminders, reminder]);
        }
    };

    return (
        <>
            <div
                className={
                    "bg-white p-8 space-y-7 border-[1.5px] border-[#EDEDED] rounded-[10px] w-[95%]"
                }
            >
                <div className={"space-y-2"}>
                    <p className={"text-dark text-[1.35rem] font-medium"}>
                        {meetingBasicInfo?.title}
                    </p>
                </div>

                <div
                    className={
                        "grid grid-cols-4 border-b-[1px] border-[#ECECEC] pb-4"
                    }
                >
                    <div className={"space-y-1"}>
                        <p className={"text-xs text-dark font-semibold"}>
                            TYPE OF MEETING
                        </p>
                        <p className={"text-sm font-light text-[#999999]"}>
                            {meetingBasicInfo?.meetingType}
                        </p>
                    </div>
                    <div className={"space-y-1"}>
                        <p className={"text-xs text-dark font-semibold"}>
                            STARTS ON
                        </p>
                        <p className={" text-sm font-light text-[#999999]"}>
                            {new Date(meetingTime.eventDate).toDateString()}
                        </p>
                    </div>
                    <div className={"space-y-1"}>
                        <p className={"text-xs text-dark font-semibold"}>
                            CHANNEL
                        </p>
                        <p className={"text-sm font-light text-[#999999]"}>
                            {meetingChannel?.channelType}
                        </p>
                    </div>
                    <div className={"space-y-1"}>
                        <p className={"text-xs text-dark font-semibold"}>
                            LOCATION
                        </p>
                        <p className={"text-sm font-light text-[#999999]"}>
                            {meetingChannel?.channelType === "Online"
                                ? meetingChannel?.channel?.name
                                : meetingChannel?.locationName}
                        </p>
                    </div>
                </div>

                <div className={"space-y-2"}>
                    <p className={"text-secondary text-[17px]"}>Description</p>
                    <p
                        dangerouslySetInnerHTML={CreateMarkup(
                            typeof meetingBasicInfo?.description === "string"
                                ? meetingBasicInfo.description
                                : JSON.stringify(meetingBasicInfo?.description)
                        )}
                        className={
                            "text-sm leading-relaxed font-light text-justify text-[#838EAB]"
                        }
                    ></p>
                </div>

                <div
                    className={
                        "flex items-center pt-5 space-x-7 font-light text-[#838EAB]"
                    }
                >
                    {reminderIntervals.map((reminder) => (
                        <Checkbox
                            className={"h-4 w-4"}
                            labelClassName={"text-sm"}
                            defaultChecked={selectedReminders.includes(
                                reminder.number
                            )}
                            label={reminder.name}
                            onChange={() => manageReminders(reminder.number)}
                        />
                    ))}
                </div>
            </div>

            <form onSubmit={goNext} className={"mr-24 "}>
                <ActionButtons
                    text={"Next"}
                    onCancel={goBack}
                    isLoading={isUpdatingEvent}
                    isEditing={isEditing}
                    onSave={handleSaveChanges}
                />
            </form>
        </>
    );
};
export default SetReminder;
