import { Fragment } from "react";
import { CalendarIcon } from "@heroicons/react/solid";
import { UserGroup } from "components/user-group";
import { useApproveBudget } from "./budget-approve";
import dayjs from "dayjs";
import { CreateMarkup } from "utils";
import { ArrowRight } from "../../../svg";

const EventBudgetCard = ({ event }, ...props) => {
	const {
		setShowRejectForm,
		setActiveEvent,
		setIsRejected,
		setShowEventDetails,
	} = useApproveBudget();

	const onReject = () => {
		setActiveEvent(event);
		setIsRejected(true);
		setShowRejectForm(true);
	};

	const formatDate = (date) => {
		const formattedDate = dayjs(date).format("DD MMMM YYYY").split(" ");
		return `${formattedDate[0]} ${formattedDate[1].slice(0, 4)} ${
			formattedDate[2]
		}`;
	};

	const handleCardClick = () => {
		setActiveEvent(event);
		setShowEventDetails(true);
	};

	const onApprove = () => {
		setActiveEvent(event);
		setIsRejected(false);
		setShowRejectForm(true);
	};

	return (
		<div className="relative cursor-pointer" {...props}>
			<div className="absolute hidden bg-black/10 -inset-[1px] blur rounded-xl"></div>
			<div className="relative space-y-3 overflow-hidden bg-white border shadow h-72 p-7 rounded-xl">
				<h2 className="text-lg font-semibold capitalize line-clamp-1 text-dark">
					{event?.title}
				</h2>

				{/* date */}
				<div className={"text-sm flex items-center space-x-4 "}>
					<div className="flex items-center gap-x-1 ">
						<div className={"h-5 w-5 center rounded-full"}>
							<CalendarIcon className="w-4 h-4 text-primary" />
						</div>
						<p className={"text-black/60"}>
							{formatDate(event?.eventDate)}
						</p>
					</div>
					<div className="flex px-3 space-x-2 font-semibold text-black/80">
						<div className={"h-5 w-5 center rounded-full"}>
							<UserGroup
								className={"h-4 w-4"}
								color={"#41727E"}
							/>
						</div>
						<p className={" font-normal text-black/60"}>
							{event?.participants?.length ?? 0} Participants
						</p>
					</div>
				</div>

				<div className={"text-sm"}>
					<h6
						className={`text-black/70 ${
							event?.description ? "opacity-100" : "opacity-0"
						}`}
					>
						Descriptions
					</h6>

					{event?.description ? (
						<Fragment>
							<p
								dangerouslySetInnerHTML={CreateMarkup(
									event?.description
								)}
								className={
									"line-clamp-4 h-20  text-dark/60 text-light text-justify"
								}
							></p>
						</Fragment>
					) : (
						<div
							className={
								"h-20 rounded flex items-center text-sm italic text-[#c9c9c9]"
							}
						>
							Description not provided.
						</div>
					)}
				</div>

				{/*   Active */}
				<div
					className={`pt-4 items-between ${
						event?.budgetStatus === 1
							? "flex justify-end"
							: "items-between"
					}`}
				>
					<div
						className={`text-sm  items-center space-x-3 ${
							event?.budgetStatus === 1 ? "hidden" : "flex"
						}`}
					>
						<button
							onClick={onReject}
							className={
								"text-primary px-4 py-1.5 border-[1.6px] border-primary font-medium rounded"
							}
						>
							Reject
						</button>
						<button
							onClick={onApprove}
							className={
								"text-white bg-primary  border-[1.6px] border-primary hover:opacity-90 px-4 py-1.5 rounded"
							}
						>
							Approve
						</button>
					</div>

					<button
						className={
							"text-primary text-sm flex items-center space-x-2"
						}
						onClick={handleCardClick}
					>
						<p>View</p>
						<ArrowRight className={"h-5 w-5"} color={"#41727E"} />
					</button>
				</div>
			</div>
		</div>
	);
};
export default EventBudgetCard;
