import PhaseIllustrator from "./phase-illustrator";

const EmptyState = () => {
    return (
        <div
            className={
                "py-20 border mt-4 rounded space-y-5 bg-[#f9f9f9] flex flex-col items-center"
            }
        >
            <div className={"h-44 w-44 bg-gray-200 p-5 rounded-full center"}>
                <PhaseIllustrator />
            </div>
            <p className={"text-center text-gray-400"}>
                No project phases are currently available. To get started,
                please click the button above to <br /> create a new project
                phase.
            </p>
        </div>
    );
};
export default EmptyState;
