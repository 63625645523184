const Day = ({
    day,
    className,
    cardKey,
    isSelected = 0,
    clickHandler: setDay,
    showBorder = true,
}) => {
    return (
        <div
            key={cardKey}
            className={`
                ${className} h-full w-full relative text-xs flex pt-2 items-center justify-center 
                ${
                    showBorder && "border-b-[1px] border-r-[1px] border-accent1"
                }`}
        >
            <header
                className={" flex w-full justify-center flex-col items-center"}
            >
                <p
                    onClick={() => setDay(day)}
                    className={`cursor-pointer h-8 w-8 rounded-full
                     center ${
                         isSelected
                             ? "bg-white text-accent "
                             : "hover:bg-white/30 hover:text-white"
                     }`}
                >
                    {day.format("DD")}
                </p>
            </header>
        </div>
    );
};

export default Day;
