import React, { useEffect, useState } from "react";
import { ExclamationCircleIcon } from "@heroicons/react/outline";
import dayjs from "dayjs";
import { splitOnUpperCase } from "../../utils";
import { twMerge } from "tailwind-merge";

const Input = ({ props }) => {
    const {
        onUpload: handleFileUpload,
        isFileInput = false,
        type = "text",
        placeholder = "",
        errorMessage = null,
        value,
        autoFocus = false,
        max = null,
        min = null,
        defaultValue = null,
        inputRef = null,
        register = () => {},
        style = {},
        onBlur: handleBlur,
        onChange: handleOnChange,
        showErrorMessage = true,
        id = null,
        name = "",
        className = "",
        hasError: errorState = false,
        wrapperClass,
        ...rest
    } = props;
    const [isError, setIsError] = useState(errorState);

    useEffect(() => {
        setIsError(errorState);
    }, [errorState]);

    const { ref, name: inputName, onChange, onBlur } = register(name) ?? {};

    const getMaxValue = () => {
        if (type === "date" && max) {
            return dayjs(max).format("YYYY-MM-DD");
        }
        return max;
    };

    const getMinValue = () => {
        if (type === "date" && min) {
            return dayjs(min).format("YYYY-MM-DD");
        }
        return min;
    };

    const classes = twMerge(
        `
                        w-full text-gray-500 focus:border-[1.7px] focus:ring-4 enable-transition  
                        focus:outline-none tracking-wider  py-3 border-[1.4px]  ${
                            isFileInput
                                ? "pl-28 cursor-pointer"
                                : "placeholder-[#AAB6C5]/90"
                        }
                        ${
                            isError
                                ? "border-red-500 focus:ring-red-500/30 focus:border-red-500"
                                : "border-[#D2D4DA] focus:border-accent focus:ring-[#5D9EE9]/30"
                        }
                        rounded-[5px] overflow-hidden
                    `,
        className
    );

    return (
        <div
            onClick={isFileInput ? handleFileUpload : null}
            className={`${wrapperClass} space-y-1`}
        >
            <label
                htmlFor={name}
                className={"text-primary/80 capitalize tracking-wider"}
            >
                {splitOnUpperCase(name)}
            </label>
            <div className={`relative`}>
                <input
                    autoFocus={autoFocus || isError}
                    autoComplete={"off"}
                    style={style}
                    readOnly={isFileInput}
                    id={id ?? name}
                    ref={inputRef ?? ref}
                    value={value}
                    name={inputName ?? name}
                    type={type}
                    onBlur={handleBlur ?? onBlur}
                    max={getMaxValue()}
                    min={getMinValue()}
                    defaultValue={defaultValue}
                    onChange={handleOnChange ?? onChange}
                    placeholder={isFileInput ? "No file Selected" : placeholder}
                    className={classes}
                    {...rest}
                />
                {isError ? (
                    <ExclamationCircleIcon
                        className={
                            "absolute right-2 pointer-events-none top-0 h-5 w-5 text-red-500 bottom-0 my-auto"
                        }
                    />
                ) : null}

                <button
                    type={"button"}
                    className={`absolute text-gray-900 ${
                        isFileInput ? "" : "hidden"
                    } top-[1px] px-3 bottom-[1px] rounded-l z-10 left-[1px] bg-[#D2D4DA]`}
                >
                    Choose File
                </button>
            </div>
            {isError && showErrorMessage ? (
                <p className={"text-red-500 pt-1 text-sm"}>
                    {errorMessage
                        ? errorMessage
                        : `Please provide valid ${name}`}
                </p>
            ) : null}
        </div>
    );
};
export default Input;
