import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";

const ButtonAdd = ({label, onClick, className}) => {
    return (
        <div role={"button"}
             onClick={onClick}
             className={
                 "flex items-center hover:opacity-90 space-x-2 cursor-pointer font-light"
             }
        >
            <p className={` ${className} text-primary text-sm `}>{label}</p>
            <div
                style={{
                    background:
                        "transparent linear-gradient(132deg, #A0C7F1 0%, #1A9FDF 100%) 0% 0% no-repeat padding-box",
                }}
                className={
                    "bg-gradient-to-tr from-accent to-blue-400 flex justify-center items-center h-5 w-5 rounded-full"
                }
            >
                <FontAwesomeIcon
                    icon={faPlus}
                    className={"h-3 w-3 text-white"}
                />
            </div>
        </div>
    )
}
export default ButtonAdd;