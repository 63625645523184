import GrievanceBreakdown from "./breakdown";
import GrievanceStatistics from "./statistics/grievance-statistics";
import DepartmentComplainedAbout from "./department";
import RiskLevelSummary from "./risk-level";
import NatureOfGrievanceSummary from "./nature-of-grievance";
import MostHighRiskGrievance from "./repeated-grievance";
import ClosedGrievanceByRiskLevel from "./closed-grievance";
import GrievanceAnalyticProvider from "provider/analytics/grievance/grievance-analytic-provider";
import { useLocation, Link } from "react-router-dom";

export const GrievanceTab = ({ url, pathname }, ...props) => {
	const { pathname: _pathname } = useLocation();
	const isActive = _pathname === url;

	return (
		<li {...props}>
			<Link
				to={url}
				className={`${
					isActive
						? "text-secondary bg-secondary/10 "
						: "text-[#A7B3C3] hover:text-gray-400"
				} 
                block font-semibold  rounded hover:bg-secondary/10 px-5 py-2`}
			>
				{pathname}
			</Link>
		</li>
	);
};

const GrievanceAnalytics = () => {
	const isGBV = useLocation().pathname.split("/").at(-1) === "gbv";
	console.log(useLocation().pathname.split("./").at(-1));
	const tabs = [
		{ pathname: "General Grievance", url: "/analytics/grievances" },
		{ pathname: "Gbv Analytics", url: "/analytics/grievances/gbv" },
	];
	return (
		<GrievanceAnalyticProvider>
			<section className={"bg-[#EBF0F3] px-5  h-full overflow-y-auto"}>
				<div className="bg-[#EBF0F3] py-5 z-20 sticky top-0 ">
					<header
						className={
							" border-[1.2px] bg-[#F7F9FA] border-[#A7B3C3] backdrop-blur-[1px] rounded-[5px] p-1"
						}
					>
						<div
							className={
								"border-[1px] items-between p-1 rounded-[5px] border-[#ECECED] bg-white/80"
							}
						>
							<ul className={"flex items-center space-x-1"}>
								{tabs.map(({ url, pathname }) => (
									<GrievanceTab
										url={url}
										pathname={pathname}
										key={pathname}
									/>
								))}
							</ul>
						</div>
					</header>
				</div>
				<div
					className={"space-y-4 py-6 pt-0"}
					style={{ width: "calc(100%  - 380px)" }}
				>
					<h1
						className={
							"text-[17px] text-[#253A53] font-medium hidden"
						}
					>
						{isGBV
							? "GBV Grievances Analytics"
							: "General Grievances Analytics"}
					</h1>
					<GrievanceBreakdown />
					<GrievanceStatistics />
					<DepartmentComplainedAbout />
					<ClosedGrievanceByRiskLevel />
					<div
						className={
							"flex justify-center items-stretch i  space-x-4"
						}
					>
						<RiskLevelSummary />
						<NatureOfGrievanceSummary />
					</div>
				</div>
				<MostHighRiskGrievance />
			</section>
		</GrievanceAnalyticProvider>
	);
};
export default GrievanceAnalytics;
