import { useQuery } from "react-query";
import { useOutletContext } from "react-router-dom";
import isBetween from "dayjs/plugin/isBetween";
import React, { createContext, useContext, useEffect, useState } from "react";

import {
    getEventsByStakeholder,
    getStakeholderEngagementLevels,
} from "provider/api";
import MeetingDetails from "../../../engagement/activity/events/_partials/meeting-details";
import SidebarRight from "components/sidebar-right";
import EngagementSummary from "./engagement-summary";
import EngagementActivities from "./angagement-activities";
import TaskCompletionRate from "./task-completion-rate";
import AttendanceSummary from "./attendace-summary";
import dayjs from "dayjs";
import { isDateBetween } from "utils";
import Loading from "./loading";

dayjs.extend(isBetween);

export const EngagementContext = createContext(null);
const StakeholderEngagement = () => {
    const [events, setEvents] = useState([]);
    const [filteredEvents, setFilteredEvents] = useState([]);
    const [showEventDetails, setShowEventDetails] = useState(false);
    const [activeEvent, setActiveEvent] = useState(null);
    const { isLoading: isLoadingStakeholders, getCurrentStakeholder } =
        useOutletContext();
    const todayDate = new Date().getTime();
    const [taskCompletionRate, setTaskCompletionRate] = useState({});
    const [engagementLevels, setEngagementLevels] = useState({});

    const currStakeholder = getCurrentStakeholder();
    useQuery(
        ["list-engagements", `${currStakeholder?._id}`],
        getEventsByStakeholder,
        {
            enabled: !!currStakeholder?._id,
            onSuccess: ({ data: events_ }) => {
                setEvents([...events_]);
                setFilteredEvents([...events_]);
            },
            staleTime: Infinity,
        }
    );

    useQuery(
        ["engagement-level", `${currStakeholder?._id}`],
        getStakeholderEngagementLevels,
        {
            enabled: !!currStakeholder,
            onSuccess: ({ data: projectPhases }) => {
                getCurrentPhase(projectPhases);
            },
            staleTime: Infinity,
        }
    );

    const filterUpcomingEvents = () => {
        return events.filter((event) => {
            return new Date(event.eventDate) >= todayDate && !event.status;
        });
    };

    const filterCompletedEvents = () => {
        return events.filter((event) => {
            return event.status;
        });
    };

    const filterOverdueEvents = () => {
        return events.filter((event) => {
            return !event.status && new Date(event.eventDate) < todayDate;
        });
    };

    const getCurrentPhase = (_engagementLevels = []) => {
        setEngagementLevels(
            _engagementLevels.find((level) => {
                return isDateBetween(
                    todayDate,
                    level.projectPhase.startDate,
                    level.projectPhase.endDate
                );
            })
        );
    };

    useEffect(() => {
        setTaskCompletionRate({
            completed: filterCompletedEvents().length,
            overdue: filterOverdueEvents().length,
            upcoming: filterUpcomingEvents().length,
        });
    }, [events]);

    if (isLoadingStakeholders) {
        return <Loading />;
    }

    const flatten = (arr) => {
        return arr.reduce(
            (acc, cur) => acc.concat(Array.isArray(cur) ? flatten(cur) : cur),
            []
        );
    };

    const getParticipantAttendance = (participants) => {
        return ["late", "absent", "present"].reduce((attendance, status) => {
            attendance[status] = participants.filter(
                (participant) =>
                    participant?.stakeholder?.name === currStakeholder?.name &&
                    participant?.attendance === status
            );
            return attendance;
        }, {});
    };

    const attendanceStatistics = () => {
        const allEventParticipants = flatten(
            filterCompletedEvents().map((event) => {
                return event.participants;
            })
        );

        const { late, absent, present } =
            getParticipantAttendance(allEventParticipants);
        return {
            present: present.length,
            late: late.length,
            absent: absent.length,
        };
    };

    return (
        <EngagementContext.Provider
            value={{
                events,
                setEvents,
                showEventDetails,
                setFilteredEvents,
                filteredEvents,
                taskCompletionRate,
                attendanceStatistics,
                engagementLevels,
                setActiveEvent,
                activeEvent,
                setShowEventDetails,
            }}
        >
            <SidebarRight
                onClose={setShowEventDetails}
                isOpen={showEventDetails}
            >
                <MeetingDetails event={activeEvent} />
            </SidebarRight>
            <div className={"space-y-10 pb-16"}>
                <EngagementSummary />

                <div className={"grid grid-cols-2 gap-x-3"}>
                    <TaskCompletionRate />
                    <AttendanceSummary />
                </div>
                <EngagementActivities />
            </div>
        </EngagementContext.Provider>
    );
};
export const useEngagementContext = () => useContext(EngagementContext);
export default StakeholderEngagement;
