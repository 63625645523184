import React, { Fragment, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { useQuery } from "react-query";

import CommitmentCard from "./commitment-card";
import { getCommitmentsByStakeholder } from "provider/api";
import CommitmentDetails from "../../../commitments/details";
import Loading from "./loading";
import useUpdateCache from "hooks/useUpdateCache";
import { cacheActions } from "../../../../../constants";
import EmptyState from "./empty-state";

const Commitment = () => {
    const [showDetails, setShowDetails] = useState(false);
    const [commitments, setCommitments] = useState([]);
    const [activeCommitment, setActiveCommitment] = useState(null);
    const updateCache = useUpdateCache();

    const { isLoading: isLoadingStakeholders, getCurrentStakeholder } =
        useOutletContext();

    const currStakeholder = getCurrentStakeholder();

    const { isLoading: isLoadingCommitments } = useQuery(
        ["commitments", `${currStakeholder?._id}`],
        getCommitmentsByStakeholder,
        {
            enabled: !!currStakeholder,
            onSuccess: ({ data: stakeholderCommitments }) => {
                setCommitments(stakeholderCommitments);
                // TODO: show success alert
            },
        }
    );

    if (isLoadingStakeholders || isLoadingCommitments) {
        return <Loading />;
    }

    const handleClick = (commitment) => {
        // Todo: Set details for selected commitment;
        setActiveCommitment(commitment);
        setShowDetails(true);
    };

    const commitmentList = commitments.map((commitment) => (
        <CommitmentCard
            commitment={commitment}
            onClick={handleClick}
            key={commitment?._id}
        />
    ));

    const updateCommitmentCache = (data) => {
        const queryKey = ["commitments", `${currStakeholder?._id}`];
        updateCache(queryKey, data, cacheActions.UPDATE);
    };
    return (
        <Fragment>
            {activeCommitment && (
                <CommitmentDetails
                    commitment={activeCommitment}
                    showDetails={showDetails}
                    closeHandler={setShowDetails}
                    cacheUpdater={updateCommitmentCache}
                />
            )}
            {commitmentList.length ? (
                <div className={"grid grid-cols-3 gap-4"}>{commitmentList}</div>
            ) : (
                <EmptyState />
            )}
        </Fragment>
    );
};
export default Commitment;
