import {XCircleIcon} from "@heroicons/react/outline";
import {useEffect, useState} from "react";
import {NotificationContext} from "../../context";

const NotificationProvider = ({children}) => {
    const [contents, setContents] = useState(null);
    const [showNotification, setShowNotification] = useState(false);

    useEffect(() => {
        if (contents) {
            setShowNotification(true);
        } else setShowNotification(false);
    }, [contents]);


    const setNotification = (contents_) => {
        console.log('CONTEXT:: ', contents_)
        setContents(contents_)
    }


    const closeNotification = () => {
        setContents(null);
        setShowNotification(false)
    }
    return (
        <NotificationContext.Provider value={{setNotification, closeNotification}}>
            {showNotification && (
                <section className={'hello relative bg-[#2E334D] h-12 text-[15px] tracking-wide center text-[#DEE3F1]'}>
                    {contents}
                    {/* close button */}
                    <XCircleIcon onClick={closeNotification}
                                 className={'absolute right-4 hover:text-[#DEE3F1] transition-all h-6 w-6 cursor-pointer text-[#A0AAC4]'}/>
                </section>
            )}
            {children}
        </NotificationContext.Provider>
    )
}
export default NotificationProvider;
