import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Fragment } from "react";
import UploadedFileItem from "./uploaded-file-item";

const UploadedFiles = ({
    files: uploadedFiles = [],
    onRemove: setUploadedFiles,
    onUpload: handleUpload,
    onUploadFinish,
}) => {
    return (
        <div
            className={
                "rounded-[5px] border-b-4 border-secondary overflow-hidden"
            }
        >
            {/* header */}
            <div
                className={
                    "grid text-[15px] w-full grid-cols-5 py-1 px-5 bg-secondary text-white"
                }
            >
                <div className={"col-span-2 flex items-center"}>File Name</div>
                <div className={"items-center center"}>File Size</div>
                <div></div>
                <div className={" center"}>
                    <button
                        type={"button"}
                        onClick={handleUpload}
                        className={
                            "flex hover:bg-white/10 py-2 px-4 rounded items-center whitespace-nowrap space-x-2"
                        }
                    >
                        <span>Upload File</span>
                        <FontAwesomeIcon icon={faPlus} className={"h-4 w-4"} />
                    </button>
                </div>
            </div>

            {/* files list */}
            {uploadedFiles.length ? (
                <Fragment>
                    {uploadedFiles.map((file) => (
                        <UploadedFileItem
                            onUploadFinish={onUploadFinish}
                            file={file}
                            setUploadedFiles={setUploadedFiles}
                            uploadedFiles={uploadedFiles}
                        />
                    ))}
                </Fragment>
            ) : (
                <div
                    className={
                        "h-20 bg-[#F2F2F2] text-[#707070] center text-sm"
                    }
                >
                    No file uploaded
                </div>
            )}
        </div>
    );
};

UploadedFiles.propTypes = {
    files: PropTypes.object.isRequired,
    onRemove: PropTypes.func.isRequired,
};
export default UploadedFiles;
