import { useEffect, useState } from "react";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Participant from "../pages/general/engagement/activity/events/_partials/participant";
import Participants from "../pages/general/engagement/activity/events/form/meeting/steps/participant/sidebar/participants";
import { getValueFromLocalStorage } from "../hooks/useLocalStorageState";

const ParticipantComponent = ({ setSelectedList, currentList }) => {
    const isEditingEvent = getValueFromLocalStorage("isEditingProjectEvent", 0);
    const [showParticipants, setShowParticipants] = useState(false);
    const [participantsList, setParticipantsList] = useState(currentList);

    const toggleSidebar = () => {
        setShowParticipants(!showParticipants);
    };

    useEffect(() => {
        if (isEditingEvent) {
            setParticipantsList(currentList);
        }
    }, [currentList]);

    useEffect(() => {
        setSelectedList(participantsList);
    }, [participantsList]);

    return (
        <>
            <Participants
                showParticipants={showParticipants}
                setShowParticipants={setShowParticipants}
                initialParticipants={participantsList}
                setParticipantList={setParticipantsList}
            />

            <div className={" space-y-10 pt-5"}>
                <div>
                    <div
                        className={"h-1 primary-gradient rounded-t-[4px]"}
                    ></div>
                    <div
                        className={
                            "min-h-[16rem] border-[1px] space-y-5 py-4 border-[#DFF1FE]"
                        }
                    >
                        <div className={"items-between  px-8"}>
                            <p className={"text-[15px] text-dark"}>
                                PARTICIPANTS
                            </p>

                            <button
                                onClick={toggleSidebar}
                                type={"button"}
                                className={
                                    "flex items-center text-sm font-light space-x-2 text-accent"
                                }
                            >
                                <span>Add Participants</span>
                                <FontAwesomeIcon
                                    icon={faPlus}
                                    className={"h-4 w-4"}
                                />
                            </button>
                        </div>

                        <div
                            className={
                                "px-8 space-y-2 overflow-y-auto max-h-[400px]"
                            }
                        >
                            {!participantsList.length ? (
                                <span>No selected participants</span>
                            ) : (
                                participantsList.map((participant, idx) => (
                                    <Participant
                                        key={idx}
                                        stakeholder={participant}
                                    />
                                ))
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default ParticipantComponent;
