import {
    forwardRef,
    Fragment,
    useEffect,
    useImperativeHandle,
    useState,
} from "react";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import { useQuery } from "react-query";

import PlanListItem from "./plan-list-item";
import { useEngagementPlans } from "../plans";
import Loading from "./loading";
import EmptyState from "./empty-state/empty-state";
import { queryKeys } from "../../../../../constants";
import { getEngagementPlans } from "provider/api";
import useGlobalContext from "hooks/useGlobalContext";
import PlanForm from "../form/plan-form";

const PlanList = forwardRef((props, ref) => {
    const [plans, setPlans] = useState([]);
    const { isLoadingPhases, activePhase } = useEngagementPlans();
    const { projectId } = useGlobalContext();
    const navigate = useNavigate();
    const { phase } = useParams();

    const headers = [
        "Stakeholder",
        "Activity",
        "Frequency",
        "Channel",
        "Starts On",
        "Ends On",
    ];

    // send get request to get engagement plans based on active phase and project
    const {
        isLoading: isLoadingPlans,
        refetch: refetchPlans,
        isFetched,
    } = useQuery(
        queryKeys.ENGAGEMENT_PLANS,
        () => {
            return getEngagementPlans(projectId, activePhase._id);
        },
        {
            onSuccess: ({ data: _plans } = {}) => {
                setPlans(_plans);
            },

            onError: (err) => {
                // TODO: Handle errors in querying engagement plans
            },
            enabled: false,
        }
    );

    useImperativeHandle(
        ref,
        () => ({
            openPlanForm() {
                handleOpenForm();
            },
        }),
        [phase]
    );

    useEffect(() => {
        if (!!projectId && !!activePhase?._id) {
            refetchPlans();
        }
    }, [projectId, !!activePhase?._id, phase]);

    const handleOpenForm = () => {
        navigate("create");
    };

    return (
        <section>
            <Routes>
                <Route path={"create"} element={<PlanForm />} />
            </Routes>

            {isLoadingPlans ? <Loading /> : null}
            {plans.length < 1 && isFetched ? <EmptyState /> : null}
            {isFetched && plans.length ? (
                <Fragment>
                    <header
                        className={
                            "grid px-8 py-6 bg-[bg-[#F1F6F9]] grid-cols-6 text-xs uppercase gap-x-8"
                        }
                    >
                        {headers.map((header) => (
                            <p key={header}>{header}</p>
                        ))}
                    </header>

                    {plans.map((plan) => (
                        <PlanListItem plan={plan} key={plan?._id} />
                    ))}
                </Fragment>
            ) : null}
        </section>
    );
});
export default PlanList;
