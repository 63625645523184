import {useState, Fragment} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCaretRight} from "@fortawesome/free-solid-svg-icons";
import EventDetails from "./event-details";
import { Transition } from "@headlessui/react";

const Event = ({ event, onClick}) => {

    const [isExpanded, setIsExpanded] = useState(false)
    return (
        <div className={'text-[13px] z-50'}>
            <div
                onClick={() => setIsExpanded(!isExpanded)}
                className={`
                    hover:bg-[#F0F0F0] group
                    ${isExpanded ? 'bg-[#F0F0F0]' : 'bg-[#F9F9F9]'} cursor-pointer py-3 pr-5 
                    flex justify-between items-center
                `}>

                <div className={'flex items-center'}>
                    <FontAwesomeIcon
                        icon={faCaretRight}
                        className={`h-4 w-6 transition-transform ease-in-out 
                         ${isExpanded ? 'rotate-90 -mt-1 text-[#51BF82]' : 'text-[#90A4AE]'}`}/>
                    <p
                        className={`text-[#638798] group-hover:text-sm ${isExpanded ? 'font-medium text-sm' : 'text-[13px] font-normal'}
                            group-hover:font-medium enable-transition`}>
                        {event.title}
                    </p>
                </div>

                <div className={`flex ${isExpanded ? 'space-x-12 ' : 'space-x-8'} group-hover:space-x-12 items-center enable-transition `}>
                    <p>Event Type:
                        <span className={`text-[#37474F]/50 ${isExpanded ? 'pl-5' : 'pl-1'} enable-transition group-hover:pl-5`}>
                            {event.isMeeting ? "Meeting" : "Other"}
                        </span>
                    </p>
                    <div onClick={() => onClick(event)}>
                        <p className={' group-hover:ml-5 transition-all group-hover:text-[#51BF82] ease-in-out relative'}>
                            View Event
                        </p>
                    </div>
                </div>
            </div>

            <Transition appear show={isExpanded} as={Fragment}>
                <Transition.Child
                    enter="duration-300 ease-in-out "
                    enterFrom="opacity-0 -mt-5"
                    enterTo="opacity-100 mt-0"
                    leave="duration-100 ease-out mt-0"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0 -mt-5 "
                >
                    <EventDetails event={event} />
                </Transition.Child>

            </Transition>
        </div>
    )
}
export default Event;