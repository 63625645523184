import { useState } from "react";
import { useQuery } from "react-query";
import { ProjectImagesContext } from "../../context";
import { getProjectImages } from "../api";

const ProjectImagesProvider = ({ children }) => {
	const [projectImages, setProjectImages] = useState({});
	const { isLoading } = useQuery("project-images", getProjectImages, {
		onSuccess: ({ data: images }) => {
			setProjectImages(images);
		},
	});
	return (
		<ProjectImagesContext.Provider
			value={{
				isLoading,
				images: projectImages?.folders ?? {},
				recentImages: projectImages?.recentImages,
			}}
		>
			{children}
		</ProjectImagesContext.Provider>
	);
};
export default ProjectImagesProvider;
