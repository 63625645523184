import {Droppable} from "react-beautiful-dnd";
import DraggableGrievanceCard from "./draggable-item";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMagnifyingGlass, faSliders} from "@fortawesome/free-solid-svg-icons";

const DroppableColumn = ({
                             colors,
                             prefix,
                             elements,
                             changeStatus,
                             handleDelete,
                             setActiveGrievance,
                             setDetailsOpen,
                         }) => {
    return (
        <div
            className={`bg-[#F7F9FB] mb-10 overflow-y-scroll no-scrollbar border-t-4 rounded-t ${colors.border}`}
        >
            {/* Title of the column and icons */}
            <div
                className="sticky top-0 z-10 flex flex-row justify-between px-6 py-4 space-x-4 bg-transparent backdrop-blur-xl place-items-center">
                <div
                    className={`inline-flex items-center text-sm font-medium text-center text-[#707090] rounded-lg `}
                >
                    {prefix}
                    <span
                        className={`animation-all inline-flex justify-center items-center ml-2 px-1 min-w-[20px] h-5 text-xs font-semibold ${colors.badge} rounded-full`}
                    >
						{elements.length}
					</span>
                </div>

                <div className="flex flex-row space-x-3 place-items-center">
                    <FontAwesomeIcon
                        icon={faMagnifyingGlass}
                        className={`cursor-pointer hover:scale-125 transition-all h-3.5 w-3.5 ${colors.icon}`}
                    />
                    <FontAwesomeIcon
                        icon={faSliders}
                        className={`cursor-pointer hover:scale-125 transition-all h-3.5 w-3.5 ${colors.icon}`}
                    />
                </div>
            </div>

            <Droppable droppableId={`${prefix}`}>
                {(provided, snapshot) => (
                    <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        className={`flex flex-col place-items-start min-h-[92%] ${
                            snapshot.isDraggingOver && colors.bgOnDrop
                        }`}
                    >
                        <div
                            className={` ${
                                elements.length !== 0 && "hidden"
                            } text-center mx-auto my-2 h-[60vh] w-auto flex place-items-center items-center`}
                        >
                            <div
                                className={"text-center text-sm text-gray-500"}
                            >
                                All clear, no items!
                            </div>
                        </div>

                        {elements.map((grievance, index) => (
                            <DraggableGrievanceCard
                                changeStatus={changeStatus}
                                handleDelete={handleDelete}
                                iconColor={colors.icon}
                                borderColor={colors.borderColor}
                                key={grievance?._id}
                                grievance={grievance}
                                index={index}
                                prefix={prefix}
                                setActiveGrievance={setActiveGrievance}
                                setDetailsOpen={setDetailsOpen}
                            />
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </div>
    );
};

export default DroppableColumn;
