import { Link, useLocation } from "react-router-dom";

export const Tab = ({ title, urlPattern, url = "/" }) => {
	const { pathname } = useLocation();
	const isActive = urlPattern.test(pathname);

	return (
		<Link
			to={url}
			className={`${
				isActive
					? "text-accent bg-accent/10 rounded-t border-b-[1.8px] border-accent "
					: "rounded bg-transparent"
			} center  px-4 py-3  hover:bg-accent/10 hover:text-accent  cursor-pointer`}
		>
			{title}
		</Link>
	);
};
