import edjsParser from "editorjs-parser";
import isBetween from "dayjs/plugin/isBetween";
import dayjs from "dayjs";

dayjs.extend(isBetween);

export const CreateMarkup = (blocks) => {
    const parser = new edjsParser();
    try {
        return { __html: parser.parse(JSON.parse(blocks)) };
    } catch (e) {
        return { __html: blocks };
    }
};

export const getMonth = (month = dayjs().month()) => {
    const year = dayjs().year();
    const firstDayOfMonth = dayjs(new Date(year, month, 1)).day();
    let currentMonthCount = 0 - firstDayOfMonth;

    return new Array(5).fill([]).map(() => {
        return new Array(7).fill(null).map(() => {
            currentMonthCount++;
            return dayjs(new Date(year, month, currentMonthCount));
        });
    });
};

export const getRandomKey = () => Math.random().toString().slice(3, 9);

export const isToday = (date) => {
    const inputDate = new Date(date);
    const today = new Date();
    return (
        inputDate.getDate() === today.getDate() &&
        inputDate.getMonth() === today.getMonth() &&
        inputDate.getFullYear() === today.getFullYear()
    );
};

export const getFileSize = (bytes) => {
    const fileSizeInMb = Math.round(bytes / 1024 / 1024);
    const fileSizeInKb = Math.round(bytes / 1024);

    if (fileSizeInMb < 1) {
        return ["KB", fileSizeInKb];
    }
    return ["MB", fileSizeInMb];
};

export const isDateBetween = (date, startDate, endDate) => {
    const dateToCheck = dayjs(date).format("YYYY-MM-DD");
    const startDate_ = dayjs(startDate).format("YYYY-MM-DD");
    const endDate_ = dayjs(endDate).format("YYYY-MM-DD");
    return dayjs(dateToCheck).isBetween(`${startDate_}`, dayjs(endDate_));
};

export const groupByProperty = (array = [], property) => {
    return array.reduce((prevObj, nextObj) => {
        const key = nextObj[property];
        prevObj[key] ??= [];
        prevObj[key].push(nextObj);
        return prevObj;
    }, {});
};
export const isImageFile = (fileExtension) => {
    return ["jpg", "jpeg", "png", "webp", "image"].includes(fileExtension);
};
export const isDocumentFile = (fileExtension) => {
    return ["doc", "docx", "document"].includes(fileExtension);
};

export const flatten = (array = []) => {
    return array.reduce((previousValue, currentValue) => {
        return previousValue.concat(
            Array.isArray(currentValue) ? flatten(currentValue) : currentValue
        );
    }, []);
};

export const splitOnUpperCase = (str = "", autoJoin = true) => {
    let splitText = str.split(/(?=[A-Z])/);
    return autoJoin ? (splitText = splitText.join(" ")) : splitText;
};

export const arrToLowerCase = (arr = []) => {
    if (!Array.isArray(arr)) {
        throw new Error(
            `function arrToLowerCase expect array as type, but ${typeof arr} was given.`
        );
    }
    return arr.map((item) => {
        return item.toLowerCase();
    });
};

export const randomId = () => Math.random().toString(36).slice(2);

export const isEmptyObject = (object) => {
    // if(!object) return true;
    return !Object.keys(object ?? {}).length;
};

export const isEmptyDescription = (description) => {
    if (!isEmptyObject(description)) {
        return !description?.blocks?.length;
    }
    return true;
};

export const slugify = (str) => {
    return str
        .toString()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase()
        .trim()
        .replace(/\s+/g, "-")
        .replace(/[^\w-]+/g, "")
        .replace(/--+/g, "-");
};

export const getFileType = (fileExtension) => {
    const videoExt = [
        "WEBM",
        "MPG",
        "MP2",
        "MPEG",
        "MPE",
        "MPV",
        "MP4",
        "MOV",
        "MKV",
    ];
    const imageExt = ["JPEG", "PNG", "SVG", "WEBP", "JPG"];
    if (videoExt.includes(fileExtension.toUpperCase())) return "video";
    else if (imageExt.includes(fileExtension.toUpperCase())) return "image";
    else return "document";
};

export const getFormData = (form) => {
    try {
        return Object.fromEntries(new FormData(form).entries());
    } catch {
        return {};
    }
};

export const getShortName = (str = "") => {
    let shortName = "";
    const words = str.split(" ");
    words.forEach((word) => {
        if (word[0]) shortName += word[0];
    });
    return shortName.slice(0, 2);
};

export const isIncludedIn = (value, array) => {
    if (!Array.isArray(array)) {
        throw new Error(`excepted type is array, but ${typeof array} is given`);
    }
    return array.includes(value);
};
