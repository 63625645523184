const RukwaSvg = ({ color, isLoading = false }) => {
    return (
        <path
            id="Path_432"
            data-name="Path 432"
            className={`${isLoading ? "animate-pulse" : ""}`}
            d="M176.956,327.99l.549-.4,4.258-1.287,8.518-.429,3.407,1.287,1.7,5.149v3.434l1.7,2.575,5.536.43,6.388.43,2.129,2.576.426,4.294.852,6.014.851,5.586,5.111.859,6.388.859,8.728,1.472,10.5,5.51,7.257,4.86,1.834,1.851,9.031,12.092.3,2.592-1.467-.28-1.519.2-1.624-.137-3.257-4.148-.186-1.141-.041-1.172-.59-.982.165-1.18-.165-.788-1.022-.48-4.909,3.334-2.4.085-1.439,1.738-.133,2.252.675,2.143-.178,4.458-2.93,3.733-2.259,1.384-4.865,1.744-.829,1.584,1.8,1.218,1.39,1.456-.8,1.12.7.787.881.63,1.519,1.407.5,1.6-.626.074-.375.466.36,2.367-.016.256-.447-.242-.356-.016-.752.18-.368.033-.406-.118-1.125-.655-1-.143-.622.266-.564.422-.815.34-1.66-.4-.8-1.555,0-1.955.735-1.6-5.826-1.6-.909.078-1.334.724-.8.286-.788,0-.662-.258-3.762-2.9-.9-1.21-.293-1.693,0-1.476-.27-1.006-.78-.654-1.5-.429-.622-.4-.84-.973-.615-.319-.59-.045-.532.057-.5-.049-.521-.343-.133-.277-.129-.72-.146-.249-.347-.2-.125.053-.1.155-.279.111-.452.1-2.023.724-.3.2-.332.114-.5-.082-.418-.314-.739-1-.456-.314h-.942l-.626.326-.039-.088.115-1.141-.1-.454-.415-.175-.782-.663-.034-1.466.426-2.455-.713-1.268-2.336-1.466-.691-1.07-.138.07h-.046l-.023.035-.069.186-.449-.512-.46-1.163-.437-.512-.553-.267-.518-.186-.379-.325-.15-.7.023-.361.035-.151.012-.14-.069-.291-.1-.14-.172-.2-.172-.267-.172-.767-.219-.372-.115-.419.15-.57h.288l.046.163.172.383.2-.674-.1-.756-.276-.7-.322-.57-.633.383-.173.151-.4-.662-.241-.79-.126-1.662-.138-.5-.806-1.267-.415-.535-.851-.7-.345-.419-.288-1.383-.391-.535-.944-.871-2.14-6.087-.437-.244-.287-.545-.23-.639-.23-.476-.4-.314-1.058-.5-.426-.29-.288-.569-.207-.72-.334-.6-.656-.267-.2-.476-1.554-2.728.633-1.358.207-.754-.437-.336.023-.372.518-1.857.126-.731-.253-1.532-1.646-3.609-.426-1.4-.207-.209-.529-.186-.081-.406.115-.418.092-.22-.15-.707-.4-.7-.529-.568-.541-.325.265-.464.207.046.207.255.265.162h1.082l.541-.325.046-.765-.15-.9-.046-.719.172-.267.517-.452.115-.209V328.81l-.472-.8Z"
            transform="translate(-139.013 -93.922)"
            fill={color}
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="0.5"
        />
    );
};
export default RukwaSvg;
