import ArrowRight from "../../../general/stakeholder/_partials/ArrowRight";
import { useEngagementAnalytics } from "../../../../hooks";
import { Link } from "react-router-dom";
import { baseRotes } from "routes/constants";
import Loading from "./loading";

const MostEngagingStakeholder = () => {
	const { isLoading, engagementAnalytics } = useEngagementAnalytics();
	const { stakeholders = [] } = engagementAnalytics;

	return (
		<div
			className={
				"w-[345px] fixed top-[64px] h-full  right-3 space-y-8  pt-10"
			}
		>
			<div
				className={
					"h-[87%]  border-t-[3px] flex flex-col justify-between overflow-hidden border-t-secondary rounded-[4px] bg-white w-[94%]"
				}
				style={{ boxShadow: "0px 3px 6px #00000029" }}
			>
				<div className={"space-y-5 p-5 px-10"}>
					<p className={"text-[#3A453A] tracking-wide font-medium"}>
						Most Engaging Stakeholder
					</p>
					{isLoading ? (
						<Loading />
					) : (
						<div className={""}>
							{stakeholders.slice(0, 11).map((stakeholder) => (
								<Link
									to={`/${stakeholder?.name.toLowerCase()}/overview`}
									target={"_blank"}
								>
									<div
										key={stakeholder?._id}
										className={
											"items-between text-[#838EAB] font-light hover:text-[#466C84] hover:font-normal cursor-pointer group hover:shadow-3xl transition-all ease-in-out rounded-[3px] p-3"
										}
									>
										<p>{stakeholder?.name}</p>
										<div
											className={
												"h-2 w-2 group-hover:hidden rounded-full bg-[#E8529D]"
											}
										></div>
										<div
											className={
												"hidden group-hover:inline"
											}
										>
											<ArrowRight
												color={"#466C84"}
												className={"h-6 w-6"}
											/>
										</div>
									</div>
								</Link>
							))}
						</div>
					)}
				</div>

				<Link
					to={baseRotes.STAKEHOLDER_BASE_ROUTE}
					className={`text-center text-[#A0A8B5] bg-[#F5F7FB] text-sm py-3 ${
						isLoading && "opacity-0 pointer-events-none"
					}`}
				>
					VIEW ALL STAKEHOLDERS
				</Link>
			</div>
		</div>
	);
};
export default MostEngagingStakeholder;
