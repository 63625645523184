import { MenuAlt3Icon } from "@heroicons/react/outline";
import dayjs from "dayjs";

const ProjectBgImage = () => {
    return (
        <div
            className={"bg-green-500 bg-black/30 bg-blend-multiply"}
            style={{
                backgroundImage: "url('/images/dsm.webp')",
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
            }}
        >
            <div className={"h-full w-full  pl-32 pr-10 pt-24 space-y-12"}>
                <div className={"w-full h-full space-y-12"}>
                    <div className={"flex justify-end"}>
                        <MenuAlt3Icon
                            className={"h-6 w-6 cursor-pointer text-white"}
                        />
                    </div>
                    <div className={"flex space-x-3 items-center"}>
                        <div
                            className={
                                "w-6 h-[1.5px] bg-white rounded-full cursor-pointer"
                            }
                        ></div>
                        <div
                            className={
                                "w-6 h-[1.5px] bg-white opacity-50 rounded-full cursor-pointer"
                            }
                        ></div>
                    </div>

                    <div className={"space-y-2 text-white"}>
                        <span className={"date"}>
                            {dayjs().format("MMMM DD, YYYY")}
                        </span>
                        <h1 className={"text-4xl tracking-wider  title"}>
                            Shiriki, Tutatue Pamoja
                        </h1>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default ProjectBgImage;
