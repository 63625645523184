import Select from "../../../../components/form-control/Select";
import { SearchIcon } from "@heroicons/react/outline";
import React from "react";
import { EqualizerIcon } from "../../../../svg";
import FileCard from "./file-card";
import { useState } from "react";
import ImageViewer from "../../../../components/image-viewer/image-viewer";

const RecentFiles = ({ files = [], isLoading }) => {
	const [activeFile, setActiveFile] = useState(null);
	const [openFile, setOpenFile] = useState(false);

	const handleOpenFile = (file) => {
		console.log("ACTIVE:: ", file);
		setActiveFile(file);
		setOpenFile(true);
	};
	return (
		<div className={"space-y-3"}>
			{activeFile && (
				<ImageViewer
					file={activeFile}
					isOpen={openFile}
					setIsOpen={setOpenFile}
				/>
			)}
			<p
				className={
					"text-[#0A0E1C] text-sm py-3 tracking-wide font-semibold"
				}
			>
				RECENT UPLOADED FILES
			</p>
			<div className={"space-y-5"}>
				<div className={"flex items-center justify-between"}>
					<div className={"text-[#202842] hidden"}>
						Show
						<Select
							defaultValue={"3"}
							className={
								"w-20 border-[#DCDFF1] text-[#202842] border-[1px] text-sm py-2 mx-2"
							}
						>
							{[1, 2, 3, 4, 5].map((entries) => (
								<option key={entries + 1} value={entries + 1}>
									{entries + 1}
								</option>
							))}
						</Select>
						entries
					</div>

					<div className={"flex space-x-3 items-center"}>
						<div
							className={
								"w-60 bg-[#F9F9F9AC] border-[1px] border-[#DCDFF1] flex items-center rounded overflow-hidden pl-2 space-x"
							}
						>
							<SearchIcon className={"h-5 w-5 text-black/50"} />
							<input
								type="text"
								placeholder={"Search..."}
								name=""
								id=""
								className={
									"bg-transparent border-none focus:ring-0 placeholder-black/50 text-sm outline-none py-2 font-light w-full"
								}
							/>
						</div>
						<EqualizerIcon className={"hidden"} />
					</div>
				</div>

				<div className={"grid grid-cols-1 2xl:grid-cols-2 gap-4"}>
					{files.map((file, index) => (
						<FileCard
							file={file}
							key={file?._id ?? index}
							onOpenFile={handleOpenFile}
						/>
					))}
				</div>
			</div>
		</div>
	);
};
export default RecentFiles;
