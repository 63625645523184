import { createContext, useContext, useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { Route, Routes, useNavigate } from "react-router-dom";
import useGlobalContext from "hooks/useGlobalContext";
import { queryKeys } from "../../../../constants";
import { getProjectPhases } from "../../../../provider/api";
import PhaseList from "./phases";
import EmptyState from "./empty-state";
import PlanList from "./plan-list";
import Loading from "./plan-list/loading";

const EngagementContext = createContext();
const Engagement = () => {
    const { projectId } = useGlobalContext();
    const navigate = useNavigate();

    //  states
    const [activePhase, setActivePhase] = useState({});
    const [phases, setPhases] = useState([]);
    const [showForm, setShowForm] = useState(false);
    const [activePlan, setActivePlan] = useState({});

    const handleSuccess = async ({ data: _phases = [] }) => {
        await setPhases(_phases);
        await setActivePhase(_phases[0]);
        if (_phases.length) {
            navigate(_phases[0].name.toLowerCase(), { replace: true });
        }
    };

    // send get request to get project phases
    const {
        isLoading: isLoadingPhases,
        refetch: refectPhases,
        isFetched,
    } = useQuery(
        queryKeys.PROJECT_PHASES,
        () => {
            return getProjectPhases(projectId);
        },
        {
            onSuccess: handleSuccess,
            onError: (err) => {
                // TODO: Handle errors in querying project phases
            },
            enabled: false,
        }
    );

    const plansRef = useRef();

    useEffect(() => {
        if (!!projectId && !phases.length) {
            refectPhases();
        }
    }, [projectId]);

    const handleCreate = () => plansRef.current.openPlanForm();

    return (
        <EngagementContext.Provider
            value={{
                activePhase,
                setActivePhase,
                setShowForm,
                showForm,
                isLoadingPhases,
                phases,
                setActivePlan,
                activePlan,
            }}
        >
            <section
                className={
                    "bg-[#F1F6F9]  h-full overflow-y-auto p-8 px-12 w-full"
                }
            >
                <PhaseList onCreate={handleCreate} />
                {isLoadingPhases && <Loading />}
                {isFetched && phases.length < 1 && <EmptyState />}
                <Routes>
                    <Route
                        element={<PlanList ref={plansRef} />}
                        path={":phase/*"}
                    />
                </Routes>
            </section>
        </EngagementContext.Provider>
    );
};
export default Engagement;

export const useEngagementPlans = () => {
    const context = useContext(EngagementContext);
    if (!context)
        throw new Error(
            "useEngagementPlans hook must be used inside engagement plan provider"
        );
    return context;
};
