import {faXmark} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Button from "components/Button";
import EditorJS from "components/editorjs/editor";
import useGrievances from "hooks/useGrievances";
import {useState} from "react";
import {useMutation} from "react-query";
import Modal from "../../../components/Modal";
import {updateGrievance} from "../../../provider/api";
import {CreateMarkup} from "../../../utils";
import {useAuth} from "../../../provider/auth/auth";

const GrievancesDetails = ({isOpen, setIsOpen, grievance, onSuccess}) => {
    const {updateGrievancesCache} = useGrievances();
    const {authUser} = useAuth();
    const {mutate: _updateGrievance, isLoading} = useMutation(
        updateGrievance,
        {
            onSuccess: ({data: grievance}) => {
                updateGrievancesCache(grievance);
                setIsOpen(false);
            },
            onError: (err) => {
                console.log(err);
            },
        }
    );

    const [comment, setComment] = useState(null);
    const getStatus = (statusId) => {
        if (statusId === 0) {
            return (
                <span class="bg-orange-200 text-orange-700 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">
					Received
				</span>
            );
        } else if (statusId === 1) {
            return (
                <span class="bg-gray-300 text-gray-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">
					Under Investigation
				</span>
            );
        } else if (statusId === 2) {
            return (
                <span class="bg-blue-200 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">
					Resolution in progress
				</span>
            );
        } else if (statusId === 3) {
            return (
                <span class="bg-green-200 text-green-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">
					Resolved
				</span>
            );
        } else if (statusId === 5) {
            return (
                <span class="bg-red-100 text-red-600 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">
					Reopened
				</span>
            );
        } else {
            return (
                <span class="bg-gray-100 text-gray-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">
					Other
				</span>
            );
        }
    };

    const getRiskLevel = (level) => {
        if (level === "Low") {
            return (
                <span class="bg-green-100 text-green-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">
					Low
				</span>
            );
        } else if (level === "Medium") {
            return (
                <span class="bg-[#fef9c3] text-[#854d0e] text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">
					Medium
				</span>
            );
        } else if (level === "High") {
            return (
                <span class="bg-red-100 text-red-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">
					High
				</span>
            );
        }
    };

    const handleEditorChange = (cleanData) => {
        setComment(cleanData);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const payload = {
            comment: JSON.stringify(comment),
            id: grievance?._id,
            investigatedOn: new Date(),
        };

        console.log("PAYLOAD:: ", payload);
        _updateGrievance(payload);
    };

    return (
        <Modal isOpen={isOpen} onClose={setIsOpen}>
            <div className="w-full max-w-xl overflow-hidden bg-white rounded-lg">
                <header className="overflow-y-scroll bg-white no-scrollbar">
                    <div className="flex justify-between px-8 py-3 bg-gray-300">
                        <p className="font-semibold text-[#002F4F]">
                            Grievance Details
                        </p>

                        <button
                            onClick={() => setIsOpen(false)}
                            className="-mr-4 border-2 focus:ring-0 ring-0 border-[#192B40]/50 w-6 h-6 bg-[#628799] text-white rounded-full grid place-items-center"
                        >
                            <FontAwesomeIcon
                                color="FFFFFF"
                                icon={faXmark}
                                className={"h-3 w-4"}
                            />
                        </button>
                    </div>
                </header>

                {/* details */}
                <div className="px-8 py-5">
                    <div>
                        <p
                            className={
                                "text-2xl text-[#303841] pr-20 leading-relaxed font-medium"
                            }
                        >
                            {grievance?.title}
                        </p>
                    </div>
                    <div
                        className={
                            " pb-4 flex items-center space-x-6 text-[13px] font-light"
                        }
                    >
                        <div className={"text-[#B0B4BA] space-y-3"}>
                            <p>Complainer's Name:</p>
                            <p>Complainer's Email:</p>
                            <p>Complainer's Tel:</p>
                            <p>Complaint Received:</p>
                            <p>Department:</p>
                            <p>Status:</p>
                            <p>Nature of complaint:</p>
                            <p>Risk level:</p>
                        </div>

                        <div className={"space-y-3 font-normal"}>
                            <p className={"font-normal"}>
                                {grievance?.complainer?.fullName}
                            </p>
                            <p className={"font-normal"}>
                                {grievance?.complainer?.email || "N/A"}
                            </p>
                            <p className={"font-normal"}>
                                {grievance?.complainer?.phoneNumber || "N/A"}
                            </p>

                            <p
                                className={
                                    "flex items-center space-x-3 text-[#51BF82]"
                                }
                            >
                                {/* CalenderIcon className={"h-4 w-4"} color={"#51BF82"} /> */}
                                <span>{grievance?.dateCreated}</span>
                            </p>

                            <p className={"text-dark "}>
                                {grievance?.department?.name}
                            </p>

                            <p>{getStatus(grievance?.status)} </p>

                            <p>{grievance?.nature?.title}</p>

                            <p>{getRiskLevel(grievance?.projectRisk)}</p>
                        </div>
                    </div>
                    <div
                        className={
                            " pb-2 text-[13px] font-light pt-3 space-y-1"
                        }
                    >
                        <p className={"text-dark font-medium"}>Descriptions:</p>

                        <p
                            dangerouslySetInnerHTML={CreateMarkup(
                                grievance?.description ??
                                '"No description provided"'
                            )}
                            className={"text-dark leading-relaxed max-h-32 overflow-y-scroll opacity-90"}
                        ></p>
                    </div>

                    {grievance?.comment ? (
                        <div className="space-y-1">
                            <p className={"text-dark font-medium text-[13px]"}>
                                Investigation comment:
                            </p>
                            <div
                                className={
                                    "border-2 border-secondary rounded-md p-1.5"
                                }
                            >
                                <div
                                    className={
                                        "bg-[#f7f7f7]  text-dark text-sm p-3 rounded"
                                    }
                                >
                                    <p
                                        dangerouslySetInnerHTML={CreateMarkup(
                                            grievance?.comment ?? ""
                                        )}
                                    ></p>
                                </div>
                            </div>
                        </div>
                    ) : null}

                    {/* Todo: show field below if this grievance in not investigated and assigned investigator is current user */}
                    {!grievance?.investigatedOn && grievance?.investigator === authUser._id ? (
                        <div>
                            <form
                                className="space-y-2"
                                onSubmit={handleSubmit}
                                action=""
                            >
                                <div>
                                    <label
                                        htmlFor="comment"
                                        className={
                                            "text-dark text-sm font-medium"
                                        }
                                    >
                                        Leave your comment below;
                                    </label>
                                    <EditorJS onChange={handleEditorChange}/>
                                </div>
                                <Button
                                    loading={isLoading}
                                    type={"submit"}
                                    className="py-3 text-sm"
                                >
                                    SUBMIT
                                </Button>
                            </form>
                        </div>
                    ) : null}
                </div>
            </div>
        </Modal>
    );
};
export default GrievancesDetails;
