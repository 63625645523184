import { useContext, useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { ArrowRightIcon } from "@heroicons/react/outline";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

import EditorJS from "components/editorjs/editor";
import { FormContext } from "../index";
import UnstableSelect from "components/unstable/select";
import UnstableInput from "components/unstable/input";
import { stakeholderValidation } from "../../../../../../forms/validation";
import { useMutation } from "react-query";
import {
    getValueFromLocalStorage,
    saveMultiValueToLocalStorage,
} from "hooks/useLocalStorageState";
import { isEmptyDescription } from "utils";
import { updateStakeholderDetails } from "provider/api";
import StakeholderType from "./stakeholder-type";
import Checkbox from "../../../../../../components/form-control/checkbox";
import getStakeholderFields from "./fields";
import StakeholderMap from "components/stakeholder-map";
import {
    cacheActions,
    localStorageKeys,
    queryKeys,
} from "../../../../../../constants";
import FormHeader from "../../../../../../components/form-header/form-header";
import useUpdateCache from "../../../../../../hooks/useUpdateCache";

const BasicDetails = () => {
    const { stakeholderFrom: stakeholderLSKeys } = localStorageKeys;
    const [validationSchema, setValidationSchema] = useState(
        stakeholderValidation.individual
    );

    const updateCache = useUpdateCache();

    // States
    const initialBasicDetails = getValueFromLocalStorage(
        stakeholderLSKeys.BASIC_DETAILS,
        {}
    );

    const [basicDetails, setBasicDetails] = useState(initialBasicDetails);
    const [stakeholderType, setStakeholderType] = useState(
        () => basicDetails?.type ?? "Individual"
    );
    const [description, setDescription] = useState(
        basicDetails.description ?? {}
    );

    const {
        handleSubmit,
        register,
        setValue,
        watch,
        formState: { errors, isSubmitted },
    } = useForm({
        resolver: yupResolver(validationSchema),
    });

    const scope = watch("scope", initialBasicDetails.scope ?? "");

    const { basicFields, locationFields } = getStakeholderFields(
        basicDetails,
        errors,
        register,
        stakeholderType
    );

    const [currentTab, setCurrentTab] = useContext(FormContext);
    const isUpdatingStakeholder = getValueFromLocalStorage(
        stakeholderLSKeys.IS_UPDATING_DETAILS,
        false
    );

    const updateStakeholderCache = async ({ data: stakeholder }) => {
        await updateCache(
            queryKeys.STAKEHOLDERS,
            stakeholder,
            cacheActions.UPDATE
        );
    };

    useEffect(() => {
        if (stakeholderType === "Individual") {
            return setValidationSchema(stakeholderValidation.individual);
        }
        if (stakeholderType === "Organizational") {
            return setValidationSchema(stakeholderValidation.organizational);
        }
        if (stakeholderType === "Community") {
            return setValidationSchema(stakeholderValidation.community);
        }
    }, [stakeholderType]);

    const [location, setLocation] = useState(
        basicDetails.location ? basicDetails.location : ""
    );

    const { mutate: updateStakeholder } = useMutation(
        "update-list-details",
        updateStakeholderDetails,
        {
            onSuccess: updateStakeholderCache,
            onError: (error) => {
                toast.error("Oops Something goes wrong, please try again");
            },
        }
    );

    const handleEditorChange = (cleanData) => {
        setDescription(cleanData);
    };

    const saveStakeholderDetailsAndAdvanceStep = (stakeholderDetails) => {
        const {
            hasContactPerson: hasPerson,
            hasRelationshipHolder: hasRelation,
        } = stakeholderDetails;

        let nextStep;

        // update current step
        if (hasPerson) {
            nextStep = currentTab + 1;
        } else if (hasRelation) {
            nextStep = currentTab + 2;
        } else {
            nextStep = currentTab + 3;
        }
        saveMultiValueToLocalStorage({
            [stakeholderLSKeys.CURRENT_STE]: nextStep,
            [stakeholderLSKeys.BASIC_DETAILS]: stakeholderDetails,
        });
        setCurrentTab(nextStep);
        toast.success("Data has been saved successfully...");
    };

    const updateStakeholderWithLocationAndAddressInfo = (formData) => {
        const {
            region,
            district,
            ward,
            street,
            postalCode,
            location,
            description,
            ...rest
        } = formData;
        const { properties, geometry } = location;
        return updateStakeholder({
            ...basicDetails,
            ...rest,
            location: {
                name: properties.display_name,
                latitude: geometry.coordinates[1],
                longitude: geometry.coordinates[0],
            },

            address: {
                street,
                ward,
                district,
                region,
                postalCode,
                country: properties.address.country,
            },
            description: JSON.stringify(description),
        });
    };
    const submitBasicDetails = async (formData) => {
        if (isEmptyDescription(description)) return;
        const payload = {
            ...basicDetails,
            ...formData,
            type: stakeholderType,
            description,
            location,
        };

        if (isUpdatingStakeholder) {
            await updateStakeholderWithLocationAndAddressInfo(payload);
        }
        saveStakeholderDetailsAndAdvanceStep(payload);
    };

    const handleCheckbox = (checked, type = "") => {
        if (type === "contactPerson") {
            setBasicDetails({
                ...basicDetails,
                hasContactPerson: checked,
            });
        } else {
            setBasicDetails({
                ...basicDetails,
                hasRelationshipHolder: checked,
            });
        }
    };

    return (
        <div className={"px-10 space-y-12 pb-16"}>
            <FormHeader
                title={"About The Stakeholder"}
                description={
                    "Basic details about list such as name, description and others"
                }
            />
            <form
                onSubmit={handleSubmit(submitBasicDetails)}
                className={"space-y-10"}
            >
                {/* Type of Stakeholder */}
                <StakeholderType
                    defaultType={basicDetails.type}
                    onChange={setStakeholderType}
                />

                {/* Mark if list has contact person or relationship holder */}
                <div className={"flex gap-10"}>
                    <Checkbox
                        label={"Has Contact Person"}
                        onChange={(checked) =>
                            handleCheckbox(checked, "contactPerson")
                        }
                        defaultChecked={basicDetails.hasContactPerson}
                    />
                    {scope === "External" ? (
                        <Checkbox
                            label={"Has RelationshipHolder"}
                            onChange={(checked) => handleCheckbox(checked)}
                            defaultChecked={basicDetails.hasRelationshipHolder}
                        />
                    ) : null}
                </div>

                <div className={"space-y-5"}>
                    {basicFields.map((props) => {
                        if (props?.options) {
                            return <UnstableSelect props={props} />;
                        }
                        return <UnstableInput props={props} />;
                    })}

                    {/* Description */}
                    <div className={" space-y-2"}>
                        <p className={"text-primary/80 tracking-wider"}>
                            Description
                        </p>
                        <EditorJS
                            uniqueId={"list-description"}
                            isError={
                                isSubmitted && isEmptyDescription(description)
                            }
                            onChange={handleEditorChange}
                            defaultValue={description}
                        />
                    </div>

                    <StakeholderMap
                        setValue={setValue}
                        defaultLocation={location}
                        fields={locationFields}
                        onChange={setLocation}
                    />
                </div>

                <div className={" flex justify-end items-center"}>
                    <button
                        type="submit"
                        className={
                            "bg-accent px-6 py-3 text-xs rounded-[2px] text-white flex items-center space-x-2"
                        }
                    >
                        <p>{isUpdatingStakeholder ? "SAVE CHANGES" : "NEXT"}</p>
                        <ArrowRightIcon className={"h-4 w-4"} />
                    </button>
                </div>
            </form>
        </div>
    );
};
export default BasicDetails;
