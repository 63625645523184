import dayjs from "dayjs";
import {CreateMarkup} from "../../../../../../utils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPenAlt, faTrashCan} from "@fortawesome/free-solid-svg-icons";
import {CalenderIcon} from "../../../../../../svg/calender";
import {useCommitmentContext} from "./commitement";

export const CommitmentCard = ({commitment}, ...props) => {
    const formatDate = (date) => {
        return dayjs(date).format('DD MMMM YYYY');
    }

    const {setActiveCommitment, setIsOpen, setCommitments, commitments} = useCommitmentContext();

    const updateCommitment = () => {
        setActiveCommitment(commitment);
        setIsOpen(true);
    }

    const deleteCommitment = () => {
        setCommitments([...commitments.filter((commitment_) => {
            return commitment_.id !== commitment.id
        })]);
    }

    return (
        <div {...props} className={'p-5 space-y-4  rounded-[4px] border-[1px] border-[#E4E4E4]'}>

            <div className={'items-between'}>
                <div className={'flex items-center '}>
                    <div className={'flex items-center test-sm space-x-2'}>
                        <CalenderIcon className={'h-4'} color={'#AAB6C5'}/>
                        <p className={'text-[#595959] '}>{formatDate(commitment?.startDate)}</p>
                    </div>

                </div>

                <div className={'space-x-2 flex items-center '}>
                    <button onClick={updateCommitment}
                            className={'h-6 w-6 rounded-full hover:bg-gray-200 text-gray-600 center bg-gray-100'}>
                        <FontAwesomeIcon icon={faPenAlt} className={'h-3 w-3'}/>
                    </button>
                    <button onClick={deleteCommitment}
                            className={'h-6 w-6 rounded-full hover:bg-gray-200 text-gray-600 center bg-gray-100'}>
                        <FontAwesomeIcon icon={faTrashCan} className={'h-3 w-3'}/>
                    </button>
                </div>
            </div>

            <p className={'text-[14px] text-dark font-semibold'}>{commitment.title}</p>

            {/* description */}
            <div className={'text-[13px]'}>
                <p className={'text-secondary'}>Descriptions:</p>
                <div className={' h-24'}>
                    <p
                        dangerouslySetInnerHTML={CreateMarkup(commitment?.description)}
                        className={'text-[#202842] line-clamp-4 leading-relaxed'}>
                    </p>
                </div>
            </div>
        </div>
    )
}