import PieChartIllustrator from "./pie-chart-illustrator";

const EmptyState = () => {
    return (
        <div className="h-[88%] -space-y-7 p-4 center flex-col">
            <div className={"h-44 w-44"}>
                <PieChartIllustrator />
            </div>
            <div className={"space-y-2"}>
                <p className={"text-center text-gray-400"}>
                    Start tracking engagement activities to see their progress
                    on this chart over time.
                </p>
                {/*<Link to={"/"} className={"text-accent mx-auto"}>*/}
                {/*    Create Event*/}
                {/*</Link>*/}
            </div>
        </div>
    );
};
export default EmptyState;
