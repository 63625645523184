import { Link } from "react-router-dom";
import { slugify } from "utils";
import { baseRotes } from "routes/constants";

const StakeholderCard = ({ key, stakeholder }) => {
    const getStakeholderPhone = () => {
        if (stakeholder?.phoneNumber) {
            if (parseInt(stakeholder?.phoneNumber[0]) === 0) {
                return `+255${stakeholder?.phoneNumber.slice(1)}`;
            }
            return `+255${stakeholder?.phoneNumber}`;
        }
    };
    return (
        <div
            key={key}
            className={
                "flex-shrink-0 bg-white py-6 w-56 px-6 space-y-5  flex flex-col " +
                "items-center rounded-[4px] border-[1px] border-[#AAB6C4]"
            }
        >
            <div className={" space-y-2"}>
                <p
                    className={
                        "text-center font-medium text-[15px] tracking-wide capitalize text-[#002F4F]"
                    }
                >
                    {stakeholder.name}
                </p>
                <div className={" font-light space-y-1 text-center"}>
                    <p className={"text-[#2CD889] text-[13px]"}>
                        {stakeholder.role}
                    </p>
                    <p className={"text-[#A6B1C2] text-xs"}>
                        {getStakeholderPhone()}
                    </p>
                    <p className={"text-[#A6B1C2] text-xs"}>
                        {" "}
                        {stakeholder.email ? stakeholder.email : "	"}
                    </p>
                </div>
            </div>

            <div>
                <Link
                    to={`${baseRotes.STAKEHOLDER_BASE_ROUTE}/${slugify(
                        stakeholder.name
                    )}/overview`}
                    target="_blank"
                    className={
                        "text-[12px] px-6 " +
                        "hover:bg-[#56BE84]/80 enable-transition font-light " +
                        "hover:text-white rounded-full text-[#646D82]" +
                        " tracking-wider py-2 border-[1.5px] border-[#56BE84]"
                    }
                >
                    VIEW PROFILE
                </Link>
            </div>
        </div>
    );
};
export default StakeholderCard;
