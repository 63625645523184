import Modal from "components/Modal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/free-solid-svg-icons";
import React, {useEffect, useState} from "react";
import Input from "components/form-control/Input";
import Textarea from "components/form-control/textarea";
import Button from "components/Button";
import useGlobalContext from "hooks/useGlobalContext";
import {useMutation} from "react-query";
import {createSubproject, updateSubproject} from "provider/api";
import useUpdateCache from "hooks/useUpdateCache";
import {isEmptyObject} from "utils";

const SubprojectForm = ({showForm, setShowForm, activeSubproject, setActiveSubproject}) => {
    const _isUpdatingSubproject = !isEmptyObject(activeSubproject);
    const [name, setName] = useState(activeSubproject?.name ?? "");
    const [description, setDescription] = useState(activeSubproject?.description ?? "");
    const [isSubmitted, setIsSubmitted] = useState(false);
    const {projectId} = useGlobalContext();
    const updateCache = useUpdateCache();

    // send post request to create subproject;
    const {isLoading, mutate: _createSubject} = useMutation('create-subproject', createSubproject, {
        onSuccess: ({data}) => {
            setIsSubmitted(false);
            updateCache(['subprojects', projectId], data, 'creation')
            setShowForm(false)
        }
    })

    // send put request to update subproject;
    const {isLoading: isUpdating, mutate: _updateSubproject} = useMutation('create-subproject', updateSubproject, {
        onSuccess: ({data}) => {
            setIsSubmitted(false);
            updateCache(['subprojects', projectId], data, 'update')
            setShowForm(false)
        }
    })

    useEffect(() => {
        if (_isUpdatingSubproject) {
            setDescription(activeSubproject.description)
            setName(activeSubproject.name);
        } else {
            setDescription("")
            setName("");
        }
    }, [activeSubproject]);


    const handleSubmit = (e) => {
        e.preventDefault();
        setIsSubmitted(true)

        if (name && description) {
            const payload = {
                parentProject: projectId,
                name, description
            }
            if (_isUpdatingSubproject) {
                payload['subprojectId'] = activeSubproject?._id;
                return _updateSubproject(payload)
            }
            return _createSubject(payload)
        }
    }

    const handleClose = () => {
        setShowForm(false)
        setActiveSubproject({})
    }

    return (
        <Modal onClose={handleClose} isOpen={showForm}>
            <div className={"w-2/6 bg-white rounded-lg overflow-hidden"}>
                <header className="flex justify-between px-5 py-3 bg-gray-300 rounded-t-md">
                    <p className="font-semibold text-[#002F4F]">
                        {_isUpdatingSubproject ? 'Update Subproject Details' : 'Create New Subproject'}
                    </p>

                    <button
                        onClick={handleClose}
                        className="-mr-1 border-2 focus:ring-0 ring-0 border-[#192B40]/50 w-6 h-6 bg-[#628799] text-white rounded-full grid place-items-center"
                    >
                        <FontAwesomeIcon color="FFFFFF" icon={faXmark}/>
                    </button>
                </header>
                <form onSubmit={handleSubmit} action="" className={'p-5 space-y-4'}>
                    {/* subproject name */}
                    <div className={'space-y-1'}>
                        <label htmlFor="name" className={'block mb-2 text-sm font-medium text-[#002F4F]'}>Subproject
                            Name
                        </label>
                        <Input
                            value={name}
                            onChange={({target}) => setName(target.value)}
                            isError={isSubmitted && !name}
                            name={'name'} className={'text-sm'}
                        />
                    </div>

                    {/* subproject description */}
                    <div className={'space-y-1'}>
                        <label htmlFor="description"
                               className={'block mb-2 text-sm font-medium text-[#002F4F]'}>Subproject
                            Description</label>
                        <Textarea
                            value={description}
                            onChange={({target}) => setDescription(target.value)}
                            isError={isSubmitted && !description}
                            name={'description'} className={'text-sm'}
                        />
                    </div>

                    <Button block={true}
                            loading={isLoading || isUpdating}
                            type="submit"
                            className={
                                "flex items-center space-x-2 text-sm"
                            }>
                        {_isUpdatingSubproject ? 'UPDATE' : 'CREATE'}
                    </Button>
                </form>
            </div>

        </Modal>
    )
}
export default SubprojectForm;
