import Label from "components/form-control/Label";
import { useMutation } from "react-query";
import ActionButtons from "components/form-control/action-buttons";
import EditorJS from "components/editorjs/editor";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { useState } from "react";
import {
    getValueFromLocalStorage,
    saveValueToLocalStorage,
} from "../../../../hooks/useLocalStorageState";
import { slugify } from "utils";
import { updatePIUDetails } from "provider/api";
import { localStorageKeys } from "../../../../constants";

const PIUMissionAndVision = () => {
    const { setCurrStep, toast, isUpdating } = useOutletContext();
    // eslint-disable-next-line no-undef
    const { piuForm: piuLSKeys } = localStorageKeys;
    const piuOperationDetails = getValueFromLocalStorage(
        piuLSKeys.MISSION_AND_VISION
    );
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [vision, setVision] = useState(piuOperationDetails?.vision ?? "");
    const [mission, setMission] = useState(piuOperationDetails?.mission ?? "");
    const { piuRegName } = useParams();
    const navigate = useNavigate();

    const handleMissionChange = (cleanData) => {
        setMission(cleanData);
    };

    const handleVisionChange = (cleanData) => {
        setVision(cleanData);
    };

    const { mutate: _updatePIUDetails, isLoading } = useMutation(
        "update-piu-" + piuOperationDetails?._id,
        updatePIUDetails,
        {
            onSuccess: ({ data }) => {
                toast.success("Information has successfully been updated");
                navigate(`/update-piu/${slugify(piuRegName)}/contact-person`);
            },
        }
    );

    const handleSubmit = (e) => {
        e.preventDefault();
        if (isUpdating) {
            const payload = {
                ...piuOperationDetails,
                mission: JSON.stringify(mission),
                vision: JSON.stringify(vision),
            };
            saveValueToLocalStorage("piuOperationDetails", {
                ...piuOperationDetails,
                mission,
                vision,
            });
            _updatePIUDetails(payload);
        } else {
            setIsSubmitted(true);
            setCurrStep(3);
            if (!mission || !mission) return;

            saveValueToLocalStorage(piuLSKeys.MISSION_AND_VISION, {
                ...piuOperationDetails,
                mission,
                vision,
            });
            saveValueToLocalStorage("piuCurrStep", 3);
            toast.success("Data saved successfully...");
            navigate(
                `/${
                    isUpdating
                        ? `update-piu/${slugify(piuRegName)}`
                        : "register-piu"
                }/contact-person`
            );
        }
    };

    return (
        <div className={"px-10 w-4/5 mx-auto  space-y-12 pb-16"}>
            <div className={"space-y-2"}>
                <p className={"text-primary text-xl tracking-wider"}>
                    Mission and Vision
                </p>
                <p
                    className={
                        "text-lg text-[#B0B2B4] tracking-wider font-light"
                    }
                >
                    Basic details about PIU such as name, description and
                    others.
                </p>
            </div>
            <form
                action="settings/pius/form"
                onSubmit={handleSubmit}
                className={"space-y-5"}
            >
                {/* mission */}
                <div className={"pr-14 space-y-2"}>
                    <Label text={"Mission"} htmlFor={"mission"} />
                    <EditorJS
                        name={"mission"}
                        uniqueId={"mission-text-area"}
                        defaultValue={mission}
                        isError={isSubmitted && !mission}
                        onChange={handleMissionChange}
                    />
                </div>

                {/* mission */}
                <div className={"pr-14 space-y-2"}>
                    <Label text={"Vision"} htmlFor={"vision"} />
                    <EditorJS
                        name={"vision"}
                        uniqueId={"vision-text-area"}
                        defaultValue={vision}
                        onChange={handleVisionChange}
                        isError={isSubmitted && !vision}
                    />
                </div>

                {/* action buttons */}
                <ActionButtons
                    className="pr-14"
                    isLoading={isLoading}
                    prevLink={`/${
                        isUpdating
                            ? `update-piu/${slugify(piuRegName)}`
                            : "register-piu"
                    }`}
                    nextBtnLabel={isUpdating ? "SAVE & CONTINUE" : "NEXT"}
                    onPrevClick={() => {
                        setCurrStep(1);
                        saveValueToLocalStorage("piuCurrStep", 1);
                    }}
                />
            </form>
        </div>
    );
};
export default PIUMissionAndVision;
