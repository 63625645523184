import { CommitmentContext } from "../../../context";
import { useQuery } from "react-query";
import { getCommitments } from "../../api";
import { useEffect, useState } from "react";
import useGlobalContext from "hooks/useGlobalContext";
import { queryKeys } from "../../../constants";

const CommitmentProvider = ({ children }) => {
    const [commitments, setCommitments] = useState([]);
    const [activeCommitment, setActiveCommitment] = useState({});
    const [filteredCommitments, setFilteredCommitments] = useState([]);
    const [activeStakeholder, setActiveStakeholder] = useState({});
    const [showForm, setShowForm] = useState(false);
    const [showDetails, setShowDetails] = useState(false);
    const { projectId } = useGlobalContext();

    // send get-request to get stakeholders-commitments
    const {
        isLoading,
        refetch: refetchCommitments,
        isFetched,
    } = useQuery(queryKeys.COMMITMENTS, () => getCommitments(projectId), {
        onSuccess: ({ data: commitments }) => {
            setFilteredCommitments(commitments);
            setCommitments(commitments);
        },
        enabled: false,
    });

    const handleFilter = (keyword) => {
        return setFilteredCommitments(
            commitments.filter((item) => {
                const { stakeholder, commitments: data } = item;

                return (
                    stakeholder?.name?.toLowerCase().includes(keyword) ||
                    data.find((commitment) =>
                        commitment.title.toLowerCase().includes(keyword)
                    )
                );
            })
        );
    };

    const filterByStatus = (status) => {
        if (status === "all") {
            return setFilteredCommitments(commitments);
        }

        const result = commitments.map((items) => {
            const { stakeholder, commitments: data } = items;
            return {
                stakeholder,
                commitments: data.filter((commitment) => {
                    return commitment?.status === parseInt(status);
                }),
            };
        });

        return setFilteredCommitments(
            result.filter((data) => {
                return data.commitments.length;
            })
        );
    };

    // refetch list-commitment whenever project-id is changed
    useEffect(() => {
        refetchCommitments();
    }, [projectId]);
    return (
        <CommitmentContext.Provider
            value={{
                stakeholderCommitments: commitments,
                isLoading,
                isFetched,
                commitments,
                activeCommitment,
                filteredCommitments,
                activeStakeholder,
                setActiveStakeholder,
                setShowForm,
                showForm,
                showDetails,
                setShowDetails,
                setActiveCommitment,
                filterByStatus,
                refetchCommitments,
                handleFilter,
            }}
        >
            {children}
        </CommitmentContext.Provider>
    );
};

export default CommitmentProvider;
