import PieChart, { PieChartLabel } from "./pie-chart";
import useGrievanceAnalytics from "../../../../hooks/useGrievanceAnalytics";

const RiskLevelSummary = () => {
	const { grievanceAnalytic, isLoading } = useGrievanceAnalytics();
	if (isLoading)
		return (
			<div className="bg-white p-5 rounded w-[55%]">
				<div className="w-56 h-3.5 bg-secondary/[.23] rounded-full "></div>
				<div className="flex h-full gap-4">
					<div className="w-full h-full center">
						<div className="w-40 center h-40 bg-secondary/[.23] animate-pulse rounded-full">
							<div className="w-5 h-5 bg-white rounded-full"></div>
						</div>
					</div>
					<div className="flex-col h-full space-y-6 shrink-0 center">
						{[...Array(3)].map((item, index) => (
							<div className="flex space-x-4">
								<div className="h-3 w-3  bg-secondary/[.26] animate-pulse rounded-full"></div>
								<div className="space-y-1">
									<div className="bg-secondary/[.23] h-2 w-10 animate-pulse rounded-full"></div>
									<div className="bg-secondary/[.3] h-2 w-20 animate-pulse rounded-full"></div>
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
		);
	const { byRiskLevel: riskLevels = [] } = grievanceAnalytic;

	const highRiskLevel = riskLevels.find((riskLevel) => {
		return riskLevel.risk === "High";
	});

	const mediumRiskLevel = riskLevels.find((riskLevel) => {
		return riskLevel.risk === "Medium";
	});

	const lowerRiskLevel = riskLevels.find((riskLevel) => {
		return riskLevel.risk === "Low";
	});

	const statistics = {
		high: highRiskLevel?.report?.total ?? 0,
		medium: mediumRiskLevel?.report?.total ?? 0,
		low: lowerRiskLevel?.report?.total ?? 0,
	};

	return (
		<div
			className={
				"bg-white rounded-t self-stretch p-5 px-8 w-[55%] border-t-[3px] h-80 border-accent"
			}
		>
			<p className={"text-[#31394C] text-[15px] font-medium"}>
				Reported grievances grouped by risk level
			</p>
			<div className={"flex items-center h-[95%] space-x-16"}>
				<div className={"h-56 w-[60%]"}>
					<PieChart statistics={statistics} />
				</div>

				<div className={"pr-3 space-y-3"}>
					<PieChartLabel
						color={"#AAB6C5"}
						label={"Low risk"}
						total={statistics.low}
					/>
					<PieChartLabel
						color={"#838EAB"}
						label={"Medium risk"}
						total={statistics.medium}
					/>
					<PieChartLabel
						color={"#628799"}
						label={"high risk"}
						total={statistics.high}
					/>
				</div>
			</div>
		</div>
	);
};
export default RiskLevelSummary;
