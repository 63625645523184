import {Link} from "react-router-dom";
import {ArrowRight} from "svg";
import {slugify} from "utils";
import {saveValueToLocalStorage} from "../../../hooks/useLocalStorageState";

const PIUListItem = ({piu}) => {
    const handleClick = () => {
        saveValueToLocalStorage('activePIU', piu)
    };

    return (
        <div className={'flex items-center'}>
            <div
                className={'h-10 w-10 rounded-full -mr-4  z-10 shrink-0 relative bg-primary  '}>
            </div>
            <div
                className={
                    `grid grid-cols-7 
                    relative
                    w-full
                    rounded-md border-[1.5px] 
                    bg-white border-[#1A9EE2] 
                    text-sm text-[#000000] 
                    hover:bg-secondary/5`
                }
            >

                <div className={`absolute inset-0 pointer-events-none rounded -left-[1.29px] overflow-hidden`}>
                    <div className={`relative  w-full h-full
                        after:absolute 
                        after:inset-0 
                        overflow-hidden
                        after:w-12
                        after:rounded-full
                        after:bg-[#F7F9FA]
                        after:border-[1.5px]
                        after:border-accent
                        after:-left-7`
                    }>

                    </div>
                </div>
                <div className="col-span-2 px-5 pl-8 py-3 space-y-1 text-left">
                    <p className="font-medium truncate ">{piu?.registeredName} </p>
                    {/*<p className="text-xs uppercase">{piu?.abbreviation}</p>*/}
                </div>
                <div className="center">{piu?.yearFound}</div>
                <div className="flex center">{piu?.email}</div>
                <div className="flex center">{piu?.address?.region}</div>
                <div className="flex center">{piu.registrationNumber}</div>
                <div className="center">
                    <Link
                        to={`/piu-details/${slugify(piu.registeredName)}`}
                        onClick={handleClick}
                        className="flex items-center justify-center py-0 space-x-2 border-b text-accent border-b-transparent hover:border-b-accent"
                    >
                        <p>view details</p>
                        <ArrowRight/>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default PIUListItem;
