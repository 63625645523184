import FolderIcon from "../../../../svg";
import { useState } from "react";
import useClickOutside from "../../../../hooks/useClickOutside";
import { splitOnUpperCase } from "../../../../utils";

const Folder = (
	{ folder, onOpenFolder: handleOpen, showTitle = false },
	...props
) => {
	const [showFullName, setShowFullName] = useState(false);

	const handleDoubleClick = () => {
		handleOpen(folder);
	};

	const nameRef = useClickOutside(() => setShowFullName(false));

	const handleClick = () => {
		setShowFullName(!showFullName);
	};

	return (
		<div
			onClick={handleClick}
			onDoubleClick={handleDoubleClick}
			{...props}
			className={
				"bg-[#F4F9FF] hover:bg-[#ebf4ff] rounded-md cursor-pointer space-y-4 center flex-col p-4"
			}
		>
			<FolderIcon />
			<div className={"relative text-sm"}>
				<p
					className={`text-sm opacity-100 tracking-wide line-clamp-2
                    text-center capitalize  rounded-[10px]`}
				>
					{splitOnUpperCase(folder.name)}
				</p>
				{showFullName && (
					<p
						ref={nameRef}
						className={
							"absolute top-0 w-full z-40 text-center rounded text-white p-1 bg-accent"
						}
					>
						{`${splitOnUpperCase(folder.name)}-${
							folder.formattedDate
						}`}
					</p>
				)}
			</div>
		</div>
	);
};

export default Folder;
