import { AuthProvider } from "provider/auth/auth";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Login from "../pages/general/Login";
import RequireAuth from "../auth/RequireAuth";
import StakeholderRegForm from "../pages/general/stakeholder/form/stakeholder";
import { permissions, RequirePermission } from "provider/permission";
import AuthLayout from "../layouts/Auth/AuthLayout";
import CommitmentPage from "../pages/general/commitments";
import StakeholderProfileRoutes from "./stakeholder-profile";
import AccessDenied from "../errors/403";
import AnalyticsRoutes from "./analytics";
import SettingsRoutes from "./settings";
import ProjectFilesRoutes from "./project-files";
import ProjectImagesRoutes from "./project-images";
import EventRegistrationRoutes from "./event-registration";
import EngagementRoutes from "./engagement";
import GrievanceRoutes from "./grievance";
import Home from "pages";
import BudgetApprove from "pages/general/budget-approve";
import EventProvider from "../provider/general/event";
import { baseRotes } from "./constants";
import PIURegistrationFormLayout from "pages/settings/pius/form";
import BasicDetails from "../pages/settings/pius/form/basic-details";
import PIUMissionAndVision from "../pages/settings/pius/form/mission-and-vision";
import PIUContactPerson from "../pages/settings/pius/form/piu-contact-person";
import PIUBranches from "../pages/settings/pius/form/piu-branches";
import PIUDetails from "pages/settings/pius/details";
import GetStarted from "../pages/get-started/get-started";
import SelectSubproject from "../pages/get-started/select-suprojects";
import CommitmentProvider from "../provider/general/commitments";

export const NotFound = () => {
    return <p>page not found</p>;
};

const Router = () => {
    return (
        <BrowserRouter>
            <AuthProvider>
                <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route path="/change-password" element={<Login />} />

                    <Route
                        path="/*"
                        element={
                            <RequireAuth>
                                <Routes>
                                    <Route
                                        exact
                                        path="register-stakeholder"
                                        element={<StakeholderRegForm />}
                                    />
                                    <Route
                                        path="/get-started"
                                        element={<GetStarted />}
                                    />
                                    <Route
                                        path="/select-subprojects"
                                        element={<SelectSubproject />}
                                    />
                                    <Route
                                        element={<PIURegistrationFormLayout />}
                                    >
                                        <Route
                                            path="register-piu/*"
                                            element={
                                                <Routes>
                                                    <Route
                                                        index
                                                        element={
                                                            <BasicDetails />
                                                        }
                                                    />
                                                    <Route
                                                        path={
                                                            "mission-and-vision"
                                                        }
                                                        element={
                                                            <PIUMissionAndVision />
                                                        }
                                                    />
                                                    <Route
                                                        path={"contact-person"}
                                                        element={
                                                            <PIUContactPerson />
                                                        }
                                                    />
                                                    <Route
                                                        path={"branches"}
                                                        element={
                                                            <PIUBranches />
                                                        }
                                                    />
                                                </Routes>
                                            }
                                        />

                                        <Route
                                            path="update-piu/:piuRegName/*"
                                            element={
                                                <Routes>
                                                    <Route
                                                        index
                                                        element={
                                                            <BasicDetails />
                                                        }
                                                    />
                                                    <Route
                                                        path={
                                                            "mission-and-vision"
                                                        }
                                                        element={
                                                            <PIUMissionAndVision />
                                                        }
                                                    />
                                                    <Route
                                                        path={"contact-person"}
                                                        element={
                                                            <PIUContactPerson />
                                                        }
                                                    />
                                                    <Route
                                                        path={"branches"}
                                                        element={
                                                            <PIUBranches />
                                                        }
                                                    />
                                                </Routes>
                                            }
                                        />
                                    </Route>
                                    <Route
                                        exact
                                        path="edit-stakeholder/:stakeholderName"
                                        element={<StakeholderRegForm />}
                                    />
                                    <Route element={<AuthLayout />}>
                                        <Route
                                            path="/access-denied"
                                            element={<AccessDenied />}
                                        />
                                        <Route
                                            path="/piu-details/:piuRegName"
                                            element={<PIUDetails />}
                                        />
                                        <Route>
                                            <Route
                                                path={"meeting-budget"}
                                                element={
                                                    <Navigate to={"pending"} />
                                                }
                                            />
                                            <Route
                                                path={"meeting-budget/:status"}
                                                element={
                                                    <RequirePermission>
                                                        <EventProvider>
                                                            <BudgetApprove />
                                                        </EventProvider>
                                                    </RequirePermission>
                                                }
                                            />
                                        </Route>

                                        <Route path={"/"} element={<Home />} />
                                        <Route
                                            path={`${baseRotes.STAKEHOLDER_BASE_ROUTE}/*`}
                                            element={
                                                <StakeholderProfileRoutes />
                                            }
                                        />
                                        <Route
                                            path={"analytics/*"}
                                            element={<AnalyticsRoutes />}
                                        />
                                        <Route
                                            path={"settings/*"}
                                            element={<SettingsRoutes />}
                                        />
                                        <Route
                                            path={"project-files/*"}
                                            element={<ProjectFilesRoutes />}
                                        />
                                        <Route
                                            path={"project-images/*"}
                                            element={<ProjectImagesRoutes />}
                                        />
                                        <Route
                                            path="event-registration/*"
                                            element={
                                                <EventRegistrationRoutes />
                                            }
                                        />
                                        <Route
                                            path="engagement/*"
                                            element={<EngagementRoutes />}
                                        />
                                        <Route
                                            path={"commitments/*"}
                                            element={
                                                <RequirePermission
                                                    permission={
                                                        permissions.READ_COMMITMENTS
                                                    }
                                                >
                                                    <CommitmentProvider>
                                                        <CommitmentPage />
                                                    </CommitmentProvider>
                                                </RequirePermission>
                                            }
                                        />
                                        <Route
                                            path={"grievances/*"}
                                            element={<GrievanceRoutes />}
                                        />
                                    </Route>
                                </Routes>
                            </RequireAuth>
                        }
                    />
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </AuthProvider>
        </BrowserRouter>
    );
};
export default Router;
