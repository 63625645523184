import Modal from "components/Modal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/free-solid-svg-icons";
import Input from "../../../../components/form-control/Input";
import Textarea from "../../../../components/form-control/textarea";
import Button from "../../../../components/Button";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup"
import {DepartmentFormValidation} from "../../../../forms/validation";
import {useMutation, useQueryClient} from "react-query";
import {createDepartment, updateDepartment} from "../../../../provider/api";
import useGlobalContext from "../../../../hooks/useGlobalContext";
import {getDatesBetween, isEmptyObject} from "../../../../utils";
import Select from "../../../../components/form-control/Select";

const DepartmentForm = (
    {
        showForm,
        setShowForm,
        activeDepartment = {},
        setActiveDepartment = {}
    }) => {

    const {handleSubmit, register, reset: clearForm, formState: {errors}} = useForm({
        resolver: yupResolver(DepartmentFormValidation)
    })
    const {projectDetails} = useGlobalContext();
    const inputClass = "text-sm"
    const queryClient = useQueryClient();
    const isUpdatingDepartment = !isEmptyObject(activeDepartment);

    const handleClose = () => {
        setShowForm(false)
        setActiveDepartment({})  // clear active department
        clearForm()
    }

    // update cache when department is updated or created.
    const updateDepartmentCache = department => {
        if (isUpdatingDepartment) {
            return queryClient.setQueriesData(['departments', projectDetails?.piu], (oldQueryData) => {
                return {
                    ...oldQueryData,
                    data: oldQueryData.data.map((_department) => {
                        if (_department?._id === department?._id) {
                            return department
                        }
                        return _department
                    })
                }
            })
        }
        return queryClient.setQueriesData(['departments', projectDetails?.piu], (oldQueryData) => {
            return {
                ...oldQueryData,
                data: [...oldQueryData.data, department]
            }
        })
    }


    // create department and update department's cache when is successfully created else show error
    const {isLoading, mutate: _createDepartment} = useMutation('create-department', createDepartment, {
        onSuccess: ({data: departmentData}) => {
            console.log("✅ response data: ", departmentData);
            updateDepartmentCache(departmentData)
            handleClose()
        },
        onError: ({message}) => {
            console.log('❌ failed to create department', message)
        }
    })

    // update department and update department's cache when is successfully updated else show error
    const {isLoading: isUpdating, mutate: _updateDepartment} = useMutation('update-department', updateDepartment, {
        onSuccess: ({data: departmentData}) => {
            console.log("✅ response data: ", departmentData);
            updateDepartmentCache(departmentData)
            handleClose()
        },
        onError: ({message}) => {
            console.log('❌ failed to create department', message)
        }
    })

    const onSubmit = (formData) => {
        console.log("✅ form data: ", formData);
        const payload = {
            ...formData,
            piu: projectDetails?.piu
        }
        if (isUpdatingDepartment) {
            return _updateDepartment({
                ...payload,
                dpId: activeDepartment?._id
            })
        }
        return _createDepartment(payload);
    }

    // populate years between 1980 - current year
    const getYears = () => {
        let arrOfYear = [];
        for (let year = 1980; year <= parseInt(new Date().getFullYear()); year++) {
            arrOfYear.push(year);
        }
        return arrOfYear
    }

    return (
        <Modal isOpen={showForm} onClose={handleClose}>
            <div className={'bg-white rounded-lg overflow-hidden w-[45%]'}>
                {/* header */}
                <div className="bg-gray-300 px-10 py-3 rounded-t-md flex justify-between">
                    <p className="font-semibold text-[#002F4F]">
                        Add New Department
                    </p>

                    <button
                        onClick={handleClose}
                        className="-mr-7 border-2 focus:ring-0 ring-0 border-[#192B40]/50 w-6 h-6 bg-[#628799] text-white rounded-full grid place-items-center"
                    >
                        <FontAwesomeIcon color="FFFFFF" icon={faXmark}/>
                    </button>
                </div>

                <form onSubmit={handleSubmit(onSubmit)} className={'space-y-4 px-10 py-7'}>
                    <div className={'space-y-1'}>
                        <label className="block mb-2 text-sm font-medium text-[#002F4F]" htmlFor="">
                            Department Name
                        </label>
                        <Input
                            defaultValue={activeDepartment?.name ?? ""}
                            register={register}
                            isError={!!errors?.name?.message}
                            name={"name"}
                            className={inputClass}
                            placeholder={'eg Finance, Human Resources'}/>
                    </div>

                    <div className={'space-y-1'}>
                        <label className="block mb-2 text-sm font-medium text-[#002F4F]" htmlFor="">
                            Head of department
                        </label>
                        <Input
                            defaultValue={activeDepartment?.headOfDepartment ?? ""}
                            className={inputClass}
                            register={register}
                            name={'headOfDepartment'}
                            isError={!!errors?.headOfDepartment?.message}
                            placeholder={'eg Boniface Kato'}
                        />
                    </div>

                    <div className={'space-y-1'}>
                        <label className="block mb-2 text-sm font-medium text-[#002F4F]" htmlFor="">
                            Description
                        </label>
                        <Textarea
                            defaultValue={activeDepartment?.description ?? ""}
                            register={register}
                            isError={!!errors?.description?.message}
                            name={'description'}
                            className={inputClass}
                        />
                    </div>

                    <div className={'space-y-1'}>
                        <label className="block mb-2 text-sm font-medium text-[#002F4F]" htmlFor="">
                            Year of establishment
                        </label>
                        <Select
                            {...register('establishedOn')}
                            defaultValue={activeDepartment?.establishedOn ?? ""}
                            isError={!!errors?.establishedOn?.message}
                            className={inputClass}
                            placeholder={'eg 2018'}>
                            <option value="" disabled={true}>Choose</option>
                            {getYears().map((year) => (
                                <option value={year}>{year}</option>
                            ))}

                        </Select>
                    </div>

                    <div>
                        <Button loading={isLoading || isUpdating} type={'submit'}
                                className={'border-[2px] py-3.5 border-secondary bg-secondary'}>
                            {isUpdatingDepartment ? 'UPDATE DEPARTMENT' : 'ADD DEPARTMENT'}
                        </Button>
                    </div>
                </form>
            </div>
        </Modal>
    )
}
export default DepartmentForm;