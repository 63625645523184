import {ArrowRightIcon, SearchIcon} from "@heroicons/react/outline";
import React, {useState} from "react";
import dayjs from "dayjs";
import {useEngagementContext} from "./engagement";
import Select from "../../../../../components/form-control/Select";

const EngagementActivities = () => {
    const {
        events: originalEvents, setActiveEvent,
        setFilteredEvents, filteredEvents = [],
        setShowEventDetails, setEvents
    } = useEngagementContext();


    const todayDate = new Date().getTime();


    const viewEventDetails = (event) => {
        setActiveEvent(event)
        setShowEventDetails(true)
    }

    const formatDate = (date) => {
        return dayjs(date).format('MMMM, DD YYYY');
    }

    const handleSearchChanges = ({target}) => {
        console.log('searhing...')
        const searchKeyword = target.value.toLowerCase()
        console.log('[+] SEARCH KEYWORD [+] ', searchKeyword);

        setFilteredEvents([...originalEvents.filter((event) => {
            return formatDate(event.eventDate).toLowerCase().includes(searchKeyword)
        })]);

    }

    const handleFilterChanges = ({target}) => {
        const filterBy = target.value;
        switch (filterBy) {
            case "upcoming": {
                filterUpcomingEvents()
                break;
            }
            case "completed": {
                filterCompletedEvents()
                break;
            }
            case "overdue": {
                filterOverdueEvents()
                break
            }
            default:
                setFilteredEvents([...originalEvents])
        }
    }

    const filterUpcomingEvents = () => {
        setFilteredEvents(originalEvents.filter((event) => {
            return new Date(event.eventDate) >= todayDate && !event.status;
        }));
    }

    const filterCompletedEvents = () => {
        setFilteredEvents(originalEvents.filter((event) => {
            return event.status;
        }))
    }

    const filterOverdueEvents = () => {
        setFilteredEvents(originalEvents.filter((event) => {
            return (!event.status && new Date(event.eventDate) < todayDate);
        }))
    }


    const tableHeaderClassName = 'text-[11px] py-3 font-semibold uppercase px-5 tracking-wider text-[#4D4F5C]';
    return (
        <div
            className={'space-y-4 h-[45rem] overflow-hidden shadow border-t-2 border-t-[#1DBF6F] border-light-gray overflow-hidden rounded'}>

            <div className={'flex items-center p-5 justify-between'}>
                <Select onChange={handleFilterChanges} className={'w-40 py-2'}>
                    <option value="all">All Activities</option>
                    <option value="completed">completed</option>
                    <option value="upcoming">upcoming</option>
                    <option value="overdue">overdue</option>
                </Select>

                <div
                    className={'w-60 bg-[#F9F9F9AC] border-[1px] border-[#DCDFF1] flex items-center rounded overflow-hidden pl-2 space-x'}>
                    <SearchIcon className={'h-5 w-5 text-black/50'}/>
                    <input type="text"
                           onChange={handleSearchChanges}
                           placeholder={'Search...'}
                           name="" id=""
                           className={'bg-transparent border-none focus:ring-0 placeholder-black/50 text-sm outline-none py-2 font-light w-full'}/>
                </div>
            </div>


            <div className={'grid grid-cols-2'}>
            </div>

            <div className={''}>
                <table className={'w-full'}>
                    {/* Table Header */}
                    <tr className={'text-left bg-[#F5F6FA]'}>
                        <th className={tableHeaderClassName}>activity</th>
                        <th className={tableHeaderClassName}>title</th>
                        <th className={tableHeaderClassName}>date</th>
                        <th className={tableHeaderClassName}>started</th>
                        <th className={tableHeaderClassName}>ended</th>
                        <th className={tableHeaderClassName}>Action</th>
                    </tr>

                    {/* Table Data */}
                    {filteredEvents.map((event) => {
                        const tableDataClassName = 'text-[13px] capitalize font-light text-[#162945]';
                        return (
                            <tr key={event._id} className={'border-b-[1px] border-light-gray'}>
                                <td className={'text-[13px] capitalize text-accent p-5 '}>
                                    {event.isMeeting ? 'Meeting' : 'Others'}
                                </td>
                                <td className={tableDataClassName}>{event.title}</td>
                                <td className={tableDataClassName}>
                                    {formatDate(event.eventDate)}
                                </td>
                                <td className={tableDataClassName}>
                                    N/A
                                </td>
                                <td className={tableDataClassName}>N/A</td>
                                <td className={'text-[13px] capitalize text-[#162945] flex'}>
                                    <div
                                        className={'flex items-center  p-5 h-full text-[#1DBF6F] space-x-2 hover:underline cursor-pointer'}>
                                        <button onClick={() => viewEventDetails(event)}>View Activity</button>
                                        <ArrowRightIcon className={'h-4'}/>
                                    </div>
                                </td>
                            </tr>
                        );
                    })}

                </table>
            </div>
        </div>
    )
}
export default EngagementActivities;