import {useEffect, useState} from "react";
import FileUpload from "../../../../../../../components/file-upload";
import UploadedFiles from "../../../../../../../components/file-upload/uploaded-files";
import EditorJS from "../../../../../../../components/editorjs/editor";
import InputCard from "../../_partials/input-card";
import Input from "../../../../../../../components/form-control/Input";
import {useConductMeetingContext} from "../../index";

const NoneRegStakeholder = () => {
    const {conductedInfo, setConductedInfo} = useConductMeetingContext();
    const {nonRegStakeholder} = conductedInfo;
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [statistics, setStatistics] = useState(nonRegStakeholder.statistics);

    const handleEditorChanges = (cleanData) => {
        setConductedInfo({
            ...conductedInfo,
            nonRegStakeholder: {
                ...conductedInfo.nonRegStakeholder,
                description: cleanData,
            },
        });
    };

    const handleInputChanges = ({target}) => {
        const {name, value} = target;
        console.log("called");
        const _value = value.replace(/[!@#\-$%^&*A-za-z]/g, ""); // replace special-char and characters;
        target.value = _value;
        setStatistics({...statistics, [name]: _value});
    };

    const handleSubmit = (_uploadedFiles) => {
        setUploadedFiles(_uploadedFiles);
    };

    const fieldNames = ["total", "male", "female", "withDisability"];
    const inputProps = fieldNames.map((name) => {
        return {
            name,
            /* type: "number", */
            onChange: handleInputChanges,
            className: "text-sm w-1/2 p-2 py-2 mt-2",
        };
    });

    const handleOnComplete = () => {
    };

    useEffect(() => {
        setConductedInfo({
            ...conductedInfo,
            nonRegStakeholder: {
                ...conductedInfo.nonRegStakeholder,
                statistics,
                documents: [...uploadedFiles],
            },
        });
    }, [statistics, uploadedFiles]);

    const handleOnClose = () => {
        setConductedInfo({
            ...conductedInfo,
            nonRegStakeholder: {
                ...conductedInfo.nonRegStakeholder,
                statistics,
                documents: [...uploadedFiles],
            },
        });
    };

    return (
        <InputCard
            title={"Non-Registered Stakeholders Attendance"}
            onClose={handleOnClose}
            onComplete={handleOnComplete}
        >
            <div className={" px-3"}>
                <FileUpload
                    isOpen={isOpen}
                    onClose={setIsOpen}
                    onSubmit={handleSubmit}
                />
                <div className={"bg-[#FFFFFF] p-3"}>
                    <div className={"border-t border-t-[#C3CBD9] py-5 pr-2 "}>
                        <div className={"w-full space-y-3"}>
                            <p className={"text-[#707070]/70 text-sm"}>
                                For non-registered participants, you may record
                                their names, signatures, and other details on a
                                paper or any software tool and upload the
                                document below.
                            </p>
                        </div>
                    </div>

                    <div className="space-y-4">
                        <UploadedFiles
                            onUpload={() => setIsOpen(true)}
                            files={uploadedFiles || nonRegStakeholder.documents}
                            onRemove={setUploadedFiles}
                        />

                        <div className={"space-y-2"}>
                            <p className={"text-dark tracking-wide"}>
                                Description
                            </p>
                            <EditorJS
                                uniqueId="none-reg-stakeholder-text-area"
                                onChange={handleEditorChanges}
                                defaultValue={nonRegStakeholder.description}
                            />
                        </div>
                    </div>
                </div>

                {/* Gather none registered stakeholders information */}
                <div className="my-5">
                    <div>
                        <div className={"items-between"}>
                            <p> Non-Registered Stakeholders attended</p>
                            <Input
                                min={0}
                                {...inputProps[0]}
                                defaultValue={statistics.total}
                            />
                        </div>

                        <div className={"items-between"}>
                            <p>How many were Male?</p>
                            <Input
                                {...inputProps[1]}
                                defaultValue={statistics.male}
                            />
                        </div>

                        <div className={"items-between"}>
                            <p>How many were Female?</p>
                            <Input
                                {...inputProps[2]}
                                defaultValue={statistics.female}
                            />
                        </div>

                        <div className={"items-between"}>
                            <p>How many were people with disability?</p>
                            <Input
                                {...inputProps[3]}
                                defaultValue={statistics.withDisability}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </InputCard>
    );
};
export default NoneRegStakeholder;
