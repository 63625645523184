import UserProvider from "provider/users/user-provider";
import UserList from "./user-list";
import UserForm from "./form";

const Users = () => {
    return (
        <UserProvider>
            <UserForm/>
            <section className={' text-dark2  text-dark2 pb-5 '}>
                <div className={' space-y-5'}>
                    <UserList/>
                </div>
            </section>
        </UserProvider>
    )
}
export default Users;