import React from "react";
import {
	ArrowRight,
	AcrobatLogo,
	DownloadIcon,
	PrinterIcon,
} from "../../../../svg";
import { isImageFile } from "../../../../utils";
import Image from "../../../../components/image";
import { faThumbtack } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SpinLoader from "../../../../components/SpinLoader";
import { useState } from "react";
import useDownloadFileFromS3 from "../../../../hooks/useDownloadFileFromS3";
import { handlePrint as printFile } from "../../../../components/image-viewer/image-viewer";

const FileCard = (
	{ file = {}, onOpenFile: handleOpenFile = () => {} },
	...props
) => {
	const [isDownloading, setIsDownloading] = useState(false);

	const { s3FileDownloader } = useDownloadFileFromS3((progress) => {
		if (parseInt(progress) === 100) {
			setIsDownloading(false);
		}
	});

	const handleFileDownload = () => {
		setIsDownloading(true);
		s3FileDownloader(file.fileKey, file.title);
	};
	const handleClick = () => {
		handleOpenFile(file);
	};

	const isImage = isImageFile(file?.type);

	// console.log(isImageFile(file.type), file.type);

	const handleFilePrint = (e) => {
		e.stopPropagation();
		printFile(file.url);
	};

	return (
		<div
			{...props}
			className={`grid ${
				isImage ? "grid-cols-6" : "grid-cols-5 "
			} hover:bg-[#F4F9FF]/70 cursor-pointer  text-black border-[1px] rounded-[3px]  border-[#D5E3D6] p-2 text-sm w-full `}
		>
			<div className={" col-span-2 flex items-center space-x-4"}>
				<div className={"h-8 w-8 shrink-0 rounded overflow-hidden"}>
					{isImageFile(file?.type) ? (
						<Image imageUrl={file.url} alt="icon" />
					) : (
						<AcrobatLogo />
					)}
				</div>
				<p className={"capitalize whitespace-nowrap truncate"}>
					{file?.title ?? "file title goes here"}
				</p>
			</div>

			<div className={"center"}>Thu, 26 Oct</div>
			<div className={"center"}>{file?.fileSize ?? "0 MB"}</div>
			<div className="space-x-1 center">
				<button
					onClick={handleFileDownload}
					className="rounded bg-black/10 group center hover:bg-black/20 backdrop-blur-sm w-7 h-7"
				>
					{isDownloading ? (
						<SpinLoader size={"small"} color={"#628799"} />
					) : (
						<DownloadIcon className={`h-3 w-3 fill-black/50 `} />
					)}
				</button>
				<button className="rounded bg-black/10 group center hover:bg-black/20 backdrop-blur-sm w-7 h-7">
					<FontAwesomeIcon
						icon={faThumbtack}
						className={"h-4 w-4 text-black/50 "}
					/>
				</button>

				{isImage && (
					<button
						onClick={handleFilePrint}
						className="rounded bg-black/10 group center hover:bg-black/20 backdrop-blur-sm w-7 h-7"
					>
						<PrinterIcon
							className={"h-4 w-4 fill-black/50"}
							color={"#fff"}
						/>
					</button>
				)}
			</div>
			{isImage && (
				<div
					onClick={handleClick}
					className={
						"center text-[#51BF82] flex items-center space-x-2"
					}
				>
					<p>View</p>
					<ArrowRight color={"#51BF82"} />
				</div>
			)}
		</div>
	);
};
export default FileCard;
