import FileUpload from "../../../../../../components/file-upload";
import React from "react";
import {useEffect, useState} from "react";
import {useConductMeetingContext} from "../index";
import UploadedFiles from "../../../../../../components/file-upload/uploaded-files";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileAudio, faPlus} from "@fortawesome/free-solid-svg-icons";


const SoundUpload = () => {
    const [isOpen, setIsOpen] = useState(false);
    const {conductedInfo, setConductedInfo} = useConductMeetingContext()
    const [uploadedSounds, setUploadedSounds] = useState(conductedInfo.videos ?? []);

    useEffect(() => {
        setConductedInfo({...conductedInfo, videos: uploadedSounds})
    }, [uploadedSounds]);

    const handleSubmit = (sounds) => {
        setUploadedSounds(sounds);
    }

    const filesTypes = "audio/*";
    const className = {
        bgColor1: "bg-[#a3afcb]/10",
        bgColor2: "bg-transparent"
    }

    return (
        <React.Fragment>
            <FileUpload onClose={setIsOpen} isOpen={isOpen} onSubmit={handleSubmit} fileTypes={filesTypes}/>
            <div className="h-full  ">
                {uploadedSounds.length ? (
                    <React.Fragment>
                        <button onClick={() => setIsOpen(true)}
                                className={'text-accent pl-2 hover:underline text-sm flex items-center space-x-1'}>
                            <FontAwesomeIcon icon={faPlus}/>
                            <p>Upload files</p>
                        </button>
                        <UploadedFiles
                            isAudioFiles={true}
                            className={className}
                            showFilenameOnHover={true}
                            files={uploadedSounds}
                            onRemove={setUploadedSounds}
                            showTitle={false}
                        />
                    </React.Fragment>

                ) : (
                    <div className={'h-52 center flex-col space-y-3'}>
                        <FontAwesomeIcon icon={faFileAudio} className={'h-10 w-10 text-[#AAB6C5]'}/>
                        <button className={'text-accent hover:underline '} onClick={() => setIsOpen(true)}>
                            Upload files
                        </button>
                    </div>
                )}
            </div>
        </React.Fragment>
    ) 
};


export default SoundUpload;

