import SuggestionItem from "./suggestion-item";
import {usePermissionContext} from "./permission-form";
import {XIcon} from "@heroicons/react/solid";

const SearchSuggestions = () => {
    const {suggestions, permissions, setSuggestions} = usePermissionContext()
    if (!suggestions.length) return;

    const getSuggestions = () => {
        return suggestions.map((suggestion) => {
            const isAlreadySelected = permissions.find((permission) => {
                return permission?.displayName === suggestion?.displayName;
            })
            return {...suggestion, isSelected: !!isAlreadySelected}
        })
    }

    return (
        <div
            className={'top-full absolute z-30 w-full max-h-[20rem] overflow-hidden bg-white/40 overflow-y-auto border-[1.5px] border-light-gray  bg-white/20 backdrop-blur-lg rounded-[5px] '}>
            <div className={'divide-y'}>
                <div className={'items-between shadow-sm px-3 py-2 bg-white sticky top-0 z-40'}>
                    <p className={'text-sm opacity-80'}> Added Permission ({permissions.length})</p>
                    <button onClick={() => setSuggestions([])}
                            className={' h-6 w-6 center hover:bg-gray-200 rounded-full'}>
                        <XIcon className={'h-4 w-4 '}/>
                    </button>
                </div>
                <div className={'divide-y '}>
                    {getSuggestions().map((suggestion) => (
                        <SuggestionItem suggestion={suggestion}/>
                    ))}
                </div>
            </div>
        </div>
    )
}
export default SearchSuggestions;