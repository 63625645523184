import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import React from "react";

const PieChart = ({statistics}) => {

    const options = {
        plotOptions: {
            series: {
                dataLabels: {
                    enabled: true,
                    format: '{point.name}: {point.y:.0f}%'
                },
                point: {
                    events: {
                        click: function () {
                            window.open(this.options.url, '_blank').focus();
                        }
                    }
                }
            },
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                borderWidth: 0,
                dataLabels: {
                    enabled: false
                },
                colors: ["#A5DED4", "#FDD1A9", "#168ADC", "#FDC2C0"],
                showInLegend: false,
            }
        },

        title: {
            text: "",
            y: 3.5,
            style: {
                display: false,
                fontSize: '14px',
                fontWeight: "bold",
                color: "#253A53"
            }
        },
        chart: {
            renderTo: 'chart', // Spacing between Title & Chart
            defaultSeriesType: 'areaspline'
        },
        series: [{
            type: 'pie',
            name: 'Activities count',
            innerSize: '10%',
            data: [
                {
                    name: "low",
                    color: '#AAB6C5',
                    y: statistics?.low,
                },
                {
                    name: "medium",
                    color: '#838EAB',
                    y: statistics?.medium
                },
                {
                    name: "high",
                    color: '#628799',
                    y: statistics?.high
                }
            ]
        }],

    }

    return (
        <div className={'h-full highcharts relative '}>
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
            />
        </div>
    )
}

export const PieChartLabel = ({label, color, total}) => {
    return (
        <div className={'flex space-x-4'}>
            <div className={'h-2.5 w-2.5 mt-2 rounded-full'} style={{backgroundColor: color}}></div>
            <div>
                <p className={'text-[#002F4F] font-medium'}>{total}</p>
                <p className={'text-[11px] text-[#868686] font-light'}>{label}</p>
            </div>
        </div>
    )
}
export default PieChart