import {useQuery} from "react-query";
import {useState} from "react";
import {getEngagementAnalytics} from "../../api";
import {EngagementAnalyticContext} from "../../../context"
import useGlobalContext from "../../../hooks/useGlobalContext";

const EngagementAnalyticsProvider = ({children}) => {
    const {projectId} = useGlobalContext();
    const [engagementAnalytics, setEngagementAnalytics] = useState([]);
    const {isLoading} = useQuery('engagement-analytics', () => {
        return getEngagementAnalytics(projectId)
    }, {
        onSuccess: ({data: analytics}) => {
            setEngagementAnalytics(analytics);
        }
    })
    return (
        <EngagementAnalyticContext.Provider value={{isLoading, engagementAnalytics}}>
            {children}
        </EngagementAnalyticContext.Provider>
    )
}
export default EngagementAnalyticsProvider;
