import PhaseIllustrator from "../../../../settings/phase/empty-state/phase-illustrator";
import { Link } from "react-router-dom";

const EmptyState = () => {
    return (
        <div
            className={
                "py-20 border mt-4 rounded space-y-5 bg-[#f9f9f9] flex flex-col items-center"
            }
        >
            <div className={"h-44 w-44 bg-gray-200 p-5 rounded-full center"}>
                <PhaseIllustrator />
            </div>
            <div className={"text-center text-gray-500"}>
                <p>
                    There are no engagement plans or phases currently available.
                    To get started, please follow the following instructions:
                </p>

                <ul className={"list-decimal w-max mx-auto list-inside"}>
                    <li>
                        Create project phase{" "}
                        <Link
                            to={"/settings/phase-managements/create"}
                            className={"text-accent"}
                        >
                            here
                        </Link>
                    </li>
                    <li>Click the button above to create plans</li>
                </ul>
            </div>
        </div>
    );
};
export default EmptyState;
