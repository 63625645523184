import MbeyaSvg from "./regions/mbeya-svg";
import NjombeSvg from "./regions/njombe-svg";
import RuvumaSvg from "./regions/ruvuma-svg";
import KigomaSvg from "./regions/kigoma-svg";
import KataviSvg from "./regions/katavi-svg";
import RukwaSvg from "./regions/rukwa-svg";
import KageraSvg from "./regions/kagera-svg";
import MtwaraSvg from "./regions/mtwara-svg";
import MaraSvg from "./regions/mara-svg";
import ArushaSvg from "./regions/arusha-svg";
import KilimanjaroSvg from "./regions/kilimanjaro-svg";
import TangaSvg from "./regions/tanga-svg";
import LindiSvg from "./regions/lindi-svg";
import PwaniSvg from "./regions/pwani-svg";
import DarEsSalaamSvg from "./regions/dar-es-salaam-svg";
import UngujaNorthSvg from "./regions/unguja-north-svg";
import UngujaSouthSvg from "./regions/unguja-south-svg";
import MorogoroSvg from "./regions/morogoro-svg";
import DodomaSvg from "./regions/dodoma-svg";
import ManyaraSvg from "./regions/manyara-svg";
import Singida from "./regions/singida";
import Tabora from "./regions/tabora";
import Iringa from "./regions/iringa";
import Mwanza from "./regions/mwanza";
import Simiyu from "./regions/simiyu";
import Geita from "./regions/geita";
import Shinyanga from "./regions/shinyanga";
import NorthPemba from "./regions/north-pemba";
import SouthPemba from "./regions/south-pemba";

const TanzaniaSvgMap = ({ isLoading = false }) => {
    const stakeholderCount = {
        mbeya: 60,
        njombe: 32,
        ruvuma: 17,
        kigoma: 6,
        katavi: 7,
        rukwa: 19,
        kagera: 26,
        mtwara: 45,
        mara: 9,
        arusha: 38,
        kilimanjaro: 14,
        tanga: 42,
        lindi: 24,
        pwani: 19,
        daressalaam: 80,
        ungujaNorth: 0,
        ungujaSouth: 0,
        northPemba: 1,
        southPemba: 11,
        morogoro: 42,
        dodoma: 26,
        manyara: 60,
        singida: 18,
        tabora: 34,
        iringa: 54,
        mwanza: 29,
        simiyu: 34,
        geita: 9,
        shinyanga: 11,
    };

    const colorArray = [
        { hexValue: "#838eab", minValue: 0, maxValue: 9 },
        { hexValue: "#f9b641", minValue: 10, maxValue: 19 },
        { hexValue: "#002f4f", minValue: 20, maxValue: 29 },
        { hexValue: "#009ee2", minValue: 30, maxValue: 39 },
        { hexValue: "#51bf82", minValue: 40, maxValue: 100 },
    ];

    const getColor = (value) => {
        const color = colorArray.find(
            (color) => value >= color.minValue && value <= color.maxValue
        );
        return isLoading ? "#D9E3E1" : color.hexValue;
    };

    return (
        <svg
            className={"pl-2 mt-4"}
            xmlns="http://www.w3.org/2000/svg"
            width="424.926"
            height="423.836"
            viewBox="0 0 424.926 423.836"
        >
            <NjombeSvg
                isLoading={isLoading}
                color={getColor(stakeholderCount.njombe)}
            />
            <g
                id="Tanzania_map"
                data-name="Tanzania map"
                transform="translate(0.25 0.25)"
            >
                <MbeyaSvg
                    isLoading={isLoading}
                    color={getColor(stakeholderCount.mbeya)}
                />
                <RuvumaSvg
                    isLoading={isLoading}
                    color={getColor(stakeholderCount.ruvuma)}
                />
                <KigomaSvg
                    isLoading={isLoading}
                    color={getColor(stakeholderCount.kigoma)}
                />
                <KataviSvg
                    isLoading={isLoading}
                    color={getColor(stakeholderCount.katavi)}
                />
                <RukwaSvg
                    isLoading={isLoading}
                    color={getColor(stakeholderCount.rukwa)}
                />
                <KageraSvg
                    isLoading={isLoading}
                    color={getColor(stakeholderCount.kagera)}
                />
                <MtwaraSvg
                    isLoading={isLoading}
                    color={getColor(stakeholderCount.mtwara)}
                />
                <MaraSvg
                    isLoading={isLoading}
                    color={getColor(stakeholderCount.mara)}
                />
                <ArushaSvg
                    isLoading={isLoading}
                    color={getColor(stakeholderCount.arusha)}
                />
                <KilimanjaroSvg
                    isLoading={isLoading}
                    color={getColor(stakeholderCount.kilimanjaro)}
                />
                <TangaSvg
                    isLoading={isLoading}
                    color={getColor(stakeholderCount.tanga)}
                />
                <LindiSvg
                    isLoading={isLoading}
                    color={getColor(stakeholderCount.lindi)}
                />
                <PwaniSvg
                    isLoading={isLoading}
                    color={getColor(stakeholderCount.pwani)}
                />
                <DarEsSalaamSvg
                    isLoading={isLoading}
                    color={getColor(stakeholderCount.daressalaam)}
                />
                <g
                    id="Group_173194"
                    data-name="Group 173194"
                    transform="translate(374.683 152.879)"
                >
                    <g
                        id="Group_173028"
                        data-name="Group 173028"
                        transform="translate(0 32.514)"
                    >
                        <UngujaNorthSvg
                            isLoading={isLoading}
                            color={getColor(stakeholderCount.ungujaNorth)}
                        />
                        <UngujaSouthSvg
                            isLoading={isLoading}
                            color={getColor(stakeholderCount.ungujaSouth)}
                        />
                        <path
                            id="Path_444"
                            data-name="Path 444"
                            d="M612.371,290.594l-.3-.249-.531.291v.288l.229.035.028.115-.031.172.042.215-.525-.365-.165.615.137.887.42.455-.1.051-.06.086-.15.019-.363-.157-.354-.227-.133-.151-.089-.16-1.972-2.282-.369-.618-.5-1.766-.343-.707,1.4-1.718-.318-2.272-.267-.9.142.008,1.422-.122,1.414.128.06.794.2.778-.238,3.084.069,1,.4.892.687.728.117.811Z"
                            transform="translate(-607.245 -269.865)"
                            fill="#51bf82"
                            stroke="#fff"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="0.5"
                        />
                    </g>
                    <g
                        id="Group_173027"
                        data-name="Group 173027"
                        transform="translate(15.327)"
                    >
                        <NorthPemba
                            isLoading={isLoading}
                            color={getColor(stakeholderCount.northPemba)}
                        />
                        <SouthPemba
                            isLoading={isLoading}
                            color={getColor(stakeholderCount.southPemba)}
                        />
                    </g>
                </g>
                <MorogoroSvg
                    isLoading={isLoading}
                    color={getColor(stakeholderCount.morogoro)}
                />
                <DodomaSvg
                    isLoading={isLoading}
                    color={getColor(stakeholderCount.dodoma)}
                />
                <ManyaraSvg
                    isLoading={isLoading}
                    color={getColor(stakeholderCount.manyara)}
                />
                <Singida
                    isLoading={isLoading}
                    color={getColor(stakeholderCount.singida)}
                />
                <Tabora
                    isLoading={isLoading}
                    color={getColor(stakeholderCount.tabora)}
                />
                <Iringa
                    isLoading={isLoading}
                    color={getColor(stakeholderCount.iringa)}
                />
                <Mwanza
                    isLoading={isLoading}
                    color={getColor(stakeholderCount.mwanza)}
                />
                <Simiyu
                    isLoading={isLoading}
                    color={getColor(stakeholderCount.simiyu)}
                />
                <Geita
                    isLoading={isLoading}
                    color={getColor(stakeholderCount.geita)}
                />
                <Shinyanga
                    isLoading={isLoading}
                    color={getColor(stakeholderCount.shinyanga)}
                />
                <text
                    transform="translate(310.632 203.148)"
                    fill="#fff"
                    fontSize="12"
                    fontFamily="Inter-SemiBold, Inter"
                    fontWeight="600"
                >
                    <tspan x="0" y="0"></tspan>
                </text>
            </g>
        </svg>
    );
};
export default TanzaniaSvgMap;
