import EditIcon from "../../general/stakeholder/_partials/edit-icon";
import {TrashIcon} from "@heroicons/react/solid";

const GrievanceCard = ({setShowForm, nature, setActiveNature, onRemove: handleRemove}) => {
    const handleEdit = () => {
        setActiveNature(nature)
        setShowForm(true)
    }

    const getThemeColor = () => {
        switch (nature.color) {
            case "#2B9BD5": {
                return {
                    bgClasses: "bg-[#2B9BD5]",
                    delBtnClasses: "border-[1px] text-[#237FAE] border-[#237FAE] bg-[#D0E6F6]/[76%]",
                    ediBtnClasses: " bg-[#237FAE] border-[1px] border-[#237FAE]"
                }
            }
            case "#19A89D": {
                return {
                    bgClasses: "bg-[#19A89D]",
                    delBtnClasses: "border-[1px] text-[#148980] border-[#148980] bg-[#DEFFFD]/[76%]",
                    ediBtnClasses: " bg-[#148980] border-[1px] border-[#148980]"
                }
            }

            case "#233A4F": {
                return {
                    bgClasses: "bg-[#233A4F]",
                    delBtnClasses: "border-[1px] text-[#002F4F] border-[#002F4F] bg-[#838FAA]/[76%]",
                    ediBtnClasses: " bg-[#002F4F] border-[1px] border-[#002F4F]"
                }
            }

            case "#83A5A8": {
                return {
                    bgClasses: "bg-[#83A5A8]",
                    delBtnClasses: "border-[1px] text-[#506E7D] border-[#506E7D] bg-[#B0C4C6]/[76%]",
                    ediBtnClasses: " bg-[#506E7D] border-[1px] border-[#506E7D]"
                }
            }
            default:
                return {
                    bgClasses: "bg-[#2B9BD5]",
                    delBtnClasses: "border-[1px] text-[#237FAE] border-[#237FAE] bg-[#D0E6F6]/[76%]",
                    ediBtnClasses: " bg-[#237FAE] border-[1px] border-[#237FAE]"
                }
        }
    }
    return (
        <div
            className={`${getThemeColor().bgClasses} items-between overflow-hidden relative flex-col text-white min-h-[16rem] p-5 rounded-[8px]`}>
            <div className={'space-y-2 w-full py-2'}>
                <h2 className={'text-lg font-medium'}>{nature?.title}</h2>
                <p className={'text-sm font-light line-clamp-6'}>
                    {nature?.description}
                </p>
            </div>

            <div className={'items-between w-full pt-1.5'}>
                <button
                    onClick={() => handleRemove(nature?._id)}
                    className={` px-5 py-2 text-sm rounded border-[1px] ${getThemeColor().delBtnClasses} hover:opacity-90  text-[15px] flex items-center space-x-2`}>
                    <TrashIcon className={'h-5 w-5'}/>
                    <span>Delete</span>
                </button>

                <button
                    onClick={handleEdit}
                    className={` px-5 py-2 rounded text-white  hover:opacity-90 text-sm flex items-center ${getThemeColor().ediBtnClasses} space-x-2`}>
                    <EditIcon class={''}/>
                    <span>Edit</span>
                </button>
            </div>

            <div className={'inset-0 absolute pointer-events-none'}>
                <img src="/images/deco.svg" className={'h-full w-full'} alt=""/>
            </div>
        </div>
    )
}
export default GrievanceCard;
