import {Menu, Transition} from '@headlessui/react'
import {Fragment} from 'react'
import {ChevronDownIcon} from '@heroicons/react/solid'
import PropsTypes from "prop-types";
import {twMerge} from "tailwind-merge";

const CustomMenu = ({menuName, options, onClick: handleClick, displayName, className}) => {
    return (
        <Menu as="div" className="relative inline-block text-left">
            <div>
                <Menu.Button
                    className={twMerge(
                        "inline-flex w-full capitalize bg-black bg-opacity-5 px-4 py-2 font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75  justify-center rounded-md px-4 py-2 text-sm font-medium text-[#6A6F7B] hover:bg-opacity-10 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75",
                        className)}>
                    {menuName}
                    <ChevronDownIcon
                        className="ml-2 -mr-1 h-5 w-5 text-[#6A6F7B] "
                        aria-hidden="true"
                    />
                </Menu.Button>
            </div>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items as={'ul'}
                            className="absolute right-0 mt-2 w-max py-2 z-50 origin-top-right divide-gray-100 overflow-hidden rounded-md bg-white drop-shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    {options.map((itemOption) => (
                        <Menu.Item as={'li'} key={itemOption._id}>
                            {({active}) => (
                                <button onClick={() => handleClick(itemOption)}
                                        className={`px-6 w-full text-left text-slate-600 text-sm py-2 ${active && 'bg-black/5 '}`}>
                                    {itemOption[displayName]}
                                </button>
                            )}
                        </Menu.Item>
                    ))}
                </Menu.Items>
            </Transition>
        </Menu>
    )
}

CustomMenu.propTypes = {
    menuName: PropsTypes.string.isRequired,
    options: PropsTypes.array.isRequired,
    onClick: PropsTypes.func.isRequired,
    displayName: PropsTypes.string.isRequired,
}
export default CustomMenu;
