const EditIcon = () => {
    return (
        <svg className={'h-[14px] w-[14px]'} id="Group_165431"
             data-name="Group 165431"
             xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.302 17.302">
            <path id="Vector"
                  d="M10.937,17.1H6.164C1.845,17.1,0,15.256,0,10.937V6.164C0,1.845,1.845,0,6.164,0H7.755a.6.6,0,0,1,.6.6.6.6,0,0,1-.6.6H6.164c-3.667,0-4.971,1.3-4.971,4.971v4.772c0,3.667,1.3,4.971,4.971,4.971h4.772c3.667,0,4.971-1.3,4.971-4.971V9.346a.6.6,0,0,1,1.193,0v1.591C17.1,15.256,15.256,17.1,10.937,17.1Z"
                  transform="translate(0 0.201)" fill="#fbfdff"/>
            <path id="Vector-2" data-name="Vector"
                  d="M1.749,13.277a1.781,1.781,0,0,1-1.257-.493,1.79,1.79,0,0,1-.469-1.551L.365,8.839a2.759,2.759,0,0,1,.692-1.384L7.325,1.187C8.907-.4,10.514-.4,12.1,1.187A3.321,3.321,0,0,1,13.274,3.82,3.538,3.538,0,0,1,12.1,5.952L5.829,12.219a2.759,2.759,0,0,1-1.384.692l-2.394.342A1.326,1.326,0,0,1,1.749,13.277ZM8.168,2.03,1.9,8.3a1.619,1.619,0,0,0-.358.708L1.2,11.4a.624.624,0,0,0,.135.541.624.624,0,0,0,.541.135l2.394-.342a1.577,1.577,0,0,0,.708-.358l6.268-6.268A2.441,2.441,0,0,0,12.073,3.7a2.208,2.208,0,0,0-.827-1.678C9.973.75,9.1,1.108,8.168,2.03Z"
                  transform="translate(4.018 0)" fill="#fbfdff"/>
            <path id="Vector-3" data-name="Vector"
                  d="M4.524,5.128A.513.513,0,0,1,4.365,5.1,6.313,6.313,0,0,1,.022.762.6.6,0,0,1,.435.022a.592.592,0,0,1,.732.414A5.11,5.11,0,0,0,4.683,3.951a.6.6,0,0,1,.414.74A.585.585,0,0,1,4.524,5.128Z"
                  transform="translate(10.271 1.897)" fill="#fbfdff"/>
        </svg>
    )
}
export default EditIcon;