const MtwaraSvg = ({ color, isLoading = false }) => {
    return (
        <path
            id="Path_434"
            data-name="Path 434"
            className={`${isLoading ? "animate-pulse" : ""}`}
            d="M643.742,502.37l-.619,1.152-1.245,1.254-1.4,1.011-2.534.888-1.479,1.11-2.311,2.3-.735.967-.456.448-1.091.407-4.889,3.841-.743.39-4.033,1.732-3.915,2.712-.99.419-6.558,1.445-3.014.227-.671.223-.619.342-7.665,6.172-.913.5-.982.3-1.039-.013-.825-.3-1.592-.873-.921-.185-1.2.548-1.354.267-5.111,0-1.321.3-1.047.919-.687.482-.816.206-.517.329-1.555,1.491-.715.379-.433-.016-.844-.2-.469-.049-.424.082-.792.371-1.163.318-.626.535-.881,1.116-3.851,2.626-.974,1.212-.022-.013-1.145-.671-3.1-.783-1.386-.713-2.157-2.279-1.4-.977-1.806-.285-.388.107-.865.363-.448.078-.465-.128-.767-.564-.38-.128-.125-.078-.206-.367-.069-.1-.238,0-.2.095-.122.115v.062l-.3-.006,0-.006-2.7-9.4-1.224-8.088,6.841-.492,1.373-.927,1.923-2.91,2.42-2.465,10.393-4.312,3.5-.271,1.232-.6,1.333.043,1.131.8,1.519.431,2.731-.226.724-.814,1.071-.785,3.022-1.389,1.818-.606,3.241-1.814,1.584-.666,1.285.271.259,1.258.485,1.062,1.729-.349.4-1.672.437-.918,1.26.078,1.208-.587,1-1.121.986-.162.957-.031,3,1.922,1.3,3.645.553.711.63.421,1.139,2.786,1.6.851,1.293-1.209.917-1.4-.21-1.422-1.006-.863-.02-1.07.675-1.539.251-1.7,1-1.326,1.636-.6,6.316-3.605,1.366-1.043,1.253-.784.016.506.325,1.189.4-.472.2-.653.006-.691-.2-.611-.354-.8.086-.336.465-.045.748.11.569.168,1.209.53.764.181,1.563.569.191-.045.118.058.127.433-.073.514-.258.533-.124.582.321.666.118.368-.289.2-.379.169-.118.245.2.32.2.023.207-.091.188-.029.652.4.306.061.133-.314.029-.414.121-.233.241-.046.411.172.477.33.907.811.484.336-.006-.362.067-.252.105-.213.1-.281.309.213.232.058.235-.074.315-.2-.665-.194-2.027-.863,1.333-1.655.519.169.226.449.118.553.207.485.343.281.878.426.4.349.213.353,1.26,2.829.394.368.1-.068,1.317.265.187.074,1.263-.368.181-.786-.467-.615-.688.139-.181-.469.134-.343.356-.2.493-.071.363.136.172.327.133.4.229.353.34.718-.36.637-.646.6-.5.624.248-.023.518.042.248-.02-.423,1.349-.057.437.105.3.219.3.181.369Z"
            transform="translate(-219.805 -129.096)"
            fill={color}
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="0.5"
        />
    );
};
export default MtwaraSvg;
