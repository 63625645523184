import React, { Fragment, useEffect, useState } from "react";
import SearchBox from "components/search-box";
import Button from "components/Button";
import SubprojectForm from "./form/subproject-form";
import SubprojectListItem from "./subproject-list-item";
import { useQuery } from "react-query";
import { getSubprojects } from "../../../provider/api";
import useGlobalContext from "../../../hooks/useGlobalContext";

const Subprojects = () => {
    const [showForm, setShowForm] = useState(false);
    const [activeSubproject, setActiveSubproject] = useState({});

    const [subprojects, setSubprojects] = useState([]);
    const { projectId } = useGlobalContext();
    const handleSearchResult = () => {
        // ...
    };

    // send get request to get a list of subproject
    const {} = useQuery(["subprojects", projectId], getSubprojects, {
        onSuccess: ({ data }) => {
            setSubprojects(data);
        },
        enabled: !!projectId,
    });

    // clear selected subprojects whenever parents id change
    useEffect(() => {
        setSubprojects([]);
    }, [projectId]);

    const handleEdit = (subproject) => {
        setActiveSubproject(subproject);
        setShowForm(true);
    };
    return (
        <Fragment>
            <SubprojectForm
                activeSubproject={activeSubproject}
                setShowForm={setShowForm}
                showForm={showForm}
                setActiveSubproject={setActiveSubproject}
            />
            <section className="min-h-full bg-white border rounded-lg s">
                <header>
                    <div className={"p-5 items-between w-full"}>
                        <h2 className="font-medium text-dark text-lg">
                            Subproject List
                        </h2>
                        <div className={"flex space-x-2 items-center"}>
                            <SearchBox
                                searchContext={{
                                    data: [],
                                    searchFun: handleSearchResult,
                                    searchProperty: "name",
                                }}
                                inputClass={"text-sm"}
                                wrapperClass={"h-9"}
                            />
                            <Button onClick={() => setShowForm(true)}>
                                Create{" "}
                            </Button>
                        </div>
                    </div>
                </header>

                <div>
                    {/* table header */}
                    <div className="grid grid-cols-6 px-5 py-2.5 mt-5 text-sm font-semibold uppercase text-black/60 bg-secondary/10">
                        <div className=" col-span-2">Subproject Name</div>
                        <div className="col-span-2">Description</div>
                        <div className="flex justify-center">Created On</div>
                    </div>

                    <div className="divide-y-[1px]">
                        {subprojects.map((subproject) => (
                            <SubprojectListItem
                                onEdit={handleEdit}
                                subproject={subproject}
                                key={subproject?._id}
                            />
                        ))}
                    </div>
                </div>
            </section>
        </Fragment>
    );
};
export default Subprojects;
