import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/free-solid-svg-icons";
import {useState} from "react";
import {useCommitmentDetailsContext} from "./commitment-details";

const KeyResultInputItem = (
    {
        keyResult,
        onRemove: handleRemove,
        setErrorList,
        errorList,
        index,
        onChange: updateKeyResults,
        inputFields
    }, ...props) => {
    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const {commitment} = useCommitmentDetailsContext();

    const handleChange = ({target}) => {
        const {name, value} = target;
        if (!value) {
            addError(`${name} field is required`);

        } else clearError()

        const list = [...inputFields];
        list[index][name] = value;
        updateKeyResults(list);

        if (target.type === "date") {
            if (!isDateValid(target.value)) {
                addError("Date must be between start date and end date of commitment");
                console.log(commitment.startDate)
                console.log(commitment.endDate)

            } else clearError()
        }
    }

    const clearError = () => {
        setIsError(false)
        setErrorList(errorList.filter((errId) => {
            return errId !== keyResult.id
        }))
        setErrorMessage(null)
    }

    const addError = (error) => {
        setErrorMessage(error);
        setErrorList([...errorList, keyResult.id])
        setIsError(true)
    }

    const isDateValid = (date) => {
        const startDate = new Date(commitment.startDate).getTime();
        const endDate = new Date(commitment.endDate).getTime();
        const dateToCheck = new Date(date);
        return dateToCheck >= startDate && dateToCheck <= endDate;
    }

    return (
        <div {...props}>
            <div className="my-1 flex flex-row space-x-4 justify-between">
                <div className="grid grid-cols-3 gap-2 form-group w-full">
                    <input
                        autoFocus
                        type="text"
                        onChange={handleChange}
                        defaultValue={keyResult.title}
                        name="title"
                        className="form-control col-span-2 border border-gray-300 text-sm focus:ring-0 "
                        placeholder="Key result expected..."
                        required={true}/>

                    <input
                        type="date"
                        name="dateToComplete"
                        defaultValue={keyResult.dateToComplete}
                        onChange={handleChange}
                        className="shadow-sm bg-white border border-gray-300 text-gray-800 text-sm focus:ring-0 p-2"
                        required={true}
                    />
                </div>
                <button className=" p-1"
                        onClick={() => handleRemove(keyResult.id)}>
                    <FontAwesomeIcon className="h-5 w-5" icon={faXmark} color={"FFFFFF"}/>
                </button>
            </div>

            {isError && <p className={'text-xs text-red-600'}>
                {errorMessage}
            </p>}
        </div>

    )
}
export default KeyResultInputItem;