import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import Folders from "../_partials/folders";
import DocumentsByCategories from "./docs-by-categories";
import FilesSummary from "./files-summary";
import RecentFiles from "./recent-files";
import { useProjectFiles } from "../../../hooks";
import { getRandomKey } from "../../../utils";
import Folder from "../_partials/folders/folder";

const ProjectFilesOverview = () => {
	const {
		projectFolders,
		isLoading,
		fileCategories = {},
		isFetchingCategories,
	} = useProjectFiles();
	const navigate = useNavigate();
	if (isLoading) {
		return <p>loading</p>;
	}
	const folders = Object.keys(projectFolders).map((key) => {
		return { _id: getRandomKey(), name: key };
	});

	const handleOpenFolder = (folder) => {
		navigate(folder.name);
	};

	const { recentFiles = [] } = fileCategories;

	return (
		<section className={"p-5 h-full  space-y-5"}>
			<div className={"space-y-2 pt-3"}>
				<p
					className={
						"text-[#0A0E1C] text-sm tracking-wide font-semibold"
					}
				>
					FILES FOLDERS
				</p>
				<div className={"grid grid-cols-5 2xl:grid-cols-7 gap-5"}>
					{folders.map((folder) => (
						<Folder
							key={folder._id}
							onOpenFolder={handleOpenFolder}
							folder={folder}
						/>
					))}
				</div>
			</div>
			<DocumentsByCategories />
			<FilesSummary />
			<RecentFiles files={recentFiles} />
		</section>
	);
};
export default ProjectFilesOverview;
