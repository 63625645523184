import { useCommitmentAnalytics } from "hooks";
import CommitmentCard from "./commitment-card";
import Loading from "./loading";

const OverdueCommitments = () => {
	const { isLoading, commitmentAnalytics } = useCommitmentAnalytics();
	if (isLoading) return <Loading />;
	const { overdue: overdueCommitments = [] } = commitmentAnalytics;
	if (!overdueCommitments.length) return;

	console.clear();
	console.log("Commitment", overdueCommitments);

	const handleClick = (commitment) => {};

	return (
		<div className={"space-y-2"}>
			<p className={"text-[#31394C] py-1 font-medium"}>
				Overdue Commitments
			</p>
			<div className={"grid grid-cols-3 gap-4"}>
				{overdueCommitments.map((commitment) => (
					<CommitmentCard
						onClick={handleClick}
						commitmentItem={commitment}
						key={commitment?._id}
					/>
				))}
			</div>
		</div>
	);
};
export default OverdueCommitments;
