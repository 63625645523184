import {ArrowsExpandIcon} from "@heroicons/react/outline";
import {useRef} from "react";
import StakeholderLocations from "./map";

const StakeholderBreakdownByLocation = ({stakeholders}) => {
    const mapContainer = useRef(null);

    const viewMapInFullScreen = () => {
        if (!mapContainer.current) return;
        mapContainer.current.requestFullscreen()
    }

    return (
        <div className={'bg-[#F5F9FA] overflow-hidden border-[1px]  rounded border-[#ECECED]'}>
            <div className={'flex items-center justify-between px-10 py-3'}>
                <p className={'text-[13px] tracking-wide text-[#002F4F] font-semibold'}>
                    STAKEHOLDER BREAKDOWN BY LOCATION
                </p>
                <ArrowsExpandIcon onClick={viewMapInFullScreen} className={'h-5 w-5 cursor-pointer text-[#628799]'}/>
            </div>

            <div ref={mapContainer} className={'h-[30rem]'}>
               <StakeholderLocations stakeholders={stakeholders}/>
            </div>

        </div>
    )
}
export default StakeholderBreakdownByLocation;