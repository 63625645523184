import { GlobalContext } from "../../context";
import { useQuery } from "react-query";
import {
    fetchProjectStakeholders,
    getProjectDetails,
    getProjectPhases,
} from "../api";
import { useEffect, useState } from "react";
import { isDateBetween, slugify } from "../../utils";
import {
    getValueFromLocalStorage,
    saveValueToLocalStorage,
} from "../../hooks/useLocalStorageState";
import { toast, ToastContainer } from "react-toastify";
import { useAuth } from "provider/auth/auth";
import { hasAnyPermission, permissions } from "../permission";
import { localStorageKeys, queryKeys } from "../../constants";

const GlobalContextProvider = ({ children }) => {
    const { authUser } = useAuth();
    const { project, defaultSubProject, defaultProject } = authUser;
    // states
    const [stakeholders, setStakeholders] = useState([]);
    const [internalStakeholder, setInternalStakeholder] = useState([]);
    const [externalStakeholder, setExternalStakeholder] = useState([]);
    const [projectDetails, setProjectDetails] = useState(defaultProject ?? {});
    const [subprojectDetails, setSubprojectDetails] = useState(
        getValueFromLocalStorage(localStorageKeys.SUBPROJECT_DETAILS, {})
    );
    const [projectId, setProjectId] = useState(
        defaultProject?._id ?? getValueFromLocalStorage("projectId")
    );
    const isWBAdmin = hasAnyPermission(permissions.MANAGE_PROJECTS_AND_PIUS);

    const [subprojectId, setSubprojectId] = useState(
        defaultSubProject?._id ?? getValueFromLocalStorage("subprojectId")
    );
    const [currentPhase, setCurrentPhase] = useState(
        getValueFromLocalStorage("currentPhase", null)
    );
    const [projectPhases, setProjectPhases] = useState([]);
    // const projectId = process.env.REACT_APP_PROJECT_ID;
    const [contentsView, setContentsView] = useState(
        getValueFromLocalStorage("contentsView", "analytics")
    );

    // send get request to get stakeholders
    const { isLoading, refetch: refetchStakeholders } = useQuery(
        queryKeys.STAKEHOLDERS,
        () => fetchProjectStakeholders(projectId),
        {
            onSuccess: ({ data }) => {
                groupStakeholders(data);
            },
            enabled: !!projectId && !isWBAdmin,
        }
    );

    // send get request to get project phases
    const { isLoading: isFetchProjectPhase, refetch: refetchProjectPhase } =
        useQuery("project-phases", () => getProjectPhases(projectId), {
            onSuccess: ({ data: phases }) => {
                setProjectPhases(phases);
            },
            enabled: !!projectId && !isWBAdmin,
        });

    /* send get request to get project details
       Todo: remove this, it unnecessary to send request to getting project information while
        we already have it in a user object
     */
    const {
        isLoading: isFetchingProjectDetails,
        refetch: refetchProjectDetails,
    } = useQuery("project-details", () => getProjectDetails(projectId), {
        onSuccess: ({ data }) => {
            setProjectDetails(data);
        },
        enabled: !!projectId && !project && !isWBAdmin,
    });

    const todayDate = new Date().getTime();

    // refetch stakeholders, project-phase, project-details whenever project-id is changed
    useEffect(() => {
        refetchProjectDetails();
        refetchStakeholders();
        refetchProjectPhase();
    }, [projectId, subprojectId]);

    useEffect(() => {
        setSubprojectDetails({});
    }, [projectId]);

    //  set current phase
    useEffect(() => {
        if (projectPhases.length) {
            setCurrentPhase(determineCurrentPhase(projectPhases));
        }
    }, [projectPhases]); // eslint-disable-line react-hooks/exhaustive-deps

    const determineCurrentPhase = (phases) => {
        const currPhase = phases.find((phase) => {
            return isDateBetween(todayDate, phase.startDate, phase.endDate);
        });
        saveValueToLocalStorage("currentPhase", currentPhase);
        return currPhase;
    };

    const groupStakeholders = (_stakeholders) => {
        setStakeholders(_stakeholders);
        setInternalStakeholder([
            ..._stakeholders.filter(
                (stakeholder) =>
                    stakeholder?.scope?.toLowerCase() === "internal"
            ),
        ]);
        setExternalStakeholder([
            ..._stakeholders.filter(
                (stakeholder) =>
                    stakeholder?.scope?.toLowerCase() === "external"
            ),
        ]);
    };

    const calculateStakeholderPriority = (
        levelOfInterest,
        levelOfInfluence
    ) => {
        const getLevelValue = (level) => {
            switch (level) {
                case "Weak": {
                    return 1;
                }
                case "Medium": {
                    return 2;
                }
                case "Strong": {
                    return 3;
                }
                default:
                    return 0;
            }
        };
        return (
            getLevelValue(levelOfInterest) * 30 +
            getLevelValue(levelOfInfluence) * 70
        );
    };

    const getStakeholderByPriority = () => {
        return stakeholders
            .map((stakeholder) => {
                return {
                    ...stakeholder,
                    priority: calculateStakeholderPriority(
                        stakeholder.levelOfInterest,
                        stakeholder.levelOfInfluence
                    ),
                };
            })
            .sort((a, b) => {
                return a.priority < b.priority ? 1 : -1;
            });
    };

    const getStakeholderByName = (name) => {
        return stakeholders.find((stakeholder) => {
            return slugify(stakeholder.name.toLowerCase()) === slugify(name);
        });
    };

    const getIndividualStakeholder = () => {
        return internalStakeholder.filter((stakeholder) => {
            return stakeholder?.type === "Individual";
        });
    };

    return (
        <GlobalContext.Provider
            value={{
                stakeholder: stakeholders,
                isLoading,
                projectId,
                externalStakeholder,
                internalStakeholder,
                isFetchProjectPhase,
                currentPhase,
                projectPhases,
                isFetchingProjectDetails,
                contentsView,
                toast,
                projectDetails,
                setContentsView,
                setProjectId,
                getStakeholderByPriority,
                getStakeholderByName,
                getIndividualStakeholder,
                setSubprojectId,
                subprojectId,
                setSubprojectDetails,
                subprojectDetails,
                // isLoadingSubprojectDetails
            }}
        >
            <ToastContainer />
            {children}
        </GlobalContext.Provider>
    );
};

export default GlobalContextProvider;
