import StepCard from "./step-card";
import { useParams } from "react-router-dom";

const FormStepIndicator = ({ currentStep, setCurrStep }) => {
    const { event_type: eventType } = useParams();
    const isMeeting = eventType === "meeting";
    // currentStep = 5

    const meetingSteps = [
        {
            number: 1,
            title: "Setup Agenda",
            path: "/event-registration/meeting",
        },
        {
            number: 2,
            title: "Participants",
            path: "/event-registration/meeting/add-participants",
        },
        {
            number: 3,
            title: "Set time & Date",
            path: "/event-registration/meeting/set-datetime",
        },
        {
            number: 4,
            title: "Location & Channel",
            path: "/event-registration/meeting/communication-channel",
        },
        {
            number: 5,
            title: "Add Attachment",
            path: "/event-registration/meeting/add-attachment",
        },
        {
            number: 6,
            title: "Set Reminder",
            path: "/event-registration/meeting/set-reminder",
        },
        {
            number: 7,
            title: "EventLayout Budget",
            path: "/event-registration/meeting/set-meeting-budget",
        },

        {
            number: 8,
            title: "Notify Participants",
            path: "/event-registration/meeting/set-notification",
        },
    ];

    return (
        <div className={"w-[300px] bg-white h-full"}>
            {isMeeting ? (
                <>
                    {meetingSteps.map((step) => (
                        <StepCard
                            setCurrStep={setCurrStep}
                            key={step.number}
                            stepPath={step.path}
                            stepTitle={step.title}
                            stepNumber={step.number}
                            isActive={step.number === currentStep}
                            isCompleted={step.number < currentStep}
                        />
                    ))}
                </>
            ) : (
                <>
                    <StepCard
                        stepTitle={"Basic information"}
                        currStep={currentStep}
                        isActive={1 === currentStep}
                        stepNumber={1}
                    />
                </>
            )}

            {/* decoration for maintain ui */}
            {[...Array(isMeeting ? 4 : 9)].map((num, idx) => (
                <StepCard stepNumber={null} key={idx} />
            ))}
        </div>
    );
};
export default FormStepIndicator;
