import {useEngagementContext} from "./engagement";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import React from "react";

const AttendanceSummary = () => {
    const {attendanceStatistics} = useEngagementContext();
    const {present = 0, late = 0, absent = 0} = attendanceStatistics();
    console.log(present, late, absent)
    const options = {
        plotOptions: {
            series: {
                dataLabels: {
                    enabled: true,
                    format: '{point.name}: {point.y:.0f}%'
                },
                point: {
                    events: {
                        click: function () {
                            window.open(this.options.url, '_blank').focus();
                        }
                    }
                }
            },
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                borderWidth: 0,
                dataLabels: {
                    enabled: false
                },
                colors: ["#A5DED4", "#FDD1A9", "#168ADC", "#FDC2C0"],
                showInLegend: false,
            }
        },

        title: {
            text: "",
            y: 3.5,
            style: {
                display: false,
                fontSize: '14px',
                fontWeight: "bold",
                color: "#253A53"
            }
        },
        chart: {
            renderTo: 'chart', // Spacing between Title & Chart
            defaultSeriesType: 'areaspline'
        },
        series: [{
            type: 'pie',
            name: 'Activities count',
            innerSize: '10%',
            data: [
                {
                    name: "late",
                    color: '#FFCA83',
                    y: late,
                },
                {
                    name: "absent",
                    color: '#F2726F',
                    y: absent
                },
                {
                    name: "present",
                    color: '#70AB91',
                    y: present
                }
            ]
        }],

    }

    return (
        <div className={' h-80 space-y-5 rounded border-[1px] border-[#E4E4E4] py-3 px-2'}>
            <p className={'text-center text-[#253A53] tracking-wide text-[15px] font-semibold '}>
                Overall Attendance Summary
            </p>
            <div className={'flex h-[80%] items-center'}>
                <div className={'h-56 w-full'}>
                    <div className={'h-full highcharts relative '}>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={options}
                        />
                    </div>
                </div>
                <div className={'w-28 space-y-4'}>
                    <AttendanceChartLabel
                        label={'Present'}
                        color={'#70AB91'}
                        total={present}
                    />

                    <AttendanceChartLabel
                        label={'Late'}
                        color={'#FFCA83'}
                        total={late}
                    />

                    <AttendanceChartLabel
                        label={'Absent'}
                        color={'#F2726F'}
                        total={absent}
                    />
                </div>
            </div>

        </div>
    )
}

export const AttendanceChartLabel = ({label, color, total}) => {
    return (
        <div className={'flex space-x-4'}>
            <div className={'h-4 w-4 mt-1 rounded-full'} style={{backgroundColor: color}}></div>
            <div>
                <p className={'text-[#666666] font-medium'}>{total}</p>
                <p className={'text-xs text-[#868686] font-light'}>{label}</p>
            </div>
        </div>
    )
}
export default AttendanceSummary