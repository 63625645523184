import { useMutation } from "react-query";
import { Navigate, useNavigate } from "react-router-dom";
import { updateUserInfo } from "../../provider/api";
import { saveValueToLocalStorage } from "../../hooks/useLocalStorageState";
import useGlobalContext from "hooks/useGlobalContext";
import { useAuth } from "../../provider/auth/auth";
import Button from "../../components/Button";
import { localStorageKeys } from "../../constants";

const SelectSubproject = () => {
    const navigate = useNavigate();
    const { projectId, setSubprojectId, setSubprojectDetails } =
        useGlobalContext();
    const { authUser } = useAuth();

    const getSubprojects = () => {
        const { subProjects = [] } = authUser;
        return subProjects.filter(
            ({ parentProject } = {}) => parentProject === projectId
        );
    };

    // send put request to update user details
    const { isLoading, mutate: updateUserDetails } = useMutation(
        "update-user-details",
        updateUserInfo,
        {
            onSuccess: ({ data: userDetails }) => {
                const { defaultSubProject } = userDetails;
                setSubprojectId(defaultSubProject);
                setSubprojectDetails(defaultSubProject);

                saveValueToLocalStorage(
                    localStorageKeys.SUBPROJECT_ID,
                    defaultSubProject._id
                );
                saveValueToLocalStorage(
                    localStorageKeys.SUBPROJECT_DETAILS,
                    defaultSubProject
                );
                navigate("/", { replace: true });
            },
            onError: () => {
                // ...
            },
        }
    );

    //return the list of subprojects by filtering them based on their parent-project id.
    if (getSubprojects().length < 1) {
        return <Navigate to={-1} replace={true} />;
    }

    const handleClick = (subproject) => {
        // update a user default project, so next time won't be necessary to select projects to get started
        updateUserDetails({
            userId: authUser._id,
            defaultSubProject: subproject._id,
        });
    };

    return (
        <section className="h-screen flex-col space-y-16 center bg-[#f4f4f4] px-56">
            <div className="w-full space-y-8 2xl:space-y-12">
                <h1 className="text-3xl font-semibold text-center text-dark">
                    Select Subproject To Get Started
                </h1>
                <small>{isLoading && "updating user default subproject"}</small>

                <div className={"grid-cols-3 gap-4 grid"}>
                    {getSubprojects().map((subproject) => (
                        <div
                            onClick={() => handleClick(subproject)}
                            className={
                                "h-40 2xl:h-56 bg-white space-y-4 flex-col center text-center p-4 hover:opacity-80 hover:shadow-lg cursor-pointer rounded-lg shadow-lg"
                            }
                        >
                            <p
                                className={
                                    "font-semibold text-dark 2xl:text-lg"
                                }
                            >
                                {subproject.name}
                            </p>
                        </div>
                    ))}
                </div>
            </div>
            <div>
                <Button className={"bg-accent py-3.5 px-7"}>
                    {isLoading ? "PLEASE WAIT.. " : "FINISH"}
                </Button>
            </div>
        </section>
    );
};

export default SelectSubproject;
