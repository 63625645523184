const Loading = () => {
    return (
        <div className={"h-full px-5 flex space-x-7 "}>
            <div
                className={"w-7 h-full flex flex-col justify-between  shrink-0"}
            >
                {[...Array(7)].map((item, index) => (
                    <p
                        key={index}
                        className={"h-3 w-full rounded skeleton-loading"}
                    ></p>
                ))}
            </div>
            <div
                className={
                    "w-full flex justify-between items-end space-x-4 h-full "
                }
            >
                {[90, 50, 79, 20, 95, 34, 90, 50, 79, 70, 95, 34].map(
                    (length) => (
                        <p
                            key={length}
                            style={{ height: `${length}%` }}
                            className={" w-10  rounded-t-lg skeleton-loading"}
                        ></p>
                    )
                )}
            </div>
        </div>
    );
};
export default Loading;
