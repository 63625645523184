import {useEffect, useState} from "react";
import CheckIcon from "./CheckIcon";

const CustomCheckBox = (
    {
        onCheck,
        checked = false,
        className, iconClass,
        activeClasses,
        inactiveClasses,
        activeIconColor = "#fff",
        inactiveIconColor = "#fff",
    }) => {
    const [isChecked, setIsChecked] = useState(false)

    useEffect(() => {
        setIsChecked(checked)
    }, [checked])

    useEffect(() => {
        onCheck(isChecked)
    }, [isChecked]) // eslint-disable-line react-hooks/exhaustive-deps

    const checkboxClass = `
        ${className} h-5 w-5 
        ${(!inactiveClasses && activeClasses && isChecked ?
        'bg-green-500 border-green-500' : 'bg-white border-[#838FAA]')
    } flex rounded-[3px] 
        ${isChecked ? activeClasses : inactiveClasses} items-center 
        justify-center border-[1.5px] cursor-pointer border-green-500
    `
    const handleOnClick = (e) => {
        e.stopPropagation()
        setIsChecked(!isChecked);
    }
    return (
        <div
            onClick={handleOnClick}
            className={checkboxClass}>
            <div className={`${isChecked ? 'opacity-100' : 'opacity-0'} transition-all`}>
                <CheckIcon
                    className={iconClass}
                    color={isChecked ? activeIconColor : inactiveIconColor}
                />
            </div>
        </div>
    )
}
export default CustomCheckBox;