import { WhatsAppIcon } from "../../../../../../../../../svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEarth, faPhone } from "@fortawesome/free-solid-svg-icons";
import CommunicationChannel from "./communication-channel";
import { useChannelContext } from "./channel";

const OnlineChannels = () => {
    const { selectedChannel, setSelectedChannel } = useChannelContext();

    const medias = [
        {
            value: "",
            icon: <WhatsAppIcon className={"h-6 w-6"} />,
            requireValue: 0,
            color: "#D5E7E4",
            name: "Whatsapp",
            description: "Via Whatsapp",
        },
        {
            value: "",
            name: "Phone",
            requireValue: 0,
            color: "#FAAD29",
            icon: <FontAwesomeIcon icon={faPhone} className={"h-6 w-6"} />,
            description: "Via normal calls",
        },
        {
            value: "",
            icon: (
                <FontAwesomeIcon
                    icon={faEarth}
                    className={"h-6 text-[#267CE1] w-6"}
                />
            ),
            requireValue: 1,
            color: "#DDEAFB",
            name: "Virtual Meeting",
            description:
                "Via platforms like Zoom, Google EventLayout and others.",
        },
    ];

    return (
        <div className={"space-y-2"}>
            <p className={"text-secondary text-lg"}>
                Choose Communication Medium
            </p>
            <div className={"space-y-3"}>
                {medias.map((media, idx) => (
                    <CommunicationChannel
                        key={idx}
                        data={media}
                        selectedChannel={selectedChannel}
                        isSelected={selectedChannel.name === media.name}
                        onSelect={setSelectedChannel}
                    />
                ))}
            </div>
        </div>
    );
};
export default OnlineChannels;
