import { useState, Fragment } from "react";
import ImageCard from "../../../project-images/image-card";
import FileGridViewCard from "../../_partials/file-grid-view-card";
import { isImageFile } from "../../../../utils";
import ImageViewer from "../../../../components/image-viewer/image-viewer";

const GridViewMode = ({ files: fileList }) => {
	const [viewImage, setViewImage] = useState(false);
	const [activeFile, setActiveFile] = useState(false); // clicked file

	const handleImageClick = (imageFile) => {
		setActiveFile(imageFile);
		setViewImage(true);
	};
	const files = fileList.map((file) => {
		if (isImageFile(file?.type)) {
			return (
				<ImageCard
					key={file?._id}
					imageFile={file}
					onClick={handleImageClick}
				/>
			);
		} else {
			return <FileGridViewCard key={file?._id} file={file} />;
		}
	});

	return (
		<Fragment>
			{activeFile ? (
				<ImageViewer
					file={activeFile}
					isOpen={viewImage}
					setIsOpen={setViewImage}
				/>
			) : null}
			<div className={"grid grid-cols-3 2xl:grid-cols-4 gap-4"}>
				{files}
			</div>
		</Fragment>
	);
};
export default GridViewMode;
