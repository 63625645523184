const UngujaSouthSvg = ({ color, isLoading = false }) => {
    return (
        <path
            id="Path_443"
            className={`${isLoading ? "animate-pulse" : ""}`}
            data-name="Path 443"
            d="M608.163,278.871l-.459-1.544.172-2.208-.045-.48.038-.461.108-.4.165-.291.118.208.3.4.115.217.652-1.062,1.4-1.164.646-.752.321-1.238.274-2.907.713-.71.458,1.2,1.193,2.155.238,1.381-.277,1.653-.016.614.121.6.343,1.119.07.579.178.611.708,1.164,0,0-.218.132-1.495.992-2.84.193-1.414-.128-1.422.121Zm1.1-10.393-.027.538.189.7.323.484-.269.4.242.51v.779l-.484.107-.43-.027-.081-.564.08-.7.107-1.1-.108-.807Z"
            transform="translate(-607.344 -266.483)"
            fill={color}
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="0.5"
        />
    );
};
export default UngujaSouthSvg;
