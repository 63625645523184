import InputCard from "../_partials/input-card";
import EditorJS from "../../../../../../components/editorjs/editor";
import { useConductMeetingContext } from "../index";
import { Fragment, useEffect, useState } from "react";
import FileUpload from "../../../../../../components/file-upload";
import UploadedFiles from "../../../../../../components/file-upload/uploaded-files";

const MeetingNote = () => {
    const { conductedInfo, setConductedInfo } = useConductMeetingContext();
    const [meetingNote, setMeetingNote] = useState(conductedInfo.meetingNote);
    const [uploadedFiles, setUploadedFiles] = useState(
        conductedInfo?.noteAttachments ?? []
    );
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        setConductedInfo({ ...conductedInfo, setConductedInfo, meetingNote });
    }, [meetingNote]);

    const handleEditorChange = (cleanData) => {
        setMeetingNote(cleanData);
    };

    const handleSubmit = (files) => {
        setUploadedFiles(files);
    };

    useEffect(() => {
        setConductedInfo({
            ...conductedInfo,
            noteAttachments: uploadedFiles,
        });
    }, [uploadedFiles]);

    return (
        <Fragment>
            <FileUpload
                isOpen={isOpen}
                onClose={setIsOpen}
                onSubmit={handleSubmit}
            />
            <InputCard title={"EventLayout Notes"}>
                <div className="p-3 space-y-5">
                    <UploadedFiles
                        onUpload={() => setIsOpen(true)}
                        showTitle={false}
                        files={uploadedFiles}
                        onRemove={setUploadedFiles}
                    />
                    <div className={"bg-[#FFFFFF] mb-3"}>
                        <EditorJS
                            uniqueId="meeting-note-text-area"
                            defaultValue={meetingNote}
                            onChange={handleEditorChange}
                        />
                    </div>
                </div>
            </InputCard>
        </Fragment>
    );
};
export default MeetingNote;
