import { removeValuesFromLocalStorage } from "hooks/useLocalStorageState";
import { localStorageKeys } from "../../../../../../../../../constants";

const clearForm = async () => {
    const { meetingForm: meetingLSKeys } = localStorageKeys;
    const keys = [
        meetingLSKeys.CURRENT_STEP,
        meetingLSKeys.REMAINDER,
        meetingLSKeys.BASIC_DETAILS,
        meetingLSKeys.PARTICIPANTS,
        meetingLSKeys.IS_UPDATING_DETAILS,
        meetingLSKeys.MEETING_BUDGET,
        meetingLSKeys.MEETING_CHANNEL,
        meetingLSKeys.ATTACHMENTS,
        meetingLSKeys.MEETING_TIME,
        meetingLSKeys.ACTIVE_EVENT,
    ];
    await removeValuesFromLocalStorage(keys);
};
export default clearForm;
