import {
    createContext,
    Fragment,
    useContext,
    useEffect,
    useState,
} from "react";
import { SearchIcon } from "@heroicons/react/outline";
import EventBudgetCard from "./event-budget-card";
import RejectBudgetForm from "./reject-budget-form";
import { useParams } from "react-router-dom";
import MeetingDetails from "../engagement/activity/events/_partials/meeting-details";
import SidebarRight from "components/sidebar-right";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { useQuery } from "react-query";
import { getUnapprovedEventsBudget } from "provider/api";
import { Tab } from "../stakeholder/list/stakeholder-details";
import { useAuth } from "../../../provider/auth/auth";
import useGlobalContext from "../../../hooks/useGlobalContext";

export const BudgetContext = createContext(null);
export const useApproveBudget = () => {
    const context = useContext(BudgetContext);
    if (!context) {
        throw new Error(
            "useApproveBudget hook should be used inside of BudgetContext provider"
        );
    }
    return context;
};

const BudgetApprove = () => {
    const { status: budgetStatus } = useParams();
    const [showRejectForm, setShowRejectForm] = useState(false);
    const [showEventDetails, setShowEventDetails] = useState(false);
    const [eventList, setEventList] = useState([]);
    const [filteredList, setFilteredList] = useState([]);
    const [isRejected, setIsRejected] = useState(false);
    const [activeEvent, setActiveEvent] = useState({});
    const { projectId } = useGlobalContext();
    const { authUser } = useAuth();

    const { isLoading } = useQuery(
        ["events-budget", projectId],
        () => {
            return getUnapprovedEventsBudget(authUser?._id, projectId);
        },
        {
            onSuccess: ({ data: _eventsList }) => {
                setEventList(_eventsList);
            },
        }
    );

    const getStatusValue = () => {
        if (budgetStatus === "pending") return 2;
        if (budgetStatus === "rejected") return 0;
        if (budgetStatus === "approved") return 1;
    };

    const getEventsByStatus = (events, status) => {
        // console.log('STATUS, ', status)
        // console.log('EVENT, ', events)
        return events.filter((_event) => {
            // console.log(_event.budgetStatus === status)
            // console.log(_event.budgetStatus)
            return _event.budgetStatus === status;
        });
    };

    const tabMenus = [
        {
            title: "Pending Budget",
            url: "/meeting-budget/pending",
            urlPattern: new RegExp("^/meeting-budget/pending$"),
        },
        {
            title: "Rejected Budget",
            url: "/meeting-budget/rejected",
            urlPattern: new RegExp("^/meeting-budget/rejected$"),
        },

        {
            title: "Approved Budget",
            url: "/meeting-budget/approved",
            urlPattern: new RegExp("^/meeting-budget/approved$"),
        },
    ];

    useEffect(() => {
        if (eventList) {
            const _events = getEventsByStatus(eventList, getStatusValue());
            setFilteredList(_events);
        }
    }, [eventList, budgetStatus]);

    return (
        <BudgetContext.Provider
            value={{
                showRejectForm,
                setShowRejectForm,
                activeEvent,
                isRejected,
                setIsRejected,
                setActiveEvent,
                setShowEventDetails,
            }}
        >
            <SidebarRight
                className={"w-[520px]"}
                onClose={setShowEventDetails}
                isOpen={showEventDetails}
            >
                <MeetingDetails event={activeEvent} />
            </SidebarRight>
            <RejectBudgetForm />
            <section
                className={
                    "px-10 h-full space-y-3 overflow-y-auto  bg-[#F9F9F9]"
                }
            >
                {/* search & filter */}
                <div
                    className={
                        "px-7 sticky top-0 bg-white/10 backdrop-blur-lg z-10"
                    }
                >
                    <div className=" w-full rounded pt-10">
                        <div
                            className={
                                " flex w-full top-0 rounded bg-white sticky border-[1px] p border-gray-200 px-4 z-30 items-end justify-between tracking-wide  shadow-sm h-14"
                            }
                        >
                            {/* Tab Menu */}
                            <div
                                className={
                                    "flex text-[13px] items-center text-[#A7B3C3] space-x-4"
                                }
                            >
                                {tabMenus.map((menuProp) => (
                                    <Tab key={menuProp.title} {...menuProp} />
                                ))}
                            </div>

                            <div
                                className={
                                    "flex space-x-3  h-full items-center"
                                }
                            >
                                <div
                                    className={
                                        "border-[2px] border-[#638798] flex items-center space-x-2" +
                                        " rounded overflow-hidden text-[#628799] px-2 py-1.5"
                                    }
                                >
                                    <SearchIcon className={"h-5 w-5"} />
                                    <input
                                        type="text"
                                        className={
                                            "h-full font-light bg-transparent p-0 border-none focus:ring-0 text-sm w-full outline-none placeholder-[#628799]"
                                        }
                                        placeholder={"Search meetings"}
                                    />
                                </div>
                                <div
                                    className={
                                        "h-8 w-8 rounded relative bg-secondary center text-white"
                                    }
                                >
                                    <FontAwesomeIcon
                                        icon={faFilter}
                                        className={"h-5 w-5"}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* event list */}
                <div className={""}>
                    <div
                        className={
                            "gap-5 grid grid-cols-3 2xl:grid-cols-4 py-10 px-7 rounded-lg"
                        }
                    >
                        {!isLoading && !filteredList.length ? (
                            <div className={"h-[70vh] center col-span-12"}>
                                <p className={"text-sm text-black/40"}>
                                    There is currently no {budgetStatus} budget.
                                </p>
                            </div>
                        ) : (
                            <Fragment>
                                {filteredList.map((event) => {
                                    return (
                                        <EventBudgetCard
                                            event={event}
                                            key={event?._id}
                                        />
                                    );
                                })}
                            </Fragment>
                        )}
                    </div>
                    <div></div>
                </div>
            </section>
        </BudgetContext.Provider>
    );
};
export default BudgetApprove;
