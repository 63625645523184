import { EventContext } from "../../../context";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { getAllEvents } from "../../api";
import { isToday } from "utils";
import useGlobalContext from "hooks/useGlobalContext";

const EventProvider = ({ children }) => {
    const [events, setEvents] = useState([]);
    const [todayEvents, setTodayEvents] = useState([]);
    const [upcomingEvents, setUpcomingEvents] = useState([]);
    const [completedEvents, setCompletedEvents] = useState([]);
    const [overdueEvents, setOverdueEvents] = useState([]);
    const [nonApprovedEventBudget, setNonApprovedEventBudget] = useState(false);
    const { projectId } = useGlobalContext();
    const todayDate = new Date().getTime();

    const { isLoading, refetch: refetchEventList } = useQuery(
        "event-list",
        () => getAllEvents(projectId),
        {
            onSuccess: (response) => groupEvents(response.data),
            enabled: !!projectId,
        }
    );

    // refetch project-event whenever project-id is changed
    useEffect(() => {
        refetchEventList();
    }, [projectId]);

    const groupEvents = (eventsList) => {
        setEvents(eventsList);

        // update today events
        setTodayEvents(
            eventsList.filter((event) => {
                return isToday(event.eventDate) && !event.status;
            })
        );

        // update upcoming data
        setUpcomingEvents(
            eventsList.filter((event) => {
                return new Date(event.eventDate) >= todayDate && !event.status;
            })
        );

        // update completed Event
        setCompletedEvents(
            eventsList.filter((event) => {
                return event.status;
            })
        );

        // update overdue Event
        setOverdueEvents(
            eventsList.filter((event) => {
                return !event.status && new Date(event.eventDate) < todayDate;
            })
        );

        setNonApprovedEventBudget(
            eventsList.filter((event) => {
                return event?.isApproved === false || !event?.budgetStatus;
            })
        );
    };

    const getEventById = (id) => {
        if (events) {
            return events.find((event) => event._id === id);
        }
        return {};
    };

    const getEventByTitle = (title) => {
        if (events) {
            return events.find((event) => event.title.toLowerCase() === title);
        }
        return {};
    };

    const groupEventParticipants = (participants = []) => {
        const individualStakeholder = participants.filter((participant) => {
            return participant.stakeholder.type === "Individual";
        });

        const organizationalStakeholder = participants.filter((participant) => {
            return participant.stakeholder.type === "Organisational";
        });

        return [
            individualStakeholder.map((participant) => participant.stakeholder),
            organizationalStakeholder.map(
                (participant) => participant.stakeholder
            ),
        ];
    };

    return (
        <EventContext.Provider
            value={{
                completedEvents,
                overdueEvents,
                upcomingEvents,
                nonApprovedEventBudget,
                todayEvents,
                getEventByTitle,
                isLoading,
                getEventById,
                groupEventParticipants,
            }}
        >
            {children}
        </EventContext.Provider>
    );
};

export default EventProvider;
