import Modal from "../Modal";

import { createContext, useContext, useState } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile, faXmark } from "@fortawesome/free-solid-svg-icons";
import { CloudUploadIcon } from "@heroicons/react/outline";
import useClickOutside from "../../hooks/useClickOutside";
import SelectedFile from "../../pages/general/engagement/activity/conduct-meeting/grievance/selected-attachments";

export const FileUploadContext = createContext(null);
const FileUpload = ({
                        onClose: setOpenModal,
                        isOpen,
                        onSubmit: handleSubmit,
                        fileTypes,
                    }) => {
    const [files, setFiles] = useState([]);
    const [uploadedFiles, setUploadedFiles] = useState([]);

    const handleChange = ({target}) => {
        setFiles([...files, ...target.files]);
    };

    const handleClose = () => {
        handleSubmit(uploadedFiles);
        setFiles([]); // clear list
        setUploadedFiles([]);
        setOpenModal(false);
    };
    return (
        <Modal onClose={handleClose} isOpen={isOpen}>
            <FileUploadContext.Provider
                value={{
                    uploadedFiles,
                    setUploadedFiles,
                    setFiles,
                    files,
                    fileTypes,
                }}
            >
                <div
                    className={
                        "w-[55%]  2xl:w-[40%] shadow-base mt-5 grid grid-cols-2 overflow-hidden rounded-xl bg-white h-[30rem]"
                    }
                >
                    <div className={"center"}>
                        <div
                            className={
                                "center text-black/70 flex-col text-center space-y-3"
                            }
                        >
                            <CloudUploadIcon className={"h-16 w-16"}/>
                            <div className={"tracking-wide"}>
                                <h5 className="font-medium text-dark">
                                    Upload File
                                </h5>

                                <p className="text-[15px]">
                                    Uploaded file must be less than or equal to{" "}
                                    <br/>
                                    <span className={"font-semibold"}>
                                        {" "}
                                        1MB
                                    </span>
                                </p>
                            </div>
                            <label htmlFor={"fileUpload"} className={"center"}>
                                <input
                                    accept={fileTypes}
                                    onChange={handleChange}
                                    id={"fileUpload"}
                                    type="file"
                                    multiple={true}
                                    hidden={true}
                                />
                                <p
                                    className={
                                        "bg-accent tracking-wide cursor-pointer hover:bg-accent/90 text-white text-sm py-2 px-4 rounded-full "
                                    }
                                >
                                    Browse Files
                                </p>
                            </label>
                        </div>
                    </div>

                    <div
                        className={
                            "bg-[#f4f4f4] space-y-2 relative  h-full overflow-y-auto"
                        }
                    >
                        {files.length ? (
                            <div className={"space-y-4"}>
                                <div
                                    className={
                                        "font-medium items-between z-10 sticky top-0 px-4 py-2 bg-[#edeff0] "
                                    }
                                >
                                    <div>
                                        <p className={"text-lg text-black/70"}>
                                            Uploaded Files
                                        </p>
                                        <p
                                            className={
                                                "text-sm text-black/50 tracking-wider"
                                            }
                                        >
                                            <span>{uploadedFiles.length}</span>/
                                            <span>{files.length}</span>
                                            <span> Uploaded</span>
                                        </p>
                                    </div>

                                    <div>
                                        <button
                                            onClick={handleClose}
                                            className="px-2.5 py-1 text-xs text-white rounded-full cursor-pointer bg-accent hover:bg-accent/90"
                                        >
                                            Done
                                        </button>
                                    </div>
                                </div>
                                <div className={"space-y-4 px-4 "}>
                                    {files.map((file) => (
                                        <SelectedFile
                                            key={file.name}
                                            file={file}
                                            setUploadedFiles={setUploadedFiles}
                                            setFiles={setFiles}
                                            files={files}
                                            uploadedFiles={uploadedFiles}
                                        />
                                    ))}
                                </div>
                            </div>
                        ) : (
                            <div
                                className={
                                    "h-full tracking-wide center text-sm text-gray-400"
                                }
                            >
                                <p>Uploaded files will display here</p>
                            </div>
                        )}

                        {/* close button */}
                        {!files.length ? (
                            <button
                                onClick={handleClose}
                                className={
                                    "absolute top-0 h-7 w-7 center z-50 rounded-full right-2 bg-gray-300/50 text-gray-500 hover:bg-gray-300"
                                }
                            >
                                <FontAwesomeIcon
                                    icon={faXmark}
                                    className={"h-5 w-5"}
                                />
                            </button>
                        ) : null}
                    </div>
                </div>
            </FileUploadContext.Provider>
        </Modal>
    );
};
FileUpload.propTypes = {
    onClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
};

export const SampleTest = () => {
    const inputClassName =
        "w-full rounded-lg tracking-wide  focus:ring-secondary/80 text-xs bg-secondary/10 border-none";
    const [addDescription, setAddDescription] = useState(false);
    const popoverRef = useClickOutside(() => {
        setAddDescription(false);
    });

    const popoverClassName = `
            absolute enable-transition ${
        addDescription
            ? "opacity-100 scale-100 w-[200px] pointer-events-auto"
            : "opacity-0 scale-500 pointer-events-none w-[50px]"
    }  
            top-full space-y-1 rounded-xl right-0 p-2 bg-white 
            shadow-base  border`;
    return (
        <div
            className={
                "bg-white shadow-base p-[2px] px-1 relative group relative flex items-center rounded-md"
            }
        >
            <div className={" flex items-center rounded-xl w-full"}>
                <FontAwesomeIcon
                    icon={faFile}
                    className={"h-7 w-7 p-3 text-secondary"}
                />
                <div className={"w-[90%] space-y-2 py-[1.5px]"}>
                    <div className={"items-between"}>
                        <p
                            className={
                                "tracking-wide text-sm w-48 whitespace-nowrap truncate cursor-pointer hover:underline"
                            }
                        >
                            Filename
                        </p>
                        <div className={"text-sm relative text-gray-500"}>
                            <button onClick={() => setAddDescription(true)}>
                                Info
                            </button>

                            <div
                                ref={popoverRef}
                                className={`${popoverClassName} z-50 backdrop-blur`}
                            >
                                <input
                                    type="text"
                                    placeholder={"Title"}
                                    className={`${inputClassName}`}
                                />
                                <textarea
                                    style={{resize: "none"}}
                                    placeholder={"Description"}
                                    className={`${inputClassName} scrollbar-hide h-12`}
                                ></textarea>
                            </div>
                        </div>
                    </div>
                    <div className={"h-1 w-full bg-gray-200 rounded-full mr-2"}>
                        <div
                            className={"h-full bg-secondary/90 rounded-full"}
                            style={{width: `45%`}}
                        ></div>
                    </div>
                </div>

                <div className={" px-3 "}>
                    <div
                        className={
                            " cursor-pointer h-5 hover:bg-gray-200 bg-gray-100 w-5 center rounded-full"
                        }
                    >
                        <FontAwesomeIcon
                            icon={faXmark}
                            className={"h-3 w-3  text-gray-500"}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
export default FileUpload;
export const useFileUploadContext = () => useContext(FileUploadContext);
