import { Fragment, useEffect, useState } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import DroppableColumn from "./droppable-column";
import useGrievances from "../../../hooks/useGrievances";
import InvestigatorForm from "./form/investigator-form";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const removeFromList = (list, index) => {
    const result = Array.from(list);
    const [removed] = result.splice(index, 1);
    return [removed, result];
};

const addToList = (list, index, element) => {
    const result = Array.from(list);
    result.splice(index, 0, element);
    return result;
};

const lists = [
    "Received Complaints",
    "Under Investigation",
    "Resolution in Progress",
    "Resolved",
];
const listColors = {
    "Received Complaints": {
        border: "border-[#fb923c]",
        icon: "text-[#fdba74]",
        bgOnDrop: "bg-orange-50/50",
        borderColor: "border-orange-500",
        badge: "bg-orange-200 text-orange-700",
    },
    "Under Investigation": {
        border: "border-[#628799]",
        icon: "text-[#628799]",
        bgOnDrop: "bg-[#ebeff2]/50",
        borderColor: "border-orange-500",
        badge: "bg-gray-300 text-gray-800",
    },
    "Resolution in Progress": {
        border: "border-[#009EE2]",
        icon: "text-[#009EE2]",
        bgOnDrop: "bg-blue-50/80",
        borderColor: "border-orange-500",
        badge: "bg-blue-200 text-blue-800",
    },
    Resolved: {
        border: "border-[#51BF82]",
        icon: "text-[#51BF82]",
        borderColor: "border-orange-500",
        bgOnDrop: "bg-green-50/50",
        badge: "bg-green-200 text-green-800",
    },
};

const DndContainer = ({ setActiveGrievance, setDetailsOpen }) => {
    const {
        allGrievances,
        isFetchingGrievances,
        saveGrievanceStatus,
        removeGrievance,
        investigatorFormRef,
        errorDeletingGrievance,
        successDeletingGrievance,
    } = useGrievances();
    const [openAnInvestigatorForm, setOpenAnInvestigatorForm] = useState(false);
    const [activeGrievance_, setActiveGrievance_] = useState({});

    const generateLists = () => {
        return {
            "Received Complaints": [
                ...allGrievances.received,
                ...allGrievances.reopened,
            ],
            "Under Investigation": allGrievances.investigation,
            "Resolution in Progress": allGrievances.inProgress,
            Resolved: allGrievances.resolved,
        };
    };

    const [elements, setElements] = useState(generateLists());

    useEffect(() => {
        setElements(generateLists());
    }, [allGrievances]);

    const onDragEnd = (result) => {
        console.log("Result", result);
        if (!result.destination) {
            return;
        }
        const listCopy = { ...elements };
        console.log("listCopy", listCopy);
        const sourceList = listCopy[result.source.droppableId];

        // remove from source list
        const [removedElement, newSourceList] = removeFromList(
            sourceList,
            result.source.index
        );

        const grievanceStatus = lists.indexOf(result.destination.droppableId);
        if (grievanceStatus > 1 && !removedElement?.investigatedOn) {
            // Todo show warning "Grievance should be investigated first".
            toast.warn("Investigation of complaints is required.", {
                delay: 0,
            });
            return;
        }
        listCopy[result.source.droppableId] = newSourceList;
        const destinationList = listCopy[result.destination.droppableId];
        listCopy[result.destination.droppableId] = addToList(
            destinationList,
            result.destination.index,
            removedElement
        );

        setElements(listCopy);

        const grievancesPayload = {
            id: removedElement._id,
            status: lists.indexOf(result.destination.droppableId),
        };

        if (grievancesPayload.status === 1 && !removedElement?.investigator) {
            console.log("REMOVED:: ", removedElement);
            setActiveGrievance_(removedElement);
            setOpenAnInvestigatorForm(true);
            return;
        }
        if (grievancesPayload.status === 3) {
            // update resolvedOn with curr-datetime date when a grievance is resolve
            grievancesPayload["resolvedOn"] = new Date();
        }
        saveGrievanceStatus(grievancesPayload);
        // console.log(result.destination.droppableId);
    };

    const changeStatus = (prefix, index, _id, status) => {
        const listCopy = { ...elements };
        const sourceList = listCopy[prefix];
        const [removedElement, newSourceList] = removeFromList(
            sourceList,
            index
        );
        listCopy[prefix] = newSourceList;

        setElements(listCopy);

        let grievancesPayload = {
            id: _id,
            status: status,
        };
        saveGrievanceStatus(grievancesPayload);
    };

    const handleDelete = (prefix, index, _id) => {
        if (window.confirm("Are you sure you want to delete this complaint?")) {
            removeGrievance(_id);
            if (errorDeletingGrievance) {
                window.alert("An error occurred, please try again...");
            } else if (successDeletingGrievance) {
                const listCopy = { ...elements };
                const sourceList = listCopy[prefix];
                const [removedElement, newSourceList] = removeFromList(
                    sourceList,
                    index
                );
                listCopy[prefix] = newSourceList;
                setElements(listCopy);
            }
        }
    };

    return (
        <Fragment>
            {/* An investigator form */}
            <InvestigatorForm
                ref={investigatorFormRef}
                activeGrievance={activeGrievance_}
                showForm={openAnInvestigatorForm}
                setShowForm={setOpenAnInvestigatorForm}
            />

            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            {isFetchingGrievances ? (
                <div className="animate-pulse w-full grid grid-cols-4 gap-4 h-[88%] overflow-hidden">
                    {[...Array(4)].map((_, index) => (
                        <div
                            key={index}
                            className={`bg-slate-200 h-full w-full mb-10 border-t-4 rounded-t`}
                        ></div>
                    ))}
                </div>
            ) : (
                <DragDropContext onDragEnd={onDragEnd}>
                    <div className="w-full grid grid-cols-4 gap-4 h-[88%] overflow-hidden">
                        {lists.map((listKey) => (
                            //console.log(elements),
                            <DroppableColumn
                                colors={listColors[listKey]}
                                elements={elements[listKey]}
                                key={listKey}
                                prefix={listKey}
                                changeStatus={changeStatus}
                                handleDelete={handleDelete}
                                setActiveGrievance={setActiveGrievance}
                                setDetailsOpen={setDetailsOpen}
                            />
                        ))}
                    </div>
                </DragDropContext>
            )}
        </Fragment>
    );
};

export default DndContainer;
