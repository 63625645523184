import { useNavigate } from "react-router-dom";
import { ArrowRightIcon } from "../../../svg";
import React from "react";
import Image from "../../../components/image";
import { useProjectImages } from "../../../hooks";
import { flatten } from "utils";

const MeetingImages = () => {
	const navigate = useNavigate();
	const { isLoading, images = {} } = useProjectImages();

	const handleClick = () => {
		navigate("meeting");
	};

	const folders = ["meetingNotes", "meetingFiles"];

	const arrOfImage = flatten(
		folders.map((folderName) => {
			const folderFiles = images[folderName] ?? [];
			const files = folderFiles.map((file) => file?.attachments);
			return files;
		})
	);
	const defaultImage =
		"https://i.pinimg.com/564x/5a/e9/50/5ae9501fc3b49810db7901873f77d6f7.jpg";

	return (
		<div
			onClick={handleClick}
			className={
				" relative overflow-hidden cursor-pointer h-[11.5rem] rounded-[10px]"
			}
		>
			<Image
				imageUrl={arrOfImage?.at(-1)?.url ?? defaultImage}
				alt="meeting"
			/>

			<div
				className={
					"bg-black/50 hover:bg-black/40  p-6 text-white flex flex-col justify-between absolute inset-0"
				}
			>
				<div>
					<p>Meeting Photos</p>
					<p className={"font-light text-sm"}>
						{arrOfImage.length} photos
					</p>
				</div>
				<div>
					<div className={`h-7 w-7 bg-white rounded-full center`}>
						<ArrowRightIcon
							color={"#628799"}
							className={"h-5 w-6"}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};
export default MeetingImages;
