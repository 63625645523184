import ClosedGrievanceCard from "../../grievance/closed-grievance/closed-grievance-card";
import {HighLevelIcon, LowLevelIcon, MediumLevelIcon} from "../../../../svg";
import {useEngagementAnalytics} from "../../../../hooks";
import Loading from "./loading";

const StakeholderEngagementLevel = () => {
    const {isLoading, engagementAnalytics} = useEngagementAnalytics();
    const {byLevel: engagementLevel} = engagementAnalytics;
    if (isLoading) return <Loading/>;

    const getPercentage = (level) => {
        switch (level) {
            case "high": {
                return [
                    Math.floor(
                        (engagementLevel?.high * 100) / engagementLevel?.total
                    ) || 0,
                    engagementLevel?.high,
                ];
            }
            case "medium": {
                return [
                    Math.floor(
                        (engagementLevel?.medium * 100) / engagementLevel?.total
                    ) || 0,
                    engagementLevel?.medium,
                ];
            }
            case "low": {
                return [
                    Math.floor(
                        (engagementLevel?.low * 100) / engagementLevel?.total
                    ) || 0,
                    engagementLevel?.low,
                ];
            }
            case "notEngaged": {
                return [
                    Math.floor(
                        (engagementLevel?.notEngaged * 100) /
                        engagementLevel?.total
                    ) || 0,
                    engagementLevel?.notEngaged,
                ];
            }
            default:
                return 0;
        }
    };

    const grievances = [
        {
            title: "High Engagement",
            range: "80% - 100%",
            percentage: getPercentage("high")[0],
            total: getPercentage("high")[1] + " Stakeholder",
            icon: <HighLevelIcon className={"h-6 w-6"}/>,
        },
        {
            title: "Medium Engagement",
            range: "31% - 79%",
            percentage: getPercentage("medium")[0],
            total: getPercentage("medium")[1] + " Stakeholder",
            icon: <MediumLevelIcon className={"h-6 w-6"}/>,
        },
        {
            title: "Low Engagement",
            range: "0% - 30%",
            percentage: getPercentage("low")[0],
            total: getPercentage("low")[1] + " Stakeholder",
            icon: <LowLevelIcon className={"h-6 w-6"}/>,
        },
        {
            title: "Not Engaged",
            range: "",
            percentage: getPercentage("notEngaged")[0],
            total: getPercentage("notEngaged")[1] + " Stakeholder",
            icon: <LowLevelIcon className={"h-6 w-6"}/>,
        },
    ];

    return (
        <div
            className={
                "bg-white border-[1px] border-[#E5E5E5] rounded-[7px] space-y-4 p-7"
            }
        >
            <p className={"text-[#31394C] font-medium text-[15px]"}>
                Stakeholder Engagement Level
            </p>
            <div className={"grid grid-cols-3 2xl:grid-cols-4 gap-4"}>
                {grievances.map((grievance) => (
                    <ClosedGrievanceCard
                        bgColor={"#F5F7FB"}
                        key={grievance.title}
                        grievance={grievance}
                    />
                ))}
            </div>
        </div>
    );
};
export default StakeholderEngagementLevel;
