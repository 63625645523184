import React, { useEffect, useState } from "react";
import { useProjectFiles } from "../../../hooks";
import { useParams, useNavigate } from "react-router-dom";
import Folders, { FolderHeader } from "../_partials/folders";
import dayjs from "dayjs";

const Folder = () => {
	const { isLoading, getFilesByFolder } = useProjectFiles();
	const { folderName } = useParams();
	const [folderList, setFolderList] = useState([]);
	const [filteredFolderList, setFilteredFolderList] = useState([]);
	const navigate = useNavigate();

	useEffect(() => {
		if (!isLoading) {
			getFolders();
		}
	}, [isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

	if (isLoading) return <p>loading ..</p>;

	const getFolders = () => {
		const folders = getFilesByFolder(folderName) ?? [];
		const structuredFolders = folders.map((folder) => {
			return {
				name: folder.title,
				_id: folder._id,
				date: folder.date,
				formattedDate: dayjs(folder.date).format("DD, MMMM YYYY"),
			};
		});

		setFolderList(structuredFolders);
		setFilteredFolderList(structuredFolders);
	};

	const handleOpenFolder = (folder) => {
		const formatDate = (date) => {
			return dayjs(date).format("DD-MM-YYYY");
		};
		navigate(`${folder.name}/${formatDate(folder.date)}`);
	};

	const handleSearch = ({ target }) => {
		const keyword = target.value.toLowerCase();
		return setFilteredFolderList(
			folderList.filter((folder) => {
				return (
					folder?.name?.toLowerCase().includes(keyword) ||
					folder?.formattedDate.toLowerCase().includes(keyword)
				);
			})
		);
	};

	return (
		<section className={"space-y-8"}>
			<FolderHeader onSearch={handleSearch} currPath={folderName} />
			<div className={"px-8 "}>
				<Folders
					folders={filteredFolderList}
					onOpenFolder={handleOpenFolder}
				/>
			</div>
		</section>
	);
};
export default Folder;
