import CommitmentCompletionRate from "./complition-rate";
import CommitmentSummary from "./summary";
import DepartmentsByBarChart from "./department";
import OverdueCommitments from "./overdue";
import CommitmentAnalyticsProvider from "provider/analytics/commitments/commitment-analytics-provider";


const CommitmentAnalytics = () => {
    return (
        <CommitmentAnalyticsProvider>
            <section className={'p-10 h-full overflow-y-auto bg-[#F1F6F9] space-y-8'}>
                <div className={'flex space-x-6'}>
                    <CommitmentCompletionRate/>
                    <CommitmentSummary/>
                </div>
                <DepartmentsByBarChart/>
                <OverdueCommitments/>
            </section>
        </CommitmentAnalyticsProvider>
    )
}
export default CommitmentAnalytics;