import {ChevronRightIcon} from "@heroicons/react/outline";
import Input from "../../../components/form-control/Input";
import Button from "../../../components/Button";
import {useAuth} from "../../../provider/auth/auth";
import {useMutation} from "react-query";
import {updateUserInfo} from "../../../provider/api";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {UserProfileFormValidation} from "../../../forms/validation";
import Select from "../../../components/form-control/Select";
import Option from "../../../components/form-control/Option";

const GeneralSettings = () => {
    const {authUser, saveAuthUser, authToken} = useAuth();
    const {handleSubmit, register, formState: {errors}} = useForm({
        resolver: yupResolver(UserProfileFormValidation)
    });
    // update user information
    const {isLoading: isUpdating, mutate: _updateUserInfo} = useMutation('update-user', updateUserInfo, {
        onSuccess: ({data: updatedUser}) => {
            updateAuthUser(updatedUser);
            console.log("Updated User", updatedUser);
        },
        onError: ({developerMessage}) => {
            // setErrorMessage(developerMessage)
        }
    })

    const onSubmit = (formData) => {
        _updateUserInfo({
            ...formData,
            userId: authUser._id

        })
    }

    const updateAuthUser = (_authUser) => {
        // saveAuthUser(_authUser, authToken, false)
    }

    return (
        <section className={' h-full pt-5 overflow-y-auto flex space-x-10'}>
            {/* users  */}
            <div className={'space-y-10 w-[370px] sticky top-0'}>
                {/* profile picture */}
                <div className={'space-y-5'}>
                    <div className={'h-40 w-40 overflow-hidden rounded-[5px] bg-red-500'}>
                        <img src="https://i.pinimg.com/564x/82/9a/dd/829add583dcc0ffb365a8cbd7f5b9436.jpg"
                             className={'h-full w-full object-cover'} alt=""/>
                    </div>

                    <button
                        className={'bg-[#F0F1F7] w-40 flex rounded items-center text-[#545871] text-[13px] py-2 px-4 space-x-1.5'}>
                        <span>icon</span>
                        <span>Upload photo</span>
                    </button>
                </div>

                <div className={'flex space-x-7 text-[#3E3D3D'}>
                    <div className={'space-y-2 font-semibold ]'}>
                        <p>Account Name:</p>
                        <p>Email:</p>
                        <p>Phone:</p>
                    </div>

                    <div className={'space-y-2 opacity-90'}>
                        <p>{authUser.firstName} {authUser.lastName}</p>
                        <p>{authUser.email}</p>
                        <p>{authUser.phoneNumber}</p>
                    </div>
                </div>
            </div>

            {/* user form */}
            <div style={{width: "calc(100% - 375px)"}} class={'space-y-5'}>
                <h2 className={'text-lg text-[#262626] font-medium'}> Review and update your account details</h2>
                <p className={'text-[#59626A] pt-4font-sm'}>
                    Review your details about the account frequently so that to stay in touch and to receive <br/>
                    Various news and important information on time.
                </p>

                <div className={'py-3'}>
                    <div className={'flex text-sm items-center text-accent space-x-1'}>
                        <ChevronRightIcon className={'h-5 w-5'}/>
                        <p>Learn more about this</p>
                    </div>
                </div>

                {/* user details form */}
                <div className={' '}>
                    <form onSubmit={handleSubmit(onSubmit)} className={'rounded bg-[#F5F6F7] overflow-hidden'}
                          style={{boxShadow: "0px 3px 6px #0000001A"}}>
                        <div className={' bg-white p-8'}>
                            <h2 className={'text-lg font-medium text-dark3'}>Account Information</h2>
                            <p className={'text-[#59626A] font-light'}>Please make sure these details are true up to
                                date</p>
                        </div>

                        <div className={'grid grid-cols-2 gap-8 p-8'}>
                            <div class={'space-y-2'}>
                                <label htmlFor="" className={'font-semibold text-[15px] text-dark3 '}>Your Full
                                    Name</label>
                                <Input register={register} name={"fullName"}
                                       defaultValue={`${authUser.firstName} ${authUser.lastName}`}
                                       className={'text-sm'}/>
                            </div>

                            <div className={'space-y-2'}>
                                <label htmlFor="" className={'font-semibold text-[15px] text-dark3 '}>
                                    Email Address
                                </label>
                                <Input
                                    register={register}
                                    name={'email'}
                                    defaultValue={authUser.email}
                                    className={'text-sm'}
                                />
                            </div>

                            <div className={'space-y-2'}>
                                <label htmlFor="" className={'font-semibold text-[15px] text-dark3 '}>
                                    Location
                                </label>
                                <Input
                                    register={register}
                                    name={'location'}
                                    className={'text-sm'}/>
                            </div>

                            <div className={'space-y-2'}>
                                <label htmlFor="" className={'font-semibold text-[15px] text-dark3 '}>
                                    Phone number
                                </label>
                                <Input
                                    name={'phoneNumber'}
                                    register={register}
                                    defaultValue={authUser.phoneNumber}
                                    className={'text-sm'}/>
                            </div>

                            <div className={'space-y-2'}>
                                <label htmlFor="gender" className={'font-semibold text-[15px] text-dark3 '}>
                                    Gender</label>
                                <Select defaultValue={authUser?.gender}
                                        isError={!!errors?.gender?.message} {...register('gender')}>
                                    <option selected={true} disabled={true} value="">
                                        Choose
                                    </option>
                                    <Option value={'F'} text={'Female'}/>
                                    <Option value={'M'} text={'Male'}/>
                                </Select>
                            </div>

                            <div className={'space-y-2'}>
                                <label htmlFor="" className={'font-semibold text-[15px] text-dark3 '}>
                                    Your Position in organisation
                                </label>
                                <Input
                                    name={'position'}
                                    register={register}
                                    className={'text-sm'}
                                />
                            </div>
                        </div>

                        <div className={'flex items-center space-x-4 justify-end px-8 pb-8'}>
                            <p className={'text-primary text-sm font-light'}>Are you sure you want to change your
                                data?</p>
                            <Button loading={isUpdating} type={'submit'} className={'px-7 font-medium py-4'}>
                                Update
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    )
}
export default GeneralSettings;