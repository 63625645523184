import { useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useMutation } from "react-query";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

import {
    getValueFromLocalStorage,
    saveMultiValueToLocalStorage,
} from "hooks/useLocalStorageState";
import Label from "components/form-control/Label";
import { updateProjectEvent } from "provider/api";
import DynamicInput from "components/daynamic-input";
import UnstableSelect from "components/unstable/select";
import UnstableInput from "components/unstable/input";
import { localStorageKeys } from "../../../../../../../../../constants";
import { meetingAgendaValidationSchema } from "../../../../../../../../../forms/validation";
import Editor from "components/editorjs";
import { isEmptyDescription } from "../../../../../../../../../utils";
import ActionButtons from "../_partials/action-buttons";

const CreateAgenda = () => {
    const { meetingForm: meetingLSKeys } = localStorageKeys;

    const meetingBasicInfo = getValueFromLocalStorage(
        meetingLSKeys.BASIC_DETAILS,
        {}
    );

    const navigate = useNavigate();
    const [setCurrStep, toast] = useOutletContext();
    const [description, setDescription] = useState(
        meetingBasicInfo.description ?? {}
    );
    const [otherAgendas, setOtherAgendas] = useState(
        meetingBasicInfo.otherAgendas ?? []
    );
    const isEditing = getValueFromLocalStorage(
        meetingLSKeys.IS_UPDATING_DETAILS,
        false
    );

    const handleOtherAgenda = (agenda = []) => {
        setOtherAgendas(agenda);
    };

    const {
        handleSubmit,
        register,
        formState: { errors, isSubmitted },
    } = useForm({
        resolver: yupResolver(meetingAgendaValidationSchema),
    });

    const handleEditorChanges = (cleanData) => {
        setDescription(cleanData);
    };

    const buildPayloadAndUpdateEvent = (formData) => {
        const payload = {
            ...meetingBasicInfo,
            ...formData,
            description: JSON.stringify(description),
            otherAgendas,
        };
        updateEvent(payload);
    };

    const storeBasicDetailsToLocalStorageAndAdvanceToNextStep = async (
        data
    ) => {
        setCurrStep(2);
        await saveMultiValueToLocalStorage({
            [meetingLSKeys.BASIC_DETAILS]: {
                description: description,
                otherAgendas,
                ...data,
            },
            [meetingLSKeys.CURRENT_STEP]: 2,
        });
        toast.success("Data has been saved successfully");
        navigate("/event-registration/meeting/add-participants");
    };

    const onSubmit = async (formData) => {
        console.log("here");
        if (isEmptyDescription(description)) return;
        if (isEditing) {
            return buildPayloadAndUpdateEvent(formData);
        }
        await storeBasicDetailsToLocalStorageAndAdvanceToNextStep(formData);
    };

    const { mutate: updateEvent, isLoading: isUpdatingEvent } = useMutation(
        updateProjectEvent,
        {
            onSuccess: async ({ data }) => {
                await storeBasicDetailsToLocalStorageAndAdvanceToNextStep(data);
            },
        }
    );

    const goBack = () => {
        // clearForm();

        navigate("/engagement/activities/overview");
    };

    const labelClasses = "text-dark text-[15px] font-medium opacity-100";

    const validateAgenda = (agenda = "") => {
        return agenda.split(" ").length > 2;
    };

    const dynamicInputProps = {
        autoFocus: false,
        initialInputs: meetingBasicInfo?.otherAgendas ?? [],
        inputClasses: "border-[1.8px] h-12 w-full rounded",
        valueHandler: handleOtherAgenda,
        validator: validateAgenda,
    };

    const fields = [
        {
            name: "title",
            hasError: !!errors?.title?.message,
            defaultValue: meetingBasicInfo.title,
            register,
        },
        {
            name: "mainAgenda",
            hasError: !!errors?.mainAgenda?.message,
            defaultValue: meetingBasicInfo.mainAgenda,

            register,
        },
        {
            name: "meetingType",
            hasError: !!errors?.meetingType?.message,
            defaultValue: meetingBasicInfo.meetingType,
            register,
            options: ["Seminar", "Workshop"],
        },
        {
            name: "description",
            isError: isSubmitted && isEmptyDescription(description),
            defaultValue: description,
            onChange: handleEditorChanges,
        },
    ];

    return (
        <div className={"space-y-10"}>
            <form
                onSubmit={handleSubmit(onSubmit)}
                className={"pr-40 space-y-7 "}
            >
                {fields.map((props) => {
                    if (props?.options) {
                        return <UnstableSelect props={props} />;
                    } else if (props.name === "description") {
                        return (
                            <div className={" space-y-2 "}>
                                <Label
                                    text={"description"}
                                    className={labelClasses}
                                />
                                <Editor {...props} />
                            </div>
                        );
                    }
                    return <UnstableInput props={props} />;
                })}

                {/* Other Agenda  Todo: indicate this is optional field */}
                <div className={" space-y-2 "}>
                    <div className={"flex items-center space-x-2"}>
                        <Label
                            text={"Other Agenda"}
                            className={
                                "text-dark text-[15px] font-medium opacity-100"
                            }
                        />{" "}
                        <p className={"text-sm text-gray-400"}>( Optional ) </p>
                    </div>
                    <DynamicInput props={dynamicInputProps} />
                </div>

                <ActionButtons
                    text={"Next"}
                    onCancel={goBack}
                    isEditing={isEditing}
                    isLoading={isUpdatingEvent}
                    onSave={() => {}}
                />
            </form>
        </div>
    );
};
export default CreateAgenda;
