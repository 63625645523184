const Loading = () => {
    return (
        <div className={"flex items-center space-x-4"}>
            <div className={`h-2 w-8  skeleton-loading rounded`}></div>
            <p
                className={
                    "text-[#A6B1C2] h-2 w-20 rounded skeleton-loading uppercase"
                }
            ></p>
        </div>
    );
};
export default Loading;
