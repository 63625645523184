import Button from "components/Button";
import PhaseFooter from "./phase-footer";

const PhaseListItem = ({ phase, onEdit: handleEdit }, ...props) => {
    const handleClick = () => handleEdit(phase);
    return (
        <div
            {...props}
            className={
                "border-[1px] bg-[#F9F9F9] border-[#E4E4E4] rounded-[8px] overflow-hidden"
            }
        >
            <div
                className={
                    "bg-white p-8 space-y-2 border-b-[1px] border-b-[#E4E4E4]"
                }
            >
                <h2 className={"text-xl tracking-wide text-dark3"}>
                    {phase.name}
                </h2>

                <div className={"flex space-x-6"}>
                    <p className={"w-full font-light text-[#838EAB] text-sm"}>
                        {phase.description}
                    </p>

                    <div className={" w-1/3"}>
                        <div className={"items-between "}>
                            <div className={"h-14 w-[2px] bg-[#E8E8E8]"}></div>
                            <div className={"center space-y-3 flex-col"}>
                                <Button
                                    onClick={handleClick}
                                    className={
                                        "border-[2px] font-semibold text-secondary border-secondary border-[#638798] py-3"
                                    }
                                    outline={true}
                                >
                                    EDIT DEPARTMENT
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <PhaseFooter startDate={phase.startDate} endDate={phase.endDate} />
        </div>
    );
};
export default PhaseListItem;
