const KataviSvg = ({ color, isLoading = false }) => {
    return (
        <path
            id="Path_431"
            data-name="Path 431"
            className={`${isLoading ? "animate-pulse" : ""}`}
            d="M159.092,294.494l.891-1.2,1.163-3.456,3.7-2.542-.137-1.781-.691-1.6.408-2.455-1.257-1.437-1.81-1.091-1.208-3.387-2.36-4.4-1.116-3.221-2.558-14.96-1.5-3.394-3.552-3.016.695-.88,8.36-.1,1.915-.446,1.548-1.327,2.525-2.867,1.341-.933,10.776-.069,1.782.118,1.24.786.533.86.267.237.251.045.582-.079.259.035.97.68,1.156,1.648.836.645.59-.54.848-1.569.683-.328.675.29.238.706.146,1.684.214.466.274.3.3.256.3.314.473.974.169.256.666.7.368.51.949,3.181-.105.976-1.119.625-.024.457.113.467.27.633.137.122.178.055,2.594,1.976.227.093.546-.024.186.065.122.262-.02.25-.1.292.2.341.146.374.165.651-.267.9.045.2.182.372.041.235-.206.562.085.272.521.116.743-.152.788-.28.8-.134.768.274.752,1.326.57.591.695-.148.739-.3,1.617.183.724-.28,1.446.905.764.331.873.128.788-.142.743-.241.675-.049.586.433.408-.609.428-.1,2.921.894.4-.061.437-.256,1.471-.582.5-.111.008-.121.222-.272.424-.41.3-.2.792-.339.829-.6.764-.706.865-.589,1.156-.248.23.073.206.176.368.41.227.118.169-.075.141-.128.141-.037.925.469,1.81.353.683.037.61-.323v.268l2.251.7.829.1.234.156.154.374.122.443.146.369.311.365.748.668.279.331.161.77-.028,1.933.255.652.5.713.691,2.643.655,1.424.909,1.339,1,1.109.934.739,2.024,1.085.533.39.234.128.5.079.231.2.036.179-.073.452.036.176.412.614.223.207.408.277,1.373.605.735.545.873.134.379.169.574.577,1.427,2.266,1.2,1.242,1.6.614,5.681.492.675.382.186.819.351.531.291.559-.23.748-.38.7-.784,1.018-1.1.651-2.3,2.92-1.135,3.63-.19,4.093.246,4.1L250.2,311.3l-.925,3.95-2.009,1.116-8.9,3.077-.024,4.171-1.555,4.28-1.422,2.8-4.016,1.534-1.366,2.1-1.536,3.184-1.075,3.382.687,1.382-8.728-1.472-6.388-.859-5.111-.859-.851-5.586-.852-6.014-.426-4.294-2.129-2.576-6.388-.43-5.536-.43-1.7-2.575v-3.434l-1.7-5.149-3.407-1.287-8.518.429-4.258,1.287-.549.4-1.925-1.232-.265-.533-.92-.6-2.567-2.9-.668-1.541-.288-.985.057-.429-1.461-1.888-.875-2.074-.345-.336-.679-.2-.679-.475Z"
            transform="translate(-133.577 -75.355)"
            fill={color}
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="0.5"
        />
    );
};
export default KataviSvg;
