import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import React from "react";

const ResolvedGrievanceByPieChart = ({ resolvedLate, resolvedOnTime }) => {
	const options = {
		tooltip: {
			pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
		},
		accessibility: {
			point: {
				valueSuffix: "%",
			},
		},
		plotOptions: {
			pie: {
				allowPointSelect: true,
				slicedOffset: 2,
				cursor: "pointer",
				dataLabels: {
					enabled: true,
					format: "{point.percentage:.1f} %",
					distance: -30,
					filter: {
						property: "percentage",
						operator: ">",
						value: 4,
					},
				},
			},
		},

		title: {
			text: "",
			y: 3.5,
			style: {
				display: false,
				fontSize: "14px",
				fontWeight: "bold",
				color: "#253A53",
			},
		},
		chart: {
			renderTo: "chart", // Spacing between Title & Chart
			defaultSeriesType: "areaspline",
		},
		series: [
			{
				type: "pie",
				name: "Activities count",
				data: [
					{
						name: "resolved on time",
						color: "#86B9B0",
						y: resolvedOnTime,
					},
					{
						name: "resolved late",
						color: "#E97E7C",
						sliced: true,
						selected: true,
						y: resolvedLate,
					},
				],
			},
		],
	};

	return (
		<div className={"h-full highcharts relative "}>
			<HighchartsReact highcharts={Highcharts} options={options} />
		</div>
	);
};

export default ResolvedGrievanceByPieChart;
