import Folder from "./folder";
import { useProjectFiles } from "../../../../hooks";
import { Fragment } from "react";
import FolderListView from "../folder-list-view";

const Folders = (
	{ folders, onOpenFolder: handleOpen = () => {}, showTitle = false },
	...props
) => {
	const { showListView } = useProjectFiles();
	return (
		<div {...props} className={"space-y-3"}>
			{showListView ? (
				<Fragment>
					{folders.map((folder) => (
						<FolderListView
							folder={folder}
							onOpenFolder={handleOpen}
						/>
					))}
				</Fragment>
			) : (
				<div className={"grid grid-cols-5 2xl:grid-cols-6 gap-5"}>
					{folders.map((folder) => (
						<Folder
							key={folder._id}
							showTitle={showTitle}
							onOpenFolder={handleOpen}
							folder={folder}
						/>
					))}
				</div>
			)}
		</div>
	);
};
export default Folders;
