import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons";

const ColorItem = ({isActive, color, checked}) => {
    return (
        <div
            className={`h-7 w-7 center cursor-pointer outline-none ${checked ? '' : 'hover:opacity-90'} focus:ring-0 rounded-full`}
            style={{backgroundColor: color}}>
            <FontAwesomeIcon icon={faCheck}
                             className={`${checked ? 'opacity-100' : 'opacity-0'} h-4.5 w-4.5 text-white`}/>
        </div>
    )
}
export default ColorItem