import { BadgeCheckIcon } from "@heroicons/react/solid";

const CommitmentBadge = ({ status }) => {
	const getBadgeClass = () => {
		switch (status) {
			case 0:
				return [
					"bg-[#FC625D]/20 border-[#FC625D]/30 text-[#FC625D]",
					"OFF TRACK",
				];
			case 1:
				return [
					"bg-blue-500/20 border-blue-300 text-blue-600",
					"ON TRACK",
				];
			case 2:
				return ["bg-green-500/20  text-green-600", "COMPLETED"];
			case 3:
				return ["bg-red-500/20 border-red-300 text-red-600", "OVERDUE"];
			default:
				return [
					"bg-orange-500/20 border-orange-300 text-orange-600",
					"UNKNOWN",
				];
		}
	};

	if (status === 2) {
		return (
			<div
				className={"text-[#37A08D] text-sm flex items-center space-x-2"}
			>
				<BadgeCheckIcon className={"h-5 w-5"} />
				<span>{getBadgeClass()[1]}</span>
			</div>
		);
	}

	const badgeClassName = `
        ${getBadgeClass()[0]}
         uppercase border-[1px]
        text-[10px] px-3 py-1.5 rounded-full
        tracking-wider
    `;
	return <span className={badgeClassName}>{getBadgeClass()[1]}</span>;
};
export default CommitmentBadge;
