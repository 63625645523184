import {useOutletContext} from "react-router-dom";
import Image from "components/image";
import React from "react";
import {CalenderIcon} from "svg";
import dayjs from "dayjs";
import {getValueFromLocalStorage} from "hooks/useLocalStorageState";
import {CreateMarkup} from "utils";

const ProjectDetails = () => {
    const {activeProject: project} = useOutletContext();
    const activeProject = getValueFromLocalStorage('activeProject');

    return (<section className="min-h-full p-10 bg-white border rounded-lg">
        <di className="flex justify-between pb-4 space-x-20 border-b item-center">
            <div className="flex space-x-10">
                {/* project logo */}
                <div className="w-32 h-32 rounded-lg shrink-0 bg-black/10"></div>

                <div className="space-y-4">
                    <h2 className="text-3xl font-semibold">
                        {project.name ?? activeProject.name} (<span className={'uppercase'}>
							{project.abbreviation ?? activeProject.abbreviation}</span>)
                    </h2>
                    <div className="flex items-center text-[15px] divide-x-2 divide-x-black/20 text-black/60">
                        <div className="flex pr-5 items-center space-x-1">
                            <p className="font-semibold ">Started on: </p>
                            <p className="flex items-center space-x-2">
                                <CalenderIcon className={"h-6 w-6"}/>
                                <span>
										{dayjs(project.startDate ?? activeProject.startDate).format("DD, MMMM YYYY")}
									</span>
                            </p>
                        </div>
                        <div className="flex items-center pl-5 space-x-1 .  ">
                            <p className="font-semibold">Ends on: </p>
                            <p className="flex items-center space-x-2">
                                <CalenderIcon className={"h-6 w-6"}/>
                                <span>{dayjs(project.endDate ?? project.endDate).format("DD, MMMM YYYY")}</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            {/* budget */}
            <div
                className="flex flex-col justify-center p-5 space-y-2 text-white rounded-lg w-60 bg-primary shrink-0">
                <p className="tracking-wide">Allocated Budget</p>
                <p className="text-2xl font-semibold">TZS {project.budget ?? activeProject.budget}</p>
            </div>
        </di>

        <div className="flex justify-between mt-10">
            <div className="pr-16 space-y-1">
                <h2 className="text-sm font-semibold uppercase ">
                    Project Description:
                </h2>
                <div
                    dangerouslySetInnerHTML={CreateMarkup(project.description ?? activeProject.description ?? 'Description is not provided')}
                    className="space-y-4 text-[15.5px] text-black/60 leading-relaxed">

                </div>
            </div>
            <div className="space-y-4 shrink-0">
                <div className="w-full">
                    <div className={"space-y-2"}>
                        <p
                            className={"text-sm font-medium text-secondary "}
                        >
                            Project Timeline
                        </p>
                        <p className={"text-[10px] text-gray"}>
                            <span className={"text-[#51BF82] pr-1"}>68% </span>
                            {" "} USED TIME
                        </p>

                        {/* Progress Bar */}
                        <div
                            className={"w-full h-[7px] bg-[#E4E6EA] rounded-full overflow-hidden"}
                        >
                            <div
                                className={"w-[68%] h-full bg-secondary"}
                            ></div>
                        </div>
                    </div>
                </div>
                <div className="w-60 rounded-lg text-sm overflow-hidden  bg-[#F6F6F8]">
                    <div
                        className={"px-3 py-2 bg-[#CED5DD] text-[#002F4F] pl-4"}
                    >
                        <p className={"text-[15px] font-medium"}>
                            PIU Details
                        </p>
                    </div>
                    <div className="divide-y-[1px]">
                        <div className="px-3 py-2">
                            <p className="text-sm font-semibold">
                                Full name
                            </p>
                            <p className="text-black/60">
                                {project?.piu?.registeredName ?? activeProject.piu.registeredName} - (<span
                                className={'uppercase'}>
									{project?.piu?.abbreviation ?? activeProject.piu.abbreviation}</span>)
                            </p>
                        </div>

                        <div className="px-3 py-2">
                            <p className="text-sm font-semibold">Email</p>
                            <p className="text-black/60">
                                {project?.piu?.email ?? activeProject?.piu?.email}
                            </p>
                        </div>

                        <div className="px-3 py-2">
                            <p className="text-sm font-semibold">
                                Phone Number
                            </p>
                            <p className="text-black/60">{project?.piu?.phoneNumber ?? activeProject.piu.phoneNumber ?? 'Not Provided'}</p>
                        </div>

                        <div>
                            <button
                                className="w-full p-2 text-sm text-center uppercase bg-[#F5F7FB] text-[#A0A8B5] center">
                                View More
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>);
};

export default ProjectDetails;
