export const localStorageKeys = {
    PROJECT_ID: "projectId",
    SUBPROJECT_ID: "subprojectId",
    SUBPROJECT_DETAILS: "subprojectDetails",

    userForm: {
        SELECTED_PIU: "selectedPIU",
        ASSIGNMENTS: "assignments",
        BASIC_INFO: "userBasicInfo",
    },
    stakeholderFrom: {
        CURRENT_STEP: "currentStep",
        BASIC_DETAILS: "stakeholderBasicDetails",
        IS_UPDATING_DETAILS: "isUpdatingStakeholderDetails",
        CONTACT_PERSON: "contactPerson",
        RELATION_SHIP_HOLDER: "relationshipHolder",
        LEVEL_OF_INTEREST: "interestLevel",
        LEVEL_OF_INFLUENCE: "influenceLevel",
        GOALS: "goals",
        CONCERN: "concern",
    },
    piuForm: {
        BASIC_DETAILS: "piuBasicDetails",
        CURRENT_STEP: "piuCurrStep",
        IS_UPDATING_DETAILS: "isUpdatingPIUDetails",
        CONTACT_PERSON: "piuContactPerson",
        MISSION_AND_VISION: "piuOperationDetails",
        ACTIVE_PIU: "activePIU",
    },

    meetingForm: {
        BASIC_DETAILS: "meetingBasicInfo",
        CURRENT_STEP: "meetingFormCurrentStep",
        PARTICIPANTS: "meetingParticipants",
        IS_UPDATING_DETAILS: "isEditingProjectEvent",
        MEETING_TIME: "meetingTime",
        MEETING_CHANNEL: "meetingChannel",
        ATTACHMENTS: "eventAttachments",
        REMAINDER: "meetingRemainder",
        MEETING_BUDGET: "meetingBudget",
        ACTIVE_EVENT: "activeProjectEvent",
    },

    commitmentForm: {
        BASIC_DETAILS: "commitmentBasicDetails",
        KEY_RESULT: "keyResults",
    },
};

export const roleTypes = {
    PIU_ADMIN: "piu-admin",
    WB_ADMIN: "wb-admin",
    ROOT_ADMIN: "core-admin",
    NORMAL_USER: "user",
    INVESTIGATOR: "investigator",
};

export const queryKeys = {
    PROJECTS: "projects",
    WORKSPACES: "workspaces",
    STAKEHOLDERS: "stakeholders",
    USERS: "users",
    ROLES: "roles",
    SUBPROJECTS: "subprojects",
    CREATE_USER: "create-user",
    UPDATE_USER: "update-user",
    PROJECT_PHASES: "project-phase",
    ENGAGEMENT_PLANS: "engagement-plans",
    ADD_ENGAGEMENT_PLAN: "add-engagement-plan",
    PIU_LIST: "system-pius",
    ENGAGEMENT_BY_STATUS: "engagementByStatus",
    EVENTS_BY_STAKEHOLDER: "eventByStakeholder",
    COMMITMENTS: "stakeholders-commitments",
    DEPARTMENTS: "departments",
};

export const cacheActions = {
    UPDATE: "update",
    ADD: "creation",
};
