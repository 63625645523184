const NorthPemba = ({ color, isLoading = false }) => {
    return (
        <path
            id="Path_445"
            className={`${isLoading ? "animate-pulse" : ""}`}
            data-name="Path 445"
            d="M638.01,239.354,638,241.2l-.28.633.292.294-.468.55-1.161,3.47-.3.422-.162.166-.057.249-.016.665-.178.517-.343.089-.226-.317.19-.7h-.213l-.264.639-.136.234-.184.2.13.415-.207.438-.439.406-.553.351-.375.1-.105-.147.133-.249.346-.217v-.294l-.557.14-.42.262-.321.064-.28-.466-1.362.045-.566-.185-.229-.531.019-1.374.153-.626.363-.543-.242-.422.49-.083,1.374.237-.315-.556-.818-1-.223-.607.611.157.77.057.691-.118.382-.365-.449-.1-.382-.143-.315-.22-.245-.339.611.006.935-.243.617-.032.706.368.388-.112.267-.793-.79.294-.954-.268-1.782-1.125-.385.249-.478-.147-.442-.4-.292-.508.42.172.277.2.264.077.369-.182.375.115,1.33.2.725-.023-.292-.294.3-.76-.147-.648.359-.188,1.131.032.323.455-.129.552.129.552.776.162.743-.39Zm-10.964,8.333.343.183.572.365.412.572.459.709.618.228.6.159.39.5-.091.481-1.052.07-.687-.548-.6-.708-.343-.5-.458-.48-.412-.572Z"
            transform="translate(-626.796 -227.894)"
            fill={color}
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="0.5"
        />
    );
};
export default NorthPemba;
