import { Link } from "react-router-dom";
import { ArrowUpWithCircleIcon, VerificationIcon } from "svg";
import ViewLocation from "../../../../maps/viewLocation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import {
    getValueFromLocalStorage,
    removeValuesFromLocalStorage,
    saveMultiValueToLocalStorage,
} from "hooks/useLocalStorageState";
import { CreateMarkup, slugify } from "utils";
import { localStorageKeys } from "../../../../constants";

const PIUDetails = () => {
    const { piuForm: piuLSKeys } = localStorageKeys;

    const activePIU = getValueFromLocalStorage(piuLSKeys.ACTIVE_PIU);

    const quickInfo = [
        { name: "PHONE", value: activePIU.phoneNumber },
        { name: "EMAIL", value: activePIU.email },
        { name: "ABBREVIATION", value: activePIU.abbreviation },
        { name: "REG NO", value: activePIU.registrationNumber },
        { name: "Website", value: activePIU.websiteLink, icon: "" },
        { name: "Founded On", value: activePIU.yearFound, icon: "" },
        {
            name: "Registered On",
            value: "20 january 2013 (hardcoded)",
            icon: "",
        },
    ];

    const {
        address,
        location,
        contactPerson,
        description,
        mission,
        vision,
        ...otherInfo
    } = activePIU;

    const addressInfo = [
        { name: "LONGITUDE", value: location?.longitude },
        { name: "LATITUDE", value: location?.latitude },
        { name: "POSTCODE", value: address.postalCode },
        { name: "STATE", value: address.country },
    ];

    const handleClick = () => {
        removeValuesFromLocalStorage("activePIU");
    };

    const handleUpdateAction = async () => {
        const locationObj = {
            properties: {
                address: {
                    ...address,
                    city_district: address.district,
                },
                display_name: location.name,
            },
            geometry: {
                coordinates: [location.longitude, location.latitude],
            },
        };

        const basicDetails = {
            ...otherInfo,
            piuId: otherInfo?._id,
            description: JSON.parse(description),
            ...address,
            location: locationObj,
        };

        const missionAndVision = {
            piuId: otherInfo?._id,
            mission: JSON.parse(mission ?? ""),
            vision: JSON.parse(vision ?? ""),
        };

        const _contactPerson = {
            ...contactPerson,
            description: JSON.parse(contactPerson.description),
            piuId: otherInfo?._id,
        };

        await saveMultiValueToLocalStorage({
            [piuLSKeys.BASIC_DETAILS]: basicDetails,
            [piuLSKeys.MISSION_AND_VISION]: missionAndVision,
            [piuLSKeys.CONTACT_PERSON]: _contactPerson,
            [piuLSKeys.IS_UPDATING_DETAILS]: true,
        });
    };

    return (
        <section className="h-full overflow-y-auto bg-[#EBF0F3] px-20 space-y-7 py-14 ">
            <header className={"space-y-10"}>
                <h1 className={"text-2xl text-[#151D41] font-semibold"}>
                    {activePIU.registeredName}
                </h1>
                <div className={"items-between"}>
                    <div className={"flex space-x-4"}>
                        <div
                            className={
                                "h-12 w-12 rounded-full outline outline-1 outline-offset-2 outline-primary bg-primary"
                            }
                        ></div>
                        <div className={"space-y-2"}>
                            <p className={"text-[#838EAB] font-light"}>
                                Construction company
                            </p>
                            <p className={"font-light text-sm"}>
                                <span className={"text-accent"}>
                                    {address?.country}{" "}
                                </span>
                                <span className={"text-[#868688]"}>
                                    {" "}
                                    {address?.street}, {address?.ward},{" "}
                                    {address.region}
                                </span>
                            </p>
                        </div>
                    </div>
                    <div className={"flex items-center space-x-3"}>
                        <Link
                            to={-1}
                            onClick={handleClick}
                            className={
                                "bg-[#F6F9FE] border-[1px] hover:opacity-90 rounded px-5 border-[#DFE1E5] text-secondary py-2.5"
                            }
                        >
                            Return to previous
                        </Link>
                        <Link
                            to={`/update-piu/${slugify(
                                activePIU.registeredName
                            )}`}
                            onClick={handleUpdateAction}
                            className={
                                "py-2.5 rounded text-white hover:opacity-90 bg-secondary px-5"
                            }
                        >
                            <span>Edit details</span>
                            {/* Todo: place edit-icon here as shown in design */}
                        </Link>
                    </div>
                </div>
            </header>

            <div className={"space-y-2"}>
                {/*quick info eg; phone-number reg-no email etc.*/}
                <div
                    className={
                        "bg-white border-[1.5px] flex justify-between border-[#E1E1E1] rounded-[6px] px-5 py-3"
                    }
                >
                    {quickInfo.slice(0, 4).map((info) => (
                        <div key={info.key} className={"space-y-2"}>
                            <p
                                className={
                                    "text-[12px] font-semibold text-[#002F4F]"
                                }
                            >
                                {info.name}
                            </p>
                            <p
                                className={
                                    "text-[15px] font-light tracking-wide text-[#A6061A]"
                                }
                            >
                                {info.value}
                            </p>
                        </div>
                    ))}
                </div>

                {/* detailed info */}
                <div
                    className={
                        "border border-t-[5px] rounded space-y-16 border-[#E4E4E4] border-t-[#AAB6C5] px-14 py-10 bg-white"
                    }
                >
                    {/* PIU-map && description */}
                    <div className={"flex space-x-10"}>
                        {/* piu-description*/}
                        <div className={"space-y-10 w-full"}>
                            <div className={"space-y-1"}>
                                <p
                                    className={
                                        "text-lg font-medium text-[#262626]"
                                    }
                                >
                                    Description
                                </p>
                                <p
                                    dangerouslySetInnerHTML={CreateMarkup(
                                        activePIU?.description ?? "Not Provided"
                                    )}
                                    className={
                                        "font-light text-[15px] leading-relaxed text-[#707070]"
                                    }
                                ></p>
                            </div>

                            <div className={"space-y-6"}>
                                {quickInfo.slice(4).map((info) => (
                                    <div
                                        key={info.name}
                                        className={"flex space-x-3"}
                                    >
                                        <div
                                            className={
                                                "h-11 w-11 rounded-full bg-gray-100"
                                            }
                                        ></div>
                                        <div className={"space-y-1"}>
                                            <p
                                                className={
                                                    "text-[#7F9FA6] font-semibold text-[15.5px]"
                                                }
                                            >
                                                {info.name}
                                            </p>
                                            {info.name === "Website" ? (
                                                <a
                                                    target={"_blank"}
                                                    rel="noreferrer"
                                                    href={info.value}
                                                    className={
                                                        "italic hover:underline text-accent font-light text-sm"
                                                    }
                                                >
                                                    {info.value}
                                                </a>
                                            ) : (
                                                <p
                                                    className={
                                                        "italic text-accent font-light text-sm"
                                                    }
                                                >
                                                    {info.value}
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>

                        {/* piu-map */}
                        <div
                            className={
                                "shrink-0 w-[55%] flex flex-col h-[27rem] bbg-[#D4D4DD] overflow-hidden border-[1px] border-[#D4D4DD] rounded-[5px]"
                            }
                        >
                            <p
                                className={
                                    "text-[#002F4F] shrink-0 px-8 border-b-[1px] border-[#D4D4DD] font-semibold text-sm  bg-[#F5F9FA] py-2"
                                }
                            >
                                PIU LOCATION
                            </p>
                            <div className={"h-full relative"}>
                                <ViewLocation
                                    coordinates={[
                                        location.longitude ?? 39.244171,
                                        location.latitude ?? -6.83335,
                                    ]}
                                />

                                {/* overlay-address */}
                                <div
                                    className={
                                        "stakeholder-location w-[250px] text-white p-5 left-5 absolute top-10"
                                    }
                                >
                                    <div
                                        className={
                                            "space-y-2 pb-5 border-b-[1px] border-white/[68%]"
                                        }
                                    >
                                        <p
                                            className={
                                                "title font-thin tracking-wide text-sm"
                                            }
                                        >
                                            {activePIU.abbreviation}
                                        </p>
                                        <p
                                            className={
                                                "text-xs font-thin opacity-90"
                                            }
                                        >
                                            {address?.street}, {address?.ward},{" "}
                                            {address.region} - {address.country}
                                            .
                                        </p>
                                    </div>

                                    <div className={"grid grid-cols-2 "}>
                                        {addressInfo.map((address) => (
                                            <div
                                                key={address.name}
                                                className={
                                                    "py-4 even:flex even:flex-col event:items-end space-y-1"
                                                }
                                            >
                                                <p
                                                    className={
                                                        "text-[9.5px] opacity-90 tracking-wide"
                                                    }
                                                >
                                                    {address.name}
                                                </p>
                                                <p
                                                    className={
                                                        "text-xs font-thin opacity-80"
                                                    }
                                                >
                                                    {address.value}
                                                </p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*mission & vision */}
                    <div
                        className={
                            "grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-4"
                        }
                    >
                        <div
                            className={
                                "bg-white border-b-[7px] border-b-[#AAB6C5] overflow-hidden rounded"
                            }
                            style={{ boxShadow: "0px 3px 10px #00000029" }}
                        >
                            <div className={"px-5 py-6 space-y-4"}>
                                <ArrowUpWithCircleIcon
                                    className={"h-10 w-10 text-primary"}
                                />
                                <div className={"space-y-2"}>
                                    <h3
                                        className={
                                            "text-[#525156] text-lg font-semibold"
                                        }
                                    >
                                        Vision
                                    </h3>
                                    <p
                                        dangerouslySetInnerHTML={CreateMarkup(
                                            activePIU?.vision
                                        )}
                                        className={
                                            "text-[15px] tracking-wide font-light text-[#525156]"
                                        }
                                    ></p>
                                </div>
                            </div>
                        </div>

                        <div
                            className={
                                "bg-white overflow-hidden border-[4px] border-[#F2F2F2] rounded"
                            }
                        >
                            <div className={"px-3 py-6 space-y-4"}>
                                <div className={"flex space-x-3 items-center"}>
                                    <VerificationIcon
                                        className={"h-10 w-10 text-primary"}
                                    />
                                    <h3
                                        className={
                                            "text-[#525156] text-lg leading-[1.4] font-semibold"
                                        }
                                    >
                                        Mission
                                    </h3>
                                </div>

                                <p
                                    dangerouslySetInnerHTML={CreateMarkup(
                                        activePIU.mission
                                    )}
                                    className={
                                        "text-[15px] tracking-wide font-light text-[#525156]"
                                    }
                                ></p>
                            </div>
                        </div>
                    </div>

                    {/* contact person details  */}
                    <div className={"space-y-4"}>
                        <p className={"text-lg font-medium text-[#262626]"}>
                            Contact Person
                        </p>

                        <div className={"flex gap-20 justify-between"}>
                            <div className={"w-full space-y-2"}>
                                <div>
                                    <h3
                                        className={
                                            "text-dark font-medium text-xl tracking-wide"
                                        }
                                    >
                                        {contactPerson?.name}
                                    </h3>
                                    <p
                                        className={
                                            "text-sm text-[#A5A5A5] font-light"
                                        }
                                    >
                                        {contactPerson?.position}
                                    </p>
                                </div>

                                <p
                                    dangerouslySetInnerHTML={CreateMarkup(
                                        contactPerson?.description
                                    )}
                                    className={
                                        "text-sm text-secondary leading-relaxed font-light tracking-wide text-justify"
                                    }
                                ></p>
                            </div>
                            <div
                                className={
                                    "bg-[#F5F9FA] shrink-0 space-y-5 border-[1px] w-72 border-[#EDEEF0] rounded p-5"
                                }
                            >
                                <div
                                    className={
                                        "flex items-center justify-between"
                                    }
                                >
                                    <p
                                        className={
                                            "text-[#628799] text-semibold text-lg"
                                        }
                                    >
                                        Contact Info
                                    </p>
                                    <FontAwesomeIcon
                                        icon={faPaperPlane}
                                        className={"h-5 w-5 text-accent"}
                                    />
                                </div>

                                <div className={"tracking-wide space-y-4 pb-3"}>
                                    <div className={"space-y-1"}>
                                        <p className={"text-xs text-primary "}>
                                            Gender
                                        </p>
                                        <p
                                            className={
                                                "capitalize text-sm font-thin text-[#408BF9]"
                                            }
                                        >
                                            {contactPerson?.gender}
                                        </p>
                                    </div>

                                    <div className={"space-y-1"}>
                                        <p className={"text-xs text-primary "}>
                                            Address
                                        </p>
                                        <p
                                            className={
                                                "capitalize text-sm font-thin text-[#408BF9]"
                                            }
                                        >
                                            {contactPerson?.physicalAddress}
                                        </p>
                                    </div>
                                </div>

                                <div
                                    className={
                                        "border-t-[1px] space-y-5 border-[#EDEEF0] "
                                    }
                                >
                                    <div
                                        className={
                                            "text-[13px] justify-between flex w-full "
                                        }
                                    >
                                        {contactPerson.communicationMedias.map(
                                            (media, idx) => (
                                                <div
                                                    key={idx}
                                                    className={
                                                        "flex flex-wrap "
                                                    }
                                                >
                                                    {media.name === "Email" && (
                                                        <div
                                                            className={
                                                                "w-full "
                                                            }
                                                        >
                                                            <p
                                                                className={
                                                                    "text-[#AFAFAF]"
                                                                }
                                                            >
                                                                Email
                                                            </p>
                                                            <p
                                                                className={
                                                                    "text-dark"
                                                                }
                                                            >
                                                                {media.value}
                                                            </p>
                                                        </div>
                                                    )}

                                                    {media.name === "Phone" && (
                                                        <div
                                                            className={
                                                                "w-full mt-2"
                                                            }
                                                        >
                                                            <p
                                                                className={
                                                                    "text-[#AFAFAF]"
                                                                }
                                                            >
                                                                Phone
                                                            </p>
                                                            <p
                                                                className={
                                                                    "text-dark"
                                                                }
                                                            >
                                                                {media.value}
                                                            </p>
                                                        </div>
                                                    )}
                                                </div>
                                            )
                                        )}
                                    </div>

                                    <div
                                        className={
                                            "text-[13px] justify-between flex w-full"
                                        }
                                    >
                                        <div className={"shrink-0"}>
                                            <p className={"text-[#AFAFAF]"}>
                                                FAX
                                            </p>
                                            <p className={"text-dark "}>
                                                1123904
                                            </p>
                                        </div>

                                        <div className={"shrink-0"}>
                                            <p className={"text-[#AFAFAF]"}>
                                                WEBSITE
                                            </p>
                                            <p className={"text-dark"}>
                                                {contactPerson.websiteLink}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default PIUDetails;
