import React from "react";

const Loading = () => {
	return (
		<div className="p-5 space-y-5 bg-white border">
			<p className="h-2.5 w-60 skeleton-loading rounded-full"></p>

			<div className="grid grid-cols-3 gap-4 2xl:grid-cols-4">
				{[...Array(4)].map((item, index) => (
					<div className="h-56 rounded skeleton-loading"></div>
				))}
			</div>
		</div>
	);
};

export default Loading;
