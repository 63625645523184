import { Outlet } from "react-router-dom";
import PinnedFiles from "./pinned-files";
import ProjectFilesProvider from "../../provider/project-files/project-files";

const ProjectFiles = () => {
	return (
		<ProjectFilesProvider>
			<section className={"h-full overflow-y-scroll "}>
				<div
					className={" space-y-10 "}
					style={{ width: "calc(100% - 320px)" }}
				>
					<Outlet />
				</div>
				<PinnedFiles />
			</section>
		</ProjectFilesProvider>
	);
};
export default ProjectFiles;
