import { SearchIcon } from "@heroicons/react/outline";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { Tab } from "../../stakeholder/list/stakeholder-details";
import GrievanceCard from "./under-investigation-card";
import useGrievances from "hooks/useGrievances";
import { Fragment, useEffect, useState } from "react";
import GrievancesDetails from "../grievance-details";
import { useParams } from "react-router-dom";
import { useAuth } from "../../../../provider/auth/auth";

const UnderInvestigationLayout = () => {
    const [activeGrievance, setActiveGrievance] = useState({});
    const [showDetails, setShowDetails] = useState(false);
    const [grievanceList, setGrievanceList] = useState([]);
    const [filteredGrievanceList, setFilteredGrievanceList] = useState([]);
    const { status } = useParams();
    const { authUser } = useAuth();

    const { allGrievances = {} } = useGrievances();
    const { investigation: grievances = [] } = allGrievances;
    const tabMenus = [
        {
            title: "Pending ",
            url: "/grievances/under-investigation/pending",
            urlPattern: new RegExp("^/grievances/under-investigation/pending$"),
        },
        {
            title: "Investigated",
            url: "/grievances/under-investigation/investigated",
            urlPattern: new RegExp(
                "^/grievances/under-investigation/investigated$"
            ),
        },
    ];

    const handleShowDetails = (grievance) => {
        setActiveGrievance(grievance);
        setShowDetails(true);
    };

    const getGrievancesByStatus = (_grievances) => {
        if (status === "pending") {
            return _grievances.filter((grievance) => {
                return !grievance?.investigatedOn;
            });
        }

        if (status === "investigated") {
            return _grievances.filter((grievance) => {
                return grievance?.investigatedOn;
            });
        }
        return [];
    };

    useEffect(() => {
        if (grievances.length) {
            const _assignedGrievance = grievances.filter(
                ({ investigator } = {}) => investigator === authUser._id
            );
            setGrievanceList(getGrievancesByStatus(_assignedGrievance));
            setFilteredGrievanceList(getGrievancesByStatus(_assignedGrievance));
        }
    }, [grievances, status]);

    const onSuccess = (grievanceId) => {
        setGrievanceList(
            grievanceList.filter((grievance) => {
                return grievance?._id != grievanceId;
            })
        );
    };

    const handleInputChange = ({ target }) => {
        const { value: searchKeyword } = target;
        setFilteredGrievanceList(
            grievanceList.filter((grievance) => {
                return grievance?.title
                    ?.toLowerCase()
                    .includes(searchKeyword.toLowerCase());
            })
        );
    };

    return (
        <Fragment>
            <GrievancesDetails
                isOpen={showDetails}
                setIsOpen={setShowDetails}
                grievance={activeGrievance}
                onSuccess={onSuccess}
            />
            <section
                className={
                    "px-10 h-full space-y-3 overflow-y-auto  bg-[#F9F9F9]"
                }
            >
                <header className="w-full pt-10 rounded ">
                    <div
                        className={
                            " flex w-full top-0 rounded bg-white sticky border-[1px] p border-gray-200 px-4 z-30 items-end justify-between tracking-wide  shadow-sm h-14"
                        }
                    >
                        {/* Tab Menu */}
                        <div
                            className={
                                "flex text-[13px] items-center text-[#A7B3C3] space-x-4"
                            }
                        >
                            {tabMenus.map((menuProp) => (
                                <Tab key={menuProp.title} {...menuProp} />
                            ))}
                        </div>

                        <div className={"flex space-x-3  h-full items-center"}>
                            <div
                                className={
                                    "border-[2px] border-[#638798] flex items-center space-x-2" +
                                    " rounded overflow-hidden text-[#628799] px-2 py-1.5"
                                }
                            >
                                <SearchIcon className={"h-5 w-5"} />
                                <input
                                    onChange={handleInputChange}
                                    type="text"
                                    className={
                                        "h-full font-light bg-transparent p-0 border-none focus:ring-0 text-sm w-full outline-none placeholder-[#628799]"
                                    }
                                    placeholder={"Search Grievance"}
                                />
                            </div>
                            <div
                                className={
                                    "h-8 w-8 rounded relative bg-secondary center text-white"
                                }
                            >
                                <FontAwesomeIcon
                                    icon={faFilter}
                                    className={"h-5 w-5"}
                                />
                            </div>
                        </div>
                    </div>
                </header>

                <div className="grid grid-cols-4">
                    {filteredGrievanceList.map((grievance, index) => (
                        // {[...Array(10)].map((grievance, index) => (
                        <GrievanceCard
                            grievance={grievance}
                            onShowDetails={handleShowDetails}
                        />
                    ))}
                </div>
            </section>
        </Fragment>
    );
};
export default UnderInvestigationLayout;
