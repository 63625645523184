import { useNavigate } from "react-router-dom";
import { createContext, useState } from "react";
import { ArrowLeftIcon } from "@heroicons/react/outline";

import FormProvider from "../../../../../provider/form";
import BasicDetails from "./basic-details";
import { TabMenu } from "../../../../../components/TabMenu";
import GoalsAndConcern from "./goals-and-concern";
import clearStakeholderForm from "./_partials/clear-stakeholder-form";
import ContactPerson from "./contact-personal";
import RelationshipHolder from "./relationship-holder";
import LevelOfInterestAndInfluence from "./level-of-interest-and-influence";
import { getValueFromLocalStorage } from "../../../../../hooks/useLocalStorageState";
import { localStorageKeys } from "../../../../../constants";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const FormContext = createContext(null);

const StakeholderRegForm = () => {
    const { stakeholderFrom: stakeholderLSKeys } = localStorageKeys;
    const isUpdatingStakeholder = getValueFromLocalStorage(
        stakeholderLSKeys.IS_UPDATING_DETAILS
    );

    const [currentStep, setCurrentStep] = useState(
        getValueFromLocalStorage(stakeholderLSKeys.CURRENT_STEP, 1)
    );
    const navigate = useNavigate();
    const formContents = {
        5: <GoalsAndConcern />,
        2: <ContactPerson />,
        3: <RelationshipHolder />,
        4: <LevelOfInterestAndInfluence />,
        1: <BasicDetails onClick={setCurrentStep} />,
    };

    const formSteps = [
        { number: 1, name: "STAKEHOLDER BASIC DETAILS" },
        { number: 2, name: "CONTACT PERSON" },
        { number: 3, name: "RELATIONSHIP HOLDER" },
        { number: 4, name: "INTEREST & INFLUENCE" },
        { number: 5, name: "GOALS & CONCERN" },
    ];

    const handleBack = () => {
        clearStakeholderForm();
        navigate("/", { replace: true });
    };

    return (
        <FormProvider>
            <FormContext.Provider value={[currentStep, setCurrentStep]}>
                <ToastContainer />
                <section className={"h-screen flex w-full overflow-hidden"}>
                    <div
                        className={
                            "h-full w-[390px] shadow bg-white px-16 py-12 space-y-6"
                        }
                    >
                        <button
                            onClick={handleBack}
                            className={
                                "h-10 w-10 rounded-full flex justify-center  items-center hover:bg-primary/10"
                            }
                        >
                            <ArrowLeftIcon
                                className={
                                    "h-6 hover:text-primary/60 text-[#AAB6C5] stroke-[1.5px]"
                                }
                            />
                        </button>

                        <div>
                            <h1
                                className={
                                    "text-secondary leading-snug font-medium tracking-wide text-3xl"
                                }
                            >
                                {isUpdatingStakeholder ? "Updating" : "Adding"}{" "}
                                <br />
                                Stakeholder
                            </h1>
                        </div>

                        {/* Tab Menus */}
                        <div className={"pt-5 space-y-10"}>
                            {formSteps.map((tab) => (
                                <TabMenu
                                    onClick={() => {
                                        if (isUpdatingStakeholder) {
                                            setCurrentStep(tab.number);
                                        }
                                    }}
                                    key={tab.number}
                                    tabName={tab.name}
                                    tabNumber={tab.number}
                                    isActive={currentStep === tab.number}
                                    isFinished={currentStep > tab.number}
                                />
                            ))}
                        </div>
                    </div>
                    <section
                        className={
                            "form-area bg-[#F6F7F9] overflow-y-auto pt-20"
                        }
                    >
                        <div className={"w-4/5 mx-auto "}>
                            {formContents[currentStep]}
                        </div>
                    </section>
                </section>
            </FormContext.Provider>
        </FormProvider>
    );
};

export default StakeholderRegForm;
