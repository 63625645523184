import { useState } from "react";
import { useMutation } from "react-query";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import SidebarRight from "components/sidebar-right";
import useGlobalContext from "../../../../../hooks/useGlobalContext";
import { cacheActions, queryKeys } from "../../../../../constants";
import { addEngagementPlan } from "../../../../../provider/api";
import { useEngagementPlans } from "../plans";
import getFormField from "./fields";
import UnstableSelect from "components/unstable/select";
import UnstableInput from "components/unstable/input";
import FormFooter from "./form-footer";
import FormHeader from "./form-header";
import Checkbox from "components/form-control/checkbox";
import useUpdateCache from "hooks/useUpdateCache";

const PlanForm = () => {
    const [showForm, setShowForm] = useState(true);
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const { activePhase, activePlan = {} } = useEngagementPlans();
    const { stakeholder: stakeholders = [] } = useGlobalContext();
    const { projectId } = useGlobalContext();
    const navigate = useNavigate();
    const updateCache = useUpdateCache();

    const updateCacheAndCloseForm = async (data, action) => {
        await updateCache(queryKeys.ENGAGEMENT_PLANS, data, action);
        handleClose();
    };

    const { mutate: addPlan, isLoading } = useMutation(
        queryKeys.ADD_ENGAGEMENT_PLAN,
        addEngagementPlan,
        {
            onSuccess: async ({ data: newPlan }) => {
                console.log(newPlan);
                await updateCacheAndCloseForm(newPlan, cacheActions.ADD);
            },
            onError: (err) => {
                //  TODO: Handle add plan error
            },
        }
    );

    const onSubmit = (formData) => {
        const payload = {
            ...formData,
            project: projectId,
            projectPhase: activePhase?._id,
        };
        addPlan(payload);
    };

    const handleClose = async () => {
        setShowForm(false);
        setTimeout(() => navigate(-1, { replace: true }), 300);
    };

    const fields = getFormField(activePlan, errors, register, stakeholders);

    return (
        <SidebarRight
            className={"w-[400px]"}
            onClose={handleClose}
            isOpen={showForm}
        >
            <form
                className={" h-full overflow-y-auto space-y-8 p-10 "}
                onSubmit={handleSubmit(onSubmit)}
            >
                <FormHeader />
                <div className={"space-y-4"}>
                    {fields.map((props) => {
                        if (props?.options) {
                            return <UnstableSelect props={props} />;
                        } else if (props.type === "checkbox") {
                            return <Checkbox {...props} />;
                        } else {
                            return <UnstableInput props={props} />;
                        }
                    })}
                </div>
                <FormFooter isLoading={isLoading} />
            </form>
        </SidebarRight>
    );
};
export default PlanForm;
