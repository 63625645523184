import Image from "components/image";
import {Link, useOutletContext} from "react-router-dom";
import React from "react";
import {ArrowRight} from "svg";
import dayjs from "dayjs";
import {slugify} from "../../utils";
import {saveValueToLocalStorage} from "../../hooks/useLocalStorageState";

const ProjectItem = ({project}) => {
    const {setActiveProject} = useOutletContext();

    const handleClick = () => {
        saveValueToLocalStorage('activeProject', project)
        setActiveProject(project);
    }
    return (
        <div className="grid grid-cols-6 px-10 py-3 text-[15px] hover:bg-secondary/5">
            <div className="flex  items-center col-span-3 space-x-2">
                <div className="w-2.5 h-2.5 shadow shadow-pink-500/50 bg-pink-500 rounded-full"></div>

                <p className="space-x-1">
                    <span>{project.name}</span> -
                    <span className={'text-black/60 uppercase font-semibold text-sm'}>({project?.abbreviation})</span>

                </p>
            </div>
            <div className="flex items-center justify-center space-x-2 text-black/60">
                <div className="w-5 h-5 overflow-hidden rounded-full bg-black/10">
                    <Image
                        imageUrl={
                            "https://www.tanroads.go.tz/uploads/files/logo_302x312.png"
                        }
                        alt="tarura logo"
                    />
                </div>
                <p className={'uppercase'}>{project?.piu?.abbreviation}</p>
            </div>
            <div className="flex justify-center text-black/60">
                {dayjs(project.createdAt).format('DD, MMMM YYYY')}
            </div>
            <div className="flex justify-center ">
                <Link
                    to={`/settings/project-management/${slugify(project.name)}`}
                    onClick={handleClick}
                    className="flex items-center justify-center py-0 space-x-2 border-b text-accent border-b-transparent hover:border-b-accent"
                >
                    <p>view</p>
                    <ArrowRight/>
                </Link>
            </div>
        </div>
    );
};

export default ProjectItem;
