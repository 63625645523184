import StakeholdersIllustrator from "./stakeholders-illustrator";

const EmptyState = () => {
    return (
        <div className={"bg-white py-10 flex-col center"}>
            <div className={"h-40 w-40 bg-gray-100 rounded-full p-2 center"}>
                <StakeholdersIllustrator />
            </div>
            <p className={"text-center text-gray-400"}>
                There are currently no high-level stakeholder engagements to{" "}
                display. <br />
                Please check back later for updates!
            </p>
        </div>
    );
};
export default EmptyState;
