import { useNavigate } from "react-router-dom";
import CheckIcon from "../../../../../../components/CheckIcon";
import {
    getValueFromLocalStorage,
    saveValueToLocalStorage,
} from "../../../../../../hooks/useLocalStorageState";

const StepCard = ({
    stepTitle,
    stepNumber,
    setCurrStep,
    isActive = 0,
    stepPath = "",
    isCompleted = 0,
}) => {
    // Space

    const navigate = useNavigate();
    const isEditingEvent = getValueFromLocalStorage("isEditingProjectEvent", 0);

    const showStepContents = () => {
        if (isEditingEvent) {
            navigate(stepPath);
            setCurrStep(stepNumber);
            saveValueToLocalStorage("meetingFormCurrentStep", stepNumber);
        }
    };

    const gradientBackground = {
        background:
            "transparent linear-gradient(180deg, #A0C7F1 0%, #267CE1 100%) 0% 0% no-repeat padding-box",
    };

    return (
        <div
            onClick={() => showStepContents()}
            className={`w-full text-[#31394C] h-20 flex justify-between items-center  pr-1 cursor-${
                isEditingEvent ? "pointer" : ""
            }`}
        >
            {/* decoration */}
            {isActive === true && (
                <div
                    style={gradientBackground}
                    className={`
                    w-[5px] h-full  relative triangle`}
                ></div>
            )}

            {/*  */}
            <div
                className={`w-full  ${
                    isActive
                        ? "bg-white font-medium"
                        : "bg-[#F8F8F8] text-[#AAB6C5] font-medium border-r-[1px]"
                }  
                    border-b-[1px] border-[#EBEBEB] h-full items-between py-5 px-7`}
            >
                <p
                    className={`text-[15px] ${
                        isCompleted ? "line-through" : ""
                    }`}
                >
                    {stepTitle}
                </p>

                {stepNumber && (
                    <div
                        className={`text-[13px] center ${
                            isActive ? "text-white " : "text-[#F9F9F9]"
                        } 
                         h-[1.45rem] w-[1.45rem] rounded-full`}
                        style={
                            isActive
                                ? gradientBackground
                                : {
                                      backgroundColor: isCompleted
                                          ? "#70CCBF"
                                          : "#AAB6C5",
                                  }
                        }
                    >
                        {isCompleted ? <CheckIcon /> : stepNumber}
                    </div>
                )}
            </div>

            {isActive === true && (
                <div className={"h-full w-1"} style={gradientBackground}></div>
            )}
        </div>
    );
};
export default StepCard;
