import React, { Fragment, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useMutation } from "react-query";

import {
    getValueFromLocalStorage,
    saveMultiValueToLocalStorage,
    saveValueToLocalStorage,
} from "hooks/useLocalStorageState";
import FileUpload from "components/file-upload";
import UploadedFiles from "components/file-upload/uploaded-files";
import { updateProjectEvent } from "provider/api";
import { localStorageKeys } from "../../../../../../../../../constants";
import ActionButtons from "../_partials/action-buttons";

const Attachment = () => {
    const navigate = useNavigate();
    const { meetingForm: meetingLSKeys } = localStorageKeys;
    const [setCurrStep, toast] = useOutletContext();
    const [isOpen, setIsOpen] = useState(false);
    const [uploadedFiles, setUploadedFiles] = useState(
        getValueFromLocalStorage(meetingLSKeys.ATTACHMENTS, [])
    );
    const meetingBasicInfo = getValueFromLocalStorage(
        meetingLSKeys.BASIC_DETAILS,
        {}
    );
    const isEditing = getValueFromLocalStorage(
        meetingLSKeys.IS_UPDATING_DETAILS,
        false
    );

    const handlePrevious = async () => {
        setCurrStep(4);
        await saveValueToLocalStorage("meetingFormCurrentStep", 4);
        navigate("/event-registration/meeting/communication-channel");
    };

    const { mutate: updateEvent } = useMutation(updateProjectEvent, {
        onSuccess: async ({ data }, reqPayload) => {
            await saveAttachmentAndAdvanceToNextStep(reqPayload);
        },
        onError: ({ message }) => toast.error(message),
    });

    const buildPayload = () => {
        return {
            ...meetingBasicInfo,
            attachments: [
                ...uploadedFiles.map((file) => {
                    const { filename, ...rest } = file;
                    return { title: filename, ...rest };
                }),
            ],
        };
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const payload = buildPayload();

        const action = isEditing
            ? updateEvent
            : saveAttachmentAndAdvanceToNextStep;
        await action(payload);
    };

    const saveAttachmentAndAdvanceToNextStep = async (payload) => {
        setCurrStep(6);
        await saveMultiValueToLocalStorage({
            [meetingLSKeys.ATTACHMENTS]: uploadedFiles,
            [meetingLSKeys.BASIC_DETAILS]: payload,
            [meetingLSKeys.CURRENT_STEP]: 6,
        });
        toast.success("Data has been saved successfully");
        navigate("/event-registration/meeting/set-reminder");
    };

    const updateUploadedFileList = (files) => {
        const initialFiles = uploadedFiles.length
            ? uploadedFiles
            : meetingBasicInfo.attachments ?? [];
        setUploadedFiles([...initialFiles, ...files]);
    };

    return (
        <Fragment>
            <FileUpload
                onClose={setIsOpen}
                isOpen={isOpen}
                onSubmit={updateUploadedFileList}
            />
            <div className={"relative w-[90%] space-y-6 pt-10"}>
                <div>
                    <p className={"py-1 text-[#4D4F5C] font-media text-lg"}>
                        Upload Meeting Attachments
                        <span
                            className={
                                "font-light text-[15px] text-gray-500 ml-2"
                            }
                        >
                            {" "}
                            ( Optional ){" "}
                        </span>
                    </p>
                    <p
                        className={
                            "text-sm text-gray-500 hidden tracking-wide font-light"
                        }
                    >
                        Upload documents or image th{" "}
                    </p>
                </div>
                <UploadedFiles
                    onUpload={() => setIsOpen(true)}
                    files={
                        uploadedFiles.length
                            ? uploadedFiles
                            : meetingBasicInfo?.attachments
                    }
                    onRemove={setUploadedFiles}
                />
            </div>

            <form onSubmit={handleSubmit} className={"mr-24 "}>
                <ActionButtons
                    text={"Next"}
                    onCancel={handlePrevious}
                    isEditing={isEditing}
                    onSave={() => {}}
                />
            </form>
        </Fragment>
    );
};
export default Attachment;
