import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";


const Header = ({navToGrievances}) => {

    return (
        <div className=" grid grid-col-1 space-y-3 pt-6">

            <div className="flex place-items-center space-x-10 justify-between">
                <button
                    onClick={navToGrievances}
                    className=" grid place-items-center w-4 transition-all hover:scale-125">
                    <FontAwesomeIcon icon={faArrowLeft} className="text=[#628799]" />
                </button>
                <p className="text-[#192B40] font-medium text-2xl pr-10">
                    All Resolved Complaints
                </p>
            </div>



            <p className="text-[#949599] text-base">
                The following are the resolved complaints from various people involved
                in a project. You can delete a resolved complaint or you can restart 
                if you think it needs to be resolved again. 
            </p>
        </div>
    );
}

export default Header;