import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import PieChartLabel from "../../../engagement/activity/overview/activity-status-pichart/label";
import React from "react";
import { useEngagementContext } from "./engagement";

const TaskCompletionRate = (props) => {
    const { taskCompletionRate } = useEngagementContext();
    const options = {
        plotOptions: {
            series: {
                dataLabels: {
                    enabled: true,
                    format: "{point.name}: {point.y:.0f}%",
                },
                point: {
                    events: {
                        click: function () {
                            window.open(this.options.url, "_blank").focus();
                        },
                    },
                },
            },
            pie: {
                allowPointSelect: true,
                cursor: "pointer",
                borderWidth: 0,
                dataLabels: {
                    enabled: false,
                },
                colors: ["#A5DED4", "#FDD1A9", "#168ADC", "#FDC2C0"],
                showInLegend: false,
            },
        },

        title: {
            text: "",
            y: 3.5,
            style: {
                display: false,
                fontSize: "14px",
                fontWeight: "bold",
                color: "#253A53",
            },
        },
        chart: {
            renderTo: "chart", // Spacing between Title & Chart
            defaultSeriesType: "areaspline",
        },
        series: [
            {
                type: "pie",
                name: "Activities count",
                innerSize: "80%",
                data: [
                    {
                        name: "upcoming",
                        color: "#1072BD",
                        y: taskCompletionRate?.upcoming,
                    },
                    {
                        name: "overdue",
                        color: "#E14A54",
                        y: taskCompletionRate?.overdue,
                    },
                    {
                        name: "completed",
                        color: "#7CD28A",
                        y: taskCompletionRate?.completed,
                    },
                ],
            },
        ],
    };

    return (
        <div
            {...props}
            className={" h-80 rounded border-[1px] border-[#E4E4E4] py-3 px-2"}
        >
            <p
                className={
                    "text-center text-[#253A53] tracking-wide text-[15px] font-semibold "
                }
            >
                Task Completion Rate
            </p>
            <div className={"h-56"}>
                <div className={"h-full highcharts relative "}>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={options}
                    />
                </div>
            </div>
            <div className={"flex justify-between px-5 "}>
                <div className={" text-[10px] space-y-3"}>
                    <PieChartLabel
                        text={`${taskCompletionRate?.upcoming}% UPCOMING`}
                        color={"bg-[#1072BD]"}
                    />
                    <PieChartLabel
                        text={`${taskCompletionRate?.overdue}% OVERDUE`}
                        color={"bg-[#E14A54]"}
                    />
                </div>

                <div className={" text-[10px] space-y-3"}>
                    <PieChartLabel
                        text={`${taskCompletionRate?.completed}% COMPLETED`}
                        color={"bg-[#7CD28A]"}
                    />
                </div>
            </div>
        </div>
    );
};
export default TaskCompletionRate;
