import {ChevronLeftIcon, ChevronRightIcon} from "@heroicons/react/outline";
import dayjs from "dayjs";
import {Can} from "provider/permission";
import EventButton from "./event-button";

const CalenderHeader = (
    {
        monthIndex,
        setMonthIndex,
        controllerPosition = "left",
        showCreateMeetingButton = false
    }) => {
    const handlePrevMonth = () => {
        return setMonthIndex(monthIndex - 1)
    }

    const handleNextMonth = () => {
        return setMonthIndex(monthIndex + 1)
    }
    return (
        <header
            className={`flex justify-between h-16 px-3 ${controllerPosition === "left" ? 'order-1' : 'order-2'} items-center`}>
            <div className={'flex items-center order-2 space-x-8 '}>
                <div className={'flex items-center '}>
                    <ChevronLeftIcon onClick={handlePrevMonth} className={'h-5 w-5 text-[#03304E] cursor-pointer'}/>
                    <p className={'text-[#838EAB]'}>{dayjs(new Date(dayjs().year(), monthIndex)).format("MMMM YYYY")}</p>
                    <ChevronRightIcon onClick={handleNextMonth} className={'h-5 w-5 text-[#03304E] cursor-pointer'}/>
                </div>

                {showCreateMeetingButton && (
                    <Can permission={'create-projectEvents'}>
                        <EventButton/>
                    </Can>
                )}
            </div>

            <div className={`opacity-0 space-x-3 ${controllerPosition === "left" ? 'order-2' : 'order-1'}`}>
                <button className={'text-[#6C6B84] bg-[#628799]/20 text-sm px-4 py-2 rounded-[1.5px]'}>
                    Day
                </button>

                <button className={'text-[#6C6B84] bg-[#628799]/20 text-sm px-4 py-2 rounded-[1.5px]'}>
                    Week
                </button>

                <button className={'text-white bg-[#628799] text-sm px-4 py-2 rounded-[1.5px]'}>
                    Month
                </button>

            </div>
        </header>
    )
}
export default CalenderHeader;