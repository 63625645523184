import { createContext, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useLocalStorageState, {
    getValueFromLocalStorage,
    saveValueToLocalStorage,
} from "../../hooks/useLocalStorageState";
import { localStorageKeys } from "../../constants";

const AuthContext = createContext(null);

export function AuthProvider({ children }) {
    const contentsView = getValueFromLocalStorage("contentsView", "general");
    const navigate = useNavigate();
    const location = useLocation();

    const { from } = location.state || {
        from: {
            pathname: contentsView === "general" ? "/" : "/analytics/list",
        },
    };
    const [authUser, setAuthUser] = useLocalStorageState("authUser", null);
    const [authToken, setAuthToken] = useLocalStorageState("authToken", null);

    async function signOut(callback) {
        setAuthToken(null);
        setAuthUser(null);

        if (callback && typeof callback === "function") {
            await Promise.resolve(callback());
        }
        navigate("/login");
    }

    function saveAuthUser(user, token) {
        setAuthToken(token);
        setAuthUser(user);

        const hasSubprojects = (projectId) => {
            const { subProjects = [] } = authUser;
            return subProjects.some(
                ({ parentProject } = {}) => parentProject === projectId
            );
        };

        // redirect the user to the project selection if they have more than one project.
        if (user.projects.length > 1 && !user?.defaultProject) {
            return navigate("/get-started");
        } else if (
            hasSubprojects(user.defaultProject._id) &&
            !user?.defaultSubProject
        ) {
            return navigate("/select-subprojects");
        } else {
            saveValueToLocalStorage(
                localStorageKeys.PROJECT_ID,
                user?.defaultProject._id
            );
            if (hasSubprojects(user.defaultProject._id)) {
                saveValueToLocalStorage(
                    localStorageKeys.SUBPROJECT_ID,
                    user?.defaultSubProject._id
                );
                saveValueToLocalStorage(
                    localStorageKeys.SUBPROJECT_DETAILS,
                    user?.defaultSubProject
                );
            }
            return navigate(from);
        }
    }

    const value = {
        authUser,
        authToken,
        saveAuthUser,
        signOut,
    };

    return (
        <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
    );
}

export function useAuth() {
    return useContext(AuthContext);
}
