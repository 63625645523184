import NatureOfGrievanceBreakdown from "./nature-of-grievance-breakdown";
import useGrievanceAnalytics from "../../../../hooks/useGrievanceAnalytics";
import { groupByProperty } from "../../../../utils";

const NatureOfGrievanceSummary = () => {
	const { grievanceAnalytic, isLoading } = useGrievanceAnalytics();
	if (isLoading)
		return (
			<div className="w-[45%] bg-white rounded p-5 space-y-8">
				<div className="space-y-1.5 animate-pulse">
					<div className="w-56 h-3.5 bg-secondary/[.23] rounded-full "></div>
					<div className="w-28 h-3 bg-secondary/[.3] rounded-full "></div>
				</div>

				<div className="space-y-5">
					{[...Array(4)].map((item, index) => (
						<div className="space-y-1 animate-pulse" key={index}>
							<div className="w-8 h-2 bg-secondary/[.23] rounded-full"></div>
							<div className="flex justify-between">
								<div className="w-16 h-2 bg-secondary/[.24] rounded-full"></div>
								<div className="w-8 h-2 bg-secondary/[.2] rounded-full"></div>
							</div>

							<div className="w-full h-1.5 bg-secondary/[.23] rounded-full "></div>
						</div>
					))}
				</div>
			</div>
		);
	const { byNature: grievanceByNature = [], general: grievances } =
		grievanceAnalytic;

	const groupedByNature = groupByProperty(grievanceByNature, "nature");
	const _natures = Object.keys(groupedByNature);

	const statistics = _natures.map((nature) => {
		const { report } = groupedByNature[nature][0];
		return {
			title: nature,
			total: report?.total,
			percentage: Math.floor(
				(report?.total * 100) / grievances?.total ?? 0
			),
		};
	});

	return (
		<div
			className={
				" p-5  bg-white space-y-3 pb-8 border-[1px] border-[#E4E4E4] border-t-[3px] border-t-accent rounded-[6px] w-[45%]"
			}
		>
			<p className={"text-[#31394C] font-light"}>
				Summary of the nature <br />
				of grievances
			</p>
			<div className={"space-y-2"}>
				{statistics.map((nature) => (
					<NatureOfGrievanceBreakdown
						title={nature.title}
						percentage={nature.percentage}
						total={nature.total}
					/>
				))}
			</div>
		</div>
	);
};
export default NatureOfGrievanceSummary;
