import {CurrStakeholderContext} from "../../../context";
import {useState} from "react";

const CurrStakeholderProvider = ({children}) => {
    const [currStakeholder, setCurrStakeholder] = useState({});
    return (
        <CurrStakeholderContext.Provider value={[
            currStakeholder,
            setCurrStakeholder
        ]}>
            {children}
        </CurrStakeholderContext.Provider>
    )
}
export default CurrStakeholderProvider;