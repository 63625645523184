import { CalenderIcon } from "../../../../../../../svg/calender";
import { ClockIcon } from "@heroicons/react/outline";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import { CreateMarkup } from "../../../../../../../utils";

const GrievanceCard = ({ grievance }, ...props) => {
	const formatDate = (date) => {
		return dayjs(date).format("DD MMMM YYYY");
	};
	return (
		<div
			{...props}
			className={
				"p-5 space-y-4 text-sm rounded-[4px] border-[1px] border-[#E4E4E4]"
			}
		>
			<div className={"items-between text-xs"}>
				<div className={"flex items-center space-x-2"}>
					<CalenderIcon className={"h-3"} color={"#AAB6C5"} />
					<p className={"text-[#595959] "}>
						{formatDate(grievance.dateOfIncident)}
					</p>
				</div>

				<div className={"flex items-center space-x-1"}>
					<ClockIcon className={"h-4"} color={"#AAB6C5"} />
					<p className={"text-[#595959]"}>{grievance.time}</p>
				</div>
			</div>

			<div className={"text-xs space-y-3"}>
				<p className={"text-[#707070] font-light tracking-wide"}>
					Complainer:{" "}
					<span className={"text-secondary"}>
						{grievance?.complainer?.fullName}
					</span>
				</p>
				<p
					dangerouslySetInnerHTML={CreateMarkup(
						grievance?.description
					)}
					className={
						"text-[#202842]  leading-relaxed h-20 line-clamp-4"
					}
				></p>

				<div className={"pt-3 hidden"}>
					<button
						className={
							"border-[1.3px] bg-[#AAB6C56F] border-[#AAB6C4] w-full text-xs p-2 rounded-[1px] text-secondary"
						}
					>
						View Complaint progress
					</button>
				</div>
			</div>
		</div>
	);
};

GrievanceCard.propTypes = {
	grievance: PropTypes.object.isRequired,
};
export default GrievanceCard;
