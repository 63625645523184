export const EngagementIcon = ({isActive = false}) => (
    <svg className={'h-5 w-5'} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <g id="vuesax_bold_grid-lock" data-name="vuesax/bold/grid-lock" transform="translate(-748 -380)">
            <g id="grid-lock">
                <path id="Vector" d="M0,0H24V24H0Z" transform="translate(748 380)" fill="none" opacity="0"/>
                <path id="Vector-2" data-name="Vector"
                      d="M7.27,3.06V2.9A2.57,2.57,0,0,0,4.37,0a2.574,2.574,0,0,0-2.9,2.9v.16C.42,3.32,0,4.04,0,5.48v.74C0,8.07.69,8.75,2.53,8.75H6.21c1.85,0,2.53-.69,2.53-2.53V5.48C8.74,4.05,8.32,3.32,7.27,3.06ZM4.36,1.38c1.29,0,1.52.49,1.52,1.52v.05H2.84V2.9C2.84,1.87,3.07,1.38,4.36,1.38Z"
                      transform="translate(762.26 394.25)" fill={isActive ? '#fff' : '#c4c4c4'}/>
                <path id="Vector-3" data-name="Vector" d="M5.75,0V5.8H0C0,2.19,2.15.02,5.75,0Z"
                      transform="translate(750 382)" fill={isActive ? '#fff' : '#c4c4c4'}/>
                <path id="Vector-4" data-name="Vector" d="M4.68,5.8H0V0C3.21.02,5.27,1.75,5.68,4.68A1,1,0,0,1,4.68,5.8Z"
                      transform="translate(764.25 382)" fill={isActive ? '#fff' : '#c4c4c4'}/>
                <path id="Vector-5" data-name="Vector" d="M5.75,0V4.62a1.007,1.007,0,0,1-1.13,1C1.73,5.19.04,3.16,0,0Z"
                      transform="translate(750 396.3)" fill={isActive ? '#fff' : '#c4c4c4'}/>
                <path id="Vector-6" data-name="Vector" d="M5.75,0H0V5.5H5.75Z" transform="translate(750 389.3)"
                      fill={isActive ? '#fff' : '#c4c4c4'}/>
                <path id="Vector-7" data-name="Vector" d="M5.5,0H0V5.5H4.5a1,1,0,0,0,1-1Z"
                      transform="translate(757.25 389.3)" fill="#c4c4c4"/>
                <path id="Vector-8" data-name="Vector" d="M5.5,0H0V5.8H5.5Z" transform="translate(757.25 382)"
                      fill={isActive ? '#fff' : '#c4c4c4'}/>
            </g>
        </g>
    </svg>
)

export const CommitmentIcon = ({isActive = false}) => {
    return (
        <svg id="pet" xmlns="http://www.w3.org/2000/svg" className={'h-5 w-5'} viewBox="0 0 24.475 24.475">
            <path id="Vector"
                  d="M15.522,6.527A8.094,8.094,0,0,0,7.813,0,7.965,7.965,0,0,0,.021,7.169a5.255,5.255,0,0,0,5.3,5.762H10.1a5.468,5.468,0,0,0,4.263-1.887A5.5,5.5,0,0,0,15.522,6.527Z"
                  transform="translate(3.935 9.504)" fill={isActive ? '#fff' : '#c4c4c4'}/>
            <path id="Vector-2" data-name="Vector"
                  d="M5.976,2.988A2.988,2.988,0,1,1,2.988,0,2.988,2.988,0,0,1,5.976,2.988Z"
                  transform="translate(7.495 2.04)" fill={isActive ? '#fff' : '#c4c4c4'}/>
            <path id="Vector-3" data-name="Vector"
                  d="M4.977,2.488A2.488,2.488,0,1,1,2.488,0,2.488,2.488,0,0,1,4.977,2.488Z"
                  transform="translate(14.787 4.232)" fill={isActive ? '#fff' : '#c4c4c4'}/>
            <path id="Vector-4" data-name="Vector"
                  d="M3.977,1.989A1.989,1.989,0,1,1,1.989,0,1.989,1.989,0,0,1,3.977,1.989Z"
                  transform="translate(18.968 9.209)" fill={isActive ? '#fff' : '#c4c4c4'}/>
            <path id="Vector-5" data-name="Vector"
                  d="M4.977,2.488A2.488,2.488,0,1,1,2.488,0,2.488,2.488,0,0,1,4.977,2.488Z"
                  transform="translate(1.53 6.221)" fill={isActive ? '#fff' : '#c4c4c4'}/>
            <path id="Vector-6" data-name="Vector" d="M0,0H24.475V24.475H0Z" fill={isActive ? '#fff' : '#c4c4c4'}
                  opacity="0"/>
        </svg>
    )
}

export const GrievancesIcon = ({isActive = false}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className={'h-5 w-5'} viewBox="0 0 25.5 25.5">
            <g id="vuesax_bold_messages" data-name="vuesax/bold/messages" transform="translate(-556 -316)">
                <g id="messages" transform="translate(556 316)">
                    <path id="Vector" d="M0,0H25.5V25.5H0Z" fill="none" opacity="0"/>
                    <path id="Vector-2" data-name="Vector"
                          d="M11.889,0h-6.8a6.444,6.444,0,0,0-.8.042C1.434.287,0,1.976,0,5.089v4.25c0,4.25,1.7,5.089,5.089,5.089h.425a.981.981,0,0,1,.68.34l1.275,1.7a1.2,1.2,0,0,0,2.04,0l1.275-1.7a.855.855,0,0,1,.68-.34h.425c3.113,0,4.8-1.424,5.047-4.293a6.444,6.444,0,0,0,.042-.8V5.089Q16.979.005,11.889,0ZM4.781,8.5A1.063,1.063,0,1,1,5.844,7.438,1.059,1.059,0,0,1,4.781,8.5Zm3.708,0A1.063,1.063,0,1,1,9.552,7.438,1.059,1.059,0,0,1,8.489,8.5Zm3.719,0a1.063,1.063,0,1,1,1.063-1.062A1.059,1.059,0,0,1,12.208,8.5Z"
                          transform="translate(2.125 6.375)" fill={isActive ? '#fff' : '#c4c4c4'}/>
                    <path id="Vector-3" data-name="Vector"
                          d="M16.311,5.089v4.25c0,2.125-.659,3.57-1.976,4.367a.465.465,0,0,1-.691-.436l.011-3.931c0-4.25-2.433-6.683-6.683-6.683L.5,2.667a.465.465,0,0,1-.436-.691C.862.659,2.307,0,4.421,0h6.8Q16.305,0,16.311,5.089Z"
                          transform="translate(7.043 2.125)" fill={isActive ? '#fff' : '#c4c4c4'}/>
                </g>
            </g>
        </svg>
    )
}
export const StakeholderIcon = ({isActive = false}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className={'h-5 w-5'} viewBox="0 0 20.238 17.192">
            <g id="group" transform="translate(-0.5 472.995)">
                <path id="Path_101756" data-name="Path 101756"
                      d="M170.316-472.951a4.009,4.009,0,0,0-1.164.42,4.118,4.118,0,0,0-1.3,1.279,3.509,3.509,0,0,0,.566,4.254,3.382,3.382,0,0,0,2.471,1.022A3.382,3.382,0,0,0,173.365-467a3.509,3.509,0,0,0,.566-4.254,4.118,4.118,0,0,0-1.3-1.279A3.655,3.655,0,0,0,170.316-472.951Z"
                      transform="translate(-160.275 0)" fill={isActive ? '#fff' : '#c4c4c4'}/>
                <path id="Path_101757" data-name="Path 101757"
                      d="M35.853-389.91a2.682,2.682,0,0,0-1.762,1.545,2.306,2.306,0,0,0-.19,1.014,2.45,2.45,0,0,0,.261,1.145,2.671,2.671,0,0,0,1.723,1.39,3.1,3.1,0,0,0,1.267,0,2.842,2.842,0,0,0,1.541-1.085,2.666,2.666,0,0,0,.135-2.709,2.945,2.945,0,0,0-1.069-1.069A2.75,2.75,0,0,0,35.853-389.91Z"
                      transform="translate(-32.077 -79.718)" fill={isActive ? '#fff' : '#c4c4c4'}/>
                <path id="Path_101758" data-name="Path 101758"
                      d="M347.883-389.873a2.755,2.755,0,0,0-1.644,1.3,2.676,2.676,0,0,0-.123,2.222,2.736,2.736,0,0,0,1.8,1.572,3.106,3.106,0,0,0,1.267,0A2.68,2.68,0,0,0,351.1-386.7a3.329,3.329,0,0,0,.024-1.172,2.684,2.684,0,0,0-1.929-2A2.836,2.836,0,0,0,347.883-389.873Z"
                      transform="translate(-331.751 -79.756)" fill={isActive ? '#fff' : '#c4c4c4'}/>
                <path id="Path_101759" data-name="Path 101759"
                      d="M139.428-276.674a2.688,2.688,0,0,0-1.45.808,2.8,2.8,0,0,0-.745,1.184l-.1.3-.012,3.327c-.008,3.271-.008,3.331.071,3.458a.728.728,0,0,0,.206.206c.127.079.21.079,4.428.079s4.3,0,4.428-.079a.728.728,0,0,0,.206-.206c.079-.127.079-.186.071-3.458l-.012-3.327-.1-.3a2.8,2.8,0,0,0-.745-1.184,2.8,2.8,0,0,0-1.184-.745l-.3-.1-2.257-.008A20.487,20.487,0,0,0,139.428-276.674Z"
                      transform="translate(-131.205 -188.499)" fill={isActive ? '#fff' : '#c4c4c4'}/>
                <path id="Path_101760" data-name="Path 101760"
                      d="M3.023-238.956a2.962,2.962,0,0,0-2.432,2.1A8.116,8.116,0,0,0,.5-234.5v2.059l.091.226a1.889,1.889,0,0,0,1.03,1.03c.218.087.265.091,1.719.1l1.493.012-.048-.131c-.059-.174-.091-6.226-.032-6.764a5.222,5.222,0,0,1,.107-.614c.036-.131.075-.277.083-.329l.02-.087-.844,0C3.653-238.992,3.157-238.976,3.023-238.956Z"
                      transform="translate(0 -224.727)" fill={isActive ? '#fff' : '#c4c4c4'}/>
                <path id="Path_101761" data-name="Path 101761"
                      d="M398.82-238.913c.008.051.048.2.083.329a5.225,5.225,0,0,1,.107.614c.059.539.028,6.59-.032,6.764l-.048.131,1.493-.012c1.453-.012,1.5-.016,1.719-.1a1.889,1.889,0,0,0,1.03-1.03l.091-.226V-234.5c0-2.055,0-2.059-.1-2.356a2.935,2.935,0,0,0-1.865-1.96,5.1,5.1,0,0,0-1.715-.178H398.8Z"
                      transform="translate(-382.526 -224.727)" fill={isActive ? '#fff' : '#c4c4c4'}/>
            </g>
        </svg>

    )
}


