import dayjs from "dayjs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCalendarDay,
    faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

const CommitmentItem = (
    { commitment, setActiveCommitment, setShowDetails },
    ...props
) => {
    const formatDate = (date) => {
        return dayjs(date).format("MMM DD, YYYY");
    };

    const handleClick = () => {
        setActiveCommitment(commitment);
        setShowDetails(true);
    };

    const getCommitmentCurrStatus = () => {
        switch (commitment?.status) {
            case 0:
                return "OFF TRACK";
            case 1:
                return "ON TRACK";
            case 2:
                return "COMPLETED";
            case 3:
                return "OVERDUE";
            default:
                return "INVALID STATUS";
        }
    };
    const commitmentCurrStatus = getCommitmentCurrStatus();

    const getStatusClassName = () => {
        if (commitmentCurrStatus === "COMPLETED") {
            return "bg-green-500/20 text-green-600 tracking-wider text-xs  whitespace-nowrap w-20 text-center py-1 border-[1px] border-green-300 rounded-full";
        }

        if (commitmentCurrStatus === "OVERDUE") {
            return "bg-red-500/20 text-red-600 tracking-wider text-xs whitespace-nowrap w-20 text-center py-1 border-[1px] border-red-300 rounded-full";
        }

        if (commitmentCurrStatus === "OFF TRACK") {
            return "bg-[#FC625D]/20 text-[#FC625D] tracking-wider text-xs whitespace-nowrap w-20 text-center  py-1 border-[1px] border-[#FC625D]/30 rounded-full";
        }

        if (commitmentCurrStatus === "ON TRACK") {
            return "bg-blue-500/20 text-blue-600 tracking-wider text-xs  whitespace-nowrap w-20 text-center py-1 border-[1px] border-blue-300 rounded-full";
        }
    };

    return (
        <div {...props} className="grid grid-cols-6 bg-white ">
            <div className={" flex items-center"}>
                <p className="text-[#37474F] text-sm">
                    {commitment?.madeFor ?? "N/A"}
                </p>
            </div>
            <div className={"flex items-center"}>
                <p className="text-[#37474F] text-sm">
                    {commitment?.department?.name ?? "N/A"}
                </p>
            </div>

            <div className={"flex items-center"}>
                <p className={`${getStatusClassName()}`}>
                    {commitmentCurrStatus.toLowerCase()}
                </p>
            </div>

            <div className="flex flex-row col-span-2 py-3 space-x-2 place-items-start">
                <div
                    className={`bg-[#E4E4E4] mt-1 h-2 w-2 shrink-0 rounded-full`}
                ></div>
                <p className="text-[#37474F] text-sm">{commitment?.title} </p>
            </div>

            <div className="flex flex-row py-3 space-x-6 shrink-0 place-items-start">
                <div className="flex flex-row space-x-2 place-items-start">
                    <FontAwesomeIcon icon={faCalendarDay} color={"#D6D6D6"} />
                    <p className="w-24 text-[#51BF82] text-sm font-normal">
                        {formatDate(commitment?.endDate)}
                    </p>
                </div>

                <button
                    onClick={handleClick}
                    className="flex flex-row space-x-2 place-items-center"
                >
                    <FontAwesomeIcon
                        icon={faChevronRight}
                        className={
                            "text-[#A2A2A2] hover:text-[#009EE2] hover:scale-125"
                        }
                    />
                </button>
            </div>
        </div>
    );
};
export default CommitmentItem;
