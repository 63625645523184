const ArrowRightWithBorder = ({className}) => {
    return (
        <svg className={className} xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 19.7 17.7">
            <g id="Group_162763" data-name="Group 162763" transform="translate(-1153.65 -116.65)">
                <line id="Line_87" data-name="Line 87" y2="16" transform="translate(1172.5 117.5)" fill="none"
                      stroke="#6f7782" strokeLinecap="round" strokeWidth="1.7"/>
                <g id="Group_162761" data-name="Group 162761">
                    <line id="Line_88" data-name="Line 88" x1="14" transform="translate(1154.5 125.5)" fill="none"
                          stroke="#6f7782" strokeLinecap="round" strokeWidth="1.7"/>
                    <line id="Line_89" data-name="Line 89" x1="5.999" y1="6" transform="translate(1162.5 119.5)"
                          fill="none" stroke="#6f7782" strokeLinecap="round" strokeWidth="1.7"/>
                    <line id="Line_90" data-name="Line 90" y1="6" x2="5.999" transform="translate(1162.5 125.5)"
                          fill="none" stroke="#6f7782" strokeLinecap="round" strokeWidth="1.7"/>
                </g>
            </g>
        </svg>

    )
}
export default ArrowRightWithBorder;