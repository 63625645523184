import {useState} from "react";
import {useQuery} from "react-query";
import {getEventReport} from "../provider/api";

const useGetEventReport = (id) => {
    const [eventReport, setEventReport] = useState({});

    const restructureEventReport = (report) => {
        const {
            participants: pts,
            mainAgenda, otherAgendas,
            ...rest
        } = report;

        const participants = {...groupEventParticipants(pts)};
        setEventReport({
            participants,
            agendas: {mainAgenda, otherAgendas},
            ...rest
        });
    }

    const groupEventParticipants = (participants) => {
        const individualStakeholder = participants.filter(participant => {
            return participant.stakeholder.type === "Individual"
        });

        const organizationalStakeholder = participants.filter(participant => {
            return participant.stakeholder.type === "Organizational"
        });

        return {
            individualStakeholder: individualStakeholder.map((participant) => participant.stakeholder),
            organizationalStakeholder: organizationalStakeholder.map((participant) => participant.stakeholder),
        }
    }


    if (!id) {
        throw(new Error('id is required to fetch event report'))
    }

    const {isLoading: isFetchingReport} = useQuery([`event-report-${id}`, id], getEventReport, {
        onSuccess: (res) => {
            console.log('REPORT: ', res.data)
            restructureEventReport(res.data)
        }
    });
    return {eventReport, isFetchingReport}
}
export default useGetEventReport;