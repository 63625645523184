import { ParagraphLoading } from "components/skeleton";

const Loading = () => {
    const getWidth = (index) => {
        switch (index) {
            case 0: {
                return "100%";
            }
            case 1: {
                return "80%";
            }
            case 2: {
                return "50%";
            }
            case 3: {
                return "85%";
            }
            default:
                return "90%";
        }
    };
    return (
        <div className={"space-y-4 pt-4"}>
            {[...Array(10)].map((item, index) => (
                <div
                    className={
                        "border flex space-x-20 h-48 bg-white px-8 py-6 "
                    }
                    key={index}
                >
                    <div
                        className={
                            "flex flex-col w-fit shrink-0 justify-between "
                        }
                    >
                        <div className={"space-y-2 "}>
                            <div className={"flex items-center space-x-2"}>
                                <p
                                    className={
                                        "h-6 w-6 rounded skeleton-loading shrink-0 rounded-full"
                                    }
                                ></p>
                                <ParagraphLoading width={"5rem"} />
                            </div>
                            <ParagraphLoading width={"15rem"} />
                        </div>
                        <ParagraphLoading width={"5rem"} height={"20px"} />
                    </div>
                    <div
                        className={
                            "w-full flex flex-col justify-between h-full"
                        }
                    >
                        {[...Array(5)].map((item, index) => (
                            <div
                                key={index}
                                className={"grid gap-5 grid-cols-6"}
                            >
                                {[...Array(5)].map((item, idx) => {
                                    if (idx === 0) {
                                        return (
                                            <div
                                                className={
                                                    "flex col-span-2 items-center space-x-2"
                                                }
                                            >
                                                <ParagraphLoading
                                                    width={"10px"}
                                                    className={
                                                        "rounded-full shrink-0"
                                                    }
                                                />
                                                <ParagraphLoading
                                                    width={getWidth(index)}
                                                    className={""}
                                                />
                                            </div>
                                        );
                                    }
                                    return (
                                        <ParagraphLoading
                                            width={"100%"}
                                            className={""}
                                        />
                                    );
                                })}
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
};
export default Loading;
