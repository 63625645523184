export const ParagraphLoading = ({ height, width, className }) => {
    return (
        <p
            style={{ width: width ?? "100%", height: height ?? "10px" }}
            className={` skeleton-loading rounded ${className}`}
        ></p>
    );
};

export const CommitmentLoading = () => {
    return (
        <div className={"bg-white rounded p-8 space-y-10 h-72"}>
            <div className={"items-between"}>
                <div className={"space-y-2"}>
                    <p className={"h-2.5 w-16 rounded skeleton-loading"}></p>
                    <p className={"h-2.5 w-28 rounded skeleton-loading"}></p>
                </div>

                <div className={"space-y-2"}>
                    <p className={"h-2.5 w-16 rounded skeleton-loading"}></p>
                    <p className={"h-2.5 w-28 rounded skeleton-loading"}></p>
                </div>
            </div>

            <div className={"space-y-4 flex flex-col justify-between"}>
                <p className={"h-2.5 w-20 rounded skeleton-loading"}></p>

                <div className={"space-y-1"}>
                    <div className={"flex space-x-2"}>
                        <p
                            className={
                                "h-2.5 w-[90%] skeleton-loading rounded skeleton-loading"
                            }
                        ></p>
                        <p
                            className={"h-2.5 w-2.5 rounded skeleton-loading"}
                        ></p>
                    </div>

                    <div className={"flex space-x-2"}>
                        <p
                            className={
                                "h-2.5 w-[70%] skeleton-loading rounded skeleton-loading"
                            }
                        ></p>
                        <p
                            className={"h-2.5 w-[30%] rounded skeleton-loading"}
                        ></p>
                    </div>

                    <div className={"flex space-x-2"}>
                        <p
                            className={
                                "h-2.5 w-[20%] skeleton-loading rounded skeleton-loading"
                            }
                        ></p>
                        <p
                            className={"h-2.5 w-[60%] rounded skeleton-loading"}
                        ></p>
                        <p
                            className={
                                "h-2.5 w-[20%] rounded opacity-90 skeleton-loading"
                            }
                        ></p>
                    </div>
                    <div className={"flex space-x-2"}>
                        <p
                            className={
                                "h-2.5 w-[90%] skeleton-loading rounded skeleton-loading"
                            }
                        ></p>
                        <p
                            className={"h-2.5 w-2.5 rounded skeleton-loading"}
                        ></p>
                    </div>

                    <div className={"flex space-x-2"}>
                        <p
                            className={
                                "h-2.5 w-[30%] rounded opacity-90 skeleton-loading"
                            }
                        ></p>
                        <p
                            className={
                                "h-2.5 w-[70%] skeleton-loading rounded skeleton-loading"
                            }
                        ></p>
                    </div>

                    <div className={"flex space-x-2"}>
                        <p
                            className={
                                "h-2.5 w-[20%] skeleton-loading rounded skeleton-loading"
                            }
                        ></p>
                        <p
                            className={"h-2.5 w-[60%] rounded skeleton-loading"}
                        ></p>
                        <p
                            className={"h-2.5 w-[20%] rounded skeleton-loading"}
                        ></p>
                    </div>
                    <div className={"flex space-x-2"}>
                        <p
                            className={
                                "h-2.5 w-full opacity-90 skeleton-loading rounded skeleton-loading"
                            }
                        ></p>
                    </div>
                </div>

                <div className={"items-between"}>
                    <div
                        className={"h-10 w-10 rounded-full skeleton-loading"}
                    ></div>
                    <p className={"h-2.5 w-28 rounded skeleton-loading"}></p>
                </div>
            </div>
        </div>
    );
};
