import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { useProjectFiles } from "../../../../hooks";

const FilesSummary = () => {
	const { fileCategories = {} } = useProjectFiles();
	return (
		<div
			className={
				"bg-[#F6F9F8] flex space-x-10 border-[1px] border-[#D5E3D6] rounded-[4px] p-8"
			}
		>
			<div className={" w-full space-y-2"}>
				<p className={"text-lg text-[#838EAB] font-medium"}>
					All project files
				</p>
				<p className={"text-[13px] text-[#AAB6C5]"}>
					All files uploaded on the project <br />
					can be accessed here, also you <br />
					can upload yours.
				</p>
			</div>
			<div className={" w-full center"}>
				<div className={"space-y-1"}>
					<p className={"text-xl font-semibold text-secondary"}>
						{fileCategories?.totalFiles ?? 0}
					</p>
					<div
						className={
							"text-[13px] space-y-4 text-[#868686] font-light tracking-wide"
						}
					>
						<p className={"font-medium"}>Files Uploaded</p>
						<p
							className={
								"border-l-[1.8px] leading-tight pl-4 border-l-secondary"
							}
						>
							This marks total number <br />
							of files that have been <br />
							uploaded in the project <br />
							since it started.
						</p>
					</div>
				</div>
			</div>

			<div className={" w-full flex justify-center"}>
				<div className={"space-y-1"}>
					<p className={"text-xl font-semibold text-[#EB595C]"}>0</p>
					<div
						className={
							"text-[13px] space-y-2 font-light tracking-wide"
						}
					>
						<p className={"font-medium text-[#EB595C]"}>
							Files Deleted
						</p>
						<p className={"leading-tight text-[#868686]"}>
							Files that have been deleted <br />
							since the project started.
						</p>

						<div className={"pt-2"}>
							<button
								className={
									"bg-[#F7F7F7] flex space-x-1 items-center w-[90%] font-normal text-[#AAB6C5] text-sm px-4 py-2 rounded-[3px] border-[1.2px] border-[#D6D6D6]"
								}
							>
								<p> VIEW DELETED</p>
								<FontAwesomeIcon
									icon={faAngleRight}
									className={"h-4 w-4"}
								/>
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
export default FilesSummary;
