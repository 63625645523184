const Loading = () => {
	return (
		<div className="grid grid-cols-3 gap-x-3">
			<div className="flex flex-col justify-between p-5 bg-white border rounded h-44">
				<div className="w-12 h-12 rounded skeleton-loading "></div>
				<div className="space-y-2">
					<div className="w-16 h-2 rounded-full skeleton-loading "></div>
					<div className="w-full h-2 rounded-full skeleton-loading "></div>
				</div>
			</div>

			<div className="h-44 grid grid-cols-1 gap-1.5">
				<div className="h-[5.3rem] bg-white border space-y-5 rounded flex flex-col p-5 justify-center">
					<div className="w-16 h-2 rounded-full skeleton-loading "></div>
					<div className="w-full h-2 rounded-full skeleton-loading "></div>
				</div>
				<div className="h-[5.3rem] bg-white border space-y-5 rounded flex flex-col p-5 justify-center">
					<div className="w-16 h-2 rounded-full skeleton-loading "></div>
					<div className="w-full h-2 rounded-full skeleton-loading "></div>
				</div>
			</div>
			<div className="flex flex-col justify-between p-5 bg-white border rounded h-44">
				<div className="space-y-3">
					<div className="w-12 h-12 rounded-full skeleton-loading "></div>
					<div className=" h-2 skeleton-loading w-[80%] rounded-full "></div>
				</div>
				<div className="space-y-4">
					<div className="w-16 h-2 rounded-full skeleton-loading "></div>
					<div className="flex items-center space-x-2">
						<div className="w-full h-2 rounded-full skeleton-loading "></div>
						<div className="w-2 h-2 rounded-full shrink-0 skeleton-loading "></div>
					</div>
				</div>
			</div>
		</div>
	);
};
export default Loading;
