import {DownloadAltIcon} from "svg";
import {TrashIcon} from "@heroicons/react/outline";
import {useState, Fragment} from "react";
import useDownloadFileFromS3 from "../../../hooks/useDownloadFileFromS3";
import SpinLoader from "../../../components/SpinLoader";

const BudgetFileCard = ({file, showDeleteBtn = true}) => {
    const [isDownloading, setIsDownloading] = useState(false);

    const {s3FileDownloader} = useDownloadFileFromS3((progress) => {
        if (parseInt(progress) === 100) {
            setIsDownloading(false);
        }
    })

    const handleRemoval = () => {
        // ...
    }
    const handleDownload = () => {
        setIsDownloading(true)
        s3FileDownloader(file.fileKey, file.title)
    }

    return (
        <div className={'bg-[#F2F2F2] flex text-sm items-center '}>
            <div>
                <div className={'h-12 w-[4px] rounded-l'}
                     style={{background: "transparent linear-gradient(180deg, #A0C7F1 0%, #267CE1 100%) 0% 0% no-repeat padding-box"}}></div>
            </div>
            <div
                className={`w-full grid h-12 ${showDeleteBtn ? "grid-cols-5" : "grid-cols-4"} rounded-r border-[1px] border-l-0 border-[#E4E4E4]`}>

                <div className={'col-span-2 flex-left whitespace-nowrap truncate  pl-3'}>
                    <p>{file?.title || file?.fileKey}</p>
                </div>
                <div className={'center font-semibold text-secondary'}>
                    {file.fileSize}
                </div>
                {showDeleteBtn ?
                    (<div className={'center'}>
                        <button onClick={handleRemoval}>
                            <TrashIcon className={'h-5 w-5 text-[#FC635E]'}/>
                        </button>
                    </div>)
                    : null}
                <div className={`${showDeleteBtn ? "flex items-center" : "center"}`}>
                    <button onClick={handleDownload} className={'flex text-[#1A9EE2] items-center space-x-2'}>
                        {!isDownloading ? (
                            <Fragment>
                                <em>Download</em>
                                <DownloadAltIcon className={'h-3 w-3'}/>
                            </Fragment>
                        ) : (
                            <Fragment>
                                <SpinLoader size={"small"} color={"#1A9EE2"}/>
                                <em>Wait..</em>
                            </Fragment>
                        )}
                    </button>
                </div>
            </div>
        </div>
    )
}
export default BudgetFileCard;