import { PlusCircleIcon } from "@heroicons/react/outline";

const FormHeader = () => {
    return (
        <header
            className={
                "border-b-[1px] text-dark flex items-center space-x-2 pb-2 border-gray-200"
            }
        >
            <PlusCircleIcon className={"h-6 w-6"} />
            <p className={"text-2xl font-medium"}>Add New Plan</p>
        </header>
    );
};
export default FormHeader;
