import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

const FormHeader = ({ onClose: closeForm }) => {
    return (
        <header className="bg-gray-300 px-10 py-3 rounded-t-md flex justify-between">
            <p className="font-semibold text-[#002F4F]">Add New Department</p>

            <button
                onClick={closeForm}
                type={"button"}
                className="-mr-7 border-2 focus:ring-0 ring-0 border-[#192B40]/50 w-6 h-6 bg-[#628799] text-white rounded-full grid place-items-center"
            >
                <FontAwesomeIcon color="FFFFFF" icon={faXmark} />
            </button>
        </header>
    );
};
export default FormHeader;
