import { TrashIcon } from "@heroicons/react/solid";
import Button from "../../../components/Button";

const DepartmentCard = ({
	department,
	setActiveDepartment,
	setShowForm,
	onDelete: handleDelete,
}) => {
	// set active department and open department form
	const handleEditDepartment = () => {
		setActiveDepartment(department);
		setShowForm(true);
	};

	const getEstablishmentYear = () => {
		const yearOfEstablishment = parseInt(department.establishedOn ?? "0");
		const year = parseInt(new Date().getFullYear()) - yearOfEstablishment;
		switch (year) {
			case 0:
				return "Current year";
			case 1:
				return "One year ago";
			case 2:
				return "Two Years ago";
			case 3:
				return "Three years ago";
			case 4:
				return "Four years ago";
			case 5:
				return "Five years year";
			case 6:
				return "Six years ago";
			case 7:
				return "Seven years ago";
			case 8:
				return "Eight years ago";
			case 9:
				return "Nine years ago";
			case 10:
				return "Ten years ago";
			default:
				return `${year} years ago`;
		}
	};

	return (
		<div
			className={
				"border-[1px] bg-[#F9F9F9] border-[#E4E4E4] rounded-[8px] overflow-hidden"
			}
		>
			<div
				className={
					"bg-white p-8 space-y-2 border-b-[1px] border-b-[#E4E4E4]"
				}
			>
				<h2 className={"text-xl tracking-wide text-dark3"}>
					{department.name}
				</h2>

				<div className={"flex space-x-6"}>
					<p className={"w-full font-light text-[#838EAB] text-sm"}>
						{department.description}
					</p>

					<div className={" w-1/3"}>
						<div className={"items-between "}>
							<div className={"h-14 w-[2px] bg-[#E8E8E8]"}></div>
							<div className={"center space-y-3 flex-col"}>
								<Button
									onClick={handleEditDepartment}
									className={
										"border-[2px] font-semibold text-secondary border-secondary border-[#638798] py-3"
									}
									outline={true}
								>
									EDIT DEPARTMENT
								</Button>
								<button
									onClick={() =>
										handleDelete(department?._id)
									}
									className={"flex items-center space-x-1"}
								>
									<TrashIcon
										className={"h-5 text-secondary w-5"}
									/>
									<span
										className={
											"text-[#A3A6B4] font-light text-[15px]"
										}
									>
										Delete
									</span>
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className={
					"px-4 py-3.5 flex space-x-7 justify-end divide-x-[1px] divide-[#DCE1EA]"
				}
			>
				<div className={"text-end px-5"}>
					<p className={"text-xs text-[#A6B1C2]"}>
						Head of Department
					</p>
					<h3 className={"text-dark3 text-[15px] font-medium"}>
						{department.headOfDepartment}
					</h3>
				</div>

				<div className={"text-end px-5"}>
					<p className={"text-xs text-[#A6B1C2]"}>Established:</p>
					<h3 className={"text-dark3 text-[15px] font-medium"}>
						{getEstablishmentYear()}
					</h3>
				</div>
			</div>
		</div>
	);
};
export default DepartmentCard;
