import React, {useEffect, useRef, useState} from 'react';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import ReactDOMServer from "react-dom/server"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLocationDot} from "@fortawesome/free-solid-svg-icons";
import {useNavigate} from "react-router-dom";


mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;


const StakeholderLocations = ({stakeholders: stakeholderList = []}) => {

    const [stakeholders, setStakeholders] = useState([]);
    const navigate = useNavigate();
    const getStakeholderWithValidCoordinates = () => {
        return stakeholderList.filter((stakeholders) => {
            return stakeholders.location.longitude && stakeholders.location.latitude
        })
    }

    useEffect(() => {
        if (stakeholderList.length) {
            setStakeholders([...getStakeholderWithValidCoordinates()]);
        }
    }, [stakeholderList]);


    const mapContainer = useRef(null);
    const map = useRef(null);

    const mapConfig = {
        longitude: 34.888821,
        latitude: -6.369028,
        zoom: 5,
        defaultMaxBounds: [
            27.583, -13.33,
            44.450, 0.500
        ]
    }


    useEffect(() => {
        if (map.current) {
            return
        }

        // initialize a map only once
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/mapbox/streets-v11',
            center: [
                mapConfig.longitude,
                mapConfig.latitude
            ],
            maxBounds: mapConfig.defaultMaxBounds, // show only a tanzania map
            zoom: mapConfig.zoom
        });

        // map.current.scrollZoom.disable();
        // map.current.addControl(new mapboxgl.FullscreenControl());


    }, []);

    const createPopup = (stakeholder) => {
        const {location, address} = stakeholder;
        return ReactDOMServer.renderToStaticMarkup(
            <div className={' space-y-2'}>
                <p className={'text-lg text-dark font-medium tracking-wider'}>{stakeholder.name}</p>
                <div className={'flex space-x-2 text-dark'}>
                    <FontAwesomeIcon icon={faLocationDot} className={'h-4 w-4 mt-1.5'}/>
                    <p className={'text-base'}>{address?.region}, {address?.district}, {address.street}</p>
                </div>
            </div>
        );
    }

    useEffect(() => {
        if (map.current && stakeholders.length) {
            stakeholders.forEach((stakeholder) => {
                const {location = {}} = stakeholder;

                const popup = new mapboxgl.Popup({offset: 25})
                    .setHTML(createPopup(stakeholder))

                new mapboxgl.Marker()
                    .setLngLat([location?.longitude, location?.latitude])
                    .setPopup(popup) // sets a popup on this marker
                    .addTo(map.current);
            })
        }
    }, [stakeholders])

    return (<div ref={mapContainer} className="h-full"/>);
}

export default StakeholderLocations;