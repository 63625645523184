const StartOutlineIcon = ({className}) =>{
    return(
        <svg className={className ? className : 'h-6 w-6'} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 48">
            <g id="Polygon_7" data-name="Polygon 7" fill="none">
                <path d="M25,0l6.5,17.5,18.5.831L35.517,29.983,40.451,48,25,37.7,9.549,48l4.934-18.017L0,18.334,18.5,17.5Z" stroke="none"/>
                <path d="M 25.00000190734863 11.48990249633789 L 21.32714080810547 21.38015174865723 L 10.75931930541992 21.85497856140137 L 19.0334300994873 28.51000213623047 L 16.233642578125 38.73440933227539 L 25 32.88833236694336 L 33.766357421875 38.73440933227539 L 30.9665699005127 28.51000213623047 L 39.24068450927734 21.85497283935547 L 28.67285919189453 21.38014221191406 L 25.00000190734863 11.48990249633789 M 25 3.814697265625e-06 L 31.5 17.50313186645508 L 50 18.33437156677246 L 35.51721954345703 29.98313140869141 L 40.45085144042969 48 L 25 37.69620132446289 L 9.549148559570312 48 L 14.48278045654297 29.98313140869141 L 0 18.33437156677246 L 18.5 17.50314140319824 L 25 3.814697265625e-06 Z" stroke="none" fill="#fecb2f"/>
            </g>
        </svg>

    )
}
export default StartOutlineIcon;