const EventIllustrator = ({ className }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            viewBox="0 0 134.317 102.98"
        >
            <g id="undraw_events_re_98ue" transform="translate(0 0)">
                <path
                    id="Path_27"
                    data-name="Path 27"
                    d="M638.4,621.92c-.131.112-.266.221-.4.328H621.419c-.1-.108-.19-.216-.284-.328a18.33,18.33,0,0,1-2.012-2.957,18.77,18.77,0,0,1-1.83-8.938c0-.2,0-.4.005-.6.015-.918.066-1.838.142-2.742.014-.157.028-.313.043-.469.123-1.3.3-2.56.491-3.741.025-.15.05-.3.076-.447a61.361,61.361,0,0,1,1.6-6.945l.065.017c.123.034.416.116.848.244,1.188.353,3.421,1.064,5.995,2.122.141.057.283.116.426.177a47.482,47.482,0,0,1,6.306,3.167c.125.076.25.153.374.23v.011l-.272,8.792,2.427-7.32q.742.565,1.419,1.171c.124.11.248.223.368.341a13.585,13.585,0,0,1,2.592,3.233C643.831,613.811,642.307,618.622,638.4,621.92Z"
                    transform="translate(-547.237 -519.27)"
                    fill="#f2f2f2"
                />
                <path
                    id="Path_28"
                    data-name="Path 28"
                    d="M646.562,619.2h-.441c0-.109-.007-.218-.014-.328-.178-3.308-1.6-6.96-3.528-10.485-.069-.128-.139-.259-.214-.387-.938-1.677-1.983-3.317-3.062-4.877-.083-.121-.168-.244-.254-.365-1.437-2.05-2.92-3.947-4.26-5.567-.094-.116-.19-.229-.285-.341-1.987-2.382-3.626-4.118-4.284-4.8l-.226-.232c-.048-.052-.077-.08-.083-.085l.154-.154v0l.157-.154c.005.007.1.1.269.273.142.145.336.347.575.6.833.882,2.21,2.384,3.8,4.288l.283.339c1.088,1.314,2.267,2.8,3.435,4.4q.44.6.855,1.2c.087.121.172.243.255.364q1.752,2.527,3.06,4.862l.218.39c2.223,4.053,3.419,7.644,3.574,10.734C646.555,618.985,646.559,619.094,646.562,619.2Z"
                    transform="translate(-557.738 -516.224)"
                    fill="#fff"
                />
                <path
                    id="Path_29"
                    data-name="Path 29"
                    d="M629.364,607.369c-.079.109-.159.216-.243.323a11.774,11.774,0,0,1-2.191,2.166c-.12.088-.243.179-.368.268a12.1,12.1,0,0,1-1.062.662,11.874,11.874,0,0,1-4.889,1.467c-.055.005-.112.01-.167.012-.025.148-.048.3-.074.445.055,0,.11-.005.165-.011a12.289,12.289,0,0,0,5.18-1.526,12.614,12.614,0,0,0,1.132-.709c.124-.087.245-.178.365-.269a12.281,12.281,0,0,0,2.334-2.329c.08-.106.159-.214.236-.321Z"
                    transform="translate(-549.797 -529.497)"
                    fill="#fff"
                />
                <path
                    id="Path_30"
                    data-name="Path 30"
                    d="M633.588,627.378c-.048.1-.1.208-.15.312a11.875,11.875,0,0,1-4.351,4.764c-.13.081-.261.16-.394.234l-.123.07a11.949,11.949,0,0,1-10.94.338l-.121-.058c-.015.157-.029.314-.04.473.039.018.079.037.119.054a12.386,12.386,0,0,0,11.2-.419c.055-.03.109-.061.163-.092.134-.077.266-.156.4-.236a12.3,12.3,0,0,0,4.47-4.886c.054-.1.1-.208.154-.314C633.84,627.537,633.714,627.457,633.588,627.378Z"
                    transform="translate(-547.381 -546.152)"
                    fill="#fff"
                />
                <path
                    id="Path_31"
                    data-name="Path 31"
                    d="M636.881,644.575c-.023.1-.047.21-.073.313a11.916,11.916,0,0,1-4.984,7.1c-.128.087-.261.17-.393.251s-.284.171-.43.251a11.98,11.98,0,0,1-14.134-1.874c-.032-.029-.062-.059-.092-.09,0,.205,0,.408,0,.612.028.026.056.052.085.077a12.42,12.42,0,0,0,14.354,1.661c.146-.08.29-.163.429-.251s.266-.163.4-.248a12.374,12.374,0,0,0,5.135-7.154c.026-.1.052-.2.076-.3C637.132,644.808,637.006,644.694,636.881,644.575Z"
                    transform="translate(-546.804 -560.466)"
                    fill="#fff"
                />
                <path
                    id="Path_32"
                    data-name="Path 32"
                    d="M277.919,218.171H199.4V159.263h78.517Z"
                    transform="translate(-199.401 -156.514)"
                    fill="#fff"
                />
                <circle
                    id="Ellipse_5"
                    data-name="Ellipse 5"
                    cx="3.125"
                    cy="3.125"
                    r="3.125"
                    transform="translate(15.901 8.793)"
                    fill="#e6e6e6"
                />
                <path
                    id="Path_33"
                    data-name="Path 33"
                    d="M353.363,201.529a.521.521,0,1,0,0,1.042h38.032a.521.521,0,1,0,0-1.042Z"
                    transform="translate(-327.118 -191.694)"
                    fill="#e6e6e6"
                />
                <path
                    id="Path_34"
                    data-name="Path 34"
                    d="M353.357,220.172a.521.521,0,1,0,0,1.042H377.4a.521.521,0,1,0,0-1.042Z"
                    transform="translate(-327.113 -207.211)"
                    fill="#e6e6e6"
                />
                <path
                    id="Path_35"
                    data-name="Path 35"
                    d="M277.919,218.171H199.4V159.263h78.517Zm-77.639-.879h76.76V160.142H200.28Z"
                    transform="translate(-199.401 -156.514)"
                    fill="#e6e6e6"
                />
                <path
                    id="Path_36"
                    data-name="Path 36"
                    d="M225.579,266.422V301.46h69.74V266.422Zm69.4,8.676H277.968v-8.34h17.016Zm-34.367,0v-8.34h17.016v8.34Zm17.016.335v8.34H260.617v-8.34Zm-17.351-.335H243.266v-8.34h17.016Zm0,.335v8.34H243.266v-8.34Zm-17.351,8.34H225.914v-8.34H242.93Zm0,.335v8.34H225.914v-8.34Zm.335,0h17.016v8.34H243.266Zm17.016,8.676v8.34H243.266v-8.34Zm.335,0h17.016v8.34H260.617Zm0-.335v-8.34h17.016v8.34Zm17.351-8.34h17.016v8.34H277.968Zm0-.335v-8.34h17.016v8.34ZM242.93,266.757v8.34H225.914v-8.34Zm-17.016,26.027H242.93v8.34H225.914Zm52.054,8.34v-8.34h17.016v8.34Z"
                    transform="translate(-221.191 -245.708)"
                    fill="#e4e4e4"
                />
                <path
                    id="Path_37"
                    data-name="Path 37"
                    d="M359.917,284.1l-6.939-.157a15.6,15.6,0,0,1,.172-7.608l6.939.157A15.193,15.193,0,0,0,359.917,284.1Z"
                    transform="translate(-326.9 -253.955)"
                    fill="#6c63ff"
                />
                <path
                    id="Path_38"
                    data-name="Path 38"
                    d="M163.676,142.185l0-.264,3.6.128,0,.264Z"
                    transform="translate(-136.237 -118.129)"
                    fill="#fff"
                />
                <path
                    id="Path_39"
                    data-name="Path 39"
                    d="M163.586,148.485l0-.264,3.595.129,0,.264Z"
                    transform="translate(-136.162 -123.372)"
                    fill="#fff"
                />
                <path
                    id="Path_40"
                    data-name="Path 40"
                    d="M163.495,154.785l0-.264,3.595.129,0,.264Z"
                    transform="translate(-136.086 -128.616)"
                    fill="#fff"
                />
                <path
                    id="Path_41"
                    data-name="Path 41"
                    d="M163.4,161.085l0-.264,3.6.129,0,.264Z"
                    transform="translate(-136.011 -133.86)"
                    fill="#fff"
                />
                <path
                    id="Path_42"
                    data-name="Path 42"
                    d="M163.315,167.385l0-.264,3.6.129,0,.264Z"
                    transform="translate(-135.936 -139.104)"
                    fill="#fff"
                />
                <path
                    id="Path_43"
                    data-name="Path 43"
                    d="M269.917,400.1l-6.939-.157a15.6,15.6,0,0,1,.172-7.608l6.939.157a15.193,15.193,0,0,0-.172,7.608Z"
                    transform="translate(-251.988 -350.508)"
                    fill="#f0f0f0"
                />
                <path
                    id="Path_44"
                    data-name="Path 44"
                    d="M73.676,258.185l0-.264,3.6.128,0,.264Z"
                    transform="translate(-61.325 -214.682)"
                    fill="#fff"
                />
                <path
                    id="Path_45"
                    data-name="Path 45"
                    d="M73.586,264.485l0-.264,3.595.129,0,.264Z"
                    transform="translate(-61.25 -219.926)"
                    fill="#fff"
                />
                <path
                    id="Path_46"
                    data-name="Path 46"
                    d="M73.5,270.785l0-.264,3.595.129,0,.264Z"
                    transform="translate(-61.174 -225.17)"
                    fill="#fff"
                />
                <path
                    id="Path_47"
                    data-name="Path 47"
                    d="M73.4,277.085l0-.264,3.6.129,0,.264Z"
                    transform="translate(-61.099 -230.413)"
                    fill="#fff"
                />
                <path
                    id="Path_48"
                    data-name="Path 48"
                    d="M73.315,283.385l0-.264,3.6.129,0,.264Z"
                    transform="translate(-61.024 -235.657)"
                    fill="#fff"
                />
                <path
                    id="Path_49"
                    data-name="Path 49"
                    d="M485.917,347.1l-6.939-.157a15.6,15.6,0,0,1,.172-7.608l6.939.157A15.194,15.194,0,0,0,485.917,347.1Z"
                    transform="translate(-431.776 -306.393)"
                    fill="#f0f0f0"
                />
                <path
                    id="Path_50"
                    data-name="Path 50"
                    d="M289.676,205.185l0-.264,3.6.128,0,.264Z"
                    transform="translate(-241.113 -170.567)"
                    fill="#fff"
                />
                <path
                    id="Path_51"
                    data-name="Path 51"
                    d="M289.586,211.485l0-.264,3.595.129,0,.264Z"
                    transform="translate(-241.038 -175.811)"
                    fill="#fff"
                />
                <path
                    id="Path_52"
                    data-name="Path 52"
                    d="M289.495,217.785l0-.264,3.6.129,0,.264Z"
                    transform="translate(-240.963 -181.055)"
                    fill="#fff"
                />
                <path
                    id="Path_53"
                    data-name="Path 53"
                    d="M289.4,224.085l0-.264,3.6.129,0,.264Z"
                    transform="translate(-240.888 -186.299)"
                    fill="#fff"
                />
                <path
                    id="Path_54"
                    data-name="Path 54"
                    d="M289.315,230.385l0-.264,3.6.129,0,.264Z"
                    transform="translate(-240.813 -191.542)"
                    fill="#fff"
                />
                <path
                    id="Path_55"
                    data-name="Path 55"
                    d="M467.917,337.1l-6.939-.157a15.6,15.6,0,0,1,.172-7.608l6.939.157A15.193,15.193,0,0,0,467.917,337.1Z"
                    transform="translate(-416.794 -298.07)"
                    fill="#6c63ff"
                />
                <path
                    id="Path_56"
                    data-name="Path 56"
                    d="M271.676,195.185l0-.264,3.6.128,0,.264Z"
                    transform="translate(-226.131 -162.243)"
                    fill="#fff"
                />
                <path
                    id="Path_57"
                    data-name="Path 57"
                    d="M271.586,201.485l0-.264,3.595.129,0,.264Z"
                    transform="translate(-226.056 -167.487)"
                    fill="#fff"
                />
                <path
                    id="Path_58"
                    data-name="Path 58"
                    d="M271.495,207.785l0-.264,3.595.129,0,.264Z"
                    transform="translate(-225.98 -172.731)"
                    fill="#fff"
                />
                <path
                    id="Path_59"
                    data-name="Path 59"
                    d="M271.4,214.085l0-.264,3.6.129,0,.264Z"
                    transform="translate(-225.905 -177.975)"
                    fill="#fff"
                />
                <path
                    id="Path_60"
                    data-name="Path 60"
                    d="M271.315,220.385l0-.264,3.6.129,0,.264Z"
                    transform="translate(-225.83 -183.219)"
                    fill="#fff"
                />
                <path
                    id="Path_61"
                    data-name="Path 61"
                    d="M620.583,428.445l-6.939-.157a15.6,15.6,0,0,1,.172-7.608l6.939.157a15.193,15.193,0,0,0-.172,7.608Z"
                    transform="translate(-543.866 -374.105)"
                    fill="#6c63ff"
                />
                <path
                    id="Path_62"
                    data-name="Path 62"
                    d="M424.342,286.534l0-.264,3.595.129,0,.264Z"
                    transform="translate(-353.203 -238.278)"
                    fill="#fff"
                />
                <path
                    id="Path_63"
                    data-name="Path 63"
                    d="M424.252,292.834l0-.264,3.6.129,0,.264Z"
                    transform="translate(-353.128 -243.522)"
                    fill="#fff"
                />
                <path
                    id="Path_64"
                    data-name="Path 64"
                    d="M424.161,299.135l0-.264,3.595.128,0,.264Z"
                    transform="translate(-353.053 -248.767)"
                    fill="#fff"
                />
                <path
                    id="Path_65"
                    data-name="Path 65"
                    d="M424.071,305.435l0-.264,3.595.128,0,.264Z"
                    transform="translate(-352.978 -254.011)"
                    fill="#fff"
                />
                <path
                    id="Path_66"
                    data-name="Path 66"
                    d="M423.981,311.735l0-.264,3.6.129,0,.264Z"
                    transform="translate(-352.903 -259.254)"
                    fill="#fff"
                />
                <path
                    id="Path_67"
                    data-name="Path 67"
                    d="M575.917,292.1l-6.939-.157a15.6,15.6,0,0,1,.172-7.608l6.939.157A15.194,15.194,0,0,0,575.917,292.1Z"
                    transform="translate(-506.688 -260.614)"
                    fill="#f0f0f0"
                />
                <path
                    id="Path_68"
                    data-name="Path 68"
                    d="M379.676,150.185l0-.264,3.6.128,0,.264Z"
                    transform="translate(-316.025 -124.787)"
                    fill="#fff"
                />
                <path
                    id="Path_69"
                    data-name="Path 69"
                    d="M379.586,156.485l0-.264,3.595.129,0,.264Z"
                    transform="translate(-315.95 -130.031)"
                    fill="#fff"
                />
                <path
                    id="Path_70"
                    data-name="Path 70"
                    d="M379.495,162.785l0-.264,3.595.129,0,.264Z"
                    transform="translate(-315.875 -135.275)"
                    fill="#fff"
                />
                <path
                    id="Path_71"
                    data-name="Path 71"
                    d="M379.4,169.085l0-.264,3.6.129,0,.264Z"
                    transform="translate(-315.8 -140.519)"
                    fill="#fff"
                />
                <path
                    id="Path_72"
                    data-name="Path 72"
                    d="M379.315,175.385l0-.264,3.6.129,0,.264Z"
                    transform="translate(-315.725 -145.763)"
                    fill="#fff"
                />
                <path
                    id="Path_73"
                    data-name="Path 73"
                    d="M644.3,398.1a1.686,1.686,0,0,0,1.138-2.321l4.375-4.092-3.027-.727-3.691,4a1.695,1.695,0,0,0,1.2,3.143Z"
                    transform="translate(-567.961 -349.367)"
                    fill="#ffb6b6"
                />
                <path
                    id="Path_74"
                    data-name="Path 74"
                    d="M658.136,280.082,656.1,277.2l7.664-7.927,1.78-12.5a4.25,4.25,0,0,1,5.565-3.428h0a4.255,4.255,0,0,1,2.67,5.385l-4.541,13.472Z"
                    transform="translate(-579.533 -234.645)"
                    fill="#e4e4e4"
                />
                <path
                    id="Path_75"
                    data-name="Path 75"
                    d="M538.111,545.236h-2.265l-1.078-8.737h3.343Z"
                    transform="translate(-445.117 -446.558)"
                    fill="#ffb6b6"
                />
                <path
                    id="Path_76"
                    data-name="Path 76"
                    d="M722.4,729.172h-1.607l-.287-1.518-.735,1.518h-4.263a.958.958,0,0,1-.545-1.747l3.4-2.351V723.54l3.581.214Z"
                    transform="translate(-628.188 -626.193)"
                    fill="#2f2e41"
                />
                <path
                    id="Path_77"
                    data-name="Path 77"
                    d="M602.755,536.763l-1.99,1.083-5.123-7.159,2.937-1.6Z"
                    transform="translate(-495.787 -440.389)"
                    fill="#ffb6b6"
                />
                <path
                    id="Path_78"
                    data-name="Path 78"
                    d="M819.028,714.233l-1.412.768-.977-1.2.08,1.684-3.745,2.038a.958.958,0,0,1-1.313-1.274l1.867-3.692-.733-1.348,3.247-1.524Z"
                    transform="translate(-708.933 -614.664)"
                    fill="#2f2e41"
                />
                <path
                    id="Path_79"
                    data-name="Path 79"
                    d="M711.669,441.25h-4.681v-16.67l-2.9-10.868a62.215,62.215,0,0,1-1.628-23.856l0-.033.025-.021c.074-.062,7.492-6.2,12.184-1.5,3.162,3.163.191,14.534.025,15.158v19.6Z"
                    transform="translate(-617.721 -345.683)"
                    fill="#2f2e41"
                />
                <path
                    id="Path_80"
                    data-name="Path 80"
                    d="M696.378,274.179l1.509-15.088c-.2-.411-3.018-3.018-.067-7.6l3.187-7.48,5.968-.093.025.025a12.021,12.021,0,0,1,3.294,10.879l-1.758,8.793,1.524,6.095Z"
                    transform="translate(-613.063 -226.977)"
                    fill="#e4e4e4"
                />
                <path
                    id="Path_81"
                    data-name="Path 81"
                    d="M543.742,443.329l9.617,13.527,3.689-2.623-8.032-16.5Z"
                    transform="translate(-452.587 -364.345)"
                    fill="#2f2e41"
                />
                <circle
                    id="Ellipse_6"
                    data-name="Ellipse 6"
                    cx="5.51"
                    cy="5.51"
                    r="5.51"
                    transform="translate(84.859 4.493)"
                    fill="#ffb6b6"
                />
                <path
                    id="Path_82"
                    data-name="Path 82"
                    d="M515.995,178.558l1.509,9.053-1.509,7.544h-3.018l1.372-7.544Z"
                    transform="translate(-426.979 -148.624)"
                    opacity="0.1"
                />
                <path
                    id="Path_83"
                    data-name="Path 83"
                    d="M724.887,425.808a1.686,1.686,0,0,0-.089-2.583l1.933-5.67-3.013.783-1.374,5.264a1.695,1.695,0,0,0,2.543,2.206Z"
                    transform="translate(-634.432 -371.504)"
                    fill="#ffb6b6"
                />
                <path
                    id="Path_84"
                    data-name="Path 84"
                    d="M718.293,295.414l-3.155-1.577,3.029-10.6-4.313-11.862a4.25,4.25,0,0,1,3.3-5.645h0a4.255,4.255,0,0,1,4.891,3.494l2.337,14.023Z"
                    transform="translate(-627.395 -245.082)"
                    fill="#e4e4e4"
                />
                <path
                    id="Path_85"
                    data-name="Path 85"
                    d="M459.842,754.951a.2.2,0,0,1-.2.2H351.128a.2.2,0,1,1,0-.4H459.643A.2.2,0,0,1,459.842,754.951Z"
                    transform="translate(-325.526 -652.172)"
                    fill="#cacaca"
                />
                <path
                    id="Path_86"
                    data-name="Path 86"
                    d="M729.026,152.6a3.428,3.428,0,0,1-3.474,1.4,5.622,5.622,0,0,1-3.279-2.1,16.624,16.624,0,0,1-1.989-3.432,16.908,16.908,0,0,0-1.982-3.436,5.653,5.653,0,0,0-3.267-2.116,2.321,2.321,0,0,0-2.032.458,1.979,1.979,0,0,0-.543,1.4,1.459,1.459,0,0,0-1.942,1.448l-.058,0c-2.43-1.382-6.119-.579-6.119.93a3.249,3.249,0,0,0-2.963,3.317h0c2.758,0,4.38,3.533,2.771,6.066a17.318,17.318,0,0,1-1.371,1.849h3.448a2.873,2.873,0,0,0,.506-1.4,6.455,6.455,0,0,1,.226,1.4s9.419,1.214,6.988-8.175a.984.984,0,0,0,.456-.138,1.466,1.466,0,0,0,.6-1.164l.272.073a3.485,3.485,0,0,1,1.766.9c.752.85.594,2.139.867,3.24a6.064,6.064,0,0,0,2.408,3.224,8.079,8.079,0,0,0,4.012,1.692,4.953,4.953,0,0,0,4.034-1.37A3.6,3.6,0,0,0,729.026,152.6Z"
                    transform="translate(-617.225 -142.864)"
                    fill="#2f2e41"
                />
            </g>
        </svg>
    );
};
export default EventIllustrator;
