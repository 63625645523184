import {faXmark} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Button from "components/Button";
import Input from "components/form-control/Input";
import Modal from "components/Modal";
import React, {useState} from "react";
import {ArrowRightIcon} from "svg";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {ProjectFormSchema} from "forms/validation";
import Stepper from "../../../general/grievances/form/Stepper";
import ProjectPiuSetupFrom from "./project-piu-setup-from";
import {saveValueToLocalStorage} from "hooks/useLocalStorageState";

const CreateProject = ({showForm, setShowForm, activeWorkspace}) => {
    const {
        handleSubmit,
        register,
        formState: {errors},
    } = useForm({
        resolver: yupResolver(ProjectFormSchema),
    });

    const [currStep, setCurrStep] = useState(1);

    const handleClose = () => {
        setShowForm(false);
    };
    const inputClass = "text-sm";
    const labelClass = "block mb-2 text-sm font-medium text-[#002F4F]";

    const onSubmit = (formData) => {
        saveValueToLocalStorage('projectBasicDetails', formData);
        setCurrStep(2)
    };


    return (
        <Modal isOpen={showForm} onClose={setShowForm}>
            <div className="w-full bg-white max-w-xl rounded-md">
                <header className="flex justify-between rounded-t-md px-5 py-3 bg-gray-300">
                    <p className="font-semibold text-[#002F4F]">
                        Create New Project
                    </p>

                    <button
                        onClick={handleClose}
                        className="-mr-1 border-2 focus:ring-0 ring-0 border-[#192B40]/50 w-6 h-6 bg-[#628799] text-white rounded-full grid place-items-center hover:opacity-90"
                    >
                        <FontAwesomeIcon color="FFFFFF" icon={faXmark}/>
                    </button>
                </header>

                <Stepper currentStep={currStep} steps={2}/>

                {currStep === 1 && (
                    <form
                        onSubmit={handleSubmit(onSubmit)}
                        action=""
                        className="grid px-8 py-5  gap-x-4 gap-y-3 grid-col-2"
                    >
                        <div className="col-span-2 space-y-2">
                            <label className={labelClass}>Project Name</label>
                            <Input
                                isError={!!errors?.name?.message}
                                register={register}
                                name={"name"}
                                className={inputClass}
                            />
                        </div>

                        <div className="col-span-2 space-y-2">
                            <label className={labelClass}>Abbreviation </label>
                            <Input
                                isError={!!errors?.abbreviation?.message}
                                register={register}
                                name={"abbreviation"}
                                className={inputClass}
                            />
                        </div>

                        <div className="col-span-2 space-y-2">
                            <label className={labelClass}>Project Budget</label>
                            <Input
                                isError={!!errors?.budget?.message}
                                register={register}
                                name={"budget"}
                                className={inputClass}
                            />
                        </div>

                        <div className="space-y-2">
                            <label className={labelClass}>Start Date</label>
                            <Input
                                isError={!!errors?.startDate?.message}
                                register={register}
                                name={"startDate"}
                                type="date"
                                className={inputClass}
                            />
                        </div>

                        <div className="space-y-2">
                            <label className={labelClass}>End Date</label>
                            <Input
                                isError={!!errors?.endDate?.message}
                                register={register}
                                name={"endDate"}
                                type="date"
                                className={inputClass}
                            />
                        </div>

                        <div className="space-y-2">
                            <label className={labelClass}>Country</label>
                            <Input
                                register={register}
                                isError={!!errors?.country?.message}
                                name={"country"}
                                className={inputClass}
                            />
                        </div>
                        <div className="space-y-2">
                            <label className={labelClass}>Continent</label>
                            <Input
                                register={register}
                                isError={!!errors?.continent?.message}
                                name={"continent"}
                                className={inputClass}
                            />
                        </div>

                        {/*  submit button */}
                        <div className={"col-span-2"}>
                            <Button
                                block={true}
                                type="submit"
                                className={
                                    "flex items-center space-x-2 text-sm"
                                }
                            >
                                <span>NEXT STEP</span>
                                <ArrowRightIcon className={"h-5 w-5"}/>
                            </Button>
                        </div>
                    </form>
                )}

                {currStep === 2 && (
                    <ProjectPiuSetupFrom
                        activeWorkspace={activeWorkspace}
                        setCurrStep={setCurrStep}
                        setShowForm={setShowForm}
                    />
                )}
            </div>
        </Modal>
    );
};

export default CreateProject;
