import React from "react";
import { CommitmentLoading } from "components/skeleton";

const Loading = () => {
	return (
		<div className={"grid grid-cols-3 gap-4"}>
			{[...Array(3)].map((item) => (
				<CommitmentLoading />
			))}
		</div>
	);
};

export default Loading;
