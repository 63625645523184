import { ArrowLeftIcon } from "@heroicons/react/outline";
import { toast, ToastContainer } from "react-toastify";
import { Link, Outlet, useParams } from "react-router-dom";
import { Fragment, useState } from "react";

import { TabMenu } from "../../../../components/TabMenu";
import {
    getValueFromLocalStorage,
    removeValuesFromLocalStorage,
} from "../../../../hooks/useLocalStorageState";
import { localStorageKeys } from "../../../../constants";

export const clearForm = () => {
    const { piuForm: piuLSKeys } = localStorageKeys;
    removeValuesFromLocalStorage([
        piuLSKeys.BASIC_DETAILS,
        piuLSKeys.MISSION_AND_VISION,
        piuLSKeys.CURRENT_STEP,
        piuLSKeys.CONTACT_PERSON,
        piuLSKeys.IS_UPDATING_DETAILS,
    ]);
};
const PIURegistrationFormLayout = () => {
    const { piuRegName: isUpdating } = useParams();
    // if (!isUpdating) clearForm();

    const _currStep = getValueFromLocalStorage("piuCurrStep", 1);
    const [currStep, setCurrStep] = useState(_currStep ?? 1);
    const formTabs = [
        { number: 1, name: "BASIC DETAILS" },
        { number: 2, name: "MISSION & VISION" },
        { number: 3, name: "CONTACT PERSON" },
        // {number: 4, name: "BRANCHES"},
    ];

    return (
        <Fragment>
            <ToastContainer />
            <section className={"h-screen flex w-full overflow-hidden"}>
                <div
                    className={
                        "h-full w-[390px] shrink-0 shadow bg-white px-16 py-12 space-y-6"
                    }
                >
                    <Link
                        onClick={clearForm}
                        to={"/settings/pius"}
                        className={
                            "h-10 w-10 rounded-full flex justify-center  items-center hover:bg-primary/10"
                        }
                    >
                        <ArrowLeftIcon
                            className={
                                "h-6 hover:text-primary/60 text-[#AAB6C5] stroke-[1.5px]"
                            }
                        />
                    </Link>

                    <div>
                        <h1
                            className={
                                "text-secondary leading-snug font-medium tracking-wide text-3xl"
                            }
                        >
                            {isUpdating ? (
                                <span>
                                    Update <br />
                                    PIU Details
                                </span>
                            ) : (
                                <span>
                                    Register <br /> New PIU
                                </span>
                            )}
                        </h1>
                    </div>

                    {/* Tab Menus */}
                    <div className={"pt-5 space-y-10"}>
                        {formTabs.map((tab) => (
                            <TabMenu
                                onClick={() => {
                                    // ....
                                }}
                                key={tab.number}
                                tabName={tab.name}
                                tabNumber={tab.number}
                                isActive={currStep === tab.number}
                                isFinished={currStep > tab.number}
                            />
                        ))}
                    </div>
                </div>
                <div
                    className={
                        "w-[calc(100%_-_390px)] bg-[#F6F7F9] overflow-y-auto pt-20"
                    }
                >
                    <Outlet
                        context={{ currStep, setCurrStep, toast, isUpdating }}
                    />
                </div>
            </section>
        </Fragment>
    );
};
export default PIURegistrationFormLayout;
