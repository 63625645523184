import useDeleteFromS3Bucket from "hooks/useDeleteFromS3Bucket";
import { useEffect, useState } from "react";
import { getFileSize, getFileType, isEmptyObject } from "utils";
import useUploadFileToS3 from "hooks/useUploadToS3Bucket";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faFile,
	faFileCsv,
	faFilePdf,
	faFileWord,
	faVolumeHigh,
	faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { RefreshIcon } from "@heroicons/react/outline";
import useClickOutside from "hooks/useClickOutside";

const SelectedFile = (
	{ file, uploadedFiles = [], setUploadedFiles, setFiles, files },
	...props
) => {
	const { deleteFromS3Bucket } = useDeleteFromS3Bucket();
	// const [fileTitle, setFileTitle] = useState(file.name.split(".")[0]);
	// const [fileDescription, setFileDescription] = useState("");
	// const [unity, size] = getFileSize(file.size);
	const { name: filename } = file;

	const isAlreadyUploaded = () => {
		return uploadedFiles.find((uploadedFile) => {
			return uploadedFile.name === filename;
		});
	};

	useEffect(() => {
		if (file && !isAlreadyUploaded()) {
			upload();
		}
	}, []);

	const [progress, setProgress] = useState(isAlreadyUploaded() ? 100 : 0);
	const {
		response = Object(),
		isUploading,
		upload,
	} = useUploadFileToS3(file, (progress) => {
		setProgress(progress);
	});

	const getFileExtension = () => {
		return file.name.split(".").pop();
	};

	const tempUrl = file.tempUrl ?? URL.createObjectURL(file);
	useEffect(() => {
		if (!isEmptyObject(response)) {
			const _uploadedFile = getUploadedFile(response, file);
			/* const {Location: fileUrl, Key} = response */
			// setUploadedFiles([...uploadedFiles, {
			//     filename,
			//     name: file.name,
			//     url: fileUrl,
			//     tempUrl,
			//     fileKey: Key,
			//     type: getFileType(getFileExtension()),
			//     extension: getFileExtension(),
			//     fileSize: `${size} ${unity}`,
			//     title: fileTitle,
			//     description: fileDescription
			// }])

			setUploadedFiles([...uploadedFiles, _uploadedFile]);
		}
	}, [response]);

	const removeFile = async () => {
		// remove from ui
		setFiles([
			...files.filter((selectedFile) => {
				return selectedFile.name !== filename;
			}),
		]);

		setUploadedFiles([
			...uploadedFiles.filter((file) => {
				return file.name !== filename;
			}),
		]);

		// remove from aws-bucket
		const file = uploadedFiles.find((uploadedFile) => {
			return uploadedFile.filename === filename;
		});
		await deleteFromS3Bucket(file.fileKey);
	};

	const inputClassName =
		"w-full rounded-lg tracking-wide  focus:ring-secondary/80 text-xs bg-secondary/10 border-none";
	const [addDescription, setAddDescription] = useState(false);
	const popoverRef = useClickOutside(() => {
		setAddDescription(false);
	});

	const imageExtensions = ["jpg", "jpeg", "png"];
	const audioExtensions = ["mp3"];

	const isAudioFile = () => {
		return audioExtensions.includes(getFileExtension());
	};

	const isImageFile = () => {
		return imageExtensions.includes(getFileExtension());
	};

	const popoverClassName = `
         absolute enable-transition ${
				addDescription
					? "opacity-100 scale-100 w-[200px] pointer-events-auto"
					: "opacity-0 scale-500 pointer-events-none w-[50px]"
			}  
         top-full space-y-1 rounded-xl right-0 p-2 bg-white 
         shadow-base  border
    `;
	const fileExtension = getFileExtension();

	const isDocumentFile = () => {
		return ["doc", "docx", "document"].includes(fileExtension);
	};

	const getFileIcon = () => {
		if (isImageFile()) {
			return (
				<div className={"w-10 rounded overflow-hidden h-7 m-3 center"}>
					<img
						src={tempUrl}
						alt={filename}
						className={"h-full w-full object-cover"}
					/>
				</div>
			);
		} else if (isAudioFile()) {
			return (
				<FontAwesomeIcon
					icon={faVolumeHigh}
					className={"h-7 w-7 p-3 text-secondary"}
				/>
			);
		} else if (isDocumentFile()) {
			return (
				<FontAwesomeIcon
					icon={faFileWord}
					className={"h-7 w-7 p-3 text-secondary"}
				/>
			);
		} else if (fileExtension === "pdf") {
			return (
				<FontAwesomeIcon
					icon={faFilePdf}
					className={"h-7 w-7 p-3 text-secondary"}
				/>
			);
		} else if (fileExtension === "csv") {
			return (
				<FontAwesomeIcon
					icon={faFileCsv}
					className={"h-7 w-7 p-3 text-secondary"}
				/>
			);
		} else
			return (
				<FontAwesomeIcon
					icon={faFile}
					className={"h-7 w-7 p-3 text-secondary"}
				/>
			);
	};

	return (
		<div
			{...props}
			className={
				"bg-white/50 shadow-base p-[2px] px-1 relative group relative flex items-center rounded-md"
			}
		>
			<div className={" flex items-center rounded-xl w-full "}>
				{getFileIcon()}
				<div className={"w-[90%] space-y-2 py-[1.5px]"}>
					<div className={"items-between"}>
						<p
							className={
								"tracking-wide text-sm w-48 whitespace-nowrap truncate cursor-pointer hover:underline"
							}
						>
							{filename || file.name || file.filename}
						</p>
						<div className={"text-sm relative text-gray-500"}>
							<button onClick={() => setAddDescription(true)}>
								Info
							</button>

							<div
								ref={popoverRef}
								className={`${popoverClassName} z-50 backdrop-blur`}
							>
								<input
									/* 	onChange={({ target }) =>
										setFileTitle(target.value)
									} */
									type="text"
									placeholder={"Title"}
									className={`${inputClassName}`}
								/>
								<textarea
									/* 	onChange={({ target }) =>
										setFileDescription(target.value)
									} */
									style={{ resize: "none" }}
									placeholder={"Description"}
									className={`${inputClassName} scrollbar-hide h-12`}
								></textarea>
							</div>
						</div>
					</div>
					<div className={"h-1 w-full bg-gray-200 rounded-full mr-2"}>
						<div
							className={"h-full bg-secondary/90 rounded-full"}
							style={{ width: `${progress}%` }}
						></div>
					</div>
				</div>

				<div className={" px-3 "}>
					{!isUploading ? (
						<div
							onClick={removeFile}
							className={
								" cursor-pointer h-5 hover:bg-gray-200 bg-gray-100 w-5 center rounded-full"
							}
						>
							<FontAwesomeIcon
								icon={faXmark}
								className={"h-3 w-3  text-gray-500"}
							/>
						</div>
					) : (
						<RefreshIcon
							className={"h-4 w-4 animate-spin text-gray-500"}
						/>
					)}
				</div>
			</div>
		</div>
	);
};
export default SelectedFile;

export const getUploadedFile = (response, file) => {
	// console.clear();
	// console.log(response)
	const tempUrl = file.tempUrl ?? URL.createObjectURL(file);

	const [unity, size] = getFileSize(file.size);
	const getFileExtension = () => {
		return file.name.split(".").pop();
	};

	const { Location: fileUrl, Key } = response;
	return {
		filename: Key,
		url: fileUrl,
		title: file.name,
		tempUrl,
		fileKey: Key,
		type: getFileType(getFileExtension()),
		extension: getFileExtension()[1],
		fileSize: `${size} ${unity}`,
	};
};
