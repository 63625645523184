import React, { useState } from "react";
import { TrashIcon } from "@heroicons/react/solid";
import { ChevronDownIcon } from "@heroicons/react/outline";

const AssignedProjectsSubprojects = ({
    assignments,
    setAssignments,
    assignment: { project, subprojects } = {},
}) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleProject = () => {
        setIsExpanded(!isExpanded);
    };
    const removeAssigment = (projectId, subprojectId) => {
        // check for a subprojectId; if so, delete the subproject associated with that id.
        if (subprojectId) {
            if (subprojects.length === 1) {
                // remove a project from the list
                setAssignments(
                    assignments.filter((assignment) => {
                        return assignment.project?._id !== projectId;
                    })
                );
                return;
            }

            setAssignments(
                assignments.map((_assignment) => {
                    if (_assignment.project._id === projectId) {
                        return {
                            ..._assignment,
                            subprojects: _assignment.subprojects.filter(
                                (subproject) => {
                                    return subproject?._id !== subprojectId;
                                }
                            ),
                        };
                    }
                    return _assignment;
                })
            );
            return;
        }

        // remove a project from the list
        setAssignments(
            assignments.filter((assignment) => {
                return assignment.project?._id !== projectId;
            })
        );
    };
    return (
        <div
            className={`rounded-[7px] border-[1px] ${
                isExpanded ? "space-y-5 bg-[#F6F9FE]" : "space-y-0 bg-white"
            } py-2 px-4 border-[#AAB6C5AA]`}
        >
            {/* project title */}
            <div className={"grid grid-cols-6 gap-4"}>
                <p className={"text-[#838EAB] col-span-4 truncate"}>
                    {project.name}
                </p>

                <button
                    onClick={() => removeAssigment(project._id)}
                    type={"button"}
                    className={
                        "flex text-[15px] justify-self-end items-center text-[#FC635E] space-x-1"
                    }
                >
                    <TrashIcon className={"h-4 w-4"} />
                    <span>Remove</span>
                </button>

                {/* button to open and close assigned project details */}
                <button
                    onClick={toggleProject}
                    className={`
                        ${
                            !subprojects.length
                                ? "text-gray-500/50 pointer-events-none"
                                : "text-[#019ee2] pointer-event-auto"
                        }
                        transition-all h-7 w-7 justify-self-end rounded-full hover:bg-[#F0F0F0] center ${
                            isExpanded ? "-rotate-180" : ""
                        }`}
                    type={"button"}
                >
                    <ChevronDownIcon className={"h-5 w-5"} />
                </button>
            </div>

            {/* subprojects */}
            <div className={`${isExpanded ? "block" : "hidden"} space-y-3`}>
                <p className={"text-[15.5px] text-[#253A53]"}>Subprojects:</p>
                <div>
                    <ul className={"grid grid-cols-2 gap-4"}>
                        {subprojects.map((subproject) => (
                            <li key={subproject?._id}>
                                <div className={"items-between"}>
                                    <div
                                        className={
                                            "flex items-center space-x-2"
                                        }
                                    >
                                        <div
                                            className={
                                                "h-3 w-3 rounded-full border-2 border-[#B1C6F2] shrink-0"
                                            }
                                        ></div>
                                        <p className={"text-[#3E608A] text-sm"}>
                                            {subproject?.name}
                                        </p>
                                    </div>
                                    <button
                                        onClick={() =>
                                            removeAssigment(
                                                project._id,
                                                subproject?._id
                                            )
                                        }
                                    >
                                        <TrashIcon
                                            className={"h-4 w-4 text-[#AAB6C5]"}
                                        />
                                    </button>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};
export default AssignedProjectsSubprojects;
