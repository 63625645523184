import {CreateMarkup} from "../../../../utils";
import {ArrowRightIcon} from "@heroicons/react/outline";
import dayjs from "dayjs";

const CommitmentCard = ({commitmentItem, onClick}, ...props) => {
    const formatDate = (date) => {
        return dayjs(date).format('DD MMMM YYYY');
    }

    console.clear()
    console.log(commitmentItem)
    return (
        <div {...props} className={'p-8 space-y-4 text-sm bg-white rounded-[8px] border-[1px] border-[#E4E4E4]'}>

            <p className={'font-medium'}>{commitmentItem?.stakeholder?.name}</p>

            <div className={'flex items-center space-x-16'}>
                <div className={'space-y-1'}>
                    <p className={'text-[#AAB6C5] text-xs'}>Create On:</p>
                    <p className={'text-[13px] text-dark'}>{formatDate(commitmentItem?.startDate)}</p>
                </div>

                <div className={'space-y-1'}>
                    <p className={'text-[#AAB6C5] text-xs'}>Should End On:</p>
                    <p className={'text-[13px] text-dark'}>{formatDate(commitmentItem?.endDate)}</p>
                </div>
            </div>

            {/* description */}
            <div className={'text-[13px]'}>
                <p className={'text-secondary'}>Descriptions:</p>
                <div className={''}>
                    <p
                        dangerouslySetInnerHTML={CreateMarkup(commitmentItem?.description)}
                        className={'text-[#AAB6C5] line-clamp-4 leading-relaxed'}>
                    </p>
                </div>
            </div>

            <div className={'items-between'}>

                <div className={'h-8 w-8 rounded-full text-white bg-secondary center'}>
                    <p className={'opacity-50 uppercase'}>BK</p>
                </div>


                <div onClick={() => onClick(commitmentItem)}
                     className={'flex italic text-accent text-[13px] space-x-2 cursor-pointer font-light items-center'}>
                    <p>View Commitment</p>
                    <ArrowRightIcon className={'h-4 w-4'}/>
                </div>

            </div>
        </div>
    )
}
export default CommitmentCard;