import Input from "components/form-control/Input";
import {ArrowRightIcon} from "svg";
import Select from "components/form-control/Select";
import Option from "components/form-control/Option";
import {getValueFromLocalStorage, saveValueToLocalStorage} from "hooks/useLocalStorageState";
import useUserRole from "hooks/useUserRole";
import {useUsers} from "hooks";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {UserAccountValidation} from "forms/validation";
import {localStorageKeys, roleTypes} from "../../../../constants";
import {isEmptyObject, isIncludedIn} from "../../../../utils";
import {useState} from "react";
import {UnstableComboboxInput} from "../../../../components/combobox";


const AccountAndPersonalInfo = ({pius}) => {
    const {activeUser, setCurrentStep, isCreatingAdminUser: isWBAdmin} = useUsers();
    const {userForm: userFormLSKeys} = localStorageKeys;
    if (isWBAdmin) {
        delete UserAccountValidation.fields.role;
    }
    const {handleSubmit, register, formState: {errors, isSubmitted}} = useForm({
        resolver: yupResolver(UserAccountValidation),
        shouldUnregister: false
    });

    const inputClassName = 'bg-[#f4f5f5] py-2.5 text-sm';
    const labelClassName = "text-sm text-dark2";
    const userBasicInfo = getValueFromLocalStorage(userFormLSKeys.BASIC_INFO, {});
    const [selectedPIU, setSelectedPIU] = useState(
        userBasicInfo.selectedPIU ?? {}
    );

    const {userRoles = []} = useUserRole();
    const coreRoles = [roleTypes.PIU_ADMIN, roleTypes.WB_ADMIN, roleTypes.ROOT_ADMIN];

    const roleOptions = userRoles.filter((role) => {
        return !isIncludedIn(role.type, coreRoles);
    }).map((role) => (
        <Option
            key={role?._id}
            value={role?._id}
            text={role?.name}
        />
    ))

    const getRoleByType = (type) => {
        const _role = userRoles.find((role) => {
            return role?.type === type;
        })
        console.clear()
        console.log("user roles");
        console.log(userRoles)
        return _role
    }

    const onSubmit = (formData) => {
        console.log("submitting")
        if (isEmptyObject(selectedPIU) && isWBAdmin) return;

        if (isWBAdmin) {
            console.log("getting role")
            formData['role'] = getRoleByType(roleTypes.PIU_ADMIN)._id;
        }

        console.log("continue")
        saveValueToLocalStorage(userFormLSKeys.BASIC_INFO, {
            ...formData,
            selectedPIU
        });
        setCurrentStep(2)
    }

    console.log(pius)

    return (
        <form onSubmit={handleSubmit(onSubmit)} className={'space-y-5'}>
            <p className={'text-dark text-sm tracking-wide uppercase font-semibold'}>Account & Personal Information</p>
            <div className={'grid grid-cols-2 gap-4'}>
                <div className={'space-y-2'}>
                    <label htmlFor="firstName" className={labelClassName}>First Name</label>
                    <Input
                        isError={!!errors?.firstName?.message}
                        name={'firstName'}
                        defaultValue={userBasicInfo?.firstName ?? activeUser?.firstName}
                        register={register}
                        id={'firstName'} className={inputClassName}
                        placeholder={'eg: Bonny'}
                    />
                </div>

                <div className={'space-y-2'}>
                    <label htmlFor="middleName" className={labelClassName}>Middle Name</label>
                    <Input
                        register={register}
                        isError={!!errors?.middleName?.message}
                        name={'middleName'}
                        defaultValue={userBasicInfo?.middleName ?? activeUser?.middleName}
                        className={inputClassName}
                        placeholder={'eg: Kato'}
                    />
                </div>

                <div className={'space-y-2'}>
                    <label htmlFor="lastName" className={labelClassName}>Last Name</label>
                    <Input
                        register={register}
                        isError={!!errors?.lastName?.message}
                        defaultValue={userBasicInfo?.lastName ?? activeUser?.lastName}
                        name={'lastName'}
                        id={'roleName'} className={inputClassName}
                        placeholder={'eg: Baraka'}
                    />
                </div>

                <div className={'space-y-2'}>
                    <label htmlFor="gender" className={labelClassName}>Gender</label>
                    <Select defaultValue={userBasicInfo?.gender ?? activeUser?.gender}
                            isError={!!errors?.gender?.message} {...register('gender')} className={inputClassName}>
                        <option selected={true} disabled={true} value="">
                            Choose
                        </option>
                        <Option value={'F'} text={'Female'}/>
                        <Option value={'M'} text={'Male'}/>
                    </Select>
                </div>

                <div className={'space-y-2'}>
                    <label htmlFor="phoneNumber" className={labelClassName}>Phone Number</label>
                    <div className={''}>
                        <Input
                            isError={!!errors?.phoneNumber?.message}
                            register={register}
                            name={'phoneNumber'}
                            defaultValue={userBasicInfo?.phoneNumber ?? activeUser?.phoneNumber}
                            className={`${inputClassName}`}
                            placeholder={'Eg: 759144442'}
                        />
                        {/*<div*/}
                        {/*    className={'bg-gray-200 border-l-[1.5px] border-light-gray text-dark2 px-2 absolute top-0 left-0 h-full rounded-l  text-sm center'}>*/}
                        {/*    +255*/}
                        {/*</div>*/}
                    </div>
                </div>

                <div className={'space-y-2'}>
                    <label htmlFor="email" className={labelClassName}>Email</label>
                    <Input
                        register={register}
                        name={'email'}
                        defaultValue={userBasicInfo?.email ?? activeUser?.email}
                        isError={!!errors?.email?.message}
                        className={inputClassName}
                        placeholder={'eg: 6onnykato@gmail.com'}
                    />
                </div>
                {/* select project-piu */}
                <div hidden={!isWBAdmin} className={'col-span-2'}>
                    <label htmlFor="" className="text-sm text-dark2">
                        Attach To PIU
                    </label>
                    <UnstableComboboxInput
                        hasError={isSubmitted && isEmptyObject(selectedPIU)}
                        name="PIU"
                        defaultOption={selectedPIU}
                        options={pius}
                        displayItemName={'registeredName'}
                        onChange={setSelectedPIU}
                    />
                </div>

                <div hidden={isWBAdmin} className={`space-y-2 col-span-2`}>
                    <label htmlFor="role" className={labelClassName}>Role</label>
                    <Select defaultValue={userBasicInfo?.role ?? activeUser?.role?._id}
                            isError={!!errors?.role?.message} {...register('role')} className={inputClassName}>
                        <option selected={true} disabled={true} value="">
                            Choose
                        </option>
                        {roleOptions}
                    </Select>
                </div>
                <div className={'flex-right col-span-2'}>
                    <button type={"submit"}
                            className={'py-2 text-sm flex items-center space-x-1 px-4 text-white flex items-center space-x-2 bg-primary rounded hover:opacity-90'}>
                        <span>Next</span>
                        <ArrowRightIcon className={'h-6 w-6'}/>
                    </button>
                </div>
            </div>
        </form>
    )
}
export default AccountAndPersonalInfo;
