import { useEffect, useState } from "react";

import { slugify } from "../../../../utils";

const generateStakeholderUrl = (stakeholderName, baseUrl, urlPath) => {
    return `${baseUrl}${
        stakeholderName ? `/${stakeholderName}` : ""
    }${urlPath}`;
};

const GetStakeholderTabMenu = (stakeholder = {}, baseUrl) => {
    const { name: stakeholderName = "", hasRelationshipHolder = false } =
        stakeholder;

    const initialTabs = [
        {
            title: "Overview",
            urlPath: "/overview",
            urlPattern: new RegExp(`^${baseUrl}/?(.*?)/(overview)?$`),
        },
        {
            title: "Contact Person",
            urlPath: "/contact-personal",
            urlPattern: new RegExp(`^${baseUrl}/?(.*?)/contact-personal$`),
        },
        {
            title: "Goals and Concern",
            urlPath: "/goals-and-concern",
            urlPattern: new RegExp(`^${baseUrl}/?(.*?)/goals-and-concern$`),
        },
        {
            title: "Engagement",
            urlPath: "/engagements",
            urlPattern: new RegExp(`^${baseUrl}/?(.*?)/engagement$`),
        },
        {
            title: "Commitment",
            urlPath: "/commitments",
            urlPattern: new RegExp(`^${baseUrl}/?(.*?)/commitment$`),
        },
    ];

    const [tabs, setTabs] = useState(initialTabs);

    const updateStakeholderTabs = () => {
        if (hasRelationshipHolder) {
            const updatedTabs = [
                ...initialTabs.slice(0, 2),
                {
                    title: "Relationship Holder",
                    urlPath: "/relationship-holder",
                    urlPattern: new RegExp(
                        `^${baseUrl}/?(.*?)/relationship-holder$`
                    ),
                },
                ...initialTabs.slice(2),
            ];
            setTabs(updatedTabs);
        } else {
            setTabs(initialTabs);
        }
    };

    useEffect(() => {
        updateStakeholderTabs();
    }, [hasRelationshipHolder]);

    return tabs.map((tab) => ({
        ...tab,
        url: generateStakeholderUrl(
            slugify(stakeholderName),
            baseUrl,
            tab.urlPath
        ),
    }));
};

export default GetStakeholderTabMenu;
