import {useState} from "react";
import {useQuery} from "react-query"
import {CommitmentAnalyticsContext} from "../../../context";
import {getCommitmentAnalytics} from "../../api";
import useGlobalContext from "../../../hooks/useGlobalContext";

const CommitmentAnalyticsProvider = ({children}) => {
    const {projectId} = useGlobalContext();
    const [commitmentAnalytics, setCommitmentAnalytics] = useState({});

    const {isLoading} = useQuery('commitment-analytics', () => {
        return getCommitmentAnalytics(projectId)
    }, {
        onSuccess: ({data: analytics}) => {
            setCommitmentAnalytics(analytics);
        }
    })

    return (
        <CommitmentAnalyticsContext.Provider value={{isLoading, commitmentAnalytics}}>
            {children}
        </CommitmentAnalyticsContext.Provider>
    )
}
export default CommitmentAnalyticsProvider;
