import dayjs from "dayjs";
import React from "react";

const SubprojectListItem = ({onEdit: handleEdit, subproject}) => {
    return (
        <div className="grid grid-cols-6 px-5 py-3 text-[15px] hover:bg-secondary/5">
            <div className="flex items-center col-span-2 space-x-2">
                <p className="space-x-1">
                    <span>{subproject.name}</span>
                </p>
            </div>

            <div className="flex  col-span-2 text-black/60">
                {subproject.description}
            </div>

            <div className="flex justify-center text-black/60">
                {dayjs(subproject.createdAt).format('DD, MMMM YYYY')}
            </div>
            <div className={'center'}>
                <button onClick={() => handleEdit(subproject)}
                        className={'text-sm border-[1.2px] whitespace-nowrap rounded-[3px] tracking-wide font-light hover:text-white hover:bg-accent transition-all text-accent border-accent px-3 py-1'}>Edit
                    Details
                </button>
            </div>
        </div>
    )
}
export default SubprojectListItem;
