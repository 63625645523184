import { ArrowRightIcon } from "../../../../svg";
import { ChevronRightIcon } from "@heroicons/react/outline";
import useGrievanceAnalytics from "../../../../hooks/useGrievanceAnalytics";
import ResolvedGrievanceByPieChart from "./resolved-grievance-by-pie-chart";

const GrievanceBreakdown = () => {
	const { grievanceAnalytic, isLoading } = useGrievanceAnalytics();

	if (isLoading)
		return (
			<div
				className={
					"bg-white p-10 grid gap-4 h-72 grid-cols-3 rounded-[7px]"
				}
			>
				{[...Array(3)].map((items, index) => (
					<div
						key={index}
						className={
							"bg-secondary/[0.23] animate-pulse  rounded h-full"
						}
					></div>
				))}
			</div>
		);

	const { general } = grievanceAnalytic;

	return (
		<div className={"bg-white p-10 grid grid-cols-3"}>
			<div className={"space-y-3"}>
				<p className={"font-bold text-4xl text-secondary"}>
					{general?.total || 0}
				</p>
				<div className={"text-[#868686] space-y-2"}>
					<p className={"text-sm"}>Grievance Received</p>
					<p
						className={
							"font-thin border-l-[2px] border-l-secondary pl-5 text-[15px]"
						}
					>
						This marks total number <br />
						of grievances received <br />
						since the project started
					</p>
				</div>

				<div className={"pt-3"}>
					<button
						className={
							"text-sm flex items-center space-x-3 text-secondary"
						}
					>
						<span>VIEW GRIEVANCES</span>
						<ArrowRightIcon
							color={"#628799"}
							className={"h-6 w-6"}
						/>
					</button>
				</div>
			</div>
			<div className={"space-y-3 "}>
				<p className={"font-bold text-4xl text-secondary"}>
					{general?.resolvedOnTime + general?.resolvedLate || 0}
				</p>
				<div className={"text-[#868686] space-y-2"}>
					<p className={"text-sm"}>Resolved Grievance</p>
					<p className={"font-thin  text-[15px]"}>
						Total number of resolved <br />
						and closed grievances
					</p>
				</div>

				<div className={"pt-3"}>
					<button
						className={
							"text-sm flex border-[1.2px] border-[#638798]/30 px-3 py-2 rounded-[3px] bg-[#CEDCDE]/50 text-primary items-center space-x-3 text-secondary"
						}
					>
						<span>View resolved</span>
						<ChevronRightIcon className={"h-4 w-4"} />
					</button>
				</div>
			</div>
			<div className={"center"}>
				<div className={" -mt-2 w-full"}>
					{/* chart */}
					<div className={"h-44 m-auto w-44"}>
						<ResolvedGrievanceByPieChart
							resolvedLate={general?.resolvedLate}
							resolvedOnTime={general?.resolvedOnTime}
						/>
					</div>
					<div className={"flex justify-between"}>
						<div className={"text-xs flex space-x-2"}>
							<svg height={8} width={8} className={"mt-1"}>
								<circle r={4} cy={4} cx={4} fill={"#91D1BD"} />
							</svg>
							<p>
								Resolved within <br /> agreed time
							</p>
						</div>

						<div className={"text-xs flex space-x-2"}>
							<svg height={8} width={8} className={"mt-1"}>
								<circle r={4} cy={4} cx={4} fill={"#E97E7C"} />
							</svg>
							<p>
								Resolved outside <br /> agreed time
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
export default GrievanceBreakdown;
