import { useState } from "react";
import { RadioGroup } from "@headlessui/react";
import TypeCard from "./type-card";
import PropsTypes from "prop-types";

const TypeInput = ({
    defaultType,
    onChange: onChangeHandler,
    types = [],
    label,
}) => {
    const [selectedType, setSelectedType] = useState(defaultType ?? types[0]);

    const handleChange = (type) => {
        setSelectedType(type);
        onChangeHandler(type);
    };
    return (
        <RadioGroup
            name={"type"}
            className={"space-y-3"}
            value={selectedType}
            onChange={handleChange}
        >
            <RadioGroup.Label className={"text-primary tracking-wide"}>
                {label}
            </RadioGroup.Label>
            <div className={"flex items-center space-x-5"}>
                {types.map((type) => (
                    <RadioGroup.Option
                        className={
                            "rounded-md focus:ring-4 focus:ring-accent/50 focus:outline-none"
                        }
                        value={type}
                    >
                        {({ checked }) => (
                            <TypeCard
                                key={type}
                                type={type}
                                isSelected={checked}
                            />
                        )}
                    </RadioGroup.Option>
                ))}
            </div>
        </RadioGroup>
    );
};

TypeInput.protoTypes = {
    defaultType: PropsTypes.string,
    onChange: PropsTypes.func.isRequired,
    label: PropsTypes.string.isRequired,
};
export default TypeInput;
