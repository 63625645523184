const StarSoldIcon = ({className}) => {
    return (
        <svg className={className ? className : 'h-6 w-6'} xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 42 42">
            <g id="Polygon_8" data-name="Polygon 8" fill="#fecb2f">
                <path d="M 30.66557502746582 37.26329040527344 L 22.14102935791016 31.34160995483398 L 21 30.54898071289062 L 19.85897064208984 31.34160995483398 L 11.3344259262085 37.26329040527344 L 14.09980964660645 26.74371910095215 L 14.42232990264893 25.51687049865723 L 13.44995975494385 24.70219039916992 L 5.212739944458008 17.80078315734863 L 15.63350009918213 17.31306076049805 L 16.97341918945312 17.25035095214844 L 17.42386054992676 15.98686027526855 L 21 5.955818176269531 L 24.57613945007324 15.98684978485107 L 25.02658081054688 17.25033950805664 L 26.36648941040039 17.31304931640625 L 36.78726196289062 17.80078125 L 28.55003929138184 24.70219039916992 L 27.57766914367676 25.51687049865723 L 27.90019035339355 26.74371910095215 L 30.66557502746582 37.26329040527344 Z" stroke="none"/>
                <path d="M 21.00000190734863 11.91166496276855 L 18.40683937072754 19.18544960021973 L 10.42548942565918 19.55900764465332 L 16.67911911010742 24.79850006103516 L 14.64756774902344 32.52656555175781 L 21 28.11376953125 L 27.35242462158203 32.52656173706055 L 25.32086944580078 24.79850006103516 L 31.57451248168945 19.55900192260742 L 23.59316062927246 19.18544006347656 L 21.00000190734863 11.91166496276855 M 21 0 L 26.45999908447266 15.31524085998535 L 42 16.04257011413574 L 29.8344612121582 26.2352409362793 L 33.97871017456055 42 L 21 32.98418045043945 L 8.021289825439453 42 L 12.16552925109863 26.2352409362793 L 0 16.04257011413574 L 15.54000091552734 15.31525039672852 L 21 0 Z" stroke="none" fill="#fecb2f"/>
            </g>
        </svg>
    )
}

export default StarSoldIcon