import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createContext, useContext, useEffect, useRef, useState } from "react";
import { getValueFromLocalStorage } from "../../../../../../hooks/useLocalStorageState";
import { useConductMeetingContext } from "../index";
import InputCard from "../_partials/input-card";
import { CommitmentCard } from "./commitment-card";
import CommitmentForm from "../../../../commitments/form/commitment-form";
import { isEmptyObject } from "utils";
import { getRandomKey } from "../../../../../../utils";

export const CommitmentContext = createContext(null);
const CommitmentInput = () => {
    const { conductedInfo, setConductedInfo } = useConductMeetingContext();
    const [showForm, setShowForm] = useState(false);
    const [activeCommitment, setActiveCommitment] = useState({});
    const [commitments, setCommitments] = useState(conductedInfo.commitments);
    const activeEvent = getValueFromLocalStorage("activeProjectEvent", {});
    const { participants } = activeEvent;
    const formRef = useRef(null);

    useEffect(() => {
        setConductedInfo({ ...conductedInfo, commitments });
    }, [commitments]); //  eslint-disable-line react-hooks/exhaustive-deps

    const handleClose = () => {
        setConductedInfo({ ...conductedInfo, commitments });
    };

    const handleSubmit = (commitment) => {
        if (isEmptyObject(activeCommitment)) {
            setCommitments([
                ...commitments,
                {
                    ...commitment,
                    id: getRandomKey(),
                },
            ]);
        } else updateCommitment(commitment);
        formRef.current.closeForm();
    };

    // update commitment structure
    const updateCommitment = (commitment) => {
        return setCommitments(
            commitments.map((_commitment) => {
                if (_commitment.id === commitment.id) {
                    return commitment;
                }
                return _commitment;
            })
        );
    };
    return (
        <InputCard
            title={"Commitments made during the meeting"}
            onClose={handleClose}
        >
            <CommitmentContext.Provider
                value={{
                    isOpen: showForm,
                    setIsOpen: setShowForm,
                    participants,
                    commitments,
                    setCommitments,
                    setActiveCommitment,
                    activeCommitment,
                }}
            >
                <CommitmentForm
                    showForm={showForm}
                    setShowForm={setShowForm}
                    setActiveCommitment={setActiveCommitment}
                    activeCommitment={activeCommitment}
                    ref={formRef}
                    onSubmit={handleSubmit}
                    initialKeyResult={activeCommitment.results}
                    stakeholders={
                        activeEvent?.participants.map(
                            (participant) => participant.stakeholder
                        ) || []
                    }
                />
                <div className={"bg-[#FFFFFF] p-3"}>
                    <div className={"border-t border-t-[#C3CBD9] py-5 px-2 "}>
                        <div className={"grid place-items-end pb-5"}>
                            <div
                                onClick={() => setShowForm(true)}
                                className={
                                    "cursor-pointer hover:bg-[#CFE5F56A] font-semibold text-xs text-[#009EE2] px-4 py-2 relative border-[#009EE2] border-[2px] space-x-2"
                                }
                            >
                                <span>ADD COMMITMENTS</span>
                                <FontAwesomeIcon icon={faPlus} />
                            </div>
                        </div>

                        <div
                            className={
                                "w-full grid grid-cols-2 2xl:grid-cols-3 gap-3"
                            }
                        >
                            {commitments.length > 0 &&
                                commitments.map((commitment) => {
                                    return (
                                        <CommitmentCard
                                            key={commitment.id}
                                            commitment={commitment}
                                        />
                                    );
                                })}
                        </div>
                    </div>
                </div>
            </CommitmentContext.Provider>
        </InputCard>
    );
};
export const useCommitmentContext = () => useContext(CommitmentContext);
export default CommitmentInput;
