import Button from "components/Button";

const FormFooter = ({ onCancel: handleCancel, isLoading = false }) => {
    return (
        <footer className={"space-x-4 flex items-center pt-5"}>
            <Button
                outline
                type={"button"}
                onClick={handleCancel}
                className={
                    "text-sm enable-transition text-accent " +
                    " border-[1.5px] w-fit shrink-0 border-accent" +
                    " hover:bg-accent/10 tracking-wider " +
                    "rounded-[2px] py-2.5 px-4"
                }
            >
                CANCEL
            </Button>

            <Button
                loading={isLoading}
                type="submit"
                className={
                    "text-sm text-white" +
                    " bg-accent border-[1.5px] w-full border-accent" +
                    " tracking-wider rounded-[2px] py-2.5 px-4"
                }
            >
                ADD PLAN
            </Button>
        </footer>
    );
};
export default FormFooter;
