import { DotsHorizontalIcon } from "@heroicons/react/outline";
import useGrievanceAnalytics from "../../../../hooks/useGrievanceAnalytics";
import BarChart from "./bar-chart";
import { groupByProperty } from "../../../../utils";

const DepartmentComplainedAbout = () => {
	const { grievanceAnalytic, isLoading } = useGrievanceAnalytics();
	if (isLoading)
		return (
			<div className="bg-white rounded-[6px] p-4 space-y-2">
				<div className="w-56 h-3.5 bg-secondary/[.23] rounded-full"></div>
				<div className="flex items-end justify-between h-72">
					<div className="h-[10%] bg-secondary/[.23] animate-pulse w-12 rounded-t-lg"></div>
					<div className="h-[50%] bg-secondary/[.23] animate-pulse w-12 rounded-t-lg"></div>
					<div className="h-[30%] bg-secondary/[.23] animate-pulse w-12 rounded-t-lg"></div>
					<div className="h-[90%] bg-secondary/[.23] animate-pulse w-12 rounded-t-lg"></div>
					<div className="h-[70%] bg-secondary/[.23] animate-pulse w-12 rounded-t-lg"></div>
					<div className="h-[10%] bg-secondary/[.23] animate-pulse w-12 rounded-t-lg transition-all "></div>
				</div>
			</div>
		);

	const { byDepartment: departmentData = [] } = grievanceAnalytic;
	const departmentNames = departmentData.map(
		(department) => department.department
	);
	const totalReceived = departmentData.map(
		(department) => department.report.received
	);
	const totalUnderInvestigation = departmentData.map(
		(department) => department.report.underInvestigation
	);
	const totalInProgress = departmentData.map(
		(department) => department.report.inProgress
	);
	const totalResolved = departmentData.map((department) => {
		return (
			department.report.resolvedOnTime + department.report.resolvedLate
		);
	});

	const totalClosed = departmentData.map((department) => {
		return department.report.closed;
	});

	const totalReopened = departmentData.map((department) => {
		return department.report.reOpened;
	});

	/* const groupedByDepartment = groupByProperty(departmentData, "department");
	const _departmentNames = Object.keys(groupedByDepartment);
	console.log(
		"DEP:",
		_departmentNames[0],
		"Value:",
		groupedByDepartment[_departmentNames[0]]
	); */

	const statistics = {
		departmentNames,
		totalResolved,
		totalReceived,
		totalUnderInvestigation,
		totalInProgress,
		totalClosed,
		totalReopened,
	};

	return (
		<div className={"bg-white rounded-[6px] "}>
			<div
				className={
					"items-between border-b-[1px] border-b-[#E5E5E5] py-3 px-5"
				}
			>
				<p className={"text-[#31394C] font-medium text-[15px]"}>
					Summary per department complained about
				</p>
				<DotsHorizontalIcon className={"h-6 text-primary w-6"} />
			</div>

			{/* bar chart */}
			<div className={"h-72 px-3 py-5 overflow-hidden"}>
				<BarChart statistics={statistics} />
			</div>
		</div>
	);
};
export default DepartmentComplainedAbout;
