import PropTypes from 'prop-types';
import {ArrowRightIcon} from "@heroicons/react/outline";
import CommitmentBadge from "./_partials/commitmentBadge";
import {CreateMarkup} from "../../../../../utils";
import dayjs from "dayjs";

const CommitmentCard = ({showStatus = true, showAvatar = false, showViewBtn = true, commitment, onClick,}, props) => {

    const formatDate = (date) => {
        return dayjs(date).format('DD MMMM YYYY');
    }

    // console.log('[+] COMMITMENT [+]', commitment)


    return (
        <div {...props} className={'p-5 space-y-4 text-sm rounded-[4px] border-[1px] border-[#E4E4E4]'}>
            {showStatus && <CommitmentBadge status={commitment.status}/>}
            <p className={'font-medium'}>{commitment.stakeholder?.name} </p>

            <div className={'flex items-center space-x-10'}>
                <div className={'space-y-1'}>
                    <p className={'text-[#AAB6C5] text-xs'}>Create On:</p>
                    <p className={'text-[13px] text-dark'}>{formatDate(commitment?.startDate)}</p>
                </div>

                <div className={'space-y-1'}>
                    <p className={'text-[#AAB6C5] text-xs'}>Should End On:</p>
                    <p className={'text-[13px] text-dark'}>{formatDate(commitment?.endDate)}</p>
                </div>
            </div>

            {/* description */}
            <div className={'text-[13px]'}>
                <p className={'text-secondary'}>Descriptions:</p>
                <div className={''}>
                    <p
                        dangerouslySetInnerHTML={CreateMarkup(commitment?.description)}
                        className={'text-[#AAB6C5] line-clamp-2 leading-relaxed'}>
                    </p>
                </div>
            </div>

            <div className={'items-between'}>
                {showAvatar && (
                    <div className={'h-8 w-8 rounded-full text-white bg-secondary center'}>
                        <p className={'opacity-50 uppercase'}>{commitment?.stakeholder?.name.slice(0, 2)}</p>
                    </div>
                )}

                {showViewBtn && (

                    <div onClick={() => onClick(commitment)}
                         className={'flex italic text-accent text-[13px] space-x-2 cursor-pointer font-light items-center'}>
                        <p>View Commitment</p>
                        <ArrowRightIcon className={'h-4 w-4'}/>
                    </div>
                )}
            </div>
        </div>
    )
}
export default CommitmentCard;

CommitmentCard.propTypes = {
    commitment: PropTypes.object.isRequired
}