import PlansIllustration from "./plans-illustration";

const EmptyState = () => {
    return (
        <div
            className={
                "h-[60vh] mt-4 space-y-4 border rounded flex flex-col center bg-[#f9f9f9]"
            }
        >
            <div className={"h-44 w-44 bg-gray-200 rounded-full center p-4"}>
                <PlansIllustration />
            </div>
            <p className={"text-center text-gray-400"}>
                There are no engagement plans currently available. To get
                started, please click the button above to <br />
                create engagement plans.
            </p>
        </div>
    );
};
export default EmptyState;
