import {Outlet, useLocation} from "react-router-dom";
import Sidebar from "./sidebar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCaretDown} from "@fortawesome/free-solid-svg-icons";
import {useAuth} from "../../provider/auth/auth";
import {useState} from "react";
import CurrStakeholderProvider from "../../provider/general/stakeholders/curr-stakeholder";
import useClickOutside from "../../hooks/useClickOutside";
import NotificationProvider from "../../provider/notification/notification";
import {UserIcon} from "@heroicons/react/solid";
import {Cannot, permissions} from "provider/permission";
import Menu from "components/custom-menu";
import useGlobalContext from "../../hooks/useGlobalContext";
import {
    getValueFromLocalStorage,
    removeValuesFromLocalStorage,
    saveValueToLocalStorage
} from "../../hooks/useLocalStorageState";
import {localStorageKeys} from "../../constants";
import {useMutation} from "react-query";
import {updateUserInfo} from "../../provider/api";
import SpinLoader from "../../components/SpinLoader";

const AuthLayout = ({children}) => {
    const {signOut, authUser} = useAuth();
    const [isOpen, setIsOpen] = useState(false);

    const {
        projectDetails = {},
        setProjectId,
        setSubprojectId,
        subprojectDetails = {},
        setSubprojectDetails,
        projectId = ""
    } = useGlobalContext();
    const locationName = useLocation().pathname.split("/")[1].replace("-", " ");
    const menuRef = useClickOutside(() => {
        setIsOpen(false);
    });

    const hasSubprojects = (projectId) => {
        const {subProjects = []} = authUser;
        return subProjects.some(({parentProject} = {}) => parentProject === projectId)
    };

    const getSubprojects = () => {
        const {subProjects = []} = authUser;
        return subProjects.filter(({parentProject} = {}) => parentProject === projectId);
    }

    // send put request to update user details
    const {
        isLoading: isUpdatingDefaultProject,
        mutate: updateDefaultProject
    } = useMutation('update-user-details',
        updateUserInfo, {
            onSuccess: async ({data: user}) => {
                setProjectIdAndSaveToLocalStorage(user.defaultProject)
                if (!hasSubprojects(user.defaultProject)) {
                    await removeOldDefaultSubproject()
                    setSubprojectDetailsAndRemoveFromLocalStorage()
                }
            },
            onError: () => {
                // Todo: handle error if failed to update user default project
            }
        })

    const setProjectIdAndSaveToLocalStorage = (projectId) => {
        // Update the projectId state with the new project-id and persist it in local storage.
        setProjectId(projectId)
        saveValueToLocalStorage(localStorageKeys.PROJECT_ID, projectId)
    }

    const removeOldDefaultSubproject = async () => {
        // remove defaultSubproject if the selected project does not have subprojects
        await updateDefaultSubproject({
            userId: authUser._id,
            defaultSubProject: null
        })
    }

    const setSubprojectDetailsAndRemoveFromLocalStorage = () => {
        setSubprojectDetails({}) // reset subproject details
        removeValuesFromLocalStorage([
            localStorageKeys.SUBPROJECT_DETAILS,
            localStorageKeys.SUBPROJECT_ID
        ])
    }

    // send put request to update user details
    const {
        isLoading: isUpdatingDefaultSubproject,
        mutate: updateDefaultSubproject
    } = useMutation('update-user-details',
        updateUserInfo, {
            onSuccess: ({data: user}) => {
                setSubprojectDetails(
                    getValueFromLocalStorage(localStorageKeys.SUBPROJECT_DETAILS)
                )
                setSubprojectId(user.defaultSubProject)
                saveValueToLocalStorage(localStorageKeys.SUBPROJECT_ID, user.defaultSubProject)
            },

            onError: () => {
                // Todo: handle error if failed to update user default subproject
            }
        })

    const handleProjectClick = async (project) => {
        await updateDefaultProject({
            userId: authUser._id,
            defaultProject: project._id
        })
    }

    const handleSubprojectClick = (subproject) => {
        saveValueToLocalStorage(localStorageKeys.SUBPROJECT_DETAILS, subproject);
        updateDefaultSubproject({
            userId: authUser._id,
            defaultSubProject: subproject._id
        })
    }

    const handleSignOut = () => {
        signOut(() => {
            removeValuesFromLocalStorage([
                localStorageKeys.SUBPROJECT_DETAILS,
                localStorageKeys.PROJECT_ID,
                localStorageKeys.SUBPROJECT_ID
            ])
        })
    }

    return (
        <main className="h-screen overflow-hidden ">
            <NotificationProvider>
                <section className={"h-full flex"}>
                    <Sidebar/>

                    {/* details area */}
                    <section className={"details-area h-full "}>
                        <header
                            className={
                                "border-b-[1px] border-[#E4E6EA] flex justify-between items-center px-7 py-4"
                            }
                        >
                            <div
                                className={
                                    "text-[#1D1C23] capitalize text-xl font-medium tracking-wide"
                                }
                            >
                                {locationName}
                            </div>
                            <p>{projectDetails?.name}</p>
                            <div className={"flex items-center space-x-8"}>
                                <Cannot permission={permissions.MANAGE_PROJECTS_AND_PIUS}>
                                    {/*
                                        menu to switch between projects if the current user has more than one project
                                    */}
                                    {authUser.projects.length > 1 && (
                                        <Menu
                                            menuName={'Projects'}
                                            onClick={handleProjectClick}
                                            options={authUser.projects.filter((project) => {
                                                // remove an active project from the switch dropdown
                                                return project?._id !== projectDetails?._id
                                            })}
                                            displayName={'name'}
                                        />
                                    )}
                                    {/*
                                        menu to switch between subprojects if the current user has more than one
                                        subproject
                                    */}
                                    {getSubprojects().length ? (
                                        <Menu
                                            menuName={subprojectDetails?.name ?? 'Subprojects'}
                                            onClick={handleSubprojectClick}
                                            options={getSubprojects().filter((subproject) => {
                                                // remove an active project from the switch dropdown
                                                return subproject?._id !== subprojectDetails?._id
                                            })}
                                            displayName={'name'}
                                        />
                                    ) : null}
                                </Cannot>

                                <div
                                    onClick={() => setIsOpen(!isOpen)}
                                    className={
                                        "bg-[#F0F0F0] flex relative items-center rounded-full cursor-pointer pr-2 space-x-1"
                                    }
                                >
                                    <div
                                        className={
                                            "center bg-gray-300/70 h-8 w-8 rounded-full overflow-hidden"
                                        }
                                    >
                                        <UserIcon className="w-6 h-6 text-[#6A6F7B]"/>
                                    </div>
                                    <p
                                        className={
                                            "text-[#6A6F7B] tracking-wider text-sm"
                                        }
                                    >
                                        {authUser.firstName}
                                    </p>
                                    <FontAwesomeIcon
                                        icon={faCaretDown}
                                        className={"h-3 w-3 text-[#45485E]"}
                                    />

                                    <div
                                        ref={menuRef}
                                        className={`absolute ${
                                            isOpen
                                                ? "scale-100 opacity-100 pointer-events-auto"
                                                : "scale-50 opacity-0 pointer-events-none"
                                        } z-[100] origin-top-right enable-transition top-full border bg-white w-[150px] p-1 rounded-lg shadow-md right-0`}
                                    >
                                        <button
                                            onClick={handleSignOut}
                                            className={
                                                "text-sm text-primary rounded p-2 hover:bg-primary/30"
                                            }
                                        >
                                            Sign Out
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </header>

                        <section className={"stakeholder-list relative overflow-hidden"}>
                            <CurrStakeholderProvider>
                                <Outlet/>
                            </CurrStakeholderProvider>

                            {(isUpdatingDefaultProject || isUpdatingDefaultSubproject) && (
                                <div
                                    className={'absolute z-50  space-y-4 inset-0 center flex-col text-dark2 bg-white/70 backdrop-blur-[1.5px]'}>
                                    <SpinLoader size={'extra-large'} className={'border-[3.5px]'}/>
                                    <p className={'text-lg'}>Please wait while switching...
                                    </p>
                                </div>
                            )}
                        </section>
                    </section>
                </section>
            </NotificationProvider>
        </main>
    );
};
export default AuthLayout;
