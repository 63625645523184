import { forwardRef, useImperativeHandle, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import UnstableInput from "components/unstable/input";
import Button from "components/Button";

const KeyResultInput = forwardRef((props, ref) => {
    const {
        defaultValue = {},
        onSubmit: _handleSubmit,
        onRemove: handleRemove,
        id = null,
        autoFocus,
        maxDate,
        minDate,
        ...rest
    } = props;

    const [isSubmitted, setIsSubmitted] = useState(false);

    const {
        handleSubmit,
        register,
        reset: clearForm,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(
            Yup.object({
                title: Yup.string().required(),
                endDate: Yup.date().required(),
            })
        ),
    });

    const handleClick = () => {
        if (id) {
            handleRemove(id);
        }
    };

    const onSubmit = (formData) => {
        if (id) {
            setIsSubmitted(true);
        }
        _handleSubmit(formData);
    };

    useImperativeHandle(
        ref,
        () => ({
            clearForm,
        }),
        [clearForm]
    );

    const fields = [
        {
            name: "title",
            autoFocus,
            hasError: !!errors?.title?.message,
            defaultValue: defaultValue?.title,
            register,
            showErrorMessage: false,
            wrapperClass: "w-full",
            className: "py-2",
        },
        {
            name: "endDate",
            hasError: !!errors?.endDate?.message,
            defaultValue: defaultValue?.endDate,
            register,
            type: "date",
            wrapperClass: "w-full",
            showErrorMessage: false,
            max: maxDate,
            min: minDate,
            className: "py-2",
        },
    ];

    return (
        <form
            {...rest}
            onSubmit={handleSubmit(onSubmit)}
            className=" flex items-center space-x-4"
        >
            {fields.map((fieldProps) => (
                <UnstableInput key={fieldProps.name} props={fieldProps} />
            ))}
            <Button
                className={"mt-6"}
                onClick={handleClick}
                outline={!!id}
                type={id ? "button" : "submit"}
            >
                {id ? "Remove" : "Add"}
            </Button>
        </form>
    );
});

export default KeyResultInput;
