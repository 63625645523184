export const UserGroup = ({className, color = "#aab6c5"}) => {
    return (
        <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.69 21.695">
            <g id="Group_164983" data-name="Group 164983" transform="translate(0 0)">
                <path id="Path_91525" data-name="Path 91525"
                      d="M36.218,20.194a2.41,2.41,0,0,0,.6-1.579V17.41a2.41,2.41,0,0,0-4.82,0v1.205a2.41,2.41,0,0,0,.6,1.579,3.145,3.145,0,0,0-.518.331,3.56,3.56,0,0,1,.211.313,5.983,5.983,0,0,1,.91,3.2v1.205h4.218a.6.6,0,0,0,.6-.6V23.134a3.3,3.3,0,0,0-1.807-2.94Z"
                      transform="translate(-16.335 -9.575)" fill={color}/>
                <path id="Path_91526" data-name="Path 91526"
                      d="M24.23,19.435c-.114-.114-.229-.217-.349-.319a4.736,4.736,0,0,0-1.054-.651,3.1,3.1,0,0,0,.729-.982,3.061,3.061,0,0,0,.277-1.265V15.013a3.013,3.013,0,0,0-6.025,0v1.205a3.061,3.061,0,0,0,.277,1.265,3.1,3.1,0,0,0,.729.982,4.386,4.386,0,0,0-1.048.657,4.742,4.742,0,0,0-1.018,1.145A4.82,4.82,0,0,0,16,22.845v1.808a.6.6,0,0,0,.6.6h8.435a.6.6,0,0,0,.6-.6V22.845a4.76,4.76,0,0,0-.747-2.573A4.459,4.459,0,0,0,24.23,19.435Z"
                      transform="translate(-9.975 -8.382)" fill={color}/>
                <path id="Path_91527" data-name="Path 91527"
                      d="M6,23.134V24.64a.6.6,0,0,0,.6.6H10.82V24.038a6.061,6.061,0,0,1,.934-3.223,2.452,2.452,0,0,1,.2-.289,2.91,2.91,0,0,0-.518-.331,2.41,2.41,0,0,0,.59-1.579V17.41a2.41,2.41,0,0,0-4.82,0v1.205a2.41,2.41,0,0,0,.6,1.579A3.3,3.3,0,0,0,6,23.134Z"
                      transform="translate(-6 -9.575)" fill={color}/>
                <path id="Path_91528" data-name="Path 91528"
                      d="M12.009,35.193a.6.6,0,0,0-.856.856,10.845,10.845,0,0,0,15.34,0,.6.6,0,1,0-.856-.856,9.64,9.64,0,0,1-13.629,0Z"
                      transform="translate(-7.978 -17.531)" fill={color}/>
                <path id="Path_91529" data-name="Path 91529"
                      d="M26.492,10.029a.6.6,0,0,0,0-.856,10.845,10.845,0,0,0-15.34,0,.614.614,0,0,0,.868.868,9.64,9.64,0,0,1,13.629,0A.6.6,0,0,0,26.492,10.029Z"
                      transform="translate(-7.977 -5.995)" fill={color}/>
            </g>
        </svg>
    )
}