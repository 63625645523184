import {useNavigate, useLocation} from "react-router-dom"

const MeetingTab = ({title = "text", url, color}) => {
    const {pathname} = useLocation();
    const navigate = useNavigate();
    const url_ = `/engagement/activities/events/${url}`;
    const isActive = pathname.includes(`${url}`)

    return (
        <div onClick={() => navigate(url)}
             className={`relative ${isActive ? 'text-white' : 'text-[#AAB6C5]'}  w-[180px] cursor-pointer`}>
            <svg className={'h-full w-full'} xmlns="http://www.w3.org/2000/svg" width={'180'} height={'40'}
                 viewBox="0 0 247 59">
                <path id="Path_102144" data-name="Path 102144"
                      d="M6,0H179.391a38.1,38.1,0,0,1,14.092,4.424C199.784,8.159,204.593,14.94,247,59H0V6A6,6,0,0,1,6,0Z"
                      fill={isActive ? color : '#EEEDF0'}/>

                {!isActive && (
                    <path
                        d="M 6 1.400001525878906 C 3.46356201171875 1.400001525878906 1.399993896484375 3.46356201171875 1.399993896484375 6 L 1.399993896484375 57.59999847412109 L 243.7110290527344 57.59999847412109 C 229.5977630615234 42.91545867919922 219.9676666259766 32.69384765625 212.8392486572266 25.12759399414062 C 200.0539398193359 11.55696868896484 196.6176605224609 7.909595489501953 192.7692260742188 5.628093719482422 C 187.0998840332031 2.267108917236328 180.0197448730469 1.471218109130859 179.3231506347656 1.400001525878906 L 6 1.400001525878906 M 6 0 L 179.3912506103516 0 C 179.3912506103516 0 187.1827850341797 0.6887168884277344 193.483154296875 4.423812866210938 C 199.7835388183594 8.158939361572266 204.5928344726562 14.94043731689453 247 59 L 0 59 L 0 6 C 0 2.686283111572266 2.686279296875 0 6 0 Z"
                        stroke="none" fill="#e2e2e2"/>
                )}
            </svg>

            <div className={'absolute  inset-0 text-sm flex justify-center items-center pr-6'}>
                {title}
            </div>
        </div>

    )
}
export default MeetingTab;