// Third-party imports
import { useForm } from "react-hook-form";
import EditorJS from "components/editorjs/editor";
import { yupResolver } from "@hookform/resolvers/yup";
import { useContext, useState } from "react";
import { useMutation } from "react-query";
import { toast } from "react-toastify";

// Local imports
import { FormContext } from "../index";
import { ContactPersonalValidation } from "../../../../../../forms/validation";
import {
    getValueFromLocalStorage,
    saveValueToLocalStorage,
} from "hooks/useLocalStorageState";
import { isEmptyDescription } from "utils";
import { updateStakeholderDetails } from "provider/api";
import getContactPersonalFields from "./fields";
import UnstableSelect from "components/unstable/select";
import UnstableInput from "components/unstable/input";
import FormHeader from "components/form-header/form-header";
import ActionButtons from "../_partials/action-buttons";
import ContactPersonAddress from "./address";
import {
    cacheActions,
    localStorageKeys,
    queryKeys,
} from "../../../../../../constants";
import useUpdateCache from "hooks/useUpdateCache";

const ContactPerson = () => {
    const { stakeholderFrom: stakeholderLSKeys } = localStorageKeys;
    const [currentStep, setCurrentStep] = useContext(FormContext);

    // Initial data from local storage
    const isUpdatingStakeholder = getValueFromLocalStorage(
        stakeholderLSKeys.IS_UPDATING_DETAILS,
        false
    );

    const updateCache = useUpdateCache();
    const basicDetails = getValueFromLocalStorage(
        stakeholderLSKeys.BASIC_DETAILS,
        {}
    );

    const contactPerson = getValueFromLocalStorage(
        stakeholderLSKeys.CONTACT_PERSON,
        {}
    );

    const [description, setDescription] = useState(contactPerson.description);
    const { hasRelationshipHolder: hasRelation } = basicDetails;
    const [address, setAddress] = useState(contactPerson.physicalAddress);

    // useForm utilities functions
    const {
        handleSubmit,
        register,
        formState: { errors, isSubmitted },
    } = useForm({
        resolver: yupResolver(ContactPersonalValidation),
    });
    const handleEditorChange = (data) => {
        setDescription(data);
    };
    const fields = getContactPersonalFields(contactPerson, errors, register);

    const updateStakeholderCacheAndAdvanceCurrStep = async ({
        data: stakeholder,
    }) => {
        await updateCache(
            queryKeys.STAKEHOLDERS,
            stakeholder,
            cacheActions.UPDATE
        );
        advanceCurrentStep();
    };

    const { mutate: updateStakeholder } = useMutation(
        "update-list-details",
        updateStakeholderDetails,
        {
            onSuccess: updateStakeholderCacheAndAdvanceCurrStep,
            onError: (e) => {
                toast.error("Failed to update list details, please try again");
            },
        }
    );

    const advanceCurrentStep = () => {
        const newStep = hasRelation ? currentStep + 1 : currentStep + 2;
        setCurrentStep(newStep);
        saveValueToLocalStorage(stakeholderLSKeys.CURRENT_STEP, newStep);
        toast.success("Data has been saved successfully...");
    };

    const updateStakeholderWithContactPersonInfo = (payload) => {
        return updateStakeholder({
            ...{
                ...basicDetails,
                description: JSON.stringify(basicDetails.description),
            },
            contactPerson: {
                ...payload,
                description: JSON.stringify(payload.description),
            },
        });
    };

    const setCurrentStepAndSaveToLocalStorage = () => {
        const newStep = currentStep - 1;
        setCurrentStep(newStep);
        saveValueToLocalStorage("currentStep", newStep);
    };

    const onSubmit = (formData) => {
        if (isEmptyDescription(description) && address) return;
        const payload = {
            ...formData,
            description,
            physicalAddress: address,
        };

        saveValueToLocalStorage(stakeholderLSKeys.CONTACT_PERSON, payload);
        if (isUpdatingStakeholder) {
            return updateStakeholderWithContactPersonInfo(payload);
        }
        advanceCurrentStep();
    };

    return (
        <div className={" space-y-6"}>
            <FormHeader
                title={"Contact Person"}
                description={`
                    Contact details of the person who is going to be the centre
                    of communication with stakeholders
                `}
            />

            <form
                onSubmit={handleSubmit(onSubmit)}
                action="src/pages/general/stakeholder/form/stakeholder/steps"
            >
                <div className="py-3 space-y-5 pr-40">
                    {fields.map((props) => {
                        return props?.options ? (
                            <UnstableSelect props={props} />
                        ) : (
                            <UnstableInput props={props} />
                        );
                    })}

                    <ContactPersonAddress
                        defaultAddress={contactPerson.physicalAddress}
                        onSelect={setAddress}
                    />

                    <div className="p space-y-2">
                        <p className="text-primary/80 tracking-wider">
                            Description
                        </p>
                        <EditorJS
                            isError={
                                isSubmitted && isEmptyDescription(description)
                            }
                            onChange={handleEditorChange}
                            defaultValue={contactPerson.description}
                        />
                    </div>

                    <ActionButtons
                        onPrevious={setCurrentStepAndSaveToLocalStorage}
                        nextBtnLabel={isUpdatingStakeholder ? "SAVE" : "NEXT"}
                    />
                </div>
            </form>
        </div>
    );
};
export default ContactPerson;
