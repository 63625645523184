import {useState} from "react";

const useUploadFileToS3 = (file, callback) => {
    const [response, setResponse] = useState({});
    const [isUploading, setIsUploading] = useState(false)
    let isConfigUpdate = false;

    async function uploadToS3Bucket(stream, credential, cd) {
        try {
            if (!window.AWS) {
                return
            }

            if (!isConfigUpdate) {
                window.AWS.config.update(({region: credential.region}));
                isConfigUpdate = true;
            }

            let s3 = new window.AWS.S3({
                credentials: new window.AWS.Credentials({
                    apiVersion: 'latest',
                    accessKeyId: credential.accessKeyId,
                    dirname: 'test',
                    secretAccessKey: credential.secretAccessKey,
                    signatureVersion: credential.signatureVersion,
                    region: credential.region,
                    Bucket: credential.Bucket
                })

            });
            return await s3.upload({
                Bucket: credential.Bucket,
                Key: getFilename(file.name),
                ContentType: file.type,
                Body: stream
            }).on("httpUploadProgress", function (progress) {
                setIsUploading(true)
                cd(getUploadingProgress(progress.loaded, progress.total));
            }).promise();
        } catch (error) {
            setIsUploading(false)
            console.log(error)
        }
    }

    function getUploadingProgress(uploadSize, totalSize) {
        let uploadProgress = (uploadSize / totalSize) * 100;
        return Number(uploadProgress.toFixed(0));
    }

    async function uploadMedia() {
        let credentialRequest = {
            accessKeyId: process.env.REACT_APP_AWS_ACCESS_ID,
            secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
            signatureVersion: process.env.REACT_APP_AWS_VERSION,
            region: process.env.REACT_APP_AWS_REGION,
            Bucket: process.env.REACT_APP_AWS_BUCKET_NAME
        };
        let mediaStreamRequest = getFile(file)
        const [mediaStream] = await Promise.all([
            mediaStreamRequest
        ])
        return await uploadToS3Bucket(mediaStream, credentialRequest, callback);
    }

    const getFile = async (file) => {
        return new Promise((resolve, reject) => {
            let reader = new FileReader();
            reader.onload = (e) => {
                resolve(e.target.result);
            };
            reader.onerror = (err) => {
                reject(false);
            };
            reader.readAsArrayBuffer(file);
        });
    }


    const getFilename = (file) => {
        const [filename, fileExtension] = file.split('.');
        const currentDate = new Date().toDateString().split(' ').join('-');
        const currentTime = new Date().toTimeString().slice(0, 8).split(':').join('-');
        return `${filename}-date-${currentDate}-time-${currentTime}.${fileExtension}`
    }

    const upload = async () => {
        setResponse(await uploadMedia());
        setIsUploading(false)
    }

    return {response, isUploading, upload};
}

export default useUploadFileToS3;




