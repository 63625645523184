// Third-party imports
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";

//  Local imports
import { FormContext } from "../index";
import EditorJS from "../../../../../../components/editorjs/editor";
import {
    register as registerStakeholder,
    updateStakeholderDetails,
} from "provider/api";
import DynamicInput from "components/daynamic-input";

import {
    getValueFromLocalStorage,
    saveValueToLocalStorage,
} from "hooks/useLocalStorageState";
import { isEmptyDescription } from "utils";
import useNotification from "../../../../../../hooks/useNotification";
import { slugify } from "../../../../../../utils";
import useGlobalContext from "hooks/useGlobalContext";
import ActionButtons from "../_partials/action-buttons";
import {
    cacheActions,
    localStorageKeys,
    queryKeys,
} from "../../../../../../constants";
import clearStakeholderForm from "../_partials/clear-stakeholder-form";
import useUpdateCache from "../../../../../../hooks/useUpdateCache";

const GoalsAndConcern = () => {
    const { stakeholderFrom: stakeholderLSKeys } = localStorageKeys;
    const navigate = useNavigate();
    const { setNotification } = useNotification();
    const { projectId } = useGlobalContext();
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [goalsHasError, setGoalsHasError] = useState(false);
    const updateCache = useUpdateCache();

    // data from local-storage
    const initialConcern = getValueFromLocalStorage(
        stakeholderLSKeys.CONCERN,
        {}
    );

    const [goals, setGoals] = useState(
        getValueFromLocalStorage(stakeholderLSKeys.GOALS, [])
    );
    const isUpdatingStakeholder = getValueFromLocalStorage(
        stakeholderLSKeys.IS_UPDATING_DETAILS,
        false
    );

    const [concern, setConcern] = useState(initialConcern);
    const [currentStep, setCurrentStep] = useContext(FormContext);

    const handleSuccess = async ({ data: newStakeholder }) => {
        await updateStakeholderCacheAndRedirectToOverviewPage(newStakeholder);
        await showSuccessNotificationAndClearForm();
    };

    const showSuccessNotificationAndClearForm = async () => {
        setNotification("Stakeholder is created successfully");
        await clearStakeholderForm();
    };

    const updateStakeholderCacheAndRedirectToOverviewPage = async (
        stakeholder
    ) => {
        await updateCache(
            queryKeys.STAKEHOLDERS,
            stakeholder,
            cacheActions.UPDATE
        );
        navigate(`/stakeholders/${slugify(stakeholder.name)}/overview`, {
            replace: true,
        });
    };

    //  send post request to register new list
    const { mutate: registerStakeholderAndClearForm, isLoading } = useMutation(
        registerStakeholder,
        {
            onSuccess: handleSuccess,
        }
    );

    // send put request to update list details
    const { mutate: updateStakeholder } = useMutation(
        "update-list-details",
        updateStakeholderDetails,
        {
            onSuccess: handleSuccess,
            onError: (e) => {
                console.log("UserMessage", e);
            },
        }
    );

    const buildStakeholderPayload = () => {
        const basicDetails = getValueFromLocalStorage(
            stakeholderLSKeys.BASIC_DETAILS,
            {}
        );

        const relationshipHolder = getValueFromLocalStorage(
            stakeholderLSKeys.RELATION_SHIP_HOLDER,
            {}
        );

        const contactPerson = getValueFromLocalStorage(
            stakeholderLSKeys.CONTACT_PERSON,
            {}
        );
        const levelOfInterest = getValueFromLocalStorage(
            stakeholderLSKeys.LEVEL_OF_INTEREST,
            {}
        );
        const levelOfInfluence = getValueFromLocalStorage(
            stakeholderLSKeys.LEVEL_OF_INFLUENCE,
            {}
        );
        const { properties, geometry } = basicDetails.location;
        const { region, district, ward, street, postalCode } = basicDetails;

        const payload = {
            ...basicDetails,
            goals,
            project: projectId,
            concern: JSON.stringify(concern),
            levelOfInterest: levelOfInterest.name,
            levelOfInfluence: levelOfInfluence.name,
            description: JSON.stringify(basicDetails.description),

            location: {
                name: properties.display_name,
                latitude: geometry.coordinates[1],
                longitude: geometry.coordinates[0],
            },

            address: {
                street,
                ward,
                district,
                region,
                postalCode,
                country: properties.address.country,
            },
        };
        if (payload.type === "Individual") {
            payload.company = {
                // email: "user@mail.test",
                name: basicDetails?.organization,
                stakeholderPosition: basicDetails.position,
            };
        }

        if (payload.hasContactPerson) {
            payload.contactPerson = {
                ...contactPerson,
                concern: JSON.stringify(contactPerson.concern),
                description: JSON.stringify(contactPerson.description),
                communicationMedias: contactPerson.communicationMedias?.filter(
                    (media) => media.name !== "" && media.isSelected
                ),
            };
        }

        if (payload.hasRelationshipHolder) {
            payload.relationshipHolder = relationshipHolder;
        }
        return payload;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsSubmitted(true);

        if (!isEmptyDescription(concern) && goals.length && !goalsHasError) {
            setIsSubmitted(false);
            const payload = buildStakeholderPayload();
            console.log("passed", payload);
            const action = isUpdatingStakeholder
                ? updateStakeholder
                : registerStakeholderAndClearForm;
            action(payload);
        }
    };

    const handleEditorChange = (cleanData) => {
        setConcern(cleanData);
        saveValueToLocalStorage("concern", cleanData);
    };

    const inputClasses = " border-[1.8px] h-12 rounded";
    const setGoalsAndStoreToLocalStorage = (goalList = []) => {
        setGoals(goalList);
        saveValueToLocalStorage("goals", goalList);
    };

    const isGoalEmpty = () => {
        return goals.map((goal) => {
            return !goal;
        });
    };
    const validateGoal = (goal = "") => {
        return goal.split(" ").length > 2;
    };

    const dynamicInputProps = {
        initialInputs: goals,
        buttonLabel: "Add Goal",
        setErrorHandle: setGoalsHasError,
        inputClasses: inputClasses,
        isError: isGoalEmpty() && isSubmitted,
        valueHandler: setGoalsAndStoreToLocalStorage,
        validator: validateGoal,
    };

    const handlePreviousStep = () => {
        const previousStep = currentStep - 1;
        setCurrentStep(previousStep);
        saveValueToLocalStorage(stakeholderLSKeys.CURRENT_STEP, previousStep);
    };
    return (
        <div className={"px-10 space-y-12"}>
            <div className={"space-y-2"}>
                <p className={"text-primary text-xl tracking-wider"}>
                    Goals & Concern
                </p>
            </div>

            <div className={"space-y-10"}>
                <form onSubmit={handleSubmit} className={"pr-14 space-y-2 "}>
                    <div>
                        <label
                            htmlFor={"concern"}
                            className={"text-primary/80 tracking-wider"}
                        >
                            What are the goals of the stakeholder in the
                            project?
                        </label>
                        <DynamicInput props={dynamicInputProps} />
                    </div>
                </form>

                <div className={" pr-14 space-y-2"}>
                    <p className={"text-primary/80 tracking-wider"}>
                        What are the concern of the stakeholder in the project
                    </p>

                    <EditorJS
                        isError={isEmptyDescription(concern) && isSubmitted}
                        onChange={handleEditorChange}
                        defaultValue={concern}
                    />
                </div>

                <ActionButtons
                    isLoading={isLoading}
                    onPrevious={handlePreviousStep}
                    nextBtnLabel={
                        isUpdatingStakeholder ? "SAVE CHANGES" : "COMPLETE"
                    }
                    onNext={handleSubmit}
                />
            </div>
        </div>
    );
};
export default GoalsAndConcern;
