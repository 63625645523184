import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import { useQuery } from "react-query";
import { getProjects, getWorkspaces } from "provider/api";
import { queryKeys } from "../../../constants";

const ProjectManagementLayout = () => {
    const [showForm, setShowForm] = useState(false);
    const [activeProject, setActiveProject] = useState({});
    const [projectList, setProjectList] = useState([]);
    const [workspaces, setWorkspaces] = useState([]);
    const [showWorkspaceForm, setShowWorkspaceForm] = useState(false);

    // send get request to get a list of project available in the system
    const { isLoading } = useQuery(queryKeys.PROJECTS, getProjects, {
        onSuccess: ({ data: projects }) => {
            setProjectList(projects);
        },
    });

    // send get request to retrieve the project's workspaces
    const { isLoading: isLoadingWorkspaces } = useQuery(
        queryKeys.WORKSPACES,
        getWorkspaces,
        {
            onSuccess: ({ data: _workspaces }) => {
                setWorkspaces(_workspaces);
            },
        }
    );

    const getWorkspaceByName = (name) => {
        const _name = name.replace(/[^a-zA-Z ]/g, " ");
        return workspaces.find((workspace) => {
            return workspace?.name.toLowerCase() === _name;
        });
    };
    return (
        <Outlet
            context={{
                showForm,
                setShowForm,
                projectList,
                isLoading,
                setActiveProject,
                activeProject,
                showWorkspaceForm,
                setShowWorkspaceForm,
                isLoadingWorkspaces,
                workspaces,
                setWorkspaces,
                getWorkspaceByName,
            }}
        />
    );
};

export default ProjectManagementLayout;
