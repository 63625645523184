import {GPSIcon} from "svg";
import {ChevronRightIcon} from "@heroicons/react/outline";
import {useCommitmentAnalytics} from "hooks";
import Loading from "./loading";

const CommitmentCompletionRate = () => {
    const {isLoading, commitmentAnalytics} = useCommitmentAnalytics();
    if (isLoading) {
        return <Loading/>
    }

    const {general} = commitmentAnalytics;
    const getCompletedRate = () => {
        return Math.floor(Math.round(general?.completed * 100 / general?.total));
    }

    return (
        <div className={'space-y-2 '}>
            <div className={'h-40 w-full  center flex-col p-5  space-y-4 bg-secondary rounded-[4px]'}>
                <div className={'items-between text-white w-full'}>
                    <p className={'text-[3.5rem] font-semibold'}>{general?.total ?? 0}</p>
                    <GPSIcon className={'h-10 w-10'}/>
                </div>

                <div className={'items-between w-full relative '}>
                    <p className={'text-[#DFE9EB] -mt-3 font-light text-sm'}>
                        Total Number <br/> Of Commitments
                    </p>

                    <div className={'h-8 w-8 -mt-3 rounded-[2px] center bg-white'}>
                        <ChevronRightIcon className={'h-6 w-6 text-secondary'}/>
                    </div>
                </div>
            </div>

            <div style={{height: "calc(100% - 10.5rem)"}}
                 className={' bg-[#DFE9EB] w-full py-5 px-7 flex w-72 flex-col justify-center rounded-[5px] space-y-5 border-[1px] border-[#AABEC8]'}>

                <div className={'h-14 bg-[#628799] w-14'}>
                </div>
                <p className={'text-[#002F4F] tracking-wide text-[15.5px]'}>Commitment Completion <br/>Progress</p>
                <p className={'text-[#9CABAE] py-4 flex space-x-2 items-center'}>
                    <span className={'text-[2.50rem] font-semibold'}>
                        {general?.completed}
                    </span>
                    <span className={'text-[13px] font-light'}>Commitment <br/>
                              Completed</span>
                </p>

                <div className={'space-y-2 pt-2'}>
                    <p className={'items-between text-[15.5px]'}>
                        <span className={'font-light'}>Completion rate</span>
                        <span className={'font-semibold text-lg text-[#151D41]'}>{getCompletedRate()}%</span>
                    </p>

                    <div className={'bg-white rounded-full h-3 overflow-hidden'}>
                        <div style={{width: `${getCompletedRate()}%`}}
                             className={'h-full bg-secondary rounded-full'}></div>
                    </div>

                </div>
            </div>
        </div>
    )
}
export default CommitmentCompletionRate;