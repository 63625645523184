const SouthPemba = ({ color, isLoading = false }) => {
    return (
        <path
            className={`${isLoading ? "animate-pulse" : ""}`}
            id="Path_446"
            data-name="Path 446"
            d="M634.728,235.664l-.42-.208-.528.55.2-.8.111-.252-.229-.291-.306-.192-.391-.016-.464.252.089-.434.06-.179.143-.236-.159-.466.051-.556.2-.415.3-.035.462.22.21-.134-.031-.339-.248-.4-.337-.2-1.266-.485.115-.374.541-.437.165-.405-.56.268-.318-.725.21-.568.528-.185.681.405.153-.63-.439-.367-.637-.342-.439-.536-.019-.453.238-1.1.048-.6.089.2.093.163.111.115.118.038.3.172.213.374.13.377.038.172.433-.06.147-.291.082-.322.241-.154.2.137.63.63.133.182,1.8,1.035.328.307.025.45.1.393.162.143.258-.307-.028-.316-.15-.377.006-.437.465-.476.042.288.136.294.057.223.321-.645.229-.786.086-.741-.076-.511.528.345v.8l-.1.936.442,1.469-.439.709-.7.55-.528.262.194.7-.124.76-.238.77-.124.744.376,1.176.035.211.36-.166.032-.374-.07-.383.063-.2.5.217.063.69-.267,1.508,0,.464-1.1.369-.743.39-.776-.162-.129-.552.129-.552-.323-.455-1.131-.032Z"
            transform="translate(-627.946 -225.009)"
            fill={color}
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="0.5"
        />
    );
};
export default SouthPemba;
