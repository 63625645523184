// import {useState} from 'react';
import PermissionItem from "./permission-item";
// import {useRoleFromContext} from "../form/add-role-form";

const SelectedPermissions = ({permissions}) => {
    // const [showSuggestions, setShowSuggestions] = useState(true);
    // const {permissions} = useRoleFromContext();

    if (!permissions.length) return;
    return (
        <div className={'flex flex-wrap gap-x-1 gap-y-2 max-h-[15rem] overflow-y-auto'}>
            {permissions.map((permission) => (
                <PermissionItem permission={permission} key={permission?._id}/>
            ))}
        </div>
    )
}
export default SelectedPermissions;