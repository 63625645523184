import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {CheckIcon} from "@heroicons/react/solid";

const EventChannelCard = ({context = {}, isActive}, ...props) => {
    const {icon, name, description} = context;
    return (
        <div {...props}
             className={`border enable-transition cursor-pointer py-2.5 ${isActive ? "bg-secondary text-white" : "bg-white hover:bg-secondary/10 hover:border-secondary text-black"} px-6 items-between rounded shadow-base`}>
            <div>
                <div className={`flex items-center ${isActive ? "text-white " : "text-secondary"} space-x-2`}>
                    <FontAwesomeIcon icon={icon} className={'h-5 w-5'}/>
                    <h6>{name}</h6>
                </div>
                <p className={`${isActive ? "text-[#cae2ed]" : "text-dark2/60"}`}><small>
                    {description}
                </small></p>
            </div>
            <div>
                <div
                    className={`h-7 w-7 text-white center rounded-full ${isActive ? "opacity-100" : "opacity-0"} bg-white/20`}>
                    <CheckIcon className={'h-5 w-5'}/>
                </div>
            </div>
        </div>
    )
}
export default EventChannelCard;