const EmptyState = () => {
    return (
        <div className={" w-ful h-4/5  flex justify-center items-center "}>
            <p className={"text-sm text-black/40"}>
                This stakeholder does not have relationship holders
            </p>
        </div>
    );
};
export default EmptyState;
