import SearchBox from "components/search-box";
import Button from "components/Button";
import React, {Fragment, useState} from "react";
import {useOutletContext} from "react-router-dom";
import WorkspaceListItem from "components/workspace/workspace-list-item";
import CreateWorkspace from "../form/create-workspace";

export const WorkspaceList = () => {
    const [showForm, setShowForm] = useState(false);
    const {
        workspaces = []
    } = useOutletContext()
    const handleSearchResult = () => {
        // ...
    }
    return (
        <Fragment>
            <CreateWorkspace showForm={showForm} setShowForm={setShowForm}/>
            <section className={'min-h-full border bg-white rounded-lg  py-8'}>
                <header className={'flex justify-between px-10'}>
                    <div className={'space-y-2'}>
                        <h1 className="text-3xl font-semibold">
                            Workspace List
                        </h1>

                    </div>

                    <div className={'flex items-center space-x-3'}>
                        <SearchBox
                            searchContext={{
                                data: [],
                                searchFun: handleSearchResult,
                                searchProperty: "name"
                            }}

                            inputClass={'text-sm'}
                            wrapperClass={'h-9'}
                        />
                        <Button onClick={() => setShowForm(true)}>Create Workspace</Button>
                    </div>
                </header>

                {/* projects */}
                <div>
                    {/* table header */}
                    <div
                        className="grid grid-cols-6 px-5 py-2.5 mt-5 text-sm font-semibold uppercase text-black/60 bg-secondary/10">
                        <div className="col-span-3 ">Name</div>
                        <div className="flex justify-center">TOTAL PROJECTS</div>
                        <div className="flex justify-center">Created On</div>
                        <div className="flex justify-center"></div>
                    </div>

                    {/* table body */}
                    <div className="divide-y-[1px]">
                        {workspaces.map((workspace) => (
                            <WorkspaceListItem key={workspace?._id} workspace={workspace}/>
                        ))}
                    </div>
                </div>
            </section>
        </Fragment>
    )
}
export default WorkspaceList;
