import { Fragment } from "react";
import {
    Route,
    Routes,
    useLocation,
    useNavigate,
    useParams,
} from "react-router-dom";
import Calender from "components/calender";
import useEvent from "hooks/useEvent";
import ToggleView from "./toggle-view";
import EmptyState from "./empty-state/empty-state";
import EventListView from "./list-view";

const EventList = () => {
    const { type } = useParams();
    const { pathname } = useLocation();
    const isListView = new RegExp(/\/list\/?$/).test(pathname);
    const eventType = `${type}Events`;

    const { [eventType]: events = [], isLoading } = useEvent();
    const navigate = useNavigate();

    const handleToggle = () => {
        if (!isListView) {
            return navigate(`/engagement/activities/events/${type}/list`);
        }
        return navigate(`/engagement/activities/events/${type}`);
    };

    const borderColor = {
        today: "#628799",
        upcoming: "#6eb8eb",
        completed: "#70CCBF",
        overdue: "#E8716F",
    };
    return (
        <div
            className={`px-6 py-12 border-[1.4px] bg-white space-y-4 border-[#E2E2E2]`}
            style={{ borderTopColor: borderColor[type] }}
        >
            {!isLoading && events.length < 1 ? (
                <EmptyState eventType={type} />
            ) : (
                <Fragment>
                    <ToggleView
                        checked={!isListView}
                        onToggle={handleToggle}
                        showCalendarView={!isListView}
                    />

                    <Routes>
                        <Route
                            index
                            element={
                                <div className={"h-[45rem]"}>
                                    <Calender
                                        isLoading={isLoading}
                                        daysHeaderBgColor={"#fff"}
                                        events={events}
                                        daysClassName={"bg-[#EEEDF0]/30"}
                                        showCreateMeetingButton={true}
                                        controllerPosition={"right"}
                                    />
                                </div>
                            }
                        />

                        <Route
                            path={"list"}
                            element={
                                <EventListView
                                    isLoading={isLoading}
                                    events={events}
                                />
                            }
                        />
                    </Routes>
                </Fragment>
            )}
        </div>
    );
};
export default EventList;
