import { useQueryClient } from "react-query";
import { cacheActions } from "../constants";

const useUpdateCache = () => {
    const queryClient = useQueryClient();
    return (queryKey, newData, action) => {
        //  update cache after new subproject is created
        if (action === cacheActions.ADD) {
            try {
                queryClient.setQueryData(queryKey, (oldQueryData) => {
                    return {
                        ...oldQueryData,
                        data: [...oldQueryData.data, newData],
                    };
                });
            } catch {
                // Todo handle oldQueryData is undefined error
            }
        }

        // update cache after new subproject is updated
        else if (action === cacheActions.UPDATE) {
            try {
                queryClient.setQueryData(queryKey, (oldQueryData) => {
                    return {
                        ...oldQueryData,
                        data: oldQueryData.data.map((oldData) => {
                            if (oldData._id === newData._id) {
                                return newData;
                            }
                            return oldData;
                        }),
                    };
                });
            } catch {
                // Todo: handle oldQueryData is undefined error
            }
        } else
            throw new Error(
                `cache actions must be either "${cacheActions.ADD}" or "${cacheActions.UPDATE}"`
            );
    };
};
export default useUpdateCache;
