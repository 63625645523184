import PropsTypes from "prop-types";
import { randomId } from "../../utils";
import { twMerge } from "tailwind-merge";

const Checkbox = ({
    name,
    onChange,
    register,
    className,
    labelClassName,
    label,
    defaultChecked = false,
}) => {
    const id = randomId();

    const handleChange = ({ target }) => {
        if (handleChange) {
            onChange(target.checked);
        }
    };
    return (
        <div className={"flex gap-2 items-center"}>
            <input
                onChange={handleChange}
                {...(register ? register(name) : {})}
                defaultChecked={defaultChecked}
                id={id}
                className={twMerge(
                    " h-5 w-5 cursor-pointer border-[1.4px] border-[#D2D4DA] checked:bg-accent rounded focus:outline-none focus:border-accent checked:text-accent checked:hover:bg-accent/90 focus:ring-[#5D9EE9]/30",
                    className
                )}
                type="checkbox"
                name={name ?? ""}
            />
            <label
                className={twMerge("cursor-pointer", labelClassName)}
                htmlFor={id}
            >
                {" "}
                {label}{" "}
            </label>
        </div>
    );
};

Checkbox.propTypes = {
    onChange: PropsTypes.func.isRequired,
    name: PropsTypes.string,
    label: PropsTypes.string.isRequired,
    defaultChecked: PropsTypes.bool,
};
export default Checkbox;
