const PieChartIllustrator = ({ className }) => {
    return (
        <svg
            id="undraw_segmentation_re_gduq"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            viewBox="0 0 161.474 97.991"
        >
            <path
                id="Path_11"
                data-name="Path 11"
                d="M387.452,235.62H248.725a2,2,0,0,0-1.993,1.993v94.005a2,2,0,0,0,1.993,1.993H387.452a1.991,1.991,0,0,0,1.515-.7.465.465,0,0,0,.043-.055,1.866,1.866,0,0,0,.286-.482,1.945,1.945,0,0,0,.151-.757V237.613a2,2,0,0,0-2-1.993Zm1.426,96a1.411,1.411,0,0,1-.235.782,1.473,1.473,0,0,1-.539.484,1.413,1.413,0,0,1-.651.158H248.725a1.426,1.426,0,0,1-1.424-1.424V237.613a1.426,1.426,0,0,1,1.424-1.424H387.452a1.426,1.426,0,0,1,1.426,1.424Z"
                transform="translate(-246.732 -235.62)"
                fill="#3f3d56"
            />
            <rect
                id="Rectangle_17"
                data-name="Rectangle 17"
                width="142.145"
                height="0.57"
                transform="translate(0.284 7.987)"
                fill="#3f3d56"
            />
            <circle
                id="Ellipse_1"
                data-name="Ellipse 1"
                cx="1.709"
                cy="1.709"
                r="1.709"
                transform="translate(3.417 2.564)"
                fill="#3f3d56"
            />
            <circle
                id="Ellipse_2"
                data-name="Ellipse 2"
                cx="1.709"
                cy="1.709"
                r="1.709"
                transform="translate(8.331 2.564)"
                fill="#3f3d56"
            />
            <circle
                id="Ellipse_3"
                data-name="Ellipse 3"
                cx="1.709"
                cy="1.709"
                r="1.709"
                transform="translate(13.245 2.564)"
                fill="#3f3d56"
            />
            <path
                id="Path_12"
                data-name="Path 12"
                d="M301.34,403.451h-5.028a1.03,1.03,0,0,1-1.028-1.028v-5.028a1.03,1.03,0,0,1,1.028-1.028h5.028a1.03,1.03,0,0,1,1.028,1.028v5.028A1.03,1.03,0,0,1,301.34,403.451Z"
                transform="translate(-284.188 -359.628)"
                fill="#628799"
            />
            <path
                id="Path_13"
                data-name="Path 13"
                d="M301.34,460.451h-5.028a1.03,1.03,0,0,1-1.028-1.028v-5.028a1.03,1.03,0,0,1,1.028-1.028h5.028a1.03,1.03,0,0,1,1.028,1.028v5.028A1.03,1.03,0,0,1,301.34,460.451Z"
                transform="translate(-284.188 -403.601)"
                fill="#3f3d56"
            />
            <path
                id="Path_14"
                data-name="Path 14"
                d="M301.34,517.451h-5.028a1.03,1.03,0,0,1-1.028-1.028v-5.028a1.03,1.03,0,0,1,1.028-1.028h5.028a1.03,1.03,0,0,1,1.028,1.028v5.028A1.03,1.03,0,0,1,301.34,517.451Z"
                transform="translate(-284.188 -447.574)"
                fill="#ccc"
            />
            <path
                id="Path_15"
                data-name="Path 15"
                d="M380.538,409.423H356.312a1.028,1.028,0,0,1,0-2.057h24.226a1.028,1.028,0,1,1,0,2.057Z"
                transform="translate(-330.475 -368.114)"
                fill="#ccc"
            />
            <path
                id="Path_16"
                data-name="Path 16"
                d="M380.538,466.423H356.312a1.028,1.028,0,0,1,0-2.057h24.226a1.028,1.028,0,1,1,0,2.057Z"
                transform="translate(-330.475 -412.087)"
                fill="#ccc"
            />
            <path
                id="Path_17"
                data-name="Path 17"
                d="M380.538,523.423H356.312a1.028,1.028,0,0,1,0-2.057h24.226a1.028,1.028,0,1,1,0,2.057Z"
                transform="translate(-330.475 -456.06)"
                fill="#ccc"
            />
            <path
                id="Path_18"
                data-name="Path 18"
                d="M818.426,384.076a2.3,2.3,0,0,1,.323.18L829,379.881l.366-2.7,4.1-.025-.242,6.193-13.53,3.578a2.428,2.428,0,0,1-.1.276,2.339,2.339,0,1,1-1.161-3.127Z"
                transform="translate(-685.225 -344.808)"
                fill="#ffb8b8"
            />
            <path
                id="Path_19"
                data-name="Path 19"
                d="M641.168,380.895h-2.8l-1.333-10.807h4.135Z"
                transform="translate(-491.442 -285.505)"
                fill="#ffb8b8"
            />
            <path
                id="Path_20"
                data-name="Path 20"
                d="M622.623,413.872h5.4v3.4h-8.806A3.4,3.4,0,0,1,622.623,413.872Z"
                transform="translate(-477.701 -319.284)"
                fill="#2f2e41"
            />
            <path
                id="Path_21"
                data-name="Path 21"
                d="M685.338,377.606l-2.788.275-2.386-10.625,4.115-.405Z"
                transform="translate(-524.715 -283.009)"
                fill="#ffb8b8"
            />
            <path
                id="Path_22"
                data-name="Path 22"
                d="M3.4,0h5.4V3.4H0A3.4,3.4,0,0,1,3.4,0Z"
                transform="translate(152.377 94.605) rotate(-5.625)"
                fill="#2f2e41"
            />
            <circle
                id="Ellipse_4"
                data-name="Ellipse 4"
                cx="5.613"
                cy="5.613"
                r="5.613"
                transform="translate(144.892 9.683)"
                fill="#ffb8b8"
            />
            <path
                id="Path_23"
                data-name="Path 23"
                d="M862.16,510.089a1.022,1.022,0,0,1-1.009-.845c-1.45-8.051-6.191-34.374-6.3-35.1a.335.335,0,0,1,0-.051v-1.963a.341.341,0,0,1,.064-.2l.626-.877a.338.338,0,0,1,.261-.143c3.57-.167,15.263-.658,15.828.048h0c.567.709.367,2.858.321,3.282l0,.044,5.253,33.6a1.031,1.031,0,0,1-.849,1.174l-3.281.541a1.033,1.033,0,0,1-1.149-.707c-1.015-3.243-4.418-14.151-5.6-18.372a.114.114,0,0,0-.224.032c.059,4.024.2,14.289.25,17.835l.005.382a1.033,1.033,0,0,1-.935,1.037l-3.164.287C862.224,510.087,862.191,510.089,862.16,510.089Z"
                transform="translate(-715.862 -416.885)"
                fill="#2f2e41"
            />
            <path
                id="Path_99"
                data-name="Path 99"
                d="M863.439,335a4.694,4.694,0,0,0-1.9,2.741,25.984,25.984,0,0,0-1.116,6.207l-.355,6.308-4.4,16.723c3.814,3.227,6.014,2.494,11.149-.146s5.721.88,5.721.88l1.027-14.229,1.467-15.548a6.894,6.894,0,0,0-1.111-1.068,11.349,11.349,0,0,0-9.7-2.056Z"
                transform="translate(-716.496 -311.887)"
                fill="#b3b3b3"
            />
            <path
                id="Path_24"
                data-name="Path 24"
                d="M872.532,395.382a2.41,2.41,0,0,1,.343.161l10.136-5.073.168-2.748,4.181-.288.224,6.265-13.545,4.479a2.4,2.4,0,1,1-1.507-2.8Z"
                transform="translate(-727.018 -352.737)"
                fill="#ffb8b8"
            />
            <path
                id="Path_101"
                data-name="Path 101"
                d="M921.971,348.9c2.494.88,2.933,10.416,2.933,10.416-2.934-1.614-6.454,1.027-6.454,1.027s-.733-2.494-1.614-5.721a5.606,5.606,0,0,1,1.173-5.281A3.994,3.994,0,0,1,921.971,348.9Z"
                transform="translate(-763.513 -322.779)"
                fill="#b3b3b3"
            />
            <path
                id="Path_102"
                data-name="Path 102"
                d="M891.362,277.585c-.7-.56-1.654.457-1.654.457l-.56-5.036a19.993,19.993,0,0,1-5.735-.14c-2.238-.559-2.588,2.028-2.588,2.028a17.964,17.964,0,0,1-.07-3.147c.14-1.259,1.958-2.518,5.175-3.357s4.9,2.8,4.9,2.8C893.066,272.307,892.062,278.144,891.362,277.585Z"
                transform="translate(-735.824 -260.804)"
                fill="#2f2e41"
            />
            <path
                id="Path_25"
                data-name="Path 25"
                d="M732.9,362.862c.036-.605.057-1.214.057-1.828a30.168,30.168,0,0,0-30.168-30.168v32Z"
                transform="translate(-598.555 -309.098)"
                fill="#628799"
            />
            <path
                id="Path_26"
                data-name="Path 26"
                d="M590.947,374.862v-32a30.168,30.168,0,1,0,30.111,32Z"
                transform="translate(-489.004 -318.356)"
                fill="#3f3d56"
            />
        </svg>
    );
};
export default PieChartIllustrator;
