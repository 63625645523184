import { Fragment, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { useQuery } from "react-query";
import PhaseHeader from "./phase-list-item/phase-header";
import PhaseForm from "./form/phase-form";
import { queryKeys } from "../../../constants";
import { getProjectPhases } from "provider/api";
import useGlobalContext from "hooks/useGlobalContext";
import EmptyState from "./empty-state/empty-state";
import Loading from "./phase-list-item/loading";
import PhaseListItem from "./phase-list-item";

const Phase = () => {
    const [phases, setPhases] = useState([]);
    const [activePhase, setActivePhase] = useState({});

    const { projectId } = useGlobalContext();
    const navigate = useNavigate();

    const { isLoading } = useQuery(
        queryKeys.PROJECT_PHASES,
        () => {
            return getProjectPhases(projectId);
        },
        {
            onSuccess: ({ data: _phases = [] }) => {
                setPhases(_phases);
            },
            onError: ({ message }) => toast.error(message),
            enabled: !!projectId,
        }
    );

    const handleEdit = (phase) => {
        setActivePhase(phase);
        navigate("update");
    };

    return (
        <Fragment>
            <ToastContainer />
            <Routes>
                <Route path={"/create"} element={<PhaseForm toast={toast} />} />
                <Route
                    path={"/update"}
                    element={
                        <PhaseForm activePhase={activePhase} toast={toast} />
                    }
                />
            </Routes>
            <section className={"space-y-5"}>
                <PhaseHeader />

                {phases.length < 1 && !isLoading ? (
                    <EmptyState />
                ) : (
                    <div className={"space-y-6"}>
                        {isLoading ? (
                            <Loading />
                        ) : (
                            phases.map((phase, index) => (
                                <PhaseListItem
                                    onEdit={handleEdit}
                                    key={phase._id}
                                    phase={phase}
                                />
                            ))
                        )}
                    </div>
                )}
            </section>
        </Fragment>
    );
};
export default Phase;
