const Iringa = ({ color, isLoading = false }) => {
    return (
        <path
            className={`${isLoading ? "animate-pulse" : ""}`}
            id="Path_452"
            data-name="Path 452"
            d="M444.235,344.193l.873,3.983.671,1.157,1.152.52.529-.031.869-.157.513.059,1.782.422.824.34.679.558.364.462.158.1.913.2.78.318,1.011.116.582.132.279.129.227.181.1.143.036.14-.154,1.233.085.42.291.359.719.32.606-.1.542-.275.533-.19.323-.012,1.96.19.582.251.129.02.4,0,.816.19-1.919,2.536-2.808,2.589-1.762,3.3-1.426,3.5-2.962.859-3.451-1.024-3.677-.365-3.438,1.271-2.849,2.191-1.228,1.179-.06.42-.02.508.052.68.21.649.251,1.326-.675,1.153-2.267.347-1.261.317-1.45-.306-3.423,2.1-3.027,2.821L423.53,384.8l-1.467,4.1-2.776,2.5-3.62,1.682-1.159,1.114-1.454,5.036.06,1.883-.473,1.789-.84-.717-.186-3.351-.63-.924-4.477-.758-3.572-1.03-3.136.1-.2,1.746.647,1.6.045,1.758-.994,1.458.056.626.707.335.356.8-.145,2.816.473,1.551-2.856.951-3.707-.221-3.707-2.649.436-7.062-9.376-3.309v-1.985L376,392.943l.456-2.581-.634-1.352-.836-1.228-.622-1.606.816-1.642,1.111-1.709,1.435-1.341,1.649-.557,1.382-1.064,1.022-1.715,1.41-3.272.949-1.171,2.15-.643L388,371.728l.945-1.287-1.075-1.1-1.774-.637-1.883.157-1.039-.182.141-1.016-.388-1.2-10.587-1.695-1.669-.549-1.442-1.014-5.786-1.374-2.008-.163-18.191.92-1.5-.2-.566-1.15.339-1.609.715-1.456,1.063-1.413.893-1.529,1.071-2.531.78-.944.557-1.094-.63-.73-1.006-.674,3.431-1.921,3.228-2.2,1.2-1.693,1.341-1.485,1.794.016,1.754.644,1.822.082,1.827-.269.768-.64-.064-1.228,1.972-2.377,11.5-2.405L377,333.021l.448-3.813,1.443-1.164,1.665-.946.671-.513.78-.3.715-.454.59-.574,1.5.132,1.62.409,1.624.189,1.568.482,3.794,2.3,3.939-.615.8-.847,1.115-.285,1.326,0,1.309-.216,1.875.165,1.834.629.876.476.489.792-.008.622.561.33,1.883.209,1.879.688,2.793,1.543,1.382.112,1.257-.092,3.438.849.4.1.7.454.582.259.679.12,1.547.538.816.151,2.262-.546.566.153.311.157.444.037.857-.047.525.053,3.536,1.413.756.124.913.3.46.72.27.89.36.807.2.177.6.368.295.287.448.915.218.3.15.31.109.776.141.3.489.255.521-.065,1.05-.34,1.81-.024.938.19.666.436.246.564Z"
            transform="translate(-175.079 -93.787)"
            fill={color}
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="0.5"
        />
    );
};
export default Iringa;
