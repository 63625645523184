import { Route, Routes } from "react-router-dom";
import { permissions, RequirePermission } from "provider/permission";
import { StakeholderAnalytics } from "pages/analytics";
import GrievanceAnalytics from "pages/analytics/grievance";
import CommitmentAnalytics from "pages/analytics/commitment";
import EngagementAnalytics from "pages/analytics/engagement";

const AnalyticsRoutes = () => {
	return (
		<RequirePermission permission={permissions.READ_ANALYTICS}>
			<Routes>
				<Route
					index
					element={
						<RequirePermission>
							<StakeholderAnalytics />
						</RequirePermission>
					}
				/>
				<Route
					path={"stakeholder"}
					element={
						<RequirePermission>
							<StakeholderAnalytics />
						</RequirePermission>
					}
				/>
				<Route path={"grievances"}>
					<Route
						index
						element={
							<RequirePermission>
								<GrievanceAnalytics />
							</RequirePermission>
						}
					/>
					<Route path="gbv" element={<GrievanceAnalytics />} />
				</Route>
				<Route
					path={"commitments"}
					element={
						<RequirePermission>
							<CommitmentAnalytics />
						</RequirePermission>
					}
				/>
				<Route path={"engagements"} element={<EngagementAnalytics />} />
			</Routes>
		</RequirePermission>
	);
};
export default AnalyticsRoutes;
