import ClosedGrievanceCard from "./closed-grievance-card";
import useGrievanceAnalytics from "../../../../hooks/useGrievanceAnalytics";

const ClosedGrievanceByRiskLevel = () => {
	const { grievanceAnalytic, isLoading } = useGrievanceAnalytics();
	if (isLoading)
		return (
			<div className="bg-white rounded-[7px] p-5 space-y-8">
				<div className="w-56 h-3.5 bg-secondary/[.23] rounded-full"></div>
				<div className="grid grid-cols-3 gap-4">
					{[...Array(3)].map((item, index) => (
						<div
							key={index}
							className="bg-secondary/[.23] animate-pulse rounded-md h-44"
						></div>
					))}
				</div>
			</div>
		);

	const { byRiskLevel: riskLevels = [], general: grievance } =
		grievanceAnalytic;

	const highLevelRisk = riskLevels.find((riskLevel) => {
		return riskLevel.risk === "High";
	});

	const mediumRiskLevel = riskLevels.find((riskLevel) => {
		return riskLevel.risk === "Medium";
	});

	const lowerRiskLevel = riskLevels.find((riskLevel) => {
		return riskLevel.risk === "Low";
	});

	const totalClosedHighRiskGrievances = highLevelRisk?.report?.closed || 0;
	const totalClosedMediumRiskGrievances =
		mediumRiskLevel?.report?.closed || 0;
	const totalClosedLowerRiskGrievances = lowerRiskLevel?.report?.closed || 0;

	const grievances = [
		{
			title: "High risk",
			percentage: Math.floor(
				(totalClosedHighRiskGrievances * 100) / grievance?.total ?? 0
			),
			total: `${totalClosedHighRiskGrievances} Grievances`,
		},
		{
			title: "Medium risk",
			percentage: Math.floor(
				(totalClosedMediumRiskGrievances * 100) / grievance?.total ?? 0
			),
			total: `${totalClosedMediumRiskGrievances} Grievances`,
		},
		{
			title: "Low risk",
			percentage: Math.floor(
				(totalClosedLowerRiskGrievances * 100) / grievance?.total ?? 0
			),
			total: `${totalClosedLowerRiskGrievances} Grievances`,
		},
	];
	return (
		<section className={"bg-white rounded-[7px] space-y-4 p-5"}>
			<p className={"text-[#31394C] font-medium text-[15px]"}>
				Closed grievance by risk level
			</p>

			<div className={"grid grid-cols-3 gap-4"}>
				{grievances.map((grievance) => (
					<ClosedGrievanceCard
						key={grievance.title}
						grievance={grievance}
					/>
				))}
			</div>
		</section>
	);
};
export default ClosedGrievanceByRiskLevel;
