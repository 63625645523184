import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import Router from "./routes";
import NotificationProvider from "provider/notification";
// import {AlertProvider} from "provider/alert/alerts";

const App = () => {
	const queryClient = new QueryClient();
	return (
		<QueryClientProvider client={queryClient}>
			{/*<AlertProvider>*/}
			<NotificationProvider>
				<Router />
				<ReactQueryDevtools
					initialIsOpen={false}
					position={"bottom-right"}
				/>
				{/*</AlertProvider>*/}
			</NotificationProvider>
		</QueryClientProvider>
	);
};

export default App;
