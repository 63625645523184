const Loading = () => {
    return (
        <div className={"space-y-5"}>
            {[...Array(4)].map((item, index) => (
                <div
                    className={
                        "h- border-[1px] bg-[#F9F9F9] border-[#E4E4E4] rounded-[8px]"
                    }
                    key={index}
                >
                    <div className={"p-4 space-y-4"}>
                        <p className={"h-5 w-[33%] skeleton-loading"}></p>
                        <div className={" flex justify-between space-x-20"}>
                            <div className={"space-y-2 w-full"}>
                                <p
                                    className={"h-5 w-full skeleton-loading"}
                                ></p>
                                <p className={"h-5 w-1/2 skeleton-loading"}></p>
                            </div>
                            <p
                                className={
                                    "h-10 w-28 skeleton-loading shrink-0"
                                }
                            ></p>
                        </div>
                    </div>

                    <div
                        className={
                            "bg-gray-100 p-4  flex justify-end space-x-5"
                        }
                    >
                        <div className={"space-y-2 flex flex-col items-end"}>
                            <p className={"h-5 w-24 skeleton-loading"}></p>
                            <p className={"h-5 w-44 skeleton-loading"}></p>
                        </div>

                        <div className={"space-y-2 flex flex-col items-end"}>
                            <p className={"h-5 w-24 skeleton-loading"}></p>
                            <p className={"h-5 w-44 skeleton-loading"}></p>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};
export default Loading;
