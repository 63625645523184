import { UnstableComboboxInput } from "components/combobox";
import { useUsers } from "hooks";
import {
    getValueFromLocalStorage,
    saveValueToLocalStorage,
} from "hooks/useLocalStorageState";
import React, { useEffect, useState } from "react";
import { flatten, isEmptyObject, isIncludedIn } from "utils";
import { useQuery } from "react-query";
import { getSubprojects } from "../../../../provider/api";
import AssignedProjectsSubprojects from "./assigned-projects-subprojects";
import Button from "../../../../components/Button";

const ProjectSetups = ({ projects }) => {
    const {
        setCurrentStep,
        activeUser,
        isCreatingAdminUser: isWBAdmin,
    } = useUsers();
    const [resetSubprojects, setResetSubprojects] = useState(false);
    const [resetProjects, setResetProjects] = useState(false);
    const [subprojects, setSubprojects] = useState([]);
    const [assignments, setAssignments] = useState([]);

    const [selectedProject, setSelectedProject] = useState(
        getValueFromLocalStorage("selectedProject", [])
    );

    const [selectedSubprojects, setSelectedSubprojects] = useState(
        getValueFromLocalStorage("selectedSubprojects", [])
    );
    const [selectedPIU, setSelectedPIU] = useState(
        getValueFromLocalStorage("selectedPIU", {})
    );
    const [isSubmitted, setIsSubmitted] = useState(false);

    useEffect(() => {
        if (!isEmptyObject(activeUser)) {
            setSelectedPIU({
                fullName: activeUser.piu.registeredName,
                _id: activeUser.piu._id,
            });

            setSelectedProject(activeUser.projects);
        }
    }, [activeUser]);

    // send get request to get all subproject based on a selected project
    const { isLoading, refetch: _getSubprojects } = useQuery(
        ["subprojects", selectedProject?._id],
        getSubprojects,
        {
            onSuccess: ({ data }) => {
                console.log(data);
                setResetSubprojects(true);
                setSubprojects(data);
            },
            enabled: false,
        }
    );

    const handleSubprojectChange = (_subprojects) => {
        // Todo:: Update resetSubproject on need rather than update every time projectId change
        setResetSubprojects(false);
        setSelectedSubprojects(_subprojects);
    };

    const handleProjectChange = (_project) => {
        // Todo:: Update resetProject on need rather than update every time projectId change
        setResetProjects(false);
        setSelectedProject(_project);
    };

    const handleSubmit = (e) => {
        // Prevent form submission
        e.preventDefault();

        // Mark the form as submitted
        setIsSubmitted(true);

        // Check if the user is an WBAdmin
        if (isWBAdmin) {
            // Check if both selectedPIU and selectedProject are not empty objects
            if (isEmptyObject(selectedPIU) || isEmptyObject(selectedProject))
                return;

            // Save selectedPIU and selectedProject to local storage
            saveValueToLocalStorage("selectedPIU", selectedPIU);
            saveValueToLocalStorage("selectedProject", selectedProject);
        } else {
            // Check if there are any assignments
            if (!assignments.length) return;

            // Save assignments to local storage
            saveValueToLocalStorage("assignments", assignments);
        }

        // Move to the next step
        setCurrentStep(3);
    };

    useEffect(() => {
        if (!isEmptyObject(selectedProject)) _getSubprojects();
    }, [selectedProject]);

    const handleAdd = () => {
        if (isEmptyObject(selectedProject)) return;

        // Get the IDs of subprojects that have already been assigned
        const existSubprojectsIds = flatten(
            assignments.map(({ subprojects = [] }) =>
                subprojects.map(({ _id }) => _id)
            )
        );

        // Create a new assignment from a selected project and subprojects
        const newAssignment = {
            project: selectedProject,
            subprojects: selectedSubprojects.filter(
                ({ _id }) => !isIncludedIn(_id, existSubprojectsIds)
            ),
        };

        // Reset selected projects and subprojects
        setResetProjects(true);
        setResetSubprojects(true);

        // Check if the selected project is already added to the list
        const alreadyAdded = assignments.some(
            ({ project: { _id } = {} }) => _id === newAssignment.project._id
        );

        // Return early if the selected project has already been added and no new subprojects are selected
        if (alreadyAdded && !newAssignment.subprojects.length) return;

        // Add the new assignment to the list of assigned projects and subprojects
        setAssignments(
            alreadyAdded
                ? assignments.map((assignment) =>
                      // If the project is already added, append the new subprojects to the existing ones
                      assignment.project._id === newAssignment.project._id
                          ? {
                                ...assignment,
                                subprojects: [
                                    ...assignment.subprojects,
                                    ...newAssignment.subprojects,
                                ],
                            }
                          : assignment
                  )
                : [...assignments, newAssignment]
        );
    };

    return (
        <form
            action=""
            className=" flex space-y-4 flex-col justify-between min-h-[20rem] "
            onSubmit={handleSubmit}
        >
            <div className={"space-y-4"}>
                <p
                    className={
                        "text-dark text-sm tracking-wide uppercase font-semibold"
                    }
                >
                    Assign Project And Subprojects
                </p>

                <div className={"space-y-2 max-h-40  overflow-y-auto"}>
                    {assignments.map((assignment) => (
                        <AssignedProjectsSubprojects
                            assignment={assignment}
                            assignments={assignments}
                            setAssignments={setAssignments}
                        />
                    ))}
                </div>

                <div className={`flex  space-x-4`}>
                    <div
                        className={`${
                            !!!subprojects.length && "col-span-2"
                        } w-full`}
                    >
                        <label htmlFor="" className="text-sm text-dark2">
                            Select Project
                        </label>
                        <UnstableComboboxInput
                            className={"text-sm"}
                            name={"project"}
                            hasError={isSubmitted && !assignments.length}
                            selectedOptions={selectedProject}
                            defaultOption={selectedProject}
                            onChange={handleProjectChange}
                            options={projects}
                            displayItemName={"name"}
                            reset={resetProjects}
                        />
                    </div>

                    <div hidden={!!!subprojects.length} className={"w-full"}>
                        <label htmlFor="" className="text-sm text-dark2">
                            Select Subprojects
                        </label>
                        <UnstableComboboxInput
                            className={"text-sm"}
                            name={"subproject"}
                            hasError={isSubmitted && !assignments.length}
                            options={subprojects}
                            displayItemName={"name"}
                            defaultOption={selectedSubprojects}
                            onChange={handleSubprojectChange}
                            isLoading={isLoading}
                            reset={resetSubprojects}
                            multiple
                        />
                    </div>
                    <div>
                        <p className={"opacity-0 pointer-events-none"}>add</p>
                        <Button
                            className={
                                "shrink-0 bg-secondary py-3.5 px-5 uppercase"
                            }
                            hidden={isWBAdmin}
                            type={"button"}
                            onClick={handleAdd}
                        >
                            add
                        </Button>
                    </div>
                </div>
            </div>
            <div className={"items-between space-x-4 col-span-2"}>
                <Button
                    outline={true}
                    type={"button"}
                    onClick={() => setCurrentStep(1)}
                    className={
                        "py-2 text-sm flex border-2 border-secondary items-center space-x-1 px-4 text-dark2 "
                    }
                >
                    Previous
                </Button>

                <Button
                    type={"submit"}
                    className={
                        "py-2 py-2.5 w-full text-sm flex items-center px-4 text-white space-x-2 bg-secondary rounded hover:opacity-90 "
                    }
                >
                    NEXT
                </Button>
            </div>
        </form>
    );
};

export default ProjectSetups;
