import { Route, Routes } from "react-router-dom";
import { RequirePermission, permissions } from "../../provider/permission";
import GrievancePage from "../../pages/general/grievances";
import ClosedComplaints from "../../pages/general/grievances/closed-complaints";
import UnderInvestigationLayout from "../../pages/general/grievances/under-investigation";
import GrievancesProvider from "../../provider/general/grievances";

const GrievanceRoutes = () => {
	return (
		<RequirePermission permission={permissions.READ_GRIEVANCES}>
			<Routes>
				<Route index element={<GrievancePage />} />
				<Route path={"closed"} element={<ClosedComplaints />} />

				<Route
					path="under-investigation/:status"
					element={
						<GrievancesProvider>
							<UnderInvestigationLayout />
						</GrievancesProvider>
					}
				/>
			</Routes>
		</RequirePermission>
	);
};
export default GrievanceRoutes;
