import {Fragment, useEffect} from "react";
import useUserRole from "hooks/useUserRole";
import {useForm} from "react-hook-form";
import {useMutation, useQueryClient} from "react-query";
import {createRole, updateUserRole} from "provider/api";
import {yupResolver} from "@hookform/resolvers/yup";

// components
import Modal from "../../../../components/Modal";
import Input from "../../../../components/form-control/Input";
import Textarea from "../../../../components/form-control/textarea";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/free-solid-svg-icons";
import SpinLoader from "../../../../components/SpinLoader";
import {RoleFormValidation} from "../../../../forms/validation";
import {isEmptyObject} from "../../../../utils";
import {roleTypes} from "../../../../constants";

const AddRoleForm = () => {
    const {
        setShowRoleForm: setShowForm,
        showRoleForm,
        rolePermissions = [],
        activeRole,
        setActiveRole,
    } = useUserRole();
    const {
        handleSubmit,
        register,
        setValue,
        formState: {errors},
    } = useForm({
        resolver: yupResolver(RoleFormValidation),
        defaultValues: {
            name: activeRole?.name,
            description: activeRole?.description,
        },
    });
    const queryClient = useQueryClient();
    const inputClassName = "bg-[#f4f5f5] py-2.5 text-sm";
    const isUpdateMode = !isEmptyObject(activeRole);

    // Todo: Find the best way to set default value to inputs fields
    useEffect(() => {
        if (isUpdateMode) {
            setValue("name", activeRole?.name);
            setValue("description", activeRole?.description);
        } else {
            setValue("name", "");
            setValue("description", "");
        }
    }, [isUpdateMode]); // eslint-disable-line react-hooks/exhaustive-deps

    const {isLoading, mutate: creatNewRole} = useMutation(
        "create-user-role",
        createRole,
        {
            onSuccess: ({data: createdRole}) => {
                updateRoleCache(createdRole);
                setShowForm(false);
            },
        }
    );

    const {isLoading: isUpdatingRole, mutate: updateRole} = useMutation(
        "update-user-role",
        updateUserRole,
        {
            onSuccess: ({data: updatedRole}) => {
                updateRoleCache(updatedRole);
                handleClose();
            },
        }
    );

    //  Get permission-names by using permissions-id returned by response after update role-permissions
    const getPermissions = (permissionsId = []) => {
        return permissionsId.map((permissionId) => {
            const p = rolePermissions.find((permission) => {
                return permission?._id === permissionId;
            });
            return {_id: permissionId, displayName: p?.displayName};
        });
    };

    const updateRoleCache = (role) => {
        if (!isUpdateMode) {
            return queryClient.setQueriesData("user-roles", (oldQueryData) => {
                return {
                    ...oldQueryData,
                    data: [...oldQueryData.data, role],
                };
            });
        } else {
            return queryClient.setQueriesData("user-roles", (oldQueryData) => {
                return {
                    ...oldQueryData,
                    data: [
                        ...oldQueryData.data.map((roleItem) => {
                            if (roleItem?._id === role?._id) {
                                return {
                                    ...role,
                                    permissions: getPermissions(
                                        role?.permissions
                                    ),
                                };
                            }
                            return roleItem;
                        }),
                    ],
                };
            });
        }
    };

    const onSubmit = (formData) => {
        if (isUpdateMode) return handleUpdate(formData);
        //  TODO: Create role under specific PIU
        creatNewRole({
            ...formData,
            type: roleTypes.NORMAL_USER
        });
    };

    const handleUpdate = (formData) => {
        const payload = {
            _id: activeRole?._id,
            ...formData,
        };
        updateRole(payload);
    };

    const handleClose = () => {
        setActiveRole({});
        setShowForm(false);
    };

    const getInnerHTML = () => {
        if (!isUpdateMode) {
            return isLoading ? (
                <Fragment>
                    <SpinLoader color={"#fff"} size={"small"}/>{" "}
                    <span>Creating Role..</span>
                </Fragment>
            ) : (
                <span>Create Role</span>
            );
        } else
            return isUpdatingRole ? (
                <Fragment>
                    <SpinLoader color={"#fff"} size={"small"}/>{" "}
                    <span>Updating Role..</span>
                </Fragment>
            ) : (
                <span>Update Role</span>
            );
    };

    return (
        <Modal isOpen={showRoleForm} onClose={handleClose}>
            <form
                onSubmit={handleSubmit(onSubmit)}
                className={"bg-white  text-dark2 rounded-xl  w-2/6 mt-[10vh]"}
            >
                <header
                    className={
                        "py-3 px-5 bg-gray-300 rounded-t-xl items-between "
                    }
                >
                    <h3 className={"font-medium text-dark"}>
                        {isUpdateMode ? "Update Role" : "Create New Role"}
                    </h3>
                    <button
                        type={"button"}
                        onClick={handleClose}
                        className="-mr-2 border-2 center border-[#192B40]/50 w-6 h-6 bg-[#628799] text-white rounded-full"
                    >
                        <FontAwesomeIcon icon={faXmark}/>
                    </button>
                </header>

                <div className={"p-5 space-y-5 text-sm"}>
                    <div className={"space-y-2"}>
                        <label htmlFor="roleName ">Role Name</label>
                        <Input
                            isError={!!errors?.name?.message}
                            defaultValue={activeRole?.name}
                            register={register}
                            name={"name"}
                            id={"name"}
                            className={inputClassName}
                            placeholder={"eg: Project Manager"}
                        />
                    </div>

                    <div className={"space-y-2"}>
                        <label htmlFor="roleName">Description</label>
                        <Textarea
                            isError={!!errors?.description?.message}
                            defaultValue={activeRole?.description}
                            register={register}
                            name={"description"}
                            id={"description"}
                            className={inputClassName}
                            placeholder={
                                "Project manager can view all projects"
                            }
                        />
                    </div>
                    <div>
                        <button
                            className={
                                "py-2 text-sm px-4 text-white flex items-center space-x-2 bg-primary rounded hover:opacity-90"
                            }
                        >
                            {getInnerHTML()}
                        </button>
                    </div>
                </div>
            </form>
        </Modal>
    );
};
export default AddRoleForm;
