import Modal from "components/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import AccountAndPersonalInfo from "./account-and-personal-info";
import AddressInfo from "./address-info";
import { useMutation, useQuery } from "react-query";
import { getPIUS, getSubprojects, updateUserInfo } from "provider/api";
import {
    removeValuesFromLocalStorage,
    saveValueToLocalStorage,
} from "hooks/useLocalStorageState";
import { useUsers } from "hooks";
import { isEmptyObject } from "utils";
import ProjectSetups from "./project-setup";
import { useState } from "react";
import useGlobalContext from "hooks/useGlobalContext";
import { useAuth } from "../../../../provider/auth/auth";

const UserForm = () => {
    const {
        setShowUserForm,
        showUserForm,
        setActiveUser,
        setCurrentStep,
        currentStep,
        activeUser,
        isCreatingAdminUser: isWBAdmin,
    } = useUsers();
    const isEditMode = !isEmptyObject(activeUser);
    const { projectDetails } = useGlobalContext();

    const [piuList, setPIUList] = useState([]);
    const { authUser } = useAuth();
    const [subprojects, setSubprojects] = useState([]);

    useMutation("update-user", updateUserInfo, {
        onSuccess: ({ data: updatedUser }) => {
            // updateUserCache(updatedUser);
            saveValueToLocalStorage("userBasicInfo", null);
            return handleClose();
        },
    });

    // send get request to get project's subprojects
    useQuery(["subprojects", projectDetails._id], getSubprojects, {
        onSuccess: ({ data }) => {
            setSubprojects(data);
        },
        enabled: !!projectDetails._id && !isWBAdmin,
    });

    // send get-request to get piu-list
    useQuery("c", getPIUS, {
        onSuccess: ({ data: pius }) => {
            setPIUList(pius);
        },
        enabled: isWBAdmin,
    });

    const handleClose = () => {
        setActiveUser({});
        setCurrentStep(1);
        removeValuesFromLocalStorage([
            "userAddressInfo",
            "userBasicInfo",
            "selectedProject",
            "selectedPIU",
            "selectedSubprojects",
        ]);
        setShowUserForm(false);
    };

    const getCurrentForm = () => {
        if (currentStep === 1)
            return (
                <AccountAndPersonalInfo
                    pius={piuList.map(({ _id, registeredName } = {}) => ({
                        _id,
                        registeredName,
                    }))}
                />
            );
        else if (currentStep === 2 && !isWBAdmin)
            return (
                <ProjectSetups
                    subprojects={subprojects}
                    projects={authUser.projects}
                    pius={piuList}
                />
            );
        else return <AddressInfo />;
    };

    return (
        <Modal isOpen={showUserForm} onClose={handleClose}>
            <div className={"mt-[5vh] bg-[#F9F9F9] rounded-xl w-1/2"}>
                <header
                    className={
                        "py-3 px-10 bg-gray-300 rounded-t-xl items-between "
                    }
                >
                    <h3 className={"font-medium text-dark"}>
                        {isWBAdmin
                            ? "Create New Project Admin"
                            : isEditMode
                            ? "Update User Details"
                            : "Create New User"}
                    </h3>
                    <button
                        onClick={handleClose}
                        className="-mr-2 border-2 center border-[#192B40]/50 w-6 h-6 bg-[#628799] text-white rounded-full"
                    >
                        <FontAwesomeIcon icon={faXmark} />
                    </button>
                </header>

                <div className={"px-10 pb-5 pt-4"}>{getCurrentForm()}</div>
            </div>
        </Modal>
    );
};
export default UserForm;
