import PropTypes from 'prop-types';
import {UserIcon} from "@heroicons/react/solid";
import {useUsers} from "../../../../hooks";

const UserListItem = ({user, index}, ...props) => {
    const {
        setActiveUser,
        setShowUserForm,
        isCreatingAdminUser: isWBAdmin
    } = useUsers()


    const badgeClassName = ` 
        tracking-wider text-xs font-medium px-3 py-1 rounded-full
    `

    const handleClick = () => {
        setActiveUser(user)
        setShowUserForm(true);
    }

    const getUserStatus = () => {
        if (user?.status === 1) {
            return <span className={`${badgeClassName} bg-green-500/20 text-green-700`}>Active</span>
        }
        return <span className={`${badgeClassName} bg-red-500/20 text-red-700`}>Inactive</span>
    }

    const getUserGender = () => {
        if (user?.gender === "M") return "Male";
        return "Female";
    }

    const buttonClassName = 'text-sm border-[1.2px] whitespace-nowrap rounded-[3px] tracking-wide font-light hover:text-white hover:bg-accent transition-all text-accent border-accent px-3 py-1';
    return (
        <div {...props}
             className={`grid gap-2 text-gray-500 border-t-[1.5px] border-t-gray-light text-[15px] grid-cols-7 `}>
            <div className={'col-span-2 py-3 pl-5 '}>
                <div className={'flex items-center opacity-100 space-x-5'}>
                    <div className={'h-9 w-9 bg-gray-200 center rounded-full overflow-hidden'}>
                        <UserIcon className={'h-6 w-6 text-gray-500'}/>
                    </div>
                    <div className={''}>
                        <h3 className={'text-dark'}>{user?.firstName} {user?.lastName}</h3>
                        <p className={'text-sm opacity-70 space-x-1'}>
                            <span>{user?.email}</span>
                        </p>
                    </div>
                </div>
            </div>
            <div className={'center py-3'}>
                {getUserGender()}
            </div>
            <div className={'center py-3'}>{user?.role?.name ?? 'N/A'}</div>
            {isWBAdmin && (
                <div className={'center'}>
                    {user?.piu?.abbreviation}
                </div>
            )}
            <div className={'center py-3 opacity-100'}>
                {getUserStatus()}
            </div>
            <div className={`flex-right  ${!isWBAdmin && 'col-span-2'}  py-2 pr-5 flex space-x-2`}>
                <button onClick={handleClick} className={buttonClassName}>Edit Details</button>
                <button
                    className={`${buttonClassName} ${isWBAdmin && 'hidden'} hover:bg-primary border-secondary text-secondary`}>Reset
                    Password
                </button>
            </div>
        </div>
    )
}

UserListItem.propTypes = {
    user: PropTypes.object.isRequired
}
export default UserListItem;
