import {useAuth} from "../provider/auth/auth";
import {Navigate, useLocation} from "react-router-dom";
import GlobalContextProvider from "../provider/global";
import {saveValueToLocalStorage} from "../hooks/useLocalStorageState";
import PermissionProvider from "provider/permission";

const RequireAuth = ({children}) => {
    const {authUser} = useAuth();
    const location = useLocation();

    if (!authUser) {
        saveValueToLocalStorage('wb-router-from', location);

        // Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they log in, which is a nicer user experience
        // than dropping them off on the home page.
        return <Navigate to="/login" state={{from: location}} replace/>;
    }

    return (
        <GlobalContextProvider>
            <PermissionProvider>
                {children}
            </PermissionProvider>
        </GlobalContextProvider>
    );
}
export default RequireAuth;


