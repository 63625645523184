import { useEffect, useState } from "react";
import useClickOutside from "../../hooks/useClickOutside";
import { splitOnUpperCase } from "../../utils";

const ColorPickerInput = ({ props }) => {
    const {
        register,
        inputName,
        colorName,
        defaultColor: initialColor,
        setColor,
    } = props;
    const [selectedColor, setSelectedColor] = useState("#2196F3");
    const [showColorPicker, setShowColorPicker] = useState(false);
    const colorPickerRef = useClickOutside(() => {
        setShowColorPicker(false);
    });

    useEffect(() => {
        if (initialColor) {
            setSelectedColor(initialColor);
            setColor(inputName, initialColor);
        }
    }, [initialColor]);

    useEffect(() => {
        setColor(inputName, selectedColor);
    }, [selectedColor]);

    const colors = [
        "#2196F3",
        "#009688",
        "#9C27B0",
        "#FFEB3B",
        "#afbbc9",
        "#4CAF50",
        "#2d3748",
        "#f56565",
        "#ed64a6",
    ];
    return (
        <div className={"space-y-2"}>
            <p
                className={
                    "text-primary/80 divide-x-[1px] divide-secondary/80 uppercase text-xs tracking-wider`"
                }
            >
                <span className={"pr-2 capitalize"}>
                    {splitOnUpperCase(inputName)}{" "}
                </span>
                <span className={"pl-2"}>{selectedColor}</span>
            </p>
            <div
                onClick={() => setShowColorPicker(true)}
                style={{ background: selectedColor }}
                className={
                    "flex rounded-[5px] relative cursor-pointer border-[1px] border-[#D2D4DA] "
                }
            >
                <input
                    {...register(inputName)}
                    className={
                        "p-0 opacity-0 h-10 w-full bg-transparent pointer-events-none outline-none focus:ring-0 px-4 py-2"
                    }
                />

                {/* color picker */}
                <div
                    ref={colorPickerRef}
                    className={`w-40 bg-white/40 transition-all p-4 ${
                        showColorPicker
                            ? "scale-100 pointer-events-auto opacity-100"
                            : "scale-50 pointer-events-none opacity-0"
                    } flex origin-center flex-wrap justify-between gap-4 z-30 absolute top-2 border rounded-lg backdrop-blur-xl left-0 right-0 mx-auto`}
                >
                    {colors.map((color) => {
                        return (
                            <button
                                type={"button"}
                                onClick={() => setSelectedColor(color)}
                                style={{ background: color }}
                                className={`h-6 w-6 ring-2 
                                    ${
                                        selectedColor === color
                                            ? "ring-black/60"
                                            : "hover:ring-black/60 ring-transparent "
                                    }
                                      hover:opacity-90 ring-offset-2 rounded-full transition-all`}
                            ></button>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default ColorPickerInput;
