import { SearchIcon } from "@heroicons/react/outline";
import { EqualizerIcon } from "../../../svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import React, { useState } from "react";
import ProjectImagesProvider from "../../../provider/images";
import ImageList from "./image-list";
import {
	getValueFromLocalStorage,
	saveValueToLocalStorage,
} from "../../../hooks/useLocalStorageState";
import { RequirePermission } from "../../../provider/permission";
import { useParams } from "react-router-dom";

const Images = () => {
	const [searchKeyword, setSearchKeyword] = useState("");
	const { category: imageCategory } = useParams();
	const [showListView, setShowListView] = useState(() => {
		return getValueFromLocalStorage("showImageInList", true);
	});
	const enableGridView = () => {
		setShowListView(false);
		return saveValueToLocalStorage("showImageInList", false);
	};

	const enableListView = () => {
		setShowListView(true);
		return saveValueToLocalStorage("showImageInList", true);
	};

	const handleChange = ({ target }) => {
		return setSearchKeyword(target?.value.toLowerCase());
	};

	return (
		<RequirePermission>
			<ProjectImagesProvider>
				<section
					className={"h-full py-10 space-y-6 px-16 overflow-y-auto"}
				>
					<div className={"space-y-3"}>
						<p className={"text-[#0A0E1C] uppercase font-semibold"}>
							{imageCategory} IMAGES
						</p>
						<div className={"items-between"}>
							<div className={"space-x-20 flex items-center"}>
								<div className={"flex space-x-3 items-center"}>
									<div
										className={
											"w-60 bg-[#F9F9F9AC] border-[1px] border-[#DCDFF1] flex items-center rounded overflow-hidden pl-2 space-x"
										}
									>
										<SearchIcon
											className={"h-5 w-5 text-black/50"}
										/>
										<input
											type="text"
											placeholder={"Search..."}
											onChange={handleChange}
											name=""
											id=""
											className={
												"bg-transparent border-none focus:ring-0 placeholder-black/50 text-sm outline-none py-2 font-light w-full"
											}
										/>
									</div>
									<div
										className={
											"h-9 w-9 center bg-[#EBF3F2] border-[1.7px] border-secondary rounded-[3px]"
										}
									>
										<EqualizerIcon />
									</div>
								</div>

								<div className={"flex"}>
									<div
										onClick={enableGridView}
										className={`h-9 cursor-pointer center w-10 rounded-l ${
											showListView
												? "bg-[#EBF3F2] text-secondary"
												: "bg-secondary text-white"
										}`}
									>
										<svg
											className="w-6 h-6"
											xmlns="http://www.w3.org/2000/svg"
											fill="currentColor"
											viewBox="0 0 16 16"
										>
											<path d="M1 4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V4zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V4zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V4zM1 9a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V9zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V9zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V9z"></path>
										</svg>
									</div>
									<div
										onClick={enableListView}
										className={`h-9 cursor-pointer w-10 center rounded-r ${
											!showListView
												? "bg-[#EBF3F2] text-secondary"
												: "bg-secondary text-white"
										}`}
									>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 24 24"
											strokeWidth={1.5}
											stroke="currentColor"
											className="w-7 h-7"
										>
											<path
												strokeLinecap="round"
												strokeLinejoin="round"
												d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
											/>
										</svg>
									</div>
								</div>
							</div>

							<div
								className={
									"bg-secondary flex items-center text-[15px] rounded-[4px] overflow-hidden text-white"
								}
							>
								<p className={"px-5"}>Upload Photos</p>
								<div className={"px-3 h-10 center bg-white/20"}>
									<FontAwesomeIcon
										icon={faPlus}
										className={"h-5 w-5"}
									/>
								</div>
							</div>
						</div>
					</div>

					<ImageList
						viewMode={showListView}
						searchKeyword={searchKeyword}
					/>
				</section>
			</ProjectImagesProvider>
		</RequirePermission>
	);
};
export default Images;
