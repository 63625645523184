const GrievanceStatisticsCard = ({statistic}, ...props) => {
    return (
        <div {...props} className={'bg-white space-y-3  rounded-[6px] p-5'}
             style={{boxShadow: "0px 3px 4px #0000001C"}}>
            <div className={'flex items-center space-x-4'}>
                {statistic.icon}
                <p className={'text-[#254D4D] text-3xl font-semibold'}>{statistic.total}</p>
            </div>
            <p className={'text-[#254D4D] text-sm'}>{statistic.title}</p>
        </div>
    )
}
export default GrievanceStatisticsCard;