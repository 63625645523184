import { createContext } from "react";

import ActivityStatusByPieChart from "./activity-status-pichart/activity-status-by-piechart";
import StakeholderActivityStatus from "./stakeholder-activity-status/stakeholder-activity-status";
import UpcomingEvents from "./upcoming-events";
import StakeholderBreakDown from "./stakeholder-break-down";
import Calender from "components/calender";
import StakeholdersList from "./stakeholders";
import EventButton from "components/calender/event-button";
import useEvent from "hooks/useEvent";
import { Can } from "provider/permission";

const OverviewContext = createContext();

const Overview = ({ setTabToCalendar }) => {
    const { upcomingEvents, isLoading } = useEvent();

    return (
        <OverviewContext.Provider value={{}}>
            {/* charts showing activity status */}
            <div className={"grid grid-cols-3 gap-x-4"}>
                <ActivityStatusByPieChart />
                <StakeholderActivityStatus />
            </div>

            {/* upcoming events sections*/}
            <UpcomingEvents />

            <div className={"grid  grid-cols-2 gap-x-4  overflow-y-auto "}>
                <StakeholderBreakDown />
                <div
                    className={
                        " border-[1px] overflow-hidden  border-[#E3E3E3] "
                    }
                >
                    <div
                        className={
                            "flex text-sm border-b-[#E3E3E3] h-11 border-b-[1px] p-5 justify-between items-center"
                        }
                    >
                        <p className={"text-[#31394C] tracking-wide"}>
                            Upcoming Meetings in a Calendar
                        </p>
                        <div className="hover:cursor-pointer">
                            <Can permission={"create-projectEvents"}>
                                <EventButton />
                            </Can>
                        </div>
                    </div>
                    <div style={{ height: "calc(100% - 60px)" }}>
                        <Calender
                            isLoading={isLoading}
                            calendarSize={"small"}
                            events={upcomingEvents}
                        />
                    </div>
                </div>
            </div>

            {/* sample stakeholders list */}
            <Can permission={"read-stakeholders"}>
                <StakeholdersList />
            </Can>
        </OverviewContext.Provider>
    );
};
export default Overview;

// const useOverviewData = () => {
//     const context = useContext(OverviewContext);
//     if (!context) {
//         throw new Error(
//             `useOverview() hooks should be used inside of OverviewContext context`
//         );
//     }
//     return context;
// };
