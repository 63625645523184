import MeetingRegFormLayout from "pages/general/engagement/activity/events/form";
import { Route, Routes } from "react-router-dom";
import { permissions, RequirePermission } from "provider/permission";
import Event from "pages/general/engagement/activity/events/form/event";
import CreateAgenda from "pages/general/engagement/activity/events/form/meeting/steps/agenda";
import AddParticipants from "pages/general/engagement/activity/events/form/meeting/steps/participant";
import SetDatetime from "pages/general/engagement/activity/events/form/meeting/steps/set-datetime";
import Channel from "pages/general/engagement/activity/events/form/meeting/steps/channel";
import Attachment from "pages/general/engagement/activity/events/form/meeting/steps/attachment";
import SetReminder from "pages/general/engagement/activity/events/form/meeting/steps/reminder";
import SetNotification from "pages/general/engagement/activity/events/form/meeting/steps/notification";
import MeetingBudget from "../../pages/general/engagement/activity/events/form/meeting/steps/budget";
import UserProvider from "../../provider/users/user-provider";

const EventRegistrationRoutes = () => {
    return (
        <RequirePermission permission={permissions.CREATE_PROJECT_EVENTS}>
            <Routes>
                <Route element={<MeetingRegFormLayout />}>
                    <Route index element={<Event />} />
                    <Route path={":event_type"}>
                        <Route index element={<CreateAgenda />} />
                        <Route
                            path={"add-participants"}
                            element={<AddParticipants />}
                        />
                        <Route
                            path={"set-datetime"}
                            element={<SetDatetime />}
                        />
                        <Route
                            path={"communication-channel"}
                            element={<Channel />}
                        />
                        <Route
                            path={"add-attachment"}
                            element={<Attachment />}
                        />
                        <Route
                            path={"set-reminder"}
                            element={<SetReminder />}
                        />
                        <Route
                            path={"set-meeting-budget"}
                            element={
                                <UserProvider>
                                    <MeetingBudget />
                                </UserProvider>
                            }
                        />
                        <Route
                            path={"set-notification"}
                            element={<SetNotification />}
                        />
                    </Route>
                </Route>
            </Routes>
        </RequirePermission>
    );
};
export default EventRegistrationRoutes;
