import Input from "../../../../components/form-control/Input";
import Select from "../../../../components/form-control/Select";
import Option from "../../../../components/form-control/Option";
import EditorJS from "../../../../components/editorjs/editor";
import Button from "../../../../components/Button";
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/outline";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
    GBVComplaintFormValidation,
    GrievanceForm2Validation,
} from "../../../../forms/validation";
import useGlobalContext from "../../../../hooks/useGlobalContext";
import { useEffect, useState } from "react";
import {
    getValueFromLocalStorage,
    saveValueToLocalStorage,
} from "../../../../hooks/useLocalStorageState";
import { isEmptyObject } from "utils";
import dayjs from "dayjs";

const AddComplaint = ({
    isGBV,
    activeGrievance,
    departments,
    grievanceNature,
    setCurrentStep,
}) => {
    const {
        handleSubmit,
        register,
        formState: { errors, isSubmitted },
    } = useForm({
        resolver: yupResolver(
            isGBV ? GBVComplaintFormValidation : GrievanceForm2Validation
        ),
    });
    const { projectPhases = [] } = useGlobalContext();
    const initialData = getValueFromLocalStorage("grievanceStep2FormData", {});
    const [complaint, setComplaint] = useState(initialData.complaint ?? "");

    const onSubmit = (formData) => {
        if (complaint) {
            const { createdDate, estimatedResolveDate } = formData;
            const payload = { ...formData, complaint };

            if (isGBV) {
                payload["estimatedResolveDate"] = dayjs(createdDate).add(
                    parseInt(estimatedResolveDate),
                    "day"
                );
            }
            setCurrentStep(isGBV ? 4 : 3);
            saveValueToLocalStorage("grievanceStep2FormData", payload);
        }
    };

    // initialize complaint with active grievance-complaint
    useEffect(() => {
        if (!isEmptyObject(activeGrievance)) {
            setComplaint(activeGrievance.complaint);
        }
    }, [activeGrievance]);

    const handleEditorChanges = (cleanData) => {
        setComplaint(cleanData);
    };

    console.log(errors);

    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            className={"grid  px-8 pt-4 pb-8 gap-x-4 space-y-4 grid-cols-2"}
        >
            {/* department list*/}
            <div
                className={`space-y-1 col-span-2 2xl:col-span-2 ${
                    isGBV ? "hidden" : "block"
                }`}
            >
                <label
                    className="block mb-2 text-sm font-medium text-[#002F4F]"
                    htmlFor=""
                >
                    Complaint against
                </label>
                <Select
                    defaultValue={
                        activeGrievance?.department
                            ? activeGrievance?.department
                            : initialData?.department ?? ""
                    }
                    {...register("department")}
                    isError={!!errors?.department?.message}
                    className={"text-sm"}
                >
                    <option value="" disabled={true}>
                        Choose
                    </option>
                    {departments.map((department) => (
                        <Option
                            value={department._id}
                            key={department._id}
                            text={department.name}
                        />
                    ))}
                </Select>
            </div>

            {/* complaint creation date */}
            <div
                className={`space-y-1 ${
                    isGBV ? "block" : "hidden"
                } 2xl:col-span-2`}
            >
                <label
                    className="block mb-2 text-sm font-medium text-[#002F4F]"
                    htmlFor=""
                >
                    Date of complaint
                </label>
                <Input
                    type={"date"}
                    defaultValue={activeGrievance?.createdTime ?? ""}
                    register={register}
                    isError={!!errors?.createdTime?.message}
                    name={"createdTime"}
                    className={"text-sm"}
                    placeholder={"eg Finance, Human Resources"}
                />
            </div>

            <div className={`2xl:col-span-2 ${isGBV ? "block" : "hidden"}`}>
                <label
                    htmlFor="createdDate"
                    className="block mb-2 text-sm font-medium text-[#002F4F]"
                >
                    Estimated resolve date
                </label>
                <Select
                    isError={!!errors.riskLevel?.message}
                    defaultValue={activeGrievance?.riskLevel ?? ""}
                    {...register("estimatedResolveDate")}
                    className={"text-sm"}
                >
                    <option value="" disabled={true}>
                        Choose
                    </option>
                    <Option value={"7"} text="7 Days" />
                    <Option value={"14"} text="14 Days" />
                </Select>
            </div>

            {/* risk level */}
            <div className={`2xl:col-span-2 ${isGBV ? "block" : "hidden"}`}>
                <label
                    htmlFor="createdDate"
                    className="block mb-2 text-sm font-medium text-[#002F4F]"
                >
                    Risk level
                </label>
                <Select
                    isError={!!errors.riskLevel?.message}
                    defaultValue={activeGrievance?.riskLevel ?? ""}
                    {...register("riskLevel")}
                    className={"text-sm"}
                >
                    <option value="" disabled={true}>
                        Choose
                    </option>
                    {["High", "Medium", "Low"].map((nature) => (
                        <Option key={nature} value={nature} />
                    ))}
                </Select>
            </div>

            {/* project phase list*/}
            <div className={"space-y-1 2xl:col-span-2 "}>
                <label
                    className="block mb-2 text-sm font-medium text-[#002F4F]"
                    htmlFor=""
                >
                    Phase the complaint made
                </label>
                <Select
                    defaultValue={
                        activeGrievance?.projectPhase
                            ? activeGrievance?.projectPhase
                            : initialData?.projectPhase ?? ""
                    }
                    {...register("projectPhase")}
                    isError={!!errors?.projectPhase?.message}
                    className={"text-sm"}
                >
                    <option value="" disabled={true}>
                        Choose
                    </option>
                    {projectPhases.map((phase) => (
                        <Option
                            key={phase?._id}
                            value={phase?._id}
                            text={phase?.name}
                        />
                    ))}
                </Select>
            </div>

            {/* Nature for a complaint*/}
            <div
                className={`space-y-1 2xl:col-span-2 ${
                    isGBV ? "hidden" : "block"
                }`}
            >
                <label
                    className="block mb-2 text-sm font-medium text-[#002F4F]"
                    htmlFor=""
                >
                    Nature of complaint
                </label>
                <Select
                    defaultValue={
                        activeGrievance?.nature
                            ? activeGrievance?.nature
                            : initialData?.nature ?? ""
                    }
                    {...register("nature")}
                    isError={!!errors?.nature?.message}
                    className={"text-sm"}
                >
                    <option value="" disabled={true}>
                        Choose
                    </option>
                    {grievanceNature.map((nature) => (
                        <Option
                            value={nature._id}
                            key={nature._id}
                            text={nature.title}
                        />
                    ))}
                </Select>
            </div>

            {/* Nature of GBV */}
            <div
                className={`space-y-1 2xl:col-span-2 ${
                    isGBV ? "block" : "hidden"
                }`}
            >
                <label
                    className="block mb-2 text-sm font-medium text-[#002F4F]"
                    htmlFor=""
                >
                    Nature of GBV
                </label>
                <Select
                    defaultValue={
                        activeGrievance?.nature
                            ? activeGrievance?.nature
                            : initialData?.nature ?? ""
                    }
                    {...register("gbvNature")}
                    isError={!!errors?.gbvNature?.message}
                    className={"text-sm"}
                >
                    <option value="" disabled={true}>
                        Choose
                    </option>
                    {grievanceNature.map((nature) => (
                        <Option
                            value={nature._id}
                            key={nature._id}
                            text={nature.title}
                        />
                    ))}
                </Select>
            </div>
            <div
                className={`space-y-1  2xl:col-span-2 ${
                    isGBV ? "" : "col-span-2"
                }`}
            >
                <label
                    className="block mb-2 text-sm font-medium text-[#002F4F]"
                    htmlFor=""
                >
                    Time the complaint made
                </label>
                <Input
                    type={"time"}
                    defaultValue={
                        activeGrievance?.time
                            ? activeGrievance?.time
                            : initialData?.time ?? ""
                    }
                    register={register}
                    isError={!!errors?.time?.message}
                    name={"time"}
                    className={"text-sm"}
                    placeholder={"eg Finance, Human Resources"}
                />
            </div>
            <div className={"col-span-2 2xl:col-span-2"}>
                <label
                    className="block mb-2 text-sm font-medium text-[#002F4F]"
                    htmlFor=""
                >
                    Complaint
                </label>
                <EditorJS
                    defaultValue={activeGrievance?.complaint ?? complaint}
                    isError={isSubmitted && !complaint}
                    onChange={handleEditorChanges}
                />
            </div>

            {/*  submit button */}
            <div className={""}>
                <Button
                    onClick={() => setCurrentStep(1)}
                    outline={true}
                    block={true}
                    type="button"
                    className={
                        " flex whitespace-nowrap items-center space-x-2 text-sm"
                    }
                >
                    <ArrowLeftIcon className={"h-5 w-5"} />
                    <span>PREV STEP</span>
                </Button>
            </div>

            <div className={""}>
                <Button
                    block={true}
                    type="submit"
                    className={"flex items-center space-x-2 text-sm"}
                >
                    <span>NEXT STEP</span>
                    <ArrowRightIcon className={"h-5 w-5"} />
                </Button>
            </div>
        </form>
    );
};
export default AddComplaint;
