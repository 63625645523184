import AddKeyResults from "components/key-results";
import FormFooter from "../form-footer";
import FormHeader from "../form-header";
import { useEffect, useState } from "react";
import { localStorageKeys } from "../../../../../constants";
import { getValueFromLocalStorage } from "hooks/useLocalStorageState";

const MilestoneForm = ({
    onSubmit: createCommitment,
    isCreating,
    setCurrStep,
}) => {
    const { commitmentForm: commitmentLSKeys } = localStorageKeys;
    const [hasError, setHasError] = useState(false);
    const basicDetails = getValueFromLocalStorage(
        commitmentLSKeys.BASIC_DETAILS,
        {}
    );
    const [keyResults, setKeyResults] = useState([]);

    const handeClick = () => {
        if (keyResults.length) {
            const payload = {
                ...basicDetails,
                stakeholder: basicDetails.stakeholder._id,
                department: basicDetails.department._id,
                results: keyResults.map(({ id, ...rest } = {}) => rest),
            };
            createCommitment(payload);
        } else setHasError(true);
    };

    useEffect(() => {
        if (keyResults.length) {
            setHasError(false);
        }
    }, [keyResults]);

    return (
        <div className={" bg-[#F9F9F9] w-[45%] mt-5 rounded-md"}>
            <FormHeader />
            <div className={"space-y-5 min-h-[20rem] px-10 py-5"}>
                <header>
                    <h2 className={"text-sm text-dark font-medium"}>
                        Add Milestones
                    </h2>
                    <p className={"text-sm text-gray-500 tracking-wide"}>
                        Milestones are a useful tool for tracking progress
                        towards meeting commitments.{" "}
                        <span className={hasError ? "text-red-500" : ""}>
                            Please provide at least one milestone to help keep
                            track of your progress.
                        </span>
                    </p>
                </header>

                <AddKeyResults
                    minDate={basicDetails?.startDate}
                    maxDate={basicDetails?.endDate}
                    keyResults={keyResults}
                    setKeyResults={setKeyResults}
                />
            </div>
            <FormFooter
                onPrev={() => setCurrStep(1)}
                currStep={2}
                onCLick={handeClick}
                type={"button"}
                loading={isCreating}
            />
        </div>
    );
};
export default MilestoneForm;
