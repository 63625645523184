import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faPowerOff } from "@fortawesome/free-solid-svg-icons";
import { CalenderIcon } from "../../../../../../svg/calender";
import Participant from "./participant";
import { CreateMarkup } from "../../../../../../utils";
import { updateProjectEvent } from "../../../../../../provider/api";
import { useMutation } from "react-query";
import { Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
    saveMultiValueToLocalStorage,
    saveValueToLocalStorage,
} from "../../../../../../hooks/useLocalStorageState";
import { DocumentTextIcon } from "@heroicons/react/outline";
import Button from "../../../../../../components/Button";
import { Can } from "provider/permission";
import { DotsHorizontalIcon } from "@heroicons/react/solid";
import ArrowRightWithBorder from "../../../../../../components/arrow-right-with-border";
import BudgetFileCard from "../../../../budget-approve/budget-file-card";
import { localStorageKeys } from "../../../../../../constants";

const MeetingDetails = ({ event, closeSidebar = () => {} }) => {
    const createdAt = new Date(event.createdAt);
    const navigate = useNavigate();
    const { meetingForm: meetingLSKeys } = localStorageKeys;

    const formatDate = (dateString) => {
        return new Date(dateString).toDateString();
    };

    const formatTime = (timeInfo) => {
        if (!timeInfo?.hour) return "--";
        return `${timeInfo?.hour}:${timeInfo?.minutes}`;
    };

    const { mutate: _updateProjectEvent, isLoading: isUpdating } = useMutation(
        updateProjectEvent,
        {
            onSuccess: () => {
                // closeSidebar();

                // Todo: Find a better way to make sure the  event marked as done will disappear in the list
                window.location.reload();
            },
        }
    );

    const markEventAsCompleted = (event) => {
        const payload = { _id: event._id, status: 1, completedOn: new Date() };

        _updateProjectEvent(payload);
    };

    const populateFormInitialData = async () => {
        const {
            channel,
            eventDate,
            endingTime,
            channelType,
            startingTime,
            locationName,
            locationScope,
            budgetTitle,
            budgetApprover = {},
            budgetFiles,
            project,
        } = event;

        const meetingTime = {
            ...(eventDate && { eventDate }),
            ...(endingTime && { endingTime }),
            ...(startingTime && { startingTime }),
        };

        const meetingBudget = {
            budgetApprover: {
                ...budgetApprover,
                fullName: `${budgetApprover?.firstName} ${budgetApprover?.middleName} ${budgetApprover?.lastName}`,
            },
            budgetTitle,
            budgetFiles,
        };

        const meetingChannel = {
            ...(channel && { channel }),
            ...(channelType && { channelType }),
            ...(locationName && { locationName }),
            ...(locationScope && { locationScope }),
        };

        const meetingBasicInfo = {
            ...event,
            project: project._id,
            description: JSON.parse(event.description ?? "{}"),
        };

        const meetingParticipants = event?.participants.map(
            (person) => person.stakeholder
        );

        const data = {
            [meetingLSKeys.BASIC_DETAILS]: meetingBasicInfo,
            [meetingLSKeys.ACTIVE_EVENT]: meetingBasicInfo,
            [meetingLSKeys.MEETING_CHANNEL]: meetingChannel,
            [meetingLSKeys.MEETING_TIME]: meetingTime,
            [meetingLSKeys.CURRENT_STEP]: 1,
            [meetingLSKeys.IS_UPDATING_DETAILS]: true,
            [meetingLSKeys.PARTICIPANTS]: meetingParticipants,
            [meetingLSKeys.REMAINDER]: event?.reminderInterval,
            [meetingLSKeys.ATTACHMENTS]: event?.attachments,
            [meetingLSKeys.MEETING_BUDGET]: meetingBudget,
        };
        await saveMultiValueToLocalStorage(data);
    };

    const handleEditEvent = async () => {
        if (event.isMeeting) {
            return await populateFormInitialData();
        }

        const data = {
            [meetingLSKeys.BASIC_DETAILS]: event,
            [meetingLSKeys.ACTIVE_EVENT]: event,
            [meetingLSKeys.IS_UPDATING_DETAILS]: true,
        };
        await saveMultiValueToLocalStorage(data);
    };

    const handleClick = () => {
        const eventTitle = event.title.toLowerCase().split(" ").join("-");
        navigate(`/engagement/conduct-meeting/${eventTitle}`);

        saveValueToLocalStorage("activeProjectEvent", event);
    };

    const viewReport = (id) => {
        navigate(`/engagement/activities/meetings/report/${id}`);
    };

    const getBudgetStatus = () => {
        if (event?.budgetStatus === 0) return "Rejected";
        if (event?.budgetStatus === 1) return "Approved";
        if (event?.budgetStatus === 2) return "Pending Review";
    };

    console.log(event);

    const isApproved = getBudgetStatus() === "Approved";

    return (
        <div className={"overflow-y-auto h-full"}>
            <header
                className={
                    "flex py-3 top-0 sticky bg-white z-10 px-6 border-b-[1.4px] border-b-[#E8ECEE] items-center justify-between"
                }
            >
                {event.isMeeting ? (
                    !event.status ? (
                        <Can permission={"update-projectEvents"}>
                            <button
                                onClick={handleClick}
                                className={
                                    "text-[15px] tracking-wide flex items-center space-x-2 font-light bg-secondary text-white px-4 py-2 rounded"
                                }
                            >
                                <span>Start Meeting</span>
                                <FontAwesomeIcon
                                    icon={faPowerOff}
                                    className={"h-4 w-4 stroke-1"}
                                />
                            </button>
                        </Can>
                    ) : (
                        <Can permission={"read-meetingReport"}>
                            <button
                                onClick={() => viewReport(event._id)}
                                className={
                                    "text-[15px] tracking-wide border-[1.5px] border-secondary flex items-center space-x-2 font-light bg-secondary text-white px-4 py-2 rounded"
                                }
                            >
                                <DocumentTextIcon
                                    className={"h-4 w-4"}
                                    color={"#fff"}
                                />
                                <p className={""}>View Report</p>
                            </button>
                        </Can>
                    )
                ) : isUpdating ? (
                    <span>Please wait...</span>
                ) : (
                    <Fragment>
                        {!event.status ? (
                            <Can permission={"update-projectEvents"}>
                                <button
                                    onClick={() => markEventAsCompleted(event)}
                                    className={
                                        "text-[15px] tracking-wide flex border-[1.5px] border-secondary items-center space-x-2 font-light bg-secondary text-white px-4 py-2 rounded"
                                    }
                                >
                                    <FontAwesomeIcon
                                        icon={faCheck}
                                        className={"h-4 w-4 stroke-1"}
                                    />
                                    <span>Mark as Done</span>
                                </button>
                            </Can>
                        ) : (
                            <Button
                                className={
                                    "text-[15px] tracking-wide flex border-[1.5px] border-green-500/30 items-center space-x-2 font-light bg-green-500/30 text-green-600 pointer-events-none text-black px-4 py-2 rounded"
                                }
                            >
                                <span>Completed</span>
                                {/* <FontAwesomeIcon icon={faPowerOff} className={"h-4 w-4 stroke-1"} /> */}
                            </Button>
                        )}
                    </Fragment>
                )}

                <div className={"flex items-center space-x-3"}>
                    <DotsHorizontalIcon className={"h-5 text-[#6f7782]"} />
                    <ArrowRightWithBorder className={"h-4 w-4"} />
                </div>
                {!event.status && (
                    <Can permission={"update-projectEvents"}>
                        <Link
                            to={
                                event.isMeeting
                                    ? "/event-registration/meeting"
                                    : "/event-registration"
                            }
                            className={
                                "text-[15px] tracking-wide flex items-center space-x-2 border-[1.5px] border-secondary font-light text-secondary px-4 py-2 rounded"
                            }
                            onClick={() => handleEditEvent()}
                        >
                            Edit details
                        </Link>
                    </Can>
                )}
            </header>

            <div className={"p-6 space-y-6"}>
                <div className={"space-y-2"}>
                    <p
                        className={
                            "text-xl font-semibold text-[#151D41] tracking-wide"
                        }
                    >
                        {event?.title}
                    </p>
                    <p className={"text-[#6B6E7B] font-light text-[15px]"}>
                        Created on {formatDate(createdAt)}
                    </p>
                </div>

                <div
                    className={
                        "flex items-center space-x-6 text-[13px] font-light"
                    }
                >
                    <div className={"text-[#B0B4BA] space-y-3"}>
                        <p>Type Of Meeting:</p>
                        <p>Starting time:</p>
                        <p>Meeting Channel:</p>
                    </div>

                    <div className={"space-y-3 font-normal"}>
                        <p className={"font-normal"}>
                            {event?.meetingType
                                ? event?.meetingType
                                : "Not provided"}
                        </p>
                        <p
                            className={
                                "flex items-center space-x-3 text-[#51BF82]"
                            }
                        >
                            <CalenderIcon
                                className={"h-4 w-4"}
                                color={"#51BF82"}
                            />
                            <span>
                                {formatDate(event.eventDate)} at{" "}
                                {formatTime(event?.startingTime)}
                            </span>
                        </p>
                        <p className={"text-dark "}>
                            {event.channelType === "Online"
                                ? event?.channel?.name
                                : "Not provided"}
                        </p>
                    </div>
                </div>

                {/*  description */}
                <div className={"text-[13px] font-light space-y-2"}>
                    <h6 className={"text-[#B0B4BA]"}>Descriptions:</h6>
                    {event?.description ? (
                        <p
                            dangerouslySetInnerHTML={CreateMarkup(
                                event?.description
                            )}
                            className={"text-dark leading-relaxed"}
                        ></p>
                    ) : (
                        <p> Not provided</p>
                    )}
                </div>

                <div className={"space-y-5"}>
                    <div className={"flex items-center space-x-2"}>
                        <h6 className={"text-[15px]"}>Budget Status: </h6>
                        <div
                            className={`tracking-wider text-xs
                     ${
                         isApproved
                             ? "border-green-300 bg-green-500/20 text-green-700"
                             : " bg-[#F26F86]/20 text-[#F26F86] border-[#F26F86]/60"
                     }
                         inline-block font-medium px-2 py-1 border-[1.4px]  rounded-full`}
                        >
                            {getBudgetStatus()}
                        </div>
                    </div>

                    {(getBudgetStatus() === "Rejected" &&
                        event?.budgetRejectionReasons) ||
                    event?.budgetApprovalComment ? (
                        <div
                            className={
                                "border-2 border-secondary rounded-md p-1.5"
                            }
                        >
                            <div
                                className={
                                    "bg-[#f7f7f7] space-y-1 text-dark text-sm p-3 rounded"
                                }
                            >
                                <h5 className={"text-dark/90 font-semibold "}>
                                    {event.budgetStatus === 0
                                        ? "Rejection Reason:"
                                        : "Approval Comment"}
                                </h5>
                                <p
                                    dangerouslySetInnerHTML={CreateMarkup(
                                        event.budgetStatus === 0
                                            ? event?.budgetRejectionReasons
                                            : event.budgetApprovalComment
                                    )}
                                ></p>
                            </div>
                        </div>
                    ) : null}
                </div>

                {event?.budgetFiles?.length ? (
                    <div className={"space-y-3"}>
                        <h6 className={"text-[15px]"}>
                            Files for{" "}
                            <span className="lowercase">
                                {event?.budgetTitle}:
                            </span>
                        </h6>
                        <div className={"space-y-2"}>
                            {event?.budgetFiles.map((file) => {
                                return (
                                    <BudgetFileCard
                                        showDeleteBtn={false}
                                        file={file}
                                    />
                                );
                            })}
                        </div>
                    </div>
                ) : null}

                <div className={"space-y-2"}>
                    <h6 className={"text-[15px]"}>Participants</h6>
                    <div className="pb-10 space-y-2">
                        {!event?.participants.length ? (
                            <span>No participants yet</span>
                        ) : (
                            event.participants.map((participant, idx) => (
                                <Participant
                                    key={idx}
                                    showRemoveBtn={false}
                                    stakeholder={participant?.stakeholder}
                                />
                            ))
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
export default MeetingDetails;
