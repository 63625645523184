import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { GrievanceNatureFormValidation } from "../../../../forms/validation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import Modal from "../../../../components/Modal";
import Input from "../../../../components/form-control/Input";
import { RadioGroup } from "@headlessui/react";
import ColorItem from "./color-item";
import { useEffect, useState } from "react";
import Button from "../../../../components/Button";
import { useMutation, useQueryClient } from "react-query";
import {
	createGrievanceNature,
	updateGrievanceNature,
} from "../../../../provider/api";
import Textarea from "../../../../components/form-control/textarea";
import { isEmptyObject } from "../../../../utils";

const GrievanceNatureForm = ({
	showForm,
	setShowForm,
	activeNature,
	setActiveNature,
}) => {
	const [selectedColor, setSelectedColor] = useState("#2B9BD5");
	const {
		handleSubmit,
		reset: clearForm,
		register,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(GrievanceNatureFormValidation),
	});
	const queryClient = useQueryClient();
	const colorList = ["#2B9BD5", "#19A89D", "#233A4F", "#83A5A8"];
	const isUpdatingNature = !isEmptyObject(activeNature);
	const handleClose = () => {
		setShowForm(false);
		setActiveNature({});
		clearForm();
	};

	const { isLoading, mutate: _createGNature } = useMutation(
		"create-grievance-nature",
		createGrievanceNature,
		{
			onSuccess: ({ data: nature }) => {
				console.log("successfully created", nature);
				updateNatureCache(nature);
				handleClose();
			},
		}
	);
	const { isLoading: isUpdating, mutate: _updateNature } = useMutation(
		"update-grievance-nature",
		updateGrievanceNature,
		{
			onSuccess: ({ data: nature }) => {
				console.log("successfully updated", nature);
				updateNatureCache(nature);
				handleClose();
			},
		}
	);

	const onSubmit = (formData) => {
		console.log(formData);
		const payload = { ...formData, color: selectedColor };

		if (isUpdatingNature)
			return _updateNature({
				...payload,
				natureId: activeNature?._id,
			});
		_createGNature(payload);
	};

	const updateNatureCache = (nature) => {
		if (!isUpdatingNature) {
			return queryClient.setQueriesData(
				"grievance-natures",
				(oldQueryData) => {
					return {
						...oldQueryData,
						data: [...oldQueryData.data, nature],
					};
				}
			);
		}

		return queryClient.setQueriesData(
			"grievance-natures",
			(oldQueryData) => {
				return {
					...oldQueryData,
					data: [
						...oldQueryData.data.map((_nature) => {
							if (_nature?._id === nature?._id) return nature;
							return _nature;
						}),
					],
				};
			}
		);
	};

	useEffect(() => {
		if (isUpdatingNature) {
			setSelectedColor(activeNature?.color);
		}
	}, [activeNature]);

	return (
		<Modal isOpen={showForm} onClose={setShowForm}>
			<div className={"w-2/6 bg-white rounded-lg overflow-hidden"}>
				<div className="flex justify-between px-5 py-3 bg-gray-300 rounded-t-md">
					<p className="font-semibold text-[#002F4F]">
						Add New Grievance Nature
					</p>

					<button
						onClick={handleClose}
						className="-mr-1 border-2 focus:ring-0 ring-0 border-[#192B40]/50 w-6 h-6 bg-[#628799] text-white rounded-full grid place-items-center"
					>
						<FontAwesomeIcon color="FFFFFF" icon={faXmark} />
					</button>
				</div>
				<form
					onSubmit={handleSubmit(onSubmit)}
					className={"px-5 space-y-4 py-7"}
				>
					<div className={"space-y-1"}>
						<label
							className="block mb-2 text-sm font-medium text-[#002F4F]"
							htmlFor=""
						>
							Name
						</label>
						<Input
							defaultValue={activeNature?.title ?? ""}
							register={register}
							isError={!!errors?.title?.message}
							name={"title"}
							className={"text-sm"}
							placeholder={"eg Finance, Human Resources"}
						/>
					</div>

					<div className={"space-y-1"}>
						<label
							className="block mb-2 text-sm font-medium text-[#002F4F]"
							htmlFor=""
						>
							Description
						</label>
						<Textarea
							defaultValue={activeNature?.description ?? ""}
							register={register}
							isError={!!errors?.description?.message}
							name={"description"}
							className={"text-sm"}
							placeholder={"eg Finance, Human Resources"}
						/>
					</div>

					<div>
						<RadioGroup
							className={"space-y-1"}
							value={selectedColor}
							onChange={setSelectedColor}
							name="color"
						>
							<RadioGroup.Label className="block mb-2 text-sm font-medium text-[#002F4F]">
								{" "}
								Select color
							</RadioGroup.Label>
							<div className={"flex space-x-3 items-center"}>
								{colorList.map((color) => (
									<RadioGroup.Option
										className={"focus:ring-0"}
										key={color}
										value={color}
									>
										{({ active, checked }) => (
											<ColorItem
												color={color}
												checked={checked}
												isActive={active}
											/>
										)}
									</RadioGroup.Option>
								))}
							</div>
						</RadioGroup>
					</div>

					<div className={"pt-2"}>
						<Button
							loading={isLoading || isUpdating}
							type={"submit"}
							className={
								"border-[2px] py-3.5 border-secondary bg-secondary"
							}
						>
							{isUpdatingNature
								? "UPDATE GRIEVANCE NATURE"
								: "ADD GRIEVANCE NATURE"}
						</Button>
					</div>
				</form>
			</div>
		</Modal>
	);
};
export default GrievanceNatureForm;
