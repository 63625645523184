import { DotsHorizontalIcon } from "@heroicons/react/outline";
import StakeholderGroupByBarChart from "./stakeholder-group-by-bar-chart";
import { useEngagementAnalytics } from "hooks";
import Loading from "./loading";

const StakeholderEngagementByGroup = () => {
	const { isLoading, engagementAnalytics } = useEngagementAnalytics();

	const { byGroup: stakeholderGroup } = engagementAnalytics;
	if (isLoading)  return <Loading />;

	const individualStakeholder = stakeholderGroup?.individual;
	const organizationalStakeholder = stakeholderGroup?.organzational;
	const communityStakeholder = stakeholderGroup?.community;

	const statistics = {
		individual: [
			individualStakeholder?.high,
			individualStakeholder?.medium,
			individualStakeholder?.low,
			individualStakeholder?.notEngaged,
		],

		organizational: [
			organizationalStakeholder?.high,
			organizationalStakeholder?.medium,
			organizationalStakeholder?.low,
			organizationalStakeholder?.notEngaged,
		],

		community: [
			communityStakeholder?.high,
			communityStakeholder?.medium,
			communityStakeholder?.low,
			communityStakeholder?.notEngaged,
		],
	};

	return (
		<div
			className={
				"bg-white border-[1px] border-[#E5E5E5] rounded-[6px] overflow-hidden"
			}
		>
			<div
				className={
					"h-12 border-b-[1px] px-5 items-between border-b-[#E5E5E5] "
				}
			>
				<p className={"text-[#31394C] font-medium text-[15px]"}>
					Stakeholder Engagement by group
				</p>
				<DotsHorizontalIcon className={"h-8 w-8 text-secondary"} />
			</div>
			<div className={"h-80 p-5"}>
				<StakeholderGroupByBarChart statistics={statistics} />
			</div>
		</div>
	);
};
export default StakeholderEngagementByGroup;
