const getContactPersonalFields = (defaultDetails, errors, register) => {
    return [
        {
            name: "name",
            hasError: !!errors?.name?.message,
            defaultValue: defaultDetails.name,
            register,
            placeholder: "Eg John Doe",
        },
        {
            name: "gender",
            hasError: !!errors?.gender?.message,
            defaultValue: defaultDetails.gender,
            register,
            options: ["Male", "Female"],
        },
        {
            name: "disability",
            hasError: !!errors?.disability?.message,
            defaultValue: defaultDetails.disability,
            register,
            options: [
                "None",
                "Deaf",
                "Vision Impairment",
                "Physical Disability",
            ],
        },
        {
            name: "position",
            hasError: !!errors?.position?.message,
            defaultValue: defaultDetails.position,
            register,
            placeholder: "Eg: District Executive",
        },
        {
            name: "email",
            hasError: !!errors?.email?.message,
            defaultValue: defaultDetails.email,
            register,
            placeholder: "Eg: example@gmail.com",
        },
        {
            name: "phoneNumber",
            hasError: !!errors?.phoneNumber?.message,
            defaultValue: defaultDetails.phoneNumber,
            register,
            placeholder: "Eg: District Executive",
        },
    ];
};
export default getContactPersonalFields;
