const GridHeader = () => {
    const headers = [
        "made to",
        "department",
        "status",
        "commitment",
        "due date",
    ];
    return (
        <header
            className={
                "w-full bg-[#F1F6F9] grid px-10 pt-6 pb-3 grid-cols-5 space-x-6 text-sm uppercase"
            }
        >
            <p>Made By</p>
            <div className="grid grid-cols-6 col-span-4 ">
                {headers.map((header, index) => (
                    <div
                        key={header}
                        className={`${index === 4 ? "col-span-2" : ""}`}
                    >
                        {header}
                    </div>
                ))}
            </div>
        </header>
    );
};
export default GridHeader;
