const UngujaNorthSvg = ({ color, isLoading = false }) => {
    return (
        <path
            id="Path_442"
            className={`${isLoading ? "animate-pulse" : ""}`}
            data-name="Path 442"
            d="M616.644,280.621l.043.071.433,1.8.238.138.83.131.086.528-.1.48-.169.486-.086.531.019.627.076.477.159.438.28.483-.468.579-.2.32-.134.444.566-.24.35.24.3.441.391.343.028-.582.258-.384.315-.029.194.48.178-.023.048-.026v-.067l.048-.154.63.163.006-.819-.184-1.168.083-.861.68.9.35,1.117.725,5.748.143.355,1.053,1.863-.178,1.7-.986,1.181-1.4.47-1.438-.423-.566-.509-.528-.691-.382-.788-.77-3.351.089-.589-.878.269.006.426.366.518.238.535-.048.883-.191.215-1.091-.407.172-.756-.223-.605-1.018-1.079-1.228-1.9-.343-.288-.04-.235-.117-.811-.687-.728-.4-.892-.069-1,.238-3.084-.2-.779-.06-.794,2.84-.193,1.5-.992.218-.132Z"
            transform="translate(-608.29 -269.537)"
            fill={color}
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="0.5"
        />
    );
};
export default UngujaNorthSvg;
