import {DotsHorizontalIcon} from "@heroicons/react/outline";
import BarChart from "./bar-chart";
import {useCommitmentAnalytics} from "hooks";
import Loading from "./loading";

const DepartmentsByBarChart = () => {
    const {isLoading, commitmentAnalytics} = useCommitmentAnalytics()
    if (isLoading) return <Loading/>
    const {byDepartment: departmentData = []} = commitmentAnalytics;

    const getDepartments = () => {
        return departmentData.map((depData) => {
            return depData?.department;
        })
    }

    const getCompletedLate = () => {
        return departmentData.map((depData) => {
            return depData?.report?.completedLate;
        })
    }

    const getCompletedOnTime = () => {
        return departmentData.map((depData) => {
            return depData?.report?.completedOnTime;
        })
    }

    const getOverdue = () => {
        return departmentData.map((depData) => {
            return depData?.report?.overdue;
        })
    }

    const getInComplete = () => {
        return departmentData.map((depData) => {
            return depData?.report?.inComplete;
        })
    }

    const statistics = {
        departmentNames: getDepartments(),
        completedLate: getCompletedLate(),
        completedOnTime: getCompletedOnTime(),
        overdue: getOverdue(),
        inComplete: getInComplete()
    }


    console.clear()
    console.log(statistics)


    return (
        <div className={'bg-white rounded-[6px] overflow-hidden'}>
            <div className={'h-12 border-b-[1px] px-5 items-between border-b-[#E5E5E5] '}>
                <p className={'text-[#31394C] font-medium'}>Commitment summary per department</p>
                <DotsHorizontalIcon className={"h-8 w-8 text-secondary"}/>
            </div>
            <div className={'h-80 p-5'}>
                <BarChart statistics={statistics}/>
            </div>
        </div>
    )
}
export default DepartmentsByBarChart