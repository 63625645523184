import StarSoldIcon from "components/StarSoldIcon";
import StartOutlineIcon from "components/StartOutlineIcon";
import { useState } from "react";
import PropsTypes from "prop-types";

const LevelInput = ({ onChange: handleLevelSelection, defaultLevel }) => {
    const [activeLevel, setActiveLevel] = useState(defaultLevel ?? "");
    const levels = [
        { id: 1, name: "Weak" },
        { id: 2, name: "Medium" },
        { id: 3, name: "Strong" },
    ];

    const handleMouseEnter = (selectedLevel) => {
        setActiveLevel(selectedLevel);
    };

    // reset level
    const handleMouseLeave = () => {
        setActiveLevel(defaultLevel);
    };

    const handleLevelClick = (selectedLevel) => {
        setActiveLevel(selectedLevel);
        handleLevelSelection(selectedLevel);
    };

    const isSelectedLevel = (levelId) => {
        return activeLevel.id === levelId;
    };

    return (
        <div
            className={"h-56 bg-white rounded flex items-center justify-center"}
        >
            <div className={"flex items-center space-x-20"}>
                {/* Start */}
                {levels.map((level) => (
                    <div
                        key={level.id}
                        onMouseEnter={() => handleMouseEnter(level)}
                        onMouseLeave={handleMouseLeave}
                        onClick={(e) => handleLevelClick(level)}
                        className="flex flex-col cursor-pointer space-y-2 items-center"
                    >
                        <div className="h-12 w-12 relative">
                            <div className="absolute inset-0 flex justify-center items-center">
                                {" "}
                                {isSelectedLevel(level.id) ||
                                level.id < activeLevel.id ? (
                                    <StarSoldIcon className={"h-12 w-12"} />
                                ) : (
                                    <StartOutlineIcon className={"h-12 w-12"} />
                                )}
                            </div>
                        </div>

                        {/* Level name */}
                        <p
                            className={`text-sm tracking-wide py-3 ${
                                isSelectedLevel(level.id)
                                    ? "border-b-2 border-b-accent text-accent"
                                    : " text-[#B4B9C0]"
                            }`}
                        >
                            <span
                                className={`${
                                    isSelectedLevel(level.id)
                                        ? "text-accent"
                                        : ""
                                }`}
                            >
                                {level.name}
                            </span>
                        </p>
                    </div>
                ))}
            </div>
        </div>
    );
};

LevelInput.protoTypes = {
    onChange: PropsTypes.func.isRequired,
    defaultLevel: PropsTypes.bool,
};
export default LevelInput;
