import { UserGroupIcon } from "../../../svg";
import StakeholderCounter from "./_partials/stakeholder-counter";
import StakeholderBreakdown from "./breakdown";
import StakeholderBreakdownByLocation from "./location";
import StickyStakeholderDetails from "./right-side-details";
import useGlobalContext from "../../../hooks/useGlobalContext";
import { Link } from "react-router-dom";
import duration from "dayjs/plugin/duration";
import dayjs from "dayjs";

dayjs.extend(duration);

const StakeholderAnalytics = () => {
    const { stakeholder: stakeholders = [], projectDetails } =
        useGlobalContext();

    const getFemaleStakeholders = () => {
        return stakeholders.filter((stakeholder) => {
            return ["Female", "F"].includes(stakeholder?.gender);
        });
    };

    const getMaleStakeholders = () => {
        return stakeholders.filter((stakeholder) => {
            return ["Male", "M"].includes(stakeholder?.gender);
        });
    };

    // list with disabilities
    const getSWD = () => {
        return stakeholders.filter((stakeholder) => {
            return (
                stakeholder?.disability !== "None" && stakeholder?.disability
            );
        });
    };

    const getProjectDuration = () => {
        const startDate = dayjs(projectDetails.startDate);
        const endDate = dayjs(projectDetails.endDate);

        const duration = dayjs.duration(startDate.diff(endDate));
        const { years, months, days } = duration["$d"];
        // console.log(duration)
        const _years = Math.abs(years);
        const _months = Math.abs(months);
        const _days = Math.abs(days);

        return `
        	${_years ? `${_years}y` : ""}  
        	${_months ? `${_months}m` : ""} 
			${_days}d
			`;
    };

    const statistics = {
        female: {
            total: getFemaleStakeholders().length,
            percentage: Math.floor(
                (getFemaleStakeholders().length * 100) / stakeholders.length
            ),
        },
        male: {
            total: getMaleStakeholders().length,
            percentage: Math.floor(
                (getMaleStakeholders().length * 100) / stakeholders.length
            ),
        },
        // list with disabilities
        swd: {
            total: getSWD().length,
            percentage: Math.floor(
                (getSWD().length * 100) / stakeholders.length
            ),
        },
    };

    return (
        <section className={"h-full overflow-y-auto "}>
            <div className={" w-full flex"}>
                <div
                    className={" space-y-14 p-8 pb-5 pt-12 h-auto"}
                    style={{ width: "calc(100%  - 345px)" }}
                >
                    {/* Stakeholder statistics */}
                    <div className={"items-between px-10"}>
                        <StakeholderCounter
                            title={
                                <span>
                                    Female <br /> Stakeholder
                                </span>
                            }
                            value={statistics.female.total}
                            percentage={statistics.female.percentage}
                        />

                        <StakeholderCounter
                            title={
                                <span>
                                    Male <br /> Stakeholder
                                </span>
                            }
                            value={statistics.male.total}
                            percentage={statistics.male.percentage}
                        />

                        <StakeholderCounter
                            title={
                                <span>
                                    Stakeholders with <br /> disability
                                </span>
                            }
                            value={statistics.swd.total}
                            percentage={statistics.swd.percentage}
                        />
                        <StakeholderCounter
                            title={
                                <span>
                                    Project <br /> Duration
                                </span>
                            }
                            value={getProjectDuration()}
                        />
                    </div>

                    {/* add list */}
                    <div
                        className={
                            "p-6 items-between rounded-[8px] border-[2.5px] border-[#EDEFF7]"
                        }
                    >
                        <div className={"items-center space-x-5 flex"}>
                            <UserGroupIcon className={"h-12 w-12"} />
                            <div className={"text-[#0E1731] space-y-1"}>
                                <p className={" font-medium text-lg"}>
                                    Add Stakeholder
                                </p>
                                <p className={"font-light text-sm"}>
                                    Do you want to add stakeholder in a project
                                    ?
                                </p>
                            </div>
                        </div>
                        <div>
                            <Link
                                to={"/register-list"}
                                target={"_blank"}
                                className={
                                    "bg-[#F7F8FF] px-6 py-2.5 text-sm rounded-[4px] border-[#AAB6C4] text-[#838EAB] border-[1px]"
                                }
                            >
                                Add Stakeholder
                            </Link>
                        </div>
                    </div>
                    <StakeholderBreakdown stakeholders={stakeholders} />

                    <StakeholderBreakdownByLocation
                        stakeholders={stakeholders}
                    />
                </div>

                <StickyStakeholderDetails stakeholders={stakeholders} />
            </div>
        </section>
    );
};
export default StakeholderAnalytics;
