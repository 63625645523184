import EventIllustrator from "./event-illustrator";

const EmptyState = () => {
    return (
        <div className={"bg-gray-100 space-y-5 py-10 flex-col rounded center"}>
            <div className={"h-40 w-40 p-2 bg-gray-300 rounded-full "}>
                <EventIllustrator />
            </div>
            <div>
                <p className={"text-center text-gray-400"}>
                    No upcoming events or activities to display at the moment.
                    You can get started by creating events, <br /> by click the
                    link bellow
                </p>
            </div>
        </div>
    );
};
export default EmptyState;
