import UploadedFiles from "components/file-upload/uploaded-files";
import {useEffect, useRef, useState} from "react";
import Button from "components/Button";
import {ArrowLeftIcon} from "@heroicons/react/outline";
import {getValueFromLocalStorage} from "hooks/useLocalStorageState";
import {isEmptyObject} from "utils";
import useGlobalContext from "../../../../hooks/useGlobalContext";
// import { useMutation, useQueryClient } from "react-query";
// import { createGrievances } from "provider/api";

const AddAttachments = ({
                            isGBV,
                            activeGrievance,
                            setCurrentStep,
                            isCreating,
                            onSubmit: _handleSubmit,
                        }) => {
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const fileInputRef = useRef(null);
    const isEditing = !isEmptyObject(activeGrievance ?? {});
    const step1FormData = getValueFromLocalStorage(
        "grievanceStep1FormData",
        {}
    );
    const step2FormData = getValueFromLocalStorage(
        "grievanceStep2FormData",
        {}
    );
    const defendantFormData = getValueFromLocalStorage("defendantFormData", {});

    // add active grievance attachment to the uploaded files
    useEffect(() => {
        if (!isEmptyObject(activeGrievance ?? {})) {
            setUploadedFiles(activeGrievance?.attachments);
            setSelectedFiles(activeGrievance?.attachments);
        }
    }, [activeGrievance]);

    const {projectId} = useGlobalContext();
    /* const queryClient = useQueryClient(); */

    const handleUpload = () => {
        if (fileInputRef.current) {
            return fileInputRef.current.click(); // open file dialog
        }
    };

    const handleUploadFinish = (file) => {
        setUploadedFiles([...uploadedFiles, file]);
    };

    const handleChanges = ({target}) => {
        setSelectedFiles([...selectedFiles, ...target.files]);
    };

    // send post request to create new grievance
    /* const { mutate: _createGrievance, isLoading: isCreating } = useMutation(
        "create-grievance",
        createGrievances,
        {
            onSuccess: ({ data: grievance }) => {
                updateCache(grievance);
                handleClose();
            },
        }
    ); */

    // update grievance caches
    // const updateCache = (grievance) => {
    // 	return queryClient.setQueriesData(
    // 		"project-grievances",
    // 		(oldQueryData) => {
    // 			return {
    // 				...oldQueryData,
    // 				data: [grievance, ...oldQueryData.data],
    // 			};
    // 		}
    // 	);
    // };

    const handleSubmit = (e) => {
        e.preventDefault();
        const payload = {
            ...step1FormData,
            ...step2FormData,
            project: projectId,
            projectRisk: step1FormData.riskLevel,
            description: JSON.stringify(step2FormData.complaint),
            attachments: uploadedFiles,
            dateOfIncident: step1FormData.createdTime,
            isGBV,
            complainer: {
                fullName: step1FormData.complainer,
                email: step1FormData.email,
                phoneNumber: step1FormData.phoneNumber,
            },
        };

        if (isEditing) {
            payload["id"] = activeGrievance.id;
        }
        if (isGBV) {
            payload["defendant"] = defendantFormData;
            payload["projectRisk"] = step2FormData.riskLevel;
            payload["dateOfIncident"] = step2FormData.createdTime;
            payload["gbvType"] = defendantFormData?.gbvType;
            payload["nature"] = step2FormData?.gbvNature;
            delete payload?.department;
        }
        console.log("Grievance Form:: ", payload);
        _handleSubmit(payload);
        /* createGrievance(payload); */
    };

    return (
        // attachments
        <div>
            <form
                className={"col-span-2 space-y-8 p-5"}
                onSubmit={handleSubmit}
                action=""
            >
                <div>
                    <label
                        className="block mb-2 text-sm font-medium text-[#002F4F]"
                        htmlFor="inputFile"
                    >
                        Add attachments{" "}
                        <span className={"font-light"}>(Optional)</span>
                    </label>
                    <UploadedFiles
                        files={selectedFiles}
                        onUpload={handleUpload}
                        onUploadFinish={handleUploadFinish}
                        onRemove={setSelectedFiles}
                    />
                    {/*  submit button */}
                    <input
                        id={"inputFile"}
                        ref={fileInputRef}
                        onChange={handleChanges}
                        type="file"
                        multiple={true}
                        className={"hidden"}
                    />
                </div>

                <div className={"flex items-center pt-5 space-x-2"}>
                    <div className={""}>
                        <Button
                            onClick={() => setCurrentStep(2)}
                            outline={true}
                            block={true}
                            type="submit"
                            className={
                                " flex whitespace-nowrap border-secondary items-center space-x-2 text-sm"
                            }
                        >
                            <ArrowLeftIcon className={"h-5 w-5"}/>
                            <span>PREV STEP</span>
                        </Button>
                    </div>
                    <div className={"w-full"}>
                        <Button
                            loading={isCreating}
                            block={true}
                            type="submit"
                            className={"py-4 bg-secondary text-sm"}
                        >
                            ADD GRIEVANCE
                        </Button>
                    </div>
                </div>
            </form>
        </div>
    );
};
export default AddAttachments;
