import { useState } from "react";
import ImagesUpload from "./components/images-upload";
import SoundUpload from "./components/sound-upload";
import DocumentsUpload from "./components/documents-upload";
import AttendanceStats from "./components/attendance-stats";

const Tab = ({ isActive, onClick, name }) => {
    return (
        <div
            onClick={() => onClick()}
            className={`pb-3 cursor-pointer  ${isActive ? 'text-[#70CCBF] border-b-[2px] border-[#70CCBF] -mb-[2px] transition-all' : ''}`}>
            {name}
        </div>
    )
}


const RightPanel = () => {
    const [currentTab, setCurrentTab] = useState('Images');
    const [images, setImages] = useState([]);
    const [videoSound, setVideoSound] = useState([]);
    const [documents, setDocuments] = useState([]);


    return (
        <div className="h-full bg-[#EDF1F7] divide-y divide-[#E4E6EA] ">
            <div className="px-10  h-72 overflow-y-auto">
                {/* Tab Menu */}
                <div
                    className={'flex pt-6  bg-[#EDF1F7] sticky top-0 pr-4 z-50 text-[14px] items-center text-[#A0A2B3] justify-between border-b-[2px] border-[#E4E6EA]'}>
                    <Tab
                        isActive={currentTab === 'Images'}
                        onClick={() => setCurrentTab('Images')}
                        name={'Images'}
                    />

                    <Tab
                        isActive={currentTab === 'Sound'}
                        onClick={() => setCurrentTab('Sound')}
                        name={'Sound'}
                    />

                    <Tab
                        isActive={currentTab === 'Documents'}
                        onClick={() => setCurrentTab('Documents')}
                        name={'Documents'}
                    />
                </div>
                
                {/* Media upload tab details */}
                {currentTab === "Images" &&
                    <div className="pt-3"><ImagesUpload images={images} setImages={setImages}/></div>}
                {currentTab === "Sound" &&
                    <div className="pt-3"><SoundUpload videoSound={videoSound} setVideoSound={setVideoSound}/></div>}
                {currentTab === "Documents" &&
                    <div className="pt-3"><DocumentsUpload documents={documents} setDocuments={setDocuments}/></div>}
            </div>


            <div className={'overflow-hidden'} style={{height: "calc(100% - 18rem)"}}>
                <AttendanceStats/>
            </div>

        </div>
    )
};

export default RightPanel;