const getFormField = (activePlan, errors, register, stakeholders) => {
    return [
        {
            name: "stakeholder",
            hasError: !!errors?.stakeholder?.message,
            defaultValue: activePlan?.stakeholder,
            displayName: "name",
            valueName: "_id",
            register,
            options: stakeholders.map(({ _id, name } = {}) => ({ _id, name })),
        },
        {
            name: "activity",
            hasError: !!errors?.activity?.message,
            defaultValue: activePlan?.activity,
            register,
            placeholder: "Finish school",
        },
        {
            name: "frequency",
            hasError: !!errors?.frequency?.message,
            defaultValue: activePlan?.frequency,
            displayName: "name",
            valueName: "value",
            register,
            options: [
                { name: "Daily", value: 1 },
                { name: "Weekly", value: 2 },
                { name: "Monthly", value: 3 },
                { name: "Only once", value: 4 },
                { name: "Every 2 weeks", value: 5 },
                { name: "Every 3 weeks", value: 6 },
                { name: "Every weekday", value: 7 },
            ],
        },
        {
            name: "startingDate",
            hasError: !!errors?.startingDate?.message,
            defaultValue: activePlan?.startOn,
            register,
            type: "date",
        },
        {
            name: "endingDate",
            hasError: !!errors?.endingDate?.message,
            defaultValue: activePlan?.endingDate,
            register,
            type: "date",
        },
        {
            name: "channel",
            hasError: !!errors?.frequency?.message,
            defaultValue: activePlan?.frequency,
            register,
            options: ["Email", "Phone", "Physical", "Whatsapp", "Virtual"],
        },
        {
            name: "isMeeting",
            label: "Is meeting",
            defaultValue: true,
            register,
            type: "checkbox",
        },
    ];
};
export default getFormField;
