import Highcharts from 'highcharts'
import borderRadius from "highcharts-border-radius"
import HighchartsReact from 'highcharts-react-official'

borderRadius(Highcharts);

const StakeholderGroupByBarChart = ({statistics}) => {

    const options = {
        chart: {
            type: 'column',
            scrollablePlotArea: {
                minWidth: "100%",
            }
        },
        title: {
            enabled: false,
            text: ''
        },
        xAxis: {
            min: 0,
            gridLineColor: "#EBF0F3",
            gridLineWidth: 0.6,
            categories: ['Individual', 'Organisational', 'Community']
        },
        yAxis: {
            min: 0,
            gridLineColor: "#EBF0F3",
            gridLineWidth: 0.6,
            scrollbar: {
                enabled: true,
                showFull: false
            },
            title: {
                enabled: false,
                text: 'Stakeholder Activities by Status'
            },
            stackLabels: {
                enabled: true,
                style: {
                    fontWeight: 'normal',
                    color: ( // theme
                        Highcharts.defaultOptions.title.style &&
                        Highcharts.defaultOptions.title.style.color
                    ) || 'gray',
                    textOutline: 'none'
                }
            }
        },

        legend: {
            enabled: false,
            align: 'right',
            x: -10,
            verticalAlign: 'top',
            y: 10,
            floating: true,
            backgroundColor:
                Highcharts.defaultOptions.legend.backgroundColor || 'white',
            borderColor: '#CCC',
            borderWidth: 0.5,
            shadow: false
        },

        plotOptions: {
            column: {
                stacking: 'normal',
                pointWidth: 45,
                dataLabels: {
                    enabled: false
                }
            }
        },

        tooltip: {
            headerFormat: '<b>{point.x}</b><br/>',
            pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
        },

        series: [
            {
                name: 'high',
                data: [
                    statistics?.individual[0],
                    statistics?.organizational[0],
                    statistics?.community[0],
                ],
                color: '#168ADC'
            },
            {
                name: 'medium',
                data: [
                    statistics?.individual[1],
                    statistics?.organizational[1],
                    statistics?.community[1]
                ],
                color: '#A5DED4'
            },
            {
                name: 'low',
                data: [
                    statistics?.individual[2],
                    statistics?.organizational[2],
                    statistics?.community[2],
                ],
                color: '#FDD1A9'
            },

            {
                name: 'no engaged',
                data: [
                    statistics?.individual[3],
                    statistics?.organizational[3],
                    statistics?.community[3]
                ],
                color: '#FDD1A9'
            }
        ]

    }

    return (
        <div className={'h-full w-full highcharts relative '}>
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
            />
        </div>
    )
}
export default StakeholderGroupByBarChart;
