import { ChevronRightIcon } from "@heroicons/react/outline";
import { Transition } from "@headlessui/react";
import { useState } from "react";
import { useOutletContext } from "react-router-dom";

import { CreateMarkup } from "utils";

const GoalsAndConcern = () => {
    const { isLoading, getCurrentStakeholder } = useOutletContext();
    const [isExpanded, setIsExpanded] = useState(true);

    if (isLoading) {
        return (
            <div className={" w-ful h-4/5  flex justify-center items-center "}>
                <div className={"flex space-y-4 items-center flex-col"}>
                    <div
                        className={
                            "h-12 w-12 border-accent border-t-transparent border-2 animate-spin rounded-full"
                        }
                    ></div>
                    <p className={"text-sm text-black/40"}>
                        Loading goals and concern details...
                    </p>
                </div>
            </div>
        );
    }
    const currStakeholder = getCurrentStakeholder();

    return (
        <div className={"space-y-12"}>
            <div className={"border-[1px] border-[#E6E6E6] px-8 rounded"}>
                <div>
                    <p
                        className={
                            "text-secondary text-[15px] tracking-wide py-3 border-b-[1px] border-[#E6E6E6]"
                        }
                    >
                        Stakeholder Concern To The Project
                    </p>
                </div>

                <div className={"text-sm leading-relaxed  py-4"}>
                    <p
                        dangerouslySetInnerHTML={CreateMarkup(
                            currStakeholder?.concern
                        )}
                        className={"text-[#989898] font-light"}
                    ></p>

                    {/*<p dangerouslySetInnerHTML={CreateMarkup(stakeholderDetails.concern)} > </p>*/}
                </div>
            </div>

            <div className={"space-y-2"}>
                <p className={"text-secondary font-light"}>
                    Stakeholder Goals To the project
                </p>
                <p
                    className={
                        "text-sm leading-relaxed font-light text-[#989898]"
                    }
                >
                    There are main goals and outcomes of the project but the
                    stakeholder also have their own goals <br /> And exceptions
                    as listed below.
                </p>

                <div className={"pt-5 space-y-3"}>
                    <div
                        onClick={() => setIsExpanded(!isExpanded)}
                        className={
                            "text-[#009EE2] flex items-center cursor-pointer"
                        }
                    >
                        <ChevronRightIcon
                            className={`h-4 w-4 ${
                                isExpanded ? "rotate-90" : "rotate-1"
                            } transition-transform ease-in-out`}
                        />
                        <p className={" text-sm font-light"}>
                            View expected project goals
                        </p>
                    </div>

                    <Transition
                        show={isExpanded}
                        enter="transition-opacity duration-500"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity duration-150"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div
                            className={
                                "text-[#989898] text-sm font-light leading-relaxed space-y-5"
                            }
                        >
                            {currStakeholder?.goals.map((goal, idx) => (
                                <div
                                    key={idx}
                                    className={
                                        "border-[1px] px-3 py-2 border-light-gray rounded border-l-2 border-l-secondary"
                                    }
                                >
                                    <p>{goal}</p>
                                </div>
                            ))}
                        </div>
                    </Transition>
                </div>
            </div>
        </div>
    );
};
export default GoalsAndConcern;
