const Singida = ({ color, isLoading = false }) => {
    return (
        <path
            className={`${isLoading ? "animate-pulse" : ""}`}
            id="Path_450"
            data-name="Path 450"
            d="M395.945,211.843l.158,1.383-.647,1.306-.351,1.018-9.536,6.547-.206,1.311.8,1.315.121,1.459-.036,1.464.5,1.593.707,1.538.623,2.666.9,1.307,1.067,1.141.481,1.238.7,1.145.582.372.416.506.279.244.372.087.17.636.089.759.99,1.63,1.3,1.409.437,1.137-.8.991-1.216,1.062-.194,1.586-.379,1.5-1.006,1.476-1.39,3.27-.146,4.561-.848,3.537.323,3.417.792,1.39.368,1.551-.017,1.417-.651,1.254-.4,3.536.691,6.74-1.083,2.626-4.926.228.634,3.667.182,4.186-1.568-.482-1.624-.189-1.62-.409-1.5-.133-.59.574-.715.454-.78.3-.671.513-1.665.946-1.442,1.164-.448,3.813-1.459,2.767-11.5,2.405-1.972,2.377.064,1.229-.767.64-1.827.269-1.822-.082-1.754-.644-1.794-.016-1.341,1.485-1.2,1.693-3.228,2.2-3.431,1.921-1.774-.8-1.628-1.163-.771-.2-.638-.375-.154-1.62-.7-1.646-.125-1.069-.5-.909-2.247-.686-.764-.788-.618-.91-.925-1.739-1.358-1.318L327.258,302l-.945-1.574-.3-2.15-.55-2.045-.893-1.293-1.188-1.01,1.7-3.476-.227-4.169.259-4.178-.412-.865,1.867-2.225.6-.348L330,277.594l3.98-4.055,1.555-1.124.84-.354.962-.134.428-.336,1.374-2.218,2.287-1.923,2.7-2.012-.154-1.27-.441-1.349.109-3.282,2.048-7.64.032-3.436.1-.77.227-.7,1.047-.826.808-1.645-.121-1.766-1.176-.875-.857-1.021,1.729-2.643,2.542-2.157,1.454-1.488,1.1-1.705-.853-1.072-3.467-3.5-1.341-.864-1.257-1.022-1.762-1.049-1.988-.738-1.135-1.154-.388-1.66-.234-1.647,1.107-3.079-.336-1.535-.655-1.878-.024-1.971,1.4-3.785-.085-3.367-.433-3.316.844-3.577,4.643-6.014L348.292,188l2.012-3.468.909-.771,1.249.426,1.232-.121.287-1.242.379-.628.311-.707.137-1.715,1.491-1.284,2.372-.615.99-1.063,1.168-.869,1.4-.423,1.426-.294,6.647-2.7.5-.834,1.047-.856,1.406-.261,2.465,2.25,2.683,1.969,1.439,2.961,3.342,3.635.1,1.167-2.238,2.98-2.085,3.783-1.454,3.963,1.765,1.964,2.663,1.58,1.947,2.241,1.435,3.172,2.606,1.269.372,1.553-.016,1.65,1.572,1.08,2.291.4,1.475,2.757Z"
            transform="translate(-171.236 -60.369)"
            fill={color}
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="0.5"
        />
    );
};
export default Singida;
