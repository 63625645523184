import { useState } from "react";

const Image = ({ imageUrl, alt }) => {
	const [isImageFullLoaded, setIsImageFullLoaded] = useState(false);

	return (
		<div
			onLoad={() => setIsImageFullLoaded(true)}
			className={`h-full w-full ${
				isImageFullLoaded
					? "bg-gray-gary-100 animate-none"
					: "animate-pulse bg-secondary/20"
			}`}
		>
			<img
				src={imageUrl}
				alt={alt}
				loading={"lazy"}
				className={`h-full w-full object-cover 
                ${
					isImageFullLoaded
						? "scale-100 opacity-100"
						: "scale-50 opacity-0"
				}
                 origin-center enable-transition`}
			/>
		</div>
	);
};
export default Image;
