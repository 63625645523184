import { ArrowRightIcon } from "@heroicons/react/outline";
import Button from "components/Button";
import Input from "components/form-control/Input";
import Option from "components/form-control/Option";
import Select from "components/form-control/Select";
import { GBVTheDefendantFormValidation } from "forms/validation";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { ArrowLeftIcon } from "@heroicons/react/outline";
import { saveValueToLocalStorage } from "hooks/useLocalStorageState";

const TheDefendantForm = ({ activeGrievance, setCurrentStep }) => {
	const {
		handleSubmit,
		register,
		reset: clearForm,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(GBVTheDefendantFormValidation),
	});

	const onSubmit = (formData) => {
		saveValueToLocalStorage("defendantFormData", formData);
		setCurrentStep(3);
	};
	return (
		<form
			onSubmit={handleSubmit(onSubmit)}
			className={"grid pb-8 pt-4 px-8 gap-x-4 space-y-4 grid-cols-2"}
		>
			{/* complainer's name*/}
			<div className={"space-y-1 col-span-2"}>
				<label
					className="block mb-2 text-sm font-medium text-[#002F4F]"
					htmlFor=""
				>
					Defendant's name
				</label>
				<Input
					defaultValue={activeGrievance?.defendant?.fullName ?? ""}
					register={register}
					isError={!!errors?.fullName?.message}
					name={"fullName"}
					className={"text-sm"}
					placeholder={"eg Boniface Kato Baraka"}
				/>
			</div>

			{/* complainer's email */}
			<div className={"space-y-1 col-span-2 2xl:col-span-2"}>
				<label
					className="block mb-2 text-sm font-medium text-[#002F4F]"
					htmlFor=""
				>
					Defendant's email
				</label>
				<Input
					defaultValue={activeGrievance?.defendant?.email ?? ""}
					register={register}
					isError={!!errors?.email?.message}
					name={"email"}
					className={"text-sm"}
					placeholder={"eg 6onnykato@gmail.com"}
				/>
			</div>

			{/* complainer's phone-number */}
			<div className={"space-y-1 col-span-2 2xl:col-span-2 "}>
				<label
					className="block mb-2 text-sm font-medium text-[#002F4F]"
					htmlFor=""
				>
					Defendant's phone number
				</label>
				<Input
					defaultValue={activeGrievance?.defendant?.phoneNumber ?? ""}
					type={"tel"}
					register={register}
					isError={!!errors?.phoneNumber?.message}
					name={"phoneNumber"}
					className={"text-sm"}
					placeholder={"eg 0759144442"}
				/>
			</div>

			<div className={`2xl:col-span-2 `}>
				<label
					htmlFor="createdDate"
					className="block mb-2 text-sm font-medium text-[#002F4F]"
				>
					Defendant's Gender
				</label>
				<Select
					isError={!!errors.gender?.message}
					defaultValue={activeGrievance?.defendant?.gender ?? ""}
					{...register("gender")}
					className={"text-sm"}
				>
					<option value="" disabled={true}>
						Choose
					</option>
					<Option value={"M"} text="Male" />
					<Option value={"F"} text="Female" />
				</Select>
			</div>

			{/* complainer's phone-number */}
			<div className={"space-y-1 2xl:col-span-2 "}>
				<label
					className="block mb-2 text-sm font-medium text-[#002F4F]"
					htmlFor=""
				>
					Defendant's Age
				</label>
				<Input
					isPhoneNumber
					defaultValue={activeGrievance?.defendant?.age ?? ""}
					register={register}
					isError={!!errors?.age?.message}
					name={"age"}
					className={"text-sm"}
				/>
			</div>

			<div className={`2xl:col-span-2 col-span-2 `}>
				<label
					htmlFor="createdDate"
					className="block mb-2 text-sm font-medium text-[#002F4F]"
				>
					Type of GBV
				</label>
				<Select
					isError={!!errors.gbvType?.message}
					defaultValue={activeGrievance?.gbvType ?? ""}
					{...register("gbvType")}
					className={"text-sm"}
				>
					<option value="" disabled={true}>
						Choose
					</option>
					<Option
						value={"gender discrimination"}
						text="Gender Discrimination"
					/>
					<Option value={"racial discrimination"} />
				</Select>
			</div>

			{/*  submit button */}

			<div className={""}>
				<Button
					onClick={() => setCurrentStep(1)}
					outline={true}
					block={true}
					type="button"
					className={
						" flex whitespace-nowrap items-center space-x-2 text-sm"
					}
				>
					<ArrowLeftIcon className={"h-5 w-5"} />
					<span>PREV STEP</span>
				</Button>
			</div>

			<div className={""}>
				<Button
					block={true}
					type="submit"
					className={"flex items-center space-x-2 text-sm"}
				>
					<span>NEXT STEP</span>
					<ArrowRightIcon className={"h-5 w-5"} />
				</Button>
			</div>
		</form>
	);
};

export default TheDefendantForm;
