import { ArrowRightIcon, CircleCheckIcon } from "../../../../svg";
import useGlobalContext from "../../../../hooks/useGlobalContext";
import { Link } from "react-router-dom";
import { baseRotes } from "../../../../routes/constants";

const StickyStakeholderDetails = ({ stakeholders = [] }) => {
    const { getStakeholderByPriority = () => [] } = useGlobalContext();
    const stakeholderByPriority = getStakeholderByPriority().slice(0, 8);

    const getStakeholderName = (stakeholder) => {
        return stakeholder.name.trim().replace(" ", "-").toLowerCase();
    };
    return (
        <div
            className={"w-[345px] fixed top-[64px] right-0 space-y-8 px-8 pt-5"}
        >
            <div
                className={
                    "w-full space-y-8 text-white bg-secondary p-5 rounded-[8px]"
                }
                style={{ boxShadow: " 0px 9px 20px #62879957" }}
            >
                <div className={"space-y-2"}>
                    <p className={"tracking-wide"}>All Stakeholders</p>
                    <p className={"text-4xl font-muli font-semibold"}>
                        {stakeholders.length}
                    </p>
                </div>

                <Link
                    to={baseRotes.STAKEHOLDER_BASE_ROUTE}
                    target={"_blank"}
                    className={"text-[13px] flex items-center space-x-2"}
                >
                    <p className={"tracking-wider"}>VIEW STAKEHOLDERS</p>
                    <ArrowRightIcon className={"h-8"} />
                </Link>
            </div>

            {/* list list */}
            <div
                className={
                    "bg-[#F6F6F8] border-[1px] overflow-hidden border-[#AAB6C4BC] rounded-[8px]"
                }
            >
                <div className={"p-3 bg-[#CED5DD] text-[#002F4F] pl-10"}>
                    <p className={"text-[15px] font-medium"}>
                        Most influential stakeholders
                    </p>
                </div>

                <div className={"py-6 px-4 space-y-2"}>
                    {stakeholderByPriority.map((stakeholder) => (
                        <Link
                            to={`${
                                baseRotes.STAKEHOLDER_BASE_ROUTE
                            }/${getStakeholderName(stakeholder)}/overview`}
                            target={"_blank"}
                            key={stakeholder?._id}
                            className={
                                "flex pl-2 py-1.5 rounded-[4px] items-center hover:bg-[#EDEFF7]/80 " +
                                "space-x-5 text-[#3E608A] mx-4"
                            }
                        >
                            <CircleCheckIcon className={"h-4 w-4"} />
                            <p className={"capitalize text-[15px]"}>
                                {stakeholder?.name}
                            </p>
                        </Link>
                    ))}
                </div>

                <div className={"px-10 pb-6 pt-2"}>
                    <Link
                        to={baseRotes.STAKEHOLDER_BASE_ROUTE}
                        target={"_blank"}
                    >
                        <button
                            className={
                                "bg-[#F5F7FB] text-xs border-[1px] rounded-[4px] border-[#AAB6C4BC] w-full" +
                                " text-[#A0A8B5]  px-6 py-2.5 tracking-wide "
                            }
                        >
                            VIEW MORE
                        </button>
                    </Link>
                </div>
            </div>
        </div>
    );
};
export default StickyStakeholderDetails;
