import {useContext, useState, createContext, useEffect} from "react";
import Modal from "components/Modal";
import Input from "components/form-control/Input"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/free-solid-svg-icons";
import SearchSuggestions from "./search-suggestions";
import useUserRole from "hooks/useUserRole";
import SelectedPermissions from "../permissions/selected-permissions";
import {useMutation, useQueryClient} from "react-query";
import {updateRolePermission} from "provider/api";
import Button from "components/Button";
import {isEmptyObject} from "../../../../utils";

export const PermissionContext = createContext(null);
export const usePermissionContext = () => useContext(PermissionContext)
const PermissionForm = () => {
    const [permissions, setPermissions] = useState([])
    const [suggestions, setSuggestions] = useState([]);
    const [addedPermission, setAddedPermission] = useState([]);
    const [removedPermission, setRemovedPermission] = useState([]);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const queryClient = useQueryClient();
    const {
        activeRole, setActiveRole, rolePermissions = [],
        isLoadingPermissions,
        showPermissionForm, setShowPermissionForm
    } = useUserRole()


    const {isLoading, mutate: updateRole} = useMutation('update-role-permission', updateRolePermission, {
        onSuccess: ({data: updatedRole}) => {
            updateRoleCache(updatedRole);
            handleClose()
        }
    })

    const updateRoleCache = (role) => {
        return queryClient.setQueriesData('user-roles', (oldQueryData) => {
            return {
                ...oldQueryData,
                data: [...oldQueryData.data.map((roleItem) => {
                    if (roleItem?._id === role?._id) {
                        return {...role, permissions: getPermissions(role?.permissions)};
                    }
                    return roleItem
                })]
            }
        })
    }

    //  Get permission-names by using permissions-id returned by response after update role-permissions
    const getPermissions = (permissionsId = []) => {
        return permissionsId.map((permissionId) => {
            const p = rolePermissions.find((permission) => {
                return permission?._id === permissionId;
            });
            return {_id: permissionId, displayName: p?.displayName}
        })
    }

    useEffect(() => {
        if (!isEmptyObject(activeRole)) {
            setPermissions(activeRole?.permissions);
        }
    }, [activeRole]);

    const handleChange = ({target}) => {
        const searchKeyword = target.value.toLowerCase();
        if (!searchKeyword) {
            return setSuggestions([])
        }
        if (!isLoadingPermissions) {
            setSuggestions(rolePermissions.filter((permission) => {
                return permission?.displayName?.toLowerCase().includes(searchKeyword);
            }))
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const payload = {
            permissions: {
                added: addedPermission.map(({_id}) => _id),
                roleId: activeRole?._id,
                removed: removedPermission.map(({_id}) => _id),
            }
        }
        console.log(payload)
        updateRole(payload)
    }

    const handleClose = () => {
        setActiveRole({})
        setShowPermissionForm(false)
    }

    return (
        <Modal isOpen={showPermissionForm} onClose={handleClose}>
            <PermissionContext.Provider value={{
                suggestions, setSuggestions,
                permissions, setPermissions, showSuggestions, setShowSuggestions,
                addedPermission, setAddedPermission,
                removedPermission, setRemovedPermission,
            }}>
                <form onSubmit={handleSubmit}
                      className={'bg-white  text-dark2 rounded-xl  w-2/6 mt-[10vh]'}>
                    <header className={'py-3 px-5 bg-gray-300 rounded-t-xl items-between '}>
                        <h3 className={'font-medium text-dark'}>Manage Permissions</h3>
                        <button
                            onClick={() => setShowPermissionForm(false)}
                            className="-mr-2 border-2 center border-[#192B40]/50 w-6 h-6 bg-[#628799] text-white rounded-full"
                        >
                            <FontAwesomeIcon icon={faXmark}/>
                        </button>
                    </header>

                    <div className={'p-5 space-y-2'}>
                        <p className={'font-semibold capitalize text-lg'}>{activeRole?.name}</p>

                        <div className={'space-y-5'}>
                            <div className={'space-y-2 relative '}>
                                <label htmlFor="roleName text-sm">Select Permission</label>
                                <Input onChange={handleChange} id={'roleName'} className={`bg-[#f4f5f5] py-2.5 text-sm`}
                                       placeholder={'Search permission'}/>
                                <SearchSuggestions/>
                            </div>

                            <SelectedPermissions permissions={permissions}/>
                        </div>

                        <div className={'pt-3'}>
                            <Button type={'submit'} loading={isLoading}
                                    className={'py-3 text-sm px-4 text-white  flex items-center space-x-2 bg-primary rounded' +
                                        ' hover:opacity-90'}>
                                Save Changes
                            </Button>
                        </div>
                    </div>
                </form>
            </PermissionContext.Provider>
        </Modal>
    )
}
export default PermissionForm;