const ArushaSvg = ({ color, isLoading = false }) => {
    return (
        <path
            className={`${isLoading ? "animate-pulse" : ""}`}
            id="Path_436"
            data-name="Path 436"
            d="M405.87,65.966l6.618,3.695,8.388,4.69,8.392,4.69,8.392,4.69,8.388,4.69,8.4,4.687,8.392,4.687,8.392,4.692,8.392,4.688,8.4,4.693,2.994,1.675-.024-.005-1.722-.39-3.228-1.337-1.273-.746-1.414-.166-.763.265-.675-.247-1.988.065-1.806,1.2-2.339,1.916-.986,1.034-1.305.562-2.611.769-.9,2.574.06,1.878.36,1.833.949.92,1.253.548,2.727,1.8,1.459,2.681.081,7.1-1.362.514-1.24.836-.728,1.024-.893.806-2.937.032-1.026,1.134-.962,1.294-1.2.279-.739-1.312-.7-.48-.724-.2-2.853.146-.279.3,0,.407-.287.648-.771.267-.812.706-.756.156-.707.274-2.335,3.069-.643,1.588.34,4.479.876,4.951-.053,2.244-.557.288-.642-.261-1.107-.045-1.071.343-1.834.158-1.758.343-.379.8-1.816.233-1.942-.819-8.833-12.83-1.863-3.5-1.285-1.84-2.255.761-1.895,2.506-1.394,1.221-1.039,1.318-.118,1.174-.8.223-.852-2.7.274-2.853.966-2.814-.517-1.858-1.035-.142-.231,2.727-.679,1.4-4.643,1.241.122,1.5-.683,1.032-1.766-.241-1.184-1.223.767-1.326.994-1.132-1.221-.516-1.576-.31L412.719,141l-1.653.014-2.594,2.622-1.555.569-1.362.939-1.1.361-1.2-.016-3.721,2.286-.364.48-.125.575-.546.583-2.295.7-1.354.725-1.483.618-1.349.729-2.683-1.969-2.465-2.25-.432-1.515.15-1.569,1.047-1.486,1.341-1.223.238-.557.064-.6.767-.326.921.251,1.062-1.011.339-27.95,1.362.2,1.475,1.188.929,1.439,1.491.475.667-.412.606-.506,2.133-.959,1.071-2,2.582-11.151-.335-3.782,1.616-4.58.945-.445,1.1-.354.99-2.1.582-2.307-.55-1.941-2.223-.661-1.475-.96-.311-1.751.84-15.417Z"
            transform="translate(-184.858 -37.77)"
            fill={color}
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="0.5"
        />
    );
};
export default ArushaSvg;
