import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import dayjs from "dayjs";

import { useMutation } from "react-query";
import Modal from "components/Modal";
import FormHeader from "./form-header";
import UnstableInput from "components/unstable/input";
import Textarea from "components/unstable/textarea";
import Button from "../../../../components/Button";
import { createProjectPhase, updateProjectPhase } from "provider/api";
import useGlobalContext from "hooks/useGlobalContext";
import useUpdateCache from "hooks/useUpdateCache";
import { cacheActions, queryKeys } from "../../../../constants";
import { isEmptyObject } from "../../../../utils";

const PhaseForm = ({ activePhase = {}, toast }) => {
    const [showForm, setShowForm] = useState(true);
    const navigate = useNavigate();
    const { projectId } = useGlobalContext();
    const updateCache = useUpdateCache();
    const isUpdate = !isEmptyObject(activePhase);

    const handleClose = () => {
        setShowForm(false);
        navigate(-1, { replace: true });
    };

    const validationSchema = Yup.object({
        name: Yup.string().required(),
        description: Yup.string().required(),
        startDate: Yup.date().required(),
        endDate: Yup.date().required(),
    });

    const {
        handleSubmit,
        register,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(validationSchema),
    });

    const formatDate = (date) => {
        if (date) {
            return dayjs(date).format("YYYY-MM-DD");
        }
        return "";
    };

    const fields = [
        {
            name: "name",
            hasError: !!errors?.name?.message,
            defaultValue: activePhase?.name,
            register,
            placeholder: "implementation",
        },
        {
            name: "startDate",
            hasError: !!errors?.startDate?.message,
            defaultValue: formatDate(activePhase?.startDate),
            register,
            type: "date",
        },
        {
            name: "endDate",
            hasError: !!errors?.endDate?.message,
            defaultValue: formatDate(activePhase?.endDate),
            register,
            type: "date",
        },
        {
            name: "description",
            hasError: !!errors?.description?.message,
            defaultValue: activePhase?.description,
            register,
            type: "date",
        },
    ];

    const updateCacheAndCloseForm = async (data, action) => {
        await updateCache(queryKeys.PROJECT_PHASES, data, action);
        toast.success(
            `Project phase is ${
                isUpdate ? "updated" : "created"
            } successfully..`
        );
        handleClose();
    };

    const { mutate: _createPhase, isLoading } = useMutation(
        "create-project-phase",
        createProjectPhase,
        {
            onSuccess: async ({ data }) => {
                await updateCacheAndCloseForm(data, cacheActions.ADD);
            },
            onError: ({ message }) => toast.error(message),
        }
    );

    const { mutate: _updatePhase, isLoading: isUpdating } = useMutation(
        "update-project-phase",
        updateProjectPhase,
        {
            onSuccess: async ({ data }) => {
                await updateCacheAndCloseForm(data, cacheActions.UPDATE);
            },
            onError: ({ message }) => toast.error(message),
        }
    );

    const onSubmit = (formData) => {
        const payload = {
            ...formData,
            project: projectId,
        };
        if (!isUpdate) {
            return _createPhase(payload);
        } else {
            _updatePhase({
                ...activePhase,
                ...formData,
            });
        }
    };

    return (
        <Modal onClose={handleClose} isOpen={showForm}>
            <form
                onSubmit={handleSubmit(onSubmit)}
                className={"bg-white rounded-lg overflow-hidden w-[45%]"}
            >
                <FormHeader onClose={handleClose} />
                <div className={"space-y-4 px-10 py-7"}>
                    {fields.map((props) => {
                        if (props.name === "description") {
                            return <Textarea key={props.name} {...props} />;
                        }
                        return <UnstableInput key={props.name} props={props} />;
                    })}
                    <Button
                        loading={isLoading || isUpdating}
                        type={"submit"}
                        className={
                            "border-[2px] py-3.5 border-secondary bg-secondary"
                        }
                    >
                        {isUpdate ? "Add Phase" : "Update Phase"}
                    </Button>
                </div>
            </form>
        </Modal>
    );
};
export default PhaseForm;
