// Third-part imports
import { useContext, useEffect, useState } from "react";
import { useMutation } from "react-query";

// Local imports
import { FormContext } from "../index";
import Label from "components/form-control/Label";
import {
    getValueFromLocalStorage,
    saveValueToLocalStorage,
} from "hooks/useLocalStorageState";
import { UnstableComboboxInput } from "components/combobox";
import useGlobalContext from "hooks/useGlobalContext";
import { updateStakeholderDetails } from "provider/api";
import { cacheActions, localStorageKeys, queryKeys } from "constants";
import { isEmptyObject } from "utils";
import ActionButtons from "../_partials/action-buttons";
import FormHeader from "components/form-header/form-header";
import { toast } from "react-toastify";
import useUpdateCache from "hooks/useUpdateCache";

const RelationshipHolder = () => {
    const { stakeholderFrom: stakeholderLSKeys } = localStorageKeys;
    const [currentStep, setCurrentStep] = useContext(FormContext);
    const [stakeholderList, setStakeholderList] = useState([]);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const { getIndividualStakeholder, isLoading } = useGlobalContext();
    const updateCache = useUpdateCache();

    // Data from local storage
    const isUpdatingStakeholder = getValueFromLocalStorage(
        stakeholderLSKeys.IS_UPDATING_DETAILS,
        false
    );
    const basicDetails = getValueFromLocalStorage(
        "stakeholderBasicDetails",
        {}
    );
    const contactPerson = getValueFromLocalStorage(
        stakeholderLSKeys.CONTACT_PERSON,
        {}
    );

    // Update list-list by add individual-stakeholders from global state
    useEffect(() => {
        const stakeholders = getIndividualStakeholder();
        if (stakeholders.length) setStakeholderList(stakeholders);
    }, [isLoading]);

    const [relationshipHolder, setRelationshipHolder] = useState(
        getValueFromLocalStorage(stakeholderLSKeys.RELATION_SHIP_HOLDER, {})
    );

    const updateStakeholderCacheAndAdvanceCurrStep = async ({
        data: stakeholder,
    }) => {
        await updateCache(
            queryKeys.STAKEHOLDERS,
            stakeholder,
            cacheActions.UPDATE
        );
        advanceTheCurrentStep();
    };

    const { mutate: updateStakeholder } = useMutation(
        "update-list-details",
        updateStakeholderDetails,
        {
            onSuccess: updateStakeholderCacheAndAdvanceCurrStep,
            onError: (e) => {
                toast.error("Oops Something goes wrong, please try again");
            },
        }
    );

    const advanceTheCurrentStep = () => {
        const newStep = currentStep + 1;
        setCurrentStep(newStep);
        saveValueToLocalStorage(stakeholderLSKeys.CURRENT_STEP, newStep);
        toast.success("Data has been saved successfully..");
    };

    const updateStakeholderWithRelationshipHolderDetails = () => {
        const updatedBasicDetails = {
            ...basicDetails,
            description: JSON.stringify(basicDetails.description),
        };

        const updatedContactPerson = {
            ...contactPerson,
            description: JSON.stringify(contactPerson.description),
        };

        const updatedStakeholder = {
            ...updatedBasicDetails,
            contactPerson: updatedContactPerson,
            relationshipHolder: relationshipHolder,
        };

        updateStakeholder(updatedStakeholder);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsSubmitted(true);
        if (isEmptyObject(relationshipHolder)) return;

        saveValueToLocalStorage(
            stakeholderLSKeys.RELATION_SHIP_HOLDER,
            relationshipHolder
        );

        if (isUpdatingStakeholder) {
            return updateStakeholderWithRelationshipHolderDetails();
        }
        advanceTheCurrentStep();
    };

    const saveStakeholderDetailsAndAdvanceToNextStep = () => {
        const basicDetails = getValueFromLocalStorage(
            stakeholderLSKeys.BASIC_DETAILS,
            {}
        );
        const stakeholder = {
            ...basicDetails,
            hasRelationshipHolder: false,
        };
        saveValueToLocalStorage(stakeholderLSKeys.BASIC_DETAILS, stakeholder);
        advanceTheCurrentStep();
    };

    const handlePrevious = () => {
        const { hasContactPerson: hasPerson } = basicDetails;

        const lastStep = hasPerson ? currentStep - 1 : currentStep - 2;
        setCurrentStep(lastStep);
        saveValueToLocalStorage(stakeholderLSKeys.CURRENT_STEP, lastStep);
    };

    const nextBtnLabel = !isEmptyObject(relationshipHolder)
        ? isUpdatingStakeholder
            ? "SAVE CHANGES"
            : "NEXT"
        : "ADD LATER";

    const nextBtnType = relationshipHolder ? "submit" : "button";

    const handleNext = () => {
        if (isEmptyObject(relationshipHolder)) {
            saveStakeholderDetailsAndAdvanceToNextStep();
        }
    };
    return (
        <div className={" space-y-6"}>
            <FormHeader
                title="Relationship Holder"
                description=" Contact details of the person who is going to be the centre
                    of communication with this stakeholder"
            />

            <form onSubmit={handleSubmit} className="py-3 space-y-5 pr-40">
                <div className={" space-y-2 "}>
                    <div className={" space-y-2 "}>
                        <Label
                            text="Search stakeholder by name"
                            htmlFor="relationshipHolder"
                        />
                        <UnstableComboboxInput
                            className="border-[1.8px]"
                            name="relationshipHolder"
                            options={stakeholderList}
                            onChange={setRelationshipHolder}
                            defaultOption={relationshipHolder}
                            displayItemName={"name"}
                            isLoading={isLoading}
                            hasError={
                                isSubmitted && isEmptyObject(relationshipHolder)
                            }
                        />
                    </div>
                </div>

                <ActionButtons
                    onPrevious={handlePrevious}
                    nextBtnLabel={nextBtnLabel}
                    nextBtnType={nextBtnType}
                    onNext={handleNext}
                />
            </form>
        </div>
    );
};
export default RelationshipHolder;
