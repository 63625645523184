import ActionButtons from "../_partials/action-buttons";
import { useMutation } from "react-query";
import React, { Fragment, useEffect, useState } from "react";
import ParticipantCard from "../participant/participant-card";
import { useNavigate, useOutletContext } from "react-router-dom";
import { createProjectEvent, updateProjectEvent } from "provider/api";
import CustomCheckBox from "components/CustomCheckBox";

import {
    getValueFromLocalStorage,
    saveValueToLocalStorage,
} from "hooks/useLocalStorageState";
import useNotification from "hooks/useNotification";
import useGlobalContext from "hooks/useGlobalContext";
import { localStorageKeys } from "../../../../../../../../../constants";
import clearForm from "../_partials/clear-form";

const SetNotification = () => {
    const { meetingForm: meetingLSKeys } = localStorageKeys;
    const navigate = useNavigate();
    const [setCurrStep] = useOutletContext();
    const { setNotification } = useNotification();
    const [isAllSelected, setIsAllSelected] = useState(false);
    const { projectId } = useGlobalContext();
    const meetingTime = getValueFromLocalStorage("meetingTime", {});
    const meetingChannel = getValueFromLocalStorage("meetingChannel", {});
    const meetingRemainder = getValueFromLocalStorage("meetingRemainder", []);
    const meetingBasicInfo = getValueFromLocalStorage("meetingBasicInfo", {});
    const meetingBudget = getValueFromLocalStorage("meetingBudget", {});

    const isEditingEvent = getValueFromLocalStorage("isEditingProjectEvent", 0);
    // const activeProjectEvent = getValueFromLocalStorage("activeProjectEvent", {});

    const [allParticipants, setAllParticipants] = useState(
        getValueFromLocalStorage(meetingLSKeys.PARTICIPANTS, [])
    );

    const isEditing = getValueFromLocalStorage("isEditingProjectEvent", false);

    const updateCacheClearFormAndNavigateToEventList = async () => {
        await clearForm();
        setNotification("EventLayout is updated successfully");
        navigate("/engagement/activities", { replace: true });
    };

    const { mutate: createEvent, isLoading: isCreatingEvent } = useMutation(
        createProjectEvent,
        {
            onSuccess: updateCacheClearFormAndNavigateToEventList,
        }
    );

    const { mutate: updateEvent, isLoading: isUpdatingEvent } = useMutation(
        updateProjectEvent,
        {
            onSuccess: updateCacheClearFormAndNavigateToEventList,
        }
    );

    const goBack = () => {
        navigate("/event-registration/meeting/set-meeting-budget");

        setCurrStep(7);
        saveValueToLocalStorage("meetingFormCurrentStep", 7);
    };

    const buildPayload = () => {
        return {
            ...meetingBasicInfo,
            status: 0,
            isMeeting: 1,
            ...meetingTime,
            ...meetingChannel,
            project: projectId,
            budgetApprover: meetingBudget?.budgetApprover?._id,
            budgetTitle: meetingBudget?.budgetTitle,
            budgetFiles: meetingBudget?.budgetFiles,
            participants: getParticipants(),
            reminderInterval: meetingRemainder,
            description:
                typeof meetingBasicInfo.description === "string"
                    ? meetingBasicInfo.description
                    : JSON.stringify(meetingBasicInfo.description),
            eventDate: new Date(meetingTime.eventDate).setHours(23, 59, 59),
        };
    };

    const submitMeeting = (e) => {
        e.preventDefault();

        const payload = buildPayload();
        const action = isEditingEvent ? updateEvent : createEvent;
        console.log(payload);
        action(payload);
    };

    const getParticipants = () => {
        return allParticipants.map((person) => {
            return { stakeholder: person?._id, notify: person?.isSelected };
        });
    };
    const markAllParticipants = (value) => {
        const newList = allParticipants.map((participant) => {
            return { ...participant, isSelected: value };
        });

        setAllParticipants(newList);
    };

    const manageParticipant = (person) => {
        const newList = allParticipants.map((participant) => {
            if (participant._id === person._id) {
                return { ...participant, isSelected: !person?.isSelected };
            }
            return participant;
        });

        setAllParticipants(newList);
    };

    useEffect(() => {
        setIsAllSelected(
            allParticipants.every((participant) => participant?.isSelected)
        );
    }, [allParticipants]);

    return (
        <Fragment>
            <div
                className={
                    "bg-white p-8 space-y-7 border-[1.5px] border-[#EDEDED] rounded-[10px] w-[90%]"
                }
            >
                <div className="flex">
                    <CustomCheckBox
                        iconClass={"h-2 w-2"}
                        activeIconColor={"#1A9EE2"}
                        activeClasses={"border-[#1A9EE2]"}
                        className={"border-[1px] h-4 w-4 "}
                        inactiveClasses={"bg-white border-[#BABABA]"}
                        onCheck={(value) => markAllParticipants(value)}
                        checked={isAllSelected}
                    />
                    <span className="ml-4">Select all</span>
                </div>
                {/* Participants list */}
                <div
                    className={
                        "pl-2 pt-5 pb-5 add-participant-inner-wrapper overflow-y-auto "
                    }
                >
                    {allParticipants.length > 0 &&
                        allParticipants.map((participant) => (
                            <ParticipantCard
                                key={participant._id}
                                participant={participant}
                                isSelected={participant?.isSelected}
                                onSelect={(e) => {
                                    manageParticipant(participant);
                                }}
                            />
                        ))}
                </div>
            </div>

            {!isCreatingEvent && (
                <form
                    hidden={isCreatingEvent}
                    onSubmit={submitMeeting}
                    className={"mr-24 "}
                >
                    <ActionButtons
                        text={"Next"}
                        onCancel={goBack}
                        isLoading={isCreatingEvent || isUpdatingEvent}
                        isEditing={isEditing}
                        onSave={submitMeeting}
                    />
                </form>
            )}
        </Fragment>
    );
};
export default SetNotification;
