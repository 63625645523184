import { useQuery } from "react-query";
import { Fragment, useState } from "react";

import StakeholderCard from "./stakeholder-card";
import { getEventsByStakeholders } from "provider/api";
import useGlobalContext from "hooks/useGlobalContext";
import { queryKeys } from "../../../../../../constants";
import Loading from "./loading";
import EmptyState from "./empty-state";

const StakeholdersList = () => {
    const [stakeholders, setStakeholders] = useState([]);
    const { projectId } = useGlobalContext();

    const { isLoading, isFetched } = useQuery(
        queryKeys.EVENTS_BY_STAKEHOLDER,
        () => getEventsByStakeholders(projectId),
        {
            onSuccess: ({ data }) => {
                if (data) {
                    setStakeholders(
                        data
                            .map((event, idx) => event.stakeholder)
                            .filter((n) => n)
                    );
                }
            },
            enabled: !!projectId,
        }
    );

    return (
        <div className={"py-10 space-y-5 "}>
            <p className={"text-[#002F4F]  text-sm font-medium"}>
                STAKEHOLDERS{" "}
            </p>

            {isLoading ? (
                <Loading />
            ) : isFetched && stakeholders.length < 1 ? (
                <EmptyState />
            ) : (
                <Fragment>
                    {isLoading ? (
                        <Loading />
                    ) : (
                        <div className={" space-x-3 w-full"}>
                            <div
                                className={
                                    "flex flex-nowrap overflow-x-auto w-full space-x-3"
                                }
                            >
                                {stakeholders.map((stakeholder, idx) => (
                                    <StakeholderCard
                                        key={idx}
                                        stakeholder={stakeholder}
                                    />
                                ))}
                            </div>
                        </div>
                    )}
                </Fragment>
            )}
        </div>
    );
};
export default StakeholdersList;
