import ProjectImagesProvider from "../../provider/images";
import { EqualizerIcon } from "../../svg";
import ImageCard from "./image-card";
import { ChevronRightIcon, SearchIcon } from "@heroicons/react/outline";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import AllImages from "./all-image";
import AttendanceImage from "./attendance";
import GrievanceImages from "./gievances";
import MeetingImages from "./meeting/meeting-images";
import { RequirePermission } from "../../provider/permission";
import { useProjectImages } from "../../hooks";
import { Link } from "react-router-dom";

const ProjectImagesOverview = () => {
	const { recentImages = [] } = useProjectImages();
	return (
		<RequirePermission>
			<section className={"h-full overflow-y-auto space-y-6 py-10 px-16"}>
				<div className={"items-between"}>
					<div className={"flex space-x-3 items-center"}>
						<div
							className={
								"w-60 bg-[#F9F9F9AC] border-[1px] border-[#DCDFF1] flex items-center rounded overflow-hidden pl-2 space-x"
							}
						>
							<SearchIcon className={"h-5 w-5 text-black/50"} />
							<input
								type="text"
								placeholder={"Search..."}
								name=""
								id=""
								className={
									"bg-transparent border-none focus:ring-0 placeholder-black/50 text-sm outline-none py-2 font-light w-full"
								}
							/>
						</div>
						<div
							className={
								"h-9 w-9 center bg-[#EBF3F2] border-[1.7px] border-secondary rounded-[3px]"
							}
						>
							<EqualizerIcon />
						</div>
					</div>

					<div
						className={
							"bg-secondary flex items-center text-[15px] rounded-[4px] overflow-hidden text-white"
						}
					>
						<p className={"px-5"}>Upload Photos</p>
						<div className={"px-3 h-10 center bg-white/20"}>
							<FontAwesomeIcon
								icon={faPlus}
								className={"h-5 w-5"}
							/>
						</div>
					</div>
				</div>

				<div className={"space-y-16"}>
					<div className={"grid gap-4 grid-cols-2"}>
						<AllImages />
						<div className={" gap-4 grid grid-col-1"}>
							<AttendanceImage />
							<div className={" grid grid-cols-2 gap-4"}>
								<GrievanceImages />
								<MeetingImages />
							</div>
						</div>
					</div>

					<div className={"space-y-3  text-secondary"}>
						<div className={"items-between"}>
							<p className={"text-sm"}>MOST RECENT PHOTOS</p>
							<Link
								to={"all"}
								className={"flex font-light items-center"}
							>
								<span>View More</span>
								<ChevronRightIcon className={"h-5 w-5"} />
							</Link>
						</div>
						<div className={"grid gap-2 grid-cols-5"}>
							{recentImages.slice(0, 5).map((image, index) => (
								<ImageCard
									showActions={false}
									index={image._id}
									imageFile={image}
								/>
							))}
						</div>
					</div>
				</div>
			</section>
		</RequirePermission>
	);
};
export default ProjectImagesOverview;
