import InputCard from "../_partials/input-card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { createContext, useRef, useContext, useEffect, useState } from "react";
import { useConductMeetingContext } from "../index";
import GrievanceForm from "pages/general/grievances/form";
import GrievanceCard from "./grievance-card";
import { getRandomKey, isEmptyObject } from "utils";
import { Menu } from "@headlessui/react";

export const GrievanceContext = createContext(null);
const GrievanceInput = () => {
	const [showForm, setShowForm] = useState(false);
	const [isGBV, setIsGBV] = useState(false);
	const { conductedInfo, setConductedInfo } = useConductMeetingContext();
	const [grievances, setGrievances] = useState(conductedInfo.grievances);
	const [activeGrievance, setActiveGrievance] = useState(null);
	const formRef = useRef(null);
	useEffect(() => {
		setConductedInfo({ ...conductedInfo, grievances });
	}, [grievances]); //  eslint-disable-line react-hooks/exhaustive-deps

	const handleSubmit = (grievance) => {
		if (isEmptyObject(activeGrievance ?? {})) {
			const newGrievance = {
				...grievance,
				id: getRandomKey(),
			};
			setGrievances([...grievances, newGrievance]);
		} else updateGrievance(grievance);
		return formRef.current.closeForm(); // call function form child component.
	};

	const updateGrievance = (grievance) => {
		console.log("latest content", grievance);
		setGrievances(
			grievances.map((_grievance) => {
				if (_grievance.id === grievance.id) {
					return grievance;
				}
				return _grievance;
			})
		);
	};

	const handleMenuClick = (menu) => {
		if (menu === "Normal Complaint") {
			setShowForm(true);
			setIsGBV(false);
			return;
		}

		setShowForm(true);
		setIsGBV(true);
		return;
	};
	const formMenuItems = ["Normal Complaint", "GBV Complaint"];
	return (
		<InputCard title={"Grievances from meeting participants"}>
			<GrievanceContext.Provider
				value={{
					grievances,
					setGrievances,
					setActiveGrievance,
					isOpen: showForm,
					activeGrievance,
					setIsOpen: setShowForm,
				}}
			>
				<GrievanceForm
					ref={formRef}
					isGBV={isGBV}
					activeGrievance={activeGrievance}
					setActiveGrievance={setActiveGrievance}
					isCreating={false}
					onSubmit={handleSubmit}
					showForm={showForm}
					setShowForm={setShowForm}
				/>
				{/* <GrievanceForm
					isOpen={isOpen}
					onClose={setIsOpen}
					onSubmit={handleSubmit}
				/> */}
				<div className={"bg-[#FFFFFF] p-3"}>
					<div className={"border-t border-t-[#C3CBD9] py-5 px-2 "}>
						<div className="flex justify-end">
							<Menu as="div" className="relative">
								<Menu.Button
									className={`cursor-pointer font-semibold 
											hover:bg-[#CFE5F56A] text-xs text-[#009EE2] px-4 
											py-2 relative border-[#009EE2] border-[2px] 
											space-x-2`}
								>
									<span>ADD COMPLAINTS </span>
									<FontAwesomeIcon icon={faPlus} />
								</Menu.Button>
								<Menu.Items
									as="ul"
									className="absolute z-50 w-full overflow-hidden border divide-y-[1px] rounded-lg top-full bg-white/40 backdrop-blur-xl"
								>
									{formMenuItems.map((menu) => (
										/* Use the `active` state to conditionally style the active item. */
										<Menu.Item key={menu} as={"li"}>
											{({ active }) => (
												<p
													onClick={() =>
														handleMenuClick(menu)
													}
													className={`${
														active
															? "bg-black/5 "
															: " "
													} p-2 text-sm text-black cursor-pointer`}
												>
													{menu}
												</p>
											)}
										</Menu.Item>
									))}
								</Menu.Items>
							</Menu>
						</div>

						{grievances.length ? (
							<div
								className={
									"w-full grid grid-cols-2 2xl:grid-cols-3 gap-5"
								}
							>
								{grievances.map((grievance, index) => (
									<GrievanceCard
										key={grievance?.id}
										grievance={grievance}
									/>
								))}
							</div>
						) : null}
					</div>
				</div>
			</GrievanceContext.Provider>
		</InputCard>
	);
};

export const useGrievanceContext = () => useContext(GrievanceContext);
export default GrievanceInput;
