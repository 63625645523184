import CategoryCard from "./category-card";
import { useProjectFiles } from "../../../../hooks";

const DocumentsByCategories = () => {
	const { isFetchingCategories, fileCategories = {} } = useProjectFiles();
	const { files } = fileCategories;
	const categories = [
		{
			name: "Documents",
			url: "categories/documents",
			totalFiles: files?.documents?.length ?? 0,
			image: {
				name: "document.png",
				altName: "document",
			},
			color: "#36B990",
		},
		{
			name: "Video",
			url: "categories/videos",
			totalFiles: files?.videos?.length ?? 0,
			image: {
				name: "video.png",
				altName: "video",
			},
			color: "#FC4155",
		},

		{
			name: "Audio Files",
			url: "categories/audios",
			totalFiles: files?.audios?.length ?? 0,
			image: {
				name: "audio.png",
				altName: "audio",
			},
			color: "#6E61B5",
		},

		{
			name: "Images",
			url: "categories/images",
			totalFiles: files?.images?.length ?? 0,
			image: {
				name: "picture.png",
				altName: "file",
			},
			color: "#FC6A21",
		},

		{
			name: "Others",
			url: "categories/others",
			totalFiles: files?.others?.length ?? 0,
			image: {
				name: "other-category.png",
				altName: "others category",
			},
			className: "w-10",
			color: "#F8C358",
		},
	];
	return (
		<div>
			<p
				className={
					"text-[#0A0E1C] text-sm py-3 tracking-wide font-semibold"
				}
			>
				DOCUMENTS BY CATEGORY
			</p>
			<div className={"grid grid-cols-3 2xl:grid-cols-4 gap-3 "}>
				{categories.map((category) => (
					<CategoryCard key={category.name} category={category} />
				))}
			</div>
		</div>
	);
};
export default DocumentsByCategories;
