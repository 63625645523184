const StakeholderCounter = ({title, value = 0, percentage = null}) => {
    return (
        <div className={'space-y-1'}>
            <div className={'text-[#0E1731] leading-tight text-[13px] tracking-wide'}>
                <p class={'flex justify-between space-x-10'}>
                    <span>{title}</span>
                    {percentage ? <span className={'text-[#00BB75] font-semibold'}>{percentage}%</span> : ""}
                </p>
            </div>
            <p class={'font-muli text-[#002F4F] font-semibold text-2xl'}>
                {value}
            </p>
        </div>
    )
}
export default StakeholderCounter;
