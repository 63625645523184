import { yupResolver } from "@hookform/resolvers/yup";
import { Fragment, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useOutletContext } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import {
    getValueFromLocalStorage,
    saveValueToLocalStorage,
} from "hooks/useLocalStorageState";
import { PIUBasicDetailsFormSchema } from "../../../../../forms/validation";
import { updatePIUDetails } from "provider/api";
import { useMutation } from "react-query";
import ActionButtons from "components/form-control/action-buttons";
import EditorJS from "components/editorjs/editor";
import { isEmptyDescription, isEmptyObject } from "utils";
import FileUpload from "components/file-upload";
import useDeleteFromS3Bucket from "hooks/useDeleteFromS3Bucket";
import UnstableInput from "components/unstable/input";
import { localStorageKeys } from "../../../../../constants";
import getBasicDetailsFields from "./fields-stracture";
import FormHeader from "components/form-header";
import Label from "components/form-control/Label";
import ColorPickerInput from "components/color-picker";
import StakeholderMap from "../../../../../components/stakeholder-map";

const BasicDetails = () => {
    const {
        handleSubmit,
        register,
        setValue,
        formState: { errors, isSubmitted },
    } = useForm({
        resolver: yupResolver(PIUBasicDetailsFormSchema),
    });

    const navigate = useNavigate();
    const { piuForm: piuLSKeys } = localStorageKeys;
    const { deleteFromS3Bucket } = useDeleteFromS3Bucket();
    const { currStep, setCurrStep, toast } = useOutletContext();
    const isUpdating = getValueFromLocalStorage(
        piuLSKeys.IS_UPDATING_DETAILS,
        false
    );
    const piuBasicDetails = getValueFromLocalStorage(
        piuLSKeys.BASIC_DETAILS,
        {}
    );

    //  states
    const [location, setLocation] = useState(piuBasicDetails?.location ?? "");
    const [description, setDescription] = useState(
        piuBasicDetails?.description ?? ""
    );
    const [companyLogo, setCompanyLogo] = useState(
        piuBasicDetails?.companyLogo ?? ""
    );
    const [showFileDialog, setShowFileDialog] = useState(false);
    let selectedSectors = piuBasicDetails?.selectedSectors ?? [];

    const onSuccess = ({ data }, { formData } = {}) => {
        toast.success("Information has successfully been updated");
        savePIUDetailsAndAdvanceCurrStep(formData);
    };

    const onError = ({ message }) => {
        toast.error(message);
    };

    // send put request to update piu details
    const { mutate: _updatePIUDetails, isLoading } = useMutation(
        "update-piu" + piuBasicDetails?._id,
        updatePIUDetails,
        {
            onSuccess,
            onError,
        }
    );

    const handleEditorChange = (cleanData) => {
        setDescription(cleanData);
    };

    const buildPayloadAndUpdatePIUDetails = (formData) => {
        const { properties, geometry } = location;
        const { region, district, ward, street, postalCode, ...rest } =
            formData;

        const payload = {
            formData,
            piuId: piuBasicDetails?.piuId,
            ...rest,
            companyLogo,
            description: JSON.stringify(description),
            location: {
                name: properties.display_name,
                latitude: geometry.coordinates[1],
                longitude: geometry.coordinates[0],
            },

            address: {
                street,
                ward,
                district,
                region,
                postalCode,
                country: properties.address.country,
            },
        };
        _updatePIUDetails(payload);
    };

    const savePIUDetailsAndAdvanceCurrStep = (formData) => {
        const payload = {
            ...piuBasicDetails,
            selectedSectors,
            description,
            companyLogo,
            ...formData,
            location,
        };
        saveValueToLocalStorage(piuLSKeys.BASIC_DETAILS, payload);
        advanceCurrStep();
    };

    const advanceCurrStep = () => {
        toast.success("Data saved successfully...");
        saveValueToLocalStorage(piuLSKeys.CURRENT_STEP, 2);
        setCurrStep(currStep + 1);
        navigate("mission-and-vision");
    };

    const onSubmit = (formData) => {
        if (location && !isEmptyDescription(description)) {
            if (isUpdating) {
                return buildPayloadAndUpdatePIUDetails(formData);
            }
            savePIUDetailsAndAdvanceCurrStep(formData);
        }
    };

    const handleUpload = () => {
        setShowFileDialog(true);
    };

    const handleUploadedFile = (files = []) => {
        if (!files.length) return;

        /*  check to see if there is any file selected early,
            if so delete the file and set a new selected file
         */
        const selectedFile = files[0];
        if (!isEmptyObject(companyLogo)) {
            const { fileKey } = companyLogo;
            deleteFromS3Bucket(fileKey);
        }
        setValue("logo", selectedFile.title);
        return setCompanyLogo(selectedFile);
    };

    const { basicFields, locationFields, colorFields } = getBasicDetailsFields(
        piuBasicDetails,
        errors,
        register,
        handleUpload
    );

    return (
        <Fragment>
            <FileUpload
                onClose={setShowFileDialog}
                onSubmit={handleUploadedFile}
                isOpen={showFileDialog}
            />
            <ToastContainer />
            <div className={"px-10 w-4/5 mx-auto  space-y-12 pb-16"}>
                <FormHeader
                    title={"About The PIU"}
                    description={
                        "Basic details about list such as name," +
                        " description and others"
                    }
                />
                <form
                    action="pages/settings/pius/form/index"
                    onSubmit={handleSubmit(onSubmit)}
                    className={"pb-3 pr-14 space-y-5"}
                >
                    {basicFields.map((props) => {
                        return <UnstableInput props={props} key={props.name} />;
                    })}

                    {/*    /!* company color *!/*/}
                    <div className={"pr-14 space-y-2"}>
                        <Label
                            text={"Set Theme Color"}
                            htmlFor={"primaryColor"}
                        />
                        <div className={"flex gap-8"}>
                            {colorFields.map((field) => (
                                <ColorPickerInput
                                    key={field.inputName}
                                    props={{ ...field, setColor: setValue }}
                                />
                            ))}
                        </div>
                    </div>

                    {/* description */}
                    <div className={" space-y-2 "}>
                        <Label text={"Description"} htmlFor={"description"} />
                        <EditorJS
                            uniqueId={"description-text-area"}
                            isError={isSubmitted && !description}
                            defaultValue={piuBasicDetails?.description}
                            onChange={handleEditorChange}
                        />
                    </div>

                    <div className="space-y-2">
                        <p className={"text-primary/80 tracking-wider"}>
                            Set stakeholder location
                        </p>
                        <StakeholderMap
                            setValue={setValue}
                            defaultLocation={location}
                            fields={locationFields}
                            onChange={setLocation}
                        />
                    </div>

                    <ActionButtons
                        showPrev={false}
                        nextBtnLabel={isUpdating ? "SAVE & CONTINUE" : "NEXT"}
                        isLoading={isLoading}
                    />
                </form>
            </div>
        </Fragment>
    );
};

export default BasicDetails;
