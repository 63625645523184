const Loading = () => {
	return (
		<div className=" bg-white rounded-[3px] p-12 space-y-12 border">
			<p className="h-3 rounded-full animate-pulse skeleton-loading w-60"></p>

			<div className="flex items-center justify-between">
				<div className="space-y-10">
					{[...Array(4)].map((item, index) => (
						<div className="flex space-x-5 " key={index}>
							<div className="w-8 h-8 rounded skeleton-loading"></div>
							<div className="pt-2 space-y-2">
								<div className="w-10 h-2 rounded-full skeleton-loading"></div>
								<div className="w-10 border-b h-[1px]"></div>
								<div className="w-20 h-2 rounded-full skeleton-loading"></div>
							</div>
						</div>
					))}
				</div>
				<div>
					<div className="rounded-full skeleton-loading center h-72 w-72">
						<div className="bg-white rounded-full w-7 h-7"></div>
					</div>
				</div>
				<div className="space-y-10">
					{[...Array(4)].map((item, index) => (
						<div className="flex space-x-5 " key={index}>
							<div className="w-3 h-3 rounded-full skeleton-loading"></div>
							<div className="pt-2 space-y-2">
								<div className="w-10 h-2 rounded-full skeleton-loading"></div>

								<div className="w-20 h-2 rounded-full skeleton-loading"></div>
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};
export default Loading;
