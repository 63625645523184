import React from "react";
import {getRandomKey} from "../../utils";
import {XIcon} from "@heroicons/react/outline";
import {useCalender} from "./index";
import useClickOutside from "../../hooks/useClickOutside";

const DayPopup = ({events = [], setShowPopup, day}) => {
    const {setShowDetails, setActiveEvent} = useCalender();
    const popupRef = useClickOutside(() => {
        setShowPopup(false)
    })

    const showEventDetails = (event) => {
        setShowPopup(false);
        setActiveEvent(event)
        setShowDetails(true);
    }

    return (
        <div ref={popupRef}
             className={`absolute w-48 z-20 right-0 enable-transition bottom-3 `}>
            <div className={'relative'}>
                <div className={'absolute rounded -inset-[.5px] bg-gray-400 blur-[4px]'}></div>
                <div className={'bg-white relative space-y-2 p-3 rounded'}>
                    <div className={'text-center relative'}>
                        <p className={'text-[12px] tracking-wide'}>{day.format('dddd')}</p>
                        <p className={'text-4xl text-gray-500 font-medium'}>{day.format('DD')}</p>

                        <button onClick={() => setShowPopup(false)}
                                className={'absolute h-7 w-7 center hover:bg-gray-200 text-gray-600 rounded-full top-0 right-0'}>
                            <XIcon className={'  right-0 h-5 top-0'}/>
                        </button>
                    </div>
                    <div className={'space-y-1'}>
                        {events.map((event) => (
                            <p onClick={() => showEventDetails(event)} key={(event?._id || getRandomKey())} className={`
                                 px-1 py-[4px] bg-secondary/20 hover:bg-secondary/30 cursor-pointer hover:border-secondary/20 border border-transparent text-secondary 
                                truncate rounded text-center w-full`
                            }>
                                {event.title}
                            </p>
                        ))}
                    </div>

                </div>
            </div>
        </div>
    )
}

export default React.memo(DayPopup);