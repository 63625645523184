import FolderIcon from "../../../svg";

const FolderListView = ({folder, onOpenFolder: handleOpen}, ...props) => {
    const handleDoubleClick = () => {
        handleOpen(folder)
    }

    return (
        <div {...props} onDoubleClick={handleDoubleClick}
             className={'grid grid-cols-4 hover:bg-[#F4F9FF]/70 cursor-pointer  text-black border-[1px] rounded-[3px]  border-[#D5E3D6] p-2 text-sm w-full '}>
            <div className={' col-span-2 flex items-center space-x-4'}>
                <div>
                    <FolderIcon className={'h-8'}/>
                </div>
                <p className={'capitalize whitespace-nowrap truncate'}>{folder?.name ?? "file title goes here"}</p>
            </div>
            <div className={'center'}>Thu, 26 Oct</div>
        </div>
    )
}
export default FolderListView