import { useParams, useLocation } from "react-router-dom";
import { useProjectFiles } from "../../../../hooks";
import FileCard from "../../overview/recent-files/file-card";
import { Fragment, useEffect, useState } from "react";
import ImageViewer from "../../../../components/image-viewer/image-viewer";
import { FolderHeader } from "../../_partials/folders";
import GridViewMode from "./grid-view-mode";
import FileSkeletonLoading from "./file-skeleton-loading";

const FolderDetails = () => {
	const { pathname } = useLocation();
	const { subFolderName, date } = useParams();
	const folderName = pathname.split("/")[2];
	const { isLoading, getSubFolderFiles } = useProjectFiles();

	const [activeFile, setActiveFile] = useState(null);
	const [openFile, setOpenFile] = useState(false);
	const [files, setFiles] = useState([]);
	const [filteredFiles, setFilteredFiles] = useState([]);
	const { showListView } = useProjectFiles();

	useEffect(() => {
		if (!isLoading) {
			getFiles();
		}
	}, [isLoading]); //  eslint-disable-line react-hooks/exhaustive-deps

	if (isLoading) return <FileSkeletonLoading />;

	const getFiles = () => {
		const { attachments = [] } = getSubFolderFiles(
			folderName,
			subFolderName,
			date
		);
		setFiles(attachments);
		setFilteredFiles(attachments);
	};

	const handleOpenFile = (file) => {
		console.log("ACTIVE:: ", file);
		setActiveFile(file);
		setOpenFile(true);
	};

	const handleSearch = ({ target }) => {
		const searchKeyword = target.value.toLowerCase();
		setFilteredFiles(
			files.filter((file) => {
				return file?.title?.toLowerCase().includes(searchKeyword);
			})
		);
	};

	return (
		<section className={"space-y-5"}>
			<FolderHeader onSearch={handleSearch} currPath={subFolderName} />
			{activeFile && (
				<ImageViewer
					file={activeFile}
					isOpen={openFile}
					setIsOpen={setOpenFile}
				/>
			)}

			<div className={"px-8"}>
				{filteredFiles.length ? (
					<Fragment>
						{showListView ? (
							<div className="grid grid-cols-1 gap-5 2xl:grid-cols-2">
								{filteredFiles.map((file) => (
									<FileCard
										key={file._id}
										file={file}
										onOpenFile={handleOpenFile}
									/>
								))}
							</div>
						) : (
							<GridViewMode files={filteredFiles} />
						)}
					</Fragment>
				) : (
					<div className={"h-[25vh] text-black/60 center"}>
						Folder is empty
					</div>
				)}
			</div>
		</section>
	);
};
export default FolderDetails;
