import { faThumbtack } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import SpinLoader from "../../../components/SpinLoader";
import useDownloadFileFromS3 from "../../../hooks/useDownloadFileFromS3";
import { AcrobatLogo, DownloadIcon } from "../../../svg";

const FileGridViewCard = ({ file }, ...props) => {
	const [isDownloading, setIsDownloading] = useState(false);

	const { s3FileDownloader } = useDownloadFileFromS3((progress) => {
		if (parseInt(progress) === 100) {
			setIsDownloading(false);
		}
	});

	const handleFileDownload = () => {
		setIsDownloading(true);
		s3FileDownloader(file.fileKey, file.title);
	};
	return (
		<div
			className={`h-40 bg-white -hidden center 2xl:h-56 border  rounded-[4px] relative`}
		>
			<div className="h-20 2xl:h-32">
				<AcrobatLogo />
			</div>

			<div
				className={`p-3 enable-transition text-dark2 flex  flex-col justify-between absolute inset-0`}
			>
				<div className="items-between">
					<p
						className={
							"bg-secondary/30 backdrop-blur-[1px] rounded-[3px] text-xs px-3 py-1.5 "
						}
					>
						{file?.type}
					</p>

					<div className="flex items-center space-x-1">
						<button
							onClick={handleFileDownload}
							className="rounded bg-black/10 group center hover:bg-black/20 backdrop-blur-sm w-7 h-7"
						>
							{isDownloading ? (
								<SpinLoader size={"small"} color={"#628799"} />
							) : (
								<DownloadIcon
									className={`h-3 w-3 fill-black/50 `}
								/>
							)}
						</button>
						<button className="rounded bg-black/10 group center hover:bg-black/20 backdrop-blur-sm w-7 h-7">
							<FontAwesomeIcon
								icon={faThumbtack}
								className={"h-4 w-4 text-black/50 "}
							/>
						</button>
					</div>
				</div>
				<p className={" text-sm whitespace-nowrap truncate"}>
					{file?.title}
				</p>
			</div>
		</div>
	);
};
export default FileGridViewCard;
