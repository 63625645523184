import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileLines } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { isImageFile } from "../../../../../../../utils";
import ImageViewer from "../../../../../../../components/image-viewer/image-viewer";

const AttendanceGroup = ({ title = "", stakeholders = [] }) => {
	return (
		<div className={"text-sm space-y-2 capitalize"}>
			<p className={"text-[#AAA9AB]"}>{title}</p>
			<div className={"font-light space-y-1 text-[13px] text-accent"}>
				{stakeholders.length ? (
					stakeholders.map((stakeholder) => (
						<p key={stakeholder?._id}>{stakeholder.name} </p>
					))
				) : (
					<p className={"text-black"}>N/A</p>
				)}
			</div>
		</div>
	);
};

const Attendance = ({ attendance = {} }) => {
	const { attachments = [], participants = [] } = attendance;
	const [openImageFile, setOpenImageFile] = useState(false);
	const [activeFile, setActiveFile] = useState(null);
	console.log("FILES  ", attachments[0]);

	const { individualStakeholder, organizationalStakeholder } = participants;

	const handleClick = (file) => {
		if (isImageFile(file.type)) {
			setOpenImageFile(true);
			setActiveFile(file);
		}
	};

	return (
		<div>
			{activeFile && (
				<ImageViewer
					file={activeFile}
					isOpen={openImageFile}
					setIsOpen={setOpenImageFile}
				/>
			)}
			<p
				className={
					"px-5 py-2 text-[#666666] tracking-wider bg-[#F7F7F7]"
				}
			>
				Attendance
			</p>
			<div className={"grid grid-cols-3 p-3 text-sm"}>
				<AttendanceGroup
					title={"Organisation"}
					stakeholders={organizationalStakeholder}
				/>
				<AttendanceGroup
					title={"Individual"}
					stakeholders={individualStakeholder}
				/>
				<div className={"space-y-2"}>
					<p className={"text-[#AAA9AB]"}>
						Non Registered Stakeholders
					</p>

					{attachments.map((attachment) => (
						<div
							onClick={() => handleClick(attachment)}
							className={"grid bg-secondary/5 grid-cols-5"}
						>
							<div
								className={
									"col-span-4 py-2 flex items-center space-x-3"
								}
							>
								{isImageFile(attachment.type) ? (
									<img
										src="/images/image-icon.png"
										alt="icon"
										className={"h-4 w-4"}
									/>
								) : (
									<FontAwesomeIcon
										icon={faFileLines}
										className={"h-4 text-pink-400"}
									/>
								)}

								<p
									className={
										"text-accent whitespace-nowrap truncate"
									}
								>
									{attachment.title || attachment.fileKey}
								</p>
							</div>

							<div className={"center"}>
								<span
									className={
										"uppercase text-[11px] bg-accent/20 text-accent rounded-full px-2 "
									}
								>
									{attachment.type}
								</span>
							</div>
							<div className={"center text-xs hidden opacity-60"}>
								{attachment.fileSize}
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};
export default Attendance;
