import {useUsers} from "hooks";

const UserTableHeader = () => {
    const {isCreatingAdminUser: isWBAdmin} = useUsers();
    const userProperties = [
        'Name', 'Gender', 'Role', 'PIU',
        'Status',
        'Action'
    ]
    if (!isWBAdmin) userProperties.splice(3, 1)
    return (
        <div className={'grid grid-cols-7'}>
            {userProperties.map((property, index) => (
                <div
                    className={`font-semibold  px-5 text-sm py-4 ${index === 0 ? 'text-left col-span-2' : index === userProperties.length - 1 ? `${!isWBAdmin && 'col-span-2'} center ` : 'center'}  font-medium`}>
                    {property}
                </div>
            ))}
        </div>
    )
}
export default UserTableHeader;