import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { useOutletContext } from "react-router-dom";

import { CreateMarkup, isEmptyObject } from "utils";
import Loading from "./loading";
import EmptyState from "./empty-state";

const ContactPerson = () => {
    const { isLoading, getCurrentStakeholder } = useOutletContext();

    if (isLoading) {
        return <Loading />;
    }
    const contactPerson = getCurrentStakeholder().contactPerson;

    if (isEmptyObject(contactPerson)) {
        return <EmptyState />;
    }

    return (
        <div className={"flex pt-5 justify-between gap-x-16 "}>
            <div className={"space-y-5"}>
                <div>
                    <p
                        className={
                            "text-dark font-medium text-xl tracking-wide"
                        }
                    >
                        {contactPerson.name}
                    </p>
                    <p className={"text-sm text-[#A5A5A5] font-light"}>
                        {contactPerson.position}
                    </p>
                </div>

                <div
                    className={
                        "text-sm text-secondary mr-80 leading-relaxed font-light tracking-wide text-justify"
                    }
                >
                    <p
                        dangerouslySetInnerHTML={CreateMarkup(
                            contactPerson.description
                        )}
                    ></p>
                </div>
            </div>

            {/* Fixed Contents */}
            <div className={"pt-4 fixed right-28"}>
                <div
                    className={
                        "bg-[#F5F9FA] space-y-5 border-[1px] w-72 border-[#EDEEF0] rounded px-5 pt-10 pb-16"
                    }
                >
                    <div className={"flex items-center justify-between"}>
                        <p className={"text-[#628799] text-semibold text-lg"}>
                            Contact Info
                        </p>
                        <FontAwesomeIcon
                            icon={faPaperPlane}
                            className={"h-5 w-5 text-accent"}
                        />
                    </div>

                    <div className={"tracking-wide space-y-4 pb-3"}>
                        <div className={"space-y-1"}>
                            <p className={"text-xs text-primary "}>Gender</p>
                            <p
                                className={
                                    "capitalize text-sm font-thin text-[#408BF9]"
                                }
                            >
                                {contactPerson.gender}
                            </p>
                        </div>

                        <div className={"space-y-1"}>
                            <p className={"text-xs text-primary "}>Address</p>
                            <p
                                className={
                                    "capitalize text-sm font-thin text-[#408BF9]"
                                }
                            >
                                {contactPerson.physicalAddress}
                            </p>
                        </div>
                    </div>

                    <div
                        className={
                            "border-t-[1px] pt-8 space-y-7 border-[#EDEEF0] "
                        }
                    >
                        <div className={"text-[13px]  w-full "}>
                            {contactPerson.communicationMedias.map(
                                (media, idx) => (
                                    <div
                                        key={idx}
                                        className={"flex flex-wrap "}
                                    >
                                        {media.name === "Email" && (
                                            <div className={"w-full "}>
                                                <p className={"text-[#AFAFAF]"}>
                                                    Email
                                                </p>
                                                <p className={"text-dark"}>
                                                    {media.value}
                                                </p>
                                            </div>
                                        )}

                                        {media.name === "Phone" && (
                                            <div className={"w-full mt-2"}>
                                                <p className={"text-[#AFAFAF]"}>
                                                    Phone
                                                </p>
                                                <p className={"text-dark"}>
                                                    {media.value}
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                )
                            )}
                        </div>

                        <div className={"text-[13px] flex flex-wrap w-full"}>
                            <div className={"w-full"}>
                                <p className={"text-[#AFAFAF]"}>FAX</p>
                                <p className={"text-dark "}>1123904</p>
                            </div>

                            <div className={"w-full mt-2"}>
                                <p className={"text-[#AFAFAF]"}>WEBSITE</p>
                                <p className={"text-dark"}>
                                    {contactPerson.websiteLink}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default ContactPerson;
