import { useRef } from "react";
import dayjs from "dayjs";

import KeyResultInput from "../../pages/general/commitments/form/milestone/key-result-input";
import { getRandomKey } from "../../utils";

const AddKeyResults = ({ keyResults, setKeyResults, minDate, maxDate }) => {
    const formRef = useRef(null);

    const handleRemove = (inputId) => {
        const _keyResults = keyResults.filter((keyResult) => {
            return keyResult.id !== inputId;
        });
        setKeyResults(_keyResults);
    };

    const handleUpdate = (id, keyResult) => {
        const _keyResults = keyResults.map((_keyResult) => {
            if (_keyResult.id === id) {
                return { id, ...keyResult };
            }
            return _keyResult;
        });
        setKeyResults(_keyResults);
    };

    const handleSubmit = ({ title, endDate }) => {
        const keyResult = {
            id: getRandomKey(),
            title,
            endDate: dayjs(endDate).format("YYYY-MM-DD"),
        };
        setKeyResults([...keyResults, keyResult]);
        // clear form
        if (formRef.current) {
            formRef.current.clearForm();
        }
    };

    return (
        <div className={"space-y-2"}>
            {keyResults.map((keyResult, index) => (
                <KeyResultInput
                    maxDate={maxDate}
                    minDate={minDate}
                    autoFocus={false}
                    key={keyResult.id}
                    id={keyResult.id}
                    defaultValue={keyResult}
                    onSubmit={(formData) =>
                        handleUpdate(keyResult.id, formData)
                    }
                    onRemove={handleRemove}
                />
            ))}

            <KeyResultInput
                maxDate={maxDate}
                minDate={minDate}
                autoFocus={true}
                ref={formRef}
                onSubmit={handleSubmit}
            />
        </div>
    );
};
export default AddKeyResults;
