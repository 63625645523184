const FileSkeletonLoading = () => {
	return (
		<section className="space-y-8 ">
			<header className="h-10 bg-red-500 "></header>
			<div className="grid grid-cols-5 gap-5 px-10">
				{[...Array(10)].map((file, index) => (
					<div
						key={index}
						className="w-full bg-red-500 rounded 2xl:h-56 "
					></div>
				))}
			</div>
		</section>
	);
};
export default FileSkeletonLoading;
