import { useMutation } from "react-query";
import { Fragment, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import {
    getValueFromLocalStorage,
    saveMultiValueToLocalStorage,
    saveValueToLocalStorage,
} from "hooks/useLocalStorageState";
import ActionButtons from "../_partials/action-buttons";
import Participants from "./sidebar";
import { updateProjectEvent } from "provider/api";
import Participant from "pages/general/engagement/activity/events/_partials/participant";
import { localStorageKeys } from "../../../../../../../../../constants";

const AddParticipants = () => {
    const navigate = useNavigate();
    const [setCurrStep, toast] = useOutletContext();
    const { meetingForm: meetingLSKeys } = localStorageKeys;
    const [showParticipants, setShowParticipants] = useState(false);
    const meetingBasicInfo = getValueFromLocalStorage(
        meetingLSKeys.BASIC_DETAILS,
        {}
    );

    const [participantList, setParticipantList] = useState(
        getValueFromLocalStorage(meetingLSKeys.PARTICIPANTS, [])
    );
    const isEditing = getValueFromLocalStorage(
        meetingLSKeys.IS_UPDATING_DETAILS,
        false
    );

    const handleBack = async () => {
        setCurrStep(1);
        await saveValueToLocalStorage("meetingFormCurrentStep", 1);
        navigate("/event-registration/meeting");
    };

    const toggleSidebar = () => {
        setShowParticipants(!showParticipants);
    };

    const { mutate: updateEvent, isLoading: isUpdatingEvent } = useMutation(
        updateProjectEvent,
        {
            onSuccess: async ({ data }) => {
                await saveParticipantsToLocalStorageAndAdvanceToNextStep();
            },
            onError: ({ message }) => toast.error(message),
        }
    );

    const buildPayloadAndUpdateEventDetails = () => {
        saveValueToLocalStorage("meetingParticipants", participantList);
        const payload = {
            ...meetingBasicInfo,
            participants: getParticipants(participantList),
        };
        updateEvent(payload);
    };

    const saveParticipantsToLocalStorageAndAdvanceToNextStep = async () => {
        setCurrStep(3);
        await saveMultiValueToLocalStorage({
            [meetingLSKeys.CURRENT_STEP]: 3,
            [meetingLSKeys.PARTICIPANTS]: participantList,
        });
        toast.success("Data has been saved successfully");
        navigate("/event-registration/meeting/set-datetime");
    };

    const getParticipants = (allParticipants) => {
        return allParticipants.map((person) => {
            return { stakeholder: person?._id };
        });
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (isEditing) {
            return buildPayloadAndUpdateEventDetails();
        }
        await saveParticipantsToLocalStorageAndAdvanceToNextStep();
    };

    const handleRemoveParticipant = ({ _id: participantId }) => {
        return setParticipantList([
            ...participantList.filter((participant) => {
                return participant._id !== participantId;
            }),
        ]);
    };

    return (
        <Fragment>
            <Participants
                showParticipants={showParticipants}
                setParticipantList={setParticipantList}
                initialParticipants={participantList}
                setShowParticipants={setShowParticipants}
            />

            <div className={"pr-20 space-y-10 pt-5"}>
                <div>
                    <p className={"py-1 text-[#4D4F5C] font-media text-lg"}>
                        Add Meeting Participants
                    </p>
                    <p
                        className={
                            "text-sm text-gray-500 tracking-wide font-light"
                        }
                    >
                        All Participant of any meeting created must first be one
                        among the stakeholders, contact persons, relationship
                        holders or any individual who is registered on the
                        system such as coordinators.
                    </p>
                </div>

                <div>
                    <div
                        className={"h-1 primary-gradient rounded-t-[4px]"}
                    ></div>
                    <div
                        className={
                            "min-h-[16rem] border-[1px] space-y-5 py-4 border-[#DFF1FE]"
                        }
                    >
                        <div className={"items-between  px-8"}>
                            <p className={"text-[15px] text-dark"}>
                                PARTICIPANTS
                            </p>

                            <button
                                onClick={toggleSidebar}
                                className={
                                    "flex items-center text-sm font-light space-x-2 text-accent"
                                }
                            >
                                <span>Add Participants</span>
                                <FontAwesomeIcon
                                    icon={faPlus}
                                    className={"h-4 w-4"}
                                />
                            </button>
                        </div>

                        <div className={"px-8 space-y-2"}>
                            {!participantList.length ? (
                                <span>No selected participants</span>
                            ) : (
                                participantList.map((participant, idx) => (
                                    <Participant
                                        key={idx}
                                        stakeholder={participant}
                                        handleRemove={handleRemoveParticipant}
                                    />
                                ))
                            )}
                        </div>
                    </div>
                </div>

                <form onSubmit={handleSubmit}>
                    <ActionButtons
                        text={"Next"}
                        isLoading={isUpdatingEvent}
                        onCancel={handleBack}
                        isEditing={isEditing}
                        onSave={() => {}}
                    />
                </form>
            </div>
        </Fragment>
    );
};
export default AddParticipants;
