import {useState} from "react";
import {useQuery} from "react-query";
import {grievanceAnalytics} from "../../api";
import {GrievanceAnalyticContext} from "../../../context";
import useGlobalContext from "../../../hooks/useGlobalContext";

const GrievanceAnalyticProvider = ({children}) => {
    const [grievanceAnalytic, setGrievanceAnalytic] = useState({});
    const {projectId} = useGlobalContext();
    const {isLoading} = useQuery("grievance-analytics", () => {
        return grievanceAnalytics(projectId)
    }, {
        onSuccess: ({data: analyticData}) => {
            setGrievanceAnalytic(analyticData);
        },
    });
    return (
        <GrievanceAnalyticContext.Provider
            value={{grievanceAnalytic, isLoading}}
        >
            {children}
        </GrievanceAnalyticContext.Provider>
    );
};
export default GrievanceAnalyticProvider;
