import { Link, Outlet, useLocation } from "react-router-dom";
import { SettingsIcon } from "svg";
import { hasAnyPermission, permissions } from "provider/permission";
import { Fragment } from "react";

export const SettingsTab = ({ url, pathname }, ...props) => {
    const { pathname: _pathname } = useLocation();
    const isActive = new RegExp(`^/settings/${url}$`).test(_pathname);

    return (
        <li {...props}>
            <Link
                to={url}
                className={`${
                    isActive
                        ? "text-secondary bg-secondary/10 "
                        : "text-[#A7B3C3] hover:text-gray-400"
                } 
                block font-semibold  rounded hover:bg-secondary/10 px-5 py-2`}
            >
                {pathname}
            </Link>
        </li>
    );
};

const ProjectSettingsLayout = () => {
    const getTabMenus = () => {
        let tabMenus = [];
        if (hasAnyPermission(permissions.MANAGE_PROJECTS_AND_PIUS)) {
            return [
                { url: "users", pathname: "Users" },
                { url: "project-management", pathname: "Project Management" },
                { url: "pius", pathname: "PIUs" },
            ];
        } else {
            if (hasAnyPermission(permissions.MANAGE_USERS)) {
                tabMenus.push({ url: "users", pathname: "Users" });
            }
            if (hasAnyPermission(permissions.MANAGE_DEPARTMENTS)) {
                tabMenus.push({ url: "departments", pathname: "Departments" });
            }
            if (hasAnyPermission(permissions.MANAGE_GRIEVANCES)) {
                tabMenus.push({
                    url: "grievance-management",
                    pathname: "Grievance",
                });
            }
            if (hasAnyPermission(permissions.MANAGE_ROLES)) {
                tabMenus.push({
                    url: "roles-and-permissions",
                    pathname: "Role & Permissions",
                });
            }
            if (hasAnyPermission(permissions.MANAGE_SUBPROJECTS)) {
                tabMenus.push({
                    url: "subproject-management",
                    pathname: "Subproject",
                });
                tabMenus.push({
                    url: "phase-managements",
                    pathname: "Phase Managements",
                });
            }
        }
        return tabMenus;
    };
    return (
        <main
            className={"h-full bg-[#F7F9FA] px-10  space-y-5 overflow-y-auto"}
        >
            <header className="sticky z-20 top-0 pt-10 bg-[#F7F9FA]">
                <div
                    className={
                        " border-[1.2px] bg-[#F7F9FA] border-[#A7B3C3] z-20  backdrop-blur-[1px] rounded-[5px] p-1"
                    }
                >
                    <div
                        className={
                            "border-[1px] items-between p-1 rounded-[5px] border-[#ECECED] bg-white/80"
                        }
                    >
                        <ul className={"flex items-center  space-x-3"}>
                            {getTabMenus().map(({ url, pathname }) => (
                                <Fragment>
                                    <SettingsTab
                                        url={url}
                                        pathname={pathname}
                                        key={pathname}
                                    />
                                </Fragment>
                            ))}
                        </ul>
                        <div className={"pr-4"}>
                            <SettingsIcon className={"h-6 w-6"} />
                        </div>
                    </div>
                </div>
            </header>

            {/*container*/}
            <div style={{ height: "calc(100% - 5.35rem)" }} className={""}>
                <Outlet />
            </div>
        </main>
    );
};
export default ProjectSettingsLayout;
