import { Route, Routes } from "react-router-dom";
import { RequirePermission, permissions } from "provider/permission";
import ProjectFilesOverview from "pages/project-files/overview";
import Folder from "pages/project-files/folder";
import FolderDetails from "pages/project-files/folder/details";
import CategoryFiles from "pages/project-files/category-files";
import ProjectFiles from "pages/project-files";

const ProjectFilesRoutes = () => {
	return (
		<RequirePermission permission={permissions.READ_PROJECT_FILES}>
			<Routes>
				<Route element={<ProjectFiles />}>
					<Route index element={<ProjectFilesOverview />} />
					<Route path={":folderName"} element={<Folder />} />
					<Route
						path={"categories/:category"}
						element={<CategoryFiles />}
					/>
					<Route
						path={":folderName/:subFolderName/:date"}
						element={<FolderDetails />}
					/>
				</Route>
			</Routes>
		</RequirePermission>
	);
};
export default ProjectFilesRoutes;
