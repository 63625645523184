import PropTypes from "prop-types";
import SidebarRight from "../../../../components/sidebar-right";
import { DotsHorizontalIcon, XIcon } from "@heroicons/react/outline";
import ArrowRightWithBorder from "../../../../components/arrow-right-with-border";
import { CalenderIcon } from "../../../../svg/calender";
import dayjs from "dayjs";
import { useMutation } from "react-query";
import KeyResultInput from "./key-result-input";
import { createContext, useContext, useEffect, useState } from "react";
import StatusBar from "../../../../components/statusbar";
import KeyResultItem from "./key-result-item";
import CommitmentAction from "./commitment-action";
import Textarea from "../../../../components/form-control/textarea";
import { updateCommitment } from "../../../../provider/api";

export const CommitmentDetailsContext = createContext(null);
const CommitmentDetails = ({
	commitment: currCommitment,
	showDetails,
	closeHandler,
	cacheUpdater = () => {},
}) => {
	const [commitment, setCommitment] = useState(currCommitment);
	const [percentageToComplete, setPercentageToComplete] = useState(0);
	const [keyResults, setKeyResults] = useState([]);
	const [completedKeyResults, setCompletedKeyResults] = useState([]);
	const isCompleted = commitment.status === 2;
	const [isAllKeyResultCompleted, setIsAllKeyResultCompleted] =
		useState(false);
	const [closingRemark, setClosingRemark] = useState("");

	const getCommitmentCurrStatus = () => {
		switch (commitment?.status) {
			case 0:
				return "OFF TRACK";
			case 1:
				return "ON TRACK";
			case 2:
				return "COMPLETED";
			case 3:
				return "OVERDUE";
			default:
				return "INVALID STATUS";
		}
	};
	const commitmentCurrStatus = getCommitmentCurrStatus();

	// console.log("🔑 Results", keyResults);
	// console.log("✅ completed", completedKeyResults);

	useEffect(() => {
		setCommitment(currCommitment);
	}, [currCommitment]);

	useEffect(() => {
		if (commitment.results) {
			setKeyResults(commitment.results);
			setCompletedKeyResults(
				commitment.results.filter((keyResult_) => {
					return keyResult_.status; // ✅ completed key results.
				})
			);
		}
	}, [commitment]);

	useEffect(() => {
		updateProgressBar();
		checkCommitmentCompletion();
	}, [completedKeyResults, keyResults]); // eslint-disable-line react-hooks/exhaustive-deps

	const updateProgressBar = () => {
		setPercentageToComplete(
			(completedKeyResults.length * 100) / keyResults.length
		);
	};

	const checkCommitmentCompletion = () => {
		if (
			completedKeyResults.length === keyResults.length &&
			keyResults?.length !== 0
		) {
			setIsAllKeyResultCompleted(true);
		} else setIsAllKeyResultCompleted(false);
	};

	const totalCompletedKeyResults = () => {
		return keyResults.filter((keyResult_) => {
			return keyResult_.status; // ✅ completed key results.
		}).length;
	};

	// console.log("✅ completed 🔑 results", totalCompletedKeyResults());

	const formatDate = (date) => {
		return dayjs(date).format("DD MMMM YYYY");
	};

	const { isLoading: isUpdating, mutate: _updateCommitment } = useMutation(
		`update-commitment-${commitment._id}`,
		updateCommitment,
		{
			onSuccess: ({ data: updatedCommitment }) => {
				setCommitment(updatedCommitment);
				console.log("✅ updated details", updatedCommitment);
				cacheUpdater(updatedCommitment);
				// updateCommitmentCache(updatedCommitment)
				// closeHandler(false);
			},
		}
	);

	// Function to add a key result, implemented separately to capture the specific loading state
	const { isLoading: isAddingKeyResult, mutate: _addKeyResults } =
		useMutation(["update-commitment", commitment._id], updateCommitment, {
			onSuccess: ({ data: updatedCommitment }) => {
				console.log("📝 commitments", updatedCommitment);
				setKeyResults(updatedCommitment.results);
				cacheUpdater(updatedCommitment);
				// updateCommitmentCache(updatedCommitment)
				// queryClient.invalidateQueries([`event-report-${reportId}`, reportId])
			},
			onError: (error) => {
				console.log("errrr");
				console.log(error);
			},
		});

	const addKeyResults = (newResults) => {
		const payload = {
			id: commitment._id,
			results: [...keyResults, ...newResults],
			// results: []
		};
		_addKeyResults(payload);
		// (!isAddingKeyResult) && setKeyResults([...keyResults, ...newResults]);
	};

	const getStatusClassName = () => {
		if (commitmentCurrStatus === "COMPLETED") {
			return "bg-green-500/20 text-green-600 tracking-wider text-xs px-3 py-1 border-[1px] border-green-300 rounded-full";
		}

		if (commitmentCurrStatus === "OVERDUE") {
			return "bg-red-500/20 text-red-600 tracking-wider text-xs px-3 py-1 border-[1px] bg-red-300 rounded-full";
		}

		if (commitmentCurrStatus === "OFF TRACK") {
			return "bg-[#FC625D]/20 text-[#FC625D] tracking-wider text-xs px-3 py-1 border-[1px] border-[#FC625D]/30 rounded-full";
		}

		if (commitmentCurrStatus === "ON TRACK") {
			return "bg-blue-500/20 text-blue-600 tracking-wider text-xs px-3 py-1 border-[1px] border-blue-300 rounded-full";
		}
	};

	const handleChange = ({ target }) => {
		setClosingRemark(target.value);
	};

	const handleComplete = () => {
		const payload = {
			id: commitment._id,
			closingRemark,
			completedOn: new Date(), // current date
			status: 2,
		};
		console.clear();
		console.log("payload", payload);
		_updateCommitment(payload);
	};

	return (
		<CommitmentDetailsContext.Provider
			value={{
				keyResults,
				completedKeyResults,
				setCompletedKeyResults,
				totalCompletedKeyResults,
				cacheUpdater,
				setCommitment,
				commitment,
				setPercentageToComplete,
				setKeyResults,
			}}
		>
			<SidebarRight isOpen={showDetails} onClose={closeHandler}>
				<div
					className={
						"bg-white px-10 relative h-full overflow-y-auto pb-20"
					}
				>
					<div
						className={
							"bg-white pt-3 sticky top-0 z-10 border-b-[1.4px] border-b-[#E8ECEE]"
						}
					>
						<div
							className={
								"flex items-center relative justify-between"
							}
						>
							<CommitmentAction
								onClick={handleComplete}
								isCompleted={isCompleted}
								keyResultCompleted={isAllKeyResultCompleted}
								status={commitmentCurrStatus}
							/>

							<div className={"flex items-center space-x-3"}>
								<DotsHorizontalIcon
									className={"h-5 text-[#6f7782]"}
								/>

								<button className="p-2" onClick={closeHandler}>
									<ArrowRightWithBorder
										className={"h-4 w-4"}
									/>
								</button>
							</div>

							<div
								className={
									"bg-white absolute hidden top-full px-5 pt-3 pb-5 border-t shadow-base rounded-lg backdrop-blur-lg "
								}
							>
								<div className={"relative"}>
									<button
										className={
											"absolute -top-2 rounded-full h-6 w-6 bg-gray-200 text-gray-500 center -right-2"
										}
									>
										<XIcon className={"h-4 w-4"} />
									</button>
									<div className={"space-y-2 text-xs"}>
										<label htmlFor="close-remark">
											Close Remark
										</label>
										<Textarea
											className={
												"border-none bg-[#f4f4f4] text-xs"
											}
											rows={2}
										/>
									</div>
								</div>
							</div>
						</div>

						<div className="py-3">
							<StatusBar percentage={percentageToComplete} />
						</div>
					</div>

					<div className={"py-6"}>
						<p
							className={
								"text-2xl text-[#303841] pr-20 leading-relaxed font-medium"
							}
						>
							{commitment?.title}
						</p>
					</div>

					<div
						className={
							"flex items-center space-x-6 text-[13px] font-light"
						}
					>
						<div className={"text-[#B0B4BA] space-y-3"}>
							<p>Stakeholder:</p>
							<p>Due date:</p>
							{commitment?.stakeholder?.contactPerson && (
								<p>Contact Personal:</p>
							)}

							<p>Status:</p>
						</div>

						<div className={"space-y-3 font-normal"}>
							<p className={"font-normal"}>
								{commitment?.stakeholder?.name}
							</p>
							<p
								className={
									"flex items-center space-x-3 text-[#51BF82]"
								}
							>
								<CalenderIcon
									className={"h-4 w-4"}
									color={"#51BF82"}
								/>
								<span>{formatDate(commitment.endDate)}</span>
							</p>
							{commitment?.stakeholder?.contactPerson && (
								<p className={"text-dark "}>
									{
										commitment?.stakeholder?.contactPerson
											.name
									}
								</p>
							)}

							<p>
								<span className={`${getStatusClassName()}`}>
									{commitmentCurrStatus.toLowerCase()}
								</span>
							</p>
						</div>
					</div>

					<div className={"text-[13px] font-light pt-3 space-y-1"}>
						<p className={"text-dark font-medium"}>Descriptions:</p>
						<p className={"text-dark leading-relaxed opacity-90"}>
							{commitment?.description}
						</p>
					</div>

					{commitment?.closingRemark && (
						<div
							className={"text-[13px] font-light pt-3 space-y-1"}
						>
							<p className={"text-dark font-medium"}>
								Closing Remark:
							</p>
							<p
								className={
									"text-dark leading-relaxed opacity-90"
								}
							>
								{commitment?.closingRemark}
							</p>
						</div>
					)}

					{isAllKeyResultCompleted && !isCompleted && (
						<div className={"space-y-2"}>
							<p className={"text-dark text-[13px] font-medium"}>
								Add Closing Remark
							</p>
							<Textarea
								className={"text-[13px] "}
								onChange={handleChange}
							/>
						</div>
					)}

					<div className={"text-[13px] font-light pt-3 space-y-2"}>
						<p className={"text-dark font-medium"}>Millstones</p>

						<div className="grid grid-cols-1 ">
							<div className="overflow-hidden divide-y divide-[#E4E4E4] rounded-lg">
								{keyResults.map((keyResult) => (
									<KeyResultItem
										isCompleted={isCompleted}
										commitment={commitment}
										key={keyResult._id}
										keyResult={keyResult}
									/>
								))}
							</div>

							<div>
								<KeyResultInput
									isCompleted={isCompleted}
									addKeyResults={addKeyResults}
									isAddingKeyResult={isAddingKeyResult}
								/>
							</div>
						</div>
					</div>
				</div>
			</SidebarRight>
		</CommitmentDetailsContext.Provider>
	);
};

export const useCommitmentDetailsContext = () =>
	useContext(CommitmentDetailsContext);

CommitmentDetails.propTypes = {
	commitment: PropTypes.object.isRequired,
};
export default CommitmentDetails;
