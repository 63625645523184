const Option = (
    {
        value = "",
        text = null,
        // isSelected=false,
        className
    }, ...props) => {

    return (
        <option {...props}
                value={value}
            // selected={isSelected}
                className={`${className} capitalize`}
        >
            {text ? text : value}
        </option>
    )
}
export default Option;