import dayjs from "dayjs";
import React, { useState } from "react";
import DayPopup from "./day-popup";
import { useCalender } from "./index";

const Day = ({
    day,
    listOfEvents,
    className,
    cardKey,
    isSelected = 0,
    showBorder = true,
    isLoading = false,
}) => {
    const getRandomColor = () => {
        const colorList = ["#26C0E2", "#EF37FC", "#FD8F6B"];
        return colorList[Math.floor(Math.random() * colorList.length)];
    };

    const getCurrentDayClass = () => {
        return day.format("DD-MM-YYYY") === dayjs().format("DD-MM-YYYY")
            ? "bg-accent h-8 text-white w-8 tex flex justify-center items-center rounded-full"
            : "";
    };

    const [showPopup, setShowPopup] = useState(false);
    const { calendarSize, setShowDetails, setActiveEvent } = useCalender();

    const isCalendarLargeSize = () => calendarSize === "large";

    const getEventsForThisDay = () => {
        return listOfEvents.filter((event) => {
            return (
                dayjs(new Date(event.eventDate)).format("DD-MM-YYYY") ===
                day.format("DD-MM-YYYY")
            );
        });
    };

    const getInnerText = () => {
        if (isCalendarLargeSize()) {
            return (
                <span>
                    More ( <strong>{getEventsForThisDay().length - 2}</strong> )
                </span>
            );
        }
        return (
            <span>
                Total (<strong>{getEventsForThisDay().length}</strong>)
            </span>
        );
    };

    const showEventDetails = (event) => {
        setActiveEvent(event);
        setShowDetails(true);
    };

    return (
        <div
            key={cardKey}
            className={`
                ${className} h-full w-full relative ${
                isLoading ? "skeleton-loading" : ""
            } text-xs flex pt-2 items-center justify-center 
                ${
                    showBorder && isLoading
                        ? "border-b-[2px] border-r-[2px] border-white"
                        : "border-b-[1px] border-r-[1px] border-accent-1"
                }`}
        >
            <header
                className={" flex w-full justify-center flex-col items-center"}
            >
                <p
                    className={`cursor-pointer ${
                        isLoading ? "opacity-0" : ""
                    } ${isSelected ? "bg-white rounded-full p-2" : ""}`}
                >
                    {day.format("DD")}
                </p>
                <div
                    className={`w-[90%]  ${
                        isCalendarLargeSize() ? "h-24" : "h-10"
                    } space-y-1 overflow-auto`}
                >
                    {isCalendarLargeSize() && (
                        <>
                            {getEventsForThisDay()
                                .slice(0, 2)
                                .map((event) => (
                                    <p
                                        onClick={() => showEventDetails(event)}
                                        className={
                                            "" +
                                            "text-white cursor-pointer  mt-2 leading-tight py-[4px] truncate rounded text-center w-full"
                                        }
                                        style={{
                                            backgroundColor: getRandomColor(),
                                        }}
                                    >
                                        {event.title}
                                    </p>
                                ))}
                        </>
                    )}

                    {getEventsForThisDay().length > 2 && (
                        <>
                            <p
                                onClick={() => setShowPopup(!showPopup)}
                                className={`bg-gray-200
                                text-gray-500 mt-2 
                                leading-tight cursor-pointer font-semibold py-[4px] 
                                truncate rounded text-center w-full`}
                            >
                                {getInnerText()}
                            </p>

                            {showPopup && (
                                <DayPopup
                                    setShowPopup={setShowPopup}
                                    day={day}
                                    events={getEventsForThisDay()}
                                />
                            )}
                        </>
                    )}
                </div>
            </header>
        </div>
    );
};

export default Day;
