import { ArrowRightIcon } from "@heroicons/react/outline";
import Button from "../../../../../../components/Button";

const ActionButtons = ({
    onPrevious: handlePrevious,
    nextBtnLabel,
    nextBtnType,
    className,
    isLoading,
    onNext: handleNext = () => {},
}) => {
    return (
        <div className={" flex justify-between items-center"}>
            <button
                onClick={handlePrevious}
                className={
                    " px-6  py-3 text-sm rounded-[2px] text-primary flex items-center space-x-2"
                }
            >
                <p>PREVIOUS STEP</p>
            </button>
            <Button
                loading={isLoading}
                onClick={handleNext}
                type={nextBtnType ?? "submit"}
                className={`bg-accent px-6 py-3 text-xs ${className} rounded-[2px] text-white flex items-center space-x-2`}
            >
                <p>{nextBtnLabel}</p>
                <ArrowRightIcon className={"h-4 w-4"} />
            </Button>
        </div>
    );
};
export default ActionButtons;
