import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as Yup from "yup";
import { useQuery } from "react-query";

import FormHeader from "./form-header";
import Textarea from "components/unstable/textarea";
import Input from "components/unstable/input";
import FormFooter from "./form-footer";
import { getDepartments } from "provider/api";
import { localStorageKeys, queryKeys } from "../../../../constants";
import useGlobalContext from "hooks/useGlobalContext";
import UnstableCombobox from "components/combobox/unstable-combobox";
import { isEmptyObject } from "../../../../utils";
import {
    getValueFromLocalStorage,
    saveValueToLocalStorage,
} from "../../../../hooks/useLocalStorageState";
import dayjs from "dayjs";

const BasicDetails = ({ setCurrStep, onClose: handleClose }) => {
    const {
        projectDetails,
        stakeholder: stakeholders,
        isLoading: isLoadingStakeholders,
    } = useGlobalContext();
    const [departments, setDepartments] = useState([]);
    const { commitmentForm: commitmentLSKeys } = localStorageKeys;
    const defaultDetails = getValueFromLocalStorage(
        commitmentLSKeys.BASIC_DETAILS,
        {}
    );
    const [stakeholder, setStakeholder] = useState(
        defaultDetails?.stakeholder ?? {}
    );
    const [department, setDepartment] = useState(
        defaultDetails?.department ?? {}
    );

    const formSchema = Yup.object({
        description: Yup.string().required(),
        title: Yup.string().required(),
        madeFor: Yup.string().required(),
        startDate: Yup.date().required(),
        endDate: Yup.date().required(),
    });

    const { refetch: refetchDepartments, isLoading } = useQuery(
        [queryKeys.DEPARTMENTS, projectDetails?.piu],
        getDepartments,
        {
            enabled: false,
            onSuccess: ({ data }) => {
                setDepartments(data);
            },
        }
    );

    useEffect(() => {
        if (projectDetails?.piu && !departments.length) {
            refetchDepartments();
        }
    }, [projectDetails?.piu]);

    const {
        handleSubmit,
        register,
        formState: { errors, isSubmitted },
    } = useForm({
        resolver: yupResolver(formSchema),
    });

    const fields = [
        {
            name: "stakeholder",
            defaultOption: stakeholder,
            hasError: isSubmitted && isEmptyObject(stakeholder),
            displayItemName: "name",
            options: stakeholders.map(({ _id, name } = {}) => ({ _id, name })),
            onChange: setStakeholder,
            isLoading: isLoadingStakeholders,
            className: "py-2",
        },
        {
            name: "madeFor",
            hasError: !!errors?.madeFor?.message,
            defaultValue: defaultDetails?.madeFor,
            placeholder: "e.g Bonny Kato",
            register,
            className: "py-2",
        },
        {
            name: "department",
            defaultOption: department,
            hasError: isSubmitted && isEmptyObject(department),
            displayItemName: "name",
            options: departments.map(({ _id, name } = {}) => ({ _id, name })),
            onChange: setDepartment,
            isLoading,
            className: "py-2",
        },

        {
            name: "title",
            hasError: !!errors?.title?.message,
            defaultValue: defaultDetails?.title,
            placeholder: "e.g. Following up with the cement vendor",
            register,
            className: "py-2",
        },

        {
            name: "startDate",
            hasError: !!errors?.startDate?.message,
            defaultValue: defaultDetails?.startDate
                ? dayjs(defaultDetails?.startDate).format("YYYY-MM-DD")
                : "",
            register,
            className: "py-2",
            type: "date",
        },
        {
            name: "endDate",
            label: "NEd",
            hasError: !!errors?.endDate?.message,
            defaultValue: defaultDetails?.endDate
                ? dayjs(defaultDetails?.endDate).format("YYYY-MM-DD")
                : "",
            register,
            className: "py-2",
            errorMessage: "Please provide a valid end date",
            type: "date",
        },
        {
            name: "description",
            className: "py-2 h-24",
            hasError: !!errors?.description?.message,
            defaultValue: defaultDetails?.description,
            register,
            wrapperClass: "col-span-2",
        },
    ];

    const buildPayloadAndAdvanceCurrStep = async (formData) => {
        const payload = {
            ...formData,
            project: projectDetails._id,
            stakeholder: stakeholder,
            department: department,
        };
        setCurrStep(2);
        await saveValueToLocalStorage(commitmentLSKeys.BASIC_DETAILS, payload);
    };

    const onSubmit = async (formData) => {
        if (!isEmptyObject(department) && !isEmptyObject(stakeholder)) {
            await buildPayloadAndAdvanceCurrStep(formData);
        }
    };

    const renderFormFields = (fields) => {
        return fields.map((props) => {
            if (props?.options) {
                return <UnstableCombobox key={props.name} {...props} />;
            } else if (props.name === "description") {
                return <Textarea key={props.name} {...props} />;
            }
            return <Input key={props.name} props={props} />;
        });
    };

    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            className={" bg-[#F9F9F9] w-[45%] mt-5 rounded-md"}
        >
            <FormHeader onClose={handleClose} />

            <div className={"grid gap-x-4 px-10 py-6 gap-y-5 grid-cols-2"}>
                {renderFormFields(fields)}
            </div>

            <FormFooter />
        </form>
    );
};
export default BasicDetails;
