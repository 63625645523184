//Todo: Delete/Remove all logics for editing Engagement-Plan
import {
    addEngagementPlan,
    deleteEngagementPlan,
    editEngagementPlan,
} from "../../../../../provider/api";

import PlanForm from "../form/plan-form";
import { useMutation } from "react-query";
import { Fragment, useEffect, useState } from "react";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { UserGroup } from "../../../../../components/user-group";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const frequencyOptions = [
    { name: "Daily", value: 1 },
    { name: "Weekly", value: 2 },
    { name: "Monthly", value: 3 },
    { name: "Only once", value: 4 },
    { name: "Every 2 weeks", value: 5 },
    { name: "Every 3 weeks", value: 6 },
    { name: "Every weekday", value: 7 },
];

export const PhaseContents = ({ currentProjectPhase = {}, isLoading }) => {
    const initialPlan = {
        _id: "",
        channel: "",
        project: "",
        activity: "",
        isMeeting: 0,
        frequency: 0,
        endingDate: "",
        stakeholder: "",
        projectPhase: "",
        startingDate: "",
    };

    const initialStakeholder = {
        _id: "",
        name: "",
        type: "",
        plans: [],
    };
    const [activeStakeholder, setActiveStakeholder] =
        useState(initialStakeholder);

    const projectId = process.env.REACT_APP_PROJECT_ID;
    const { plans: initialPlans, _id: phaseInfo } = currentProjectPhase;

    const [isOpen, setIsOpen] = useState(false);
    const [hoveredPlan, setHoveredPlan] = useState(null);
    const [activePlan, setActivePlan] = useState(initialPlan);
    const [isEditingMode, setIsEditingMode] = useState(false);
    const [phasePlans, setPhasePlans] = useState(initialPlans);

    const { mutate: sendEngagementPlan, isLoading: isSavingPlan } = useMutation(
        addEngagementPlan,
        {
            onSuccess: ({ data }) => {
                setActiveStakeholder({
                    ...activeStakeholder,
                    plans: [...activeStakeholder.plans, data],
                });

                const newPlansList = phasePlans.map((stakeholder) => {
                    if (stakeholder._id === activeStakeholder._id) {
                        return {
                            ...activeStakeholder,
                            plans: [...activeStakeholder.plans, data],
                        };
                    }

                    return stakeholder;
                });

                // Clear form
                setActivePlan(initialPlan);

                // Update state
                setIsOpen(false);
                setPhasePlans(newPlansList);
            },
            onError: ({ error }) => {},
        }
    );

    const { mutate: updateEngagementPlan, isLoading: isUpdatingPlan } =
        useMutation(editEngagementPlan, {
            onSuccess: ({ data }) => {
                const newPlansList = activeStakeholder.plans.map((plan) => {
                    if (plan._id === data._id) {
                        return data;
                    }
                    return plan;
                });

                setActiveStakeholder({
                    ...activeStakeholder,
                    plans: newPlansList,
                });

                let newList = phasePlans.map((stakeholder) => {
                    if (stakeholder._id === activeStakeholder._id) {
                        return { ...stakeholder, plans: newPlansList };
                    }
                    return stakeholder;
                });

                // Hide form
                setIsEditingMode(false);

                // Clear form
                setActivePlan(initialPlan);

                // Update state
                setIsOpen(false);
                setPhasePlans(newList);
            },
        });

    const { mutate: removeEngagementPlan, isLoading: isDeletingPlan } =
        useMutation(deleteEngagementPlan, {
            // onSuccess: ({ data }) => {},
        });

    const getFormattedDate = (dateString) => {
        return new Date(dateString).toDateString();
    };

    useEffect(() => {
        if (currentProjectPhase?._id) {
            setPhasePlans(currentProjectPhase.plans);
        }
    }, [currentProjectPhase]);

    const updatePlan = () => {
        updateEngagementPlan(activePlan);
    };

    const deletePlan = (targetPlan, targetStakeholder) => {
        const newPlansList = targetStakeholder.plans.filter(
            (plan) => plan._id !== targetPlan._id
        );

        let newList = phasePlans.map((stakeholder) => {
            if (stakeholder._id === targetStakeholder._id) {
                return { ...stakeholder, plans: newPlansList };
            }
            return stakeholder;
        });

        // Update state
        setPhasePlans(newList);

        removeEngagementPlan(targetPlan._id);
    };

    const saveNewPlan = () => {
        const { _id, ...payload } = activePlan;

        sendEngagementPlan({
            ...payload,
            projectPhase: phaseInfo._id,
            stakeholder: activeStakeholder._id,
            project: projectId || phaseInfo.project,
        });
    };

    return (
        <Fragment>
            <PlanForm
                onClose={setIsOpen}
                isOpen={isOpen}
                activePlan={activePlan}
                updatePlan={updatePlan}
                isEditing={isEditingMode}
                saveNewPlan={saveNewPlan}
                isSavingPlan={isSavingPlan}
                setActivePlan={setActivePlan}
                isUpdatingPlan={isUpdatingPlan}
            />

            <div className={""}>
                <div className={""}>
                    {/* Stakeholder */}
                    <div className={"pb-4 "}>
                        {phasePlans?.length > 0
                            ? phasePlans.map((stakeholder, index) => (
                                  <div
                                      key={stakeholder._id}
                                      className={`
                            group ${
                                index % 2 === 0 ? "bg-white" : "bg-[#F9FAFC]"
                            }
                          border-[#EEEEEE]
                          border-[1px] overflow-hidden
                          gap-x-4 hover:border-l-4
                          hover:border-l-green-500
                          ${
                              isOpen &&
                              activeStakeholder._id === stakeholder._id
                                  ? "border-l-4 border-l-green-500 border-y-[#51BF82]/50 hover:shadow-none"
                                  : ""
                          }
                          hover:border-t-[#51BF82]/60
                          hover:shadow-xl
                          hover:shadow-green-500/5`}
                                  >
                                      <div
                                          className={
                                              "grid-cols-5  gap-x-8  grid py-6 px-8 b"
                                          }
                                      >
                                          {/*Stakeholder Name*/}
                                          <div
                                              className={
                                                  "flex flex-col justify-between "
                                              }
                                          >
                                              <div className={"space-y-2"}>
                                                  <div
                                                      className={
                                                          "flex items-center space-x-2"
                                                      }
                                                  >
                                                      <UserGroup
                                                          className={"h-5 w-5"}
                                                      />
                                                      <p
                                                          className={
                                                              "text-sm text-[#AAB6C5]"
                                                          }
                                                      >
                                                          {stakeholder.type}
                                                      </p>
                                                  </div>
                                                  <p
                                                      className={
                                                          "font-medium text-sm text-accent"
                                                      }
                                                  >
                                                      {stakeholder.name}
                                                  </p>
                                              </div>

                                              <div
                                                  className={
                                                      "pt-10  group-hover:inline"
                                                  }
                                              >
                                                  <button
                                                      onClick={(e) => {
                                                          e.preventDefault();
                                                          setIsOpen(true);
                                                          setIsEditingMode(
                                                              false
                                                          );
                                                          setActivePlan(
                                                              initialPlan
                                                          );
                                                          setActiveStakeholder(
                                                              stakeholder
                                                          );
                                                      }}
                                                      className={`italic text-xs  bg-[#F8FCFF]
                                          px-4 py-2 flex space-x-2 text-accent font-light ${
                                              isOpen &&
                                              activeStakeholder._id ===
                                                  stakeholder._id
                                                  ? "hidden"
                                                  : "inline"
                                          }
                                  border border-[#DCDFF1] items-center rounded-[2px]`}
                                                  >
                                                      <p>Add Plan</p>
                                                      <FontAwesomeIcon
                                                          icon={faPlus}
                                                          className={"h-3 w-3"}
                                                      />
                                                  </button>
                                              </div>
                                          </div>

                                          <div
                                              className={
                                                  "col-span-4 space-y-1 "
                                              }
                                          >
                                              {!stakeholder?.plans.length ? (
                                                  <span className="ml-32">
                                                      No any engagement plan has
                                                      been recorded so far with
                                                      this stakeholder. Click{" "}
                                                      <b>
                                                          <i>Add Plan</i>
                                                      </b>{" "}
                                                      button to start.
                                                  </span>
                                              ) : (
                                                  stakeholder.plans.map(
                                                      (plan) => (
                                                          <div
                                                              key={plan._id}
                                                              className={`grid relative gap-x-8 group py-2 group grid-cols-5`}
                                                              onMouseEnter={(
                                                                  e
                                                              ) => {
                                                                  e.preventDefault();
                                                                  setHoveredPlan(
                                                                      plan._id
                                                                  );
                                                              }}
                                                              onMouseLeave={(
                                                                  e
                                                              ) => {
                                                                  e.preventDefault();
                                                                  setHoveredPlan(
                                                                      null
                                                                  );
                                                              }}
                                                          >
                                                              <div
                                                                  className={
                                                                      "space-y-6 "
                                                                  }
                                                              >
                                                                  {/*title */}
                                                                  <div
                                                                      className={
                                                                          "space-x-3 flex text-sm items-center text-[#838EAB]"
                                                                      }
                                                                  >
                                                                      <div>
                                                                          <div
                                                                              className={
                                                                                  "h-[6px] w-[6px] rounded-full bg-green-400"
                                                                              }
                                                                          ></div>
                                                                      </div>

                                                                      <p
                                                                          className={
                                                                              "font-light"
                                                                          }
                                                                      >
                                                                          {
                                                                              plan.activity
                                                                          }{" "}
                                                                      </p>
                                                                  </div>
                                                              </div>

                                                              {/* Stakeholder Frequency */}
                                                              <div
                                                                  className={
                                                                      "font-light text-sm text-[#838EAB] space-y-6"
                                                                  }
                                                              >
                                                                  <div
                                                                      className={
                                                                          "flex items-center space-x-2"
                                                                      }
                                                                  >
                                                                      <UserGroup
                                                                          className={
                                                                              "h-4 w-4"
                                                                          }
                                                                      />

                                                                      <p
                                                                          className={
                                                                              "font-light"
                                                                          }
                                                                      >
                                                                          {frequencyOptions.find(
                                                                              (
                                                                                  option
                                                                              ) =>
                                                                                  option.value ===
                                                                                  plan.frequency
                                                                          )
                                                                              ?.name ||
                                                                              "--"}
                                                                      </p>
                                                                  </div>
                                                              </div>

                                                              {/* Channel  */}
                                                              <div
                                                                  className={
                                                                      "font-light text-sm text-[#838EAB] space-y-6"
                                                                  }
                                                              >
                                                                  <div
                                                                      className={
                                                                          "flex items-center space-x-2"
                                                                      }
                                                                  >
                                                                      <p
                                                                          className={
                                                                              "font-light"
                                                                          }
                                                                      >
                                                                          {
                                                                              plan.channel
                                                                          }
                                                                      </p>
                                                                  </div>
                                                              </div>

                                                              {/* start date */}
                                                              <div
                                                                  className={
                                                                      "text-sm text-[#002F4F] space-y-6"
                                                                  }
                                                              >
                                                                  <p
                                                                      className={
                                                                          "font-light"
                                                                      }
                                                                  >
                                                                      {getFormattedDate(
                                                                          plan.startingDate
                                                                      )}
                                                                  </p>
                                                              </div>

                                                              {/* end date */}
                                                              <div
                                                                  className={
                                                                      "text-sm text-[#002F4F] space-y-6"
                                                                  }
                                                              >
                                                                  <p
                                                                      className={
                                                                          "font-light"
                                                                      }
                                                                  >
                                                                      {plan.endingDate
                                                                          ? getFormattedDate(
                                                                                plan.endingDate
                                                                            )
                                                                          : "--"}
                                                                  </p>
                                                              </div>

                                                              <div
                                                                  className={
                                                                      "absolute right-0"
                                                                  }
                                                                  hidden={
                                                                      plan._id ===
                                                                      hoveredPlan
                                                                          ? false
                                                                          : true
                                                                  }
                                                              >
                                                                  <button
                                                                      type={
                                                                          "button"
                                                                      }
                                                                      onClick={() => {
                                                                          deletePlan(
                                                                              plan,
                                                                              stakeholder
                                                                          );
                                                                      }}
                                                                      className={`text-red-500 hover:underline text-xs ${
                                                                          // isEditingMode &&
                                                                          hoveredPlan !==
                                                                          plan._id
                                                                              ? "hidden"
                                                                              : "inline"
                                                                      }`}
                                                                  >
                                                                      {isDeletingPlan
                                                                          ? "Deleteing..."
                                                                          : "Delete"}
                                                                  </button>
                                                              </div>
                                                          </div>
                                                      )
                                                  )
                                              )}
                                          </div>
                                      </div>
                                  </div>
                              ))
                            : ""}
                    </div>
                </div>
            </div>
        </Fragment>
    );
};
