const ArrowRight = ({color, className = "h-4 w-4"}) => {
    return (
        <>
            <svg className={className} id="Group_172944"
                 data-name="Group 172944"
                 xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.775 7.939">
                <path
                    id="Path_90622" data-name="Path 90622"
                    d="M7.649,4.67a.992.992,0,0,1-1.4,0L4.962,3.386V8.644a.992.992,0,0,
                        1-1.985,0V3.386L1.694,4.67a.992.992,0,1,1-1.4-1.4L3.268.289a1,1,0
                        ,0,1,1.4,0L7.649,3.266a.992.992,0,0,1,0,1.4Z"
                    transform="translate(15.775 0) rotate(90)" fill={`${color ? color : '#009ee2'}`}/>
                <circle id="Ellipse_83" data-name="Ellipse 83" cx="1.014" cy="1.014" r="1.014"
                        transform="translate(0 2.954)" fill={`${color ? color : '#009ee2'}`}/>
              <circle id="Ellipse_84" data-name="Ellipse 84" cx="1.014" cy="1.014" r="1.014"
                      transform="translate(3.12 2.954)" fill={`${color ? color: '#009ee2'}`}/>
          </svg>
      </>
  )
}
export default ArrowRight