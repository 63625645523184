import { useState } from "react";
import useEvent from "hooks/useEvent";
import Button from "components/Button";
import SidebarRight from "components/sidebar-right";
import MeetingDetails from "../../events/_partials/meeting-details";
import Loading from "./loading";
import EmptyState from "./empty-state/empty-state";
import Event from "./event";

const UpcomingEvents = ({ setTabToCalendar }) => {
    const { upcomingEvents, isLoading } = useEvent();
    const [activeEvent, setActiveEvent] = useState({});
    const [showSidebar, setShowSidebar] = useState(false);

    const handleOnClickEvent = (event) => {
        setShowSidebar(true);
        setActiveEvent(event);
    };

    return (
        <div>
            <div>
                <SidebarRight onClose={setShowSidebar} isOpen={showSidebar}>
                    <MeetingDetails
                        event={activeEvent}
                        closeSidebar={() => setShowSidebar(false)}
                    />
                </SidebarRight>
            </div>

            <div className={"space-y-5 bg-white p-5"}>
                <p className={"text-lg"}>Upcoming Events & Activities</p>
                <div className={"flex justify-end items-center"}>
                    <p
                        onClick={setTabToCalendar}
                        className={
                            "text-[#1A9EE2] hidden text-sm font-light transition-all hover:scale-x-105 cursor-pointer"
                        }
                    >
                        See all upcoming events
                    </p>
                    <Button className={"hidden px-5 uppercase"}>Filter</Button>
                </div>

                {isLoading ? (
                    <Loading />
                ) : upcomingEvents.length > 0 ? (
                    upcomingEvents
                        .slice(0, 5)
                        .map((event) => (
                            <Event
                                eventKey={event._id}
                                event={event}
                                onClick={handleOnClickEvent}
                            />
                        ))
                ) : (
                    <EmptyState />
                )}
            </div>
            {/* </div> */}
        </div>
    );
};
export default UpcomingEvents;
