const GraphSvg = ({ className }) => {
    return (
        <svg
            id="undraw_data_report_re_p4so"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            viewBox="0 0 203.002 152.358"
        >
            <path
                id="Path_133"
                data-name="Path 133"
                d="M896.478,577.819a48.923,48.923,0,0,1-2.286,13.523c-.031.1-.065.2-.1.3h-8.529c.009-.091.018-.193.027-.3.569-6.537-2.68-45.851-6.6-52.655C879.336,539.239,897.139,557.047,896.478,577.819Z"
                transform="translate(-705.437 -439.596)"
                fill="#f0f0f0"
            />
            <path
                id="Path_134"
                data-name="Path 134"
                d="M925.254,659.8c-.071.1-.146.2-.222.3h-6.4c.049-.087.1-.188.17-.3,1.057-1.907,4.185-7.611,7.089-13.523,3.12-6.354,5.984-12.949,5.743-15.336C931.711,631.476,933.87,647.887,925.254,659.8Z"
                transform="translate(-734.853 -508.051)"
                fill="#f0f0f0"
            />
            <path
                id="Path_22"
                data-name="Path 22"
                d="M367.176,278.486H206.17V154.543H367.176Z"
                transform="translate(-206.17 -154.543)"
                fill="#fff"
            />
            <path
                id="Path_23"
                data-name="Path 23"
                d="M367.176,278.486H206.17V154.543H367.176Zm-158.627-2.379H364.8V156.922H208.549Z"
                transform="translate(-206.17 -154.543)"
                fill="#e5e5e5"
            />
            <path
                id="Path_24"
                data-name="Path 24"
                d="M330.876,461.236h-12.19a1.246,1.246,0,0,1-1.245-1.245v-16.8a1.246,1.246,0,0,1,1.245-1.245h12.19a1.246,1.246,0,0,1,1.245,1.245v16.8A1.246,1.246,0,0,1,330.876,461.236Z"
                transform="translate(-288.739 -367.807)"
                fill="#e6e6e6"
            />
            <path
                id="Path_25"
                data-name="Path 25"
                d="M417.056,415.386h-12.19a1.246,1.246,0,0,1-1.245-1.245V381.4a1.246,1.246,0,0,1,1.245-1.245h12.19A1.246,1.246,0,0,1,418.3,381.4v32.743A1.246,1.246,0,0,1,417.056,415.386Z"
                transform="translate(-352.688 -321.957)"
                fill="#e6e6e6"
            />
            <path
                id="Path_26"
                data-name="Path 26"
                d="M503.236,461.236h-12.19a1.246,1.246,0,0,1-1.245-1.245v-16.8a1.246,1.246,0,0,1,1.245-1.245h12.19a1.246,1.246,0,0,1,1.245,1.245v16.8A1.246,1.246,0,0,1,503.236,461.236Z"
                transform="translate(-416.638 -367.807)"
                fill="#e6e6e6"
            />
            <path
                id="Path_27"
                data-name="Path 27"
                d="M589.415,397.286h-12.19a1.219,1.219,0,0,1-1.245-1.189V356.951a1.219,1.219,0,0,1,1.245-1.189h12.19a1.219,1.219,0,0,1,1.245,1.189V396.1A1.219,1.219,0,0,1,589.415,397.286Z"
                transform="translate(-480.587 -303.857)"
                fill="#e6e6e6"
            />
            <path
                id="Path_28"
                data-name="Path 28"
                d="M675.595,364.709H663.4a1.247,1.247,0,0,1-1.245-1.245V313.1a1.247,1.247,0,0,1,1.245-1.245h12.19a1.246,1.246,0,0,1,1.245,1.245v50.359A1.246,1.246,0,0,1,675.595,364.709Z"
                transform="translate(-544.536 -271.28)"
                fill="#f2f2f2"
            />
            <circle
                id="Ellipse_22"
                data-name="Ellipse 22"
                cx="2.517"
                cy="2.517"
                r="2.517"
                transform="translate(33.526 64.069)"
                fill="#3f3d56"
            />
            <circle
                id="Ellipse_23"
                data-name="Ellipse 23"
                cx="2.517"
                cy="2.517"
                r="2.517"
                transform="translate(55.756 47.711)"
                fill="#3f3d56"
            />
            <circle
                id="Ellipse_24"
                data-name="Ellipse 24"
                cx="2.517"
                cy="2.517"
                r="2.517"
                transform="translate(77.986 64.069)"
                fill="#3f3d56"
            />
            <circle
                id="Ellipse_25"
                data-name="Ellipse 25"
                cx="2.517"
                cy="2.517"
                r="2.517"
                transform="translate(100.217 39.741)"
                fill="#3f3d56"
            />
            <circle
                id="Ellipse_26"
                data-name="Ellipse 26"
                cx="2.517"
                cy="2.517"
                r="2.517"
                transform="translate(122.447 30.514)"
                fill="#3f3d56"
            />
            <path
                id="Path_29"
                data-name="Path 29"
                d="M183.517,161.826l-22.283-16.69L139.255,161.6l-.5-.671,22.482-16.839L183.411,160.7l22.038-24.117.088-.036,22.23-8.957.314.778-22.142,8.921Z"
                transform="translate(-102.961 -94.677)"
                fill="#3f3d56"
            />
            <path
                id="Path_30"
                data-name="Path 30"
                d="M588.921,532.7h-3.786l-1.8-14.605h5.589Z"
                transform="translate(-432.861 -384.45)"
                fill="#ffb6b6"
            />
            <path
                id="Path_31"
                data-name="Path 31"
                d="M779.787,729.37H767.577v-.154a4.752,4.752,0,0,1,4.752-4.752h7.458Z"
                transform="translate(-622.761 -577.452)"
                fill="#2f2e41"
            />
            <path
                id="Path_32"
                data-name="Path 32"
                d="M758.705,489.607l2.15,34.251,7.218,24.109,5.592.4-2.808-29.383,1.231-19.513s5.837-4.351,2.609-9.588Z"
                transform="translate(-616.178 -403.177)"
                fill="#2f2e41"
            />
            <path
                id="Path_33"
                data-name="Path 33"
                d="M598.5,532.7h-3.787l-1.8-14.605H598.5Z"
                transform="translate(-439.969 -384.45)"
                fill="#ffb6b6"
            />
            <path
                id="Path_34"
                data-name="Path 34"
                d="M789.366,729.37H777.156v-.154a4.752,4.752,0,0,1,4.752-4.752h7.458Z"
                transform="translate(-629.869 -577.452)"
                fill="#2f2e41"
            />
            <path
                id="Path_35"
                data-name="Path 35"
                d="M768.284,489.607l2.15,34.251,7.218,24.109,5.592.4-2.808-29.383,1.231-19.513s5.837-4.351,2.609-9.588Z"
                transform="translate(-623.286 -403.177)"
                fill="#2f2e41"
            />
            <path
                id="Path_36"
                data-name="Path 36"
                d="M771.462,378.443c-6.448,0-14.038-3.941-14.477-4.172l-.122-.064,14.8-37.755,7.329-3.463a3.507,3.507,0,0,1,4.984,3.56l-2.909,26.093-3.036,13.514-.032.032A9,9,0,0,1,771.462,378.443Z"
                transform="translate(-614.81 -286.709)"
                fill="#3f3d56"
            />
            <path
                id="Path_37"
                data-name="Path 37"
                d="M717.558,411.888a3.106,3.106,0,0,1,4.538,1.446l11.021-.6-2.892,4.953L720.2,417.5a3.123,3.123,0,0,1-2.644-5.61Z"
                transform="translate(-584.556 -345.147)"
                fill="#ffb6b6"
            />
            <path
                id="Path_38"
                data-name="Path 38"
                d="M774.323,375.494l-11.044-.282.143-7.306,5.222-1.662,8.566-12.015a5.527,5.527,0,1,1,8.813,6.664Z"
                transform="translate(-619.572 -301)"
                fill="#3f3d56"
            />
            <circle
                id="Ellipse_27"
                data-name="Ellipse 27"
                cx="7.339"
                cy="7.339"
                r="7.339"
                transform="translate(153.807 30.212)"
                fill="#ffb6b6"
            />
            <path
                id="Path_39"
                data-name="Path 39"
                d="M792.755,262.384a5.016,5.016,0,0,1,2.749-1.442,7,7,0,0,0,2.953-.927c.927-.738,1.334-2.14,2.471-2.452a2.709,2.709,0,0,1,1.479.117,9.575,9.575,0,0,1,4.633,3.089,3.967,3.967,0,0,0,1.066,1.066,7.724,7.724,0,0,1,.735.358,2,2,0,0,1,.6.781,7.6,7.6,0,0,1,.531,5.334c-.284,1.053-.8,2.029-1.1,3.089s-1.7,3.075-2.03,2.574a6.513,6.513,0,0,1-.927-1.544c-.349-1.136-.034-2.279-.923-3.069-.55-.488-1.544-.815-1.918-.185-.148.244-.139.553-.266.806a1.059,1.059,0,0,1-1.452.309,2.227,2.227,0,0,1-.908-1.347c-.72-2.434-1.946-3.966-4.549-2.925a3.124,3.124,0,0,1-3.045-.015C791.624,265.123,791.874,263.384,792.755,262.384Z"
                transform="translate(-640.893 -230.949)"
                fill="#2f2e41"
            />
            <path
                id="Path_40"
                data-name="Path 40"
                d="M692.358,380.275h-12.19a1.247,1.247,0,0,1-1.245-1.245V328.671a1.246,1.246,0,0,1,1.245-1.245h12.19a1.246,1.246,0,0,1,1.245,1.245V379.03A1.246,1.246,0,0,1,692.358,380.275Z"
                transform="translate(-556.975 -282.83)"
                fill="#628799"
            />
            <path
                id="Path_41"
                data-name="Path 41"
                d="M716.361,428.651A3.106,3.106,0,0,1,720.9,430.1l11.021-.6-2.892,4.953-10.022-.188a3.123,3.123,0,0,1-2.644-5.61Z"
                transform="translate(-583.667 -357.586)"
                fill="#ffb6b6"
            />
            <path
                id="Path_42"
                data-name="Path 42"
                d="M773.125,392.256l-11.044-.282.143-7.306,5.222-1.662,8.566-12.015a5.527,5.527,0,1,1,8.813,6.664Z"
                transform="translate(-618.683 -313.438)"
                fill="#3f3d56"
            />
            <path
                id="Path_43"
                data-name="Path 43"
                d="M652.546,743.412H534.871a.309.309,0,0,1,0-.618H652.546a.309.309,0,0,1,0,.618Z"
                transform="translate(-449.853 -591.054)"
                fill="#cacaca"
            />
        </svg>
    );
};
export default GraphSvg;
