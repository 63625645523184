import {DownloadIcon, EmailIcon, PineIcon, PrinterIcon} from "../../../svg";
import {useState} from "react";

const PinnedFileCard = (props) => {
    const [showActions, setShowActions] = useState(false);

    const handleClick = () => {
        setShowActions(!showActions)
    }

    const ActionWrapper = ({children}) => (
        <div className={'h-10 cursor-pointer hover:bg-[#EDF1F7] center rounded w-10'}>
            {children}
        </div>
    )
    return (
        <div{...props} onClick={handleClick} className={` border-b-[#E4E6EA] items-between border-b-[1.5px] 
            cursor-pointer group relative  py-5
        `}>

            <div className={'flex space-x-4'}>
                <div>
                    <div className={'h-10 w-10 rounded-[5px] bg-white center p-2'}>
                        <img src="/images/pdf.png" alt="pdf file" className={'w-10'}/>
                    </div>
                </div>
                <div className={'space-y-1'}>
                    <p className={'text-[#3D3F4B] text-[15px]'}>Meeting Attendance</p>
                    <p className={'text-xs text-[#A3A6AB]'}>.PDF file</p>
                </div>
            </div>

            <div className={'text-[13px] text-[#838EAB] font-medium'}>
                20 MB
            </div>


            <div className={'absolute z-50  bg-white cursor-auto opacity-0 ' +
                'pointer-events-none group-hover:pointer-events-auto ' +
                'group-hover:opacity-100 origin-center space-y-8 scale-50 ' +
                'group-hover:scale-100 transition-all rounded-[4px] w-full p-5 top-0 '}
                 style={{boxShadow: "6px 8px 18px #151E4033"}}>
                <div className={'items-between'}>
                    <div className={'flex space-x-4'}>
                        <div>
                            <div className={'h-10 w-10 rounded-[5px] bg-[#D8EAE6] center p-2'}>
                                <img src="/images/excel.png" alt="pdf file" className={'w-10'}/>
                            </div>
                        </div>
                        <div className={'space-y-1'}>
                            <p className={'text-[#3D3F4B] text-[15px]'}>Meeting Attendance</p>
                            <p className={'text-xs text-[#A3A6AB]'}>.PDF file</p>
                        </div>
                    </div>

                    <div className={'text-[13px] text-[#838EAB] font-medium'}>
                        20 MB
                    </div>
                </div>

                <div className={'items-between px-1'}>
                    <ActionWrapper>
                        <PrinterIcon className={'h-6 w-6'}/>
                    </ActionWrapper>

                    <ActionWrapper>
                        <DownloadIcon className={'h-6 w-6'}/>
                    </ActionWrapper>

                    <ActionWrapper>
                        <EmailIcon className={'w-6'}/>
                    </ActionWrapper>

                    <ActionWrapper>
                        <PineIcon color={"#628799"} className={'h-5 w-5'}/>
                    </ActionWrapper>
                </div>
            </div>
        </div>
    )
}

export default PinnedFileCard;