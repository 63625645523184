import { useState } from "react";
import { useQuery } from "react-query";

import { getEventsByStakeholders } from "provider/api";
import { queryKeys } from "../../../../../../constants";
import useGlobalContext from "hooks/useGlobalContext";
import ActivitiesStatusByChartBar from "./chart";

const StakeholderActivityStatus = () => {
    const [eventsByStakeholders, setEventsByStakeholders] = useState([]);
    const { projectId } = useGlobalContext();
    const { isLoading, isFetched } = useQuery(
        queryKeys.EVENTS_BY_STAKEHOLDER,
        () => getEventsByStakeholders(projectId),
        {
            onSuccess: ({ data }) => {
                setEventsByStakeholders(data);
            },
            enabled: !!projectId,
        }
    );

    const stakeholderNames = eventsByStakeholders.map((event, index) => {
        if (event.stakeholder?.name) return event.stakeholder.name;
        return "Unknown";
    });
    // console.log(stakeholdersNames);

    const statusCounts = {
        upcoming: eventsByStakeholders.map(
            (event, index) => event.events?.upcoming
        ),
        completedOnTime: eventsByStakeholders.map(
            (event, index) => event.events?.completed
        ),
        completedLate: eventsByStakeholders.map(
            (event, index) => event.events?.lateCompleted
        ),
        overdue: eventsByStakeholders.map(
            (event, index) => event.events?.overdue
        ),
    };

    return (
        <div
            className={
                "col-span-2 bg-white p-2 border-[1px] space-y-5 border-[#E5E5E5]"
            }
        >
            <p
                className={
                    "text-[#31394C] px-8 py-3 border-b-[1px] border-b-[#E3E3E3] text-sm tracking-wide"
                }
            >
                Stakeholder Activities by Status
            </p>
            <div className={" h-72 "}>
                <ActivitiesStatusByChartBar
                    isFetched={isFetched}
                    isLoading={isLoading}
                    stakeholderNames={stakeholderNames}
                    statusCounts={statusCounts}
                />
            </div>
        </div>
    );
};
export default StakeholderActivityStatus;
