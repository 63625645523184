import {
    createContext,
    Fragment,
    useContext,
    useEffect,
    useState,
} from "react";
import Month from "./Month";
import { getMonth } from "../../utils";
import CalenderHeader from "./CalenderHeader";
import dayjs from "dayjs";
import SidebarRight from "../sidebar-right";
import MeetingDetails from "../../pages/general/engagement/activity/events/_partials/meeting-details";

export const CalenderContext = createContext(null);
const Calender = ({
    events,
    controllerPosition,
    showCreateMeetingButton,
    daysClassName,
    daysHeaderBgColor,
    isLoading = false,
    calendarSize = "large",
}) => {
    const [monthIndex, setMonthIndex] = useState(dayjs().month());
    const [currentMonth, setCurrentMonth] = useState(getMonth());
    const [showDetails, setShowDetails] = useState(false);
    const [activeEvent, setActiveEvent] = useState();

    useEffect(() => {
        setCurrentMonth(getMonth(monthIndex));
    }, [monthIndex]);

    return (
        <Fragment>
            <SidebarRight
                isOpen={showDetails}
                onClose={() => setShowDetails(!showDetails)}
            >
                <MeetingDetails
                    event={activeEvent}
                    closeSidebar={() => setShowDetails(false)}
                />
            </SidebarRight>
            <section className={"h-full space-y-4"}>
                {/* Calender Header */}
                <CalenderContext.Provider
                    value={{
                        monthIndex,
                        setMonthIndex,
                        showCreateMeetingButton,
                        controllerPosition,
                        daysHeaderBgColor,
                        events,
                        activeEvent,
                        setActiveEvent,
                        currentMonth,
                        setShowDetails,
                        daysClassName,
                        calendarSize,
                    }}
                >
                    <CalenderHeader
                        monthIndex={monthIndex}
                        setMonthIndex={setMonthIndex}
                        showCreateMeetingButton={showCreateMeetingButton}
                        controllerPosition={controllerPosition}
                    />
                    <div className={"calender-wrapper"}>
                        <Month
                            isLoading={isLoading}
                            daysHeaderBgColor={daysHeaderBgColor}
                            listOfEvents={events}
                            month={currentMonth}
                            className={daysClassName}
                        />
                    </div>
                </CalenderContext.Provider>
            </section>
        </Fragment>
    );
};
export const useCalender = () => useContext(CalenderContext);
export default Calender;
