import { GrievanceContext } from "../../../context";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { getGrievances } from "../../api";
import { useState, useRef, useEffect } from "react";
import { updateGrievance, deleteGrievance, createGrievances } from "../../api";
import useGlobalContext from "hooks/useGlobalContext";

const GrievancesProvider = ({ children }) => {
	const { projectId } = useGlobalContext();
	const [allGrievances, setAllGrievances] = useState({
		received: [],
		investigation: [],
		inProgress: [],
		resolved: [],
		closed: [],
		reopened: [],
	});

	const queryClient = useQueryClient();
	const { isLoading: isFetchingGrievances, refetch: refetchGrievances } =
		useQuery("project-grievances", () => getGrievances(projectId), {
			onSuccess: ({ data }) => {
				setAllGrievances({
					received: data?.filter(
						(grievance) => grievance.status === 0
					),
					investigation: data?.filter(
						(grievance) => grievance.status === 1
					),
					inProgress: data?.filter(
						(grievance) => grievance.status === 2
					),
					resolved: data?.filter(
						(grievance) => grievance.status === 3
					),
					closed: data?.filter((grievance) => grievance.status === 4),
					reopened: data?.filter(
						(grievance) => grievance.status === 5
					),
				});
			},
		});

	// refetch project-grievance whenever project-id is changed
	useEffect(() => {
		refetchGrievances();
	}, [projectId]);

	const investigatorFormRef = useRef(null);

	const {
		mutate: saveGrievanceStatus,
		isLoading: savingGrievance,
		isSuccess: successChangingStatus,
		isError: errorChangingStatus,
	} = useMutation(updateGrievance, {
		onSuccess: ({ data: grievance }) => {
			investigatorFormRef.current.closeForm();
			console.log("successfully...", grievance);
			updateGrievancesCache(grievance);
		},
		onError: (err) => {
			console.log(err);
		},
	});

	const {
		mutate: removeGrievance,
		isLoading: isDeletingGrievance,
		isSuccess: successDeletingGrievance,
		isError: errorDeletingGrievance,
	} = useMutation(deleteGrievance, {
		onSuccess: () => {
			console.log("Grievance deleted successfully");
		},
	});

	const updateGrievancesCache = (updatedGrievance) => {
		queryClient.setQueryData("project-grievances", (oldQueryData) => {
			return {
				...oldQueryData,
				data: oldQueryData.data.map((_grievance) => {
					if (_grievance._id === updatedGrievance._id)
						return updatedGrievance;
					return _grievance;
				}),
			};
		});
	};

	const { mutate: uploadGrievance, isLoading: isUploadingGrievance } =
		useMutation(createGrievances, {
			onSuccess: () => {
				alert("Saved grievance successfully");
			},
			onError: () => {
				alert("Saving grievance failed, please try again");
			},
		});

	return (
		<GrievanceContext.Provider
			value={{
				allGrievances,
				isFetchingGrievances,
				saveGrievanceStatus,
				savingGrievance,
				removeGrievance,
				updateGrievancesCache,
				investigatorFormRef,
				isDeletingGrievance,
				errorDeletingGrievance,
				successDeletingGrievance,
				successChangingStatus,
				errorChangingStatus,
				uploadGrievance,
				isUploadingGrievance,
			}}
		>
			{children}
		</GrievanceContext.Provider>
	);
};

export default GrievancesProvider;
