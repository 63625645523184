import PropTypes from "prop-types";
import useUserRole from "../../../hooks/useUserRole";

const RoleItem = ({role, index}, ...props) => {
    const {setShowRoleDetails, setActiveRole, setShowRoleForm, setShowPermissionForm} = useUserRole();
    const openRoleDetails = () => {
        setActiveRole(role)
        setShowRoleDetails(true)
    }

    const handleUpdatePermission = () => {
        setActiveRole(role)
        setShowPermissionForm(true)
    }

    const handleUpdateRole = () => {
        setActiveRole(role)
        setShowRoleForm(true)
    }
    const buttonClassName = 'text-sm border-[1.2px] whitespace-nowrap rounded-[3px] tracking-wide font-light hover:text-white hover:bg-accent transition-all text-accent border-accent px-3 py-1';
    // {role?.permissions?.length}
    return (
        <div {...props}
             className={`grid grid-cols-5 text-gray-500 gap-4 bg-white text-sm  border-t-[1.5px] border-t-gray-light px-5`}>
            <div className="py-3 flex items-center text-dark2 font-medium">{role?.name}</div>
            <div className="col-span-2  flex py-2 items-center">
                <p className={'line-clamp-1'}>{role?.description}</p>
            </div>
            <div className="center space-y-2 flex-col py-3">
                <p>
                    <span
                        className={"bg-blue-500/20 text-blue-700 tracking-wider text-xs whitespace-nowrap py-1 w-16 center rounded-full"}>
                    Total {role?.permissions?.length}
                </span>
                </p>

                <button onClick={handleUpdatePermission} className={'text-xs text-accent hover:underline'}>Manage
                </button>
            </div>
            <div className="center py-3 flex items-center space-x-3">
                <div className={'flex-right col-span-2 py-2  flex space-x-2'}>
                    <button onClick={handleUpdateRole} className={buttonClassName}>Edit Details</button>
                    <button onClick={openRoleDetails}
                            className={`${buttonClassName} hover:bg-primary border-secondary text-secondary`}>View Role
                    </button>
                </div>
            </div>
        </div>
    )
}

RoleItem.propType = {
    role: PropTypes.object.isRequired
}
export default RoleItem;