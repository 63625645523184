import {CalenderIcon, CalenderWithSearchIcon, CalenderWithTickIcon, CalenderWithXMarkIcon,} from "../../../../svg";
import ActivityRatePieChart from "./activity-rate-pie-chart";
import {useEngagementAnalytics} from "../../../../hooks";
import Loading from "./loading";

const EngagementActivityRate = () => {
    const {isLoading, engagementAnalytics} = useEngagementAnalytics();
    const {general} = engagementAnalytics;
    if (isLoading) return <Loading/>;

    const getPercentage = (type) => {
        switch (type) {
            case "onTime": {
                return Math.floor(
                    ((general?.completed ?? 0) * 100) / general?.total || 0
                );
            }
            case "late": {
                return Math.floor(
                    ((general?.lateCompleted ?? 0) * 100) / general?.total || 0
                );
            }
            case "overdue": {
                return Math.floor(
                    ((general?.overdue ?? 0) * 100) / general?.total || 0
                );
            }
            case "upcoming": {
                return Math.floor(
                    ((general?.upcoming ?? 0) * 100) / general?.total || 0
                );
            }
            default:
                return 0;
        }
    };

    const chartLabel = [
        {
            name: "On Time",
            percentage: getPercentage("onTime"),
            color: "#70AB91",
        },
        {
            name: "Late",
            percentage: getPercentage("late"),
            color: "#F2BC9E",
        },
        {
            name: "Overdue",
            percentage: getPercentage("overdue"),
            color: "#1F78B4",
        },
        {
            name: "Upcoming",
            percentage: getPercentage("upcoming"),
            color: "#B1CBDC",
        },
    ];

    const statistics = [
        {
            name: "Done On-time",
            total: general?.completed ?? 0,
            icon: <CalenderWithTickIcon/>,
        },
        {
            name: "Done Late",
            total: general?.lateCompleted ?? 0,
            icon: <CalenderWithXMarkIcon/>,
        },
        {
            name: "Overdue",
            total: general?.overdue ?? 0,
            icon: <CalenderWithSearchIcon/>,
        },
        {
            name: "Upcoming",
            total: general?.upcoming ?? 0,
            icon: <CalenderIcon/>,
        },
    ];
    return (
        <div
            style={{boxShadow: "0px 3px 6px #0000000F"}}
            className={" bg-white rounded-[3px] p-12 space-y-6 "}
        >
            <p className={"text-[15.5px] text-[#8F9198]"}>
                ACTIVITY SUCCESS RATE
            </p>
            <div className={"flex justify-between"}>
                <div className={"w-[60%]  space-y-7 pt-5"}>
                    {statistics.map((statistics) => (
                        <StatisticNumber
                            statistics={statistics}
                            key={statistics.name}
                        />
                    ))}
                </div>
                <div className={"h-full w-full m-auto center"}>
                    <div className={"h-80 w-full"}>
                        <ActivityRatePieChart statistics={chartLabel}/>
                    </div>
                </div>
                <div className={"w-1/2 flex justify-center pt-5"}>
                    <div className={"space-y-8"}>
                        {chartLabel.map((label) => (
                            <ChartLabel label={label} key={label.name}/>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};
export const StatisticNumber = ({statistics}, ...props) => {
    return (
        <div {...props} className={"flex space-x-4"}>
            {statistics.icon}
            <div className={"space-y-2"}>
                <p
                    className={
                        "text-[#606C7C]  w-12 text-lg font-semibold pb-1 border-b-[1.87px] border-b-[#CED1D6]"
                    }
                >
                    {statistics.total}
                </p>
                <p className={"text-[#707070] font-light text-[13px]"}>
                    {statistics.name}
                </p>
            </div>
        </div>
    );
};

export const ChartLabel = ({label}, ...props) => {
    return (
        <div {...props} className={"flex space-x-4 pl-5"}>
            <div
                style={{backgroundColor: label.color}}
                className={"h-2.5 mt-2 w-2.5 rounded-full"}
            ></div>
            <div className={"space-y-1"}>
                <p
                    className={
                        "text-[#90B5CE]  w-1/2 text-lg font-semibold pb-1 "
                    }
                >
                    {label.percentage}%
                </p>
                <p
                    className={
                        "text-[#868686] tracking-wide font-light text-[13px]"
                    }
                >
                    {label.name}
                </p>
            </div>
        </div>
    );
};
export default EngagementActivityRate;
