import { UserGroup } from "components/user-group";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import Plan from "./plan";
import { useEngagementPlans } from "../plans";

const PlanListItem = ({ plan = {}, ...props }) => {
    const { _id: stakeholder, plans: stakeholderPlans = [] } = plan;

    const { setShowForm, setActivePlan } = useEngagementPlans();

    const handleClick = () => {
        setActivePlan(plan);
        setShowForm(true);
    };
    return (
        <div
            className={`
                  group
                  border-[#EEEEEE]
                  odd:bg-white even:bg-[#F9FAFC]
                  border-[1px] overflow-hidden
                  gap-x-4 hover:border-l-4
                  hover:border-l-green-500
                  hover:border-t-[#51BF82]/60
                  hover:shadow-xl
                  hover:shadow-green-500/5`}
            {...props}
        >
            <div className={"grid-cols-5  gap-x-8  grid py-6 px-8"}>
                {/*Stakeholder Name*/}
                <div className={"flex flex-col justify-between "}>
                    <div className={"space-y-2"}>
                        <div className={"flex items-center space-x-2"}>
                            <UserGroup className={"h-5 w-5"} />
                            <p className={"text-sm text-[#AAB6C5]"}>
                                Individual
                            </p>
                        </div>
                        <p className={"font-medium text-sm text-accent"}>
                            {stakeholder[0]?.name}
                        </p>
                    </div>

                    {/* add plan button */}
                    <div className={"pt-10  group-hover:inline"}>
                        <button
                            onClick={handleClick}
                            className={`italic text-xs  bg-[#F8FCFF]
                                          px-4 py-2 flex space-x-2 text-accent font-light
                               
                                  border border-[#DCDFF1] items-center rounded-[2px]`}
                        >
                            <span>Add Plan</span>
                            <FontAwesomeIcon
                                icon={faPlus}
                                className={"h-3 w-3"}
                            />
                        </button>
                    </div>
                </div>

                <div className={"col-span-4 space-y-1 "}>
                    {stakeholderPlans.map((plan, index) => (
                        <Plan plan={plan} key={index} />
                    ))}
                </div>
            </div>
        </div>
    );
};
export default PlanListItem;
