import { useState, Fragment } from "react";
import { useMutation, useQueryClient } from "react-query";
import { updateKeyResult } from "../../../../provider/api";
import dayjs from "dayjs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDay } from "@fortawesome/free-solid-svg-icons";
import { useCommitmentDetailsContext } from "./commitment-details";
import SpinLoader from "../../../../components/SpinLoader";

const KeyResultItem = (
	{ keyResult, commitment, isCompleted: isCommitmentCompleted },
	...props
) => {
	const [isChecked, setIsChecked] = useState(keyResult.status);
	const [isCompleted, setIsCompleted] = useState(keyResult.status);
	const {
		completedKeyResults,
		setCompletedKeyResults,
		cacheUpdater,
		setKeyResults,
	} = useCommitmentDetailsContext();

	const nowTime = new Date().getTime();

	// console.log("📝 Old data",  queryClient.getQueryData("commitmentsByStakeholders"));
	const { mutate: _updateKeyResult, isLoading } = useMutation(
		["update-key-result", nowTime],
		updateKeyResult,
		{
			onSuccess: ({ data: updatedCommitment }) => {
				console.log("📝 response", updatedCommitment.results);
				console.log("📝 Status", updatedCommitment.status);
				setKeyResults(updatedCommitment.results);
				updateCompletedList(updatedCommitment);
				cacheUpdater(updatedCommitment);
			},
			onError: (err) => {
				removeFromCompletedList();
			},
		}
	);

	const updateCompletedList = (updatedCommitment) => {
		const targetKeyResult = updatedCommitment.results.find((keyResult_) => {
			return keyResult_._id === keyResult._id;
		});
		console.log("🥳 status", targetKeyResult.status);
		targetKeyResult.status
			? addToCompletedList()
			: removeFromCompletedList();
	};

	const formatDate = (date) => {
		return dayjs(date).format("MMMM DD YYYY");
	};

	const handleChange = (event) => {
		const payload = {
			resultId: keyResult._id,
			commitmentId: commitment._id,
			status: isChecked,
		};
		setIsChecked((prevState) => !prevState);

		if (event.target.checked) {
			console.log("✅ Checkbox is checked");
			payload.status = true;
		} else {
			console.log("⛔️ Checkbox is NOT checked");
			payload.status = false;
		}

		console.log("payload:: ", payload);
		_updateKeyResult(payload);
	};

	const addToCompletedList = () => {
		setCompletedKeyResults([...completedKeyResults, keyResult]);
		setIsCompleted(true);
	};

	const removeFromCompletedList = () => {
		setIsCompleted(false);
		setCompletedKeyResults([
			...completedKeyResults.filter((keyResult_) => {
				return keyResult_._id !== keyResult._id;
			}),
		]);
	};

	// const getKeyResultTitle = () => {
	//     const title = keyResult?.title.trim();
	//     const firstChar = title[0].toUpperCase();
	//     return firstChar + title.slice(1, title.length - 1).toLowerCase();
	// }

	return (
		<>
			<div
				{...props}
				className={` ${
					isCompleted
						? "bg-green-400/20 text-black"
						: "bg-white text-[#B0B4BA]"
				} px-2 flex flex-row space-x-8 justify-between`}
			>
				<div className="flex flex-row py-3 space-x-2 place-items-center">
					<div
						className={` ${
							isCompleted ? "bg-green-600" : "bg-[#B0B4BA]"
						} h-[6px] w-[6px] rounded-full`}
					></div>
					<p className="">{keyResult?.title}</p>
				</div>

				<div className="flex flex-row py-3 space-x-6 place-items-center">
					<div className="flex flex-row space-x-2 place-items-center">
						<FontAwesomeIcon
							icon={faCalendarDay}
							color={"#D6D6D6"}
						/>
						<p className={`text-green-600 text-xs `}>
							{" "}
							{formatDate(keyResult.dateToComplete)}{" "}
						</p>
					</div>

					{!isCommitmentCompleted && (
						<Fragment>
							{isLoading ? (
								<SpinLoader size={"small"} />
							) : (
								<input
									checked={isChecked}
									name="isComplete"
									type="checkbox"
									value=""
									className={`w-4 h-4 text-blue-600 cursor-pointer bg-gray-100 rounded border-gray-300 focus:ring-0 focus:ring-offset-0`}
									onChange={handleChange}
								/>
							)}
						</Fragment>
					)}
				</div>
			</div>
		</>
	);
};
export default KeyResultItem;
