import { forwardRef, useImperativeHandle, useState } from "react";
import Modal from "../../../../components/Modal";
import BasicDetails from "./basic-details";
import MilestoneForm from "./milestone";
import { localStorageKeys } from "../../../../constants";
import { removeValuesFromLocalStorage } from "../../../../hooks/useLocalStorageState";
import { useNavigate } from "react-router-dom";

const CommitmentForm = forwardRef(
    (
        {
            onSubmit: handleSubmit,
            isCreating = false,
            setActiveCommitment,
            activeCommitment,
            initialKeyResult = [],
            stakeholders: _stakeholderList = [],
        },
        ref
    ) => {
        const [showForm, setShowForm] = useState(true);
        const [currStep, setCurrStep] = useState(1);
        const { commitmentForm: commitmentLSKeys } = localStorageKeys;
        const navigate = useNavigate();

        const handleClose = async () => {
            setShowForm(false);
            await removeValuesFromLocalStorage(
                commitmentLSKeys.BASIC_DETAILS,
                commitmentLSKeys.KEY_RESULT
            );

            navigate(-1);
        };

        useImperativeHandle(ref, () => {
            return {
                closeForm() {
                    handleClose();
                },
            };
        });

        return (
            <Modal isOpen={showForm} onClose={handleClose}>
                {currStep === 1 ? (
                    <BasicDetails
                        setCurrStep={setCurrStep}
                        onClose={handleClose}
                    />
                ) : (
                    <MilestoneForm
                        isCreating={isCreating}
                        setCurrStep={setCurrStep}
                        onSubmit={handleSubmit}
                    />
                )}
            </Modal>
        );
    }
);

export default CommitmentForm;
