import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { FolderHeader } from "../_partials/folders";
import { useProjectFiles } from "../../../hooks";
import FileCard from "../overview/recent-files/file-card";
import { Fragment } from "react";
import GridViewMode from "../folder/details/grid-view-mode";
import { isEmptyObject } from "utils";
const CategoryFiles = () => {
	const [categoryFiles, setCategoryFiles] = useState([]);
	const [filteredCategoryFiles, setFilteredCategoryFiles] = useState([]);
	const { category } = useParams();
	const {
		isFetchingCategories,
		fileCategories = {},
		showListView,
	} = useProjectFiles();

	useEffect(() => {
		if (!isEmptyObject(fileCategories)) {
			const { files: fileList } = fileCategories;
			const files = fileList[category] ?? [];
			setCategoryFiles(files);
			setFilteredCategoryFiles(files);
		}
	}, [fileCategories]);

	if (isFetchingCategories) return "loading";
	const { files: fileList } = fileCategories;

	const files = fileList[category];

	const handleSearch = ({ target }) => {
		const { value: searchKeyword } = target;

		const _files = categoryFiles.filter((file) => {
			return file?.title
				?.toLowerCase()
				.includes(searchKeyword.toLowerCase());
		});
		setFilteredCategoryFiles(_files);
	};

	const handleOpenFile = () => {
		//..
	};
	return (
		<section className="">
			<FolderHeader onSearch={handleSearch} currPath={category} />

			<div className={"p-10"}>
				{filteredCategoryFiles.length ? (
					<Fragment>
						{showListView ? (
							<div className="grid grid-cols-1 gap-5 2xl:grid-cols-2">
								{filteredCategoryFiles.map((file) => (
									<FileCard
										key={file._id}
										file={file}
										onOpenFile={handleOpenFile}
									/>
								))}
							</div>
						) : (
							<GridViewMode files={filteredCategoryFiles} />
						)}
					</Fragment>
				) : (
					<div className={"h-[25vh] text-black/60 center"}>
						category is currently empty
					</div>
				)}
			</div>
		</section>
	);
};
export default CategoryFiles;
