const Loading = () => {
	return (
		<div className="bg-white rounded-[6px]  p-4 space-y-6">
			<div className="space-y-1">
				<div className="w-56 h-3.5 skeleton-loading rounded-full"></div>
				<div className="w-28 h-3.5 skeleton-loading rounded-full"></div>
			</div>
			<div className="flex items-end justify-between h-72">
				<div className="h-[10%] skeleton-loading w-10 rounded-t-lg"></div>
				<div className="h-[50%] skeleton-loading w-10 rounded-t-lg"></div>
				<div className="h-[30%] skeleton-loading w-10 rounded-t-lg"></div>
				<div className="h-[90%] skeleton-loading w-10 rounded-t-lg"></div>
				<div className="h-[70%] skeleton-loading w-10 rounded-t-lg"></div>
				<div className="h-[10%] skeleton-loading w-10 rounded-t-lg transition-all "></div>
				<div className="h-[10%] skeleton-loading w-10 rounded-t-lg transition-all "></div>
				<div className="h-[10%] skeleton-loading w-10 rounded-t-lg transition-all "></div>
			</div>
		</div>
	);
};
export default Loading;
