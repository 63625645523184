import {useState, useEffect} from 'react';
import {
    getValueFromLocalStorage,
    saveValueToLocalStorage,
} from "../../../../../../hooks/useLocalStorageState";


import {Link} from "react-router-dom";


const useRadioButtons = (name) => {
    const [value, setState] = useState(null);

    const handleChange = (e) => {
        setState(e.target.value);
    };

    const inputProps = {
        name,
        type: "radio",
        onChange: handleChange,
    };

    return [value, inputProps];
};

const StakeholderItem = ({stakeholder, onMark, rowIndex}) => {
    const [attendanceValue, attendanceInputProps] = useRadioButtons(stakeholder.name);

    const checkedValue = stakeholder?.attendance;

    const color =
        checkedValue === "present"
            ? "#51BF82"
            : checkedValue === "late"
                ? "#FEC041"
                : checkedValue === "absent"
                    ? "#FC635D"
                    : "#628799";

    useEffect(() => {
        onMark({name: stakeholder.name, attendanceValue});
    }, [attendanceValue]) //  eslint-disable-line react-hooks/exhaustive-deps


    return (
        <div className="">
            <div className={`grid grid-cols-6 text-sm ${rowIndex % 2 === 0 ? 'bg-[#a3afcb]/10' : ''}`}>
                <div className={' col-span-3 py-1'}>
                    <div className="flex space-x-2 items-center pl-1 py-1">
                        <div
                            className="w-2 h-2 rounded-full"
                            style={{backgroundColor: color}}
                        ></div>
                        <Link to={`/${stakeholder.name.toLowerCase()}`}
                              target="_blank"
                              className="font-light group-hover:scale-x-110 text-[#628799]">
                            {stakeholder.name}
                        </Link>
                    </div>
                </div>
                <div className={'center'}>
                    <input
                        className="text-[#51BF82] focus:ring-0 cursor-pointer"
                        value="present"
                        checked={checkedValue === "present"}
                        {...attendanceInputProps}
                    />
                </div>
                <div className={'center'}>
                    <input
                        className="text-[#FEC041] focus:ring-0 cursor-pointer"
                        value="late"
                        checked={checkedValue === "late"}
                        {...attendanceInputProps}
                    />
                </div>
                <div className={'center'}>
                    <input
                        className=" text-[#FC635D] focus:ring-0 cursor-pointer"
                        value="absent"
                        checked={checkedValue === "absent"}
                        {...attendanceInputProps}
                    />
                </div>
            </div>
        </div>
    );
};

const AttendanceStats = () => {
    const activeEvent = getValueFromLocalStorage("activeProjectEvent", {});


    const [attendanceList, setAttendanceList] = useState(
        activeEvent.participants.map((participant) => {
            return {
                ...participant?.stakeholder,
                attendance: participant?.attendance,
            };
        })
    );

    useEffect(() => {
        updateAttendance()
    }, [attendanceList]) //  eslint-disable-line react-hooks/exhaustive-deps

    const handleMark = ({name, attendanceValue}) => {
        setAttendanceList(
            attendanceList.map((stakeholder) => {
                if (stakeholder.name === name) {
                    return {...stakeholder, attendance: attendanceValue};
                }
                return stakeholder;
            })
        );
    };

    // const {mutate: updateMeeting, isLoading: isUpdating} = useMutation(
    //     updateProjectEvent,
    //     // { onSuccess: () => {} }
    // );

    const updateAttendance = () => {
        const newParticipantsList = activeEvent.participants.map((participant) => {
            const marked = attendanceList.find(
                (person) => person._id === participant.stakeholder?._id
            )?.attendance;

            return {...participant, attendance: marked};
        });

        const payload = {...activeEvent, participants: newParticipantsList};

        // updateMeeting(payload);
        saveValueToLocalStorage("activeProjectEvent", payload);
    };

    const countParticipantsByAttendance = (attendance) => {
        return attendanceList.filter(
            (stakeholder) => stakeholder?.attendance === attendance
        ).length;
    };

    const status = [
        {name: 'present', className: 'text-[#51BF82]'},
        {name: 'late', className: 'text-[#FEC041]'},
        {name: 'absent', className: 'text-[#FC635D]'},
    ]

    return (
        <div className={'h-full'}>
            <div style={{height: "calc(100% - 3.5rem)"}} className=" px-5 overflow-y-auto relative">
                <div className={'sticky top-0 pt-3 bg-[#EDF1F7]'}>
                    <p className="text-[#628799] text-sm  border-l-[5px] sticky top-0 border-l-[#838EAB] rounded py-2 pl-3 bg-gradient-to-r from-[#a3afcb] via-[#EDF1F7] to-[#EDF1F7]">
                        Registered Stakeholders Attendance
                    </p>

                    <div className={'grid mt-1 grid-cols-6 text-sm'}>
                        <div className={' col-span-3 py-2'}>Stakeholder</div>
                        <div className={'center'}>Present</div>
                        <div className={'center'}>Late</div>
                        <div className={'center'}>Absent</div>
                    </div>
                </div>

                <div className=" ">
                    {attendanceList.map((stakeholder, rowIndex) => {
                        return (
                            <StakeholderItem
                                rowIndex={rowIndex}
                                stakeholder={stakeholder}
                                onMark={handleMark}
                                key={stakeholder?._id}
                            />
                        );
                    })}
                </div>

                {/*{isUpdating && <i>Saving...</i>}*/}
                {/*{!isUpdating && <button onClick={() => onSaveAttendance()}>Save</button>}*/}
            </div>

            <div className=" bg-[#EDF1F7] h-14 grid grid-cols-3 divide-x-2 divide-[#253A53]/50">
                {status.map((status_) => (
                    <div className="grid place-items-center">
                        <div className={`font-bold text-2xl ${status_.className}`}>
                            {countParticipantsByAttendance(status_.name)}
                        </div>
                        <p className="font-medium text-[#253A53] capitalize text-sm">{status_.name}</p>
                    </div>
                ))}
            </div>
        </div>

    );
};
export default AttendanceStats;