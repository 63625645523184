import { useState } from "react";
import { useQuery } from "react-query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsis } from "@fortawesome/free-solid-svg-icons";

import PieChart from "../../../../../../chats/PieChart";
import { getEngagementByStatus } from "provider/api";
import useGlobalContext from "hooks/useGlobalContext";
import { queryKeys } from "../../../../../../constants";
import PieChartLabel from "./label/piechart-label";
import EmptyState from "./empty-state";
import { isEmptyObject } from "../../../../../../utils";
import PieChartLoading from "./loading";

const ActivityStatusByPieChart = () => {
    const [activitiesStatus, setActivitiesStatus] = useState({});
    const { projectId } = useGlobalContext();

    const { isLoading, isFetched } = useQuery(
        queryKeys.ENGAGEMENT_BY_STATUS,
        () => getEngagementByStatus(projectId),
        {
            onSuccess: ({ data }) => {
                setActivitiesStatus(data);
            },
            enabled: !!projectId,
        }
    );

    const percentages = Object.fromEntries(
        Object.entries(activitiesStatus).map(([key, value]) => [
            key,
            Math.round((value / activitiesStatus.total) * 100),
        ])
    );

    const labelData = [
        [
            {
                text: `${percentages?.completedOnTime || 0}% COMPLETED ON TIME`,
                color: "#A5DED4",
            },
            {
                text: `${percentages?.lateComplete || 0}% COMPLETED LATE`,
                color: "#FDD1A9",
            },
        ],
        [
            {
                text: `${percentages?.upcoming || 0}% UPCOMING`,
                color: "#168ADC",
            },
            {
                text: `${percentages?.overdue || 0}% OVERDUE`,
                color: "#FDC2C0",
            },
        ],
    ];

    return (
        <div className={"  border-[1px] bg-white border-[#E5E5E5] p-2"}>
            <div
                className={
                    "flex justify-between  items-center px-8 py-3 border-b-[1px] border-b-[#E3E3E3]"
                }
            >
                <p className={"text-[#31394C] text-sm tracking-wide"}>
                    Engagement Activities by Status
                </p>
                <FontAwesomeIcon
                    icon={faEllipsis}
                    className={"hidden text-[#31394C] opacity-30 h-5 w-5"}
                />
            </div>
            {isEmptyObject(activitiesStatus) && isFetched ? (
                <EmptyState />
            ) : null}
            <div className={"space-y-5"}>
                {isLoading ? <PieChartLoading /> : null}
                {isFetched && !isEmptyObject(activitiesStatus) ? (
                    <div className={" h-56 "}>
                        <PieChart
                            isLoading={isLoading}
                            activitiesStatus={activitiesStatus}
                            innerSize={"10%"}
                            showDataLabels={false}
                        />
                    </div>
                ) : null}

                <div
                    className={
                        "flex pb-2 pt-5 items-center justify-between px-5 "
                    }
                >
                    {labelData.map((labels, index) => (
                        <div key={index} className={" text-[10px] space-y-3"}>
                            {labels.map((labelProps) => (
                                <PieChartLabel
                                    isLoading={isLoading}
                                    key={labelProps.color}
                                    {...labelProps}
                                />
                            ))}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};
export default ActivityStatusByPieChart;
