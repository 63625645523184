const TangaSvg = ({ color, isLoading = false }) => {
    return (
        <path
            id="Path_438"
            data-name="Path 438"
            className={`${isLoading ? "animate-pulse" : ""}`}
            d="M554.7,186.474l.259.187,3.342,2.407,3.333,2.407,3.333,2.411,3.342,2.411,3.337,2.407,3.337,2.411,3.337,2.411,3.337,2.408,3.317,2.4.267.245.691.191.252,1.1-.213,1.271-.728.673-1.065.536.862.348.589.051.34.217.1.869-.048.833-.146.68-.255.626-1.247,2.124-.5.479-.226-.575.133-.776.252-.76.127-.744-.245-.69-.691,2.021-.6.719-.85-.06.677,1.054.105.39.136,2.229-.118.393-.267.169-.608-.191-.337.147-.147.2-.388.76-.067.536.563-.032,1.25-.514.028.591.43.942.073.365-.159.288-1.489,1.3-.255.336-.776,1.623-1.2,1.8-.127.281.306.288.426-.326.6-.767.235.482-1.693,4.924-.427.863-.544.728-.411.272-.451.22-.357.307-.118.537.169.332.309.147.271.24.054.623-.525,1.56-1.127,2.3-1.365,2.068-1.279.818.535.243-.258.812-.032,1.829-.379.729-.5.626-.71,1.458-.866,1.315-.28.847-.147.918-.013,2.533-.165.384-.255.272-.22.343-.153.368-.029.132-3.267-.542-.638.512-.78.262-.542-.106-.542.059-.7.735-.857.271-2.243.975-.514-.282-.561.142-.792.006-.7-.274-.432.286-.392.329-1.309-.274-.667-.4-.594-.593-1.245.069-1.075-.455-1.022-1.442-1.664-.433-1.459-.534-1.1-1.213-2.453-1.225-2.788-.226-1.006.488-1.135.187-2.651-.608-5.924,1.592-.63.581-.937-.285-.586.494.052,1.1-1.58.506-2.048-.106-1.058,1.071-.942-.811-2.538-2.917-.456-1.805-1.321-.55-.679-.077-.671-.354-.606.067-.5-.185-.1-.285-.214-.162-1.245-.42-.59,1.048-.711,4.511-1.261,1.13-1.414.577-1.236,1.505-1.661,1.237-.771,1.587-1.047,1.047-1.047-.691-.881-.945,0-.372-.141-.256-.15-1.028.808-1.156-1.039-.347-1.18-.608-.59-4.152,4.647-3.176,1.236-2.02.84-4.6.743-2.113,3.592-4.648.876-2.607.865-3.79.04-1.118-.323-1.029.328-.913,5.758-6.976,2.157-1.945,1.947,0,1.9.637,2.324.552,2.4-.566,1.947-.653,2.675-.568.687-.035,1.03.75,1.083.615,1.883.073L540,219.458l-.655-1.01-.452-1.1-.274-1.282-.771-1.028-.542-1.677.024-1.893.307-2.593,1.952-2.066,1.765-1.226,13.334-19.1Z"
            transform="translate(-209.76 -63.807)"
            fill={color}
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="0.5"
        />
    );
};
export default TangaSvg;
