export const CheckIcon = ({className, color = "#fff"}) => {
    return (
        <svg className={className ? className : 'h-3 w-3'} xmlns="http://www.w3.org/2000/svg"
             viewBox="0 0 14.249 14.275">
            <path id="Path_101780" data-name="Path 101780"
                  d="M64.681,5.8l2.625,4.561c2.72-4.365,5.775-8.191,8.543-10.4.62-.589,1.575-.245.907.392-3.1,3.286-5.488,7.014-7.923,12.85-.955.687-2.052,1.03-2.577.392L62.82,7.269c-.477-.785,1.193-2.6,1.861-1.471Z"
                  transform="translate(-62.735 0.342)" fill={color}/>
        </svg>
    )
}
export default CheckIcon;