import SidebarRight from "../../../components/sidebar-right";
import PropTypes from "prop-types";
import {DotsHorizontalIcon, SearchIcon} from "@heroicons/react/outline";
import ArrowRightWithBorder from "../../../components/arrow-right-with-border";
import ToggleButton from "../../../components/toggle-button";
import dayjs from "dayjs";
import {useEffect, useState} from "react";
import {isEmptyObject} from "../../../utils";

const RoleDetails = ({onClose: closeRoleDetails, isOpen, role = {}}) => {
    const [permissionList, setPermissionList] = useState([]);
    const [filteredPermissions, setFilteredPermissions] = useState([]);

    const formatDate = (date) => {
        return dayjs(date).format('dd, MMMM DD YYYY')
    }

    useEffect(() => {
        if (!isEmptyObject(role)) {
            setFilteredPermissions(role?.permissions)
            setPermissionList(role?.permissions)
        }
    }, [role])

    const handleInputChange = ({target}) => {
        const searchKeyword = target.value.toLowerCase();
        setFilteredPermissions(permissionList.filter((permission) => {
            return permission?.displayName?.toLowerCase()?.includes(searchKeyword);
        }))
    }
    return (
        <SidebarRight onClose={closeRoleDetails} isOpen={isOpen} className={'w-[430px]'}>
            <div className={'w-ful h-full pb-8  bg-white/10 overflow-y-auto'}>
                <div className={'sticky top-0 bg-white z-40'}>
                    <header className={'border-b-[1.4px] items-between p-3 border-b-[#E8ECEE]'}>
                        <p className={'font-medium text-dark'}>Role Details</p>
                        <div className={"flex items-center space-x-3"}>
                            <DotsHorizontalIcon className={"h-5 text-[#6f7782]"}/>

                            <button
                                className="p-2"
                                onClick={(closeHandler) => closeRoleDetails(false)}
                            >
                                <ArrowRightWithBorder className={"h-4 w-4"}/>
                            </button>
                        </div>
                    </header>

                    <div className={'p-5  space-y-10'}>
                        <div className={'space-y-4'}>
                            <div>
                                <h2 className={'font-medium text-lg text-dark'}>{role?.name}</h2>
                                <h3 className={'text-sm opacity-80'}>Created on {formatDate(role?.createdAt)}</h3>
                            </div>

                            <div className={'text-sm'}>
                                <p className={'font-medium text-dark '}>Description</p>
                                <p className={'font-light text-dark'}>
                                    {role?.description}
                                </p>
                            </div>
                        </div>

                        <div
                            className={'items-between border-b-[1.4px] pb-4 border-b-[#E8ECEE]'}>
                            <h3 className={'font-medium text-dark'}>Permissions</h3>
                            <div className={'flex'}>
                                <div className={'flex items-center '}>
                                    <SearchIcon className={'h-5 text-dark2/80 relative left-4'}/>
                                    <input onChange={handleInputChange} type="text" placeholder={'Search'}
                                           className={'p-0 text-sm pl-4 bg-transparent  outline-0 border-0 py-1  border-b-[2px] focus:ring-0'}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={'px-5 space-y-2'}>

                    <div>
                        {filteredPermissions.map((permission, index) => (
                            <div
                                className={`items-between pl-2 text-sm  capitalize py-2 ${index % 2 === 0 ? 'bg-[#a3afcb]/10' : ''}`}>
                                <p>{permission?.displayName}.</p>
                                <div className={'pointer-events-none'}>
                                    <ToggleButton currentState={true}/>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </SidebarRight>
    )
}

RoleDetails.propTypes = {
    onClose: PropTypes.func.isRequired,
    role: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired
}
export default RoleDetails;