import { PineIcon } from "../../../svg";
import PinnedFileCard from "./pinned-file-card";

const PinnedFiles = () => {
	return (
		<div
			className={
				"fixed p-6 pt-0 overflow-y-auto  top-[64px] space-y-2 w-[340px] bg-[#EDF1F7] border-t-[1.5px] border-l-[1.5px] border-[#E4E6EA] right-4 h-full"
			}
		>
			<div
				className={
					"flex pt-8 z-50 bg-[#EDF1F7] top-0 sticky items-center space-x-4"
				}
			>
				<p
					className={
						"text-[#151D41] tracking-wide text-lg font-semibold"
					}
				>
					Pinned Files
				</p>
				<PineIcon />
			</div>
			<div className={"space-y-2"}>
				{[...Array(6)].map((file, index) => (
					<PinnedFileCard key={index} />
				))}
			</div>
		</div>
	);
};
export default PinnedFiles;
