import { Route, Routes } from "react-router-dom";
import ProjectImagesOverview from "pages/project-images";
import Images from "pages/project-images/images";
import { permissions, RequirePermission } from "provider/permission";
import ProjectImagesProvider from "../../provider/images";

const ProjectImagesRoutes = () => {
	return (
		<RequirePermission permission={permissions.READ_PROJECT_IMAGES}>
			<Routes>
				<Route
					index
					element={
						<ProjectImagesProvider>
							<ProjectImagesOverview />
						</ProjectImagesProvider>
					}
				/>
				<Route path={"overview"} element={<ProjectImagesOverview />} />
				<Route path={":category"} element={<Images />} />
			</Routes>
		</RequirePermission>
	);
};
export default ProjectImagesRoutes;
