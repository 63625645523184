import React from "react";
import { ArrowRightIcon } from "../../../svg";
import Image from "../../../components/image";
import { Link, useNavigate } from "react-router-dom";
import { useProjectImages } from "../../../hooks";
import { flatten } from "../../../utils";

const AllImages = () => {
	const avatarClassName = "h-10 w-10";
	const { isLoading, images = {} } = useProjectImages();

	const folders = Object.keys(images);
	folders.pop(); // remove meeting-budget folder
	const arrOfImage = flatten(
		folders.map((folderName) => {
			const folderFiles = images[folderName];
			const files = folderFiles.map((file) => file?.attachments);
			return files;
		})
	);
	const defaultImage =
		"https://i.pinimg.com/564x/a7/d9/c7/a7d9c75d541c30bc5eb3d27baa3316f7.jpg";

	return (
		<div className={"h-96  center relative overflow-hidden rounded-[10px]"}>
			<Image
				imageUrl={arrOfImage?.at(-1)?.url ?? defaultImage}
				alt={"all project images"}
			/>
			<Link
				to={"all"}
				className={
					"bg-black/50 hover:bg-black/40  cursor-pointer p-10 text-white flex flex-col justify-between absolute inset-0"
				}
			>
				<div className={"items-between"}>
					<div className={"space-y-1"}>
						<p className={"text-lg"}>All Photos</p>
						<p className={"font-light text-[15px]"}>
							{arrOfImage.length} photos
						</p>
					</div>
					<div className={"flex items-center -space-x-2"}>
						<div
							className={`z-30 ring-2 ring-white/80 rounded-full ${avatarClassName} overflow-hidden`}
						>
							<Image
								imageUrl={
									arrOfImage?.at(-2)?.url ?? defaultImage
								}
								alt="overview"
							/>
						</div>
						<div
							className={`z-20 ring-2 ring-white/80 rounded-full ${avatarClassName} overflow-hidden`}
						>
							<Image
								imageUrl={
									arrOfImage?.at(-3)?.url ?? defaultImage
								}
								alt="overview"
							/>
						</div>
						<div
							className={`z-10 ring-2 ring-white/80 rounded-full ${avatarClassName} overflow-hidden`}
						>
							<Image
								imageUrl={
									arrOfImage?.at(-4)?.url ?? defaultImage
								}
								alt="overview"
							/>
						</div>
					</div>
				</div>
				<div>
					<div
						className={`${avatarClassName} bg-white rounded-full center`}
					>
						<ArrowRightIcon
							color={"#628799"}
							className={"h-8 w-10"}
						/>
					</div>
				</div>
			</Link>
		</div>
	);
};
export default AllImages;
