import { useState } from "react";

import Label from "components/form-control/Label";
import Select from "components/form-control/Select";
import Option from "components/form-control/Option";
import PlaceAutoComplete from "components/PlaceAutoComplete";
import Input from "components/form-control/Input";
import { useChannelContext } from "./channel";
import { getValueFromLocalStorage } from "hooks/useLocalStorageState";

const PhysicalChannel = () => {
    const { isSubmitted, setSelectedChannel } = useChannelContext();
    const [addressInfo, setAddressInfo] = useState(null);
    const meetingChannel = getValueFromLocalStorage("meetingChannel", {});
    const [isIndoor, setIsIndoor] = useState(
        meetingChannel?.channel?.name === "Indoor" || true
    );

    console.log("is indoor", isIndoor);

    const handleChange = ({ target }) => {
        setSelectedChannel({ name: target.value, value: null });
        return target.value === "Indoor"
            ? setIsIndoor(true)
            : setIsIndoor(false);
    };

    const handleInputChange = ({ target }) => {
        setSelectedChannel({
            name: "Indoor",
            locationName: target.value,
            channelType: "Physical",
        });
        setAddressInfo(target.value);
    };

    const handleAddress = ({ properties }) => {
        setAddressInfo(properties?.display_name);
        setSelectedChannel({
            name: "Outdoor",
            locationName: properties?.display_name,
            channelType: "Physical",
        });
    };

    return (
        <div className={"space-y-5"}>
            <div className={"pr-14 space-y-2 "}>
                <Label
                    text={"Select meeting location (Indoor or Outdoor)"}
                    htmlFor={"locationScope"}
                />
                <Select
                    name="locationScope"
                    onChange={handleChange}
                    defaultValue={meetingChannel?.channel?.name ?? "Indoor"}
                >
                    <option selected disabled value="">
                        Choose
                    </option>
                    <Option value={"Indoor"} />
                    <Option value={"Outdoor"} />
                </Select>
            </div>

            {/*  show address field is outdoor meeting*/}
            {isIndoor ? (
                <div className={"pr-14 space-y-2 "}>
                    <Label
                        text={"EventLayout Address"}
                        htmlFor={"locationName"}
                    />
                    <Input
                        isError={isSubmitted && !addressInfo && isIndoor}
                        name="meeting address"
                        defaultValue={meetingChannel?.locationName}
                        onChange={handleInputChange}
                        placeholder="eh.. Board room"
                    />
                </div>
            ) : (
                <div className={"pr-14 space-y-2 "}>
                    <Label
                        text={"EventLayout Address"}
                        htmlFor={"locationName"}
                    />
                    <div>
                        <PlaceAutoComplete
                            inputClassName={"bg-white text-[#AAB6C5]"}
                            placeholder={"Eg: Mwenge, Dar es Salaam - Tanzania"}
                            containerClassName={
                                "bg-white border-[1.4px] border-light-gray"
                            }
                            onClick={handleAddress}
                            defaultValue={meetingChannel?.locationName}
                            suggestionClassName={
                                "w-full bg-white border-[1.4px] border-light-gray shadow"
                            }
                        />

                        {isSubmitted && !addressInfo && !isIndoor ? (
                            <p className={"text-red-500 pt-1 text-sm"}>
                                Please provide valid meeting address
                            </p>
                        ) : null}
                    </div>
                </div>
            )}
        </div>
    );
};
export default PhysicalChannel;
