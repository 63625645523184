import dayjs from "dayjs";
import React from "react";
import {Link} from "react-router-dom";
import {slugify} from "../../utils";
import {ArrowRight} from "../../svg";

const WorkspaceListItem = ({workspace, ...props}) => {

    return (
        <div {...props} className="grid grid-cols-6 px-5 py-3 text-[15px] hover:bg-secondary/5">
            <div className={'col-span-3'}>{workspace.name}</div>
            <div className={'flex justify-center text-black/60'}>{workspace.projects.length}</div>
            <div className="flex justify-center text-black/60">
                {dayjs(workspace.createdAt).format('DD, MMMM YYYY')}
            </div>

            <div className="flex justify-center ">
                <Link
                    to={slugify(workspace.name)}
                    className="flex items-center justify-center py-0 space-x-2 border-b text-accent border-b-transparent hover:border-b-accent"
                >
                    <p>view</p>
                    <ArrowRight/>
                </Link>
            </div>
        </div>
    )
}

export default WorkspaceListItem;
