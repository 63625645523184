import axios from "axios";
import {useQuery} from "react-query";
import {SearchIcon} from "@heroicons/react/outline";
import {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLocationDot} from "@fortawesome/free-solid-svg-icons";

const PlaceAutoComplete = (
    {
        onClick,
        defaultValue,
        containerClassName = "",
        inputClassName = "",
        placeholder = "",
        suggestionClassName = "",
    }
) => {
    const [keyword, setKeyword] = useState(defaultValue ? defaultValue : "");
    const [showSuggestions, setShowSuggestions] = useState(true);
    const BASE_URL = process.env.REACT_APP_NOMINATIM_PLACE_API;

    const {
        data: response,
        refetch,
    } = useQuery(
        "search-location",
        () => {
            // return axios.get(BASE_URL + `${keyword}.json?country=TZ&access_token=` + ACCESS_KEY)
            return axios.get(
                BASE_URL +
                `q=${keyword}&format=geojson&countrycodes=tz&limit=5&addressdetails=1`
            );
        },
        {
            enabled: false,
        }
    );

    return (
        <div className={"relative"}>
            <div
                className={` ${
                    containerClassName
                        ? containerClassName
                        : " w-72  text-white overflow-hidden bg-primary/90"
                } p-1 flex items-center ${
                    keyword ? " rounded-t-md" : "rounded-md"
                }`}
            >
                <div className={"h-10 w-10 flex justify-center items-center  "}>
                    <SearchIcon className={"h-5 w-5"}/>
                </div>
                <input
                    value={keyword}
                    onChange={(e) => {
                        // setLocation(e.target.value)
                        setKeyword(e.target.value);
                        setShowSuggestions(true);
                        refetch();
                    }}
                    type="text"
                    placeholder={placeholder}
                    className={` ${
                        inputClassName
                            ? inputClassName
                            : "border-none focus:ring-0 text-white/90   placeholder-[#032F4F] h-10 bg-transparent"
                    } focus:ring-0 placeholder-[#AAB6C5] border-none w-full`}
                />
            </div>
            {keyword && showSuggestions && (
                <div
                    className={` ${
                        suggestionClassName
                            ? suggestionClassName
                            : "text-white/80 w-72 bg-primary/80 "
                    } z-50 absolute top-full rounded-b-md `}
                >
                    {/*{response?.data?.features.map((location, index) => (*/}
                    {/*    <p onClick={() => {*/}
                    {/*        onClick(location)*/}
                    {/*        setShowSuggestions(false)*/}
                    {/*        setKeyword(location.place_name)*/}

                    {/*    }} key={index}*/}
                    {/*       className={` ${suggestionClassName ? 'hover:bg-black/5'  : 'hover:bg-primary/50'} p-2 text-sm mx-2 flex items-center rounded-lg space-x-2 cursor-pointer    capitalize`}>*/}
                    {/*        <FontAwesomeIcon icon={faLocationDot} className={''}/>*/}
                    {/*        <span>{location?.place_name}</span>*/}
                    {/*    </p>*/}
                    {/*))}*/}

                    {response?.data?.features.map((location, index) => (
                        <p
                            onClick={() => {
                                onClick(location);
                                setShowSuggestions(false);
                                setKeyword(location.properties.display_name);
                            }}
                            key={index}
                            className={` ${
                                suggestionClassName
                                    ? "hover:bg-black/5"
                                    : "hover:bg-primary/50"
                            } p-2 text-sm mx-2 flex items-center rounded-lg space-x-2 cursor-pointer    capitalize`}
                        >
                            <FontAwesomeIcon
                                icon={faLocationDot}
                                className={""}
                            />
                            <span>{location?.properties.display_name}</span>
                        </p>
                    ))}
                </div>
            )}
        </div>
    );
};
export default PlaceAutoComplete;
