import { object, string } from "yup";
import React, { Fragment, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { changerUserPassword, login } from "provider/api";
import Button from "components/Button";
import ProjectBgImage from "./ProjectBgImage";
import { useAuth } from "provider/auth/auth";
import { yupResolver } from "@hookform/resolvers/yup";
import { LockClosedIcon, UserIcon } from "@heroicons/react/solid";
import Logo from "components/logo";
import {
    getValueFromLocalStorage,
    saveValueToLocalStorage,
} from "hooks/useLocalStorageState";
import { Navigate, useLocation, useNavigate } from "react-router-dom";

const Login = () => {
    const { saveAuthUser, authUser } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const isChangePassword = location.pathname === "/change-password";

    // Define validation rules to be applied in email, password
    const validationSchema = object({
        email: string()
            .email("Please enter invalid email")
            .required("Email is required"),
        password: string().required("Password is required"),
    });

    const changePassValidationSchema = object({
        oldPassword: string().required("old password is required"),
        newPassword: string().required("New password is required"),
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(
            isChangePassword ? changePassValidationSchema : validationSchema
        ),
    });

    // show error message after fail to login
    const [errorMessage, setErrorMessage] = useState("");

    const { mutate, isLoading } = useMutation(login, {
        onSuccess: ({ data }) => {
            saveAuthUser(data.user, data.token);

            // saveValueToLocalStorage("projectId", "629ee3670ed8032d33c762c0");
        },
        onError: ({ data, message }) => {
            const shouldChangePassword = data?.firstTimeLoginFlag === 0;

            if (shouldChangePassword) {
                saveValueToLocalStorage("authToken", data?.token);
                saveValueToLocalStorage("_authUser", data?.user);
                navigate("/change-password", { replace: true });
            } else setErrorMessage(message);
        },
    });

    const { isLoading: isUpdatingPassword, mutate: changePassword } =
        useMutation("change-user-password", changerUserPassword, {
            onSuccess: () => {
                const token = getValueFromLocalStorage("authToken", null);
                const user = getValueFromLocalStorage("_authUser", null);
                saveAuthUser(user, token);
            },
            onError: ({ message }) => {
                setErrorMessage(message);
            },
        });

    if (authUser) return <Navigate to={"/"} />;

    const onSubmit = (formData) => {
        if (isChangePassword) {
            return changeUserPassword(formData);
        }
        return mutate(formData);
    };

    const changeUserPassword = (formData) => {
        const payload = {
            oldPassword: formData.oldPassword,
            password: formData.newPassword,
        };
        changePassword(payload);
    };

    return (
        <div className={"grid grid-cols-2 h-screen "}>
            <div className={"px-40 py-14"}>
                <div className={"flex flex-col justify-between h-full w-full "}>
                    <div className="flex items-center">
                        <Logo className={"h-20"} />
                    </div>
                    <div className={""}>
                        <form
                            onSubmit={handleSubmit(onSubmit)}
                            className={"space-y-4"}
                        >
                            {isChangePassword ? (
                                <Fragment>
                                    <div className={"text-dark"}>
                                        <h2 className={"text-lg font-semibold"}>
                                            Change password
                                        </h2>
                                        <p
                                            className={
                                                "text-sm text-[#707070] tracking-wide opacity-80 "
                                            }
                                        >
                                            This is happens only the first time
                                            when to you try to access the
                                            system.
                                        </p>
                                    </div>
                                    <div>
                                        <div
                                            className={`border-[1px] ${
                                                errors.oldPassword ||
                                                errorMessage
                                                    ? "border-red-500"
                                                    : "border-[#E8E8E8]"
                                            }  p-3 space-x-2 flex items-center rounded`}
                                        >
                                            <LockClosedIcon
                                                className={
                                                    "h-4 w-4 text-gray-400"
                                                }
                                            />
                                            <input
                                                {...register("oldPassword")}
                                                type={"password"}
                                                className={
                                                    "outline-none text-[#B4B4B4] border-none focus:ring-0 p-0 w-full font-light text-sm " +
                                                    "placeholder-[#B4B4B4]"
                                                }
                                                placeholder={"Old Password"}
                                            />
                                        </div>
                                        <div
                                            className={
                                                "flex items-center justify-between"
                                            }
                                        >
                                            {errors.oldPassword?.message && (
                                                <p
                                                    className={
                                                        "text-red-500 text-xs "
                                                    }
                                                >
                                                    {
                                                        errors.oldPassword
                                                            ?.message
                                                    }
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                    <div>
                                        <div
                                            className={`border-[1px] ${
                                                errors.newPassword ||
                                                errorMessage
                                                    ? "border-red-500"
                                                    : "border-[#E8E8E8]"
                                            }  p-3 space-x-2 flex items-center rounded`}
                                        >
                                            <LockClosedIcon
                                                className={
                                                    "h-4 w-4 text-gray-400"
                                                }
                                            />
                                            <input
                                                {...register("newPassword")}
                                                type={"password"}
                                                className={
                                                    "outline-none text-[#B4B4B4] border-none focus:ring-0 p-0 w-full font-light text-sm " +
                                                    "placeholder-[#B4B4B4]"
                                                }
                                                placeholder={"New Password"}
                                            />
                                        </div>
                                        <div
                                            className={
                                                "flex items-center justify-between"
                                            }
                                        >
                                            {errors.newPassword?.message ? (
                                                <p
                                                    className={
                                                        "text-red-500 text-xs "
                                                    }
                                                >
                                                    {
                                                        errors.newPassword
                                                            ?.message
                                                    }
                                                </p>
                                            ) : null}
                                            {errorMessage ? (
                                                <p
                                                    className={
                                                        "text-red-500 text-xs "
                                                    }
                                                >
                                                    {errorMessage === "Offline!"
                                                        ? "Check your internet connection"
                                                        : errorMessage}
                                                </p>
                                            ) : null}
                                        </div>
                                    </div>
                                </Fragment>
                            ) : (
                                <Fragment>
                                    {/* Email Field  */}
                                    <div>
                                        <div
                                            className={`border-[1px] ${
                                                errors.email || errorMessage
                                                    ? "border-red-500"
                                                    : "border-[#E8E8E8]"
                                            }   
                                        p-3 space-x-2 flex items-center rounded`}
                                        >
                                            <UserIcon
                                                className={
                                                    "h-4 w-4 text-gray-400"
                                                }
                                            />
                                            <input
                                                type={"email"}
                                                {...register("email")}
                                                className={
                                                    "outline-none font-light w-full border-none focus:ring-0 p-0 text-[#B4B4B4] text-sm " +
                                                    "placeholder-[#B4B4B4]"
                                                }
                                                placeholder={
                                                    "Your login or e-mail"
                                                }
                                            />
                                        </div>
                                        {errors.email?.message && (
                                            <span
                                                className={
                                                    "text-red-500 text-xs "
                                                }
                                            >
                                                {errors.email?.message}
                                            </span>
                                        )}
                                    </div>

                                    {/* Password Field  */}
                                    <div>
                                        <div
                                            className={`border-[1px] ${
                                                errors.password || errorMessage
                                                    ? "border-red-500"
                                                    : "border-[#E8E8E8]"
                                            }  p-3 space-x-2 flex items-center rounded`}
                                        >
                                            <LockClosedIcon
                                                className={
                                                    "h-4 w-4 text-gray-400"
                                                }
                                            />
                                            <input
                                                {...register("password")}
                                                type={"password"}
                                                className={
                                                    "outline-none text-[#B4B4B4] border-none focus:ring-0 p-0 w-full font-light text-sm " +
                                                    "placeholder-[#B4B4B4]"
                                                }
                                                placeholder={"Password"}
                                            />
                                        </div>
                                        <div
                                            className={
                                                "flex items-center justify-between"
                                            }
                                        >
                                            {errors.password?.message && (
                                                <span
                                                    className={
                                                        "text-red-500 text-xs "
                                                    }
                                                >
                                                    {errors.password?.message}
                                                </span>
                                            )}
                                            {errorMessage && (
                                                <span
                                                    className={
                                                        "text-red-500 text-xs "
                                                    }
                                                >
                                                    {errorMessage === "Offline!"
                                                        ? "Check your internet connection"
                                                        : errorMessage}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </Fragment>
                            )}

                            {/* Submit Button */}
                            <div
                                className={
                                    "flex items-center justify-between pt-2"
                                }
                            >
                                <div
                                    className={
                                        "text-xs  text-[#707070] font-light"
                                    }
                                >
                                    <a
                                        className={"hover:underline hidden"}
                                        href="src/forms/Login/Login#"
                                    >
                                        Register
                                    </a>
                                    <a
                                        className={"hover:underline"}
                                        href="src/forms/Login/Login#"
                                    >
                                        Forget password
                                    </a>
                                </div>
                                <div>
                                    <Button
                                        type={"submit"}
                                        loading={
                                            isChangePassword
                                                ? isLoading
                                                : isUpdatingPassword
                                        }
                                    >
                                        {isChangePassword
                                            ? "Change Password"
                                            : "Sign In"}
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </div>

                    {/* Footer */}
                    <div
                        className={
                            "flex opacity-0 pointer-events-none justify-between font-light items-center text-xs"
                        }
                    >
                        <div>
                            <span className={"text-[#ACACAC]"}>
                                Contact technical team
                            </span>
                        </div>
                        <div className={"space-x-8 text-blue-500"}>
                            <a href="src/forms/Login/Login#">Whatsapp</a>
                            <a href="src/forms/Login/Login#">Email</a>
                            <a href="src/forms/Login/Login#">Phone</a>
                        </div>
                    </div>
                </div>
            </div>

            {/* Project Background Image   */}
            <ProjectBgImage />
        </div>
    );
};

export default Login;
