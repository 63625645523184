import {UnstableComboboxInput} from "components/combobox";
import Button from "components/Button";
import {ArrowLeftIcon} from "@heroicons/react/outline";
import React, {useState} from "react";
import {useMutation, useQuery} from "react-query";
import {createProject, getPIUS} from "provider/api";
import {getValueFromLocalStorage, removeValuesFromLocalStorage,} from "hooks/useLocalStorageState";
import {isEmptyObject, slugify} from "utils";
import EditorJS from "components/editorjs/editor";
import {useOutletContext} from "react-router-dom";
import useUpdateCache from "../../../../hooks/useUpdateCache";
import {cacheActions, queryKeys} from "../../../../constants";

const ProjectPiuSetupFrom = ({setCurrStep, setShowForm, activeWorkspace}) => {
    const [selectedPIU, setSelectedPIU] = useState("");
    const [selectedPIUObj, setSelectedPIUObj] = useState("");
    const [selectedWorkspace, setSelectedWorkspace] = useState("");
    const [piuList, setPiuList] = useState([]);
    const [projectDescription, setProjectDescription] = useState("");
    const projectBasicDetails = getValueFromLocalStorage("projectBasicDetails");
    const {workspaces, getWorkspaceByName} = useOutletContext()
    const thereActiveWorkspace = !isEmptyObject(activeWorkspace);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const updateCache = useUpdateCache();

    // send a get request to retrieve a system's PIUs
    const {isLoading} = useQuery("system-pius", getPIUS, {
        onSuccess: ({data: _piuList}) => {
            setPiuList(_piuList);
        },
    });

    // send http request to create a new project
    const {mutate: _createProject, isLoading: isCreating} = useMutation(
        "create-project",
        createProject,
        {
            onSuccess: ({data: newProject}) => {
                updateWorkspaceAndProjectCache(newProject)
                closeProjectForm()
            },
        }
    );

    const closeProjectForm = () => {
        removeValuesFromLocalStorage("projectBasicDetails");
        setShowForm(false);
    }

    const updateWorkspaceAndProjectCache = (project) => {
        if (thereActiveWorkspace || !isEmptyObject(selectedWorkspace)) {
            // If there are any selected or active workspace update its data in cache.
            const workspace = thereActiveWorkspace ? activeWorkspace : getWorkspaceByName(slugify(selectedWorkspace.name));
            const updatedWorkspace = {
                ...workspace,
                projects: [
                    ...workspace.projects,
                    project
                ]
            }
            updateCache(
                queryKeys.WORKSPACES,
                updatedWorkspace,
                cacheActions.UPDATE
            )
        } else updateCache(
            queryKeys.PROJECTS,
            project, cacheActions.ADD
        );
    }

    const handleEditorChanges = (cleanData) => {
        setProjectDescription(cleanData);
    };

    const handleSubmit = (e) => {
        setIsSubmitted(true)
        e.preventDefault()
        if (isEmptyObject(projectDescription) || isEmptyObject(selectedPIUObj))
            return;
        const payload = {
            ...projectBasicDetails,
            budget: parseInt(projectBasicDetails.budget),
            description: JSON.stringify(projectDescription),
            piu: selectedPIUObj._id
        };

        // If there are any selected or active workspaces, append their ids to the workspaceId property.
        if (!isEmptyObject(selectedWorkspace) || thereActiveWorkspace) {
            payload['workspaceId'] = thereActiveWorkspace ? activeWorkspace._id : selectedWorkspace._id;
        }
        _createProject(payload);
    };

    return (
        <form
            onSubmit={handleSubmit}
            action=""
            className="grid px-8 py-5 bg-white gap-x-4 gap-y-5 grid-col-2"
        >
            <div className="col-span-2 space-y-2">
                <label
                    className={"block mb-2 text-sm font-medium text-[#002F4F]"}
                >
                    Project Description
                </label>
                <EditorJS onChange={handleEditorChanges}/>
            </div>

            <div className="col-span-2 space-y-2">
                <label
                    className={"block mb-2 text-sm font-medium text-[#002F4F]"}
                >
                    Select Project PIU
                </label>
                <UnstableComboboxInput
                    name="piu"
                    className={"text-sm"}
                    hasError={isSubmitted && isEmptyObject(selectedPIUObj)}
                    options={piuList}
                    displayItemName={"registeredName"}
                    onChange={setSelectedPIUObj}
                    selectedOptions={selectedPIU}
                    isLoading={isLoading}
                />
            </div>

            <div hidden={thereActiveWorkspace} className="col-span-2 space-y-2">
                <label
                    className={`block mb-2 text-sm font-medium text-[#002F4F] flex justify-between w-full `}
                >
                    <span>Select Workspace</span>
                    <span className="text-sm font-light text-gray-500">
						( Optional )
					</span>
                </label>
                <UnstableComboboxInput
                    selectedOptions={selectedWorkspace}
                    onChange={setSelectedWorkspace}
                    className={"text-sm"}
                    displayItemName={"name"}
                    name={"workspaces"}
                    options={workspaces}
                />
            </div>

            <div className={"flex items-center pt-5 space-x-2 col-span-2"}>
                <div className={"shrink-0"}>
                    <Button
                        onClick={() => setCurrStep(1)}
                        outline={true}
                        block={true}
                        type="submit"
                        className={
                            " flex whitespace-nowrap border-secondary items-center space-x-2 text-sm"
                        }
                    >
                        <ArrowLeftIcon className={"h-5 w-5"}/>
                        <span>PREV STEP</span>
                    </Button>
                </div>
                <div className={"w-full"}>
                    <Button
                        loading={isCreating}
                        block={true}
                        type="submit"
                        className={"py-4 bg-secondary text-sm"}
                    >
                        CREATE
                    </Button>
                </div>
            </div>
        </form>
    );
};

export default ProjectPiuSetupFrom;
