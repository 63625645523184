import { useEffect, useState } from "react";
import { Switch } from "@headlessui/react";

const ToggleButton = ({
    currentState = false,
    label = null,
    onToggle,
    activeClassname,
}) => {
    const [enabled, setEnabled] = useState(currentState);

    useEffect(() => {
        setEnabled(currentState);
    }, [currentState]);

    const handleOnToggle = () => {
        setEnabled(!enabled);
        onToggle(!enabled);
    };

    return (
        <div
            className={"flex items-center text-[15px] space-x-2 text-[#AAB6C5]"}
        >
            <Switch
                checked={enabled}
                onChange={handleOnToggle}
                className={`${
                    enabled
                        ? "border-[#628799] bg-[#628799]"
                        : "border-[#628799] bg-white"
                } relative inline-flex h-6 w-10 border-[1.5px]  items-center rounded-full`}
            >
                <span className="sr-only">Enable notifications</span>
                <span
                    className={`${
                        enabled
                            ? "right-[2px] bg-white"
                            : "left-[3px] bg-[#628799]"
                    } inline-block h-4 w-4 absolute enable-transition rounded-full `}
                />
            </Switch>
            {label && (
                <p
                    className={"cursor-pointer hover:text-primary"}
                    onClick={handleOnToggle}
                >
                    {label}
                </p>
            )}
        </div>
    );
};

export default ToggleButton;
