const useDeleteFromS3Bucket = () => {
    let isConfigUpdate = false;

    async function deleteFileObject(credential, filename) {
        try {
            if (!window.AWS) {
                return
            }

            if (!isConfigUpdate) {
                window.AWS.config.update(({region: credential.region}));
                isConfigUpdate = true;
            }

            let s3 = new window.AWS.S3({
                credentials: new window.AWS.Credentials({
                    apiVersion: 'latest',
                    accessKeyId: credential.accessKeyId,
                    secretAccessKey: credential.secretAccessKey,
                    signatureVersion: credential.signatureVersion,
                    region: credential.region,
                    Bucket: credential.Bucket
                })

            });
            return await s3.deleteObject({
                Bucket: credential.Bucket,
                Key: filename,
            }).on("httpUploadProgress", function (progress) {
                console.log('deleting');
            }).promise();
        } catch (error) {
            console.log('error while attempt delete an object')
            console.log(error)
        }
    }

    async function deleteFromS3Bucket(objectKey) {
        console.log('called');
        let credentialRequest = { // deleteFromS3Bucket
            accessKeyId: process.env.REACT_APP_AWS_ACCESS_ID,
            secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
            signatureVersion: process.env.REACT_APP_AWS_VERSION,
            region: process.env.REACT_APP_AWS_REGION,
            Bucket: process.env.REACT_APP_AWS_BUCKET_NAME
        };
        await deleteFileObject(credentialRequest, objectKey);
    }

    return {deleteFromS3Bucket};
}
export default useDeleteFromS3Bucket;