import { useEffect, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";

import useGlobalContext from "hooks/useGlobalContext";
import { baseRotes } from "routes/constants";
import { slugify } from "utils";
import { Tab } from "./list/stakeholder-details";
import StakeholderList from "./list/stakeholder-list";
import EditIcon from "./_partials/edit-icon";
import GetStakeholderTabMenu from "./_partials/stakeholder-tab-menu";
import saveStakeholderDetailsAndNavigateToForm from "./_partials/handle-stakeholder-edit";

const StakeholderProfile = () => {
    const { stakeholder: stakeholders, isLoading } = useGlobalContext();
    const [currStakeholder, setCurrStakeholder] = useState({});
    const getStakeholderByName = (name) => {
        return stakeholders.find((stakeholder) => {
            return slugify(stakeholder.name.toLowerCase()) === name;
        });
    };

    const { STAKEHOLDER_BASE_ROUTE } = baseRotes;
    const { stakeholderName } = useParams();

    useEffect(() => {
        if (stakeholderName) {
            setCurrStakeholder(getStakeholderByName(stakeholderName));
        }
    }, [stakeholderName]);

    const getCurrentStakeholder = () => {
        return stakeholderName
            ? getStakeholderByName(stakeholderName)
            : currStakeholder;
    };

    const navigate = useNavigate();
    const _currStakeholder = getCurrentStakeholder();
    const menuItems = GetStakeholderTabMenu(
        _currStakeholder,
        STAKEHOLDER_BASE_ROUTE
    );

    const tabMenus = menuItems.map((menuProps) => (
        <Tab key={menuProps.title} {...menuProps} />
    ));

    const handleEditStakeholder = async () => {
        await saveStakeholderDetailsAndNavigateToForm(
            currStakeholder,
            navigate
        );
    };

    return (
        <div className={`h-full w-full flex `}>
            <StakeholderList
                stakeholders={stakeholders}
                isLoading={isLoading}
                getCurrentStakeholder={getCurrentStakeholder}
                setCurrStakeholder={setCurrStakeholder}
            />
            <div
                className={`
                stakeholder-details
                h-full overflow-y-auto 
                pointer-events-none 
                ${
                    currStakeholder
                        ? "pointer-events-auto cursor-auto"
                        : " cursor-wait"
                }
                `}
            >
                <div className={"w-4/5 mx-auto h-full space-y-8"}>
                    <div
                        className={`
                        flex  top-0 border-b bg-white
                        sticky z-30 items-end 
                        justify-between tracking-wide  
                        shadow-lg h-16`}
                    >
                        {/* Tab Menu */}
                        <div
                            className={
                                "flex text-[13px] items-center text-[#A7B3C3] space-x-5"
                            }
                        >
                            {tabMenus}
                        </div>
                        <div className={"pb-2"}>
                            <button
                                onClick={handleEditStakeholder}
                                className={`
                                h-8 w-8 hover:opacity-90 flex justify-center 
                                items-center bg-accent 
                                overflow-hidden rounded-full 
                                text-white`}
                            >
                                <EditIcon />
                            </button>
                        </div>
                    </div>
                    <Outlet
                        context={{
                            stakeholders,
                            isLoading,
                            getStakeholderByName,
                            currStakeholder,
                            setCurrStakeholder,
                            getCurrentStakeholder,
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default StakeholderProfile;
