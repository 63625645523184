import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import clearForm from "./clear-form";
import Button from "../../../../../../../../../components/Button";

const ActionButtons = ({
    text,
    onCancel: handleCancel,
    isEditing = false,
    onSave: handleSave = () => {},
    isLoading = false,
    isUploading: uploadingStatus = false,
}) => {
    const navigate = useNavigate();
    const [isUploading, setIsUploading] = useState(uploadingStatus);

    useEffect(() => {
        setIsUploading(uploadingStatus);
    }, [uploadingStatus]);

    const handleNavigate = async () => {
        await clearForm();
        navigate("/engagement/activities/events/upcoming");
    };

    return (
        <div className={"py-4 flex justify-end space-x-4"}>
            <Button
                onClick={isEditing ? handleNavigate : handleCancel}
                className={
                    "rounded-[3px] py-3 bg-white font-medium border-[1.5px] px-4 border-accent text-accent"
                }
            >
                {isEditing ? "CANCEL" : "BACK"}
            </Button>

            <Button
                loading={isLoading}
                onClick={handleSave}
                disabled={isUploading}
                type={"submit"}
                className={
                    "uppercase rounded-[3px] bg-accent text-xs  border-[1.5px] px-4 border-accent text-white"
                }
            >
                {isEditing ? "Save Changes" : text || "text"}
            </Button>
        </div>
    );
};
export default ActionButtons;
