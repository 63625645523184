const AirdropIcon = () => {
    return (
        <svg className={'h-14 w-14'} id="vuesax_bulk_airdrop" data-name="vuesax/bulk/airdrop" xmlns="http://www.w3.org/2000/svg"
             viewBox="0 0 53.344 53.344">
            <g id="airdrop" transform="translate(0 0)">
                <path id="Vector" d="M0,0H53.344V53.344H0Z" transform="translate(53.344 53.344) rotate(180)" fill="none"
                      opacity="0"/>
                {/* Head */}
                <path id="Vector-2" data-name="Vector"
                      d="M9.635,6.1A4.9,4.9,0,1,0,6.1,9.635,4.837,4.837,0,0,0,9.635,6.1Z"
                      transform="translate(21.771 19.104)" fill="#628799"/>
                <g id="Group" transform="translate(5.468 2.778)">
                    <path id="Vector-3" data-name="Vector"
                          d="M7.913,37.163a1.7,1.7,0,0,1-1.134-.444,21.2,21.2,0,1,1,28.939-.067,1.667,1.667,0,1,1-2.289-2.423,17.643,17.643,0,0,0,5.646-13,17.87,17.87,0,0,0-35.74,0A17.615,17.615,0,0,0,9.046,34.3a1.678,1.678,0,0,1,.089,2.356A1.735,1.735,0,0,1,7.913,37.163Z"
                          fill="#628799" opacity="0.3"/>
                </g>
                {/* Second Circle */}
                <g id="Group-2" data-name="Group" transform="translate(12.825 10.135)">
                    <path id="Vector-4" data-name="Vector"
                          d="M22.738,23.871a1.7,1.7,0,0,1-1.134-.445,1.657,1.657,0,0,1-.067-2.356,10.535,10.535,0,1,0-15.336,0,1.677,1.677,0,0,1-.067,2.356,1.657,1.657,0,0,1-2.356-.067,13.863,13.863,0,1,1,23.96-9.491A13.743,13.743,0,0,1,23.96,23.36,1.735,1.735,0,0,1,22.738,23.871Z"
                          fill="#628799" opacity={'0.6'}/>
                </g>
                {/* Body */}
                <path id="Vector-5" data-name="Vector"
                      d="M4.272,1.817,1.071,5.8a4.84,4.84,0,0,0,3.779,7.868h6.379A4.837,4.837,0,0,0,15.007,5.8l-3.2-3.979A4.814,4.814,0,0,0,4.272,1.817Z"
                      transform="translate(18.622 35.212)" fill="#628799"/>
            </g>
        </svg>


    )
}
export default AirdropIcon;