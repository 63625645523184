import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import Button from "../../../components/Button";
import { useNavigate } from "react-router-dom";
import { Menu } from "@headlessui/react";
import { Fragment } from "react";

const Header = ({ setIsOpen: setShowForm, setIsGBV }) => {
	const navigate = useNavigate();
	const formMenuItems = ["Normal Complaint", "GBV Complaint"];

	const handleMenuClick = (menu) => {
		if (menu === "Normal Complaint") {
			setShowForm(true);
			setIsGBV(false);
			return;
		}

		setShowForm(true);
		setIsGBV(true);
		return;
	};

	return (
		<div className="w-full bg-[#EBF0F3] py-6 grid justify-items-end">
			<div className="flex flex-row space-x-6">
				<Button
					onClick={() => navigate("closed")}
					className={
						"bg-[#F6F9FE] border-[#DFE1E5] text-[#628799] font-medium"
					}
				>
					<span>View Closed Complaints</span>
				</Button>

				<Menu as="div" className="relative">
					<Menu.Button
						className={
							"bg-primary hover:opacity-90 flex space-x-2 text-white text-sm px-4 py-2 rounded"
						}
					>
						<span>ADD COMPLAINTS </span>
						<FontAwesomeIcon icon={faPlus} />
					</Menu.Button>
					<Menu.Items
						as="ul"
						className="absolute z-50 w-full overflow-hidden border divide-y-[1px] rounded-lg top-full bg-white/40 backdrop-blur-xl"
					>
						{formMenuItems.map((menu) => (
							/* Use the `active` state to conditionally style the active item. */
							<Menu.Item key={menu} as={"li"}>
								{({ active }) => (
									<p
										onClick={() => handleMenuClick(menu)}
										className={`${
											active ? "bg-black/5 " : " "
										} p-2 text-sm text-black cursor-pointer`}
									>
										{menu}
									</p>
								)}
							</Menu.Item>
						))}
					</Menu.Items>
				</Menu>
			</div>
		</div>
	);
};

export default Header;
