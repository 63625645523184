import PropTypes from "prop-types";
import CheckIcon from "../../../../components/CheckIcon";
import { useEffect, useState } from "react";
import { usePermissionContext } from "./permission-form";

const SuggestionItem = ({ suggestion, onClick }, ...props) => {
    const {
        permissions,
        setPermissions,
        addedPermission,
        setAddedPermission,
        removedPermission,
        setRemovedPermission,
    } = usePermissionContext();
    const [isSelected, setIsSelected] = useState(
        suggestion?.isSelected ?? false
    );

    useEffect(() => {
        const isInPermissionsList = permissions.find((permission) => {
            return permission?.displayName === suggestion?.displayName;
        });
        if (isSelected && !isInPermissionsList) {
            setPermissions([...permissions, suggestion]);

            // Add permission to added list
            setAddedPermission([...addedPermission, suggestion]);

            // Remove permission from removed list
            setRemovedPermission(
                removedPermission.filter((permission) => {
                    return permission?.displayName !== suggestion?.displayName;
                })
            );
        }

        if (!isSelected && isInPermissionsList) {
            setPermissions(
                permissions.filter((permission) => {
                    return permission?.displayName !== suggestion?.displayName;
                })
            );

            // Remove permission from added list
            setAddedPermission(
                addedPermission.filter((permission) => {
                    return permission?.displayName !== suggestion?.displayName;
                })
            );

            // Add permission to removed list
            setRemovedPermission([...removedPermission, suggestion]);
        }
    }, [isSelected]); // eslint-disable-line react-hooks/exhaustive-deps

    const toggleSelection = () => {
        setIsSelected(!isSelected);
    };
    return (
        <p
            onClick={toggleSelection}
            {...props}
            className={
                "flex items-center hover:bg-white/70 cursor-pointer p-2  space-x-1"
            }
        >
            <CheckIcon
                color={"#41727E"}
                className={`h-3 w-3 ${
                    isSelected ? "opacity-100 scale-100" : "opacity-0 scale-50"
                } transition-all`}
            />
            <span>{suggestion?.displayName}</span>
        </p>
    );
};
SuggestionItem.propTypes = {
    suggestion: PropTypes.object.isRequired,
};
export default SuggestionItem;
