import { useState, Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import KeyResultInputItem from "./key-result-input-item";
import { getRandomKey } from "../../../../utils";

const KeyResultInput = ({ addKeyResults, isCompleted }) => {
	const [inputFields, setInputFields] = useState([]);
	const [errorList, setErrorList] = useState([]);

	const addInputField = () => {
		setInputFields([
			...inputFields,
			{
				title: "",
				id: getRandomKey(),
				dateToComplete: "",
				status: 0,
			},
		]);
	};

	const removeInputField = (id) => {
		setInputFields([
			...inputFields.filter((input) => {
				return input.id !== id;
			}),
		]);

		setErrorList(
			errorList.filter((errId) => {
				return errId !== id;
			})
		);
	};

	const handleSubmit = () => {
		addKeyResults(inputFields);
		setInputFields([]);
	};

	return (
		<div className="container grid items-start grid-cols-1 py-2 transition-all">
			{inputFields.map((data, index) => {
				return (
					<KeyResultInputItem
						inputFields={inputFields}
						index={index}
						onChange={setInputFields}
						errorList={errorList}
						setErrorList={setErrorList}
						keyResult={data}
						key={data.id}
						onRemove={removeInputField}
					/>
				);
			})}

			{!isCompleted && (
				<Fragment>
					{inputFields.length === 0 && (
						<div>
							<button
								onClick={(event) => {
									event.stopPropagation();
									addInputField();
								}}
								className="bg-white py-3 text-[#009EE2] flex flex-row space-x-2 place-items-center"
							>
								<span className="text-xs italic">
									Add Milestone{" "}
								</span>
								<div className="h-3 w-3 rounded-full text-white bg-gradient-to-r from-[#A0C7F1] to-[#267CE1] grid place-items-center">
									<FontAwesomeIcon
										className="h-2.5 w-2.5"
										icon={faPlus}
										color={"FFFFFF"}
									/>
								</div>
							</button>
						</div>
					)}

					{inputFields.length !== 0 && (
						<div className="flex flex-row justify-between py-2">
							<button
								className="text-[#009EE2] italic hover:scale-110 transition-transform"
								onClick={addInputField}
							>
								Add more...
							</button>

							<button
								className={`text-[#009EE2] ${
									errorList.length
										? "opacity-50 pointer-events-none"
										: "opacity-100 pointer-events-auto"
								} border border-[#009EE2] hover:scale-110 transition-all rounded py-1 px-4`}
								onClick={handleSubmit}
							>
								save
							</button>
						</div>
					)}
				</Fragment>
			)}
		</div>
	);
};
export default KeyResultInput;
