import { Fragment, useEffect, useState } from "react";
import { CheckIcon } from "@heroicons/react/outline";

const CommitmentAction = ({
	status: commitmentStatus,
	isCompleted,
	keyResultCompleted,
	onClick: completeCommitment,
}) => {
	const [completionStatus, setCompletionStatus] = useState(false);
	// console.log("completionStatus:: ", completionStatus);

	useEffect(() => {
		setCompletionStatus(keyResultCompleted);
	}, [keyResultCompleted]);

	// const handleClick = () => {
	//     switch (commitmentStatus) {
	//         case "COMPLETED": {
	//             // ...
	//         }
	//
	//         case "OVERDUE": {
	//             // ...
	//         }
	//
	//         default: {
	//             // ..
	//         }
	//     }
	// }

	return (
		<button
			onClick={completeCommitment}
			className={`text-xs tracking-wide transition-all border-[1.4px] ${
				completionStatus && !isCompleted
					? "opacity-100 pointer-events-auto"
					: "opacity-60 pointer-events-none"
			}  bg-secondary hover:bg-secondary/90 text-white flex items-center gap-x-1 font-light px-3 py-2 rounded`}
		>
			<Fragment>
				<CheckIcon className={"h-5"} />
				{commitmentStatus !== 2 ? "Completed" : "Complete"}
			</Fragment>
		</button>
	);
};
export default CommitmentAction;
