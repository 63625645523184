import Button from "../../../../components/Button";
import { ArrowLeftIcon } from "@heroicons/react/outline";

const FormFooter = ({
    loading,
    isUpdating,
    currStep = 1,
    type,
    onCLick: handleClick,
    onPrev: handelPrev,
}) => {
    return (
        <div className={"flex items-center pt-3 pb-6  px-10 gap-x-4"}>
            <Button
                onClick={handelPrev}
                outline={true}
                block={true}
                type="submit"
                className={` flex whitespace-nowrap py-2.5 items-center space-x-2 text-xs ${
                    currStep === 1 ? "hidden" : ""
                }`}
            >
                <ArrowLeftIcon className={"h-5 w-5"} />
                <span>PREV STEP</span>
            </Button>
            <Button
                loading={loading}
                onClick={handleClick}
                block={true}
                type={type ?? "submit"}
                className={"py-3 text-xs w-full"}
            >
                {isUpdating
                    ? "UPDATE COMMITMENT"
                    : currStep === 1
                    ? "NEXT"
                    : "ADD COMMITMENT"}
            </Button>
        </div>
    );
};
export default FormFooter;
