import { Link } from "react-router-dom";
import { flatten } from "utils";
import Image from "../../../components/image";
import { useProjectImages } from "../../../hooks";
import { ArrowRightIcon } from "../../../svg";

const GrievanceImages = () => {
	const { isLoading, images = {} } = useProjectImages();
	const { grievances: grievancesImages = [] } = images;

	const arrOfImage = flatten(
		grievancesImages.map((file) => {
			return file.attachments;
		})
	);

	const defaultImage =
		"https://i.pinimg.com/736x/bd/0c/31/bd0c3178e30a229021d8614e31c78d08.jpg";

	return (
		<Link
			to="grievances"
			className={
				" relative cursor-pointer overflow-hidden h-[11.5rem] rounded-[10px]"
			}
		>
			<Image
				imageUrl={arrOfImage?.at(-1)?.url ?? defaultImage}
				alt="grievance images"
			/>

			<div
				className={
					"bg-black/50 hover:bg-black/40 p-6 text-white flex flex-col justify-between absolute inset-0"
				}
			>
				<div>
					<p>Grievance Photos</p>
					<p className={"font-light text-sm"}>
						{grievancesImages.length} photos
					</p>
				</div>
				<div>
					<div className={`h-7 w-7 bg-white rounded-full center`}>
						<ArrowRightIcon
							color={"#628799"}
							className={"h-5 w-6"}
						/>
					</div>
				</div>
			</div>
		</Link>
	);
};
export default GrievanceImages;
