import Select from "../form-control/Select";
import Option from "../form-control/Option";
import React from "react";

const TimePicker = ({currentTime, setTime, clearErrors}) => {
    const handleTimeChanges = (e) => {
        clearErrors();

        const {name, value} = e.target;

        setTime({...currentTime, [name]: value});
    };

    return (
        <div className={"flex  space-x-5 flex-wrap "}>
            <Select
                name="hour"
                onChange={handleTimeChanges}
                defaultValue={currentTime?.hour ?? "00"}
                className={"font-medium text-accent w-full py-2 border-[#E4E4E4]"}
            >
                {[...Array(24)].map((number, idx) => (
                    <Option value={idx < 10 ? `0${idx}` : idx} key={idx}/>
                ))}
            </Select>
            <Select
                name="minutes"
                onChange={handleTimeChanges}
                defaultValue={currentTime?.minutes ?? "00"}
                className={" py-2  text-accent w-full font-medium border-[#E4E4E4]"}
            >
                {[...Array(60)].map((number, idx) => {
                    return <Option value={idx < 10 ? `0${idx}` : idx} key={idx}/>
                })}
            </Select>
        </div>
    );
};
export default TimePicker;
