const Shinyanga = ({ color, isLoading = false }) => {
    return (
        <path
            className={`${isLoading ? "animate-pulse" : ""}`}
            id="Path_456"
            data-name="Path 456"
            d="M335.33,173.245l-1.123-.16-.9.49-.513.944-1.075.027-1.337-.553-1.4-.1-.788-.227-.6-.567-.76-.306-.889.261-1.249-.026-1.281-.347-1.062-.76-.8-1.077-1.034-.644-1.24-.168-2.348-.073-2.93-1-1.341.036-1.293.207-.776-.6-.178-1-2.186-.5-2.255.569-1.079.386-2.658,0-2.182-.569-4.521,1.091-3.471,1.64-2.081.1-2.339-.2-1.77-1.486-1.588-.531-1.879.812-2.788-.605-.675.343-.557.567-2.513,1.086-2.146.713.428,2.24-.574,2.149-.938.146-.441.711-.671.593-.283.689.675.8.788.693,1.75.6-.719,2.082-3.794,1.116-.938.034-.064-.659.045-.735-.42-1.108-.735-.78-1.3-.6-1.374.84-1.03.164L263.508,181l-.263,1.542.263,1.481-.137.845-.606.472-1,1.265-1.406.3-1.568-.748-1.483-.914-3.192-1-3.249.63-3.208,2.447-2.913,1.359.295-1.493.109-1.621-.557-1.935-.158-2.022.1-2.129-.279-2.018-1.144-1.09-1.313-.948-3.475-.717-4.57-1.4-.535-2.739.55-2.481,2.2-3.308,3.438-3.032,4.125-1.929,3.988-.551,3.576-.276.55-3.032-.687-3.858-.55-4,.825-2.618,1.689-2.182,1.216-.974,1.491-.427,1.18.815.339,1.463,1.011.832,1.232.128.63-1.3.921-1.066,1.159-.947,2.437-1.18,1.265-.253.857,1.153.327,1.451-.287,2.182.016,2,1.386,1.048,3.132,1.907,1.459.053.045-1.087-.913-.148-.182-1.232.546-1.478.279-1.076,1.127.071,1.152-.546.59-1.232.788-.467,1.014.288,1.285-.411,1.131-.233,1.208.909,1.1,1.054.622,1.437,1.05.824,1.277.387,1.208.617.8.1.731.332.767.755.921.539,2.578-1.208,1.487-2.906,1.358-.484,1.463.354,1.677-.23,1.54-.447,2.133-1.291,2.03.234,1.864,1.66,2.8,2.593,3.418,1.66,1.968.519,1.45-.311,1.346-.726,1.139.519,1.139,2.179,1.864,1.141,2.9.311,3.625.1,3.21.934,1.243,2.075,1.346,4.462,1.657,3.841,2.175,2.8,1.657,1.557,1.876,1.354-.99,1.063-2.372.615Z"
            transform="translate(-151.756 -53.848)"
            fill={color}
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="0.5"
        />
    );
};
export default Shinyanga;
