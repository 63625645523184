import { PlusIcon } from "@heroicons/react/solid";
import { Fragment, useState } from "react";
import { useMutation, useQuery } from "react-query";
import Button from "../../../components/Button";
import {
	getGrievanceNature,
	removeGrievanceNature,
} from "../../../provider/api";
import GrievanceNatureForm from "./form";
import GrievanceCard from "./grievance-card";

const GrievanceManagement = () => {
	const [showForm, setShowForm] = useState(false);
	const [activeNature, setActiveNature] = useState({});
	const [natures, setNatures] = useState([]);

	// fetch grievance nature from backend
	useQuery("grievance-natures", getGrievanceNature, {
		onSuccess: ({ data }) => {
			setNatures(data);
		},
	});

	// send delete request to delete grievance-nature
	const { mutate: _removeNature } = useMutation(
		"remove-grievance-nature",
		removeGrievanceNature,
		{
			onSuccess: ({ data }) => {
				console.log("successfully removed");
			},
		}
	);

	const handleRemoveNature = (natureId) => {
		// remove from ui
		setNatures([...natures.filter(({ _id }) => _id !== natureId)]);
		// remove from database
		_removeNature({ natureId });
	};

	return (
		<Fragment>
			<GrievanceNatureForm
				showForm={showForm}
				setShowForm={setShowForm}
				activeNature={activeNature}
				setActiveNature={setActiveNature}
			/>
			<section className={"space-y-5 pt-7"}>
				<div className={"items-between"}>
					<h2 className={"text-[#002F4F] font-medium text-lg "}>
						Manage grievance nature
					</h2>
					<Button
						onClick={() => setShowForm(true)}
						outline={true}
						className={"flex border-[2px] items-center space-x-2"}
					>
						<span>Add Grievance Nature</span>
						<PlusIcon className={"h-5 w-5"} />
					</Button>
				</div>

				<div className={"grid grid-cols-4 gap-4 2xl:grid-cols-5"}>
					{natures.map((nature) => (
						<GrievanceCard
							setShowForm={setShowForm}
							onRemove={handleRemoveNature}
							setActiveNature={setActiveNature}
							nature={nature}
						/>
					))}
				</div>
			</section>
		</Fragment>
	);
};
export default GrievanceManagement;
