import { isIncludedIn } from "../../../../../../utils";

const getStakeholderFields = (
    defaultDetails,
    errors,
    register,
    stakeholderType
) => {
    const fields = [
        {
            name: "scope",
            hasError: !!errors?.scope?.message,
            defaultValue: defaultDetails.scope,
            register,
            options: ["Internal", "External"],
        },
        {
            name: "name",
            hasError: !!errors?.name?.message,
            defaultValue: defaultDetails.name,
            register,
            placeholder:
                defaultDetails.type === "Organizational"
                    ? "Eg. ABC Ltd"
                    : "Eg. John Doe",
        },
        {
            name: "phoneNumber",
            hasError: !!errors?.phoneNumber?.message,
            defaultValue: defaultDetails.phoneNumber,
            register,
            placeholder: "255789101112",
            type: "tel",
        },
        {
            name: "email",
            hasError: !!errors?.email?.message,
            defaultValue: defaultDetails.email,
            register,
            placeholder: "user@mail.com",
            type: "email",
        },
        {
            name: "organizationType",
            hasError: !!errors?.organizationType?.message,
            defaultValue: defaultDetails.organizationType,
            register,
            options: ["Functional", "Divisional", "Flatarchy", "Matrix"],
        },
        {
            name: "gender",
            hasError: !!errors?.gender?.message,
            defaultValue: defaultDetails.gender,
            register,
            options: ["Male", "Female"],
        },
        {
            name: "disability",
            hasError: !!errors?.disability?.message,
            defaultValue: defaultDetails.disability,
            register,
            options: [
                "None",
                "Deaf",
                "Vision Impairment",
                "Physical Disability",
            ],
        },
        {
            name: "organization",
            hasError: !!errors?.organization?.message,
            defaultValue: defaultDetails.organization,
            placeholder: "Eg: Org",
            register,
        },
        {
            name: "position",
            hasError: !!errors?.position?.message,
            defaultValue: defaultDetails.position,
            placeholder: "Eg: District Executive",
            register,
        },
        {
            name: "postalCode",
            hasError: !!errors?.postalCode?.message,
            defaultValue: defaultDetails.postalCode,
            placeholder: "Eg: 123 Temeke",
            register,
        },
        {
            name: "role",
            hasError: !!errors?.role?.message,
            defaultValue: defaultDetails.role,
            register,
            options: [
                "Vendor",
                "Sponsor",
                "Advisor",
                "Executor",
                "Beneficiary",
            ],
        },
    ];

    const locationFields = [
        {
            name: "region",
            hasError: !!errors?.region?.message,
            defaultValue: defaultDetails.region,
            placeholder: "Eg: District Executive",
            register,
        },
        {
            name: "district",
            hasError: !!errors?.district?.message,
            defaultValue: defaultDetails.district,
            placeholder: "Eg: District Executive",
            register,
        },
        {
            name: "ward",
            hasError: !!errors?.ward?.message,
            defaultValue: defaultDetails.ward,
            placeholder: "Eg: District Executive",
            register,
        },
        {
            name: "street",
            hasError: !!errors?.street?.message,
            defaultValue: defaultDetails.street,
            placeholder: "Eg: District Executive",
            register,
        },
    ];

    const removeFields = (names) => {
        const toBeRemoved = Array.isArray(names) ? names : [names];
        return fields.filter((field) => {
            return !isIncludedIn(field.name, toBeRemoved);
        });
    };

    //  return fields based on a type of list
    switch (stakeholderType) {
        case "Organizational": {
            return {
                basicFields: removeFields([
                    "gender",
                    "disability",
                    "organization",
                    "position",
                ]),
                locationFields,
            };
        }
        case "Community": {
            return {
                basicFields: removeFields([
                    "gender",
                    "disability",
                    "organization",
                    "position",
                    "organizationType",
                ]),
                locationFields,
            };
        }
        default:
            return {
                basicFields: removeFields(["organizationType"]),
                locationFields,
            };
    }
};
export default getStakeholderFields;
