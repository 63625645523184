import { toast, ToastContainer } from "react-toastify";
import { useState } from "react";
import { Outlet } from "react-router-dom";

import FormStepIndicator from "./form-step-indicator";
import EventProvider from "../../../../../../provider/general/event";
import { getValueFromLocalStorage } from "../../../../../../hooks/useLocalStorageState";

const MeetingRegFormLayout = () => {
    const initialStep = getValueFromLocalStorage("meetingFormCurrentStep", 1);
    const [currStep, setCurrStep] = useState(initialStep);

    return (
        <EventProvider>
            <section className={"h-full  flex "}>
                <FormStepIndicator
                    currentStep={currStep}
                    setCurrStep={setCurrStep}
                />
                <div
                    className={
                        "create-meeting-from space-y-5 h-full overflow-y-auto px-20 py-6 "
                    }
                >
                    <Outlet context={[setCurrStep, toast]} />
                </div>
            </section>
            <ToastContainer />
        </EventProvider>
    );
};
export default MeetingRegFormLayout;
