import StakeholderBreakdownStatistics from "../_partials/stakeholder-breakdown-statistics";

const StakeholderBreakdown = ({ stakeholders = [] }) => {
	const getIndividualStakeholders = () => {
		return stakeholders.filter((stakeholder) => {
			return stakeholder?.type === "Individual";
		});
	};

	const getOrganizationalStakeholders = () => {
		return stakeholders.filter((stakeholder) => {
			return stakeholder?.type === "Organizational";
		});
	};

	const getCommunityStakeholders = () => {
		return stakeholders.filter((stakeholder) => {
			return stakeholder?.type === "Community";
		});
	};

	const getContactsPersonal = () => {
		const stakeWithContactPersonal = stakeholders.filter((stakeholder) => {
			return !!stakeholder?.contactPerson;
		});

		return stakeWithContactPersonal.map((stakeholder) => {
			return stakeholder.contactPerson;
		});
	};

	const getFemaleContactPersonal = () => {
		return getContactsPersonal().filter((contactPersonal) => {
			return ["Female", "F"].includes(contactPersonal?.gender);
		});
	};

	const getMaleContactPersonal = () => {
		return getContactsPersonal().filter((contactPersonal) => {
			return ["Male", "M"].includes(contactPersonal?.gender);
		});
	};

	// get contact personal with disabilities
	const getContactsPersonalWD = () => {
		return getContactsPersonal().filter((contactPersonal) => {
			return contactPersonal.disability !== "none";
		});
	};

	const statistics = {
		individual: {
			total: getIndividualStakeholders().length,
			percentage: Math.floor(
				(getIndividualStakeholders().length * 100) / stakeholders.length
			),
		},
		organisational: {
			total: getOrganizationalStakeholders().length,
			percentage: Math.floor(
				(getOrganizationalStakeholders().length * 100) /
					stakeholders.length
			),
		},
		community: {
			total: getCommunityStakeholders().length,
			percentage: Math.floor(
				(getCommunityStakeholders().length * 100) / stakeholders.length
			),
		},

		contactPersonal: {
			total: getContactsPersonal().length,
			male: getMaleContactPersonal().length,
			female: getFemaleContactPersonal().length,
			withDisability: getContactsPersonalWD().length,
		},
	};

	const contactPersonalBreakdown = [
		{
			title: "Number of contact persons",
			total: statistics.contactPersonal.total,
		},

		{
			title: "Male Contact persons",
			total: statistics.contactPersonal.male,
		},

		{
			title: "Female Contact persons",
			total: statistics.contactPersonal.female,
		},

		{
			title: "Contact persons with disability",
			total: statistics.contactPersonal.withDisability,
		},

		{
			title: "Stakeholder without contact persons",
			total: stakeholders.length - getContactsPersonal().length,
		},
	];
	return (
		<div className={"flex space-x-16"}>
			<div className={"w-1/2 space-y-2"}>
				<p className={"text-[#0E1731] text-lg"}>
					Stakeholder Breakdown
				</p>
				<div className={"space-y-4"}>
					<StakeholderBreakdownStatistics
						title={"Individual Stakeholders"}
						percentage={statistics.individual.percentage}
						total={statistics.individual.total}
					/>

					<StakeholderBreakdownStatistics
						title={"Community Stakeholders"}
						percentage={statistics.community.percentage}
						total={statistics.community.total}
					/>

					<StakeholderBreakdownStatistics
						title={"Organisational Stakeholders"}
						percentage={statistics.organisational.percentage}
						total={statistics.organisational.total}
					/>
				</div>
			</div>
			<div className={"w-1/2 space-y-2 text-[#0E1731]"}>
				<p className={"px-3 text-lg"}>Contact Persons</p>
				<div className={"space-y-1"}>
					{contactPersonalBreakdown.map((detail, index) => (
						<p
							key={detail.title}
							className={`items-between ${
								index % 2 === 0 ? "bg-[#EDEFF7]/40" : ""
							} font-light py-2 rounded-[4px] px-3 text-sm`}
						>
							<span>{detail.title}</span>
							<span className={"text-secondary"}>
								{detail.total}
							</span>
						</p>
					))}
				</div>
			</div>
		</div>
	);
};
export default StakeholderBreakdown;
