import React from "react";
import {Dialog, Transition} from "@headlessui/react";
import PropTypes from "prop-types";

const SidebarRight = ({onClose, isOpen, children, className = 'w-2/5'}) => {
    return (
        <Transition appear show={isOpen} as={"div"}>
            <Dialog
                onClose={onClose}
                className={`${className} fixed top-[64px] h-full right-0 z-40`}
            >

                <Transition.Child
                    enter="duration-300 ease-out"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="duration-300 ease-in"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    {/*<Dialog.Overlay className="fixed inset-0 bg-[#192B40]/70"/>*/}
                </Transition.Child>

                <Transition.Child
                    className={'h-full relative'}
                    enter="ease-in-out  duration-300"
                    enterFrom=" -right-[40rem]"
                    enterTo="opacity-100 right-0"
                    leave="ease-in-out  duration-300"
                    leaveFrom=" right-0"
                    leaveTo=" -right-[40rem]"
                >
                    <div className={'h-full meeting-sidebar backdrop-blur-xl bg-white/70'}>
                        {children}
                    </div>
                </Transition.Child>

            </Dialog>
        </Transition>

    )
}
export default SidebarRight;

SidebarRight.propTypes = {
    onClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired
}