import EventListItem from "../_partials/event-list-item";
import { useState } from "react";
import SidebarRight from "../../../../../../components/sidebar-right";
import MeetingDetails from "../_partials/meeting-details";
import MeetingHeader from "../_partials/meeting-header";
import Loading from "../_partials/event-list-item/loading";

const EventListView = ({ events, isLoading = false }) => {
    const [showSidebar, setShowSidebar] = useState(false);
    const [activeEvent, setActiveEvent] = useState({});

    const handleOnClickEvent = (event) => {
        setShowSidebar(true);
        setActiveEvent(event);
    };

    return (
        <div className={"space-y-6"}>
            <SidebarRight onClose={setShowSidebar} isOpen={showSidebar}>
                <MeetingDetails event={activeEvent} />
            </SidebarRight>

            <MeetingHeader title={"All Upcoming Meetings"} />

            {isLoading ? (
                <Loading />
            ) : (
                <div className={"space-y-4"}>
                    {events.map((meeting, index) => (
                        <EventListItem
                            key={index}
                            meeting={meeting}
                            type={"completed"}
                            onClick={handleOnClickEvent}
                            borderTopColor={"#70CCBF"}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};
export default EventListView;
