import React, {Fragment, useEffect, useState} from "react";
import UserTableHeader from "./user-table-header";
import UserListItem from "./user-list-item";
import {useUsers} from "../../../../hooks";
import Button from "../../../../components/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {SearchIcon} from "@heroicons/react/outline";

const UserList = () => {
    const {users = [], isLoading, setShowUserForm} = useUsers();
    const [userList, setUserList] = useState([]);
    const [filterUsers, setFilterUsers] = useState([]);

    useEffect(() => {
        if (users.length) {
            setUserList(users);
            setFilterUsers(users);
        }
    }, [users]);

    if (isLoading) return <p>loading</p>

    const handleSearch = ({target}) => {
        const searchKeyword = target.value.toLowerCase();
        setFilterUsers(userList.filter((user) => {
            return user?.fullName?.toLowerCase().includes(searchKeyword);
        }))
    }

    return (
        <Fragment>
            <header className={' pt-5 backdrop-blur-lg sticky top-0'}>
                <div
                    className={'bg-white/80  backdrop-blur-lg  items-between border-b-[1.5px] border-[#E5E5E5] px-5 py-3'}>
                    <h3 className={'font-medium text-dark text-lg'}>User List</h3>
                    <div className={'flex items-center space-x-2'}>
                        <div className={` relative `}>
                            <input
                                onChange={handleSearch}
                                className={`w-full rounded-md tracking-wide outline-none focus:border-accent
                                       focus:border-[1.7px] focus:ring-4 enable-transition focus:ring-[#5D9EE9]/30  font-light text-sm pl-8 py-2
                                    `}
                                type="text" placeholder={'Search..'}/>
                            <div className={'absolute top-0 left-0 flex items-center pl-2 h-full'}>
                                <SearchIcon className={'h-5'}/>
                            </div>
                        </div>
                        <Button onClick={() => setShowUserForm(true)}
                                className={'bg-primary flex whitespace-nowrap space-x-1 items-center'}>
                            <FontAwesomeIcon icon={faPlus} className={'h-4 w-4'}/>
                            <span>Create User</span>
                        </Button>
                    </div>
                </div>
            </header>
            <div className={'bg-white rounded-lg'}>
                <UserTableHeader/>
                {filterUsers.map((user, index) => (
                    <UserListItem
                        user={user} key={user?._id}
                        index={index}
                    />
                ))}
            </div>
        </Fragment>
    )
}
export default UserList