import {CalenderIcon} from "svg";
import dayjs from "dayjs";
import React, {Fragment, useState} from "react";
import Button from "components/Button";
import {useOutletContext, useParams} from "react-router-dom";
import ProjectItem from "components/project-item";
import SearchBox from "components/search-box";
import CreateWorkspace from "../form/create-workspace";
import CreateProjectForm from "../form";

const WorkspaceDetails = () => {
    const [showProjectForm, setShowProjectForm] = useState(false);
    const [showWorkspaceForm, setShowWorkspaceForm] = useState(false);
    const {workspaceName} = useParams()

    const {
        getWorkspaceByName,
        isLoadingWorkspaces,
    } = useOutletContext()

    const handleSearchResult = () => {
        //...
    }
    const workspace = getWorkspaceByName(workspaceName);
    if (isLoadingWorkspaces) return "loading"
    return (
        <Fragment>
            <CreateWorkspace
                showForm={showWorkspaceForm}
                setShowForm={setShowWorkspaceForm}
                activeWorkspace={workspace}
            />
            <CreateProjectForm
                activeWorkspace={workspace}
                showForm={showProjectForm}
                setShowForm={setShowProjectForm}
            />
            <section className={'min-h-full border bg-white rounded-lg  py-8'}>
                <header className={'flex justify-between px-10'}>
                    <div className={'space-y-2'}>
                        <h1 className="text-3xl capitalize font-semibold">
                            {workspace.name}
                        </h1>
                        <div className={'flex items-center divide-x-[1px] divide-x-black/60'}>
                            <div className="flex pr-5 items-center space-x-1 text-black/60 pr-4">
                                <p className="">Created On: </p>
                                <p className="flex items-center space-x-2">
                                    <CalenderIcon className={"h-3 w-3"}/>
                                    <span>
                                {dayjs(workspace.createdAt).format("DD, MMMM YYYY")}
                            </span>
                                </p>
                            </div>
                            <p className={'space-x-2 text-black/60 pl-4'}>
                                <span className={''}>Total Projects:</span>
                                <span>{workspace.projects.length}</span>
                            </p>
                        </div>
                    </div>

                    <div className={'flex items-center space-x-20'}>
                        <SearchBox
                            searchContext={{
                                data: [],
                                searchFun: handleSearchResult,
                                searchProperty: "name"
                            }}

                            inputClass={'text-sm'}
                            wrapperClass={'h-9'}
                        />
                        <div className={'flex items-center space-x-3'}>
                            <Button onClick={() => setShowProjectForm(true)}>Add Project</Button>
                            <Button outline onClick={() => setShowWorkspaceForm(true)}>Edit</Button>
                        </div>
                    </div>
                </header>

                {/* projects */}
                <div>
                    {/* table header */}
                    <div
                        className="grid grid-cols-6 px-10 py-2.5 mt-5 text-sm font-semibold uppercase text-black/60 bg-secondary/10">
                        <div className="col-span-3 ">Projects</div>
                        <div className="flex justify-center">PIU</div>
                        <div className="flex justify-center">Created On</div>
                        <div className="flex justify-center"></div>
                    </div>

                    {/* table body */}
                    <div className="divide-y-[1px]">
                        {workspace.projects.map((project) => (
                            <ProjectItem project={project} key={project?._id}/>
                        ))}
                    </div>
                </div>

                {!workspace.projects.length && <div className={'h-96  center text-center text-black/60 '}>
                    <div>
                        <p>There is currently no project in this workspace;<br/> consider adding one.</p>
                    </div>
                </div>}

            </section>
        </Fragment>
    )
}
export default WorkspaceDetails;
