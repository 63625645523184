import {Fragment, useState} from "react";
import DepartmentCard from "./department-card";
import Button from "../../../components/Button";
import {PlusIcon} from "@heroicons/react/solid";
import DepartmentForm from "./form/department-form";
import useGlobalContext from "../../../hooks/useGlobalContext";
import {useMutation, useQuery} from "react-query";
import {deleteDepartment, getDepartments} from "../../../provider/api";

const DepartmentSettings = () => {
    const [showForm, setShowForm] = useState(false);
    const [departments, setDepartments] = useState([]);
    const [activeDepartment, setActiveDepartment] = useState({});
    const {projectDetails} = useGlobalContext();

    // fetch department from backend
    useQuery(['departments', projectDetails?.piu], getDepartments, {
        enabled: !!projectDetails,
        onSuccess: ({data}) => {
            setDepartments(data);
        }
    })

    const {mutate: _deleteDepartment} = useMutation('delete-department', deleteDepartment, {
        onSuccess: data => {
            console.log('⛓ deleted successfully', data)
        }
    })

    const handleDelete = (dpId) => {
        // remove from ui
        setDepartments([...departments.filter(({_id}) => _id !== dpId)])

        // remove from database
        _deleteDepartment({dpId})

    }

    return (
        <Fragment>
            <DepartmentForm
                activeDepartment={activeDepartment}
                setActiveDepartment={setActiveDepartment}
                setShowForm={setShowForm}
                showForm={showForm}
            />
            <section className={'space-y-7 h-full overflow-y-scroll'}>
                <div className={'items-between'}>
                    <h2 className={'text-[#002F4F] font-medium text-lg '}>All Departments</h2>

                    <Button onClick={() => setShowForm(true)}
                            className={'flex border-[2px] border-secondary bg-secondary items-center space-x-2'}>
                        <span>Add Department</span>
                        <PlusIcon className={'h-5 w-5'}/>
                    </Button>
                </div>
                <div className={'space-y-4'}>
                    {departments.map((department) => (
                        <DepartmentCard
                            setActiveDepartment={setActiveDepartment}
                            setShowForm={setShowForm}
                            onDelete={handleDelete}
                            key={department.id}
                            department={department}
                        />
                    ))}
                </div>
            </section>
        </Fragment>
    )
}
export default DepartmentSettings;