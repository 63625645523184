import {Navigate} from "react-router-dom";
import {hasAnyPermission, permissions} from "provider/permission";

const Home = () => {
    if (hasAnyPermission(permissions.READ_STAKEHOLDERS)) {
        return <Navigate to={'/stakeholders'} replace={true}/>
    } else if (hasAnyPermission(permissions.READ_ENGAGEMENT_PLANS)) {
        return <Navigate to={'/engagement'} replace={true}/>
    } else if (hasAnyPermission([permissions.READ_PROJECT_EVENTS, permissions.UPDATE_PROJECT_EVENTS])) {
        return <Navigate to={'/engagement/activities'} replace={true}/>
    } else if (hasAnyPermission(permissions.READ_COMMITMENTS)) {
        return <Navigate to={'/commitments'} replace={true}/>
    } else if (hasAnyPermission(permissions.READ_GRIEVANCES)) {
        return <Navigate to={'/grievances'} replace={true}/>
    } else if (hasAnyPermission(permissions.MANAGE_PROJECTS_AND_PIUS)) {
        return <Navigate to={'/settings/users'} replace={true}/>
    }
}
export default Home;
