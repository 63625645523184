import EqualizerIcon from "../_partials/equalizer-icon";
import { SearchIcon } from "@heroicons/react/outline";

const SearchBox = ({ onChange: handleOnChnage }) => {
    return (
        <div
            className={
                "flex sticky  bg-[#F8FAFB] top-0 h justify-between border-b-[1px] py-5 border-[#E4E6EA] items-center px-3 gap-x-3"
            }
        >
            <div
                className={
                    "border-[1px] border-[#638798] flex items-center space-x-1" +
                    " rounded-full overflow-hidden text-[#628799] px-4 py-1.5"
                }
            >
                <SearchIcon className={"h-5 w-5"} />
                <input
                    type="text"
                    onChange={handleOnChnage}
                    className={
                        "h-full font-light bg-transparent p-0 border-none focus:ring-0  text-sm w-full outline-none placeholder-[#628799]"
                    }
                    placeholder={"Search list"}
                />
            </div>
            <div>
                <div
                    className={
                        "h-8 w-8 relative group bg-accent overflow-hidden rounded-full text-white"
                    }
                >
                    <div
                        className={
                            "absolute inset-0 flex justify-center items-center"
                        }
                    >
                        <EqualizerIcon />
                    </div>
                </div>
            </div>
        </div>
    );
};
export default SearchBox;
