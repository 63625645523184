import { createContext, useContext, useState } from "react";

export const FormContext = createContext(null);
const FormProvider = ({ children }) => {
    const [stakeholderDetails, setStakeholderDetails] = useState({
        // list holder basic details fields
        basicDetails: {
            type: "",
            scope: "",
            name: "",
            gender: "",
            disability: "",
            phoneNumber: "",
            email: "",
            title: "",
            logo: "",
            organisation: "",
            websiteLink: "",
            description: "",
            role: "",
            address: {
                country: "",
                region: "",
                district: "",
                street: "",
                postalCode: "",
            },
            project: "",
            sectors: [],
            company: {
                name: "",
                logo: "",
                websiteLink: "",
                email: "",
                phoneNumber: "",
                stakeholderPosition: "",
            },
            location: {
                latitude: "",
                longitude: 39.273301,
                name: "",
            },
        },

        // contact personal  fields
        contactPersonal: {
            name: "",
            gender: "",
            disability: "",
            position: "",
            title: "",
            description: "",
            organisation: "",
            phoneNumber: "",
            email: "",
            address: "",
            communicationMedia: [],
        },

        relationshipHolder: {
            name: "",
            gender: "",
            phoneNumber: "",
            email: "",
            position: "",
            disability: "",
            title: "",
            organisation: "",
            description: "",
            address: "",
            communicationMedia: [],
        },
        levelOfInterest: "",
        levelOfInfluence: "",
        goals: [],
        concern: "",
    });
    return (
        <FormContext.Provider
            value={[stakeholderDetails, setStakeholderDetails]}
        >
            {children}
        </FormContext.Provider>
    );
};
export default FormProvider;

export const useFormContext = () => useContext(FormContext);
