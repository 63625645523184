import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { usePermissionContext } from "../form/permission-form";

const PermissionItem = ({ permission }, ...props) => {
    const {
        permissions,
        setPermissions,
        removedPermission,
        setAddedPermission,
        addedPermission,
        setRemovedPermission,
    } = usePermissionContext();

    const handleRemove = () => {
        setPermissions(
            permissions.filter((permission_) => {
                return permission_?.displayName !== permission?.displayName;
            })
        );

        /// Remove permission from added list
        setAddedPermission(
            addedPermission.filter((permission_) => {
                return permission_?.displayName !== permission?.displayName;
            })
        );

        // Add permission to removed list
        setRemovedPermission([...removedPermission, permission]);
    };
    return (
        <span
            {...props}
            className={
                "text-xs bg-[#f4f5f5] border-[1.4px] border-light-gray inline-flex items-center space-x-1 pl-3 pr-2 py-2 rounded-full"
            }
        >
            <span> {permission?.displayName}</span>
            <button
                onClick={handleRemove}
                className={
                    " relative -mr-3 text-white px-1 hover:bg-primary/80 h-4 w-4 center rounded-full bg-primary/40"
                }
            >
                <FontAwesomeIcon className={"h-3 w-3"} icon={faXmark} />
            </button>
        </span>
    );
};

PermissionItem.propTypes = {
    permission: PropTypes.object.isRequired,
};
export default PermissionItem;
