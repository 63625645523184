import { useState, useRef } from "react";
import { useQueryClient, useMutation } from "react-query";
import Header from "./header";
import { createGrievances } from "provider/api";
import GrievancesDetails from "./grievance-details";
import DndContainer from "./dnd-container";
import GrievancesProvider from "../../../provider/general/grievances";
import GrievanceForm from "./form";

const GrievancePage = () => {
	const [showForm, setShowForm] = useState(false);
	const [detailsOpen, setDetailsOpen] = useState(false);
	const [activeGrievance, setActiveGrievance] = useState({});
	const [isGBV, setIsGBV] = useState(false);
	const queryClient = useQueryClient();
	const formRef = useRef(null);

	const updateCache = (grievance) => {
		return queryClient.setQueriesData(
			"project-grievances",
			(oldQueryData) => {
				return {
					...oldQueryData,
					data: [grievance, ...oldQueryData.data],
				};
			}
		);
	};

	//  send post request to create new grievance
	const { mutate: _createGrievance, isLoading: isCreating } = useMutation(
		"create-grievance",
		createGrievances,
		{
			onSuccess: ({ data: grievance }) => {
				updateCache(grievance);
				formRef.current.closeForm(); // call function from child component
			},
		}
	);

	const handleSubmit = (grievanceData) => {
		_createGrievance(grievanceData);
	};

	return (
		<GrievancesProvider>
			<GrievanceForm
				ref={formRef}
				isGBV={isGBV}
				isCreating={isCreating}
				onSubmit={handleSubmit}
				showForm={showForm}
				setShowForm={setShowForm}
			/>
			<GrievancesDetails
				isOpen={detailsOpen}
				setIsOpen={setDetailsOpen}
				grievance={activeGrievance}
			/>

			<div className="bg-[#EBF0F3] w-full pl-5 pb-10 pr-10 h-screen">
				<Header setIsOpen={setShowForm} setIsGBV={setIsGBV} />
				<DndContainer
					activeGrievance={activeGrievance}
					setActiveGrievance={setActiveGrievance}
					setDetailsOpen={setDetailsOpen}
				/>
			</div>
		</GrievancesProvider>
	);
};

export default GrievancePage;
