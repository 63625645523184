import React from "react";

const Loading = () => {
	return (
		<div className="space-y-8">
			{[...Array(10)].map((item, index) => (
				<div className="flex items-center space-x-2">
					<div className="w-full h-6 rounded-lg skeleton-loading"></div>
					<div className="w-4 h-4 rounded-full skeleton-loading shrink-0"></div>
				</div>
			))}
		</div>
	);
};

export default Loading;
