// import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
// import { faPlus } from "@fortawesome/free-solid-svg-icons";
import EventButton from "../../../../../../components/calender/event-button";
import {Can} from "provider/permission";

const MeetingHeader = ({title, oClick}) => {
    return (
        <header className={'flex justify-between items-center border-b-[1.4px] border-[#E2E2E2] pt-4 pb-6'}>
            <p className={'text-[#002F4F] text-lg font-medium'}>{title}</p>

            <Can permission={'create-projectEvents'}>
                <EventButton/>
            </Can>

            {/* <button
                onClick={oClick}
                className={'border-[1.5px] flex items-center space-x-2 text-accent border-accent rounded-[3px] px-4 py-[.7rem] text-xs'}>
                <p> CREATE MEETING</p>
                <FontAwesomeIcon icon={faPlus} className={'h-4 w-4'}/>
            </button> */}
        </header>
    )
}
export default MeetingHeader;