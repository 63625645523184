import EngagementActivityRate from "./activity-rate";
import MostEngagingStakeholder from "./engaging-stakeholder";
import StakeholderEngagementByGroup from "./stakeholder-group";
import StakeholderEngagementLevel from "./engagement-level";
import EngagementSummary from "./engagement-summary";
import EngagementAnalyticsProvider from "../../../provider/analytics/engagement";

const EngagementAnalytics = () => {

    return (
        <EngagementAnalyticsProvider>
            <section className={'p-10 h-full bg-[#F9F9F9] overflow-y-auto '}>
                <div className={'space-y-6'} style={{width: "calc(100%  - 335px)"}}>
                    <EngagementSummary/>
                    <EngagementActivityRate/>
                    <StakeholderEngagementLevel/>
                    <StakeholderEngagementByGroup/>
                </div>
                <MostEngagingStakeholder/>
            </section>
        </EngagementAnalyticsProvider>


    )
}
export default EngagementAnalytics;