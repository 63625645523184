import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/outline";
import { useMutation } from "react-query";

import { getMonth } from "../../utils";
import TimePicker from "./time-picker";

import {
    getValueFromLocalStorage,
    saveMultiValueToLocalStorage,
    saveValueToLocalStorage,
} from "../../hooks/useLocalStorageState";
import Month from "./month";
import { updateProjectEvent } from "../../provider/api";
import { localStorageKeys } from "../../constants";
import ActionButtons from "../../pages/general/engagement/activity/events/form/meeting/steps/_partials/action-buttons";

const Datetime = () => {
    const navigate = useNavigate();
    const [setCurrStep, toast] = useOutletContext();
    const { meetingForm: meetingLSKeys } = localStorageKeys;

    const meetingBasicInfo = getValueFromLocalStorage(
        meetingLSKeys.BASIC_DETAILS,
        {}
    );

    const initialTime = {
        hour: dayjs().format("HH"),
        minutes: dayjs().format("mm"),
    };

    const meetingTime = getValueFromLocalStorage(
        meetingLSKeys.MEETING_TIME,
        {}
    );

    const {
        startingTime: startTime,
        endingTime: endTime,
        eventDate: eventDay,
    } = meetingTime;

    const initialError = { name: "", message: "" };
    const [eventDate, setEventDate] = useState(eventDay);
    const [formError, setFormError] = useState(initialError);
    const [currentMonth, setCurrentMonth] = useState(getMonth());
    const [monthIndex, setMonthIndex] = useState(dayjs().month());
    const [endingTime, setEndingTime] = useState(
        endTime?.hour ? endTime : initialTime
    );
    const [startingTime, setStartingTime] = useState(
        startTime?.hour ? startTime : initialTime
    );
    const isEditing = getValueFromLocalStorage(
        meetingLSKeys.IS_UPDATING_DETAILS,
        false
    );

    const handleBack = async () => {
        setCurrStep(2);
        await saveValueToLocalStorage(meetingLSKeys.CURRENT_STEP, 2);
        navigate("/event-registration/meeting/add-participants");
    };

    const clearFormError = () => {
        setFormError(initialError);
    };

    useEffect(() => {
        setCurrentMonth(getMonth(monthIndex));
    }, [monthIndex]);

    const handlePrevMonth = () => {
        return setMonthIndex(monthIndex - 1);
    };

    const handleNextMonth = () => {
        return setMonthIndex(monthIndex + 1);
    };

    const handleDayChanges = (day) => {
        clearFormError();
        setEventDate(day);
    };

    const validateMeetingTime = () => {
        if (!eventDate) {
            setFormError({
                name: "eventDate",
                message: "EventLayout date is required!",
            });
            return 0;
        } else if (!startingTime.hour || startingTime.hour.includes("-")) {
            setFormError({
                name: "startingTime",
                message: "Please provide hour for starting time",
            });

            return 0;
        } else if (
            !startingTime.minutes ||
            startingTime.minutes.includes("-")
        ) {
            setFormError({
                name: "startingTime",
                message: "Please provide minutes for starting time",
            });
        } else if (!endingTime.hour || endingTime.hour.includes("-")) {
            setFormError({
                name: "endingTime",
                message: "Please provide hour for ending time",
            });
        } else if (!endingTime.minutes || endingTime.minutes.includes("-")) {
            setFormError({
                name: "endingTime",
                message: "Please provide minutes for ending time",
            });

            return 0;
        }

        clearFormError();
        return 1;
    };

    const handleNextStep = async (e) => {
        e.preventDefault();
        const isFormValid = validateMeetingTime();
        if (!isFormValid) return;

        if (isEditing) return buildPayloadAndUpdateEventDetails();
        await saveEventDateTimeInfoAndAdvanceToNextStep();
    };

    const { mutate: updateEvent, isLoading } = useMutation(updateProjectEvent, {
        onSuccess: async () =>
            await saveEventDateTimeInfoAndAdvanceToNextStep(),
        onError: ({ message }) => toast.error(message),
    });

    const buildPayloadAndUpdateEventDetails = () => {
        const payload = {
            ...meetingBasicInfo,
            eventDate,
            startingTime,
            endingTime,
        };
        updateEvent(payload);
    };

    const saveEventDateTimeInfoAndAdvanceToNextStep = async () => {
        setCurrStep(4);
        await saveMultiValueToLocalStorage({
            [meetingLSKeys.MEETING_TIME]: {
                eventDate,
                startingTime,
                endingTime,
            },
            [meetingLSKeys.CURRENT_STEP]: 4,
        });
        toast.success("Data has been saved successfully");
        navigate("/event-registration/meeting/communication-channel");
    };

    return (
        <>
            <div className={"h-[28rem] flex "}>
                <div className={"h-full datetime col-2 w-[50%]"}>
                    <div
                        className={
                            "flex h-16 items-center text-dark py-4 justify-center"
                        }
                    >
                        <ArrowLeftIcon
                            onClick={handlePrevMonth}
                            className={"h-5 w-5 cursor-pointer opacity-40"}
                        />
                        <div className={"w-32 text-center font-semibold "}>
                            {dayjs(new Date(dayjs().year(), monthIndex)).format(
                                "MMMM, YYYY"
                            )}
                        </div>
                        <ArrowRightIcon
                            onClick={handleNextMonth}
                            className={"h-5 w-5 cursor-pointer opacity-40"}
                        />
                    </div>

                    <div
                        className={
                            "datetime-wrapper overflow-hidden bg-green-500/10"
                        }
                    >
                        <Month
                            showBorder={false}
                            month={currentMonth}
                            daysClass={"text-base"}
                            selectedDay={eventDate}
                            className={"border-t-0"}
                            clickHandler={handleDayChanges}
                            daysHeaderClass={"bg-black/30 text-white"}
                        />
                    </div>
                </div>

                <div
                    className={
                        "w-[45%] bg-white border-y-[1.5px] border-[#E4E4E4] border-r-[1.5px]"
                    }
                >
                    <div
                        className={
                            "bg-[#F5F6F7] border-b-[1.5px] border-[#E4E4E4] py-4 px-5 space-y-1 pt-5"
                        }
                    >
                        <div className="flex">
                            <p className={"text-3xl font-light text-accent"}>
                                {startingTime?.hour}:{startingTime?.minutes}
                            </p>
                            <p className="mx-5 text-3xl text-accent">To</p>
                            <p className={"text-3xl font-light text-accent"}>
                                {endingTime?.hour}:{endingTime?.minutes}
                            </p>
                        </div>
                        <p className={"font-light text-[15px]"}>
                            {/* Thursday,{" "} */}
                            <span className={"font-medium text-dark"}>
                                {eventDate?.$d
                                    ? eventDate?.$d.toDateString()
                                    : new Date(eventDate).toDateString()}
                            </span>
                        </p>
                    </div>

                    <div className={"pl-10 mt-3 space-y-8"}>
                        <div className={"space-y-2"}>
                            <p className={"text-[#BDBDBD] text-sm"}>
                                Starting Time
                            </p>
                            <TimePicker
                                setTime={setStartingTime}
                                currentTime={startingTime}
                                clearErrors={clearFormError}
                            />
                        </div>

                        <div className={" space-y-2"}>
                            <p className={"text-[#BDBDBD] text-sm"}>
                                Ending Time
                            </p>
                            <TimePicker
                                setTime={setEndingTime}
                                currentTime={endingTime}
                                clearErrors={clearFormError}
                            />
                        </div>
                        <p className="text-red-600 text-sm">
                            {formError.message}
                        </p>
                    </div>
                </div>
            </div>

            <form onSubmit={handleNextStep} className={"mr-24 "}>
                <ActionButtons
                    text={"Next"}
                    onCancel={handleBack}
                    isEditing={isEditing}
                    isLoading={isLoading}
                    onSave={() => {}}
                />
            </form>
        </>
    );
};
export default Datetime;
