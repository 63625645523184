import { useNavigate, useParams } from "react-router-dom";
import { ArrowRightIcon, DocumentTextIcon } from "@heroicons/react/outline";

import { CalenderAltIcon as CalenderIcon } from "svg";
import { Can } from "provider/permission";

const EventListItem = ({ key, onClick: handleClick, meeting: event }) => {
    const navigate = useNavigate();
    const { type } = useParams();
    const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

    const d = new Date(event.eventDate);
    let day = weekdays[d.getDay()];
    let date = d.getDate();

    const formatTime = (timeInfo) => {
        if (!timeInfo?.hour) return "--";
        return `${timeInfo?.hour}:${timeInfo?.minutes} ${timeInfo?.mode}`;
    };

    const formatDate = (dateInfo) => {
        return new Date(dateInfo).toDateString();
    };

    const viewReport = (id) => {
        navigate(`/engagement/activities/meetings/report/${id}`);
    };

    const cardTheme = {
        today: {
            borderTop: "border-t-[#628799]",
            text: "text-[#628799]",
            dateFill: "bg-[#628799]/10",
        },
        upcoming: {
            borderTop: "border-t-[#009EE2]",
            text: "text-[#009EE2]",
            dateFill: "bg-[#009EE2]/10",
        },
        completed: {
            borderTop: "border-t-[#70CCBF]",
            text: "text-[#70CCBF]",
            dateFill: "bg-[#70CCBF]/10",
        },
        overdue: {
            borderTop: "border-t-[#E8716F]",
            text: "text-[#E8716F]",
            dateFill: "bg-[#E8716F]/10",
        },
    };

    const color = cardTheme[type];
    return (
        <div
            key={key}
            className={`h-40 rounded-[5px] flex items-center px-10 justify-between border-t-[4px] border-[1px] border-accent1 ${color.borderTop}`}
        >
            <div className={"flex items-center space-x-8"}>
                <div
                    className={`${color.dateFill} ${color.text}  h-20 px-4 leading-4 rounded-[1.5rem] flex justify-center items-center flex-col`}
                >
                    <p className={"font-light "}>{day}</p>
                    <p className={"f text-2xl"}>{date}</p>
                </div>
                <div className={"space-y-2 font-light"}>
                    <p className={` text-lg ${color.text} `}>{event?.title}</p>
                    <div className={"space-y-2"}>
                        {/*                         <p className={'text-dark text-[15px] '}>Description: <span className={'text-[#828282]'}>{meeting.description}</span>
                        </p> */}
                        <div
                            className={
                                "text-[#AAB6C5] text-[13px] grid grid-cols-3"
                            }
                        >
                            <p
                                className={
                                    "border-r-[#AAB6C5] border-r-[1px] pr-3"
                                }
                            >
                                {formatDate(event.eventDate)}
                            </p>
                            <p className={"px-2"}>
                                {formatTime(event.startingTime)} -{" "}
                                {formatTime(event.endingTime)}
                            </p>
                            <p
                                className={
                                    "border-l-[#AAB6C5] border-l-[1px] pl-3"
                                }
                            >
                                {event.channelType}
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            {type === "upcoming" && (
                <div
                    onClick={() => handleClick(event)}
                    className={`flex items-center cursor-pointer hover:underline ${color.text} space-x-3 font-light italic text-[15px]`}
                >
                    <p>View Meeting</p>
                    <ArrowRightIcon className={"h-5 "} />
                </div>
            )}

            {type === "overdue" && (
                <div className={"space-y-2 text-center"}>
                    <Can permission={"update-projectEvents"}>
                        <button
                            className={
                                "border-[1.5px] flex items-center space-x-2 bg-[#F6F9FE] text-accent border-[#DFE1E5] rounded-[3px] px-3 py-2 text-xs"
                            }
                        >
                            <CalenderIcon
                                className={"h-4 w-4"}
                                color={"#E8716F"}
                            />
                            <p className={"text-[#E8716F]"}>Reschedule</p>
                        </button>
                    </Can>

                    <p
                        onClick={() => handleClick(event)}
                        className={
                            "text-[#AAB6C5] tracking-wide cursor-pointer text-sm font-light"
                        }
                    >
                        View Event
                    </p>
                </div>
            )}
            {type === "completed" && (
                <Can permission={"read-meetingReport"}>
                    <div className={"space-y-2 text-center"}>
                        <button
                            onClick={() => viewReport(event._id)}
                            className={
                                "border-[1.5px] flex items-center space-x-2 bg-[#F6F9FE] text-accent border-[#DFE1E5] rounded-[3px] px-3 py-2 text-xs"
                            }
                        >
                            <DocumentTextIcon
                                className={"h-4 w-4"}
                                color={"#70CCBF"}
                            />
                            <p className={"text-[#70CCBF]"}>View Report</p>
                        </button>
                    </div>
                </Can>
            )}
        </div>
    );
};
export default EventListItem;
