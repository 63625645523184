const Loading = () => {
    return (
        <div className={'space-y-2 shrink-0 w-72 w-full'}>
            <div className={'h-40  flex flex-col justify-center space-y-6 p-5 bg-white rounded'}>
                <div className={'items-between'}>
                    <div className={'h-12 w-12 rounded skeleton-loading '}></div>
                    <div className={'h-10 w-10 rounded-full skeleton-loading '}></div>
                </div>

                <div className={'items-between'}>
                    <div className={'space-y-2'}>
                        <p className={'h-2 w-24 rounded-full skeleton-loading'}></p>
                        <p className={'h-2 w-44 rounded-full skeleton-loading'}></p>
                    </div>
                    <div className={'h-10 w-10 rounded skeleton-loading '}></div>
                </div>
            </div>

            <div className={'bg-white flex flex-col justify-between h-[calc(100%_-_10.5rem)] px-7 py-5 rounded'}>
                <div className={'space-y-4'}>
                    <div className={'w-14 h-14 skeleton-loading rounded'}></div>
                    <div className={'space-y-2'}>
                        <p className={'h-2 w-44 rounded-full skeleton-loading'}></p>
                        <p className={'h-2 w-16 rounded-full skeleton-loading'}></p>
                    </div>
                </div>
                <div className={"flex items-center space-x-3"}>
                    <div className={'h-10 w-8 rounded skeleton-loading'}></div>
                    <div className={'space-y-3'}>
                        <p className={'h-2 w-10 rounded-full skeleton-loading'}></p>
                        <p className={'h-2 w-16 rounded-full skeleton-loading'}></p>
                    </div>
                </div>

                <div className={'space-y-2'}>
                    <div className={"items-between"}>
                        <p className={'h-2 w-20 rounded-full skeleton-loading'}></p>
                        <p className={'h-2 w-10 rounded-full skeleton-loading'}></p>
                       
                    </div>
                    <div className={'h-3 w-full rounded-full  skeleton-loading'}></div>
                </div>
            </div>
        </div>
    )
}
export default Loading;