import { useNavigate } from "react-router-dom";
import React from "react";
import {
	ChevronLeftIcon,
	SearchIcon,
	ViewGridIcon,
} from "@heroicons/react/outline";
import { ListBulletIcon } from "../../../../svg";
import { saveValueToLocalStorage } from "../../../../hooks/useLocalStorageState";
import { useProjectFiles } from "../../../../hooks";

const FolderHeader = ({ onSearch: handleChange, currPath }) => {
	const { showListView, setShowListView } = useProjectFiles();
	const navigate = useNavigate();

	const handleGoBack = () => {
		navigate(-1);
	};

	const handleGoNext = () => {
		navigate(1);
	};

	const enableGridView = () => {
		setShowListView(false);
		return saveValueToLocalStorage("showFilesInList", false);
	};

	const enableListView = () => {
		setShowListView(true);
		return saveValueToLocalStorage("showFilesInList", true);
	};

	return (
		<header
			className={
				"bg-white/60  z-10 sticky  backdrop-blur-lg px-8 grid grid-cols-6 " +
				"gap-2 top-0 border-b-[1.5px] border-b-[#E4E6EA] h-12"
			}
		>
			<div
				className={
					"col-span-3 space-x-2 pr-3 h-full flex items-center "
				}
			>
				<div className={"flex  items-center text-dark2"}>
					<button
						onClick={handleGoBack}
						className={"hover:bg-secondary/10 group rounded"}
					>
						<ChevronLeftIcon
							className={
								"h-6 w-6 group-hover:opacity-100 opacity-50"
							}
						/>
					</button>
					<button
						onClick={handleGoNext}
						className={"hover:bg-secondary/10 group rounded "}
					>
						<ChevronLeftIcon
							className={
								"-rotate-180 group-hover:opacity-100 opacity-50 h-6 w-6"
							}
						/>
					</button>
				</div>
				<p
					className={
						"font-medium capitalize text-dark2 whitespace-nowrap w-full truncate"
					}
				>
					{currPath}
				</p>
			</div>
			<div
				className={"h-full flex justify-center space-x-2 items-center"}
			>
				<button
					onClick={enableGridView}
					className={`h-7 w-8 rounded-md center ${
						showListView ? "bg-transparent" : "bg-secondary/20"
					}`}
				>
					<ViewGridIcon className={"h-5 w-5 text-dark2 opacity-80"} />
				</button>
				<button
					onClick={enableListView}
					className={`h-7 w-8 rounded-md center opacity-50 ${
						!showListView ? "bg-transparent" : "bg-secondary/20"
					}`}
				>
					<ListBulletIcon className={"h-6 w-6"} />
				</button>
			</div>
			<div className={"h-full col-span-2 flex items-center"}>
				<div className={` relative w-full`}>
					<input
						onChange={handleChange}
						className={` w-full rounded-md tracking-wide outline-none focus:border-accent
                    focus:border-[1.7px] border-dark2 opacity-80 focus:ring-2 enable-transition 
                    focus:ring-[#5D9EE9]/30  font-light text-sm pl-8 py-2.5
              `}
						type="text"
						placeholder={"Search.."}
					/>
					<div
						className={
							"absolute top-0 left-0 flex text-dark2/80 items-center pl-2 h-full"
						}
					>
						<SearchIcon className={"h-4"} />
					</div>
				</div>
			</div>
		</header>
	);
};
export default FolderHeader;
