import { removeValuesFromLocalStorage } from "../../../../../../hooks/useLocalStorageState";
import { localStorageKeys } from "../../../../../../constants";

const clearStakeholderForm = () => {
    const { stakeholderFrom: stakeholderLSKeys } = localStorageKeys;
    const keysToRemove = [
        stakeholderLSKeys.CURRENT_STEP,
        stakeholderLSKeys.BASIC_DETAILS,
        stakeholderLSKeys.IS_UPDATING_DETAILS,
        stakeholderLSKeys.CONTACT_PERSON,
        stakeholderLSKeys.RELATION_SHIP_HOLDER,
        stakeholderLSKeys.LEVEL_OF_INFLUENCE,
        stakeholderLSKeys.LEVEL_OF_INTEREST,
        stakeholderLSKeys.GOALS,
        stakeholderLSKeys.CONCERN,
    ];
    removeValuesFromLocalStorage(keysToRemove);
};
export default clearStakeholderForm;
