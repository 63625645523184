const StakeholderBreakdownStatistics = ({title, percentage, total}) => {
    return (
        <div className={'-space-y-1'}>
            <p className={'font-semibold text-[#002F4F] text-lg'}>{total}</p>
            <div className={'space-y-1'}>
                <p className={'items-between text-sm'}>
                    <span className={'text-secondary font-thin'}>{title}</span>
                    <span className={'text-[#838EAB] text-[13px] font-medium'}>{percentage}%</span>
                </p>
                <div className={'h-1.5 w-full bg-[#EDEFF7]  mr-2'}>
                    <div className={'h-full bg-[#838EAB] '}
                         style={{width: `${percentage}%`}}></div>
                </div>
            </div>
        </div>
    )
}
export default StakeholderBreakdownStatistics;