import React from "react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import StakeholderCard from "./stakeholder-card";
import Loading from "./loading";
import { useParams } from "react-router-dom";
import { slugify } from "utils";

const StakeholderScope = ({
	title,
	onClick: handleClick,
	isActive = false,
	stakeholders,
	setCurrStakeholder,
	isLoading,
	showTitle = true,
}) => {
	const { stakeholderName } = useParams();

	return (
		<div className={""}>
			{showTitle && (
				<div
					className={
						"py-3 sticky bg-[#F8FAFB] top-0 border-b-[1px] flex items-center justify-between px-3 border-[#E4E6EA]"
					}
				>
					<p
						onClick={handleClick}
						className={
							"text-[13px] cursor-pointer uppercase text-[#B3B3B3]"
						}
					>
						{title}
					</p>

					<ChevronDownIcon
						className={`h-5 text-[#B3B3B3] transition ease-in-out w-5 ${
							isActive ? "" : "-rotate-180"
						} `}
					/>
				</div>
			)}

			<div className={"px-3"}>
				{isLoading ? (
					<Loading />
				) : (
					<div
						className={`${
							isActive ? "inline" : "hidden"
						} space-y-3`}
					>
						{stakeholders.map((stakeholder) => (
							<StakeholderCard
								isActive={
									stakeholderName ===
									slugify(stakeholder?.name)
								}
								key={stakeholder?._id}
								stakeholder={stakeholder}
								setCurrStakeholder={setCurrStakeholder}
							/>
						))}
					</div>
				)}
			</div>
		</div>
	);
};

export default StakeholderScope;
