import Button from "components/Button";
import { PlusIcon } from "@heroicons/react/solid";
import { useNavigate } from "react-router-dom";

const PhaseHeader = () => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate("create");
    };

    return (
        <header
            className={"items-between bg-[#F7F9FA] sticky top-24 py-4 z-20"}
        >
            <h2 className={"text-[#002F4F] font-semibold text-lg "}>
                Project Phases
            </h2>

            <Button
                onClick={handleClick}
                className={
                    "flex border-[2px] border-secondary bg-secondary items-center space-x-2"
                }
            >
                <span>Add Phase</span>
                <PlusIcon className={"h-5 w-5"} />
            </Button>
        </header>
    );
};
export default PhaseHeader;
