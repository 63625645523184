import { Dialog, Transition } from "@headlessui/react";
import { createPortal } from "react-dom";
import PropTypes from "prop-types";

const portalRoot = document.getElementById("portal-root");

const Modal = ({ isOpen, onClose, children, dialogClass = "pt-[5vh]" }) => {
	if (!isOpen) {
		return null;
	}

	return createPortal(
		<Transition appear show={isOpen} as={"div"}>
			<Dialog
				onClose={onClose}
				className={`fixed inset-0 top-0 z-50 ${dialogClass}`} //p-5
			>
				<Transition.Child
					enter="duration-300 ease-out"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="duration-300 ease-in"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<Dialog.Overlay className="fixed inset-0 bg-[#192B40]/70" />
				</Transition.Child>

				<Transition.Child
					enter="ease-out duration-300"
					enterFrom="opacity-0 scale-50"
					enterTo="opacity-100 scale-100"
					leave="ease-in duration-300"
					leaveFrom="opacity-100 scale-100"
					leaveTo="opacity-0 scale-50"
				>
					<div className="w-auto center ">{children}</div>
				</Transition.Child>
			</Dialog>
		</Transition>,
		portalRoot
	);
};

Modal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
};
export default Modal;
