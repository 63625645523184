import ParticipantCard from "../participant-card";
import { ChevronLeftIcon } from "@heroicons/react/outline";
import SearchBox from "components/search-box";
import { useEffect, useState } from "react";
import useGlobalContext from "hooks/useGlobalContext";
import SidebarRight from "components/sidebar-right";
import Loading from "./loading";

const Participants = ({
    showParticipants,
    initialParticipants: participants,
    setShowParticipants,
    setParticipantList: setParticipants,
}) => {
    const [stakeholderList, setStakeholderList] = useState([]);
    const [filteredStakeholders, setFilteredStakeholders] = useState([]);
    const { stakeholder: stakeholders, isLoading } = useGlobalContext();

    useEffect(() => {
        if (stakeholders) {
            setStakeholderList(stakeholders);
            setFilteredStakeholders(stakeholders);
        }
    }, [stakeholders]);

    const manageParticipants = (person) => {
        const isSelected = participants.find((data) => data._id === person._id);

        if (isSelected) {
            const rest = participants.filter((item) => item._id !== person._id);

            setParticipants(rest);
        } else setParticipants([...participants, person]);
    };

    // const handleSubmit = () => {
    // setParticipantList(participants);
    // setShowParticipants(false);
    // setParticipants([]);
    // };

    const checkSelection = (id) => {
        return !!participants.find((item) => item._id === id);
    };

    return (
        <SidebarRight
            className={"w-[400px]"}
            onClose={setShowParticipants}
            isOpen={showParticipants}
        >
            <div
                className={
                    "h-[94%] flex flex-col justify-between w-full bg-[#F5F7F9]  p-6 space-y-3"
                }
            >
                <div className={"add-participant-wrapper "}>
                    <div className={"h-36 "}>
                        {/* close sidebar button  */}
                        <button onClick={() => setShowParticipants(false)}>
                            <ChevronLeftIcon
                                className={"h-6 w-6 text-[#96BFED]"}
                            />
                        </button>

                        {/* search box */}
                        <div className={"pl-2 space-y-2"}>
                            <p className={"text-lg text-dark font-medium"}>
                                Select Participants
                            </p>
                            <SearchBox
                                searchContext={{
                                    data: stakeholderList,
                                    searchFun: setFilteredStakeholders,
                                    searchProperty: "name",
                                }}
                                wrapperClass={"text-[#202842]/50"}
                                inputClass={
                                    "border-[#DCDFF1] pl-10 rounded-[5px]"
                                }
                            />
                        </div>
                    </div>

                    <div
                        style={{ height: "calc(100% - 7rem)" }}
                        className={"pl-2 pt-2 pb-5 overflow-y-auto "}
                    >
                        {isLoading ? (
                            <Loading />
                        ) : stakeholderList.length > 0 ? (
                            filteredStakeholders.map((participant) => (
                                <ParticipantCard
                                    key={participant._id}
                                    participant={participant}
                                    onSelect={() => {
                                        manageParticipants(participant);
                                    }}
                                    isSelected={() =>
                                        checkSelection(participant._id)
                                    }
                                />
                            ))
                        ) : (
                            <div
                                className={
                                    "h-full text-sm text-[#3E3D3D] font-light center "
                                }
                            >
                                <p>Not Found</p>
                            </div>
                        )}
                    </div>
                </div>

                {/*<div className={"pl-2 h-16 bg-[#F5F7F9] pt-2 "}>*/}
                {/*    <button*/}
                {/*        onClick={handleSubmit}*/}
                {/*        className={*/}
                {/*            "" +*/}
                {/*            "uppercase hover:opacity-90 rounded-[3px] " +*/}
                {/*            "bg-accent text-xs w-full py-2.5" +*/}
                {/*            " border-[1.5px] px-4 border-accent" +*/}
                {/*            " text-white"*/}
                {/*        }*/}
                {/*    >*/}
                {/*        ADD*/}
                {/*    </button>*/}
                {/*</div>*/}
            </div>
        </SidebarRight>
    );
};

export default Participants;
