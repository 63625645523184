import InputCard from "../_partials/input-card";

const Attendance = () => {
    return (
        <InputCard title={'Registered Stakeholders Attendance'} defaultValue={true}>
            <div className={'bg-[#FFFFFF] p-3'}>
                <div className={'border-t border-t-[#C3CBD9] py-5 px-2 '}>
                    <div className={'w-full space-y-3'}>
                        <p className={'text-[#707070]/70 text-sm'}>
                            All registered stakeholders who attended the meeting will
                            be recorded on the right side. After taking the attendance
                            of the registered stakeholder on the right, you may mark
                            this step as complete by checking the checkbox above.
                        </p>
                    </div>
                </div>
            </div>
        </InputCard>
    )
}
export default Attendance;