import {Draggable} from "react-beautiful-dnd";
import {CalendarIcon, ClockIcon} from "@heroicons/react/outline";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowsRotate, faMaximize, faTag, faTrashCan} from "@fortawesome/free-solid-svg-icons";
import dayjs from "dayjs";
import {CreateMarkup} from "../../../utils";
import {BadgeCheckIcon} from "@heroicons/react/solid";

const DraggableGrievanceCard = ({
                                    changeStatus,
                                    handleDelete,
                                    grievance,
                                    borderColor,
                                    iconColor,
                                    index,
                                    prefix,
                                    setActiveGrievance,
                                    setDetailsOpen,
                                }) => {
    const _id = grievance?._id;

    const formatDate = (date) => {
        return dayjs(date).format("MMM DD, YYYY");
    };

    const formatTime = (date) => {
        return dayjs(date).format("hh: mm a");
    };

    const getRiskClassName = () => {
        switch (grievance?.projectRisk) {
            case "Low": {
                return "bg-blue-500/20 text-blue-700 border border-blue-500/40";
            }
            case "Medium": {
                return "bg-orange-500/20 text-orange-700 border border-orange-500/40";
            }
            case "High": {
                return "bg-red-500/20 text-red-700 border border-red-500/40";
            }
            default:
                return "";
        }
    };

    console.log(borderColor);
    return (
        <Draggable draggableId={grievance?._id} index={index}>
            {(provided, snapshot) => {
                return (
                    <div
                        className={`mx-3 my-2 ${
                            grievance?.isGBV
                                ? "border-primary/90 border-[1.5px]"
                                : "border-[#E4E4E4] border-[1px]"
                        } p-3 h-auto relative overflow-hidden  w-[90%] shadow hover:transition-transform hover:translate-x-1 hover:-translate-y-1 hover:shadow-lg bg-[#FFFFFF]/70 space-y-4 text-sm rounded-md `}
                        ref={provided.innerRef}
                        snapshot={snapshot}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                    >
                        <div className={"items-between text-xs"}>
                            <div className={"flex items-center space-x-2"}>
                                <CalendarIcon className={`h-4 ${iconColor}`}/>
                                <p className={"text-[#595959] "}>
                                    {formatDate(grievance?.dateCreated) ||
                                        "N/A"}
                                </p>
                            </div>

                            <div className={"flex items-center space-x-1"}>
                                <ClockIcon className={`h-4 ${iconColor}`}/>
                                <p className={"text-[#595959]"}>
                                    {formatTime(grievance?.createdAt) || "N/A"}
                                </p>
                            </div>
                        </div>

                        <div className={"text-xs space-y-2.5"}>
                            <p
                                className={
                                    "text-[#202842] text-sm text-justify leading-relaxed line-clamp-3"
                                }
                            >
                                {grievance?.title || "No title was provided"}
                            </p>

                            <p
                                className={
                                    "text-[#707070] font-light tracking-wide"
                                }
                            >
                                Complainer:{" "}
                                <span className={"text-secondary"}>
									{grievance?.complainer?.fullName || "N/A"}
								</span>
                            </p>

                            <p
                                dangerouslySetInnerHTML={CreateMarkup(
                                    grievance?.description ??
                                    '"No description provided"'
                                )}
                                className={
                                    "text-sm text-[#628799] line-clamp-3 font-light "
                                }
                            ></p>

                            <div className="flex justify-between">
                                <div className="flex items-center space-x-2">
                                    {grievance.status === 5 && (
                                        <p
                                            className="bg-red-500/20
											text-red-700 border
											border-red-500/40 rounded-full px-3
											py-1.5 flex items-center space-x-1"
                                        >
                                            <FontAwesomeIcon
                                                icon={faArrowsRotate}
                                                className={"h-3.5 w-3.5"}
                                            />
                                            <span>Reopened</span>
                                        </p>
                                    )}

                                    <p
                                        className={`${getRiskClassName()} rounded-full px-3 py-1.5 flex items-center space-x-1`}
                                    >
                                        <FontAwesomeIcon
                                            icon={faTag}
                                            className={"h-3.5 w-3.5"}
                                        />
                                        <span>{grievance?.projectRisk}</span>
                                    </p>
                                </div>
                                <div className="flex flex-row items-center space-x-4">
                                    <button
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            handleDelete(prefix, index, _id);
                                        }}
                                    >
                                        <FontAwesomeIcon
                                            icon={faTrashCan}
                                            className="h-[16px] cursor-pointer text-[#B0B0B0] hover:text-red-500 hover:scale-125 transition-all"
                                        />
                                    </button>

                                    <button
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            setActiveGrievance(grievance);
                                            setDetailsOpen(true);
                                        }}
                                    >
                                        <FontAwesomeIcon
                                            icon={faMaximize}
                                            className="h-[16px] cursor-pointer text-[#B0B0B0] hover:text-green-500 hover:scale-125 transition-all"
                                        />
                                    </button>
                                </div>
                            </div>

                            <div className={"pt-2"}>
                                <button
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        changeStatus(prefix, index, _id, 4); // Status 4 is for closed
                                    }}
                                    className={`${
                                        iconColor !== "text-[#51BF82]"
                                            ? "hidden"
                                            : "block"
                                    } transition-all cursor-pointer border-[1.3px] bg-[#E1F2E5] border-[#56BE84] w-full text-xs p-2 rounded-[1px] text-[#151C20]`}
                                >
                                    Close Card
                                </button>
                            </div>

                            <div className="hidden text-xs">
                                {grievance?._id}
                            </div>
                        </div>

                        {grievance?.investigatedOn ? (
                            <div
                                className="absolute flex justify-center w-32 h-10 pt-[2px] pl-14 text-primary -rotate-45 -bottom-8 -right-10 bg-primary/20">
                                <BadgeCheckIcon className="w-5 h-5 rotate-45"/>
                            </div>
                        ) : null}
                    </div>
                );
            }}
        </Draggable>
    );
};

export default DraggableGrievanceCard;
