const Loading = () => {
    return (
        <div className={"space-y-4"}>
            {[...Array(10)].map((item, index) => (
                <div
                    key={index}
                    className={"border p-3 rounded-md items-between"}
                >
                    <div className={"flex items-center w-full space-x-4"}>
                        <p className="h-20 px-4 shrink-0 rounded-[1.5rem] skeleton-loading">
                            <span className={"opacity-0"}>24</span>
                        </p>
                        <div className={"w-full space-y-4 "}>
                            <p className="h-4 rounded w-2/6 rounded skeleton-loading"></p>
                            <div className={"space-x-3 flex"}>
                                <p
                                    className={
                                        "h-4 rounded shrink-0 w-2/12 skeleton-loading rounded"
                                    }
                                ></p>
                                <p
                                    className={
                                        "h-4 shrink-0 rounded w-[28%] skeleton-loading rounded"
                                    }
                                ></p>
                                <p
                                    className={
                                        "h-4 shrink-0 rounded w-3/12 skeleton-loading rounded"
                                    }
                                ></p>
                            </div>
                        </div>
                    </div>
                    <div className={"shrink-0"}>
                        <p
                            className={"h-8 w-28 rounded-md skeleton-loading"}
                        ></p>
                    </div>
                </div>
            ))}
        </div>
    );
};
export default Loading;
