import {ExclamationCircleIcon} from "@heroicons/react/outline";

const Textarea = (
    {
        className, rows = 4,
        showResizer = "none",
        onChange: handleChange,
        isError = false,
        showErrorMessage = true,
        value,
        register = () => {
        }, name, defaultValue, placeholder

    }, ...props) => {
    return (
        <div>
            <div className={'relative'}>
                <textarea value={value} onChange={handleChange} defaultValue={defaultValue} {...register(name)}
                          style={{resize: showResizer}}
                          rows={rows} {...props} placeholder={placeholder}
                          className={` ${className} w-full text-gray-500 focus:border-[1.7px] focus:ring-4 enable-transition  placeholder-[#AAB6C5] 
                    focus:outline-none tracking-wider py-3 border-[1.4px] 
                    ${
                              isError
                                  ? "border-red-500 focus:ring-red-500/30 focus:border-red-500"
                                  : "border-[#D2D4DA] focus:border-accent focus:ring-[#5D9EE9]/30"
                          }
                     rounded-[5px]
                `}>

                </textarea>
                {isError ? (
                    <ExclamationCircleIcon
                        className={
                            "absolute right-2 top-0 h-5 w-5 text-red-500 bottom-0 my-auto"
                        }
                    />
                ) : null}
            </div>
            {isError && showErrorMessage ? (
                <p className={"text-red-500 pt-1 text-sm"}>
                    Please provide valid {name}
                </p>
            ) : null}
        </div>
    )
}
export default Textarea;
