import Input from "components/form-control/Input";
import {Fragment, useState} from "react";
import SpinLoader from "components/SpinLoader";
import {useUsers} from "hooks";
import {useForm} from "react-hook-form";
import useGlobalContext from "hooks/useGlobalContext";
import {
    getValueFromLocalStorage,
    removeValuesFromLocalStorage,
    saveValueToLocalStorage,
} from "hooks/useLocalStorageState";
import {useMutation} from "react-query";
import {createNewUser, updateUserInfo} from "provider/api";
import {flatten, isEmptyObject} from "utils";
import {ExclamationIcon} from "@heroicons/react/solid";
import {userType} from "../../../../provider/permission";
import useUpdateCache from "../../../../hooks/useUpdateCache";
import {cacheActions, localStorageKeys, queryKeys} from "../../../../constants";

const AddressInfo = () => {
    const {
        handleSubmit,
        register,
        formState: {errors},
    } = useForm();
    const {
        activeUser,
        setCurrentStep,
        setActiveUser,
        setShowUserForm,
        isCreatingAdminUser: isWBAdmin,
    } = useUsers();
    const addressInfo = getValueFromLocalStorage("userAddressInfo", {});
    const [addressObj, setAddressObj] = useState(addressInfo ?? {});
    const isEditMode = !isEmptyObject(activeUser);
    const {projectDetails} = useGlobalContext();
    const [errorMessage, setErrorMessage] = useState(null);
    const updateCache = useUpdateCache();

    // create user and update user cache
    const {isLoading, mutate: _createUser} = useMutation(
        queryKeys.CREATE_USER,
        createNewUser,
        {
            onSuccess: ({data: newUser}) => {
                console.log(newUser)
                updateCache(queryKeys.USERS, newUser, cacheActions.ADD)
                return handleClose();
            },
            onError: ({developerMessage}) => {
                setErrorMessage(developerMessage);
            },
        }
    );

    // update user information
    const {isLoading: isUpdating, mutate: updateUser} = useMutation(
        queryKeys.UPDATE_USER,
        updateUserInfo,
        {
            onSuccess: ({data: updatedUser}) => {
                updateCache(queryKeys.USERS, updatedUser, cacheActions.UPDATE)
                return handleClose();
            },
            onError: ({developerMessage}) => {
                setErrorMessage(developerMessage);
            },
        }
    );

    // called when a user form is closed
    const handleClose = () => {
        // clearFormField()
        setActiveUser({});
        removeValuesFromLocalStorage([
            'userAddressInfo',
            'userBasicInfo',
            'selectedProject',
            'selectedPIU',
            'selectedSubproject'
        ])
        setCurrentStep(1);
        setShowUserForm(false);
    };


    const inputClassName = "bg-[#f4f5f5] py-2.5 text-sm";
    const labelClassName = "text-sm text-dark2";
    const {userForm: userFormLSKeys} = localStorageKeys;

    const handleInputChange = ({target}) => {
        const {value, name} = target;
        setAddressObj({...addressObj, [name]: value});
        // saveValueToLocalStorage("userAddressInfo", addressPayload);
    };

    const createUser = () => {
        const {selectedPIU, ...userBasicInfo} = getValueFromLocalStorage(userFormLSKeys.BASIC_INFO, {});
        const assignments = getValueFromLocalStorage(userFormLSKeys.ASSIGNMENTS, []);

        // const {piu: piuId, _id: projectId} = projectDetails;

        const userPayload = {
            ...userBasicInfo,
            piu: isWBAdmin
                ? selectedPIU?._id
                : activeUser.piu?._id,
            address: {...addressObj},
        };

        if (isWBAdmin) {
            userPayload['type'] = userType.PIU_ADMIN
        } else {
            userPayload['projects'] = assignments.map((assignment) => {
                return assignment.project._id
            });
            userPayload['project'] = projectDetails._id
            if (assignments.length === 1) {
                userPayload['defaultProject'] = assignments[0]?.project?._id;
            }
            // if (selectedSubprojects) {
            //     userPayload['subProjects'] = selectedSubprojects.map((subproject) => subproject?._id)
            // }
            userPayload['subProjects'] = flatten(assignments.map((assignment) => {
                return assignment?.subprojects?.map((subproject) => subproject._id) ?? []
            }))
        }

        if (!isEditMode) return _createUser(userPayload);
        return updateUser({
            ...userPayload,
            userId: activeUser._id
        });
    };

    const handlePrevious = () => {
        saveValueToLocalStorage("userAddressInfo", addressObj);
        setCurrentStep(isWBAdmin ? 1 : 2);
    };

    // console.log("Active", activeUser)
    return (
        <form onSubmit={handleSubmit(createUser)} className={"space-y-5"}>
            <p
                className={
                    "text-dark text-sm tracking-wide uppercase font-semibold"
                }
            >
                Address Information{" "}
                <span className={"text-gray-500 capitalize font-normal"}>
					( Optional )
				</span>
            </p>
            <div className={"grid grid-cols-2 gap-4"}>
                <div className={"space-y-2"}>
                    <label htmlFor="country" className={labelClassName}>
                        Country
                    </label>
                    <Input
                        name={"country"}
                        onChange={handleInputChange}
                        isError={!!errors?.country?.message}
                        defaultValue={
                            addressInfo?.country ?? activeUser?.address?.country
                        }
                        register={register}
                        id={"roleName"}
                        className={inputClassName}
                        placeholder={"eg: Tanzania"}
                    />
                </div>

                <div className={"space-y-2"}>
                    <label htmlFor="region" className={labelClassName}>
                        Region
                    </label>
                    <Input
                        name={"region"}
                        register={register}
                        onChange={handleInputChange}
                        isError={!!errors?.region?.message}
                        defaultValue={
                            addressInfo?.region ?? activeUser?.address?.region
                        }
                        className={inputClassName}
                        placeholder={"eg: Dar es Saalam"}
                    />
                </div>

                <div className={"space-y-2"}>
                    <label htmlFor="district" className={labelClassName}>
                        District
                    </label>
                    <Input
                        name={"district"} // & id={district}
                        register={register}
                        onChange={handleInputChange}
                        isError={!!errors?.district?.message}
                        defaultValue={
                            addressInfo?.district ??
                            activeUser?.address?.district
                        }
                        className={inputClassName}
                        placeholder={"eg: Ubungo"}
                    />
                </div>

                <div className={"space-y-2"}>
                    <label htmlFor="ward" className={labelClassName}>
                        Ward
                    </label>
                    <Input
                        name={"ward"}
                        register={register}
                        isError={!!errors?.ward?.message}
                        onChange={handleInputChange}
                        defaultValue={
                            addressInfo?.ward ?? activeUser?.address?.ward
                        }
                        className={inputClassName}
                        placeholder={"eg: Makuburi"}
                    />
                </div>
                <div className={"space-y-2"}>
                    <label htmlFor="street" className={labelClassName}>
                        Street
                    </label>
                    <Input
                        name={"street"} // & id={district}
                        register={register}
                        isError={!!errors?.street?.message}
                        onChange={handleInputChange}
                        defaultValue={
                            addressInfo?.street ?? activeUser?.address?.street
                        }
                        className={inputClassName}
                        placeholder={"eg: Upendo Street"}
                    />
                </div>

                <div className={"space-y-2"}>
                    <label htmlFor="postalCade" className={labelClassName}>
                        Postal Code
                    </label>
                    <Input
                        name={"postalCode"}
                        register={register}
                        isError={!!errors?.postalCode?.message}
                        onChange={handleInputChange}
                        defaultValue={
                            addressInfo?.postalCode ??
                            activeUser?.address?.postalCode
                        }
                        className={inputClassName}
                        placeholder={"eg: 123"}
                    />
                </div>

                {/* show errors from backend */}
                <div
                    className={`bg-red-500/20 flex items-center ${
                        errorMessage ? "" : "hidden"
                    } space-x-2 text-sm tracking-wder text-red-800 col-span-2 border border-red-300 p-3 rounded`}
                >
                    <ExclamationIcon className={"h-5 w-5"}/>
                    <p>{errorMessage}</p>
                </div>

                <div className={"items-between col-span-2"}>
                    <button
                        type={"button"}
                        onClick={handlePrevious}
                        className={
                            "py-2 text-sm flex items-center space-x-1 px-4 text-dark2 "
                        }
                    >
                        Previous
                    </button>

                    <button
                        className={
                            "py-2 text-sm flex items-center space-x-1 px-4 text-white space-x-2 bg-primary rounded hover:opacity-90"
                        }
                    >
                        {isLoading || isUpdating ? (
                            <Fragment>
                                <SpinLoader color={"#fff"} size={"small"}/>{" "}
                                <span>
									{isEditMode
                                        ? "Updating User.."
                                        : "Creating User..."}
								</span>
                            </Fragment>
                        ) : (
                            <span>
								{isEditMode ? "Update User" : "Creating User"}
							</span>
                        )}
                    </button>
                </div>
            </div>
        </form>
    );
};
export default AddressInfo;
