import { Outlet } from "react-router-dom";
import MeetingTab from "./_partials/meeting-tab";

const EventLayout = () => {
    const tabMenus = [
        {
            title: "Today Events",
            url: "today",
            color: "#628799",
        },

        {
            title: "Upcoming Events",
            url: "upcoming",
            color: "#6eb8eb",
        },

        {
            title: "Completed Events",
            url: "completed",
            color: "#70CCBF",
        },

        {
            title: "Overdue Events",
            url: "overdue",
            color: "#E8716F",
        },
    ];
    return (
        <div className={"pt-4"}>
            <div className={"flex -space-x-3 pl-5"}>
                {tabMenus.map((menu) => (
                    <MeetingTab
                        url={menu.url}
                        title={menu.title}
                        color={menu.color}
                    />
                ))}
            </div>
            <Outlet />
        </div>
    );
};
export default EventLayout;
