import { useState, useRef } from "react";
import PropTypes from "prop-types";
import Modal from "../Modal";
import { ArrowLeftIcon } from "@heroicons/react/outline";
import { DownloadIcon, PrinterIcon } from "../../svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faAngleLeft,
	faAngleRight,
	faThumbtack,
} from "@fortawesome/free-solid-svg-icons";
import SpinLoader from "../SpinLoader";
import useDownloadFileFromS3 from "../../hooks/useDownloadFileFromS3";

export function handlePrint(url) {
	var win = window.open("about:blank", "_blank");
	win.document.open();
	win.document.write(
		[
			"<html>",
			"   <head>",
			"   </head>",
			'   <body onload="window.print()" onafterprint="win.close()">',
			'       <img src="' + url + '"/>',
			"   </body>",
			"</html>",
		].join("")
	);
	win.document.close();
}
const ImageViewer = ({ file, isOpen, setIsOpen }) => {
	const [isImageFullLoaded, setIsImageFullLoaded] = useState(false);
	const [isDownloading, setIsDownloading] = useState(false);
	const { s3FileDownloader } = useDownloadFileFromS3((progress) => {
		if (parseInt(progress) === 100) {
			setIsDownloading(false);
		}
	});
	const imageRef = useRef(null);

	/**
	 * Prints an image by temporarily opening a popupimage source to load
	 * @returns {void}
	 */

	const handleOnClose = () => {
		setIsImageFullLoaded(false);
		setIsOpen(false);
		setIsDownloading(false);
	};

	const handleDownload = () => {
		setIsDownloading(true);
		s3FileDownloader(file.fileKey, file.title);
	};

	return (
		<Modal isOpen={isOpen} onClose={handleOnClose} dialogClass="">
			<div className={"w-full h-screen bg-black/70"}>
				<div
					className={
						"items-between  bg-gradient-to-b py-2 px-5 from-black/60 to-transparent"
					}
				>
					<div className={"flex  items-center text-white space-x-6"}>
						<button
							onClick={handleOnClose}
							className={
								"h-8 w-8 center rounded-full hover:bg-gray-600"
							}
						>
							<ArrowLeftIcon className={"h-6 w-6"} />
						</button>
						<div className={"flex items-center space-x-3"}>
							<img
								className={"h-5 w-5"}
								src={
									"https://ssl.gstatic.com/docs/doclist/images/mediatype/icon_1_image_x32.png"
								}
								alt={"icon"}
							/>
							<p className={"tracking-wider"}>{file.title}</p>
						</div>
					</div>
					<div className={"flex items-center space-x-5"}>
						<button onClick={() => handlePrint(file.url)}>
							<PrinterIcon className={"h-5 w-5"} color={"#fff"} />
						</button>
						<button onClick={handleDownload}>
							{isDownloading ? (
								<SpinLoader size={"small"} color={"#fff"} />
							) : (
								<DownloadIcon
									className={"h-5 w-5"}
									color={"#fff"}
								/>
							)}
						</button>
						<FontAwesomeIcon
							icon={faThumbtack}
							className={"h-5 w-5"}
							color={"#fff"}
						/>
					</div>
				</div>

				<div className={"h-[93%] relative center space-y-2 flex-col"}>
					{/* previous button*/}
					<button
						className={
							"bg-gray-500 text-white absolute center h-8 w-8 rounded-full top-[40%] -translate-x-[40%] left-16"
						}
					>
						<FontAwesomeIcon
							icon={faAngleLeft}
							className={"h-5 w-5"}
						/>
					</button>

					<div
						className={
							"center relative overflow-hidden w-4/5 h-[94%] "
						}
					>
						<img
							ref={imageRef}
							onLoad={() => setIsImageFullLoaded(true)}
							className={" max-h-full max-w-full"}
							src={file.url}
							alt=""
						/>

						{!isImageFullLoaded && (
							<div className={"absolute center inset-0"}>
								<SpinLoader size={"medium"} color={"#fff"} />
							</div>
						)}
					</div>

					{/*next button*/}
					<button
						className={
							"bg-gray-500 text-white absolute h-8 w-8 center rounded-full top-[40%] -translate-x-[40%] right-10"
						}
					>
						<FontAwesomeIcon
							icon={faAngleRight}
							className={"h-5 w-5"}
						/>
					</button>
				</div>
			</div>
		</Modal>
	);
};

ImageViewer.propTypes = {
	file: PropTypes.object.isRequired,
	isOpen: PropTypes.bool.isRequired,
	setIsOpen: PropTypes.func.isRequired,
};
export default ImageViewer;
