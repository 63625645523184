import CheckIcon from "./CheckIcon";
import {getValueFromLocalStorage} from "../hooks/useLocalStorageState";

export const TabMenu = (
    {
        isActive = false,
        onClick,
        tabName,
        tabNumber,
        isFinished,
    }) => {
    const isUpdatingStakeholder = getValueFromLocalStorage("isUpdatingStakeholderDetails");
    const className = `h-[25px] w-[25px] overflow-hidden relative font-medium transition-all
        ease-in-out text-sm flex justify-center items-center border-2 
            ${
        isActive
            ? "border-accent text-accent"
            : `${
                isFinished
                    ? "border-[#4ADD85]"
                    : "border-[#D3D5D8] text-[#B0AFB2]"
            }`
    }  rounded-full`;
    return (
        <div onClick={() => onClick()}
             className={`
             flex ${isUpdatingStakeholder ? "pointer-events-auto" : "pointer-events-none"}
              items-center cursor-pointer space-x-2`}>
            <div>
                <div
                    className={className}
                >

                    {tabNumber}

                    <div
                        className={`${
                            isFinished ? "opacity-100" : "opacity-0"
                        } absolute bg-[#4ADD85] inset-0 flex justify-center items-center`}
                    >
                        <CheckIcon/>
                    </div>
                </div>
            </div>

            <p
                className={`text-sm tracking-wide ${
                    isActive ? "text-[#009EE2]" : "text-primary/90"
                }`}
            >
                {tabName}
            </p>
        </div>
    );
};
