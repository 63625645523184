import CustomCheckBox from "components/CustomCheckBox";

const ParticipantCard = ({
    participant,
    onSelect: handleClick,
    isSelected,
}) => {
    const handleCheck = (value) => {
        // setIsCheck(value);
    };

    const checkBoxProps = {
        onCheck: handleCheck,
        checked: isSelected,
        iconClass: "h-2 w-2",
        activeIconColor: "#1A9EE2",
        activeClasses: "border-[#1A9EE2]",
        className: "border-[1px] h-4 w-4 pointer-events-none",
        inactiveClasses: "bg-white border-[#BABABA]",
    };

    return (
        <div
            onClick={handleClick}
            className={
                "" +
                "border-t-[.5px] cursor-pointer hover:bg-accent/5 " +
                "font-light flex items-between text-sm px-2" +
                " py-2.5 border-[#3A404057]"
            }
        >
            <div
                className={`flex items-center space-x-3 
                    capitalize ${
                        isSelected ? "text-accent" : "text-[#3E3D3D]"
                    }`}
            >
                <div
                    className={
                        "h-2 w-2 bg-[#DFF1FE] rounded-full border-[.6px] border-[#1A9EE2]"
                    }
                ></div>
                <p>{participant.name}</p>
            </div>

            <CustomCheckBox {...checkBoxProps} />
        </div>
    );
};
export default ParticipantCard;
