import GrievanceStatisticsCard from "./grievance-statistics-card";
import { InvestigationIcon, ProgressIcon, ReopenIcon } from "../../../../svg";
import useGrievanceAnalytics from "../../../../hooks/useGrievanceAnalytics";

const GrievanceStatistics = () => {
	const { grievanceAnalytic, isLoading } = useGrievanceAnalytics();
	if (isLoading)
		return (
			<div className={"grid grid-cols-3 p-4 bg-white gap-4 rounded"}>
				{[...Array(5)].map((num) => (
					<div
						className={
							"h-32 rounded-[6px] bg-secondary/[0.23] animate-pulse"
						}
					></div>
				))}
			</div>
		);
	const { general } = grievanceAnalytic;

	const statistics = [
		{
			icon: <InvestigationIcon className={"h-7 w-7"} />,
			total: general?.underInvestigation,
			title: "Grievances under investigation",
		},
		{
			icon: <ProgressIcon className={"h-6 w-6"} />,
			total: general?.inProgress,
			title: "Grievances Resolution in progress",
		},
		{
			icon: <ReopenIcon className={"h-7 w-7"} />,
			total: general?.reOpened,
			title: "Reopened Grievances",
		},
		{
			icon: <ReopenIcon className={"h-7 w-7"} />,
			total: general?.received,
			title: "Pending Resolution",
		},
		{
			icon: <ReopenIcon className={"h-7 w-7"} />,
			total: general?.closed,
			title: "Closed Grievances",
		},
	];
	return (
		<div className={"grid grid-cols-3 gap-4"}>
			{statistics.map((statistic) => (
				<GrievanceStatisticsCard
					key={statistic.title}
					statistic={statistic}
				/>
			))}
		</div>
	);
};
export default GrievanceStatistics;
