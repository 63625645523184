import Loading from "./loading";

const PieChartLabel = ({ text, color, isLoading = false }) => {
    if (isLoading) {
        return <Loading />;
    }

    return (
        <div className={"flex items-center space-x-4"}>
            <div
                style={{ background: color }}
                className="h-1.5 w-8 b rounded-full"
            ></div>
            <p className={"text-[#A6B1C2] uppercase"}>{text}</p>
        </div>
    );
};
export default PieChartLabel;
