const DarEsSalaamSvg = ({ color, isLoading = false }) => {
    return (
        <path
            id="Path_441"
            data-name="Path 441"
            className={`${isLoading ? "animate-pulse" : ""}`}
            d="M603.659,308.794l1.031.74.306.336.589,1.012.347.362.665.4.331.292.261.445.156.513.054.5.242.98.582,1.076.706.525.63-.679.172,1.141-.181,1.535.019,1.394.789.743-.026-.718-.064-.3-.175-.324.716.045.566.221,1.114.676.6.16,1.184.106.49.272.579.208,1.431-.016.28.224.165.542.719.952.328,1.163,1.87,2.48.124.273-.045.382-.19.241-.2.205-.1.25.334,2.513-.042,1.039-.586-.055-.394.5-.3,1.068-.229.462-.436.347-.369-.009-.28-.35-.133-.67-1.642,1.151-.045.058-.029-.031-.448-.607-.521.163-.06-5.589-.857-1.409-1.091-1.256-1.01-.293-.974-.133-.566.055-.5-.045-1.309.153-.9,1.3-.614.055-.566-.081-.343.692-.594.506-.7-.24-.6-.275-.8.608-.525.886-.905.58-.8-.76-.752.269-.465-.427.473-.932.7-.836.2-.946.465-.81.78-.462.424-.749-.351-.843-.2-.783-1.1-.7-.295-1.361-.016-.749-.493-.425-.942-.059.089-.588,1.1-1.4.5-1.715.194-1.914.715-1.745.724-.612.666-.757Z"
            transform="translate(-230.848 -90.232)"
            fill={color}
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="0.5"
        />
    );
};
export default DarEsSalaamSvg;
