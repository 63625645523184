import UserRoleProvider from "../../provider/roles/user-role-provider";
import { Navigate, Route, Routes } from "react-router-dom";
import { RequirePermission } from "../../provider/permission";
import UserRoles from "../../pages/settings/roles";
import Users from "../../pages/settings/users";
import ProjectSettingsLayout from "../../pages/settings";
import GeneralSettings from "../../pages/settings/general";
import DepartmentSettings from "../../pages/settings/department";
import GrievanceManagement from "../../pages/settings/grievance";
import ProjectManagementLayout from "pages/settings/project";
import ProjectDetails from "pages/settings/project/details";
import Projects from "../../pages/settings/project/projects";
import WorkspaceDetails from "../../pages/settings/project/workspace/workspace-details";
import WorkspaceList from "../../pages/settings/project/workspace/workspace";
import PIUList from "../../pages/settings/pius";
import Subprojects from "../../pages/settings/subproject";
import Phase from "../../pages/settings/phase";

const SettingsRoutes = () => {
    return (
        <RequirePermission>
            <UserRoleProvider>
                <RequirePermission>
                    {/*[*/}
                    {/*permissions.READ_USERS,*/}
                    {/*permissions.READ_ROLES,*/}
                    {/*]*/}
                    <Routes>
                        <Route element={<ProjectSettingsLayout />}>
                            {" "}
                            {/* layout route */}
                            <Route
                                index
                                element={
                                    <Navigate
                                        replace={true}
                                        to={"user-profile"}
                                    />
                                }
                            />{" "}
                            {/* redirect to profile tab*/}
                            <Route
                                path={"notifications"}
                                element={
                                    <Navigate replace={true} to={"/settings"} />
                                }
                            />
                            <Route
                                path={"user-profile"}
                                element={<GeneralSettings />}
                            />
                            <Route
                                path={"departments"}
                                element={<DepartmentSettings />}
                            />
                            <Route
                                path={"phase-managements/*"}
                                element={<Phase />}
                            />
                            <Route
                                path={"grievance-management/*"}
                                element={<GrievanceManagement />}
                            />
                            {/* subprojects routes */}
                            <Route
                                path={"subproject-management"}
                                element={<Subprojects />}
                            />
                            {/* projects routes */}
                            <Route
                                path="project-management/*"
                                element={
                                    <Routes>
                                        <Route
                                            element={
                                                <ProjectManagementLayout />
                                            }
                                        >
                                            <Route
                                                index
                                                element={<Projects />}
                                            />
                                            <Route
                                                path={"workspaces"}
                                                element={<WorkspaceList />}
                                            />
                                            <Route
                                                path={
                                                    "workspaces/:workspaceName"
                                                }
                                                element={<WorkspaceDetails />}
                                            />
                                            <Route
                                                path={"/:projectTitle"}
                                                element={<ProjectDetails />}
                                                onLeave={() => {
                                                    console.log(
                                                        "leaving a page."
                                                    );
                                                }}
                                            />
                                        </Route>
                                    </Routes>
                                }
                            />
                            <Route path={"roles-and-permissions"}>
                                <Route index element={<UserRoles />} />
                            </Route>
                            <Route path={"pius"} element={<PIUList />} />
                            <Route path={"users"}>
                                <Route index element={<Users />} />
                            </Route>
                        </Route>
                    </Routes>
                </RequirePermission>
            </UserRoleProvider>
        </RequirePermission>
    );
};
export default SettingsRoutes;
