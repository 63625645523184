const FormHeader = ({ title, description }) => {
    return (
        <div className="space-y-2">
            <p className="text-primary text-xl tracking-wider">{title}</p>
            <p className="text-lg text-[#B0B2B4] tracking-wider font-light">
                {description}
            </p>
        </div>
    );
};
export default FormHeader;
