import { Route, Routes } from "react-router-dom";

import { permissions, RequirePermission } from "provider/permission";
import Engagement from "pages/general/engagement/plans";
import ConductMeeting from "pages/general/engagement/activity/conduct-meeting";
import EngagementActivities from "pages/general/engagement/activity";
import Overview from "pages/general/engagement/activity/overview";
import EventLayout from "pages/general/engagement/activity/events";
import MeetingReport from "pages/general/engagement/activity/events/report";
import EventList from "pages/general/engagement/activity/events/events-list";

const EngagementRoutes = () => {
    return (
        <RequirePermission
            permission={[
                permissions.READ_ENGAGEMENT_PLANS,
                permissions.READ_PROJECT_EVENTS,
            ]}
        >
            <Routes>
                <Route
                    path={"plans/*"}
                    element={
                        <RequirePermission
                            permission={permissions.READ_ENGAGEMENT_PLANS}
                        >
                            <Engagement />
                        </RequirePermission>
                    }
                />
                />
                <Route
                    path={"conduct-meeting/:title"}
                    element={<ConductMeeting />}
                />
                <Route element={<EngagementActivities />}>
                    <Route
                        path={"activities/*"}
                        element={
                            <RequirePermission
                                permission={permissions.READ_PROJECT_EVENTS}
                            >
                                <Routes>
                                    <Route index element={<Overview />} />
                                    <Route path={"events/*"}>
                                        <Route element={<EventLayout />}>
                                            <Route
                                                path={":type/*"}
                                                element={<EventList />}
                                            />
                                        </Route>
                                    </Route>
                                    <Route
                                        path={"meetings/report/:eventId"}
                                        element={<MeetingReport />}
                                    />
                                </Routes>
                            </RequirePermission>
                        }
                    ></Route>
                </Route>
                <Route path="conduct-meeting" element={<ConductMeeting />} />
            </Routes>
        </RequirePermission>
    );
};
export default EngagementRoutes;
