import { Link } from "react-router-dom";
import CommitmentIllustration from "./illustration";

const EmptyState = () => {
    return (
        <div
            className={
                "py-20 center flex-col bg-[#f9f9f9] rounded-lg border mt-5 space-y-5 h-[70vh]"
            }
        >
            <div className={"h-44 w-44 p-4 rounded-full bg-gray-200"}>
                <CommitmentIllustration />
            </div>
            <p className={"text-center text-gray-500"}>
                There are no commitments currently available. To get started,
                please click{" "}
                <Link to={"/"} className={"text-accent"}>
                    here
                </Link>{" "}
                <br />
                to create new commitments plans.
            </p>
        </div>
    );
};
export default EmptyState;
