import { FrequencyIcon } from "svg";

const ClosedGrievanceCard = (
	{ grievance, bgColor = "#F0F8F4B2" },
	...props
) => {
	return (
		<div
			{...props}
			style={{ backgroundColor: bgColor }}
			className={
				"space-y-5 p-4 rounded-[5px] border-[1px] border-[#E4E4E4]"
			}
		>
			<div className="text-[#686A80] font-light">
				<div className={"items-between"}>
					<p className={" text-[15px] font-semibold"}>
						{grievance.title}
					</p>
					{grievance?.icon ? (
						grievance?.icon
					) : (
						<FrequencyIcon className={"h-6 w-6"} />
					)}
				</div>
				<p className="text-sm">Range: {grievance.range}</p>
			</div>

			<div className={"space-y-3"}>
				<div className="m-auto h-28 w-28">
					<svg
						viewBox="0 0 36 36"
						className="w-full h-full circular-chart orange"
					>
						<path
							className="circle-bg"
							d="M18 2.0845
                          a 15.9155 15.9155 0 0 1 0 31.831
                          a 15.9155 15.9155 0 0 1 0 -31.831"
						/>
						<path
							className="circle"
							strokeDasharray={`${grievance.percentage}, 100`}
							d="M18 2.0845
                          a 15.9155 15.9155 0 0 1 0 31.831
                          a 15.9155 15.9155 0 0 1 0 -31.831"
						/>
						<text x="18" y="20.35" className="percentage">
							{grievance.percentage}%
						</text>
					</svg>
				</div>
				<p className={"text-center text-sm text-secondary"}>
					{grievance.total}
				</p>
			</div>
		</div>
	);
};
export default ClosedGrievanceCard;
