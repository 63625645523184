import {createContext} from "react";

export const GlobalContext = createContext(null);
export const EventContext = createContext(null);
export const CurrStakeholderContext = createContext(null);
export const NotificationContext = createContext(null);
export const GrievanceContext = createContext(null);
export const CommitmentContext = createContext(null);
export const ProjectFilesContext = createContext(null);
export const GrievanceAnalyticContext = createContext(null);
export const CommitmentAnalyticsContext = createContext(null);
export const UserContext = createContext(null);
export const UserRoleContext = createContext(null);
export const EngagementAnalyticContext = createContext(null);
export const ProjectImagesContext = createContext(null);
