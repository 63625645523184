const EventDetails = ({ event }) => {
    const formatDate = (dateInfo) => {
        if (!dateInfo) return "Date not provided";
        return new Date(dateInfo).toDateString();
    };

    return (
        <div
            className={
                "grid z-0 grid-cols-2 text-xs bg-[#F9F9F9] rounded-b-md p-3"
            }
        >
            <div className={"w-full space-y-3"}>
                <p className={"font-semibold text-[#37474F]"}>Event Details</p>
                <div
                    className={
                        "grid grid-cols-2 gap-4 border-r border-[#9169FF]/10"
                    }
                >
                    <div className={"text-[#37474F] font-light leading-loose"}>
                        <p>Event Date:</p>
                        <p>Channel type:</p>
                        <p>Channel/location scope:</p>
                    </div>

                    <div
                        className={
                            "pl-0 xs text-[#37474F] font-light leading-loose"
                        }
                    >
                        <p>{formatDate(event.eventDay)} </p>
                        <p>{event.channelType}</p>
                        <p>
                            {event.channelType === "Online"
                                ? event.channel?.name
                                : event.locationScope}
                        </p>
                    </div>
                </div>
            </div>

            <div className={"w-full space-y-3 pl-5"}>
                <p className={"font-semibold text-[#37474F]"}>
                    Participant Details
                </p>
                <div className={"grid grid-cols-2 gap-4"}>
                    <div
                        className={
                            " xs text-[#37474F] font-light leading-loose"
                        }
                    >
                        <p>Total registered stakeholders</p>
                        {/* <p>Non Registered Stakeholders</p> */}
                        {/* <p>Non Registered Stakeholders</p> */}
                    </div>

                    <div
                        className={
                            "pl-0 xs text-[#37474F] font-light leading-loose"
                        }
                    >
                        <p>{event.participants.length}</p>
                        {/* <p>32</p> */}
                        {/* <p>8</p> */}
                    </div>
                </div>
            </div>
        </div>
    );
};
export default EventDetails;
