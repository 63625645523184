import Modal from "components/Modal";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import EditorJS from "components/editorjs/editor";
import Button from "components/Button";
import { useApproveBudget } from "./budget-approve";
import { useMutation, useQueryClient } from "react-query";
import { updateProjectEvent } from "../../../provider/api";
import { isEmptyDescription } from "../../../utils";
import { useAuth } from "../../../provider/auth/auth";

const RejectBudgetForm = () => {
    const {
        showRejectForm,
        setShowRejectForm,
        activeEvent,
        isRejected,
        setActiveEvent,
    } = useApproveBudget();
    const [reason, setReason] = useState("");
    const [hasError, setHasError] = useState(null);
    const queryClient = useQueryClient();
    const handleEditorChange = (cleanData) => {
        setReason(cleanData);
    };
    const { authUser } = useAuth();

    // update meeting budget
    const { mutate: _updateEvent, isLoading } = useMutation(
        `update-project-event-${activeEvent._id}`,
        updateProjectEvent,
        {
            onSuccess: ({ data: updatedEvent }) => {
                handleClose();
                updateEventCache(updatedEvent);
            },
        }
    );

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!reason) return setHasError(true);
        let payload;

        // update meeting
        if (isRejected) {
            payload = {
                _id: activeEvent?._id,
                budgetStatus: 0, // reject
                budgetRejectionReasons: [
                    ...activeEvent.budgetRejectionReasons,
                    JSON.stringify(reason),
                ],
            };
        } else {
            payload = {
                _id: activeEvent?._id,
                budgetStatus: 1, // approved
                budgetApprovalComment: JSON.stringify(reason),
            };
        }
        _updateEvent(payload);
    };

    const updateEventCache = (updatedEvent) => {
        queryClient.setQueriesData(
            ["events-budget", authUser._id],
            (oldQueryData) => {
                return {
                    ...oldQueryData,
                    data: [
                        ...oldQueryData.data.map((event) => {
                            if (event._id === updatedEvent._id) {
                                return {
                                    ...event,
                                    budgetStatus: updatedEvent.budgetStatus,
                                    budgetRejectionReasons:
                                        updatedEvent.budgetRejectionReasons,
                                    budgetApprovalComment:
                                        updatedEvent.budgetApprovalComment,
                                };
                            }
                            return event;
                        }),
                    ],
                };
            }
        );
    };

    const handleClose = () => {
        setActiveEvent({}); // remove active event
        setShowRejectForm(false);
    };
    return (
        <Modal isOpen={showRejectForm} onClose={handleClose}>
            <form
                onSubmit={handleSubmit}
                action=""
                className={
                    "w-[30%] bg-white mt-[15vh] rounded-lg overflow-hidden"
                }
            >
                <header className={"py-3 px-5 bg-gray-300  items-between "}>
                    <h3 className={"font-medium text-dark"}>
                        {isRejected
                            ? "Reject EventLayout Budget"
                            : "Approve EventLayout Budget"}
                    </h3>
                    <button
                        type={"button"}
                        onClick={handleClose}
                        className="-mr-2 border-2 center border-[#192B40]/50 w-6 h-6 bg-[#628799] text-white
                            rounded-full"
                    >
                        <FontAwesomeIcon icon={faXmark} />
                    </button>
                </header>

                <div className={"p-5 space-y-5"}>
                    <p className={"text-black/60"}>
                        {isRejected
                            ? "Give a justification for your decision to reject a meeting budget."
                            : "You may, at your discretion, add a comment to go along with this budget."}
                    </p>
                    <div className={"space-y-3"}>
                        <EditorJS
                            isError={hasError && isEmptyDescription(reason)}
                            onChange={handleEditorChange}
                        />
                        <Button type={"submit"} loading={isLoading}>
                            {isRejected ? "Reject Budget" : "Approve Budget"}
                        </Button>
                    </div>
                </div>
            </form>
        </Modal>
    );
};
export default RejectBudgetForm;
