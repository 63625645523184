import {UserContext} from "../../context";
import {useQuery} from "react-query";
import {getUsers} from "../api";
import {useState} from "react";
import useGlobalContext from "hooks/useGlobalContext";
import {hasAnyPermission, permissions, userType} from "../permission";
import {queryKeys} from "../../constants";

const UserProvider = ({children}) => {
    const [users, setUsers] = useState([]);
    const [showUserForm, setShowUserForm] = useState(false);
    const [activeUser, setActiveUser] = useState({});
    const [currentStep, setCurrentStep] = useState(1);
    const {projectId} = useGlobalContext();
    const isCreatingAdminUser = hasAnyPermission(permissions.MANAGE_PROJECTS_AND_PIUS);

    const {isLoading} = useQuery(queryKeys.USERS, () => {
        return getUsers(isCreatingAdminUser ? '/users' : `/users?project=${projectId}`)
    }, {
        onSuccess: ({data: userList = []}) => {

            // filter all piu-admins
            if (isCreatingAdminUser) {
                setUsers(userList.filter((user) => {
                    return user.type === userType.PIU_ADMIN;
                }))
                return;
            }
            setUsers(userList.filter((user) => {
                return user.type !== userType.PIU_ADMIN;
            }));
        },
    });

    return (
        <UserContext.Provider
            value={{
                users,
                isLoading,
                setActiveUser,
                setShowUserForm,
                showUserForm,
                isCreatingAdminUser,
                activeUser,
                currentStep,
                setCurrentStep,
            }}
        >
            {children}
        </UserContext.Provider>
    );
};
export default UserProvider;
