export const CalenderIcon = ({className, color = "#009ee2"}) => {
    return (
        <svg className={`${className} ${!className && 'h-6 w-6'}`} id="icon" data-name="icon"
             xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.656 24.063">
            <defs>
                <clipPath id="clip-path">
                    <path id="_Icon_Сolor" data-name="Icon Сolor"
                          d="M18.047,24.063H3.609A3.613,3.613,0,0,1,0,20.453V6.016a3.614,3.614,0,0,1,3.609-3.61h1.2V1.2a1.2,1.2,0,0,1,2.407,0v1.2h7.218V1.2a1.2,1.2,0,0,1,2.407,0v1.2h1.2a3.614,3.614,0,0,1,3.609,3.61V20.453A3.613,3.613,0,0,1,18.047,24.063ZM2.406,13.234v7.219a1.2,1.2,0,0,0,1.2,1.2H18.047a1.2,1.2,0,0,0,1.2-1.2V13.234Zm1.2-8.422a1.2,1.2,0,0,0-1.2,1.2v4.812H19.25V6.016a1.2,1.2,0,0,0-1.2-1.2h-1.2v1.2a1.2,1.2,0,0,1-2.407,0v-1.2H7.219v1.2a1.2,1.2,0,0,1-2.407,0v-1.2ZM15.64,18.047H10.828a1.2,1.2,0,0,1,0-2.407H15.64a1.2,1.2,0,1,1,0,2.407Zm-9.624,0a1.2,1.2,0,1,1,1.2-1.2A1.2,1.2,0,0,1,6.016,18.047Z"
                          fill={color}/>
                </clipPath>
            </defs>
            <g id="Group_351" data-name="Group 351" transform="translate(0 0)">
                <path id="_Icon_Сolor-2" data-name="Icon Сolor"
                      d="M18.047,24.063H3.609A3.613,3.613,0,0,1,0,20.453V6.016a3.614,3.614,0,0,1,3.609-3.61h1.2V1.2a1.2,1.2,0,0,1,2.407,0v1.2h7.218V1.2a1.2,1.2,0,0,1,2.407,0v1.2h1.2a3.614,3.614,0,0,1,3.609,3.61V20.453A3.613,3.613,0,0,1,18.047,24.063ZM2.406,13.234v7.219a1.2,1.2,0,0,0,1.2,1.2H18.047a1.2,1.2,0,0,0,1.2-1.2V13.234Zm1.2-8.422a1.2,1.2,0,0,0-1.2,1.2v4.812H19.25V6.016a1.2,1.2,0,0,0-1.2-1.2h-1.2v1.2a1.2,1.2,0,0,1-2.407,0v-1.2H7.219v1.2a1.2,1.2,0,0,1-2.407,0v-1.2ZM15.64,18.047H10.828a1.2,1.2,0,0,1,0-2.407H15.64a1.2,1.2,0,1,1,0,2.407Zm-9.624,0a1.2,1.2,0,1,1,1.2-1.2A1.2,1.2,0,0,1,6.016,18.047Z"
                      fill={color}/>
            </g>
        </svg>
    )
}