import { CalenderWithNumberIcon } from "../../../../svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserGroup } from "@fortawesome/free-solid-svg-icons";
import { ChevronRightIcon } from "@heroicons/react/outline";
import { useEngagementAnalytics } from "../../../../hooks";
import Loading from "./loading";

const EngagementSummary = () => {
	const { isLoading, engagementAnalytics } = useEngagementAnalytics();
	const { general } = engagementAnalytics;
	if (isLoading) return <Loading />;
	return (
		<div className={"grid grid-cols-3 gap-x-3"}>
			<div
				className={
					"bg-[#466C84] space-y-6 flex flex-col justify-center h-44 rounded-[4px] p-5 overflow-hidden"
				}
			>
				<CalenderWithNumberIcon className={"h-12 w-12"} />

				<div className={"space-y-2"}>
					<p className={"text-4xl text-white font-semibold"}>
						{general?.total}
					</p>
					<p className={"text-[#AFBFCA] text-sm font-light"}>
						Total number of Events
					</p>
				</div>
			</div>
			<div className={"h-44 grid grid-cols-1 gap-1.5"}>
				<div
					className={
						"bg-[#628799] space-y-1 text-white flex h-[5.3rem] px-5 flex-col justify-center spa rounded-[4px] overflow-hidden"
					}
				>
					<p className={"text-2xl  font-semibold"}>
						{general?.planned}
					</p>
					<p className={"text-sm font-light opacity-70"}>
						Total number of planned Events
					</p>
				</div>
				<div
					className={
						"bg-[#AAB6C5] space-y-1 flex h-[5.3rem] px-5 flex-col justify-center spa rounded-[4px] overflow-hidden"
					}
				>
					<p className={"text-2xl text-[#466C84] font-semibold"}>
						{general?.unPlanned}
					</p>
					<p className={"text-sm font-light text-[#4B4B4B]"}>
						Total number of unplanned Events
					</p>
				</div>
			</div>
			<div
				className={
					"bg-white p-1 border-[1.8px] border-[#838FAA] h-44 rounded-[4px] overflow-hidden"
				}
			>
				<div
					className={
						"h-full w-full space-y-2 rounded-[4px] px-5 pt-3 bg-[#F0F0F0]"
					}
				>
					<div className={"space-y-2"}>
						<div
							className={"h-12 center w-12 rounded-full bg-white"}
						>
							<FontAwesomeIcon
								icon={faUserGroup}
								className={"h-6 text-[#466C84]/70 w-6"}
							/>
						</div>
						<p className={"text-xs text-[#949599]"}>
							No. of meetings conducted
						</p>
						<p className={"text-3xl text-[#466C84] font-semibold"}>
							{general?.meetingsConducted}
						</p>
					</div>
					<div
						className={"flex text-[#466C84] space-x-1 items-center"}
					>
						<p className={"text-[13px]  italic font-light"}>
							View completed meetings
						</p>
						<ChevronRightIcon className={"h-4 w-4"} />
					</div>
				</div>
			</div>
		</div>
	);
};
export default EngagementSummary;
