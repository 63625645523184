import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import Button from "../../../../components/Button";
import useGrievances from "../../../../hooks/useGrievances";
import dayjs from "dayjs";
import { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import { CreateMarkup } from "../../../../utils";

const GrievanceRow = ({
	isLoading = false,
	grievance,
	handleDelete,
	handleReopen,
}) => {
	const formatDate = (date) => {
		return dayjs(date).format("MMM DD, YYYY");
	};

	const formatTime = (date) => {
		return dayjs(date).format("hh: mm a");
	};

	const { savingGrievance: isUpdating } = useGrievances();
	return isLoading ? (
		<div className="flex flex-col px-10 py-6 space-y-3 bg-white">
			<div className="text-[#AAB6C5] flex flex-row space-x-8 place-items-center text-xs">
				<div className="flex flex-row space-x-2 animate-pulse place-items-center">
					<div className="bg-slate-200 w-3.5 h-3.5 rounded"> </div>
					<div className="h-3 rounded bg-slate-200 w-28"> </div>
				</div>
				<div className="flex flex-row space-x-2 animate-pulse place-items-center">
					<div className="bg-slate-200 w-3.5 h-3.5 rounded-full">
						{" "}
					</div>
					<div className="w-20 h-3 rounded bg-slate-200"> </div>
				</div>
			</div>

			<div className="flex flex-col space-y-1 animate-pulse">
				<div className="w-full h-4 rounded bg-slate-200"> </div>
				<div className="w-full h-4 rounded bg-slate-200"> </div>
				<div className="bg-slate-200 w-[75%] h-4 rounded"> </div>
			</div>

			<div className="flex flex-row justify-between w-full place-items-center">
				<div className="flex flex-row space-x-4">
					<div className="animate-pulse">
						<div className="w-24 rounded bg-slate-200 h-9"> </div>
					</div>
					<div className="animate-pulse">
						<div className="w-24 rounded bg-slate-200 h-9"> </div>
					</div>
				</div>

				<div className="animate-pulse">
					<div className="w-48 h-5 rounded bg-slate-200"> </div>
				</div>
			</div>
		</div>
	) : (
		<div className="flex flex-col px-10 py-6 space-y-3 bg-white">
			<div className="text-[#AAB6C5] flex flex-row space-x-8 place-items-center text-xs">
				<div className="flex flex-row space-x-2">
					<FontAwesomeIcon
						icon={faCalendar}
						className="w-3.5 h-3.5"
					/>
					<p>Resolved: {formatDate(grievance?.resolvedOn)} </p>
				</div>
				<div className="flex flex-row space-x-2">
					<FontAwesomeIcon icon={faClock} className="w-3.5 h-3.5" />
					<p>{formatTime(grievance?.createdAt)}</p>
				</div>
			</div>

			<p
				dangerouslySetInnerHTML={CreateMarkup(grievance?.description)}
				className="text-[#233A4F] font-light text-justify"
			></p>

			<div className="flex flex-row justify-between w-full place-items-center">
				<div className="flex flex-row space-x-4">
					<Button
						className="hidden"
						onClick={() => handleDelete(grievance._id)}
						color="danger"
					>
						Delete
					</Button>

					<Button
						loading={isUpdating}
						onClick={() => handleReopen(grievance._id)}
					>
						Reopen
					</Button>
				</div>

				<div className="text-[#002F4F]">
					<p>
						Complainer:{" "}
						<span className="font-semibold">
							{grievance?.complainer?.fullName}
						</span>
					</p>
				</div>
			</div>
		</div>
	);
};

const GrievancesGrid = (props) => {
	const {
		allGrievances,
		isFetchingGrievances,
		saveGrievanceStatus,
		savingGrievance,
		removeGrievance,
		errorDeletingGrievance,
		successDeletingGrievance,
		successChangingStatus,
		errorChangingStatus,
	} = useGrievances();

	const [items, setItems] = useState([]);
	const [query, setQuery] = useState("");
	const [pageCount, setpageCount] = useState(0);
	const [itemOffset, setItemOffset] = useState(0);
	const [limit, setLimit] = useState(10);

	useEffect(() => {
		const filteredGrievances = allGrievances.closed.filter((grievance) => {
			const isIncludeQueryKey = grievance?.complainer?.fullName
				.toLowerCase()
				.includes(query.toLowerCase());
			return query === "" || isIncludeQueryKey;
			// if (query === '') {
			//     return grievance;
			// } else if (grievance?.complainer?.fullName.toLowerCase().includes(query.toLowerCase())) {
			//     return grievance;
			// }
			// return false;
		});
		const endOffset = itemOffset + limit;
		console.log(`Loading items from ${itemOffset} to ${endOffset}`);
		setItems(filteredGrievances.slice(itemOffset, endOffset));

		const total = filteredGrievances.length;
		setpageCount(Math.ceil(total / limit));
	}, [query, limit, allGrievances, itemOffset]);

	const handlePageClick = (event) => {
		const newOffset = event.selected * limit;
		console.log(
			`User requested page number ${event.selected}, which is offset ${newOffset}`
		);
		setItemOffset(newOffset);
	};

	const handleDelete = (_id) => {
		if (window.confirm("Are you sure you want to delete this complaint?")) {
			removeGrievance(_id);
			errorDeletingGrievance
				? window.alert("An error occurred, please try again...")
				: setItems(items.filter((item) => item._id !== _id));
		}
	};

	const handleReopen = (_id) => {
		if (window.confirm("Are you sure you want to reopen this complaint?")) {
			const grievancesPayload = {
				id: _id,
				status: 5, //Status 5 is for REOPENED complaints
				reOpenedAt: new Date(),
			};
			saveGrievanceStatus(grievancesPayload);
			if (errorChangingStatus) {
				window.alert("An error occurred, please try again...");
			} else if (successChangingStatus) {
				setItems(items.filter((item) => item._id !== _id));
			}
		}
	};

	return (
		<div className="justify-between w-full pb-10 space-y-4">
			{/* Header of the grid, sticky at the top */}
			<div className="z-10 backdrop-blur sticky top-0 bg-[#FFFFFF]/90 border-t-4 border-[#628799] rounded-t px-10 py-3 flex flex-row justify-between">
				<div className="flex flex-row space-x-3 place-items-center text-[#202842]">
					<p> Show </p>
					<input
						onChange={(event) =>
							setLimit(event.target.valueAsNumber)
						}
						id="entries"
						className="border px-4 py-1 rounded border-[#DCDFF1] focus:border-[#628799] focus:ring-[#628799]"
						type="number"
						defaultValue={limit}
						min="1"
						max={10}
					/>
					<p>entries</p>
				</div>

				<div className="flex flex-row space-x-3 place-items-center">
					<span
						className={`animation-all inline-flex justify-center items-center ml-2 px-1 min-w-[20px] h-5 text-xs font-semibold bg-blue-200 text-blue-800 rounded-full`}
					>
						{items.length}
					</span>

					<div className="relative w-full">
						<div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
							<FontAwesomeIcon
								icon={faSearch}
								className="text-[#202842]/50"
							/>
						</div>
						<input
							onChange={(event) => setQuery(event.target.value)}
							className="pl-10 p-2 rounded border-[#AAB6C4] focus:border-[#628799] focus:ring-[#628799]"
							type="text"
							placeholder="Search complainer..."
						/>
					</div>
				</div>
			</div>

			{/* The column with pagination */}
			{items.length > 0 ? (
				<div className="grid grid-cols-1 border border-[#E4E4E4] divide-y divide-[#E4E4E4]">
					{items.map((grievance, index) => (
						<GrievanceRow
							key={grievance._id}
							grievance={grievance}
							handleDelete={handleDelete}
							handleReopen={handleReopen}
						/>
					))}
				</div>
			) : (
				<p className="text-[#B0B4BA] pl-10">No any closed complaints</p>
			)}

			{/* The pagination component */}
			<div className="z-10 sticky bottom-0 backdrop-blur-sm bg-[#EBF0F3]/80 w-full pt-4 pb-8 pr-5 grid place-content-end">
				<ReactPaginate
					renderOnZeroPageCount={null}
					previousLabel={"Previous"}
					nextLabel={"Next"}
					breakLabel={"..."}
					pageCount={pageCount}
					marginPagesDisplayed={2}
					pageRangeDisplayed={2}
					onPageChange={handlePageClick}
					containerClassName={
						"flex flex-row place-items-center text-sm space-x-4"
					}
					pageClassName={"rounded"}
					pageLinkClassName={"px-4 py-2 text-[#253A53]"}
					previousClassName={"text-[#000000]"}
					previousLinkClassName={"text-[#000000]"}
					nextClassName={"text-[#000000]"}
					nextLinkClassName={"text-[#000000]"}
					breakClassName={"page-item"}
					breakLinkClassName={"page-link"}
					activeClassName={
						"bg-[#009EE2]/20 py-2 text-[#009EE2] rounded"
					}
					hrefBuilder={(page, pageCount, selected) =>
						page >= 1 && page <= pageCount ? `/page/${page}` : "#"
					}
					hrefAllControls
				/>
			</div>
		</div>
	);
};

export default GrievancesGrid;
