import { useEffect, useRef } from "react";

const useClickOutside = (handler = null) => {
    if (!handler) {
        throw new Error("handler function is required.");
    }

    if (typeof handler !== "function")
        throw new Error(
            `handler must be a function, but ${typeof handler} is given.`
        );

    const domNodeRef = useRef();

    useEffect(() => {
        document.addEventListener("mousedown", handleClickEvent);
        return () => {
            document.removeEventListener("mousedown", handleClickEvent);
        };
    }, []);

    useEffect(() => {
        document.addEventListener("keydown", handleEscapeKey);
        return () => {
            document.removeEventListener("keydown", handleEscapeKey);
        };
    }, []);

    const handleClickEvent = (event) => {
        if (domNodeRef.current) {
            if (!domNodeRef.current.contains(event.target)) {
                handler();
            }
        }
    };

    const handleEscapeKey = (event) => {
        // even.preventDefault()
        if (event.key === "Escape") {
            handler();
        }
    };
    return domNodeRef;
};
export default useClickOutside;
