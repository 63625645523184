import { ProjectFilesContext } from "../../context";
import { useQuery } from "react-query";
import { getProjectFilesByFolders } from "../api";
import { useState } from "react";
import dayjs from "dayjs";
import { getValueFromLocalStorage } from "../../hooks/useLocalStorageState";
import { getProjectFilesByCategory } from "../api/index";

const ProjectFilesProvider = ({ children }) => {
	const [projectFolders, setProjectFolders] = useState({});
	const [fileCategories, setFileCategories] = useState([]);
	const [showListView, setShowListView] = useState(() => {
		return getValueFromLocalStorage("showFilesInList", false);
	});

	const formatDate = (date) => {
		return dayjs(date).format("DD-MM-YYYY");
	};

	const { isLoading: isFetchingCategories } = useQuery(
		"project-file-by-category",
		getProjectFilesByCategory,
		{
			onSuccess: ({ data: categories }) => {
				setFileCategories(categories);
				console.log("FILE CATEGORIES:: ", categories);
			},
		}
	);

	const { isLoading } = useQuery(
		"project-files-by-folder",
		getProjectFilesByFolders,
		{
			onSuccess: ({ data }) => {
				setProjectFolders(data?.folders);
			},
		}
	);
	const getFilesByFolder = (folderTitle) => {
		return (
			projectFolders[folderTitle]?.filter(
				(subFolder) => subFolder?.attachments.length
			) ?? {}
		);
	};

	const getSubFolderFiles = (folderTitle, subFolderTitle, date) => {
		// console.log("📆 Date", date)
		// console.log("📂 Folder Name", folderTitle)
		// console.log("📂 SubFolder Name", subFolderTitle)

		return (
			projectFolders[folderTitle].find((subFolder) => {
				return (
					subFolder.title === subFolderTitle &&
					formatDate(subFolder.date) === date
				);
			}) ?? {}
		);
	};

	return (
		<ProjectFilesContext.Provider
			value={{
				getFilesByFolder,
				projectFolders,
				isLoading,
				getSubFolderFiles,
				showListView,
				setShowListView,
				fileCategories,
				isFetchingCategories,
			}}
		>
			{children}
		</ProjectFilesContext.Provider>
	);
};
export default ProjectFilesProvider;
