import UnstableInput from "../unstable/input";
import Map from "./map";

const StakeholderMap = ({
    fields = [],
    onChange: handleChange,
    setValue,
    defaultLocation,
}) => {
    const handleLocationChange = (location) => {
        handleChange(location);

        const { properties: locationProps } = location;
        const { address } = locationProps;

        setValue("region", address.city);
        setValue("ward", address.ward);
        setValue("district", address.city_district);
        setValue("country", address.country);
        setValue("street", address?.street ?? address?.road ?? address?.ward);
    };
    return (
        <div className={"space-y-4"}>
            <div className={" h-[27rem] relative rounded-md overflow-hidden"}>
                <Map
                    onChange={handleLocationChange}
                    defaultValue={defaultLocation}
                />
            </div>

            <div className={"grid grid-cols-2 gap-4"}>
                {fields.map((props) => (
                    <UnstableInput props={props} />
                ))}
            </div>
        </div>
    );
};
export default StakeholderMap;
