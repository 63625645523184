import { Tab } from "../../stakeholder/list/stakeholder-details";
import ArrowRight from "../../stakeholder/_partials/ArrowRight";

import EventProvider from "../../../../provider/general/event";
import { Outlet } from "react-router-dom";

const EngagementActivities = () => {
    const tabMenus = [
        {
            title: "Overview",
            url: "/engagement/activities",
            urlPattern: new RegExp("^/engagement/activities/?$"),
        },
        {
            title: "Events",
            url: "/engagement/activities/events/today",
            urlPattern: new RegExp(
                "^/engagement/activities/(events)?(meeting)?/*"
            ),
        },
    ];

    return (
        <EventProvider>
            <section
                className={
                    "px-10 pb-10 space-y-5  h-full bg-[#F9F9F9] overflow-y-auto"
                }
            >
                <p className={"text-sm pt-10 text-[#628799]"}>
                    STAKEHOLDER ENGAGEMENT OVERVIEW
                </p>
                <div
                    className={
                        " flex w-full top-0 bg-white sticky border-[1px] border-[#ECECED] px-8  z-30 items-end justify-between tracking-wide  shadow-sm h-14"
                    }
                >
                    {/* Tab Menu */}
                    <div
                        className={
                            "flex text-[13px] items-center text-[#A7B3C3] space-x-10"
                        }
                    >
                        {tabMenus.map((menuProp) => (
                            <Tab key={menuProp.title} {...menuProp} />
                        ))}
                    </div>

                    <div className={"pb-2 flex items-center space-x-3"}>
                        <button
                            className={
                                "hidden py-2  relative group bg-[#F6F9FE] text-accent text-xs border-[1.2px] border-[#DFE1E5] px-3 overflow-hidden rounded "
                            }
                        >
                            Customize Overview
                        </button>

                        <button
                            className={
                                " py-2 space-x-3 relative group bg-accent flex items-center  text-xs border-[1.2px] border-accent px-3 overflow-hidden rounded text-white"
                            }
                        >
                            <p>Generate Report</p>
                            <ArrowRight color={"#fff"} />
                        </button>
                    </div>
                </div>
                <Outlet />
            </section>
        </EventProvider>
    );
};
export default EngagementActivities;
