import AddRoleForm from "./form/add-role-form";
import RoleList from "./role-list";
import useUserRole from "../../../hooks/useUserRole";
import { Fragment } from "react";
import PermissionForm from "./form/permission-form";

const UserRoles = () => {
	const { showRoleForm, setShowRoleForm } = useUserRole();
	return (
		<Fragment>
			<AddRoleForm isOpen={showRoleForm} onClose={setShowRoleForm} />
			<PermissionForm />
			<section className={"h-full text-dark2"}>
				<div className={" space-y-5"}>
					<RoleList />
				</div>
			</section>
		</Fragment>
	);
};
export default UserRoles;
