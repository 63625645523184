import React from "react";
import {Link} from "react-router-dom";
import {getShortName, slugify} from "../../utils";

const Workspace = ({workspace, ...props}) => {
    return (
        <Link {...props} to={`workspaces/${slugify(workspace.name)}`}
              className="flex  border overflow-hidden w-full rounded-md cursor-pointer">
            <div style={{background: workspace.colour}}
                 className="text-lg font-semibold text-white uppercase center shrink-0 h-14 w-14">
                {getShortName(workspace.name)}
            </div>
            <div className="flex flex-col justify-center h-full px-4">
                <p className=" font-semibold capitalize line-clamp-1">{workspace.name}</p>
                <p className="text-sm font- text-black/60">{workspace.projects.length} Projects</p>
            </div>
        </Link>
    );
};

export default Workspace;
