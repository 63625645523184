import RoleItem from "./role-item";
import RoleDetails from "./role-details";
import React, {Fragment, useEffect, useState} from "react";
import useUserRole from "../../../hooks/useUserRole";
import Button from "../../../components/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {SearchIcon} from "@heroicons/react/outline";
import {isIncludedIn} from "../../../utils";
import {roleTypes} from "../../../constants";

const RoleList = () => {
    const [userRoleList, setUserRoleList] = useState([]);
    const [filteredRoles, setFilteredRoles] = useState([]);
    const coreRoles = [roleTypes.PIU_ADMIN, roleTypes.WB_ADMIN, roleTypes.ROOT_ADMIN];
    const {
        setShowRoleDetails,
        setActiveRole,
        activeRole, isLoading, showRoleDetails,
        userRoles, setShowRoleForm
    } = useUserRole()

    useEffect(() => {
        if (userRoles) {
            const roles = userRoles.filter((role) => {
                return !isIncludedIn(role.type, coreRoles);
            })
            setUserRoleList(roles)
            setFilteredRoles(roles)
        }
    }, [userRoles]);

    if (isLoading) return <p>is loading</p>

    const handleRoleItemClick = (role) => {
        setActiveRole(role)
        setShowRoleDetails(true)
    }

    const handleSearch = ({target}) => {
        const searchKeyword = target.value.toLowerCase();
        setFilteredRoles(userRoleList.filter((role) => {
            return role?.name?.toLowerCase().includes(searchKeyword);
        }))
    }

    return (
        <Fragment>
            <header className={' pt-5 backdrop-blur-lg sticky top-0'}>
                <div
                    className={'bg-white/80  backdrop-blur-lg  items-between border-b-[1.5px] border-[#E5E5E5] px-5 py-3'}>
                    <h3 className={'font-medium text-dark text-lg'}>Roles List</h3>
                    <div className={'flex items-center space-x-2'}>
                        <div className={` relative `}>
                            <input
                                onChange={handleSearch}
                                className={` w-full rounded-md tracking-wide outline-none focus:border-accent
                                    focus:border-[1.7px] focus:ring-4 enable-transition focus:ring-[#5D9EE9]/30  font-light text-sm pl-8 py-2
                               `}
                                type="text" placeholder={'Search..'}/>
                            <div className={'absolute top-0 left-0 flex items-center pl-2 h-full'}>
                                <SearchIcon className={'h-5'}/>
                            </div>
                        </div>
                        <Button
                            onClick={() => setShowRoleForm(true)}
                            className={'bg-primary flex whitespace-nowrap space-x-1 items-center'}>
                            <FontAwesomeIcon icon={faPlus} className={'h-4 w-4'}/>
                            <span>Create Roles</span>
                        </Button>
                    </div>
                </div>
            </header>

            <div>
                {Object.keys(activeRole).length ?
                    <RoleDetails
                        isOpen={showRoleDetails}
                        role={activeRole}
                        onClose={setShowRoleDetails}
                    /> : null}
                <div className={'bg-white rounded-lg overflow-hidden'}>
                    <div className={'grid grid-cols-5  font-semibold gap-4  text-sm  px-5'}>
                        <div className="py-3 flex items-center">Name</div>
                        <div className="flex col-span-2 items-center">
                            Description
                        </div>
                        <div className="center">Permission</div>
                        <div className="center">Action</div>
                    </div>
                    {filteredRoles.map((role, index) => (
                        <RoleItem role={role} key={index} index={index} onClick={handleRoleItemClick}/>
                    ))}
                </div>
            </div>
        </Fragment>
    )
}
export default RoleList;
