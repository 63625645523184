import {UserGroup} from "components/user-group";
import {ChevronRightIcon, TrashIcon} from "@heroicons/react/outline";
import {saveValueToLocalStorage} from "hooks/useLocalStorageState";
import {Link} from "react-router-dom";
import {Can, permissions} from "provider/permission";

const Participant = ({stakeholder = {}, handleRemove, showRemoveBtn = true}) => {
    return (
        <div
            // key={cardKey}
            className={
                `bg-[#F5F6F7] p-2 border-[1px] ${showRemoveBtn ? " grid-cols-3" : " grid-cols-2"} border-[#E4E4E4] grid rounded-[4px]`
            }
        >
            <div className={"flex  space-x-4"}>
                <div>
                    <div
                        className={
                            "h-8 mt-1 w-8 flex justify-center items-center primary-gradient rounded-full"
                        }
                    >
                        <UserGroup className={"text-5 w-5 "} color={"#fff"}/>
                    </div>
                </div>
                <div className={"space-y-1"}>
                    <p className={"text-dark whitespace-nowrap font-medium text-[15px] "}>
                        {stakeholder.name}
                    </p>
                    <p className={"text-[13px] text-[#8F98A9]"}>
                        {stakeholder?.company?.stakeholderPosition || stakeholder.type}
                    </p>
                </div>
            </div>

            {showRemoveBtn ? (
                <Can permission={permissions.UPDATE_STAKEHOLDERS}>
                    <div className={'center'}>
                        <button onClick={() => handleRemove(stakeholder)}
                                className={'text-[13px] space-x-2 text-[#FC635E] flex items-center'}>
                            <TrashIcon className={'h-4 w-4'}/>
                            <span>Remove</span>
                        </button>
                    </div>
                </Can>
            ) : null}
            <div
                className={
                    "flex items-center text-[#51BF82] flex-right hover:underline cursor-pointer space-x-2 font-light"
                }
            >
                {" "}
                <Can permission={permissions.READ_STAKEHOLDERS}>
                    <Link
                        to={`/stakeholders/${stakeholder?.name?.toLowerCase()}/overview`}
                        target="_blank"
                        className={"text-[13px] flex"}>
                          <span
                              onClick={() =>
                                  saveValueToLocalStorage("currentStakeholder", stakeholder)
                              }
                          >
                            View Stakeholder Profile
                          </span>
                        <ChevronRightIcon className={"h-4 w-4"}/>
                    </Link>
                </Can>
            </div>
        </div>
    );
};
export default Participant;
