import {SearchIcon} from "@heroicons/react/outline";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import React, {useState} from "react";
import {Link} from "react-router-dom";
import PIUListItem from "./piu-list-item";
import {useQuery} from "react-query";
import {getPIUS} from "provider/api";

const PIUList = () => {
    const [piuList, setPiuList] = useState([]);
    const [filteredPIUList, setFilteredPIUList] = useState([]);

    const {isLoading} = useQuery("system-pius", getPIUS, {
        onSuccess: ({data: pius}) => {
            setFilteredPIUList(pius);
            setPiuList(pius);
        },
    });

    const handleSearch = ({target}) => {
        const {value: searchKeyword} = target;
        setFilteredPIUList(
            piuList.filter((piu) => {
                return piu?.registeredName
                    ?.toLowerCase()
                    ?.includes(searchKeyword.toLowerCase());
            })
        );
    };

    const tableHeader = [
        "Registered Name",
        "Founded In",
        "Email",
        "Region",
        "Reg Number",
        "Action",
    ];

    return (
        <section className={"space-y-4"}>
            <header className={" pt-5"}>
                <div
                    className={
                        " backdrop-blur-lg  items-between border-b-[1.5px] border-[#E5E5E5] px-5 py-3"
                    }
                >
                    <h3 className={"font-medium text-dark text-xl "}>
                        Lists of all PIUs
                    </h3>
                    <div className={"flex items-center space-x-2"}>
                        <div className={` relative `}>
                            <input
                                onChange={handleSearch}
                                className={`w-full rounded-md tracking-wide outline-none focus:border-accent
                                       focus:border-[1.7px] focus:ring-4 bg-transparent enable-transition focus:ring-[#5D9EE9]/30  font-light text-sm pl-8 py-2
                                    `}
                                type="text"
                                placeholder={"Search.."}
                            />
                            <div
                                className={
                                    "absolute top-0 left-0 flex items-center pl-2 h-full"
                                }
                            >
                                <SearchIcon className={"h-5"}/>
                            </div>
                        </div>
                        <Link
                            to={"/register-piu"}
                            className={
                                "bg-primary text-white px-4 py-1.5 rounded hover:opacity-90 flex whitespace-nowrap space-x-1 items-center"
                            }
                        >
                            <FontAwesomeIcon
                                icon={faPlus}
                                className={"h-4 w-4"}
                            />
                            <span>Add PIU</span>
                        </Link>
                    </div>
                </div>
            </header>
            <div className={" rounded-lg"}>
                {/* table header */}
                <div className={'flex'}>
                    <div className={'h-10 w-10 shrink-0'}></div>
                    <div className={"grid w-full grid-cols-7"}>
                        {tableHeader.map((property, index) => (
                            <div
                                className={`font-semibold text-[#484848]  px-5 text-[13px] tracking-wider uppercase py-4 ${
                                    index === 0 ? "text-left col-span-2" : "center"
                                }  font-medium`}
                            >
                                {property}
                            </div>
                        ))}
                    </div>
                </div>

                {/* table body */}
                <div className={'space-y-3'}>
                    {filteredPIUList.map((piu) => (
                        <PIUListItem key={piu?._id} piu={piu}/>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default PIUList;
