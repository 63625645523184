const Label = ({
    text="",
    className="",
    }) =>{

    return(
        <label
            htmlFor={text.replace(' ', '')}
            className={`${className} text-primary/80 capitalize tracking-wider`}>
            {text}
        </label>
    )
}
export default Label;