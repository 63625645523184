import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const PieChart = ({
    activitiesStatus = {},
    innerSize,
    chartTitle,
    showLegend = false,
    showDataLabels = true,
    isLoading = false,
}) => {
    const options = {
        plotOptions: {
            series: {
                dataLabels: {
                    enabled: true,
                    format: "{point.name}: {point.y:.0f}%",
                },
                point: {
                    events: {
                        click: function () {
                            window.open(this.options.url, "_blank").focus();
                        },
                    },
                },
            },
            pie: {
                allowPointSelect: true,
                cursor: "pointer",
                borderWidth: 2,
                dataLabels: {
                    enabled: showDataLabels,
                },
                colors: ["#A5DED4", "#FDD1A9", "#168ADC", "#FDC2C0"],
                showInLegend: showLegend,
            },
        },

        title: {
            text: chartTitle ? chartTitle : "",
            y: 3.5,
            style: {
                display: false,
                fontSize: "14px",
                fontWeight: "bold",
                color: "#253A53",
            },
        },
        chart: {
            renderTo: "chart", // Spacing between Title & Chart
            defaultSeriesType: "areaspline",
        },
        series: [
            {
                type: "pie",
                name: "Activities count",
                innerSize: innerSize ? innerSize : "80%",
                data: [
                    {
                        name: "Upcoming",
                        color: "#168ADC",
                        y: activitiesStatus?.upcoming || 0,
                        url: "engagement/activities/events/upcoming",
                    },
                    {
                        name: "Overdue",
                        color: "#FDC2C0",
                        y: activitiesStatus?.overdue || 0,
                        url: "http://my_site1.com",
                    },
                    {
                        name: "Completed On Time",
                        color: "#A5DED4",
                        y: activitiesStatus?.completed || 0,
                        url: "http://my_site1.com",
                    },
                    {
                        name: "Completed Late",
                        color: "#FDD1A9",
                        y: activitiesStatus?.lateCompleted || 0,
                        url: "http://my_site1.com",
                    },
                ],
            },
        ],
    };

    return (
        <div className={"h-full highcharts relative "}>
            <HighchartsReact highcharts={Highcharts} options={options} />
        </div>
    );
};
export default PieChart;
