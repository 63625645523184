import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/free-solid-svg-icons";
import Modal from "components/Modal"
import Input from "components/form-control/Input";
import {RadioGroup} from "@headlessui/react";
import ColorItem from "../../grievance/form/color-item";
import React, {useState} from "react";
import Button from "components/Button";
import {useMutation, useQueryClient} from "react-query";
import {createWorkspace, updateWorkspace} from "../../../../provider/api";
import {getFormData, isEmptyObject, slugify} from "utils";
import {useNavigate} from "react-router-dom";


const CreateWorkspaceForm = ({showForm, setShowForm, activeWorkspace}) => {
    const _isEditingWorkspace = !isEmptyObject(activeWorkspace);
    const [selectedColor, setSelectedColor] = useState(activeWorkspace?.colour ?? "#2B9BD5");
    const [name, setName] = useState("");
    const colorList = ["#2B9BD5", "#19A89D", "#233A4F", "#83A5A8"];
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    // send post request to create new workspace
    const {isLoading, mutate: _createWorkspace} =
        useMutation('create-workspace', createWorkspace, {
            onSuccess: ({data}) => {
                updateWorkspaceCache(data)
                handleClose()
            }
        })

    // send put request to update active workspace
    const {isLoading: isUpdating, mutate: _updateWorkspace} =
        useMutation('update-worskapce', updateWorkspace, {
            onSuccess: ({data: workspace}) => {
                navigate(`/settings/project-management/workspaces/${slugify(workspace.name)}`)
                updateWorkspaceCache(workspace)
                handleClose()
            }
        })
    const handleClose = () => {
        setShowForm(false)
    }

    const updateWorkspaceCache = (workspace) => {
        if (_isEditingWorkspace) {
            queryClient.setQueryData('workspaces', (oldQueryData) => {
                return {
                    ...oldQueryData,
                    data: oldQueryData.data.map((_workspace) => {
                        if (workspace?._id === _workspace?._id) {
                            return workspace
                        }
                        return _workspace;
                    })
                }
            })
        } else {
            queryClient.setQueryData('workspaces', (oldQueryData) => {
                return {
                    ...oldQueryData,
                    data: [
                        ...oldQueryData.data,
                        workspace
                    ]
                }
            })
        }
        // ...
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        const {name, colour} = getFormData(e.target);
        if (name && colour) {
            _isEditingWorkspace ?
                _updateWorkspace({
                    name,
                    colour,
                    workspaceId: activeWorkspace?._id
                }) :
                _createWorkspace({
                    name,
                    colour
                })
        }
    }
    return (
        <Modal isOpen={showForm} onClose={setShowForm}>
            <div className={"w-2/6 bg-white rounded-lg overflow-hidden"}>
                <div className="flex justify-between px-5 py-3 bg-gray-300 rounded-t-md">
                    <p className="font-semibold text-[#002F4F]">
                        Create New Workspace
                    </p>

                    <button
                        onClick={handleClose}
                        className="-mr-1 border-2 focus:ring-0 ring-0 border-[#192B40]/50 w-6 h-6 bg-[#628799] text-white rounded-full grid place-items-center"
                    >
                        <FontAwesomeIcon color="FFFFFF" icon={faXmark}/>
                    </button>
                </div>

                <form action="" onSubmit={handleSubmit} className={'p-5 space-y-4'}>
                    <div className={'space-y-1'}>
                        <label htmlFor="name" className={'block mb-2 text-sm font-medium text-[#002F4F]'}>Workspace
                            Name</label>
                        <Input defaultValue={activeWorkspace?.name ?? ""} name={'name'} className={'text-sm'}/>
                    </div>

                    <div>
                        <RadioGroup
                            className={"space-y-1"}
                            value={selectedColor}
                            onChange={setSelectedColor}
                            name="colour"
                        >
                            <RadioGroup.Label className={'block mb-2 text-sm font-medium text-[#002F4F]'}>
                                {" "}
                                Select color
                            </RadioGroup.Label>
                            <div className={"flex space-x-3 items-center"}>
                                {colorList.map((color) => (
                                    <RadioGroup.Option
                                        className={"focus:ring-0"}
                                        key={color}
                                        value={color}
                                    >
                                        {({active, checked}) => (
                                            <ColorItem
                                                color={color}
                                                checked={checked}
                                                isActive={active}
                                            />
                                        )}
                                    </RadioGroup.Option>
                                ))}
                            </div>
                        </RadioGroup>
                    </div>

                    {/*  submit button */}
                    <div className={"col-span-2 pt-3"}>
                        <Button
                            loading={isLoading}
                            block={true}
                            type="submit"
                            className={
                                "flex items-center space-x-2 text-sm"
                            }
                        >
                            <span>{_isEditingWorkspace ? 'UPDATE' : 'CREATE'}</span>

                        </Button>
                    </div>
                </form>
            </div>
        </Modal>
    )
}
export default CreateWorkspaceForm;
