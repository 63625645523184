

const StatusBar = ({ percentage = 0 }) => {

    const percentageStr = percentage.toString() + '%';

    // console.log('Percentage string is:' + percentageStr)

    return (
        percentage >= 0 && percentage <= 20 ? <div className="w-full bg-[#E8EBF1] h-1"><div className="bg-[#f44336] transition-all h-1" style={{ width: percentageStr }}></div></div> :
        percentage >= 21 && percentage <= 40 ? <div className="w-full bg-[#E8EBF1] h-1"><div className="bg-[#ff9800] transition-all h-1" style={{ width: percentageStr }}></div></div> :
        percentage >= 41 && percentage <= 60 ? <div className="w-full bg-[#E8EBF1] h-1"><div className="bg-[#ffc107] transition-all h-1" style={{ width: percentageStr }}></div></div> :
        percentage >= 61 && percentage <= 80 ? <div className="w-full bg-[#E8EBF1] h-1"><div className="bg-[#a3e635] transition-all h-1" style={{ width: percentageStr }}></div></div> :
        percentage >= 81 && percentage <= 100 ? <div className="w-full bg-[#E8EBF1] h-1"> <div className="bg-[#56BE84] transition-all h-1" style={{ width: percentageStr }}></div></div> :       
        <div className="w-full bg-[#E8EBF1] h-1"></div>

    )
};


export default StatusBar