import { useState } from "react";
import useDownloadFileFromS3 from "../../hooks/useDownloadFileFromS3";
import { handlePrint as printFile } from "../../components/image-viewer/image-viewer";
import { PrinterIcon, DownloadIcon } from "../../svg";
import SpinLoader from "../../components/SpinLoader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbtack } from "@fortawesome/free-solid-svg-icons";

const ImageCard = (
	{ imageFile, height = "h-40", onClick: handleClick, showActions = true },
	...props
) => {
	const imageClassName = "h-full w-full object-cover";
	const [isImageFullLoaded, setIsImageFullLoaded] = useState(false);
	const [isDownloading, setIsDownloading] = useState(false);
	const { s3FileDownloader } = useDownloadFileFromS3((progress) => {
		if (parseInt(progress) === 100) {
			setIsDownloading(false);
		}
	});

	const handleFileDownload = (e) => {
		e.stopPropagation();
		setIsDownloading(true);
		s3FileDownloader(imageFile.fileKey, imageFile.title);
	};

	const handleFilePrint = (e) => {
		e.stopPropagation();
		printFile(imageFile.url);
	};

	const handlePinFile = (e) => {
		e.stopPropagation();
		// ...
	};
	return (
		<div
			onClick={() => handleClick(imageFile)}
			{...props}
			className={`${height} ${
				isImageFullLoaded
					? "bg-secondary/20 animate-none pointer-events-auto"
					: "animate-pulse pointer-events-none bg-secondary/20"
			} cursor-pointer 2xl:h-56  overflow-hidden  rounded-[4px] relative`}
		>
			<img
				onLoad={() => setIsImageFullLoaded(true)}
				loading={"lazy"}
				className={`${imageClassName} ${
					isImageFullLoaded
						? "scale-100 opacity-100"
						: "scale-50 opacity-0"
				} origin-center enable-transition`}
				src={imageFile?.url}
				alt=""
			/>

			<div
				className={` ${
					isImageFullLoaded ? "flex" : "hidden"
				} p-3 text-white bg-black/50 hover:bg-black/40 flex-col justify-between absolute inset-0`}
			>
				<div className="items-between">
					<span
						className={
							"bg-secondary/30 backdrop-blur-[2px] uppercase rounded-[3px] text-xs px-3 py-1.5 "
						}
					>
						{imageFile?.type}
					</span>

					<div
						className={` ${
							showActions ? "flex" : "hidden"
						} items-center space-x-1`}
					>
						<button
							onClick={handleFileDownload}
							className="rounded bg-white/30 group center hover:bg-white/40 backdrop-blur-sm w-7 h-7"
						>
							{isDownloading ? (
								<SpinLoader size={"small"} color={"#fff"} />
							) : (
								<DownloadIcon
									className={
										"h-3 w-3 fill-white/80 group-hover:fill-white"
									}
								/>
							)}
						</button>
						<button
							onClick={handleFilePrint}
							className="rounded bg-white/30 group center hover:bg-white/40 backdrop-blur-sm w-7 h-7"
						>
							<PrinterIcon
								className={
									"h-4 w-4 fill-white/80 group-hover:fill-white"
								}
								color={"#fff"}
							/>
						</button>

						<button
							onClick={handlePinFile}
							className="rounded bg-white/30 group center hover:bg-white/40 backdrop-blur-sm w-7 h-7"
						>
							<FontAwesomeIcon
								icon={faThumbtack}
								className={
									"h-4 w-4 text-white/80 group-hover:text-white"
								}
							/>
						</button>
					</div>
				</div>
				<p className={" text-sm whitespace-nowrap truncate"}>
					{imageFile?.title}
				</p>
			</div>
		</div>
	);
};
export default ImageCard;
