import { useEffect, useState } from "react";
import { ExclamationCircleIcon } from "@heroicons/react/outline";

const RegularInput = (
    {
        type = "text",
        errorMessage = "",
        placeholder = "",
        defaultValue,
        onChange: handleOnChange,
        id = null,
        name = "",
        onBlur,
        className = "",
        autoFocus = true,
        isError: errorState = false,
    },
    ...props
) => {
    const [isError, setIsError] = useState(errorState);

    useEffect(() => {
        setIsError(errorState);
    }, [errorState]);

    return (
        <div>
            <div className={"relative"}>
                <input
                    autoFocus={autoFocus}
                    {...props}
                    id={id ?? name}
                    name={name}
                    onBlur={onBlur}
                    type={type}
                    defaultValue={defaultValue}
                    onChange={handleOnChange}
                    placeholder={placeholder}
                    className={` ${className}
                w-full text-gray-500 focus:border-[1.7px] focus:ring-4 enable-transition  placeholder-[#AAB6C5] 
                focus:outline-none tracking-wider py-3 border-[1.4px] 
                ${
                    isError
                        ? "border-red-500 focus:ring-red-500/30 focus:border-red-500"
                        : "border-[#D2D4DA] focus:border-accent focus:ring-[#5D9EE9]/30"
                }
                 rounded-[5px]
            `}
                />
                {isError ? (
                    <ExclamationCircleIcon
                        className={
                            "absolute right-2 top-0 h-5 w-5 text-red-500 bottom-0 my-auto"
                        }
                    />
                ) : null}
            </div>
            {isError ? (
                <p className={"text-red-500 pt-1 text-sm"}>{errorMessage}</p>
            ) : null}
        </div>
    );
};
export default RegularInput;
