import { ArrowRightIcon } from "../../../svg";
import React from "react";
import Image from "../../../components/image";
import { Link, useNavigate } from "react-router-dom";
import { flatten } from "utils";
import { useProjectImages } from "../../../hooks";

const AttendanceImage = () => {
	const navigate = useNavigate();
	const { isLoading, images = {} } = useProjectImages();
	const { attendances: attendanceImages = [] } = images;

	const arrOfImage = flatten(
		attendanceImages.map((file) => {
			return file.attachments;
		})
	);

	const defaultImage =
		"https://i.pinimg.com/736x/ba/98/32/ba9832b0d4d97204f8b102d296669c2b.jpg";

	return (
		<Link
			to={"attendance"}
			className={
				"bg-red-500 cursor-pointer relative h-[11.5rem] overflow-hidden rounded-[10px]"
			}
		>
			<Image
				imageUrl={arrOfImage[0]?.url ?? defaultImage}
				alt="attendance"
			/>

			<div
				className={
					"bg-black/50 hover:bg-black/40 p-6 text-white flex flex-col justify-between absolute inset-0"
				}
			>
				<div>
					<p className={"text-lg"}>Attendance Photos</p>
					<p className={"font-light text-sm"}>
						{arrOfImage.length} photos
					</p>
				</div>
				<div>
					<div className={`h-8 w-8 bg-white rounded-full center`}>
						<ArrowRightIcon
							color={"#628799"}
							className={"h-7 w-8"}
						/>
					</div>
				</div>
			</div>
		</Link>
	);
};
export default AttendanceImage;
