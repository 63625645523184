const NjombeSvg = ({ color, isLoading = false }) => {
    return (
        <path
            className={`${isLoading ? "animate-pulse" : ""}`}
            id="Path_428"
            data-name="Path 428"
            d="M358.132,455.285l-3.8-4.033-.02-.009,0,0-1.109-.509-.675-.742-.295-.172L350.9,448.2l-.542-.3-.372-.442-.052-.034.153-1.3-1.572-1.581-.792-.108-.727-.318-1.184-1.626-2.138-3.708-.876-1.138-.1-1.24-.428-.526-1.33-.3.21-.822.833-.444-.659-1.788-.905-1.676-1.3-1.508-.227-1.882,2.243-3.671,1.612-1.446,2.129-.423,2.885,1,1.091-.36.4-1.137,1.984-.811,2.287.209,2.251-.053,3.9-.956,3.75.54,2.016-.043,1.81-.726,1.373-1.466,1.022-2.064,1.257-.885,1.463-.542,1.281.151.876-.607.982-2.577,5.536,1.688v1.985l9.376,3.309-.436,7.062,3.707,2.649,3.706.221,2.856-.951.739-.356.651-.552.84.4,2.679,4.1,7.4,5.839.538,1.8.117,1.881-1.176,1.134-1.406,1.026-1.794.862-1.491.942-1.176,1.227-1.483.969-3.4,3.171-2.534,1.366-3.806,4.074-2.849,1.177-1.293.7-.376,1.083.724,1.074.865.816.594,1.415-.121,1.936,1.548,2.27.372,2.6-3.289,4.591-.493,1.338-1.406.3-1.431-.01-1.321.481-.934,1.029-.2,2.611-3.354,2.608-2.885,1.419-1.806,2.549-3.382,2.187-1.1.5-1.225.08-.452-.3-.484-.107-1.011.189-.083,0-.026-.213.081-.51.364-.892.085-.378-.165-.739-.752-1.261-.174-.858.792-4.338.012-.423-.085-.493-.327-.866-.372-2.11-1.3-2.931-.3-1.355.081-.394.347-.775.081-.455-.053-.431-.456-1.359-1.176-1.67-.445-.816-3.9-4.856-.045-.164-.707-.685-.178-.1-.869-.9-.227-.316-.194-.75-.1-.709-.246-.524Z"
            transform="translate(-174.586 -112.441)"
            fill={color}
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="0.5"
        />
    );
};
export default NjombeSvg;
