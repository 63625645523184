import React from "react";
import {useEngagementContext} from "./engagement";

const EngagementSummary = () => {
    const {events, engagementLevels} = useEngagementContext();

    const getTotalMeeting = () => {
        return events.filter((event) => {
            return event.isMeeting;
        }).length
    }

    return (
        <div
            className={'border-[1px] flex px-5 items-center justify-between border-light-gray bg-[#F9F9F9] py-3 rounded'}>
            <div className={'space-y-1'}>
                <p className={'text-[10px] font-semibold text-[#253A53] tracking-wide'}>ALL ACTIVITIES</p>
                <p className={'text-accent font-light text-sm'}>{events.length}</p>
            </div>
            <div className={'space-y-1'}>
                <p className={'text-[10px] font-semibold text-[#253A53] tracking-wide'}>MEETINGS</p>
                <p className={'text-accent font-light text-sm'}>{getTotalMeeting()}</p>
            </div>
            <div className={'space-y-1'}>
                <p className={'text-[10px] font-semibold text-[#253A53] tracking-wide'}>DESIRED LEVEL</p>
                <p className={'text-accent font-light text-sm'}>{engagementLevels?.desiredLevel}</p>
            </div>
            <div className={'space-y-1'}>
                <p className={'text-[10px] font-semibold text-[#253A53] tracking-wide'}>CURRENT LEVEL</p>
                <p className={'text-accent font-light text-sm'}>{engagementLevels?.currentLevel}</p>
            </div>
        </div>
    )
}
export default EngagementSummary