import { saveMultiValueToLocalStorage } from "hooks/useLocalStorageState";
import { localStorageKeys } from "../../../../constants";
import { slugify } from "utils";

const parseJSON = (jsonString) => {
    try {
        return JSON.parse(jsonString);
    } catch (e) {
        return jsonString;
    }
};

const getStakeholderEngagementLevel = (level) => {
    const levels = {
        Weak: { id: 1, name: "Weak" },
        Medium: { id: 2, name: "Medium" },
        Strong: { id: 3, name: "Strong" },
    };
    return levels[level] || "";
};

const saveStakeholderDetailsAndNavigateToForm = async (
    stakeholder,
    navigate
) => {
    const { stakeholderFrom: stakeholderLSKeys } = localStorageKeys;
    const {
        contactPerson,
        description,
        address,
        company,
        location,
        relationshipHolder,
        hasContactPerson,
        hasRelationshipHolder,
        concern,
        levelOfInfluence,
        levelOfInterest,
        goals,
        ...rest
    } = stakeholder;

    const stakeholderLocation = {
        properties: {
            address: { ...address, city_district: address.district },
            display_name: location.name,
        },
        geometry: {
            coordinates: [location.longitude, location.latitude],
        },
    };

    const stakeholderBasicDetails = {
        ...rest,
        location: stakeholderLocation,
        organization: company?.name,
        position: company?.stakeholderPosition,
        description: parseJSON(description),
        postalCode: address.postalCode,
        region: address.region,
        district: address.district,
        ward: address.ward,
        street: address.street,
        hasContactPerson,
        hasRelationshipHolder,
    };

    const stakeholderContactPerson = contactPerson
        ? {
              ...contactPerson,
              description: parseJSON(contactPerson.description),
          }
        : {};

    const _levelOfInfluence = getStakeholderEngagementLevel(levelOfInfluence);
    const _levelOfInterest = getStakeholderEngagementLevel(levelOfInterest);

    const stakeholderData = {
        [stakeholderLSKeys.IS_UPDATING_DETAILS]: true,
        [stakeholderLSKeys.BASIC_DETAILS]: stakeholderBasicDetails,
        [stakeholderLSKeys.CONTACT_PERSON]: stakeholderContactPerson,
        [stakeholderLSKeys.LEVEL_OF_INTEREST]: _levelOfInterest,
        [stakeholderLSKeys.LEVEL_OF_INFLUENCE]: _levelOfInfluence,
        [stakeholderLSKeys.RELATION_SHIP_HOLDER]: relationshipHolder ?? {},
        [stakeholderLSKeys.GOALS]: goals,
        [stakeholderLSKeys.CONCERN]: parseJSON(concern),
    };

    await saveMultiValueToLocalStorage(stakeholderData);
    navigate(`/edit-stakeholder/${slugify(stakeholder.name)}`);
};

export default saveStakeholderDetailsAndNavigateToForm;
