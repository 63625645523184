import EventIllustrator from "../../../overview/upcoming-events/empty-state/event-illustrator";
import EventButton from "../../../../../../../components/calender/event-button";

const EmptyState = ({ eventType }) => {
    return (
        <div className={"py-16 bg-gray-50 space-y-4  center flex-col"}>
            <div
                className={
                    "h-44 w-44 shadow p-5 center rounded-full overflow-hidden bg-gray-200"
                }
            >
                <EventIllustrator />
            </div>
            <div className={"center space-y-4 flex-col"}>
                <p className={"text-center text-gray-400"}>
                    There is no {eventType} events to display at the moment. You
                    can get started by creating events, <br /> by clicking the
                    link bellow.
                </p>
                <EventButton />
            </div>
        </div>
    );
};
export default EmptyState;
