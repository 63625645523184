import { CheckIcon } from "@heroicons/react/outline";
import {
    CommunityIcon,
    IndividualIcon,
    OrganizationalIcon,
} from "../../../../../../svg";
import { faEarth, faPersonWalking } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CustomRadioButton = ({ type, isSelected, icon = "" }) => {
    const getProperIcon = () => {
        switch (type) {
            case "Individual": {
                return <IndividualIcon isSelected={isSelected} />;
            }
            case "Organizational": {
                return <OrganizationalIcon isSelected={isSelected} />;
            }
            case "Community": {
                return <CommunityIcon isSelected={isSelected} />;
            }
            case "Online EventLayout": {
                return (
                    <FontAwesomeIcon
                        icon={faEarth}
                        className={`${
                            isSelected ? "text-accent " : "text-[#AAB6C5]"
                        } h-6 fill-current w-6`}
                    />
                );
            }
            case "Physical EventLayout": {
                return (
                    <FontAwesomeIcon
                        icon={faPersonWalking}
                        className={`${
                            isSelected ? "text-accent" : "text-[#AAB6C5]"
                        } h-6 fill-current w-6`}
                    />
                );
            }

            default:
                return icon;
        }
    };
    return (
        <div
            className={` ${
                isSelected ? "border-accent" : "border-[#DDEAFB]"
            } bg-[#F6F9FE]  justify-between 
            cursor-pointer flex items-center transition-all ease-in-out border-[1.7px] w-52 h-24 rounded-md p-3`}
        >
            <div className={"h-full flex items-center"}>
                <div className={"space-y-3"}>
                    {getProperIcon()}
                    <p
                        className={`text-xs ${
                            isSelected ? "text-accent" : "text-[#AAB6C5]"
                        }  tracking-wide uppercase`}
                    >
                        {type}
                    </p>
                </div>
            </div>

            <div className={"h-full "}>
                <div
                    className={`h-6 w-6 ${
                        isSelected
                            ? "border-accent bg-accent "
                            : "border-light-gray bg-white"
                    }  border-[1.7px] rounded-full flex items-center justify-center`}
                >
                    <CheckIcon
                        className={`h-5 ${
                            isSelected
                                ? "opacity-100 scale-100"
                                : "opacity-0 scale-50"
                        } transition-all ease-in-out text-white w-5`}
                    />
                </div>
            </div>
        </div>
    );
};
export default CustomRadioButton;
