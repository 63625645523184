import SummaryPieChart from "./summary-pie-chart";
import {CalendarCheckIcon} from "svg";
import {useCommitmentAnalytics} from "hooks";
import Loading from "./loading";

const CommitmentSummary = () => {
    const {isLoading, commitmentAnalytics} = useCommitmentAnalytics()
    if (isLoading) return <Loading/>
    const {general} = commitmentAnalytics;

    const chartLabel = [
        {
            name: 'Incomplete',
            percentage: Math.floor(((general?.inComplete ?? 0) * 100) / general?.total ?? 0),
            color: '#F2BC9E'
        },
        {
            name: 'On Time',
            percentage: Math.floor(((general?.completedOnTime ?? 0) * 100) / general?.total ?? 0),
            color: '#70AB91'
        },
        {
            name: 'Late',
            percentage: Math.floor(((general?.completedLate ?? 0) * 100) / general?.total ?? 0),
            color: '#1F78B4'
        },
        {
            name: 'Overdue',
            percentage: Math.floor(((general?.overdue ?? 0) * 100) / general?.total ?? 0),
            color: '#B1CBDC'
        },
    ]

    const statistics = [
        {name: "Completed on time", total: general?.completedOnTime ?? 0},
        {name: "Incomplete", total: general?.inComplete ?? 0},
        {name: "Completed Late", total: general?.completedLate ?? 0},
        {name: "Overdue", total: general?.overdue ?? 0},
    ]
    return (
        <div style={{width: "calc(100% - 1.5rem)"}} className={' bg-white  p-12 space-y-6 '}>
            <p className={'text-[15.5px] text-[#8F9198]'}>COMMITMENT COMPLETION RATE</p>
            <div className={'flex justify-between'}>
                <div className={'w-[60%]  space-y-7 pt-5'}>
                    {statistics.map((statistics) => (
                        <StatisticNumber statistics={statistics} key={statistics.name}/>
                    ))}
                </div>
                <div className={'h-full w-full m-auto center'}>
                    <div className={'h-80 w-full'}>
                        <SummaryPieChart statistics={chartLabel}/>
                    </div>
                </div>
                <div className={'w-1/2 flex justify-center pt-5'}>
                    <div className={'space-y-8'}>
                        {chartLabel.map((label) => (
                            <ChartLabel label={label} key={label.name}/>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export const StatisticNumber = ({statistics}, ...props) => {
    return (
        <div {...props} className={'flex space-x-4'}>
            <CalendarCheckIcon/>
            <div className={'space-y-2'}>
                <p className={'text-[#606C7C]  w-12 text-lg font-semibold pb-1 border-b-[1.87px] border-b-[#CED1D6]'}>
                    {statistics.total}
                </p>
                <p className={'text-[#707070] font-light text-[13px]'}>{statistics.name}</p>
            </div>
        </div>
    )
}

export const ChartLabel = ({label}, ...props) => {
    return (
        <div {...props} className={'flex space-x-4 pl-5'}>
            <div style={{backgroundColor: label.color}} className={'h-2.5 mt-2 w-2.5 rounded-full'}></div>
            <div className={'space-y-1'}>
                <p className={'text-[#90B5CE]  w-1/2 text-lg font-semibold pb-1 '}>{label.percentage}%</p>
                <p className={'text-[#868686] tracking-wide font-light text-[13px]'}>{label.name}</p>
            </div>
        </div>
    )
}
export default CommitmentSummary;