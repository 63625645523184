// Third-part imports
import { useContext, useEffect, useState } from "react";
import { useMutation } from "react-query";

// Local imports
import { FormContext } from "../index";
import {
    getValueFromLocalStorage,
    saveMultiValueToLocalStorage,
    saveValueToLocalStorage,
} from "../../../../../../hooks/useLocalStorageState";
import { updateStakeholderDetails } from "../../../../../../provider/api";
import LevelInput from "./level-input";
import {
    cacheActions,
    localStorageKeys,
    queryKeys,
} from "../../../../../../constants";
import ActionButtons from "../_partials/action-buttons";
import { toast } from "react-toastify";
import useUpdateCache from "../../../../../../hooks/useUpdateCache";

const LevelOfInterestAndInfluence = () => {
    const { stakeholderFrom: stakeholderLSKeys } = localStorageKeys;
    const [showNextButton, setShowNextButton] = useState(false);
    const [currentStep, setCurrentStep] = useContext(FormContext);
    const updateCache = useUpdateCache();

    // Data from local storage
    const isUpdatingStakeholder = getValueFromLocalStorage(
        stakeholderLSKeys.IS_UPDATING_DETAILS,
        false
    );

    const interest = getValueFromLocalStorage(
        stakeholderLSKeys.LEVEL_OF_INTEREST,
        {}
    );
    const influence = getValueFromLocalStorage(
        stakeholderLSKeys.LEVEL_OF_INFLUENCE,
        {}
    );
    const basicDetails = getValueFromLocalStorage(
        stakeholderLSKeys.BASIC_DETAILS,
        {}
    );

    const contactPerson = getValueFromLocalStorage(
        stakeholderLSKeys.CONTACT_PERSON,
        {}
    );

    const relationshipHolder = getValueFromLocalStorage(
        stakeholderLSKeys.RELATION_SHIP_HOLDER,
        {}
    );

    const [levelOfInterest, setLevelOfInterest] = useState(interest);
    const [levelOfInfluence, setLevelOfInfluence] = useState(influence);

    // Update state that responsible to show next button if all levels are selected
    useEffect(() => {
        if (levelOfInterest.id && levelOfInfluence.id) {
            setShowNextButton(true);
        }
    }, [levelOfInfluence, levelOfInterest]);

    const updateStakeholderCacheAndAdvanceCurrStep = async ({
        data: stakeholder,
    }) => {
        await updateCache(
            queryKeys.STAKEHOLDERS,
            stakeholder,
            cacheActions.UPDATE
        );
        advanceTheCurrentStep();
    };

    const { mutate: updateStakeholder } = useMutation(
        "update-list-details",
        updateStakeholderDetails,
        {
            onSuccess: updateStakeholderCacheAndAdvanceCurrStep,
            onError: (e) => {
                toast.error("Oops Something goes wrong, please try again");
            },
        }
    );

    const handlePreviousStep = () => {
        let lastStep;
        const { hasContactPerson, hasRelationshipHolder } = basicDetails;

        if (hasRelationshipHolder) {
            lastStep = currentStep - 1;
        } else if (hasContactPerson) {
            lastStep = currentStep - 2;
        } else {
            lastStep = currentStep - 3;
        }

        setCurrentStep(lastStep);
        saveValueToLocalStorage("currentStep", lastStep);
    };

    const updateStakeholderWithLevelOfInterestAndInfluence = () => {
        const updatedBasicDetails = {
            ...basicDetails,
            description: JSON.stringify(basicDetails.description),
        };

        const updatedContactPerson = {
            ...contactPerson,
            description: JSON.stringify(contactPerson.description),
        };

        const updatedInformation = {
            ...updatedBasicDetails,
            contactPerson: updatedContactPerson,
            relationshipHolder,
            levelOfInterest: levelOfInterest.name,
            levelOfInfluence: levelOfInfluence.name,
        };
        updateStakeholder(updatedInformation);
    };

    const advanceTheCurrentStep = () => {
        const newStep = currentStep + 1;
        setCurrentStep(newStep);
        saveValueToLocalStorage(stakeholderLSKeys.CURRENT_STEP, newStep);
        toast.success("Data has been saved successfully");
    };

    const storeLevelsToLocalStorage = () => {
        saveMultiValueToLocalStorage({
            [stakeholderLSKeys.LEVEL_OF_INTEREST]: levelOfInterest,
            [stakeholderLSKeys.LEVEL_OF_INFLUENCE]: levelOfInfluence,
        });
    };

    const handleNextStep = () => {
        storeLevelsToLocalStorage();
        return isUpdatingStakeholder
            ? updateStakeholderWithLevelOfInterestAndInfluence()
            : advanceTheCurrentStep();
    };

    return (
        <div className={"pr-32 pb-3 space-y-12"}>
            {/* Interest Level */}
            <div className={"space-y-5"}>
                <p className={"text-xl text-primary tracking-wide"}>
                    Level Of Interest
                </p>
                <LevelInput
                    onChange={setLevelOfInterest}
                    defaultLevel={levelOfInterest}
                />
            </div>

            {/*Influence Level*/}
            <div className={"space-y-5"}>
                <p className={"text-xl text-primary tracking-wide"}>
                    Level Of Influence
                </p>
                <LevelInput
                    onChange={setLevelOfInfluence}
                    defaultLevel={levelOfInfluence}
                />
            </div>
            <ActionButtons
                onPrevious={handlePreviousStep}
                onNext={handleNextStep}
                className={
                    showNextButton
                        ? "opacity-100 pointer-events-auto"
                        : "opacity-0  "
                }
                nextBtnLabel={isUpdatingStakeholder ? "SAVE CHANGES" : "NEXT"}
            />
        </div>
    );
};
export default LevelOfInterestAndInfluence;
