import {useQueryClient} from "react-query";
import {useState} from "react";
import PropTypes from "prop-types";
import CommitmentCard from "../../../../../stakeholder/tabs/Commitment/commitment-card";
import {getRandomKey} from "../../../../../../../utils";
import CommitmentDetails from "../../../../../commitments/details";
// import CommitmentDetails from "./commitment-details";

const Commitments = ({ commitments, reportId }) => {
    const [activeCommitment, setActiveCommitment] = useState({});
    const [showDetails, setShowDetails] = useState(false);
    const queryClient = useQueryClient();

    const handleClick = (commitment) => {
        setShowDetails(true);
        setActiveCommitment(commitment);
    };

    const updateReportCache = (updatedCommitment) => {
        const queryKey = [`event-report-${reportId}`, reportId];
        queryClient.setQueriesData(
            [`event-report-${reportId}`, reportId],
            (oldQueryData) => {
                return {
                    ...oldQueryData,
                    data: {
                        ...oldQueryData.data,
                        commitments: oldQueryData.data.commitments.map(
                            (commitmentDetails) => {
                                if (
                                    commitmentDetails._id ===
                                    updatedCommitment._id
                                ) {
                                    return updatedCommitment;
                                }
                                return commitmentDetails;
                            }
                        ),
                    },
                };
            }
        );
    };
    return (
        <>
            <CommitmentDetails
                commitment={activeCommitment}
                showDetails={showDetails}
                reportId={reportId}
                closeHandler={setShowDetails}
                cacheUpdater={updateReportCache}
            />
            <div className={"space-y-2"}>
                <p
                    className={
                        "px-5 py-2 text-[#666666] tracking-wider bg-[#F7F7F7]"
                    }
                >
                    Commitments
                </p>
                <div className={"grid grid-cols-3 gap-10 p-5"}>
                    {commitments.map((commitment) => (
                        <CommitmentCard
                            key={commitment?._id || getRandomKey()}
                            showAvatar={true}
                            showStatus={false}
                            showViewBtn={true}
                            onClick={handleClick}
                            commitment={commitment}
                        />
                    ))}
                </div>
            </div>
        </>
    );
};
Commitments.propTypes = {
    commitments: PropTypes.object.isRequired,
};
export default Commitments;
