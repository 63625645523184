import React, {createContext, useContext} from "react";
import {Navigate, useLocation} from "react-router-dom";
import {arrToLowerCase} from "utils";
import {getValueFromLocalStorage} from "hooks/useLocalStorageState";

export const PermissionContext = createContext(null);

export const usePermission = () => {
    const permissionObj = useContext(PermissionContext);
    if (permissionObj === null) {
        throw new Error(
            "usePermission should be used inside of PermissionProvider"
        );
    }
    return permissionObj;
};

export const matchPermission = (userPermissions, props) => {
    let match;
    const {permission = []} = props;
    const permissionArr = Array.isArray(permission)
        ? arrToLowerCase(permission)
        : [permission.toLowerCase()];
    if (permissionArr.length === 0)
        match = true; // render whatever child in there.
    else {
        match = permissionArr.some((p) => userPermissions.includes(p));
    }
    return match;
};

export const Can = (props) => {
    const {children} = props;
    const {permissions: userPermissions = []} = usePermission();
    const match = matchPermission(userPermissions, props);
    return match ? children : null;
};

export const Cannot = (props) => {
    const {children} = props;
    const {permissions: userPermissions = []} = usePermission();
    const match = matchPermission(userPermissions, props);
    return match ? null : children;
};


export const RequirePermission = (props) => {
    const location = useLocation();
    const {children} = props;
    const {permissions: userPermissions = []} = usePermission();
    const match = matchPermission(userPermissions, props);
    if (match) return children;
    return <Navigate to="/access-denied" state={{from: location}} replace/>;
};

export const hasAnyPermission = (permission) => {
    const userPermissions = getUserPermission();
    /* console.log(userPermissions) */
    return matchPermission(userPermissions, {permission});
};

export const Switch = ({children}) => {
    let match, element;
    const {permissions: userPermissions} = usePermission();
    React.Children.forEach(children, (child) => {
        if (!match && React.isValidElement(child) && child.type === Can) {
            element = child;
            match = !!matchPermission(userPermissions, child.props);
        }
    });
    return match ? element : null;
};

export const getUserPermission = () => {
    const user = getValueFromLocalStorage("authUser", {});
    const {permissions: permissionObj = []} = user?.role;
    return permissionObj.map((permission) => {
        return permission?.genericName?.toLowerCase();
    });
};

const PermissionProvider = ({children}) => {
    const permissions = getUserPermission();
    return (
        <PermissionContext.Provider value={{permissions}}>
            {children}
        </PermissionContext.Provider>
    );
};
export default PermissionProvider;
