import Modal from "components/Modal";
import {UnstableComboboxInput} from "components/combobox";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/free-solid-svg-icons";
import Button from "components/Button";
import {forwardRef, useImperativeHandle, useState} from "react";
import useGlobalContext from "hooks/useGlobalContext";
import useGrievances from "hooks/useGrievances";
import {useQuery} from "react-query";
import {queryKeys, roleTypes} from "../../../../constants";
import {getUsers} from "../../../../provider/api";
import {isEmptyObject} from "../../../../utils";

const InvestigatorForm = forwardRef(
    ({showForm, setShowForm, activeGrievance}, ref) => {
        const {projectId} = useGlobalContext();
        const {saveGrievanceStatus: updateGrievance, savingGrievance} =
            useGrievances();

        // states
        const [investigators, setInvestigators] = useState([]);
        const [selectedInvestigator, setSelectedInvestigator] = useState({});
        const [isSubmitted, setIsSubmitted] = useState(false);

        const {isLoading} = useQuery(queryKeys.USERS, () => {
            return getUsers(`/users?project=${projectId}`)
        }, {
            onSuccess: ({data: users}) => {
                setInvestigators(users.filter((user) => {
                    return user.role?.type === roleTypes.INVESTIGATOR
                }))
            }
        })

        useImperativeHandle(ref, () => ({
            closeForm() {
                handleClose();
            },
        }));

        // handle form submit
        const handleSubmit = (e) => {
            e.preventDefault();
            setIsSubmitted(true);
            if (isEmptyObject(selectedInvestigator)) return;
            const payload = {
                id: activeGrievance?._id,
                investigator: selectedInvestigator._id,
                status: 1,
            };
            updateGrievance(payload);
        };

        const handleClose = () => {
            setShowForm(false);
        };

        return (
            <Modal isOpen={showForm} onClose={setShowForm}>
                <div className="w-full max-w-xl bg-white rounded-lg">
                    {/* form header  */}
                    <header className="sticky top-0 flex justify-between px-5 py-3 bg-gray-300 rounded-t-lg">
                        <p className="font-semibold text-[#002F4F]">
                            Add An Investigator
                        </p>

                        <button
                            onClick={handleClose}
                            className=" border-2 focus:ring-0 ring-0 border-[#192B40]/50 w-6 h-6 bg-[#628799] text-white rounded-full grid place-items-center"
                        >
                            <FontAwesomeIcon
                                color="FFFFFF"
                                icon={faXmark}
                                className={"h-3 w-4"}
                            />
                        </button>
                    </header>

                    {/* form */}
                    <form onSubmit={handleSubmit} className="p-5 space-y-4">
                        <div className="space-y-1.5">
                            <label
                                className="text-sm font-semibold text-dark"
                                htmlFor="investigator"
                            >
                                Select An Investigator
                            </label>
                            <UnstableComboboxInput
                                name="investigator"
                                className={"text-sm"}
                                options={investigators}
                                onChange={setSelectedInvestigator}
                                defaultOption={selectedInvestigator}
                                displayItemName={"fullName"}
                                isLoading={isLoading}
                                hasError={isSubmitted && isEmptyObject(selectedInvestigator)}
                            />
                        </div>

                        {/* submit button */}
                        <div>
                            <Button
                                loading={savingGrievance}
                                type="submit"
                                className="py-4"
                                block
                            >
                                UPDATE GRIEVANCE
                            </Button>
                        </div>
                    </form>
                </div>
            </Modal>
        );
    }
);

export default InvestigatorForm;
