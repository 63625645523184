import React, { useEffect, useState } from "react";
import FileUpload from "../../../../../../components/file-upload";
import { getRandomKey } from "../../../../../../utils";
import { CameraIcon } from "@heroicons/react/outline";
import { useConductMeetingContext } from "../index";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useDeleteFromS3Bucket from "../../../../../../hooks/useDeleteFromS3Bucket";

const ImagesUpload = () => {
	const { conductedInfo, setConductedInfo } = useConductMeetingContext();
	const [isOpen, setIsOpen] = useState(false);
	const [uploadedImages, setUploadedImages] = useState(conductedInfo.images);
	const { deleteFromS3Bucket } = useDeleteFromS3Bucket();

	useEffect(() => {
		setConductedInfo({ ...conductedInfo, images: uploadedImages });
	}, [uploadedImages]);

	const handleSubmit = (images) => {
		console.log("NEW IMAGES", images);
		console.log("UPLOADED:: ", uploadedImages);
		setUploadedImages([...uploadedImages, ...images]);
	};

	const removeImageFile = async (imageFile) => {
		// remove from ui
		setUploadedImages([
			...uploadedImages.filter((selectedFile) => {
				return selectedFile.filename !== imageFile.filename;
			}),
		]);

		// remove from aws-bucket
		const file = uploadedImages.find((uploadedFile) => {
			return uploadedFile.filename === imageFile.filename;
		});
		await deleteFromS3Bucket(file.fileKey);
	};

	return (
		<React.Fragment>
			<FileUpload
				onClose={setIsOpen}
				isOpen={isOpen}
				onSubmit={handleSubmit}
				fileTypes={"image/*"}
			/>
			<div className="grid grid-cols-3  gap-y-3 2xl:grid-cols-4">
				{uploadedImages.map((image) => {
					return (
						<div
							key={getRandomKey()}
							className="w-24 relative group h-24 bg-[#E4E4E4] overflow-hidden rounded-[4px]   "
						>
							<img
								alt="Uploaded"
								className="object-cover w-full h-full "
								src={image.tempUrl}
							></img>

							<div
								onClick={() => removeImageFile(image)}
								className={
									"group-hover:flex text-gray-500 hidden hover:bg-accent hover:text-white cursor-pointer h-5 absolute right-[2.5px] top-[2.5px] bg-gray-100 w-5 center rounded-full"
								}
							>
								<FontAwesomeIcon
									icon={faXmark}
									className={"h-3 w-3 "}
								/>
							</div>
						</div>
					);
				})}
				<div
					onClick={() => setIsOpen(true)}
					className="w-24 h-24 bg-[#E4E4E4] rounded-[4px]  cursor-pointer center"
				>
					<CameraIcon className={"h-8 text-[#AAB6C5] w-8"} />
				</div>
			</div>
		</React.Fragment>
	);
};

export default ImagesUpload;
