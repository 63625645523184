import { ArrowsExpandIcon } from "@heroicons/react/outline";
import { useRef } from "react";
import { useOutletContext } from "react-router-dom";
import StarSoldIcon from "components/StarSoldIcon";
import StartOutlineIcon from "components/StartOutlineIcon";
import ViewLocation from "../../../../../maps/viewLocation";
import { CreateMarkup, isEmptyObject } from "utils";
import AirdropIcon from "../../_partials/AirdropIcon";
import ArrowRight from "../../_partials/ArrowRight";

const Overview = () => {
    const { isLoading, getCurrentStakeholder } = useOutletContext();
    const mapContainer = useRef(null);
    if (isLoading) {
        return (
            <div className={" w-ful h-4/5  flex justify-center items-center "}>
                <div className={"flex space-y-4 items-center flex-col"}>
                    <div
                        className={
                            "h-12 w-12 border-accent border-t-transparent border-2 animate-spin rounded-full"
                        }
                    ></div>
                    <p className={"text-sm text-black/40"}>
                        Loading Stakeholder details...
                    </p>
                </div>
            </div>
        );
    }

    const currStakeholder = getCurrentStakeholder();
    if (!isLoading && isEmptyObject(currStakeholder)) {
        return (
            <div className={" w-ful h-4/5  flex justify-center items-center "}>
                <div>
                    <p className={"text-sm text-black/40"}>
                        There is not information to display
                    </p>
                </div>
            </div>
        );
    }

    const viewMapInFullScreen = () => {
        if (!mapContainer.current) return;
        mapContainer.current.requestFullscreen();
    };

    return (
        <div className={"space-y-8 h-full"}>
            <div>
                <div
                    className={
                        "flex border border-[#1A9FDF] p-[2.5px] rounded items-center justify-between"
                    }
                >
                    <div
                        className={
                            "flex border w-full border-[#ECECED] p-2 rounded items-center justify-between"
                        }
                    >
                        <div>
                            <p
                                className={
                                    "text-sm font-semibold text-[#002F4F]"
                                }
                            >
                                {currStakeholder?.name}
                            </p>
                        </div>
                        <div className={"flex"}>
                            <div
                                className={
                                    "text-xs bg-[#CFE5F56A] flex space-x-2 whitespace-nowrap px-4 py-2 text-accent rounded border-[1px] border-[#1A9FDF]"
                                }
                            >
                                <p> CONTACT THE STAKEHOLDER</p>
                                <ArrowRight />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Stakeholder Descriptions */}
            <div
                className={
                    "bg-[#F5F9FA] space-y-5 border-[1px]  px-10 rounded border-[#ECECED]"
                }
            >
                <div
                    className={
                        " w-full flex items-center justify-between py-5 border-b-[1px] border-[#ECECED]"
                    }
                >
                    <div className={"space-y-2"}>
                        <p className={"text-[13px] sm text-[#002F4F] "}>
                            TYPE OF STAKEHOLDER
                        </p>
                        <p className={"text-sm text-gray font-light"}>
                            {currStakeholder?.type}
                        </p>
                    </div>

                    <div className={"space-y-2"}>
                        <p className={"text-[13px] sm text-[#002F4F]"}>
                            ADDED ON
                        </p>
                        <p className={"text-sm text-gray font-light"}>
                            {new Date(
                                `${currStakeholder?.createdAt}`
                            ).toDateString()}
                        </p>
                    </div>

                    <div className={"space-y-2"}>
                        <p className={"text-[13px] sm text-[#002F4F] "}>
                            ROLE/RELATIONSHIP
                        </p>
                        <p
                            className={
                                "text-sm text-gray font-light capitalize"
                            }
                        >
                            {currStakeholder?.role}
                        </p>
                    </div>
                </div>

                <div className={"space-y-3"}>
                    <div className={"space-y-2"}>
                        <p className={"font-semibold text-[#233A4F]"}>
                            Stakeholder Descriptions
                        </p>
                        <p
                            dangerouslySetInnerHTML={CreateMarkup(
                                currStakeholder?.description
                            )}
                            className={"text-sm text-[#628799] font-light "}
                        ></p>
                    </div>

                    <div className={"py-5 space-x-10 flex"}>
                        {/* Level of interest */}
                        <div
                            className={
                                "h-28 w-full bg-white border-[1px] border-[#ECECED] flex rounded overflow-hidden"
                            }
                        >
                            <div
                                className={
                                    "h-full w-24 bg-[#EBF5F3] flex justify-center items-center"
                                }
                            >
                                <div
                                    className={
                                        "flex space-y-2 flex-col items-center"
                                    }
                                >
                                    <AirdropIcon />
                                    <p className={"text-primary text-[13px]"}>
                                        {currStakeholder?.levelOfInterest}
                                    </p>
                                </div>
                            </div>

                            <div
                                className={
                                    " px-8 flex space-y-4 flex-col justify-center"
                                }
                            >
                                <div
                                    className={
                                        "text-xs font-semibold text-dark"
                                    }
                                >
                                    LEVEL OF INTERESTS
                                </div>
                                <div className={""}>
                                    {currStakeholder?.levelOfInterest?.toLowerCase() ===
                                        "weak" && (
                                        <div
                                            className={
                                                "flex items-center space-x-4"
                                            }
                                        >
                                            <StarSoldIcon
                                                className={
                                                    "h-8 w-8 text-yellow"
                                                }
                                            />
                                            <StartOutlineIcon
                                                className={
                                                    "h-8 w-8 text-star-muted"
                                                }
                                            />
                                            <StartOutlineIcon
                                                className={
                                                    "h-8 w-8 text-star-muted"
                                                }
                                            />
                                        </div>
                                    )}

                                    {currStakeholder?.levelOfInterest?.toLowerCase() ===
                                        "medium" && (
                                        <div
                                            className={
                                                "flex items-center space-x-4"
                                            }
                                        >
                                            <StarSoldIcon
                                                className={
                                                    "h-8 w-8 text-yellow"
                                                }
                                            />
                                            <StarSoldIcon
                                                className={
                                                    "h-8 w-8 text-star-muted"
                                                }
                                            />
                                            <StartOutlineIcon
                                                className={
                                                    "h-8 w-8 text-star-muted"
                                                }
                                            />
                                        </div>
                                    )}

                                    {currStakeholder?.levelOfInterest?.toLowerCase() ===
                                        "strong" && (
                                        <div
                                            className={
                                                "flex items-center space-x-4"
                                            }
                                        >
                                            <StarSoldIcon
                                                className={
                                                    "h-8 w-8 text-yellow"
                                                }
                                            />
                                            <StarSoldIcon
                                                className={
                                                    "h-8 w-8 text-star-muted"
                                                }
                                            />
                                            <StarSoldIcon
                                                className={
                                                    "h-8 w-8 text-star-muted"
                                                }
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        {/* Level of influence */}
                        <div
                            className={
                                "h-28 w-full bg-white border-[1px] border-[#ECECED] flex rounded overflow-hidden"
                            }
                        >
                            <div
                                className={
                                    "h-full w-24 bg-[#EBF5F3] flex justify-center items-center"
                                }
                            >
                                <div
                                    className={
                                        "flex space-y-2 flex-col items-center"
                                    }
                                >
                                    <AirdropIcon />
                                    <p className={"text-primary text-[13px]"}>
                                        {currStakeholder?.levelOfInfluence}
                                    </p>
                                </div>
                            </div>

                            <div
                                className={
                                    " px-8 flex space-y-4 flex-col justify-center"
                                }
                            >
                                <div
                                    className={
                                        "text-xs font-semibold text-dark"
                                    }
                                >
                                    LEVEL OF INFLUENCE
                                </div>
                                <div className={""}>
                                    {currStakeholder?.levelOfInfluence?.toLowerCase() ===
                                        "weak" && (
                                        <div
                                            className={
                                                "flex items-center space-x-4"
                                            }
                                        >
                                            <StarSoldIcon
                                                className={
                                                    "h-8 w-8 text-yellow"
                                                }
                                            />
                                            <StartOutlineIcon
                                                className={
                                                    "h-8 w-8 text-star-muted"
                                                }
                                            />
                                            <StartOutlineIcon
                                                className={
                                                    "h-8 w-8 text-star-muted"
                                                }
                                            />
                                        </div>
                                    )}

                                    {currStakeholder?.levelOfInfluence?.toLowerCase() ===
                                        "medium" && (
                                        <div
                                            className={
                                                "flex items-center space-x-4"
                                            }
                                        >
                                            <StarSoldIcon
                                                className={
                                                    "h-8 w-8 text-yellow"
                                                }
                                            />
                                            <StarSoldIcon
                                                className={
                                                    "h-8 w-8 text-star-muted"
                                                }
                                            />
                                            <StartOutlineIcon
                                                className={
                                                    "h-8 w-8 text-star-muted"
                                                }
                                            />
                                        </div>
                                    )}

                                    {currStakeholder?.levelOfInfluence?.toLowerCase() ===
                                        "strong" && (
                                        <div
                                            className={
                                                "flex items-center space-x-4"
                                            }
                                        >
                                            <StarSoldIcon
                                                className={
                                                    "h-8 w-8 text-yellow"
                                                }
                                            />
                                            <StarSoldIcon
                                                className={
                                                    "h-8 w-8 text-star-muted"
                                                }
                                            />
                                            <StarSoldIcon
                                                className={
                                                    "h-8 w-8 text-star-muted"
                                                }
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Stakeholder Location */}
            <div
                className={
                    "bg-[#F5F9FA] overflow-hidden border-[1px]  rounded border-[#ECECED]"
                }
            >
                <div className={"flex items-center justify-between px-10 py-3"}>
                    <p
                        className={
                            "text-[13px] tracking-wide text-[#002F4F] font-medium"
                        }
                    >
                        STAKEHOLDER LOCATION
                    </p>
                    <ArrowsExpandIcon
                        onClick={viewMapInFullScreen}
                        className={"h-5 w-5 cursor-pointer text-[#628799]"}
                    />
                </div>

                <div ref={mapContainer} className={" h-[32rem] relative"}>
                    <ViewLocation
                        coordinates={[
                            currStakeholder?.location?.longitude,
                            currStakeholder?.location?.latitude,
                        ]}
                    />
                    <div
                        className={
                            "absolute  top-16 text-white left-16 w-[250px] p-5 h-82 stakeholder-location"
                        }
                    >
                        <div
                            className={
                                "space-y-4 py-3 border-b-[1px] border-white/30 w-full"
                            }
                        >
                            <p className={"title text-sm "}>
                                {currStakeholder?.name}
                            </p>
                            <div
                                className={
                                    "text-xs opacity0 font-thin w-full flex flex-wrap"
                                }
                            >
                                <p className={"whitespace-nowrap capitalize"}>
                                    {currStakeholder?.address?.street}
                                </p>
                                ,
                                {/* <p
                                    className={'whitespace-nowrap capitalize'}> {currStakeholder.address.district},</p> */}
                                <p
                                    className={
                                        "whitespace-nowrap capitalize pl-1"
                                    }
                                >
                                    {currStakeholder?.address?.region},
                                </p>
                                <b />
                                <p
                                    className={
                                        "whitespace-nowrap capitalize pl-1"
                                    }
                                >
                                    {currStakeholder?.address?.country}.
                                </p>
                            </div>
                        </div>

                        <div className={"flex items-center justify-between"}>
                            <div className={"py-4 space-y-1"}>
                                <p
                                    className={
                                        "text-[9.5px] opacity-90 tracking-wide"
                                    }
                                >
                                    LATITUDE
                                </p>
                                <p className={"text-xs font-thin opacity-80"}>
                                    {currStakeholder?.location?.latitude}
                                </p>
                            </div>
                            <div className={"py-4 space-y-1"}>
                                <p
                                    className={
                                        "text-[9.5px] opacity-90 tracking-wide"
                                    }
                                >
                                    LONGITUDE
                                </p>
                                <p className={"text-xs font-thin opacity-80"}>
                                    {currStakeholder?.location?.longitude}
                                </p>
                            </div>
                        </div>

                        <div
                            className={
                                "grid grid-cols-2 gap-10  justify-between"
                            }
                        >
                            <div className={"py-4 space-y-1"}>
                                <p className={"text-[10px] tracking-wide"}>
                                    POSTAL CODE
                                </p>
                                <p className={"text-xs font-thin opacity-80"}>
                                    {currStakeholder?.address?.postalCode}
                                </p>
                            </div>
                            <div className={"py-4 space-y-1"}>
                                <p className={"text-[10px] tracking-wide"}>
                                    STATE
                                </p>
                                <p className={"text-xs font-thin opacity-80"}>
                                    {currStakeholder?.address?.country}
                                </p>
                            </div>
                        </div>

                        <div
                            className={
                                "grid grid-cols-2 gap-10  justify-between"
                            }
                        >
                            <div className={"py-4 space-y-1"}>
                                <p className={"text-[10px] tracking-wide"}>
                                    REGION
                                </p>
                                <p className={"text-xs font-thin opacity-80"}>
                                    {currStakeholder?.address?.region}
                                </p>
                            </div>
                            <div className={"py-4 space-y-1"}>
                                <p className={"text-[10px] tracking-wide"}>
                                    DISTRICT
                                </p>
                                <p className={"text-xs font-thin opacity-80"}>
                                    {currStakeholder?.address?.district}
                                </p>
                            </div>
                        </div>

                        <div
                            className={
                                "grid grid-cols-2 gap-10  justify-between"
                            }
                        >
                            <div className={"py-4 space-y-1"}>
                                <p className={"text-[10px] tracking-wide"}>
                                    WARD
                                </p>
                                <p className={"text-xs font-thin opacity-80"}>
                                    {currStakeholder?.address?.ward}
                                </p>
                            </div>
                            <div className={"py-4 space-y-1"}>
                                <p className={"text-[10px] tracking-wide"}>
                                    STREET
                                </p>
                                <p className={"text-xs font-thin opacity-80"}>
                                    {currStakeholder?.address?.street}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Overview;
