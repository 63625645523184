import { useEffect, useState } from "react";
import RegularInput from "../form-control/regular-input";
import ButtonAdd from "../button-add";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { randomId } from "../../utils";

const DynamicInput = ({ props }) => {
    const {
        inputClasses,
        valueHandler,
        initialInputs = [],
        setErrorHandle = () => {},
        buttonLabel = "Add Agenda",
        placeholder = "",
        validator: isValid = () => {},
        autoFocus = true,
    } = props;
    const [errors, setErrors] = useState([]);

    const [inputList, setInputList] = useState([
        // initial input field properties
        {
            _id: `input_${randomId()}`,
            value: "",
        },
    ]);

    useEffect(() => {
        if (initialInputs.length) {
            setInputList(
                initialInputs.map((inputValue) => {
                    return {
                        _id: `input_${randomId()}`,
                        value: inputValue,
                    };
                })
            );
        }
    }, []);

    useEffect(() => {
        valueHandler(getInputValues());
    }, [inputList]);

    useEffect(() => {
        if (errors.length) {
            setErrorHandle(true);
        } else setErrorHandle(false);
    }, [errors]);

    const handleRemoveInput = (inputId) => {
        return setInputList([
            ...inputList.filter((input) => {
                return input._id !== inputId;
            }),
        ]);
    };

    const handleAddInput = () => {
        return setInputList([
            ...inputList,
            {
                _id: `input_${randomId()}`,
                value: "",
            },
        ]);
    };

    const getInputValues = () => {
        return inputList.map(({ value }) => value);
    };
    const handleInputChange = ({ target }) => {
        const { id: inputId, value } = target;

        // update input value from input list;
        setInputList([
            ...inputList.map((input) => {
                if (input._id === inputId) {
                    return { ...input, value }; // update input value
                }
                return input;
            }),
        ]);
    };

    const handleBlur = ({ target }) => {
        const { id, value } = target;
        if (!isValid(value)) {
            return setErrors([...errors, { id, value }]);
        }

        // remove a field form error list
        return setErrors([
            ...errors.filter((error) => {
                return error.id !== id;
            }),
        ]);
    };

    const hasError = (inputId) => {
        return !!errors.find((error) => {
            return error.id === inputId;
        });
    };

    return (
        <div className={"space-y-2"}>
            {inputList.map((input, index) => (
                <div key={input._id} className="flex items-center space-x-2 ">
                    <div className={"w-full"}>
                        <RegularInput
                            autoFocus={autoFocus}
                            isError={hasError(input._id)}
                            errorMessage={
                                "Please provide a valid goal, or you can delete input"
                            }
                            name={"name here"}
                            id={input._id}
                            defaultValue={input.value}
                            onBlur={handleBlur}
                            onChange={handleInputChange}
                            className={`${inputClasses} w-full`}
                            placeholder={placeholder}
                        />
                    </div>
                    <div>
                        <button
                            type={"button"}
                            onClick={() => handleRemoveInput(input._id)}
                            className={
                                "h-6 w-6 hover:bg-gray-200 text-gray-500 rounded center"
                            }
                        >
                            <FontAwesomeIcon
                                className="w-5 h-5"
                                icon={faXmark}
                                color={"FFFFFF"}
                            />
                        </button>
                    </div>
                </div>
            ))}

            <div className={"flex justify-end"}>
                <ButtonAdd
                    onClick={handleAddInput}
                    label={buttonLabel}
                    className={"italic text-accent"}
                />
            </div>
        </div>
    );
};
export default DynamicInput;
