import { Fragment } from "react";
import CheckIcon from "../../../../components/CheckIcon";

const Stepper = ({currentStep, steps}) => {
    const getSteps = () => {
        const _steps = [];

        for (let step = 1; step <= steps; step++) {
            if (step < steps) {
                _steps.push(
                    <Fragment>
                        <StepCircle step={step} currentStep={currentStep}/>
                        <StepLine step={step} currentStep={currentStep}/>
                    </Fragment>
                );
            } else
                _steps.push(
                    <StepCircle step={step} currentStep={currentStep}/>
                );
        }
        return _steps;
    };

    return (
        <div className={"px-5 flex items-between pt-3 items-center"}>
            {/* step 1 */}
            {/*<StepCircle step={1} currentStep={currentStep} />*/}
            {/*<StepLine step={1} currentStep={currentStep} />*/}

            {/*/!* step 2 *!/*/}
            {/*<StepCircle step={2} currentStep={currentStep} />*/}
            {/*<StepLine step={2} currentStep={currentStep} />*/}

            {/*/!* step 3 *!/*/}
            {/*<StepCircle step={3} currentStep={currentStep} />*/}
            {/*{isGBV && (*/}
            {/*	<Fragment>*/}
            {/*		<StepLine step={3} currentStep={currentStep} />*/}

            {/*/!* step 3 *!/*/}
            {/*		<StepCircle step={4} currentStep={currentStep} />*/}
            {/*	</Fragment>*/}
            {/*)}*/}

            {getSteps().map((stepper) => {
                return stepper;
            })}
        </div>
	);
};

export const StepCircle = ({ step, currentStep }) => {
	return (
		<div
			className={`h-5 w-5 center shrink-0 font-medium text-sm rounded-full ${
				currentStep === step
					? "border-secondary"
					: currentStep > step
					? "border-secondary bg-secondary"
					: "border-[#E2E2E2] bg-transparent"
			} 
            border-[2px] `}
		>
			<CheckIcon
				className={`h-2.5 w-2.5 ${
					currentStep > step ? "block" : "hidden"
				}`}
			/>
			<p
				className={`${
					currentStep > step
						? "hidden"
						: currentStep === step
						? "block text-secondary"
						: "block text-[#E2E2E2]"
				}`}
			>
				{step}
			</p>
		</div>
	);
};

export const StepLine = ({ step, currentStep }) => {
	return (
		<div
			className={`w-full h-[2px] ${
				currentStep >= step ? "bg-secondary" : "bg-[#E2E2E2]"
			}`}
		></div>
	);
};
export default Stepper;
