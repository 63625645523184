import {faChevronDown} from "@fortawesome/free-solid-svg-icons";
import {useState, Fragment, useEffect} from "react";
import {Transition} from "@headlessui/react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import CustomCheckBox from "../../../../../../components/CustomCheckBox";
import {useConductMeetingContext} from "../index";


const InputCard = ({
                       title, children, defaultValue = false, onComplete: completeHandler = () => {
    }, onClose: handleOnClose = () => {
    }
                   }) => {

    const [isExpanded, setIsExpanded] = useState(defaultValue)
    const [isComplete, setIsComplete] = useState(false)
    const {setCompletedSteps, completedSteps} = useConductMeetingContext();

    useEffect(() => {
        if (!isExpanded) handleOnClose()
    }, [isExpanded]);


    const handleOnComplete = (markAsCompleted) => {
        if (markAsCompleted) {
            setIsComplete(true);
            setCompletedSteps([...completedSteps, title]);
            completeHandler()
        } else {
            setIsComplete(false)
            setCompletedSteps([...completedSteps.filter(step => {
                return step !== title;
            })])
        }
    }


    const checkboxProps = {
        onCheck: handleOnComplete,
        className: 'h-4 w-4',
        activeClasses: "bg-green-500",
        iconClass: "h-3"
    }

    return (
        <div
            className={`border rounded-lg overflow-hidden border-[#EBEBEB] border-l-[3px] enable-transition ${isComplete ? 'border-l-[#51BF82]' : 'border-l-[#628799]'} `}>
            <div onClick={() => setIsExpanded(!isExpanded)}
                 className={`group cursor-pointer bg-white py-3 pr-5 flex justify-between items-center`}>

                <div className={'pl-3 flex items-center space-x-4'}>
                    <CustomCheckBox {...checkboxProps}/>
                    <p
                        className={` ${isComplete ? 'text-[#51BF82]' : 'text-[#032F4F]'}  group-hover:text-[16px] ${isExpanded ? 'font-normal text-[16px]' : 'text-[16px] font-normal'}
                            group-hover:font-normal enable-transition cursor-pointer`}>
                        {title}
                    </p>

                </div>

                <div
                    className={`flex ${isExpanded ? 'space-x-12 ' : 'space-x-8'} p-2 items-center cursor-pointer enable-transition `}>
                    <FontAwesomeIcon
                        icon={faChevronDown}
                        className={`h-4 w-6 transition-transform ease-in-out  
                         ${isExpanded ? (isComplete ? 'text-[#51BF82] rotate-180 -mt-1' : 'rotate-180 -mt-1') : isComplete ? 'text-[#51BF82]' : ''}`}/>
                </div>
            </div>

            <Transition appear show={isExpanded} as={Fragment}>
                <Transition.Child
                    enter="duration-300 ease-in-out "
                    enterFrom="opacity-0 -mt-5"
                    enterTo="opacity-100 mt-0"
                    leave="duration-100 ease-out mt-0"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0 -mt-5 "
                >
                    {children}
                </Transition.Child>

            </Transition>
        </div>
    )
}
export default InputCard;