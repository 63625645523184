import { useState, Fragment } from "react";
import RepeatedGrievanceCard from "./repeated-grievance-card";
import { useQuery } from "react-query";
import { getHighRiskGrievances } from "../../../../provider/api";
import { useNavigate } from "react-router-dom";
import GrievancesDetails from "../../../general/grievances/grievance-details";

const MostHighRiskGrievance = () => {
	const [grievanceList, setGrievanceList] = useState([]);
	const [activeGrievance, setActiveGrievance] = useState(null);
	const [showDetails, setShowDetails] = useState(false);
	const navigate = useNavigate();
	const { isLoading } = useQuery(
		"most-high-risk-grievances",
		getHighRiskGrievances,
		{
			onSuccess: ({ data: grievances }) => {
				setGrievanceList(grievances);
			},
		}
	);

	const handleClick = () => {
		navigate("/grievances");
	};

	const handleCardClick = (grievance) => {
		setActiveGrievance(grievance);
		setShowDetails(true);
	};

	return (
		<Fragment>
			{activeGrievance && (
				<GrievancesDetails
					isOpen={showDetails}
					setIsOpen={setShowDetails}
					grievance={activeGrievance}
				/>
			)}
			<div
				className={
					"w-[345px]  fixed top-[10rem]   right-8 space-y-8  pt-5"
				}
				style={{ height: "calc(100vh - 64px)" }}
			>
				<div
					className={
						"bg-white space-y-3 flex flex-col justify-between rounded-[5px] overflow-hidden border-t-[4px] h-[95%] border-secondary "
					}
				>
					<div className={"p-5 space-y-4"}>
						{isLoading ? (
							<p className="w-40 h-3 rounded-full animate-pulse bg-secondary/[.23]"></p>
						) : (
							<p className={"text-[#3A453A]"}>
								Most high risk grievances
							</p>
						)}
						<div className={"space-y-4"}>
							{isLoading ? (
								[...Array(4)].map((grievance, index) => (
									<div
										key={index}
										className={
											"bg-secondary/[.23] h-32 animate-pulse rounded"
										}
									></div>
								))
							) : grievanceList?.length ? (
								grievanceList.map((grievance) => (
									<RepeatedGrievanceCard
										onClick={handleCardClick}
										key={grievance._id}
										grievance={grievance}
									/>
								))
							) : (
								<div
									className={
										"h-[34rem] bg-[#E3E4E886] center text-sm text-black/50"
									}
								>
									<p>Empty</p>
								</div>
							)}
						</div>
					</div>

					<button
						onClick={handleClick}
						className={
							"text-center text-[#A0A8B5] bg-[#F5F7FB] text-xs py-4"
						}
					>
						VIEW ALL GRIEVANCES
					</button>
				</div>
			</div>
		</Fragment>
	);
};
export default MostHighRiskGrievance;
