import PropTypes from "prop-types";
import {getRandomKey} from "../../../../../../../utils";

const MeetingAgendas = ({agendas}) => {
    console.log('Agenda:: ', agendas)
    const {mainAgenda, otherAgendas: otherAgenda} = agendas;
    return (
        <div className={'space-y-5'}>
            <p className={'px-5 py-2 text-[#666666] tracking-wider bg-[#F7F7F7]'}>Agendas</p>

            <div className={'px-5 space-y-4'}>
                <div>
                    <p className={'text-xs text-secondary opacity-80 tracking-wide'}>MAIN AGENDA</p>
                    <p className={'text-[#666666] opacity-80 text-lg'}>
                        {mainAgenda}
                    </p>
                </div>

                {otherAgenda.length ? (
                    <div>
                        <p className={'text-xs text-secondary opacity-80 tracking-wide'}>OTHER AGENDA</p>
                        {otherAgenda.map((agenda, index) => (
                            <div key={agenda} className={'py-3 pr-20 space-y-2'}>
                                <p className={'text-[#666666] opacity-80 text-sm'}>
                                    <strong>{index + 1}. </strong>
                                    {agenda}
                                </p>
                            </div>
                        ))}
                    </div>
                ) : null}
            </div>

        </div>
    )
}

MeetingAgendas.propTypes = {
    agendas: PropTypes.object.isRequired
}
export default MeetingAgendas;
