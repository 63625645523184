import Datetime from "components/datetime";

const SetDatetime = () => {
    return (
        <div className={"space-y-6"}>
            <div>
                <p className={"py-1 text-[#4D4F5C] font-media text-lg"}>
                    Set Meeting Datetime
                </p>
                <p className={"text-sm text-gray-500 tracking-wide font-light"}>
                    Setting the start time, end time, and date for a meeting
                    involves specifying the exact time and date when the meeting
                    will begin and end. This information is critical for
                    participants to know when they should attend and when they
                    can expect the meeting to conclude.
                </p>
            </div>
            <Datetime />
        </div>
    );
};
export default SetDatetime;
