import { Link, useLocation, useNavigate } from "react-router-dom";
import {
    CommitmentIcon,
    EngagementIcon,
    GrievancesIcon,
    StakeholderIcon,
} from "../_partials/menu-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faAngleDown,
    faDiagramProject,
    faListCheck,
} from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import useClickOutside from "hooks/useClickOutside";
import useGlobalContext from "hooks/useGlobalContext";
import { Can } from "provider/permission";

export const StakeholderMenu = () => {
    const { pathname } = useLocation();
    const urlPattern = new RegExp("^/(stakeholders|analytics/list)/*");
    const isActive = urlPattern.test(pathname);
    const { contentsView } = useGlobalContext();
    const isGeneralView = contentsView === "general";

    return (
        <Link
            to={isGeneralView ? "/" : "/analytics"}
            className={"flex  items-center space-x-4 justify-between"}
        >
            <button
                className={`w-full font-light
                 ${
                     isActive
                         ? "bg-[#628799] text-white"
                         : "text-[#2D3943] hover:bg-secondary/30"
                 }
                  flex items-center space-x-3 rounded px-2 py-2 `}
            >
                <StakeholderIcon isActive={isActive} />

                {/*<UserGroupIcon className={`${isActive? 'text-white' : 'text-[#C4C4C4]'} h-5 w-5`}/>*/}
                <p className={"tracking-wide capitalize"}>Stakeholder</p>
            </button>
            <div
                className={`w-[4.5px] ${
                    isActive ? "opacity-100" : "opacity-0"
                } h-5 bg-[#628799] rounded-l-[2px]`}
            ></div>
        </Link>
    );
};

export const EngagementMenu = () => {
    const { pathname } = useLocation();
    const urlPattern = new RegExp("^/(engagement|analytics/engagements)/*");
    const isPlanUrlActive = new RegExp("^/engagement/plans/*").test(pathname);
    const isActivitiesActive = new RegExp("^/engagement/activities/*").test(
        pathname
    );
    const isActive = urlPattern.test(pathname);
    const [showSubmenus, setShowSubmenus] = useState(false);
    const navigate = useNavigate();
    const { contentsView } = useGlobalContext();
    const isGeneralView = contentsView === "general";

    const menuRef = useClickOutside(() => {
        if (!isActive) setShowSubmenus(false);
    });

    const toggleSubmenus = () => {
        if (isGeneralView) {
            setShowSubmenus(!showSubmenus);
        } else {
            setShowSubmenus(false);
            navigate("/analytics/engagements");
        }
    };

    const submenuClassName = `space-x-3 ${
        isActive ? "hover:bg-white/10" : "hover:bg-secondary/30 "
    } flex 
     items-center w-full  px-2 py-1.5`;
    return (
        <div ref={menuRef} onClick={toggleSubmenus} className={"space-y-4"}>
            <div className={"flex  items-center space-x-4 justify-between"}>
                <button
                    className={`w-full font-light items-between
                 ${
                     isActive
                         ? "bg-[#628799] text-white"
                         : showSubmenus
                         ? "bg-secondary/30 text-[#2D3943]/80"
                         : "text-[#2D3943] hover:bg-secondary/30"
                 } flex items-center rounded px-2 py-2 `}
                >
                    <div className={"flex items-center space-x-3"}>
                        <EngagementIcon isActive={isActive || showSubmenus} />
                        <p className={"tracking-wide capitalize"}>Engagement</p>
                    </div>
                    <FontAwesomeIcon
                        icon={faAngleDown}
                        className={`h-4 ${
                            !isGeneralView && "hidden"
                        } transition-all w-4 ${
                            (showSubmenus || isActive) && "rotate-180"
                        }`}
                    />
                </button>
                <div
                    className={`w-[4.5px] ${
                        isActive ? "opacity-100" : "opacity-0"
                    } h-5 bg-[#628799] rounded-l-[2px]`}
                ></div>
            </div>

            {((isActive && isGeneralView) ||
                isPlanUrlActive ||
                isActivitiesActive ||
                showSubmenus) && (
                <div
                    className={`
                    ${
                        isActive
                            ? "bg-[#628799] text-white before:border-b-[#628799] space-y-1"
                            : "bg-secondary/20 before:border-b-secondary/20 text-[#2D3943]/80"
                    }
                    
                     py-2 mr-5 
                    relative rounded
                    before:h-0 before:w-0
                    before:border-l-[10px]
                    before:border-r-[10px]
                    before:border-l-transparent 
                    before:border-r-transparent
                    before:border-b-[9px]
                   
                    before:absolute
                    before:left-4
                    before:bottom-full
                `}
                >
                    <Can permission={"read-engagementPlans"}>
                        <Link
                            to={"/engagement/plans"}
                            className={`${submenuClassName} ${
                                isPlanUrlActive && "bg-white/10"
                            }`}
                        >
                            <FontAwesomeIcon
                                icon={faListCheck}
                                className={"h-3 w-4"}
                            />
                            <p>Plans</p>
                        </Link>
                    </Can>
                    <Can permission={"read-projectEvents"}>
                        <Link
                            to={"/engagement/activities"}
                            className={`${submenuClassName} ${
                                isActivitiesActive && "bg-white/10"
                            }`}
                        >
                            <FontAwesomeIcon
                                icon={faDiagramProject}
                                className={"h-3 w-4"}
                            />
                            <p>Activities</p>
                        </Link>
                    </Can>
                </div>
            )}
        </div>
    );
};

export const CommitmentMenu = () => {
    const { pathname } = useLocation();
    const urlPattern = new RegExp("^/(commitments|analytics/commitments)/*");
    const isActive = urlPattern.test(pathname);
    const navigate = useNavigate();
    const { contentsView } = useGlobalContext();
    const isGeneralView = contentsView === "general";

    const handleClick = () => {
        navigate(isGeneralView ? "/commitments" : "/analytics/commitments");
    };

    return (
        <Link
            to={isGeneralView ? "/commitments" : "/analytics/commitments"}
            className={"flex  items-center space-x-4 justify-between"}
        >
            <button
                className={`w-full font-light
                 ${
                     isActive
                         ? "bg-[#628799] text-white"
                         : "text-[#2D3943] hover:bg-secondary/30"
                 } 
                 flex items-center space-x-3 rounded px-2 py-2 `}
            >
                <CommitmentIcon isActive={isActive} />
                <p className={"tracking-wide capitalize"}>Commitment</p>
            </button>
            <div
                className={`w-[4.5px] ${
                    isActive ? "opacity-100" : "opacity-0"
                } h-5 bg-[#628799] rounded-l-[2px]`}
            ></div>
        </Link>
    );
};

export const GrievanceMenu = () => {
    const { pathname } = useLocation();
    const urlPattern = new RegExp("^/(grievances|analytics/grievances)/*");
    const isActive = urlPattern.test(pathname);
    const navigate = useNavigate();
    const { contentsView } = useGlobalContext();
    const isGeneralView = contentsView === "general";

    return (
        <Link
            to={isGeneralView ? "/grievances" : "/analytics/grievances"}
            className={"flex  items-center space-x-4 justify-between"}
        >
            <button
                className={`w-full font-light
                 ${
                     isActive
                         ? "bg-[#628799] text-white"
                         : "text-[#2D3943] hover:bg-secondary/30"
                 } 
                 flex items-center space-x-3 rounded px-2 py-2 `}
            >
                <GrievancesIcon isActive={isActive} />
                <p className={"tracking-wide capitalize"}>Grievances</p>
            </button>
            <div
                className={`w-[4.5px] ${
                    isActive ? "opacity-100" : "opacity-0"
                } h-5 bg-[#628799] rounded-l-[2px]`}
            ></div>
        </Link>
    );
};

export const OverviewMenu = ({ url, pathname, icon: Icon, pattern }) => {
    const { pathname: _pathname } = useLocation();
    const isActive = pattern
        ? pattern.test(_pathname)
        : new RegExp(`^${url}/*`).test(_pathname);
    return (
        <Link
            to={url}
            className={"flex  items-center space-x-4 justify-between"}
        >
            <button
                className={`w-full font-light
                 ${
                     isActive
                         ? "bg-[#628799] text-white"
                         : "text-[#2D3943] hover:bg-secondary/30"
                 } 
                 flex items-center space-x-3 rounded px-2 py-2 `}
            >
                <Icon
                    className={`h-5 w-5 ${
                        isActive ? "text-white" : "text-[#c4c4c4]"
                    }`}
                />
                <p className={"tracking-wide capitalize"}>{pathname}</p>
            </button>
            <div
                className={`w-[4.5px] ${
                    isActive ? "opacity-100" : "opacity-0"
                } h-5 bg-[#628799] rounded-l-[2px]`}
            ></div>
        </Link>
    );
};
