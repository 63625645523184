import Legend from "./legend";

const MapLegend = () => {
    return (
        <div className={'w-[250px] mt-16 space-y-3 '}>
            <p className={'text-[#3A4040] text-sm'}>KEY</p>
            <div className={'space-y-2'}>
                <Legend color={'#51BF82'} from={'40'} to={'Above'} />
                <Legend color={'#1A9EE2'} from={'30'} to={'39'} />
                <Legend color={'#032F4F'} from={'20'} to={'29'} />
                <Legend color={'#F9B641'} from={'10'} to={'19'} />
                <Legend color={'#838EAB'} from={'09'} to={'Below'} />
            </div>
        </div>
    )
}
export default MapLegend;