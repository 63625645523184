import {Can, Cannot, hasAnyPermission, RequirePermission, Switch} from "./permissionProvider"

export {default} from "./permissionProvider";

export const permissions = {
    READ_STAKEHOLDERS: "read-stakeholders",
    READ_MEETING_REPORT: "read-meetingReport",
    READ_PROJECT_FILES: "read-projectFiles",
    READ_PROJECT_IMAGES: "read-projectImages",
    READ_NOTIFICATIONS: 'read-notifications',
    READ_COMMITMENTS: 'read-commitments',
    READ_GRIEVANCES: 'read-grievances',
    READ_ANALYTICS: 'read-analytics',
    READ_SETTINGS: 'read-settings',
    READ_ENGAGEMENTS: 'read-engagements',
    READ_ENGAGEMENT_PLANS: 'read-engagementPlans',
    READ_PROJECT_EVENTS: 'read-projectEvents',
    READ_PROJECT: 'read-project',
    READ_ROLES: 'read-users',
    READ_PROJECT_PHASE: 'read-projectPhases',
    READ_USERS: 'read-users',
    UPDATE_SUBPROJECTS: 'update-subProjects',
    READ_SUBPROJECTS: 'read-subProjects',
    DELETE_SUBPROJECTS: 'delete-subProjects',
    CREATE_SUBPROJECTS: 'create-subProjects',
    CREATE_PROJECT_EVENTS: 'create-projectEvents',
    CREATE_STAKEHOLDERS: 'create-stakeholders',
    UPDATE_STAKEHOLDERS: "update-stakeholders",
    UPDATE_PROJECT_EVENTS: 'update-projectEvents',
    MANAGE_PROJECTS_AND_PIUS: "manage-projects-pius", // world-bank stuffs
    GRIEVANCE_INVESTIGATOR: "grievance-investigator",
    MANAGE_SUBPROJECTS: [
        'update-subProjects',
        'delete-subProjects',
        'create-subProjects',
        'read-subProjects'
    ],
    MANAGE_USERS: [
        'read-users'
    ],
    MANAGE_DEPARTMENTS: [
        'update-departments',
        'delete-departments',
        'create-departments',
        'read-departments'
    ],
    MANAGE_GRIEVANCES: [
        'update-grievances',
        'delete-grievances',
        'create-grievances',
        'read-grievances'
    ],
    MANAGE_ROLES: [
        'update-roles',
        'delete-roles',
        'create-roles',
        'read-roles'
    ],
}

export const userType = {
    PIU_ADMIN: 'piuAdmin',
    STAFF: 'staff'
}


export {
    Can, Switch, RequirePermission,
    hasAnyPermission, Cannot
}
