import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ArrowRightIcon } from "@heroicons/react/outline";
import { yupResolver } from "@hookform/resolvers/yup";
import Modal from "components/Modal";
import { forwardRef, useImperativeHandle, useState } from "react";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import Button from "components/Button";
import Input from "components/form-control/Input";
import Option from "components/form-control/Option";
import Select from "components/form-control/Select";
import {
	GrievanceFormValidation,
	GVBComplainerFormValidation,
} from "forms/validation";
import useGlobalContext from "hooks/useGlobalContext";
import { saveValueToLocalStorage } from "hooks/useLocalStorageState";
import { getDepartments, getGrievanceNature } from "provider/api";
import AddAttachments from "./add-attachments";
import AddComplaint from "./add-complaint";
import Stepper from "./Stepper";
import TheDefendantForm from "./the-defendant";
import dayjs from "dayjs";

const GrievanceForm = forwardRef(
	(
		{
			isCreating,
			isGBV,
			showForm,
			setShowForm,
			activeGrievance,
			setActiveGrievance = () => {},
			onSubmit: _handleSubmit,
		},
		ref
	) => {
		const [grievanceNature, setGrievanceNature] = useState([]);
		const [departments, setDepartments] = useState([]);
		const { projectDetails } = useGlobalContext();
		const [currentStep, setCurrentStep] = useState(1);

		const {
			handleSubmit,
			register,
			reset: clearForm,
			formState: { errors },
		} = useForm({
			resolver: yupResolver(
				isGBV ? GVBComplainerFormValidation : GrievanceFormValidation
			),
		});

		// fetch grievance nature from backend
		useQuery("grievance-natures", getGrievanceNature, {
			onSuccess: ({ data: natures }) => {
				setGrievanceNature(natures);
			},
		});

		// fetch department from backend
		useQuery(["departments", projectDetails?.piu], getDepartments, {
			enabled: !!projectDetails?.piu,
			onSuccess: ({ data }) => {
				setDepartments(data);
			},
		});

		// create function to be called from the parent component
		useImperativeHandle(ref, () => ({
			closeForm() {
				handleClose();
			},
		}));

		// clear form fields, reset active grievance and close form
		const handleClose = () => {
			console.log("called");
			setShowForm(false);
			setActiveGrievance({});
			setCurrentStep(1);
			saveValueToLocalStorage("grievanceStep1FormData", null);
			saveValueToLocalStorage("grievanceStep2FormData", null);
			clearForm();
		};

		// handle form data
		const onSubmit = (formData) => {
			setCurrentStep(currentStep + 1);
			const { createdDate, estimatedResolveDate } = formData;
			const payload = {
				...formData,
				dateOfIncident: formData.createdDate,
			};

			if (!isGBV) {
				payload["estimatedResolveDate"] = dayjs(createdDate).add(
					parseInt(estimatedResolveDate),
					"day"
				);
			}
			saveValueToLocalStorage("grievanceStep1FormData", payload);
		};

		return (
			<Modal isOpen={showForm} onClose={handleClose}>
				<div
					className={
						"w-[40%] 2xl:w-[35%] bg-[#F9F9F9] rounded-lg overflow-hidden"
					}
				>
					<div className="flex justify-between px-5 py-3 bg-gray-300 rounded-t-md">
						<p className="font-semibold text-[#002F4F]">
							{isGBV ? "Add GBV Grievance" : "Add Grievance"}
						</p>

						<button
							onClick={handleClose}
							className="-mr-1 border-2 focus:ring-0 ring-0 border-[#192B40]/50 w-6 h-6 bg-[#628799] text-white rounded-full grid place-items-center"
						>
							<FontAwesomeIcon color="FFFFFF" icon={faXmark} />
						</button>
					</div>

					{/* form steppers */}
					<Stepper currentStep={currentStep} steps={isGBV ? 4 : 3}/>

					{currentStep === 1 && (
						<form
							onSubmit={handleSubmit(onSubmit)}
							className={"grid p-8 gap-x-4 space-y-4 grid-cols-2"}
						>
							<div className={"space-y-1 col-span-2"}>
								<label
									className="block mb-2 text-sm font-medium text-[#002F4F]"
									htmlFor=""
								>
									Complaint title
								</label>
								<Input
									defaultValue={activeGrievance?.title ?? ""}
									register={register}
									isError={!!errors?.title?.message}
									name={"title"}
									className={"text-sm"}
									placeholder={"eg environmental pollution"}
								/>
							</div>

							{/* complainer's name*/}
							<div className={"space-y-1 col-span-2"}>
								<label
									className="block mb-2 text-sm font-medium text-[#002F4F]"
									htmlFor=""
								>
									Complainer's name
								</label>
								<Input
									defaultValue={
										activeGrievance?.complainer?.fullName ??
										""
									}
									register={register}
									isError={!!errors?.complainer?.message}
									name={"complainer"}
									className={"text-sm"}
									placeholder={"eg Boniface Kato Baraka"}
								/>
							</div>

							{/* complainer's gender */}
							<div
								className={`2xl:col-span-2 ${
									isGBV ? "block" : "hidden"
								}`}
							>
								<label
									htmlFor="createdDate"
									className="block mb-2 text-sm font-medium text-[#002F4F]"
								>
									Complainer's Gender
								</label>
								<Select
									isError={!!errors.complainerGender?.message}
									defaultValue={
										activeGrievance?.complainerGender ?? ""
									}
									{...register("complainerGender")}
									className={"text-sm"}
								>
									<option value="" disabled={true}>
										Choose
									</option>
									<Option value={"M"} text="Male" />
									<Option value={"F"} text="Female" />
								</Select>
							</div>

							{/* complainer's age */}
							<div className={`space-y-1 2xl:col-span-2 `}>
								<label
									className="block mb-2 text-sm font-medium text-[#002F4F]"
									htmlFor=""
								>
									Complaint's Age
								</label>
								<Input
									isPhoneNumber
									defaultValue={
										activeGrievance?.complainer
											?.complainerAge ?? ""
									}
									register={register}
									isError={!!errors?.complainerAge?.message}
									name={"complainerAge"}
									className={"text-sm"}
								/>
							</div>

							{/* complainer's email */}
							<div className={"space-y-1 2xl:col-span-2"}>
								<label
									className="block mb-2 text-sm font-medium text-[#002F4F]"
									htmlFor=""
								>
									Complainer's email
								</label>
								<Input
									defaultValue={
										activeGrievance?.complainer?.email ?? ""
									}
									register={register}
									isError={!!errors?.email?.message}
									name={"email"}
									className={"text-sm"}
									placeholder={"eg 6onnykato@gmail.com"}
								/>
							</div>

							{/* complainer's phone-number */}
							<div className={"space-y-1 2xl:col-span-2 "}>
								<label
									className="block mb-2 text-sm font-medium text-[#002F4F]"
									htmlFor=""
								>
									Complainer's phone number
								</label>
								<Input
									defaultValue={
										activeGrievance?.complainer
											?.phoneNumber ?? ""
									}
									type={"tel"}
									register={register}
									isError={!!errors?.phoneNumber?.message}
									name={"phoneNumber"}
									className={"text-sm"}
									placeholder={"eg 0759144442"}
								/>
							</div>

							{/* complaint creation date */}
							<div
								className={`space-y-1 2xl:col-span-2 ${
									isGBV ? "hidden" : "bloc,"
								}`}
							>
								<label
									className="block mb-2 text-sm font-medium text-[#002F4F]"
									htmlFor=""
								>
									Date of complaint
								</label>
								<Input
									type={"date"}
									defaultValue={
										activeGrievance?.createdTime ?? ""
									}
									register={register}
									isError={!!errors?.createdTime?.message}
									name={"createdTime"}
									className={"text-sm"}
									placeholder={"eg Finance, Human Resources"}
								/>
							</div>

							{/* estimation date */}
							<div
								className={`2xl:col-span-2 ${
									isGBV ? "hidden" : "block"
								}`}
							>
								<label
									htmlFor="createdDate"
									className="block mb-2 text-sm font-medium text-[#002F4F]"
								>
									Estimated resolve date
								</label>
								<Select
									isError={!!errors.riskLevel?.message}
									defaultValue={
										activeGrievance?.riskLevel ?? ""
									}
									{...register("estimatedResolveDate")}
									className={"text-sm"}
								>
									<option value="" disabled={true}>
										Choose
									</option>
									<Option value={"7"} text="7 Days" />
									<Option value={"14"} text="14 Days" />
								</Select>
							</div>

							{/* risk level */}
							<div
								className={`2xl:col-span-2 ${
									isGBV ? "hidden" : "block"
								}`}
							>
								<label
									htmlFor="createdDate"
									className="block mb-2 text-sm font-medium text-[#002F4F]"
								>
									Risk level
								</label>
								<Select
									isError={!!errors.riskLevel?.message}
									defaultValue={
										activeGrievance?.riskLevel ?? ""
									}
									{...register("riskLevel")}
									className={"text-sm"}
								>
									<option value="" disabled={true}>
										Choose
									</option>
									{["High", "Medium", "Low"].map((nature) => (
										<Option key={nature} value={nature} />
									))}
								</Select>
							</div>

							{/*  submit button */}
							<div className={"col-span-2"}>
								<Button
									block={true}
									type="submit"
									className={
										"flex items-center space-x-2 text-sm"
									}
								>
									<span>NEXT STEP</span>
									<ArrowRightIcon className={"h-5 w-5"} />
								</Button>
							</div>
						</form>
					)}

					{/* {currentStep === 2 && <TheDefendantForm />} */}

					{currentStep === 2 && isGBV ? (
						<TheDefendantForm
							activeGrievance={activeGrievance}
							setCurrentStep={setCurrentStep}
						/>
					) : null}

					{(currentStep === 2 && !isGBV) ||
					(currentStep === 3 && isGBV) ? (
						<AddComplaint
							isGBV={isGBV}
							activeGrievance={activeGrievance}
							setCurrentStep={setCurrentStep}
							departments={departments}
							grievanceNature={grievanceNature}
						/>
					) : null}

					{(currentStep === 3 && !isGBV) ||
					(currentStep === 4 && isGBV) ? (
						<AddAttachments
							isGBV={isGBV}
							activeGrievance={activeGrievance}
							isCreating={isCreating}
							onSubmit={_handleSubmit}
							onClose={handleClose}
							setCurrentStep={setCurrentStep}
						/>
					) : null}
				</div>
			</Modal>
		);
	}
);
export default GrievanceForm;
