import React, { useEffect, useRef, useState } from "react";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import PlaceAutoComplete from "../PlaceAutoComplete";
import axios from "axios";
import { useQuery } from "react-query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

export default function Map({
    defaultValue = "",
    showSearchBox = true,
    onChange: handleLocationChange,
}) {
    const mapContainer = useRef(null);
    const map = useRef(null);
    const markerContainer = useRef(null);
    const popupContainer = useRef(null);
    const marker = useRef(null);
    // const [lng, setLng] = useState(defaultValue.center ? defaultValue.center[0] : 34.888821);
    // const [lat, setLat] = useState(defaultValue.center ? defaultValue.center[1] : -6.369028);
    const [lng, setLng] = useState(
        defaultValue.geometry?.coordinates
            ? defaultValue.geometry?.coordinates[0]
            : 34.888821
    );
    const [lat, setLat] = useState(
        defaultValue.geometry?.coordinates
            ? defaultValue.geometry?.coordinates[1]
            : -6.369028
    );
    const [coordinates, setCoordinates] = useState({});
    const [zoom, setZoom] = useState(defaultValue ? 15 : 0);
    const [showMarker, setShowMarker] = useState(!!defaultValue);
    const [newLocation, setNewLocation] = useState({});
    const defaultMaxBounds = [27.583, -13.33, 44.45, 0.5];

    const searchPlaceByCoordinates = () => {
        return axios(
            `https://nominatim.openstreetmap.org/reverse?lon=${coordinates.lng}&lat=${coordinates.lat}&countrycodes=tz&format=json&limit=10&addressdetails=1`
        );
    };

    const { refetch: searchPlace } = useQuery(
        "search-place-by-coordinates",
        searchPlaceByCoordinates,
        {
            enabled: false,
            onSuccess: (res) => {
                setNewLocation(res.data);
            },
        }
    );

    useEffect(() => {
        if (map.current) return; // initialize map only once
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: "mapbox://styles/mapbox/streets-v11",
            center: [lng, lat],
            maxBounds: defaultMaxBounds, // show only tanzania map
            zoom: zoom,
        });

        const popup = new mapboxgl.Popup({ closeOnClick: false })
            // .setLngLat([lng, lat])
            .setDOMContent(popupContainer.current);

        // .addTo(map.curren

        // Create a default Marker and add it to the map.
        marker.current = new mapboxgl.Marker(markerContainer.current, {
            draggable: true,
        })
            .setLngLat([lng, lat])
            .addTo(map.current);
        // .setPopup(popup)

        if (newLocation.length) {
            console.log("New Location", newLocation.length);
        }
    });

    const onDragEnd = () => {
        const newCoordinates = marker.current.getLngLat();
        setCoordinates(newCoordinates);
        if (coordinates.lng && coordinates.lat) {
            searchPlace();

            // newLocation && setLocation(newLocation);
        } else {
            setNewLocation({});
        }
    };

    marker.current && marker.current.on("dragend", onDragEnd);

    useEffect(() => {
        if (!map.current) return; // wait for map to initialize
        map.current.on("move", () => {
            setLng(map.current.getCenter().lng.toFixed(4));
            setLat(map.current.getCenter().lat.toFixed(4));
            setZoom(map.current.getZoom().toFixed(2));
        });
    });

    // Update coordinates and reflect to the map
    const updateLocation = (newLocation) => {
        handleLocationChange(newLocation);
        // setLocation(newLocation);
        // const center = newLocation.center;
        const center = newLocation.geometry.coordinates;
        setShowMarker(true);
        console.log(center);

        if (!map.current && !marker.current) return;
        map.current.flyTo({
            center,
            zoom: 15, // update zoom level from 0-10
        });
        marker.current.setLngLat(center); // move marker to the new coordinates
        // popup.current.setLngLat(center) // move marker to the new coordinates
    };

    return (
        <div className={"h-full relative"}>
            {showSearchBox && (
                <div className={"absolute pl-5 z-30 pt-3  top-0"}>
                    <PlaceAutoComplete
                        onClick={updateLocation}
                        defaultValue={defaultValue.properties?.display_name}
                    />
                </div>
            )}

            <div
                className={` h-6 w-6 rounded-full rounded-ful ${
                    showMarker ? "bg-red-500" : "bg-transparent"
                } relative`}
                ref={markerContainer}
            >
                {/*  Marker Animation   */}
                <div
                    className={`absolute inset-0 ${
                        showMarker ? "bg-red-500" : "bg-transparent"
                    } rounded-full marker-animation`}
                ></div>
            </div>

            <div ref={popupContainer} className={"w-full p-2  text-white/90"}>
                <div>
                    <p className={"uppercase text-[10px]"}>Location Details</p>
                    <div className={"flex space-x-1 text-xsm"}>
                        <FontAwesomeIcon
                            icon={faLocationDot}
                            className={"h-4 mt-1 w-4"}
                        />
                        <p>{newLocation?.display_name}</p>
                    </div>
                </div>
            </div>

            <div ref={mapContainer} className="h-full" />
        </div>
    );
}
