import GrievanceCard from "./grievance-card";
import {getRandomKey} from "../../../../../../../utils";

const Grievances = ({grievances}) => {
    return (
        <div className={'space-y-5'}>
            <p className={'px-5 py-2 text-[#666666] tracking-wider bg-[#F7F7F7]'}>Grievances</p>
            <div className={'grid grid-cols-4 gap-3'}>
                {grievances.map((grievance) => (
                    <GrievanceCard
                        key={grievance?._id || getRandomKey()}
                        grievance={grievance}
                    />
                ))}
            </div>
        </div>
    )
}
export default Grievances;