import { useState } from "react";
import { RadioGroup } from "@headlessui/react";
import CustomRadioButton from "../_partials/CustomRadioButton";
import PropsTypes from "prop-types";

const StakeholderType = ({ defaultType, onChange: onChangeHandler }) => {
    const [stakeholderType, setStakeholderType] = useState(
        defaultType ?? "Individual"
    );
    const stakeholderTypes = ["Individual", "Organizational", "Community"];

    const handleChange = (type) => {
        setStakeholderType(type);
        onChangeHandler(type);
    };
    return (
        <RadioGroup
            name={"type"}
            className={"space-y-3"}
            value={stakeholderType}
            onChange={handleChange}
        >
            <RadioGroup.Label className={"text-primary tracking-wide"}>
                Select type of Stakeholder
            </RadioGroup.Label>
            <div className={"flex items-center space-x-5"}>
                {stakeholderTypes.map((type) => (
                    <RadioGroup.Option
                        className={
                            "rounded-md focus:ring-4 focus:ring-accent/50 focus:outline-none"
                        }
                        value={type}
                    >
                        {({ checked }) => (
                            <CustomRadioButton
                                key={type}
                                type={type}
                                isSelected={checked}
                            />
                        )}
                    </RadioGroup.Option>
                ))}
            </div>
        </RadioGroup>
    );
};

StakeholderType.protoTypes = {
    defaultType: PropsTypes.string,
    onChange: PropsTypes.func.isRequired,
};
export default StakeholderType;
