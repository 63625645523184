import dayjs from "dayjs";

const PhaseFooter = ({ startDate, endDate }) => {
    const formatDate = (date) => {
        return dayjs(date).format("MMM DD, YYYY");
    };

    const phaseDateRange = [
        { name: "Start Date", value: formatDate(startDate) },
        { name: "End Date", value: formatDate(endDate) },
    ];
    return (
        <div
            className={
                "px-4 py-3.5 flex space-x-7 justify-end divide-x-[1px] divide-[#DCE1EA]"
            }
        >
            {phaseDateRange.map((date) => (
                <div key={date.name} className={"text-end px-5"}>
                    <p className={"text-xs text-[#A6B1C2] capitalize"}>
                        {date.name}
                    </p>
                    <h3 className={"text-dark3 text-[15px] font-medium"}>
                        {date.value}
                    </h3>
                </div>
            ))}
        </div>
    );
};
export default PhaseFooter;
