import {useMutation} from "react-query";
import {Navigate, useNavigate} from "react-router-dom";
import {updateUserInfo} from "../../provider/api";
import {saveValueToLocalStorage} from "../../hooks/useLocalStorageState";
import useGlobalContext from "hooks/useGlobalContext";
import {useAuth} from "../../provider/auth/auth";
import {localStorageKeys} from "../../constants";

const GetStarted = () => {
    const navigate = useNavigate();
    const {setProjectId} = useGlobalContext();
    const {authUser} = useAuth();

    // check if a selected project has subprojects
    const hasSubprojects = (projectId) => {
        const {subProjects = []} = authUser;
        return subProjects.some(({parentProject} = {}) => parentProject === projectId)
    };

    // send put request to update user details
    const {isLoading, mutate: updateUserDetails} = useMutation('update-user-details', updateUserInfo, {
        onSuccess: ({data}) => {
            const {defaultProject} = data;

            // update a user default project, so next time won't be necessary to select projects to get started
            setProjectId(defaultProject);
            saveValueToLocalStorage(localStorageKeys.PROJECT_ID, defaultProject);

            if (hasSubprojects(defaultProject)) {
                return navigate('/select-subprojects');
            } else {
                return navigate("/", {replace: true});
            }
        },

        onError: () => {
            // ...
        }
    })

    if (authUser.projects.length <= 1 || !!authUser.defaultProject) {
        return <Navigate to={-1} replace={true}/>
    }


    const handleClick = (project) => {
        updateUserDetails({
            userId: authUser._id,
            defaultProject: project._id
        })
    };

    return (
        <section className="h-screen flex-col space-y-16 center bg-[#f4f4f4] px-56">
            <div className="w-full space-y-8 2xl:space-y-12">
                <h1 className="text-3xl font-semibold text-center text-dark">
                    Select Project To Get Started
                </h1>
                <small>{isLoading && 'updating user default project'}</small>

                <div className={"grid-cols-3 2xl:grinw-full gap-4 grid"}>
                    {authUser.projects.map((project) => (
                        <div
                            onClick={() => handleClick(project)}
                            className={
                                "h-40 2xl:h-56 bg-white space-y-4 flex-col center text-center p-4 hover:opacity-80 hover:shadow-lg cursor-pointer rounded-lg shadow-lg"
                            }
                        >
                            <p
                                className={
                                    "font-semibold text-dark 2xl:text-lg"
                                }
                            >
                                {project.name}
                            </p>
                            <p className={"uppercase 2xl:text-sm text-dark2"}>
                                {project.abbreviation}
                            </p>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default GetStarted;
