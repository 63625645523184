import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { SearchIcon } from "@heroicons/react/outline";
import CustomMenu from "../../../components/custom-menu";
import { Link } from "react-router-dom";
import useCommitments from "../../../hooks/useCommitments";

const Header = () => {
    const { handleFilter: filterCommitments, filterByStatus } =
        useCommitments();

    const handleChange = ({ target }) => {
        filterCommitments(target.value.toLowerCase());
    };

    // const handleClick = (e) => {
    //     e.stopPropagation();
    //     setShowFilterOptions(!showFilterOptions);
    //     const value = e.target.getAttribute("status");
    //     filterByStatus(value);
    // };

    const filters = [
        { _id: "all", name: "All" },
        { _id: 0, name: "Off Track" },
        { _id: 1, name: "On Track" },
        { _id: 2, name: "Completed" },
        { _id: 3, name: "Overdue" },
    ];

    const handleClick = ({ _id: status }) => {
        filterByStatus(status);
    };

    return (
        <div className="bg-[#F1F6F9] w-full border border-[#1A9FDF] rounded p-[2px]">
            <div className="border border-[#ECECED] bg-white px-4 py-2 flex place-items-center justify-between">
                <p className="font-semibold text=[#002F4F]">
                    Commitment Register
                </p>

                <div className={"flex space-x-3 items-center"}>
                    <Link
                        to={"create"}
                        className={
                            "cursor-pointer rounded hover:bg-[#CFE5F56A] font-medium text-xs text-[#009EE2] px-4 py-2 relative border-[#009EE2] border-[1.5px] space-x-2"
                        }
                    >
                        <span>ADD COMMITMENTS</span>
                        <FontAwesomeIcon icon={faPlus} />
                    </Link>
                    <div
                        className={
                            "border-[1px] border-[#638798] flex items-center space-x-2" +
                            " rounded overflow-hidden text-[#628799] px-2 py-1.5"
                        }
                    >
                        <SearchIcon className={"h-5 w-5"} />
                        <input
                            onChange={handleChange}
                            type="text"
                            className={
                                "h-full font-light bg-transparent p-0 border-none focus:ring-0 text-sm w-full outline-none placeholder-[#628799]"
                            }
                            placeholder={"Filter commitment"}
                        />
                    </div>
                    <CustomMenu
                        menuName={"Filters"}
                        options={filters}
                        displayName={"name"}
                        onClick={handleClick}
                    />
                </div>
            </div>
        </div>
    );
};

export default Header;
