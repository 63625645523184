const Loading = () => {
    return (
        <div className={"flex items-center pb-1 space-x-4"}>
            {[...Array(5)].map((items, index) => (
                <p
                    key={index}
                    className={"h-10 w-32 skeleton-loading rounded"}
                ></p>
            ))}
        </div>
    );
};
export default Loading;
