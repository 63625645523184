import React from "react";
import Button from "components/Button";
import { ArrowRightIcon } from "svg";
import { Link } from "react-router-dom";

const ActionButtons = ({
	onPrevClick: handlePrevClick,
	nextBtnLabel,
	showPrev = true,
	prevLink,
	isLoading = false,
	className,
}) => {
	return (
		<div
			className={`flex ${
				!showPrev ? "justify-end" : "justify-between items-center"
			} ${className}`}
		>
			{showPrev && (
				<Link
					to={prevLink}
					type={"button"}
					onClick={handlePrevClick}
					className={
						" px-6  py-3 text-sm rounded-[2px] text-primary flex items-center space-x-2"
					}
				>
					<p>PREVIOUS STEP</p>
				</Link>
			)}
			<Button
				type={"submit"}
				loading={isLoading}
				className={
					"bg-accent px-6 py-3 border-accent text-xs rounded-[2px] text-white flex items-center space-x-2"
				}
			>
				<span>{nextBtnLabel}</span>
				<ArrowRightIcon className={"h-4 w-4"} />
			</Button>
		</div>
	);
};

export default ActionButtons;
