import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useNavigate, useOutletContext } from "react-router-dom";
import EditorJS from "../../../../components/editorjs/editor";
import ActionButtons from "../../../../components/form-control/action-buttons";
import useGlobalContext from "../../../../hooks/useGlobalContext";
import {
    getValueFromLocalStorage,
    saveValueToLocalStorage,
} from "../../../../hooks/useLocalStorageState";
import { createPIU, updatePIUDetails } from "provider/api";
import { isEmptyDescription } from "utils";
import { ContactPersonalValidation } from "../../../../forms/validation";
import {
    cacheActions,
    localStorageKeys,
    queryKeys,
} from "../../../../constants";
import FormHeader from "../../../../components/form-header";
import UnstableSelect from "../../../../components/unstable/select";
import UnstableInput from "../../../../components/unstable/input";
import ContactPersonAddress from "../../../general/stakeholder/form/stakeholder/contact-personal/address";
import getContactPersonalFields from "../../../general/stakeholder/form/stakeholder/contact-personal/fields";
import { clearForm } from "./piu-registration-form-layout";
import useUpdateCache from "../../../../hooks/useUpdateCache";

const PIUContactPerson = () => {
    const {
        handleSubmit,
        register,

        formState: { errors, isSubmitted },
    } = useForm({
        resolver: yupResolver(ContactPersonalValidation),
    });

    const updateCache = useUpdateCache();
    const { piuForm: piuLSKeys } = localStorageKeys;
    const { setCurrStep } = useOutletContext();
    const piuBasicDetails = getValueFromLocalStorage(piuLSKeys.BASIC_DETAILS);
    const piuOperationDetails = getValueFromLocalStorage(
        piuLSKeys.MISSION_AND_VISION
    );
    const navigate = useNavigate();
    const contactPerson = getValueFromLocalStorage(
        piuLSKeys.CONTACT_PERSON,
        {}
    );
    const isUpdating = getValueFromLocalStorage(
        piuLSKeys.IS_UPDATING_DETAILS,
        false
    );

    // states
    const [description, setDescription] = useState(
        contactPerson?.description ?? ""
    );
    const [address, setAddress] = useState(
        contactPerson?.physicalAddress ?? ""
    );

    const { toast } = useGlobalContext();

    const handleEditorChange = (cleanData) => {
        setDescription(cleanData);
    };

    const clearFormUpdateCacheAndNavigateToPIUList = async (piu, action) => {
        const successMessage = isUpdating
            ? "Information has successfully been updated"
            : "PIU has been registered successfully";

        try {
            await clearForm();
            await updateCache(queryKeys.PIU_LIST, piu, action);
            navigate(`/settings/pius`);
            toast.success(successMessage);
        } catch (error) {
            toast.error("An error occurred while updating PIU information");
        }
    };

    const onError = ({ message }) => toast.error(message);

    const { mutate: _updatePIUDetails, isLoading: isUpdatingDetails } =
        useMutation(
            "update-piu-" + piuOperationDetails?._id,
            updatePIUDetails,
            {
                onSuccess: ({ data }) => {
                    return clearFormUpdateCacheAndNavigateToPIUList(
                        data,
                        cacheActions.UPDATE
                    );
                },
                onError,
            }
        );

    const { mutate: _registerPIU, isLoading } = useMutation(
        "create-piu",
        createPIU,
        {
            onSuccess: ({ data }) => {
                return clearFormUpdateCacheAndNavigateToPIUList(
                    data,
                    cacheActions.ADD
                );
            },
            onError,
        }
    );

    const buildPayload = (formData) => {
        const {
            _id,
            region,
            district,
            ward,
            street,
            postalCode,
            location,
            description,
            selectedSectors = [],
            ...rest
        } = piuBasicDetails;
        const { properties, geometry } = location;

        const { mission, piuId, vision } = piuOperationDetails;

        const payload = {
            ...rest,
            description: JSON.stringify(description),
            sectors: selectedSectors.map(({ name } = {}) => name),
            location: {
                name: properties.display_name,
                latitude: geometry.coordinates[1],
                longitude: geometry.coordinates[0],
            },

            address: {
                street,
                ward,
                district,
                region,
                postalCode,
                country: properties.address.country,
            },
            mission: JSON.stringify(mission),
            vision: JSON.stringify(vision),
            contactPerson: {
                ...formData,
                description: JSON.stringify(description),
                physicalAddress: address,
            },
        };

        if (isUpdating) {
            payload[piuId] = piuId;
        }
        return payload;
    };

    const setCurrStepToPrevStepAndStoreToLocalStorage = () => {
        setCurrStep(2);
        saveValueToLocalStorage(piuLSKeys.CURRENT_STEP, 2);
    };

    const fields = getContactPersonalFields(contactPerson, errors, register);

    const onSubmit = (formData) => {
        if (!isEmptyDescription(description) && address) {
            const payload = buildPayload(formData);
            const action = isUpdating ? _updatePIUDetails : _registerPIU;
            action(payload);
        }
    };

    return (
        <div className={"px-10 w-4/5 mx-auto  space-y-12 pb-16"}>
            <FormHeader
                title={"Contact Person"}
                description={`
                    Contact details of the person who is going to be the centre
                    of communication with stakeholders
                `}
            />

            <form
                onSubmit={handleSubmit(onSubmit)}
                action="src/pages/general/stakeholder/form/stakeholder/steps"
            >
                <div className="py-3 space-y-5 pr-40">
                    {fields.map((props) => {
                        return props?.options ? (
                            <UnstableSelect props={props} />
                        ) : (
                            <UnstableInput props={props} />
                        );
                    })}

                    <ContactPersonAddress
                        defaultAddress={contactPerson.physicalAddress}
                        onSelect={setAddress}
                    />

                    <div className="p space-y-2">
                        <p className="text-primary/80 tracking-wider">
                            Description
                        </p>
                        <EditorJS
                            isError={
                                isSubmitted && isEmptyDescription(description)
                            }
                            onChange={handleEditorChange}
                            defaultValue={contactPerson.description}
                        />
                    </div>

                    <ActionButtons
                        prevLink={"/register-piu/mission-and-vision"}
                        onPrevClick={
                            setCurrStepToPrevStepAndStoreToLocalStorage
                        }
                        isLoading={isUpdatingDetails || isLoading}
                        nextBtnLabel={isUpdating ? "SAVE" : "NEXT"}
                    />
                </div>
            </form>
        </div>
    );
};
export default PIUContactPerson;
