import dayjs from "dayjs";

const getBasicDetailsFields = (
    defaultDetails = {},
    errors,
    register,
    handleUpload
) => {
    const basicFields = [
        {
            name: "registeredName",
            hasError: !!errors?.registeredName?.message,
            defaultValue: defaultDetails?.registeredName,
            register,
            options: ["Internal", "External"],
        },
        {
            name: "abbreviation",
            hasError: !!errors?.abbreviation?.message,
            defaultValue: defaultDetails?.abbreviation,
            register,
            options: ["Internal", "External"],
        },
        {
            name: "yearFound",
            hasError: !!errors?.yearFound?.message,
            defaultValue: defaultDetails?.yearFound,
            type: "number",
            register,
        },
        {
            name: "registeredOn",
            hasError: !!errors?.registeredOn?.message,
            defaultValue: dayjs(defaultDetails?.registeredOn ?? "").format(
                "YYYY-MM-DD"
            ),
            type: "date",
            register,
        },
        {
            name: "registrationNumber",
            hasError: !!errors?.registrationNumber?.message,
            defaultValue: defaultDetails?.registrationNumber,
            register,
        },
        {
            name: "email",
            hasError: !!errors?.email?.message,
            defaultValue: defaultDetails?.email,
            register,
        },
        {
            name: "phoneNumber",
            hasError: !!errors?.phoneNumber?.message,
            defaultValue: defaultDetails?.phoneNumber,
            register,
        },
        {
            name: "websiteLink",
            hasError: !!errors?.websiteLink?.message,
            defaultValue: defaultDetails?.websiteLink,
            register,
        },
        {
            name: "logo",
            hasError: !!errors?.logo?.message,
            defaultValue: defaultDetails?.logo,
            isFileInput: true,
            onClick: handleUpload,
            register,
        },
    ];

    const colorFields = [
        {
            inputName: "primaryColor",
            hasError: !!errors?.primaryColor?.message,
            defaultColor: defaultDetails?.primaryColor,
            register,
        },
        {
            inputName: "secondaryColor",
            hasError: !!errors?.secondaryColor?.message,
            defaultColor: defaultDetails?.secondaryColor,
            register,
        },
        {
            inputName: "accentColor",
            hasError: !!errors?.accentColor?.message,
            defaultColor: defaultDetails?.accentColor,
            register,
        },
    ];

    const locationFields = [
        {
            name: "region",
            hasError: !!errors?.region?.message,
            defaultValue: defaultDetails?.region,
            placeholder: "Eg: District Executive",
            register,
        },
        {
            name: "district",
            hasError: !!errors?.district?.message,
            defaultValue: defaultDetails?.district,
            placeholder: "Eg: District Executive",
            register,
        },
        {
            name: "ward",
            hasError: !!errors?.ward?.message,
            defaultValue: defaultDetails?.ward,
            placeholder: "Eg: District Executive",
            register,
        },
        {
            name: "street",
            hasError: !!errors?.street?.message,
            defaultValue: defaultDetails?.street,
            placeholder: "Eg: District Executive",
            register,
        },
    ];
    return { basicFields, locationFields, colorFields };
};
export default getBasicDetailsFields;
