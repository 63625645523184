import { createContext, useContext, useState } from "react";
import { useMutation } from "react-query";
import { useNavigate, useOutletContext } from "react-router-dom";

import OnlineChannels from "./online-channel";
import PhysicalChannel from "./physical-channel";
import ActionButtons from "../_partials/action-buttons";
import {
    getValueFromLocalStorage,
    saveMultiValueToLocalStorage,
    saveValueToLocalStorage,
} from "hooks/useLocalStorageState";
import { updateProjectEvent } from "provider/api";
import TypeInput from "components/type-input/type-input";
import { localStorageKeys } from "../../../../../../../../../constants";

export const ChannelContext = createContext(null);
export const useChannelContext = () => {
    const context = useContext(ChannelContext);
    if (!context)
        throw new Error(
            "useChannelContext must be used inside of channel provider"
        );
    return context;
};

const Channel = () => {
    const { meetingForm: meetingLSKeys } = localStorageKeys;
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [setCurrStep, toast] = useOutletContext();
    const navigate = useNavigate();
    const meetingChannel = getValueFromLocalStorage(
        meetingLSKeys.MEETING_CHANNEL,
        {}
    );
    const meetingBasicInfo = getValueFromLocalStorage(
        meetingLSKeys.BASIC_DETAILS
    );
    const [isOnline, setIsOnline] = useState(
        meetingChannel?.channelType
            ? meetingChannel?.channelType === "Online"
            : true
    );
    const isEditing = getValueFromLocalStorage(
        meetingLSKeys.IS_UPDATING_DETAILS,
        false
    );
    const initialChannel = {
        channelType: meetingChannel?.channelType ?? "Online",
        name: meetingChannel?.channel?.name ?? "Whatsapp",
        value: meetingChannel?.channel?.value,
        locationName: meetingChannel?.locationName ?? null,
    };
    const [selectedChannel, setSelectedChannel] = useState(initialChannel);

    const { mutate: updateEvent, isLoading: isUpdatingEvent } = useMutation(
        updateProjectEvent,
        {
            onSuccess: async ({ data }) => {
                await saveChannelDataAndAdvanceToNextStep();
            },
            onError: ({ message }) => toast.error(message),
        }
    );

    const buildPayloadAndUpdateEventDetails = () => {
        const { channelType, locationName, ...rest } = selectedChannel;
        const payload = {
            ...meetingBasicInfo,
            channelType: isOnline ? "Online" : "Physical",
            locationName,
            channel: { ...rest },
        };
        updateEvent(payload);
    };

    const isFormValid = () => {
        setIsSubmitted(true);
        const { channelType, locationName } = selectedChannel;
        if (
            selectedChannel.name === "Virtual EventLayout" &&
            !selectedChannel.value?.length
        ) {
            return false;
        }
        return !(channelType === "Physical" && !locationName);
    };

    const saveChannelDataAndAdvanceToNextStep = async () => {
        setCurrStep(5);
        const { channelType, locationName, ...rest } = selectedChannel;
        const channelData = {
            channelType: isOnline ? "Online" : "Physical",
            locationName,
            channel: {
                ...rest,
            },
        };

        await saveMultiValueToLocalStorage({
            [meetingLSKeys.MEETING_CHANNEL]: channelData,
            [meetingLSKeys.CURRENT_STEP]: 5,
        });
        toast.success("Data has been saved successfully");
        navigate("/event-registration/meeting/add-attachment");
    };

    const handleSubmit = async () => {
        if (!isFormValid()) return;
        if (isEditing) {
            buildPayloadAndUpdateEventDetails();
        }
        await saveChannelDataAndAdvanceToNextStep();
    };

    const handlePrevious = async () => {
        setCurrStep(4);
        await saveValueToLocalStorage(meetingLSKeys.CURRENT_STEP, 4);
        navigate("/event-registration/meeting/set-datetime");
    };

    const setIsOnlineAndUpdateSelectedChannel = () => {
        setIsOnline(true);
        setSelectedChannel(initialChannel);
    };

    const handleChange = (type) => {
        if (type === "Online EventLayout") {
            return setIsOnlineAndUpdateSelectedChannel();
        }
        setIsOnline(false);
    };

    const typeInputProps = {
        onChange: handleChange,
        defaultType: isOnline ? "Online EventLayout" : "Physical EventLayout",
        types: ["Online EventLayout", "Physical EventLayout"],
        label: "Select Channel",
    };

    return (
        <ChannelContext.Provider
            value={{
                isSubmitted,
                setSelectedChannel,
                selectedChannel,
            }}
        >
            <div className={"space-y-10"}>
                <TypeInput {...typeInputProps} />

                {/* Online meeting contents */}
                {isOnline ? <OnlineChannels /> : <PhysicalChannel />}

                <ActionButtons
                    isEditing={isEditing}
                    onCancel={handlePrevious}
                    text={"Next"}
                    onSave={handleSubmit}
                    isLoading={isUpdatingEvent}
                />
            </div>
        </ChannelContext.Provider>
    );
};
export default Channel;
