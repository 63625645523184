const KilimanjaroSvg = ({ color, isLoading = false }) => {
    return (
        <path
            className={`${isLoading ? "animate-pulse" : ""}`}
            id="Path_437"
            data-name="Path 437"
            d="M514.035,125.927l5.4,3.019,3.228,1.8.6.963.691,4.245.085.516.784,4.786-.711.053-.388.336-.137.546.056.7-.739.056-.93.5-.873.692-.57.631-.2.348-.186.437-.1.457.06.413.283.288.643.081.2.308-.158.648-.311.765.113.539,1.87-.109.837.073.824.219.739.356.546.5.295.6.679,2.413.392.8.538.7,2.129,1.53,3.337,2.409,3.333,2.41,3.342,2.41,3.333,2.406,3.333,2.41,3.342,2.406,3.075,2.223,0,.006-13.334,19.1-1.765,1.226-1.035-1.222-.853-.748-.408-.17-.776-.152-.392-.259-.542-.217-2.356-.081-.877.118-1.439.9-.893.144-.942-.344-2.711-2.075-3.406-1.9-.9-.124-2.012-1.653-1.3-2.222-.077-3,.218-3.031-.085-1.26.028-1.221.586-1.43-.041-1.088-.234-1.058-.861-2.973-.473-.8-.347-1.983.137-4.353-.691-1.744-1.159-2.041-1.443-2.46-2.1-2.285-1.208-2.865.158-.745-.57-.494-1.144-.069-1.083-.348-.481-.71-.5-.591-.772.073-.78.227-1.039,1.044-1.071-.109-.99.095-.962.249-.081-7.1-1.459-2.681-2.727-1.8-1.253-.548-.949-.92-.36-1.833-.06-1.878.9-2.574,2.611-.769,1.305-.562.986-1.034,2.339-1.916,1.806-1.2,1.988-.065.675.247.764-.265,1.414.166,1.273.746,3.228,1.337,1.722.39Z"
            transform="translate(-207.88 -50.154)"
            fill={color}
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="0.5"
        />
    );
};
export default KilimanjaroSvg;
