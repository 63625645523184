import { useNavigate } from "react-router-dom";
import Header from "./header";
import GrievancesGrid from "./grid";
import GrievancesProvider from "../../../../provider/general/grievances";

const ClosedComplaints = () => {
    const navigate = useNavigate();

    return (
        <GrievancesProvider>
            <div className="w-full h-full pl-14 pr-24 bg-[#EBF0F3] space-y-4 overflow-y-scroll">
                <Header navToGrievances={() => navigate("/grievances")} />

                <GrievancesGrid />
            </div>
        </GrievancesProvider>

    );
}


export default ClosedComplaints;

