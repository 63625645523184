import React, { Fragment, useRef, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { Route, Routes } from "react-router-dom";

import Commitment from "./commitment";
import { createCommitments } from "provider/api";
import EmptyState from "./empty-state/empty-state";
import Header from "./header";
import CommitmentForm from "./form";
import Loading from "./loading";
import GridHeader from "./grid-header";
import CommitmentDetails from "./details/commitment-details";
import useCommitments from "../../../hooks/useCommitments";
import { queryKeys } from "../../../constants";

const Commitments = () => {
    const { filteredCommitments, isLoading, isFetched, commitments } =
        useCommitments();
    const [activeCommitment, setActiveCommitment] = useState(false);
    const [showDetails, setShowDetails] = useState(false);

    // const handleSuccess = ({ data }) => {
    //     setCommitments(data);
    //     setFilteredCommitments(data);
    // };

    // const {
    //     isFetched,
    //     isLoading,
    //     refetch: refetchCommitments,
    // } = useQuery(queryKeys.COMMITMENTS, () => getCommitments(projectId), {
    //     onSuccess: handleSuccess,
    //     onError: (err) => {
    //         // TODO: Handle errors
    //     },
    //     enabled: false,
    // });

    const queryClient = useQueryClient();

    const { mutate: createNewCommitment, isLoading: isCreating } = useMutation(
        createCommitments,
        {
            onSuccess: async ({ data }) => {
                await formRef.current.closeForm();
                queryClient.refetchQueries(queryKeys.COMMITMENTS);
            },

            onError: () => {
                alert("Saving commitment failed, please try again");
            },
        }
    );

    const formRef = useRef(null);

    const handleSubmit = (payload) => {
        createNewCommitment(payload);
    };

    const handleClose = () => {
        setShowDetails(false);
    };

    return (
        <Fragment>
            <Routes>
                <Route
                    path={"create"}
                    element={
                        <CommitmentForm
                            ref={formRef}
                            onSubmit={handleSubmit}
                            isCreating={isCreating}
                        />
                    }
                />
            </Routes>

            {showDetails ? (
                <CommitmentDetails
                    showDetails={showDetails}
                    closeHandler={handleClose}
                    commitment={activeCommitment}
                />
            ) : null}

            <section className="w-full h-screen bg-[#F1F6F9] p-10 overflow-y-scroll">
                <div className="sticky z-10 -top-10">
                    <Header />
                    {isLoading ? <Loading /> : <GridHeader />}
                    {isFetched && commitments.length < 1 ? (
                        <EmptyState />
                    ) : null}
                </div>

                {isFetched && commitments.length ? (
                    <div className="grid grid-cols-1 divide-y divide-[#E4E4E4] border border-[#E4E4E4] mb-6">
                        {filteredCommitments.map((stakeholder) => (
                            <Commitment
                                setShowDetails={setShowDetails}
                                setActiveCommitment={setActiveCommitment}
                                key={stakeholder.stakeholder?._id}
                                stakeholder={stakeholder}
                            />
                        ))}
                    </div>
                ) : null}
            </section>
        </Fragment>
    );
};

export default Commitments;
