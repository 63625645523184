import Day from "./day";
import React from "react";
import dayjs from "dayjs";

const Month = ({
    month,
    className,
    daysClass,
    showBorder,
    selectedDay,
    clickHandler,
    daysHeaderClass,
    listOfEvents = [],
}) => {
    const isSelected = (date1, date2) => {
        return (
            dayjs(date1).format("DD-MM, YYYY") ===
            dayjs(date2).format("DD-MM, YYYY")
        );
    };
    return (
        <div className={"h-full"}>
            <div
                className={`${daysHeaderClass}  grid uppercase content-center text-xs h-12 grid-cols-7 text-center `}
            >
                <div>Sun</div>
                <div>Mon</div>
                <div>Tue</div>
                <div>Wed</div>
                <div>Thu</div>
                <div>Fri</div>
                <div>Sat</div>
            </div>
            <div
                className={`${className} grid grid-cols-7 border-t-[1px] border-l-[1px] border-accent1  calender-day-wrapper`}
            >
                {month.map((row, i) => (
                    <React.Fragment key={i}>
                        {row.map((day, idx) => (
                            <Day
                                key={idx}
                                day={day}
                                className={daysClass}
                                showBorder={showBorder}
                                listOfEvents={listOfEvents}
                                clickHandler={clickHandler}
                                isSelected={isSelected(selectedDay, day)}
                            />
                        ))}
                    </React.Fragment>
                ))}
            </div>
        </div>
    );
};
export default Month;
