import { get, patch, post, put, remove } from "./request";
import { getValueFromLocalStorage } from "../../hooks/useLocalStorageState";

const projectId = getValueFromLocalStorage("projectId");
export const login = (payload) => {
    return post("/auth/login", payload);
};

export const register = (payload) => {
    return post("/stakeholders", payload);
};

export const updateUser = (payload) => {
    return patch("/users/me", payload);
};

export const fetchStakeholders = () => {
    return get("/stakeholders");
};

export const fetchEvents = () => {
    return get("/events");
};

export const fetchProjectStakeholders = (_projectId) => {
    return get(`/stakeholders/by-query?project=${_projectId}`);
};

export const getProjectPhases = (_projectId) => {
    return get(`/project-phases/by-query?project=${_projectId}`);
};

export const getEngagementPlans = (projectId, phaseId) => {
    return get(
        `/engagement-plans/by-project/${projectId}?projectPhase=${phaseId}`
    );
};

export const createProjectPhase = (payload) => {
    return post("/project-phases", payload);
};

export const updateProjectPhase = ({ _id, ...payload }) => {
    return put(`/project-phases/${_id}`, payload);
};
export const addEngagementPlan = (payload) => {
    return post("/engagement-plans", payload);
};

export const createProjectEvent = (payload) => {
    return post("/project-events", payload);
};

export const createCommitments = (payload) => {
    return post("/commitments", payload);
};

export const createGrievances = (payload) => {
    return post("/grievances", payload);
};

export const updateProjectEvent = (payload) => {
    return put(`/project-events/${payload._id}`, payload);
};

export const editEngagementPlan = (payload) => {
    return put(`/engagement-plans/${payload._id}`, payload);
};

export const deleteEngagementPlan = (planId) => {
    return remove(`/engagement-plans/${planId}`);
};

export const deleteProject = (payload) => {
    return remove(`/projects/${payload.id}`, payload);
};

export const getEngagementByStatus = (_projectId) => {
    return get(`/project-events/count-by-status?project=${_projectId}`);
};

export const getEventsByStakeholders = (_projectId) => {
    return get(`/project-events/group-by-stakeholder?project=${_projectId}`);
};

export const getEventReport = ({ queryKey }) => {
    const [, eventId] = queryKey;
    return get(`/project-events/${eventId}/report`);
};

export const updateCommitment = (payload) => {
    return put(`/commitments/${payload.id}`, payload);
};

export const getAllEvents = (_projectId) => {
    return get(
        `/project-events/by-query?project=${_projectId}&totalRecords=200`
    );
};

export const getCommitments = (_projectId) => {
    return get(
        `/commitments/by-stakeholders?project=${_projectId}&totalRecords=999999`
    );
};

export const getCommitmentsByStakeholder = ({ queryKey }) => {
    const [, stakeholderId] = queryKey;
    return get(`/commitments/by-query?stakeholder=${stakeholderId}`);
};

export const getEventsByStakeholder = ({ queryKey }) => {
    const [, stakeholderId] = queryKey;
    return get(
        `/project-events/by-query?project=${projectId}&stakeholder=${stakeholderId}&totalRecords=200`
    );
};

export const getStakeholderEngagementLevels = ({ queryKey }) => {
    const [, stakeholderId] = queryKey;
    return get(`/engagement-levels/by-query?stakeholder=${stakeholderId}`);
};

export const getGrievances = (_projectId) => {
    return get(
        `/grievances/by-query?project=${_projectId}&totalRecords=999999`
    );
};

export const updateGrievance = (payload) => {
    return put(`/grievances/${payload.id}`, payload);
};

export const deleteGrievance = (id) => {
    return remove(`/grievances/${id}`);
};

export const getProjectDetails = (_projectId) => {
    return get(`/projects/${_projectId}`);
};

export const getSubprojectDetails = (subprojectId) => {
    return get(`/sub-projects/${subprojectId}`);
};
export const getDepartments = ({ queryKey }) => {
    const [, piuId] = queryKey;
    return get(`/departments?piuId=${piuId}`);
};

export const updateKeyResult = (payload) => {
    const { commitmentId, resultId, ...restPayload } = payload;
    return put(`/commitments/${commitmentId}/results/${resultId}`, restPayload);
};

export const getProjectFilesByFolders = () => {
    return get(`/projects/${projectId}/files-by-folder`);
};
export const getProjectFilesByCategory = () => {
    return get(`/projects/${projectId}/files-by-category`);
};

export const createGrievance = (payload) => {
    return post("/grievances", payload);
};

export const grievanceAnalytics = (_projectId) => {
    return get(`/grievances/report?projectId=${_projectId}`);
};

export const getCommitmentAnalytics = (_projectId) => {
    return get(`/commitments/report?projectId=${_projectId}`);
};

export const getHighRiskGrievances = () => {
    return get(
        `/grievances/by-query?project=${projectId}&isResolved=0&projectRisk=High`
    );
};

export const getUsers = (endpoint) => {
    // return get(IS_WB_ADMIN ? '/users' : `/users?project=${_projectId}`);
    return get(endpoint);
};

export const getUserRoles = () => {
    return get("/access-control/roles");
};

export const getEngagementAnalytics = (_projectId) => {
    return get(`/project-events/report?projectId=${_projectId}`);
};

export const getRolePermissions = () => {
    return get("/access-control/permissions");
};

export const createRole = (payload) => {
    return post("/access-control/roles", payload);
};

export const updateUserRole = (payload) => {
    return put(`/access-control/roles/${payload._id}`, payload);
};

export const updateRolePermission = (payload) => {
    return put(
        `/access-control/roles/${payload?.permissions?.roleId}/grant-permission`,
        payload
    );
};

export const getProjectImages = () => {
    return get(`/projects/${projectId}/images`);
};

export const createNewUser = (payload) => {
    return post("/users/invite", payload);
};

export const updateUserInfo = (payload) => {
    return put(`/users/${payload.userId}`, payload);
};

export const changerUserPassword = (payload) => {
    return post("/auth/change-password", payload);
};

export const getUnapprovedEventsBudget = (approver, _projectId) => {
    return get(
        `/project-events/by-query?project=${_projectId}&totalRecords=200&budgetApprover=${approver}&isMeeting=1`
    );
};

export const updateStakeholderDetails = ({
    _id: stakeholderId,
    ...payload
}) => {
    return put(`/stakeholders/${stakeholderId}`, payload);
};

export const createDepartment = (payload) => {
    return post("/departments", payload);
};

export const getAllDepartments = () => {
    return get("/departments");
};

export const updateDepartment = ({ dpId, ...payload }) => {
    return put(`/departments/${dpId}`, payload);
};

export const deleteDepartment = ({ dpId }) => {
    return remove(`/departments/${dpId}`);
};

export const createGrievanceNature = (payload) => {
    return post("/grievance-natures", payload);
};

export const getGrievanceNature = () => {
    return get("/grievance-natures");
};

export const updateGrievanceNature = ({ natureId, ...payload }) => {
    console.log("payload", payload);
    return put(`/grievance-natures/${natureId}`, payload);
};

export const removeGrievanceNature = ({ natureId }) => {
    return remove(`/grievance-natures/${natureId}`);
};

export const getPIUS = () => {
    return get("/pius");
};

export const createProject = (payload) => {
    return post("/projects", payload);
};

export const getProjects = () => {
    return get("/projects");
};

export const createPIU = (payload) => {
    return post("/pius", payload);
};

export const updatePIUDetails = ({ piuId, formData, ...payload }) => {
    console.log(payload);
    return put(`/pius/${piuId}`, payload);
};

export const getSubprojects = ({ queryKey }) => {
    const [, projectId] = queryKey;
    return get(`/sub-projects/by-query?parentProject=${projectId}`);
};

export const createSubproject = (payload) => {
    return post("/sub-projects", payload);
};

export const updateSubproject = ({ subprojectId, ...payload }) => {
    return put(`/sub-projects/${subprojectId}`, payload);
};

export const createWorkspace = (payload) => {
    return post("/workspaces", payload);
};

export const getWorkspaces = () => {
    return get("/workspaces");
};

export const updateWorkspace = ({ workspaceId, ...payload }) => {
    return put(`/workspaces/${workspaceId}`, payload);
};

export const removeWorkspace = ({ workspaceId }) => {
    return remove(`/workspaces/${workspaceId}`);
};
