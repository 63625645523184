const Loading = () => {
    return (
        <div className={"space-y-4"}>
            {[...Array(10)].map((item, index) => (
                <div className={"items-between space-x-6"}>
                    <div
                        key={index}
                        className={
                            "h-4 bg-white w-full items-between  skeleton-loading rounded-full"
                        }
                    ></div>

                    <div
                        className={
                            "h-4 w-4 shrink-0 rounded skeleton-loading border"
                        }
                    ></div>
                </div>
            ))}
        </div>
    );
};
export default Loading;
