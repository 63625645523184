import { CalenderIcon } from "../../../../../../svg/calender";
import { CreateMarkup } from "../../../../../../utils";
import { useGrievanceContext } from "./grievance";
import useDeleteFromS3Bucket from "../../../../../../hooks/useDeleteFromS3Bucket";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faPenAlt, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { PencilAltIcon, PencilIcon } from "@heroicons/react/outline";

const GrievanceCard = ({ grievance }, ...props) => {
	const {
		setActiveGrievance,
		setIsOpen: showForm,
		grievances = [],
		setGrievances,
	} = useGrievanceContext();
	const { deleteFromS3Bucket } = useDeleteFromS3Bucket();

	const handleEdit = () => {
		setActiveGrievance(grievance);
		showForm(true);
	};

	const deleteGrievance = () => {
		// delete from UI
		const grievanceTobeDeleted = grievances.find((grievance_) => {
			return grievance_.id === grievance.id;
		});

		setGrievances([
			...grievances.filter((grievance_) => {
				return grievance_.id !== grievanceTobeDeleted.id;
			}),
		]);

		// delete from bucket
		if (grievanceTobeDeleted?.attachments.length) {
			grievanceTobeDeleted?.attachments.forEach((file) => {
				deleteFromS3Bucket(file.fileKey);
			});
		}
	};

	return (
		<div
			{...props}
			className={
				"p-5 space-y-4 rounded-[4px] border-[1px] border-[#E4E4E4]"
			}
		>
			<div className={"items-between text-sm"}>
				<div className={"flex items-center space-x-2"}>
					<CalenderIcon className={"h-4"} color={"#AAB6C5"} />
					<p className={"text-[#595959] "}>
						{grievance?.createdDate}
					</p>
				</div>

				<div className={"space-x-2 flex items-center "}>
					<button
						onClick={handleEdit}
						className={
							"h-6 w-6 rounded-full hover:bg-gray-200 text-gray-600 center bg-gray-100"
						}
					>
						<FontAwesomeIcon
							icon={faPenAlt}
							className={"h-3 w-3"}
						/>
					</button>
					<button
						onClick={deleteGrievance}
						className={
							"h-6 w-6 rounded-full hover:bg-gray-200 text-gray-600 center bg-gray-100"
						}
					>
						<FontAwesomeIcon
							icon={faTrashCan}
							className={"h-3 w-3"}
						/>
					</button>
				</div>
			</div>

			<div className={"text-[13px] space-y-3"}>
				<p className={"text-[#707070] font-light tracking-wide"}>
					Complainer:{" "}
					<span className={"text-secondary"}>
						{grievance.complainer.fullName}
					</span>
				</p>

				<div>
					<h5 className={"text-[16px] font-medium text-dark"}>
						{grievance.title}
					</h5>
					<p
						dangerouslySetInnerHTML={CreateMarkup(
							grievance.description
						)}
						className={
							"text-[#202842]  leading-relaxed h-20 line-clamp-4"
						}
					></p>
				</div>
			</div>
		</div>
	);
};
export default GrievanceCard;
