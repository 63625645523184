import dayjs from "dayjs";
import { CreateMarkup } from "../../../../utils";

const RepeatedGrievanceCard = (
	{ grievance, onClick: showDetails },
	...props
) => {
	const formatDate = (date) => {
		return dayjs(date).format("DD, MM YYYY");
	};
	const handleCardClick = () => {
		showDetails(grievance);
	};
	return (
		<div
			{...props}
			onClick={handleCardClick}
			className={
				"bg-[#E3E4E886] hover:bg-[#E3E4E886]/80 cursor-pointer rounded-[5px] p-5"
			}
		>
			<div
				className={
					"text-xs space-y-2 border-l-[2.2px] pl-4 border-l-secondary/70"
				}
			>
				<p className={"text-sm text-primary"}>
					{formatDate(grievance?.dateOfIncident)} ({grievance?.time})
				</p>
				<div className={"s"}>
					<div>
						<p className={""}>
							Complainer:{" "}
							<span className={"text-primary"}>
								{grievance?.complainer?.fullName}
							</span>
						</p>
						<p className="text-base font-semibold text-dark">
							{grievance?.title}
						</p>
					</div>
					<p
						dangerouslySetInnerHTML={CreateMarkup(
							grievance?.description
						)}
						className={
							"text-text-dark2 line-clamp-2 leading-relaxed font-light"
						}
					></p>
				</div>
			</div>
		</div>
	);
};
export default RepeatedGrievanceCard;
