import Highcharts from 'highcharts'
import borderRadius from "highcharts-border-radius"
import HighchartsReact from 'highcharts-react-official'

borderRadius(Highcharts);

const BarChart = ({statistics}) => {

    const options = {
        chart: {
            type: 'column',
            scrollablePlotArea: {
                minWidth: "100%",
            }
        },
        title: {
            enabled: false,
            text: ''
        },
        xAxis: {
            min: 0,
            gridLineColor: "#EBF0F3",
            gridLineWidth: 0.6,
            categories: statistics.departmentNames
        },
        yAxis: {
            min: 0,
            gridLineColor: "#EBF0F3",
            gridLineWidth: 0.6,
            scrollbar: {
                enabled: true,
                showFull: false
            },
            title: {
                enabled: false,
                text: 'Stakeholder Activities by Status'
            },
            stackLabels: {
                enabled: true,
                style: {
                    fontWeight: 'normal',
                    color: ( // theme
                        Highcharts.defaultOptions.title.style &&
                        Highcharts.defaultOptions.title.style.color
                    ) || 'gray',
                    textOutline: 'none'
                }
            }
        },

        legend: {
            enabled: false,
            align: 'right',
            x: -10,
            verticalAlign: 'top',
            y: 10,
            floating: true,
            backgroundColor:
                Highcharts.defaultOptions.legend.backgroundColor || 'white',
            borderColor: '#CCC',
            borderWidth: 0.5,
            shadow: false
        },

        plotOptions: {
            column: {
                stacking: 'normal',
                pointWidth: 45,
                dataLabels: {
                    enabled: false
                }
            },
            // series: {
            //     borderRadiusTopLeft: 8,
            //     borderRadiusTopRight: 8
            // }
        },

        tooltip: {
            headerFormat: '<b>{point.x}</b><br/>',
            pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
        },

        series: [
            {
                name: 'Completed On Time',
                data: statistics.completedOnTime,
                color: '#70AB91'
            },
            {
                name: 'Completed Late',
                data: statistics.completedLate,
                color: '#1F78B4'
            }
            , {
                name: 'Incomplete',
                data: statistics.inComplete,
                color: '#B1CBDC'
            }, {
                name: 'overdue',
                data: statistics.overdue,
                color: '#F2BC9E'
            }]

    }

    return (
        <div className={'h-full w-full highcharts relative '}>
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
            />
        </div>
    )
}
export default BarChart;