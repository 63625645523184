import { UserGroup } from "components/user-group";
import { frequencyOptions } from "../phase2/projectPhaseContents";

const Plan = ({ plan }) => {
    const frequency = frequencyOptions.find(
        ({ value } = {}) => value === plan.frequency
    );

    return (
        <div className={`grid relative group gap-x-3 py-2 group grid-cols-6`}>
            <div className={"space-y-6 col-span-2 "}>
                <div className={"space-x-3 flex text-sm  text-[#838EAB]"}>
                    <div
                        className={
                            "h-[6px] w-[6px] mt-1.5 shrink-0 rounded-full bg-green-400"
                        }
                    ></div>

                    <p className={"font-light "}>{plan?.activity}</p>
                </div>
            </div>

            {/* Stakeholder Frequency */}
            <div className={"font-light text-sm text-[#838EAB] space-y-6"}>
                <div className={"flex items-center space-x-2"}>
                    <UserGroup className={"h-4 w-4"} />

                    <p className={"font-light"}>{frequency.name};</p>
                </div>
            </div>

            {/* Channel  */}
            <div className={"font-light text-sm text-[#838EAB] space-y-6"}>
                <div className={"flex items-center space-x-2"}>
                    <p className={"font-light"}>{plan?.channel}</p>
                </div>
            </div>

            {/* start date */}
            <div className={"text-sm text-[#002F4F] space-y-6"}>
                <p className={"font-light"}>13th January 2023</p>
            </div>

            {/* end date */}
            <div className={"text-sm text-[#002F4F] space-y-6"}>
                <p className={"font-light"}>13th January 2023</p>
            </div>

            <div className={"absolute hidden right-0"}>
                <button
                    type={"button"}
                    className={`text-red-500 hover:underline text-xs `}
                >
                    Delete
                </button>
            </div>
        </div>
    );
};
export default Plan;
