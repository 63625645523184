import React, {useEffect, useRef, useState} from 'react';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax


mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;


const ViewLocation  = ({setLocation, coordinates="", showSearchBox=true, dragMaker=true}) => {

    const markerContainer = useRef(null);
    const mapContainer = useRef(null);
    const map = useRef(null);
    const marker = useRef(null)
    const [lng, setLng] = useState(coordinates[0]);
    const [lat, setLat] = useState(coordinates[1]);
    const [zoom, setZoom] = useState(15);
    const defaultMaxBounds = [
        27.583, -13.33,
        44.450, 0.500
    ];



    useEffect(() => {
        if (map.current) {
            updateLocation(coordinates);
            return
        } // initialize a map only once

        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/mapbox/streets-v11',
            center: [lng, lat],
            maxBounds: defaultMaxBounds, // show only tanzania map
            zoom: zoom
        });

        // map.current.scrollZoom.disable();
        // map.current.addControl(new mapboxgl.FullscreenControl());


        // Create a default Marker and add it to the map.
        marker.current = new mapboxgl.Marker(markerContainer.current, {
            draggable: false
        })
            .setLngLat([lng, lat])
            .addTo(map.current)
    }, [coordinates]);


    useEffect(() => {
        if (!map.current) return; // wait for map to initialize
        map.current.on('move', () => {
            setLng(map.current.getCenter().lng.toFixed(4));
            setLat(map.current.getCenter().lat.toFixed(4));
            setZoom(map.current.getZoom().toFixed(2));
        });
    });


    // Update coordinates and reflect to the map
    const updateLocation = (newCenter) => {
        map.current.flyTo({
            center: newCenter,
        })
        marker.current.setLngLat(newCenter) // move marker to the new coordinates
        // popup.current.setLngLat(center) // move marker to the new coordinates
    }




    return (
        <div className={'h-full relative'}>
            <div
                className={` h-56 w-56 rounded-full rounded-ful bg-accent/20 flex justify-center items-center`}
                ref={markerContainer}>
                {/*  Marker Animation   */}
                <div
                    className={` h-12 w-12 bg-accent/80  rounded-full `}>
                </div>
            </div>

            <div ref={mapContainer} className="h-full"/>
        </div>
    );
}

export default ViewLocation;