const Loading = () => {
    return (
        <div className={"space-y-4"}>
            {[...Array(5)].map((items, index) => (
                <div
                    key={index}
                    className={
                        "h-10 px-3 bg-gray-100/60 rounded-lg items-between"
                    }
                >
                    <div
                        className={"h-3.5 w-1/2 skeleton-loading rounded"}
                    ></div>
                    <div className={"flex space-x-4 items-center"}>
                        <div
                            className={"h-3.5 w-32 skeleton-loading rounded "}
                        ></div>
                        <div
                            className={"h-3.5 w-20 skeleton-loading rounded "}
                        ></div>
                    </div>
                </div>
            ))}
        </div>
    );
};
export default Loading;
