const Loading = () => {
    return (
        <div
            className={
                "h-56 skeleton-loading w-56 mx-auto rounded-full rounded center"
            }
        >
            <div className={"h-10 w-10 rounded-full bg-white"}></div>
        </div>
    );
};
export default Loading;
