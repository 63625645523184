import DynamicInput from "../../../../components/daynamic-input";
import { useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { saveValueToLocalStorage } from "../../../../hooks/useLocalStorageState";
import { ArrowRightIcon } from "@heroicons/react/outline";

const PIUBranches = () => {
    const [inputError, setInputError] = useState({});
    const { setCurrStep } = useOutletContext();
    const navigate = useNavigate();

    const handleValueChange = () => {
        // ...
    };

    const inputValidator = ({ value }) => {
        console.clear();
        console.log(value);
        if (!value) return false;
        return true;
    };
    return (
        <div className={"px-10 w-4/5 mx-auto  space-y-12 pb-16"}>
            <div className={"space-y-2"}>
                <p className={"text-primary text-xl tracking-wider"}>
                    PIU Branches
                </p>
                <p
                    className={
                        "text-lg text-[#B0B2B4] tracking-wider font-light"
                    }
                >
                    Contact details of the person who is going to be the centre
                    of communication with stakeholders
                </p>
            </div>
            <form action="settings/pius/form">
                <DynamicInput
                    validator={inputValidator}
                    valueHandler={handleValueChange}
                    setErrorHandle={setInputError}
                    buttonLabel={"Add branch"}
                    placeholder={"eg Tanroads-Mwanza"}
                />

                {/* action buttons */}
                <div className={" flex  pt-4 justify-between items-center"}>
                    <button
                        type={"button"}
                        onClick={() => {
                            setCurrStep(3);
                            saveValueToLocalStorage("piuCurrStep", 3);
                            navigate("/register-piu/mission-and-vision");
                        }}
                        className={
                            " px-6  py-3 text-sm rounded-[2px] text-primary flex items-center space-x-2"
                        }
                    >
                        <p>PREVIOUS STEP</p>
                    </button>
                    <button
                        type={"submit"}
                        className={
                            "bg-accent px-6 py-3 text-xs rounded-[2px] text-white flex items-center space-x-2"
                        }
                    >
                        <span>NEXT</span>
                        <ArrowRightIcon className={"h-4 w-4"} />
                    </button>
                </div>
            </form>
        </div>
    );
};
export default PIUBranches;
