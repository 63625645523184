import { Fragment, useEffect, useState } from "react";
import { Combobox, Transition } from "@headlessui/react";
import {
    CheckCircleIcon,
    ExclamationCircleIcon,
} from "@heroicons/react/outline";
import { ChevronUpDownIcon } from "svg";
import SpinLoader from "../SpinLoader";
import { twMerge } from "tailwind-merge";

const UnstableComboboxInput = ({
    className,
    options = [],
    defaultOption,
    onChange: handleChange,
    name,
    hasError = false,
    multiple = false,
    displayItemName,
    isLoading = false,
    reset = false,
}) => {
    const [query, setQuery] = useState("");
    const [selectedOption, setSelectedOption] = useState(defaultOption ?? []);

    // call onChange handler with the latest selected options
    useEffect(() => {
        handleChange(selectedOption);
    }, [selectedOption]);

    // When the reset value changes, reset a selected option or options.
    useEffect(() => {
        reset && setSelectedOption([]);
    }, [reset]);

    // filter options by compare a given displayName with a search query typed from input box
    const filteredOptions =
        query === ""
            ? options
            : options.filter((option) => {
                  return option?.[displayItemName]
                      ?.toLowerCase()
                      .includes(query.toLowerCase());
              });

    const inputClass = twMerge(
        `
        w-full text-gray-500 focus:border-[1.7px] focus:ring-4 enable-transition  placeholder-[#AAB6C5] 
        focus:outline-none tracking-wider py-3 border-[1.4px] 
        ${
            hasError
                ? "border-red-500 focus:ring-red-500/30 focus:border-red-500"
                : "border-[#D2D4DA] focus:border-accent focus:ring-[#5D9EE9]/30"
        }
        rounded-[5px]`,
        className
    );

    const getDisplayValue = () => {
        if (multiple) {
            return {
                displayValue: (options) => {
                    return options
                        .map((optionItem) => optionItem[displayItemName])
                        .join(", ");
                },
            };
        } else {
            return {
                displayValue: (option) => option[displayItemName],
            };
        }
    };

    return (
        <Combobox
            value={selectedOption}
            onChange={setSelectedOption}
            multiple={multiple}
        >
            <div className={"space-y-1"}>
                <label
                    htmlFor={name}
                    className={"text-primary/80 capitalize tracking-wider"}
                >
                    {name}
                </label>
                <div className={"relative"}>
                    <Combobox.Input
                        id={name}
                        autoComplete={"off"}
                        className={inputClass}
                        onChange={(event) => setQuery(event.target.value)}
                        {...getDisplayValue()}
                    />
                    <Combobox.Button
                        className={"absolute right-2 top-0 bottom-0 my-auto"}
                    >
                        <ChevronUpDownIcon
                            className={"text-gray-400 h-6 w-6"}
                        />
                    </Combobox.Button>

                    {hasError ? (
                        <ExclamationCircleIcon
                            className={
                                "absolute right-8 top-0 h-5 w-5 text-red-500 bottom-0 my-auto"
                            }
                        />
                    ) : null}
                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <Combobox.Options
                            as="ul"
                            class={
                                "bg-white max-h-52 overflow-y-auto absolute top-full z-40 w-full shadow-base border-[1.4px]  border-light-gray py-2 rounded-[5px] mt-2"
                            }
                        >
                            {isLoading ? (
                                <div className={"py-3 center"}>
                                    <SpinLoader
                                        size={"large"}
                                        color={"#303030"}
                                        className={"border-[2.5px]"}
                                    />
                                </div>
                            ) : (
                                filteredOptions.map((item) => (
                                    <Combobox.Option
                                        as="li"
                                        key={item._id}
                                        className={"text-sm cursor-pointer"}
                                        value={item}
                                    >
                                        {({ active, selected }) => (
                                            <li
                                                className={`${
                                                    active
                                                        ? "bg-black/5 text-white"
                                                        : ""
                                                } flex items-center text-dark2 space-x-2 px-3 py-2`}
                                            >
                                                <CheckCircleIcon
                                                    className={`text-green-600 ${
                                                        selected
                                                            ? "opacity-100"
                                                            : "opacity-0"
                                                    } h-5 w-5`}
                                                />
                                                <p>{item[displayItemName]}</p>
                                            </li>
                                        )}
                                    </Combobox.Option>
                                ))
                            )}
                        </Combobox.Options>
                    </Transition>
                </div>

                {hasError ? (
                    <p className={"text-red-500 pt-1 text-sm"}>
                        Please select {name}
                    </p>
                ) : null}
            </div>
        </Combobox>
    );
};
export default UnstableComboboxInput;
