const Simiyu = ({ color, isLoading = false }) => {
    return (
        <path
            className={`${isLoading ? "animate-pulse" : ""}`}
            id="Path_454"
            data-name="Path 454"
            d="M335.331,86.5l0,0,.61,1.537-.016.37,1.544.859,1.026-.479,2.647.154L342.7,88.8l.267-1.365.958-.579,2.5,2.317,1.366.168,1.374,0,3.055,1.64,1.075.214.9.53.909,1.314,1.289.949,2.889,1.684,1.075,1.254.885,1.371,1.12.568,1.265-.158.99.055,2.194,1.5,1.439.635,21.5-.168.336,3.782-2.582,11.151-1.071,2-2.133.959-.606.506-.667.412-1.491-.475-.929-1.439-1.475-1.188-1.362-.2-.339,27.95-1.062,1.011-.921-.251-.767.326-.064.6-.238.557-1.341,1.223-1.047,1.486-.15,1.569.432,1.515-1.406.261-1.047.856-.5.834-6.647,2.7-1.426.294-1.4.423-1.168.869-1.876-1.354-1.657-1.557-2.175-2.8-1.657-3.841-1.346-4.462-1.243-2.075-3.21-.934-3.625-.1-2.9-.311-1.864-1.141-1.139-2.179-1.139-.519-1.346.726-1.45.311-1.968-.519-3.418-1.66-2.8-2.593L322,131.773l-2.03-.234.957-2.823.978-.916.514-1.214.263-1.861,1.123-3.393.332-1.582-1.208-4.385.557-1,.929-.228.594-.722.659-.593.934-.269,1.071-.688,2.7.781,1.491-1.163.687-.292.784.045.756-.763.133-1.112-.942-1.79-1.786-1.1-1.778-.813-1.422-1.266-1.716-1.7-2.992-3.464.575-.334-.115-.553-.253-.2-.219-.1-.138-.161-.057-.346.15-.311.334-.4.391-.334.322-.15.656-.1,1.565-.611.587-.357.84-1.117.575-.415.748.2,1.335-1.036.529-.3,1.738-.368.4-.357.4-.069,2.267-1.5-.081-.392-.184-.23-.184-.161-.092-.127.035-.368.219-.622.012-.368ZM318.8,87.876l.277-.1.421-.039-.42.04Zm4.291-.216.258.009.3.054-.305-.054Zm7.491-1.389.69-.5.323-.161-.318.161Z"
            transform="translate(-170.246 -42.012)"
            fill={color}
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="0.5"
        />
    );
};
export default Simiyu;
