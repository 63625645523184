import {ProjectFilesContext} from "../context";
import {useContext} from "react";
import useCommitmentAnalytics from "./useCommitmentAnalytics"
import useDownloadFileFromS3 from "./useDownloadFileFromS3"
import useEngagementAnalytics from './useEngagementAnalytics'
import useProjectImages from './useProjectImages'
import useUsers from "./useUsers";

export const useProjectFiles = () => {
    return useContext(ProjectFilesContext);
}

export {
    useCommitmentAnalytics,
    useDownloadFileFromS3,
    useUsers, useEngagementAnalytics,
    useProjectImages
}