import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import EmptyState from "./empty-state";
import Loading from "./loading";

const ActivitiesStatusByChartBar = ({
    stakeholderNames,
    statusCounts,
    isLoading = false,
    isFetched,
}) => {
    if (isLoading) {
        return <Loading />;
    }

    if (isFetched && stakeholderNames.length < 1) {
        return <EmptyState />;
    }

    const defaultData = [10, 49, 45, 23, 56, 45, 34];
    const options = {
        chart: {
            type: "column",
            scrollablePlotArea: {
                minWidth: 1100,
            },
        },
        title: {
            enabled: false,
            text: "",
        },
        xAxis: {
            min: 0,
            categories: stakeholderNames ?? [],
        },
        yAxis: {
            min: 0,
            gridLineColor: "#EBF0F3",
            gridLineWidth: 0.6,
            scrollbar: {
                enabled: true,
                showFull: false,
            },
            title: {
                enabled: false,
                text: "Stakeholder Activities by Status",
            },
            stackLabels: {
                enabled: true,
                style: {
                    fontWeight: "normal",
                    color:
                        // theme
                        (Highcharts.defaultOptions.title.style &&
                            Highcharts.defaultOptions.title.style.color) ||
                        "gray",
                    textOutline: "none",
                },
            },
        },

        legend: {
            enabled: false,
            align: "right",
            x: -10,
            verticalAlign: "top",
            y: 10,
            floating: true,
            backgroundColor:
                Highcharts.defaultOptions.legend.backgroundColor || "white",
            borderColor: "#CCC",
            borderWidth: 0.5,
            shadow: false,
        },

        plotOptions: {
            column: {
                stacking: "normal",
                pointWidth: 45,
                dataLabels: {
                    enabled: false,
                },
            },
        },

        tooltip: {
            headerFormat: "<b>{point.x}</b><br/>",
            pointFormat:
                "{series.name}: {point.y}<br/>Total: {point.stackTotal}",
        },

        series: [
            {
                name: "Upcoming",
                data: statusCounts?.upcoming ?? defaultData,
                color: "#168ADC",
            },
            {
                name: "Completed on Time",
                data: statusCounts?.completedOnTime ?? defaultData,
                color: "#A5DED4",
            },
            {
                name: "Completed Late",
                data: statusCounts?.completedLate ?? defaultData,
                color: "#FDD1A9",
            },
            {
                name: "Overdue",
                data: statusCounts?.overdue ?? defaultData,
                color: "#FDC2C0",
            },
        ],
    };

    return (
        <div className={"h-full highcharts relative "}>
            <HighchartsReact highcharts={Highcharts} options={options} />
        </div>
    );
};
export default ActivitiesStatusByChartBar;
