import { CreateMarkup, isEmptyObject, slugify } from "utils";
import { Link, useOutletContext } from "react-router-dom";
import EmptyState from "./empty-state";
import Loading from "./loading";
import { baseRotes } from "../../../../../routes/constants";
import { InfoIcon } from "../../../../../svg";

const RelationshipHolder = () => {
    const { isLoading, getCurrentStakeholder } = useOutletContext();

    if (isLoading) {
        return <Loading />;
    }
    const relationshipHolder = getCurrentStakeholder().relationshipHolder;

    if (isEmptyObject(relationshipHolder)) {
        return <EmptyState />;
    }

    const otherDetails = [
        { name: "Position", value: relationshipHolder.position },
        { name: "Email", value: relationshipHolder.email },
        { name: "Phone", value: relationshipHolder.phoneNumber },
        { name: "Gender", value: relationshipHolder.gender },
    ];

    return (
        <div className={"flex pt-5 justify-between"}>
            <div className={"space-y-5"}>
                <div className={"space-y-1"}>
                    <div className={"flex items-center space-x-2"}>
                        <p className="text-dark font-medium text-xl tracking-wide">
                            {relationshipHolder.name}
                        </p>

                        <Link
                            to={`${baseRotes.STAKEHOLDER_BASE_ROUTE}/${slugify(
                                relationshipHolder.name
                            )}/overview`}
                            target={"_blank"}
                            className={"text-accent"}
                        >
                            <InfoIcon className={"h-5 w-5"} />
                        </Link>
                    </div>
                    <div className="flex divide-x-2 divide-secondary/60 ">
                        {otherDetails.map((details, index) => (
                            <div
                                className={`flex items-center text-sm last:pl-3  ${
                                    index > 0 && index < otherDetails.length
                                        ? "px-3"
                                        : ""
                                } first:pr-3 space-x-2`}
                            >
                                <p className="text-secondary">
                                    {details.name}:
                                </p>
                                <p className="text-secondary/80 font-light">
                                    {" "}
                                    {details.value}
                                </p>
                            </div>
                        ))}
                    </div>
                </div>

                <div
                    className={
                        "text-sm text-secondary leading-relaxed font-light tracking-wide text-justify"
                    }
                >
                    <p className={"text-lg font-medium"}>Description</p>
                    <p
                        dangerouslySetInnerHTML={CreateMarkup(
                            relationshipHolder.description
                        )}
                    ></p>
                </div>
            </div>
        </div>
    );
};
export default RelationshipHolder;
