import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import CommitmentItem from "../commitment-item";
import { Link } from "react-router-dom";
import { saveValueToLocalStorage } from "hooks/useLocalStorageState";
import { localStorageKeys } from "../../../../constants";

const Commitment = ({ stakeholder, setShowDetails, setActiveCommitment }) => {
    const getColor = (stakeholder) => {
        switch (stakeholder?.type) {
            case "Individual": {
                return "bg-[#51BF82]";
            }
            case "Organizational": {
                return "bg-[#E8529D]";
            }
            case "Positional": {
                return "bg-[#7B75F3]";
            }
            default:
                return "bg-gray-500";
        }
    };

    const handleClick = () => {
        saveValueToLocalStorage(localStorageKeys.commitmentForm.BASIC_DETAILS, {
            stakeholder: stakeholder.stakeholder,
        });
        setActiveCommitment(stakeholder);
    };

    return (
        <div className="grid w-full grid-cols-5 px-10 py-4 space-x-6 bg-white">
            <div>
                <div className="text-[#009EE2] py-3 flex flex-row -ml-6 space-x-4 place-items-center">
                    <div
                        className={`${getColor(
                            stakeholder.stakeholder
                        )} h-2 w-2 rounded-[2px]`}
                    ></div>
                    <p>{stakeholder.stakeholder?.name || "N/A"} </p>
                </div>
            </div>

            <div className="col-span-4">
                <div className="grid grid-cols-1 divide-y divide-[#E4E4E4]">
                    {stakeholder.commitments.map((commitment) => (
                        <CommitmentItem
                            setActiveCommitment={setActiveCommitment}
                            setShowDetails={setShowDetails}
                            key={commitment._id}
                            commitment={commitment}
                        />
                    ))}

                    <div>
                        <Link
                            to={"create"}
                            onClick={handleClick}
                            className="bg-white w-fit py-3 text-[#009EE2] flex flex-row space-x-2 place-items-center"
                        >
                            <span className="text-sm italic">
                                Add Commitment{" "}
                            </span>
                            <div className="h-4 w-4 rounded-full text-white bg-gradient-to-r from-[#A0C7F1] to-[#267CE1] grid place-items-center">
                                <FontAwesomeIcon
                                    className="w-3 h-3"
                                    icon={faPlus}
                                    color={"FFFFFF"}
                                />
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Commitment;
