import Logo from "components/logo";
import {
    CommitmentMenu,
    EngagementMenu,
    GrievanceMenu,
    OverviewMenu,
    StakeholderMenu,
} from "./menus";
import useGlobalContext from "hooks/useGlobalContext";
import { saveValueToLocalStorage } from "hooks/useLocalStorageState";
import { Link } from "react-router-dom";
import { Can, Cannot, permissions, Switch } from "provider/permission";
import {
    BellIcon,
    CogIcon,
    CreditCardIcon,
    FolderIcon,
    PhotographIcon,
} from "@heroicons/react/solid";
import { useAuth } from "provider/auth/auth";

const Sidebar = () => {
    const { authUser } = useAuth();
    const { piu: currentPIU } = authUser;
    const { contentsView, setContentsView } = useGlobalContext();
    const isGeneralView = contentsView === "general";
    // const navigate = useNavigate();

    const enableGeneralContentsView = () => {
        setContentsView("general");
        saveValueToLocalStorage("contentsView", "general");
    };

    const enableAnalyticsContentsView = () => {
        setContentsView("analytics");
        saveValueToLocalStorage("contentsView", "analytics");
    };

    return (
        <aside
            className={
                "w-[225px] border-r-[1px] border-[#E4E6EA] h-full flex flex-col justify-between py-4"
            }
        >
            <div className={"space-y-6"}>
                {/* Logo && Tabs */}
                <div className={"px-5 space-y-6 py-2"}>
                    <Switch>
                        <Can permission={permissions.MANAGE_PROJECTS_AND_PIUS}>
                            <div>
                                <img
                                    src="/images/world-bank-logo.svg"
                                    alt="world bank logo"
                                />
                            </div>
                        </Can>

                        <Can>
                            <div
                                className={
                                    "flex items-center text-[#2D3943] text-lg flex justify-between space-x-2"
                                }
                            >
                                <Logo className={"h-6"} />
                                <p className={"font-medium uppercase"}>
                                    {currentPIU?.abbreviation}
                                </p>
                            </div>
                        </Can>
                    </Switch>

                    <Can permission={permissions.READ_ANALYTICS}>
                        <div
                            className={"bg-[#F0F0F0] p-1 flex rounded text-xs"}
                        >
                            <Link
                                to={"/"}
                                onClick={enableGeneralContentsView}
                                className={`${
                                    isGeneralView
                                        ? "text-[#628799] bg-white "
                                        : "bg-transparent text-[#628799]/40"
                                } 
                             cursor-pointer w-full py-2 text-center `}
                            >
                                GENERAL
                            </Link>
                            <Link
                                to={"/analytics"}
                                onClick={enableAnalyticsContentsView}
                                className={`${
                                    !isGeneralView
                                        ? "text-[#628799] bg-white"
                                        : "bg-transparent text-[#628799]/40"
                                } 
                             cursor-pointer w-full py-2 font-light  text-center`}
                            >
                                ANALYTICS
                            </Link>
                        </div>
                    </Can>
                </div>

                {/* Menus */}
                <div className={"pl-5 space-y-2"}>
                    <p
                        className={
                            "text-[9.5px] font-medium tracking-wider text-[#9597A6]"
                        }
                    >
                        MENU
                    </p>
                    <div className={"space-y-2 text-[15px]"}>
                        {/* Stakeholder Menu */}
                        <Can permission={permissions.READ_STAKEHOLDERS}>
                            <StakeholderMenu />
                        </Can>

                        <Can permission={permissions.MANAGE_PROJECTS_AND_PIUS}>
                            <OverviewMenu
                                url={"/settings/users"}
                                pathname={"Settings"}
                                pattern={new RegExp("^/setting/*")}
                                icon={CogIcon}
                            />
                        </Can>

                        {/* Engagement Menu */}
                        <Can
                            permission={[
                                permissions.READ_ENGAGEMENT_PLANS,
                                permissions.READ_PROJECT_EVENTS,
                            ]}
                        >
                            <EngagementMenu />
                        </Can>

                        {/* Commitments Menu */}
                        <Can permission={permissions.READ_COMMITMENTS}>
                            <CommitmentMenu />
                        </Can>

                        {/* Grievances Menu */}
                        <Can permission={permissions.READ_GRIEVANCES}>
                            <GrievanceMenu />
                        </Can>
                    </div>
                </div>

                {/* Overview Menu */}
                <Can
                    permission={[
                        permissions.READ_PROJECT_FILES,
                        permissions.READ_PROJECT_IMAGES,
                        permissions.READ_SETTINGS,
                        permissions.READ_NOTIFICATIONS,
                        permissions.READ_PROJECT_EVENTS,
                        permissions.READ_PROJECT,
                        permissions.READ_PROJECT_PHASE,
                        permissions.GRIEVANCE_INVESTIGATOR,
                    ]}
                >
                    <div className={"pl-5 space-y-1 pt-5"}>
                        <p
                            className={
                                "text-[9.5px] tracking-wider font-medium text-[#9597A6]"
                            }
                        >
                            OVERVIEW
                        </p>
                        <div className={"text-[15px] "}>
                            <Can permission={permissions.READ_PROJECT_FILES}>
                                <OverviewMenu
                                    url={"/project-files"}
                                    pathname={"Project Files"}
                                    icon={FolderIcon}
                                />
                            </Can>
                            <Can
                                permission={[
                                    permissions.READ_PROJECT_FILES,
                                    permissions.READ_PROJECT_IMAGES,
                                ]}
                            >
                                <OverviewMenu
                                    url={"/project-images"}
                                    pathname={"Project Images"}
                                    icon={PhotographIcon}
                                />
                            </Can>

                            <Can permission={permissions.READ_SETTINGS}>
                                <OverviewMenu
                                    url={"/settings"}
                                    pathname={"Settings"}
                                    icon={CogIcon}
                                />
                            </Can>

                            <Can
                                permission={[
                                    permissions.READ_PROJECT_EVENTS,
                                    permissions.READ_PROJECT,
                                    permissions.READ_PROJECT_PHASE,
                                ]}
                            >
                                <OverviewMenu
                                    url={"/meeting-budget"}
                                    pathname={"Event Budget"}
                                    icon={CreditCardIcon}
                                />
                            </Can>

                            <Can
                                permission={permissions.GRIEVANCE_INVESTIGATOR}
                            >
                                <OverviewMenu
                                    url={
                                        "/grievances/under-investigation/pending"
                                    }
                                    pathname={"Investigation"}
                                    icon={CreditCardIcon}
                                />
                            </Can>
                            <Can permission={permissions.READ_NOTIFICATIONS}>
                                <OverviewMenu
                                    url={"/settings/notifications"}
                                    pathname={"Notifications"}
                                    icon={BellIcon}
                                />
                            </Can>
                        </div>
                    </div>
                </Can>
            </div>

            {/* Project Progress */}
            <Cannot permission={permissions.MANAGE_PROJECTS_AND_PIUS}>
                <div className={"px-4"}>
                    <div className={"space-y-2"}>
                        <p className={"text-sm font-medium text-secondary "}>
                            Project Progress
                        </p>
                        <p className={"text-[10px] text-gray"}>
                            <span className={"text-[#51BF82]"}>68% </span>
                            TO COMPLETION
                        </p>

                        {/* Progress Bar */}
                        <div
                            className={
                                "w-full h-[7px] bg-[#E4E6EA] rounded-full overflow-hidden"
                            }
                        >
                            <div
                                className={"w-[68%] h-full bg-secondary"}
                            ></div>
                        </div>
                        <div>
                            <div
                                className={
                                    "text-[11px] font-light flex justify-between items-center"
                                }
                            >
                                <p className={"text-[#628799]"}>JAN 2019</p>
                                <p className={"text-[#C1BAB2]"}>DEC 2022</p>
                            </div>
                            <div
                                className={
                                    "text-[9px] italic font-light flex justify-between items-center"
                                }
                            >
                                <p className={"text-[#C1BAB2]"}>
                                    Project Started
                                </p>
                                <p className={"text-[#C1BAB2]"}>
                                    Project Will End
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </Cannot>
        </aside>
    );
};
export default Sidebar;
