import TanzaniaSvgMap from "../../../../../maps/tanzania-svg-map";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsis } from "@fortawesome/free-solid-svg-icons";
import MapLegend from "./map-legend";

const StakeholderBreakDown = () => {
    return (
        <div className={"border-[1px] border-[#E3E3E3] "}>
            <div
                className={
                    "flex justify-between items-center px-8 py-3 border-b-[1px] border-b-[#E3E3E3]"
                }
            >
                <p className={"text-[#31394C] text-sm tracking-wide"}>
                    Tanzania Map Of Stakeholder Breakdown
                </p>
                <FontAwesomeIcon
                    icon={faEllipsis}
                    className={"text-[#31394C] opacity-30 h-5 w-5"}
                />
            </div>
            <div className={"flex items-center py-5 space-x-16"}>
                <TanzaniaSvgMap isLoading={false} />
                <MapLegend />
            </div>
        </div>
    );
};
export default StakeholderBreakDown;
