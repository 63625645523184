import { useEngagementPlans } from "../plans";
import Loading from "./loading";
import Button from "components/Button";
import Tab from "components/tab";

const PhaseList = ({ onCreate: openPlanForm }) => {
    const { phases, isLoadingPhases, setActivePhase } = useEngagementPlans();

    return (
        <header
            className={
                "bg-white border-[1px] space-y-5 border-[#EEEEEE] pt-8 px-8 rounded"
            }
        >
            <div className={"items-between pt-3"}>
                <p className={"text-xl tracking-wider text-[#37474F]"}>
                    Project Engagement Plan
                </p>
                <Button onClick={openPlanForm}>Create Plan</Button>
            </div>
            <div className={"flex space-x-10"}>
                {isLoadingPhases ? (
                    <Loading />
                ) : (
                    phases.map((phase) => (
                        <Tab
                            onClick={() => setActivePhase(phase)}
                            key={phase._id}
                            urlPattern={
                                new RegExp(
                                    `\\/${phase?.name?.toLowerCase()}\\/?(.*)?$`
                                )
                            }
                            title={phase.name}
                            url={`/engagement/plans/${phase?.name?.toLowerCase()}`}
                        />
                    ))
                )}
            </div>
        </header>
    );
};
export default PhaseList;
