const EqualizerIcon = () => {
    return (
        <svg
            className={'h-4 w-4 group-hover:rotate-90 transition-all ease-out group-hover:scale-110'}
            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <g id="vuesax_bold_setting-4" data-name="vuesax/bold/setting-4"
               transform="translate(-428 -190)">
                <g id="setting-4">
                    <path id="Vector"
                          d="M6.28,1.4H.7A.7.7,0,0,1,0,.7.7.7,0,0,1,.7,0H6.28a.7.7,0,0,1,.7.7A.7.7,0,0,1,6.28,1.4Z"
                          transform="translate(443.02 196.18)" fill="#fff"/>
                    <path id="Vector-2" data-name="Vector"
                          d="M4.42,1.4H.7A.7.7,0,0,1,0,.7.7.7,0,0,1,.7,0H4.42a.7.7,0,0,1,0,1.4Z"
                          transform="translate(430 196.18)" fill="#fff"/>
                    <path id="Vector-3" data-name="Vector"
                          d="M7.9,3.95A3.95,3.95,0,1,1,3.95,0,3.95,3.95,0,0,1,7.9,3.95Z"
                          transform="translate(434.19 192.93)" fill="#fff"/>
                    <path id="Vector-4" data-name="Vector"
                          d="M4.42,1.4H.7A.7.7,0,0,1,0,.7.7.7,0,0,1,.7,0H4.42a.7.7,0,0,1,.7.7A.7.7,0,0,1,4.42,1.4Z"
                          transform="translate(444.88 206.41)" fill="#fff"/>
                    <path id="Vector-5" data-name="Vector"
                          d="M6.28,1.4H.7A.7.7,0,0,1,0,.7.7.7,0,0,1,.7,0H6.28a.7.7,0,1,1,0,1.4Z"
                          transform="translate(430 206.41)" fill="#fff"/>
                    <path id="Vector-6" data-name="Vector"
                          d="M7.9,3.95A3.95,3.95,0,1,1,3.95,0,3.95,3.95,0,0,1,7.9,3.95Z"
                          transform="translate(437.91 203.17)" fill="#fff"/>
                    <path id="Vector-7" data-name="Vector" d="M0,0H24V24H0Z"
                          transform="translate(452 214) rotate(180)" fill="none"
                          opacity="0"/>
                </g>
            </g>
        </svg>
    )
}
export default EqualizerIcon;