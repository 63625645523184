import GraphSvg from "./graph-svg";

const EmptyState = () => {
    return (
        <div className={"h-full center -space-y-6 flex-col"}>
            <div>
                <div className={"h-60 w-60"}>
                    <GraphSvg />
                </div>
            </div>

            <p className={"text-center text-gray-400"}>
                Start tracking your stakeholder activities: <br />
                Begin logging your stakeholder activities to see them visualized
                in this chart.
            </p>
        </div>
    );
};
export default EmptyState;
