import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCalendarDay,
    faHandshakeSimple,
} from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";

import useClickOutside from "../../hooks/useClickOutside";
import clearForm from "../../pages/general/engagement/activity/events/form/meeting/steps/_partials/clear-form";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons/faAngleDown";

const EventButton = () => {
    const [isOpen, setIsOpen] = useState(false);
    const actionRef = useClickOutside(() => {
        setIsOpen(false);
    });

    const handleClick = async () => {
        await clearForm();
    };
    return (
        <div
            ref={actionRef}
            className={"relative text-secondary cursor-pointer"}
        >
            <div
                onClick={() => setIsOpen(!isOpen)}
                className={
                    "text-xs text-accent px-4 py-2 relative border-accent border-[2px] space-x-2"
                }
            >
                <span>CREATE EVENT</span>
                <FontAwesomeIcon icon={faAngleDown} />
            </div>

            <div
                className={`${
                    isOpen
                        ? "opacity-100 pointer-events-auto"
                        : "opacity-0 pointer-events-none"
                } border shadow-base p-1 flex flex-col rounded-b bg-white w-max absolute`}
            >
                <Link
                    to={"/event-registration/meeting"}
                    onClick={handleClick}
                    className={
                        "text-left w-full flex items-center rounded space-x-2 hover:bg-gray-100 p-2"
                    }
                >
                    <FontAwesomeIcon
                        icon={faHandshakeSimple}
                        className={"h-4 w-4"}
                    />
                    <p>Meeting</p>
                </Link>

                <Link
                    to={"/event-registration"}
                    onClick={handleClick}
                    className={
                        "text-left w-full flex items-center rounded space-x-2 hover:bg-gray-100 p-2"
                    }
                >
                    <FontAwesomeIcon
                        icon={faCalendarDay}
                        className={"h-4 w-4"}
                    />
                    <span>Normal Event</span>
                </Link>
            </div>
        </div>
    );
};
export default EventButton;
