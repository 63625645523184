const DodomaSvg = ({ color, isLoading = false }) => {
    return (
        <path
            className={`${isLoading ? "animate-pulse" : ""}`}
            id="Path_448"
            data-name="Path 448"
            d="M472.368,261.049l-1.168,1.9-.056,2.251,1.063,9.269-2.117,3.2-1.612.8-.962,3.852-.82,1.967-.234.832-.448.708-.55-.083-.557-.157-.113.629.444.629.02.981-.6.861-.921,2.926.2,7.079-.194,1.762-3.208,1.993-1.669,3.508-.493,1.78.246,1.935.484,1.9-.295,1.843-1.442,1.5-.21-.952-.246-.564-.666-.436-.938-.19-1.81.024-1.051.34-.521.065-.489-.255-.141-.3-.109-.776-.15-.31-.218-.3-.448-.915-.295-.287-.6-.368-.2-.177-.36-.807-.271-.89-.46-.72-.913-.3-.756-.124-3.536-1.413-.525-.053-.857.047-.445-.037-.311-.157-.566-.153-2.263.546-.816-.151-1.548-.538-.679-.12-.582-.259-.7-.454-.4-.1-3.438-.849-1.257.092-1.382-.112-2.793-1.543-1.879-.688-1.883-.209-.561-.33.008-.622-.489-.792-.876-.476-1.834-.629-1.875-.165-1.309.216-1.326,0-1.115.285-.8.847-3.939.615-3.794-2.3L402.566,293l-.634-3.667,4.926-.228,1.083-2.626-.691-6.74.4-3.536.651-1.254.016-1.417-.368-1.551-.792-1.39-.323-3.416.848-3.537.146-4.561,1.39-3.27,1.006-1.476.379-1.5.194-1.586,1.216-1.062.8-.991-.437-1.137-1.3-1.409-.99-1.63-.089-.759-.17-.636-.372-.087-.279-.244-.416-.506-.582-.372-.7-1.145-.481-1.238-1.067-1.141-.9-1.307-.622-2.666-.707-1.538-.5-1.593.036-1.464-.121-1.459-.8-1.315.206-1.311,9.536-6.547.351-1.018.647-1.306-.158-1.383.525.061.477.231,3.58.331,2.041-.345.861-1.395,2.687-5.468,1.984-3.142.372-1.5-.691-1.386-.218-.626-.073-.662.182-.687.351-.207.861.332,3.075-2.114,1.685-1.613.416-.669.63-.446.679.281.659.371.945-.47.957-.656,1.94.045,1.2-1.991.441-.322.962-.568,1.163-.887,9.778-2.765.274,11.63.379,2.2.63,2.144.9,2,.214,1.283-.457,7.976-1.443,9.439-.836,2.755-1.24,1.933-.853,2.1.424,2.476-.671,2.208.521,1.8,1.5,1.579,2.028,1.632,1.827,1.819,1.119,1.963,1.374,1.788,2.38,1.462,2.513,1.192,1.568,1.91,1.127,2.287,1.418,2.212,1.947,1.725,2.622,1.026Z"
            transform="translate(-188.205 -64.506)"
            fill={color}
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="0.5"
        />
    );
};
export default DodomaSvg;
