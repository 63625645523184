const StakeholdersIllustrator = ({ className }) => {
    return (
        <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            viewBox="0 0 883.79154 477.54833"
        >
            <path
                d="M285.64567,625.40937h624V587.79265c0-7.85613-9.46752-15.58911-25.97454-21.21594l-.16963-.05828c-11.145-3.93309-82.39137-31.91514-120.92863-31.0688-20.35714.44572-37.78577,3.89633-51.80066,10.25608-43.62457,19.80352-100.92971,27.11875-131.20606,21.37156C552.884,562.01132,479.6348,553.63356,452.57,554.012c-37.90461.52134-38.01578,6.854-76.92434,27.2916-14.86056,7.79959-20.41864,8.778-90,15Z"
                transform="translate(-158.10423 -211.22583)"
                fill="#f2f2f2"
            />
            <path
                d="M185.04707,570.11882c-1.35878-20.181,7.20869-40.568,24.96122-48.05895-3.58868,20.79264.83292,43.898,12.23394,63.92874,4.44052,7.80175,10.14325,16.13142,8.9296,24.28318-.75507,5.07224-4.20567,9.09878-8.42213,11.721-4.21675,2.62222-9.18535,4.0222-14.09339,5.39614l-.84736.89023C196.14431,610.21308,186.40586,590.29981,185.04707,570.11882Z"
                transform="translate(-158.10423 -211.22583)"
                fill="#f2f2f2"
            />
            <path
                d="M210.26938,522.42952a70.94122,70.94122,0,0,0-11.08689,45.7894,36.56563,36.56563,0,0,0,2.44015,10.71166,23.82934,23.82934,0,0,0,6.601,8.87084c2.65224,2.30994,5.5949,4.40345,7.71876,7.2385a12.907,12.907,0,0,1,2.4638,9.79615c-.61913,4.0628-2.72221,7.45546-4.90654,10.68554-2.42529,3.58638-5.00779,7.26511-5.44777,11.87883-.05331.559-.9469.32156-.89367-.23662.76548-8.02708,7.53528-12.90818,9.81063-20.21857a12.40245,12.40245,0,0,0-1.22715-10.73656c-1.97895-2.99857-5.01193-5.15975-7.71473-7.47826a25.57423,25.57423,0,0,1-6.75025-8.47892,34.30581,34.30581,0,0,1-2.83815-10.63634,73.62719,73.62719,0,0,1,1.11352-23.749,68.83236,68.83236,0,0,1,10.004-24.07494c.29676-.44566,1.00812.19556.71329.63832Z"
                transform="translate(-158.10423 -211.22583)"
                fill="#fff"
            />
            <path
                d="M198.38953,562.81652a14.31271,14.31271,0,0,1-11.42228-12.04992c-.0642-.57358.82821-.57477.8925-.00043a13.2503,13.2503,0,0,0,10.64542,11.19047.43386.43386,0,1,1-.11564.85988Z"
                transform="translate(-158.10423 -211.22583)"
                fill="#fff"
            />
            <path
                d="M206.74638,586.78549a19.24593,19.24593,0,0,0,7.67434-13.773c.05535-.55853.949-.32123.89367.23662a20.01143,20.01143,0,0,1-8.01786,14.3117c-.43462.31788-.98242-.45919-.55015-.77535Z"
                transform="translate(-158.10423 -211.22583)"
                fill="#fff"
            />
            <path
                d="M202.36359,537.93858a6.50713,6.50713,0,0,0,6.301-.64814c.42952-.3238.97689.45365.55016.77535a7.28517,7.28517,0,0,1-6.96676.73268.54077.54077,0,0,1-.389-.54826.38761.38761,0,0,1,.50465-.31163Z"
                transform="translate(-158.10423 -211.22583)"
                fill="#fff"
            />
            <path
                d="M274.77852,554.94109c-.26126.21062-.52252.42124-.7827.6394a72.27932,72.27932,0,0,0-9.60668,9.45952c-.22895.26212-.45646.53177-.67632.801a78.35742,78.35742,0,0,0-14.9093,28.925,82.35947,82.35947,0,0,0-2.48834,13.16673c-.66069,6.20459-.86591,13.054-3.53171,18.237a12.89826,12.89826,0,0,1-.92231,1.56491l-32.7328,1.66149c-.08158-.0338-.16209-.06011-.244-.09389l-1.29542.12649c.00794-.23571.02135-.47929.02929-.715.004-.13683.01532-.274.01934-.41086.005-.09133.01033-.18273.00947-.26617.00154-.03042.00345-.06087.00642-.08381-.00086-.08344.00736-.15979.00793-.23571.07115-1.36228.14958-2.725.24333-4.08843-.00144-.00752-.00144-.00752.00441-.01542a121.35083,121.35083,0,0,1,5.53908-30.17243c.0963-.28573.1908-.579.302-.86546a73.3087,73.3087,0,0,1,5.49308-12.24142,63.04431,63.04431,0,0,1,3.96558-6.11425,52.03366,52.03366,0,0,1,13.59972-12.78952,47.43186,47.43186,0,0,1,36.95664-6.74135C274.10484,554.77037,274.43749,554.85212,274.77852,554.94109Z"
                transform="translate(-158.10423 -211.22583)"
                fill="#f2f2f2"
            />
            <path
                d="M274.78493,555.363a66.16821,66.16821,0,0,0-37.92163,26.008,28.7375,28.7375,0,0,0-4.42534,9.11318,19.397,19.397,0,0,0,.38035,10.30317c.92338,3.24456,2.23623,6.50941,2.37036,9.81079a10.24681,10.24681,0,0,1-3.842,8.474c-3.0427,2.527-6.98434,3.68283-10.89786,4.67369-4.34522,1.10017-8.886,2.17163-12.10895,5.19966-.3905.36689-1.03384-.34031-.64393-.70664,5.60733-5.26824,14.585-4.67814,21.08556-8.52632,3.03328-1.79563,5.40411-4.59194,5.512-8.401.0943-3.33089-1.25674-6.69839-2.21951-9.97949a21.02015,21.02015,0,0,1-.753-10.11328,26.82976,26.82976,0,0,1,4.02756-9.29892,62.75,62.75,0,0,1,15.57317-16.27943,69.29347,69.29347,0,0,1,23.62542-11.16222c.53588-.13949.77022.7462.23784.88478Z"
                transform="translate(-158.10423 -211.22583)"
                fill="#fff"
            />
            <path
                d="M239.48181,577.03824c-4.54862-4.30133-5.68673-10.92577-2.6855-15.47325.29567-.448,1.08457.08816.78851.53675-2.81163,4.26019-1.7246,10.37158,2.52737,14.39239.44747.42314-.18547.96483-.63038.54411Z"
                transform="translate(-158.10423 -211.22583)"
                fill="#fff"
            />
            <path
                d="M232.13772,599.1734a20.43413,20.43413,0,0,0,15.23907-5.21165c.392-.36531,1.03547.34177.64393.70664a21.26908,21.26908,0,0,1-15.87342,5.38943c-.57915-.03466-.58558-.91889-.00958-.88442Z"
                transform="translate(-158.10423 -211.22583)"
                fill="#fff"
            />
            <path
                d="M258.27491,561.67443a8.02849,8.02849,0,0,0,5.96316,3.329c.57827.02737.58409.91161.00958.88442a8.9962,8.9962,0,0,1-6.60312-3.66932.45042.45042,0,0,1-.00677-.62538.46781.46781,0,0,1,.63715.08127Z"
                transform="translate(-158.10423 -211.22583)"
                fill="#fff"
            />
            <path
                d="M1004.56527,570.11882c1.35878-20.181-7.20869-40.568-24.96122-48.05895,3.58868,20.79264-.83292,43.898-12.23394,63.92874-4.44052,7.80175-10.14325,16.13142-8.92959,24.28318.75506,5.07224,4.20566,9.09878,8.42212,11.721,4.21675,2.62222,9.18535,4.0222,14.09339,5.39614l.84736.89023C993.468,610.21308,1003.20648,590.29981,1004.56527,570.11882Z"
                transform="translate(-158.10423 -211.22583)"
                fill="#f2f2f2"
            />
            <path
                d="M979.343,522.42952a70.94122,70.94122,0,0,1,11.08689,45.7894,36.56563,36.56563,0,0,1-2.44015,10.71166,23.82934,23.82934,0,0,1-6.601,8.87084c-2.65224,2.30994-5.5949,4.40345-7.71875,7.2385a12.907,12.907,0,0,0-2.46381,9.79615c.61913,4.0628,2.72221,7.45546,4.90654,10.68554,2.42529,3.58638,5.00779,7.26511,5.44777,11.87883.05331.559.9469.32156.89367-.23662-.76548-8.02708-7.53528-12.90818-9.81063-20.21857a12.40245,12.40245,0,0,1,1.22715-10.73656c1.979-2.99857,5.01193-5.15975,7.71473-7.47826a25.5741,25.5741,0,0,0,6.75025-8.47892,34.30581,34.30581,0,0,0,2.83815-10.63634,73.62719,73.62719,0,0,0-1.11352-23.749,68.83236,68.83236,0,0,0-10.004-24.07494c-.29676-.44566-1.00812.19556-.71329.63832Z"
                transform="translate(-158.10423 -211.22583)"
                fill="#fff"
            />
            <path
                d="M991.22281,562.81652a14.31271,14.31271,0,0,0,11.42228-12.04992c.0642-.57358-.82821-.57477-.8925-.00043a13.2503,13.2503,0,0,1-10.64542,11.19047.43386.43386,0,1,0,.11564.85988Z"
                transform="translate(-158.10423 -211.22583)"
                fill="#fff"
            />
            <path
                d="M982.866,586.78549a19.24593,19.24593,0,0,1-7.67434-13.773c-.05535-.55853-.949-.32123-.89367.23662a20.01143,20.01143,0,0,0,8.01786,14.3117c.43462.31788.98242-.45919.55015-.77535Z"
                transform="translate(-158.10423 -211.22583)"
                fill="#fff"
            />
            <path
                d="M987.24875,537.93858a6.50713,6.50713,0,0,1-6.301-.64814c-.42952-.3238-.97689.45365-.55016.77535a7.28517,7.28517,0,0,0,6.96676.73268.54077.54077,0,0,0,.389-.54826.38761.38761,0,0,0-.50465-.31163Z"
                transform="translate(-158.10423 -211.22583)"
                fill="#fff"
            />
            <path
                d="M914.83382,554.94109c.26126.21062.52252.42124.7827.6394A72.27932,72.27932,0,0,1,925.2232,565.04c.22895.26212.45646.53177.67632.801a78.35742,78.35742,0,0,1,14.9093,28.925,82.35947,82.35947,0,0,1,2.48834,13.16673c.66069,6.20459.86591,13.054,3.53171,18.237a12.89826,12.89826,0,0,0,.92231,1.56491l32.7328,1.66149c.08158-.0338.16209-.06011.244-.09389l1.29542.12649c-.00793-.23571-.02135-.47929-.02928-.715-.004-.13683-.01533-.274-.01935-.41086-.005-.09133-.01033-.18273-.00947-.26617-.00154-.03042-.00345-.06087-.00642-.08381.00086-.08344-.00736-.15979-.00793-.23571-.07115-1.36228-.14957-2.725-.24333-4.08843.00144-.00752.00144-.00752-.00441-.01542a121.35083,121.35083,0,0,0-5.53908-30.17243c-.0963-.28573-.19079-.579-.30205-.86546a73.3087,73.3087,0,0,0-5.49308-12.24142,63.04431,63.04431,0,0,0-3.96558-6.11425,52.03362,52.03362,0,0,0-13.59971-12.78952,47.43189,47.43189,0,0,0-36.95665-6.74135C915.5075,554.77037,915.17485,554.85212,914.83382,554.94109Z"
                transform="translate(-158.10423 -211.22583)"
                fill="#f2f2f2"
            />
            <path
                d="M914.82741,555.363a66.16821,66.16821,0,0,1,37.92163,26.008,28.73728,28.73728,0,0,1,4.42534,9.11318,19.397,19.397,0,0,1-.38035,10.30317c-.92338,3.24456-2.23623,6.50941-2.37036,9.81079a10.24681,10.24681,0,0,0,3.842,8.474c3.0427,2.527,6.98434,3.68283,10.89786,4.67369,4.34522,1.10017,8.886,2.17163,12.10895,5.19966.3905.36689,1.03384-.34031.64393-.70664-5.60733-5.26824-14.585-4.67814-21.08556-8.52632-3.03328-1.79563-5.40411-4.59194-5.512-8.401-.0943-3.33089,1.25674-6.69839,2.21951-9.97949a21.02,21.02,0,0,0,.753-10.11328,26.82976,26.82976,0,0,0-4.02756-9.29892,62.75,62.75,0,0,0-15.57317-16.27943,69.29347,69.29347,0,0,0-23.62542-11.16222c-.53587-.13949-.77022.7462-.23784.88478Z"
                transform="translate(-158.10423 -211.22583)"
                fill="#fff"
            />
            <path
                d="M950.13053,577.03824c4.54862-4.30133,5.68673-10.92577,2.6855-15.47325-.29566-.448-1.08457.08816-.78851.53675,2.81163,4.26019,1.7246,10.37158-2.52737,14.39239-.44747.42314.18547.96483.63038.54411Z"
                transform="translate(-158.10423 -211.22583)"
                fill="#fff"
            />
            <path
                d="M957.47462,599.1734a20.43413,20.43413,0,0,1-15.23907-5.21165c-.392-.3653-1.03547.34177-.64393.70664a21.26908,21.26908,0,0,0,15.87342,5.38943c.57915-.03466.58558-.91889.00958-.88442Z"
                transform="translate(-158.10423 -211.22583)"
                fill="#fff"
            />
            <path
                d="M931.33743,561.67443a8.02848,8.02848,0,0,1-5.96316,3.329c-.57827.02737-.58409.91161-.00958.88442a8.9962,8.9962,0,0,0,6.60312-3.66932.45042.45042,0,0,0,.00677-.62538.46781.46781,0,0,0-.63715.08127Z"
                transform="translate(-158.10423 -211.22583)"
                fill="#fff"
            />
            <rect
                x="49.96319"
                y="258.98303"
                width="6"
                height="2"
                fill="#e6e6e6"
            />
            <path
                d="M969.2017,472.20887H957.0225v-2h12.1792Zm-24.35889,0H932.66312v-2h12.17969Zm-24.35938,0H908.30424v-2h12.17919Zm-24.35888,0H883.94486v-2h12.17969Zm-24.35938,0H859.586v-2h12.1792Zm-24.35888,0H835.2266v-2h12.17969Zm-24.35938,0h-12.1792v-2h12.1792Zm-24.35889,0H786.50834v-2H798.688Zm-24.35937,0h-12.1792v-2h12.1792Zm-24.35889,0H737.79008v-2h12.17968Zm-24.35937,0H713.4307v-2h12.17969Zm-24.35889,0H689.07181v-2H701.2515Zm-24.35937,0H664.71244v-2h12.17969Zm-24.35938,0h-12.1792v-2h12.1792Zm-24.35889,0H615.99418v-2h12.17968Zm-24.35937,0H591.6348v-2h12.17969Zm-24.35889,0H567.27591v-2H579.4556Zm-24.35937,0H542.91654v-2h12.17969Zm-24.35938,0H518.55716v-2h12.17969Zm-24.35888,0H494.19828v-2H506.378Zm-24.35938,0H469.8389v-2h12.17969Zm-24.35937,0H445.48v-2h12.1792Zm-24.35889,0H421.12064v-2h12.17969Zm-24.35938,0H396.76127v-2H408.941Zm-24.35937,0h-12.1792v-2h12.1792Zm-24.35889,0H348.043v-2h12.17969Zm-24.35937,0H323.68363v-2h12.17969Zm-24.35889,0H299.32474v-2h12.17969Zm-24.35937,0H274.96537v-2h12.17969Zm-24.35938,0h-12.1792v-2h12.1792Zm-24.35889,0H226.24711v-2h12.17968Z"
                transform="translate(-158.10423 -211.22583)"
                fill="#e6e6e6"
            />
            <rect
                x="823.27764"
                y="258.98303"
                width="6"
                height="2"
                fill="#e6e6e6"
            />
            <rect
                x="49.96319"
                y="244.98303"
                width="6"
                height="2"
                fill="#e6e6e6"
            />
            <path
                d="M969.2017,458.20887H957.0225v-2h12.1792Zm-24.35889,0H932.66312v-2h12.17969Zm-24.35938,0H908.30424v-2h12.17919Zm-24.35888,0H883.94486v-2h12.17969Zm-24.35938,0H859.586v-2h12.1792Zm-24.35888,0H835.2266v-2h12.17969Zm-24.35938,0h-12.1792v-2h12.1792Zm-24.35889,0H786.50834v-2H798.688Zm-24.35937,0h-12.1792v-2h12.1792Zm-24.35889,0H737.79008v-2h12.17968Zm-24.35937,0H713.4307v-2h12.17969Zm-24.35889,0H689.07181v-2H701.2515Zm-24.35937,0H664.71244v-2h12.17969Zm-24.35938,0h-12.1792v-2h12.1792Zm-24.35889,0H615.99418v-2h12.17968Zm-24.35937,0H591.6348v-2h12.17969Zm-24.35889,0H567.27591v-2H579.4556Zm-24.35937,0H542.91654v-2h12.17969Zm-24.35938,0H518.55716v-2h12.17969Zm-24.35888,0H494.19828v-2H506.378Zm-24.35938,0H469.8389v-2h12.17969Zm-24.35937,0H445.48v-2h12.1792Zm-24.35889,0H421.12064v-2h12.17969Zm-24.35938,0H396.76127v-2H408.941Zm-24.35937,0h-12.1792v-2h12.1792Zm-24.35889,0H348.043v-2h12.17969Zm-24.35937,0H323.68363v-2h12.17969Zm-24.35889,0H299.32474v-2h12.17969Zm-24.35937,0H274.96537v-2h12.17969Zm-24.35938,0h-12.1792v-2h12.1792Zm-24.35889,0H226.24711v-2h12.17968Z"
                transform="translate(-158.10423 -211.22583)"
                fill="#e6e6e6"
            />
            <rect
                x="823.27764"
                y="244.98303"
                width="6"
                height="2"
                fill="#e6e6e6"
            />
            <rect
                x="49.96319"
                y="215.98303"
                width="6"
                height="2"
                fill="#e6e6e6"
            />
            <path
                d="M969.2017,429.20887H957.0225v-2h12.1792Zm-24.35889,0H932.66312v-2h12.17969Zm-24.35938,0H908.30424v-2h12.17919Zm-24.35888,0H883.94486v-2h12.17969Zm-24.35938,0H859.586v-2h12.1792Zm-24.35888,0H835.2266v-2h12.17969Zm-24.35938,0h-12.1792v-2h12.1792Zm-24.35889,0H786.50834v-2H798.688Zm-24.35937,0h-12.1792v-2h12.1792Zm-24.35889,0H737.79008v-2h12.17968Zm-24.35937,0H713.4307v-2h12.17969Zm-24.35889,0H689.07181v-2H701.2515Zm-24.35937,0H664.71244v-2h12.17969Zm-24.35938,0h-12.1792v-2h12.1792Zm-24.35889,0H615.99418v-2h12.17968Zm-24.35937,0H591.6348v-2h12.17969Zm-24.35889,0H567.27591v-2H579.4556Zm-24.35937,0H542.91654v-2h12.17969Zm-24.35938,0H518.55716v-2h12.17969Zm-24.35888,0H494.19828v-2H506.378Zm-24.35938,0H469.8389v-2h12.17969Zm-24.35937,0H445.48v-2h12.1792Zm-24.35889,0H421.12064v-2h12.17969Zm-24.35938,0H396.76127v-2H408.941Zm-24.35937,0h-12.1792v-2h12.1792Zm-24.35889,0H348.043v-2h12.17969Zm-24.35937,0H323.68363v-2h12.17969Zm-24.35889,0H299.32474v-2h12.17969Zm-24.35937,0H274.96537v-2h12.17969Zm-24.35938,0h-12.1792v-2h12.1792Zm-24.35889,0H226.24711v-2h12.17968Z"
                transform="translate(-158.10423 -211.22583)"
                fill="#e6e6e6"
            />
            <rect
                x="823.27764"
                y="215.98303"
                width="6"
                height="2"
                fill="#e6e6e6"
            />
            <rect
                x="49.96319"
                y="195.98303"
                width="6"
                height="2"
                fill="#e6e6e6"
            />
            <path
                d="M969.2017,409.20887H957.0225v-2h12.1792Zm-24.35889,0H932.66312v-2h12.17969Zm-24.35938,0H908.30424v-2h12.17919Zm-24.35888,0H883.94486v-2h12.17969Zm-24.35938,0H859.586v-2h12.1792Zm-24.35888,0H835.2266v-2h12.17969Zm-24.35938,0h-12.1792v-2h12.1792Zm-24.35889,0H786.50834v-2H798.688Zm-24.35937,0h-12.1792v-2h12.1792Zm-24.35889,0H737.79008v-2h12.17968Zm-24.35937,0H713.4307v-2h12.17969Zm-24.35889,0H689.07181v-2H701.2515Zm-24.35937,0H664.71244v-2h12.17969Zm-24.35938,0h-12.1792v-2h12.1792Zm-24.35889,0H615.99418v-2h12.17968Zm-24.35937,0H591.6348v-2h12.17969Zm-24.35889,0H567.27591v-2H579.4556Zm-24.35937,0H542.91654v-2h12.17969Zm-24.35938,0H518.55716v-2h12.17969Zm-24.35888,0H494.19828v-2H506.378Zm-24.35938,0H469.8389v-2h12.17969Zm-24.35937,0H445.48v-2h12.1792Zm-24.35889,0H421.12064v-2h12.17969Zm-24.35938,0H396.76127v-2H408.941Zm-24.35937,0h-12.1792v-2h12.1792Zm-24.35889,0H348.043v-2h12.17969Zm-24.35937,0H323.68363v-2h12.17969Zm-24.35889,0H299.32474v-2h12.17969Zm-24.35937,0H274.96537v-2h12.17969Zm-24.35938,0h-12.1792v-2h12.1792Zm-24.35889,0H226.24711v-2h12.17968Z"
                transform="translate(-158.10423 -211.22583)"
                fill="#e6e6e6"
            />
            <rect
                x="823.27764"
                y="195.98303"
                width="6"
                height="2"
                fill="#e6e6e6"
            />
            <rect
                x="49.96319"
                y="152.98303"
                width="6"
                height="2"
                fill="#e6e6e6"
            />
            <path
                d="M969.2017,366.20887H957.0225v-2h12.1792Zm-24.35889,0H932.66312v-2h12.17969Zm-24.35938,0H908.30424v-2h12.17919Zm-24.35888,0H883.94486v-2h12.17969Zm-24.35938,0H859.586v-2h12.1792Zm-24.35888,0H835.2266v-2h12.17969Zm-24.35938,0h-12.1792v-2h12.1792Zm-24.35889,0H786.50834v-2H798.688Zm-24.35937,0h-12.1792v-2h12.1792Zm-24.35889,0H737.79008v-2h12.17968Zm-24.35937,0H713.4307v-2h12.17969Zm-24.35889,0H689.07181v-2H701.2515Zm-24.35937,0H664.71244v-2h12.17969Zm-24.35938,0h-12.1792v-2h12.1792Zm-24.35889,0H615.99418v-2h12.17968Zm-24.35937,0H591.6348v-2h12.17969Zm-24.35889,0H567.27591v-2H579.4556Zm-24.35937,0H542.91654v-2h12.17969Zm-24.35938,0H518.55716v-2h12.17969Zm-24.35888,0H494.19828v-2H506.378Zm-24.35938,0H469.8389v-2h12.17969Zm-24.35937,0H445.48v-2h12.1792Zm-24.35889,0H421.12064v-2h12.17969Zm-24.35938,0H396.76127v-2H408.941Zm-24.35937,0h-12.1792v-2h12.1792Zm-24.35889,0H348.043v-2h12.17969Zm-24.35937,0H323.68363v-2h12.17969Zm-24.35889,0H299.32474v-2h12.17969Zm-24.35937,0H274.96537v-2h12.17969Zm-24.35938,0h-12.1792v-2h12.1792Zm-24.35889,0H226.24711v-2h12.17968Z"
                transform="translate(-158.10423 -211.22583)"
                fill="#e6e6e6"
            />
            <rect
                x="823.27764"
                y="152.98303"
                width="6"
                height="2"
                fill="#e6e6e6"
            />
            <path
                d="M498.5924,306.42238A54.43442,54.43442,0,1,1,444.158,251.988,54.19376,54.19376,0,0,1,498.5924,306.42238Z"
                transform="translate(-158.10423 -211.22583)"
                fill="#f2f2f2"
            />
            <path
                d="M466.42048,293.39789v0l-.26934.48551c2.39734-5.90828.21737-13.18867-4.05929-17.64369-4.2765-4.455-10.22183-6.49172-16.076-7.29974a26.11051,26.11051,0,0,0-12.989,1.00937c-5.984,2.30524-10.55592,7.64166-14.37308,13.193-1.746,2.53912-3.46274,5.43386-3.28881,8.60746.16147,2.94513,1.90965,5.44438,3.34127,7.94307,1.43178,2.4987,2.62734,5.59876,1.59981,8.32591-.61229,1.62516-1.90637,2.79562-2.95635,4.12507s-1.91375,3.12637-1.40654,4.79442a3.91163,3.91163,0,0,0,2.91422,2.53267,10.30442,10.30442,0,0,0,3.87175-.09172l33.36873-5.04151A14.22126,14.22126,0,0,0,466.42048,293.39789Z"
                transform="translate(-158.10423 -211.22583)"
                fill="#2f2e41"
            />
            <circle cx="283.49603" cy="86.56464" r="16.3146" fill="#ffb9b9" />
            <path
                d="M485.03177,342.36329a54.41539,54.41539,0,0,1-76.1573,5.50392l4.669-13.028a14.01013,14.01013,0,0,1,11.90033-8.17187l2.97028-.18234,7.08262-6.10851s19.08373-.68141,18.8006-1.00771l11.55965,6.95306-.0096-.048,4.54418.27832a14.004,14.004,0,0,1,11.90036,8.17187Z"
                transform="translate(-158.10423 -211.22583)"
                fill="#628799"
            />
            <path
                d="M457.075,282.64858c-.42114-2.2279-2.69341-3.24688-4.64027-3.74064a44.69879,44.69879,0,0,0-17.29206-.94956c-4.20682.59993-8.51349,1.9228-11.5659,5.20643a15.55509,15.55509,0,0,0-3.93323,10.01779,11.92977,11.92977,0,0,0,1.42785,6.36422,5.89823,5.89823,0,0,0,4.90354,3.16041l.19574.68074a29.69858,29.69858,0,0,0,20.59185-13.79282A17.67067,17.67067,0,0,1,441.6,297.93508a32.16654,32.16654,0,0,0,13.88555-10.70143C456.45455,285.94749,457.39535,284.34356,457.075,282.64858Z"
                transform="translate(-158.10423 -211.22583)"
                fill="#2f2e41"
            />
            <circle cx="749.93011" cy="112.45057" r="54.43442" fill="#f2f2f2" />
            <circle cx="734.98221" cy="84.3398" r="7.68522" fill="#2f2e41" />
            <circle cx="747.27856" cy="101.75963" r="19.46923" fill="#2f2e41" />
            <circle cx="748.8896" cy="105.40309" r="15.82095" fill="#9e616a" />
            <path
                d="M917.73788,300.50166a18.1224,18.1224,0,0,0-27.55245,15.81606c8.06159,2.024,16.5308,3.57295,25.12791.48413l2.03026-4.96892,1.19694,4.9726c2.61811,1.07012,5.243,1.97555,7.86788-.01034A18.504,18.504,0,0,0,917.73788,300.50166Z"
                transform="translate(-158.10423 -211.22583)"
                fill="#2f2e41"
            />
            <path
                d="M885.931,274.70314a15.72,15.72,0,0,0-16.40724,2.52753,17.77974,17.77974,0,0,0-5.67439,15.82291c.87144,5.9001,4.45831,10.98087,7.76459,15.94464s6.53641,10.40295,6.53272,16.367a16.22438,16.22438,0,0,1-16.19216,16.21436c5.04165,2.17045,10.30379,4.38345,15.78676,4.12615s11.22994-3.7821,12.12433-9.19775c.64447-3.90218-1.27-7.7073-2.86676-11.3257a80.1134,80.1134,0,0,1-5.59893-18.43074c-.53326-3.029-.8805-6.21337.107-9.1261a10.71639,10.71639,0,0,1,10.64589-7.03244l1.81167-1.62989A14.10411,14.10411,0,0,0,885.931,274.70314Z"
                transform="translate(-158.10423 -211.22583)"
                fill="#2f2e41"
            />
            <path
                d="M948.9921,360.09716a54.41536,54.41536,0,0,1-76.1573,5.50392l4.66895-13.028a14.01011,14.01011,0,0,1,11.90033-8.17186l2.97028-.18235,7.08262-6.1085s19.08373-.68142,18.8006-1.00771l11.55965,6.953-.0096-.048,4.54418.27832a14.004,14.004,0,0,1,11.90036,8.17186Z"
                transform="translate(-158.10423 -211.22583)"
                fill="#628799"
            />
            <path
                d="M807.59231,265.75677a54.43442,54.43442,0,1,1-54.33782-54.53085A54.29369,54.29369,0,0,1,807.59231,265.75677Z"
                transform="translate(-158.10423 -211.22583)"
                fill="#f2f2f2"
            />
            <path
                d="M727.92812,247.95277c2.25084-7.17335,7.36933-13.75249,14.44807-16.17747,7.07917-2.425,17.44486,1.26906,22.7668,6.573,9.73487,9.702,12.11055,43.03175,5.42228,53.56434-1.33031-.07042-5.92073-.1225-7.26716-.17293l-1.90469-6.34826v6.28569q-3.21759-.09943-6.48554-.1373a28.2255,28.2255,0,0,1-27.867-27.52907C726.86664,257.02447,727.04111,250.77962,727.92812,247.95277Z"
                transform="translate(-158.10423 -211.22583)"
                fill="#2f2e41"
            />
            <circle cx="594.24641" cy="44.9877" r="15.51343" fill="#9e616a" />
            <path
                d="M738.96206,242.589l17.86223-8.94406,0,0a22.451,22.451,0,0,1,13.14952,18.601l.44522,5.333-7.47982-1.904-.81947-6.81926-1.2702,6.28734-3.45187-.87829.03281-10.58688-3.454,10.57932-12.32864-2.83249Z"
                transform="translate(-158.10423 -211.22583)"
                fill="#2f2e41"
            />
            <path
                d="M793.55192,301.68273a54.41538,54.41538,0,0,1-76.1573,5.50391l4.66895-13.028a14.01009,14.01009,0,0,1,11.90032-8.17187l2.97029-.18234,7.08262-6.10851s19.08373-.68141,18.8006-1.00771l11.55965,6.95305-.00961-.048,4.54419.27832a14.004,14.004,0,0,1,11.90035,8.17187Z"
                transform="translate(-158.10423 -211.22583)"
                fill="#628799"
            />
            <circle cx="445.40865" cy="142.45057" r="54.43442" fill="#f2f2f2" />
            <circle cx="443.90625" cy="131.81777" r="17.37605" fill="#ffb6b6" />
            <path
                d="M644.35068,390.09716a54.41536,54.41536,0,0,1-76.1573,5.50392l4.66895-13.028a14.0101,14.0101,0,0,1,11.90032-8.17186l2.97029-.18235,7.08262-6.1085s19.08373-.68142,18.8006-1.00771l11.55965,6.953-.0096-.048,4.54418.27832a14.004,14.004,0,0,1,11.90035,8.17186Z"
                transform="translate(-158.10423 -211.22583)"
                fill="#628799"
            />
            <path
                d="M620.33645,332.26732a22.40275,22.40275,0,0,0-9.38192-12.76467c-3.648-2.3333-8.20016-3.52449-12.333-2.29057s-7.55451,5.2898-7.284,9.672a4.23985,4.23985,0,0,0-4.27975,1.32164,9.81133,9.81133,0,0,0-2.09973,4.25128,27.02765,27.02765,0,0,0,.25348,14.9665l-.08206.69834c2.01629-.33356,3.54567-2.06356,4.49671-3.90676s1.49831-3.88808,2.52906-5.68621,2.74243-3.4021,4.78435-3.45822l-.17995,3.12123q2.63517-1.35767,5.27029-2.71533-.62682,1.49938-1.25361,2.99878,2.30133-.96189,4.60265-1.92374-.25727,1.4418-.5145,2.88356a6.848,6.848,0,0,1,9.428-3.04833,9.1,9.1,0,0,1,3.7202,5.23332,27.56918,27.56918,0,0,1,.89088,6.53149A22.84088,22.84088,0,0,0,620.33645,332.26732Z"
                transform="translate(-158.10423 -211.22583)"
                fill="#2f2e41"
            />
            <circle cx="130.26989" cy="167.19686" r="54.43442" fill="#f2f2f2" />
            <circle
                id="a04f79c1-b358-4543-9edb-632b127caa90"
                data-name="ab6171fa-7d69-4734-b81c-8dff60f9761b"
                cx="129.07155"
                cy="156.32912"
                r="16.51407"
                fill="#9e616a"
            />
            <path
                d="M328.51124,414.36329a54.41539,54.41539,0,0,1-76.1573,5.50392l4.669-13.028a14.01011,14.01011,0,0,1,11.90032-8.17187l2.97029-.18234,7.08262-6.10851s19.08373-.68141,18.8006-1.00771l11.55965,6.95306-.00961-.048,4.54419.27832a14.004,14.004,0,0,1,11.90035,8.17187Z"
                transform="translate(-158.10423 -211.22583)"
                fill="#628799"
            />
            <path
                d="M272.17089,375.18979c-.0325-.09032-.06206-.1817-.09413-.2722-.06358.056-.1245.11381-.19344.16612Z"
                transform="translate(-158.10423 -211.22583)"
                fill="#2f2e41"
            />
            <path
                d="M306.95789,361.68a11.87255,11.87255,0,0,0-4.43336-8.66557,10.41525,10.41525,0,0,1-2.71294-2.45248,16.778,16.778,0,0,1-.9216-2.2983c-1.26772-3.01747-4.8807-4.62116-8.12161-4.16424a12.20213,12.20213,0,0,0-7.93481,5.30966,5.40074,5.40074,0,0,0-7.55266,6.26007c-1.67831-1.352-4.39309-.27053-5.44074,1.61285s-.88136,4.18793-.56989,6.32046a59.72319,59.72319,0,0,0,2.80648,11.3151c1.63415-1.439,1.53372-4.05968,1.334-6.275-.20751-2.30147-.08318-5.1114,1.92933-6.24707,1.94439-1.09721,4.30435.1074,6.44825.73047a12.18657,12.18657,0,0,0,10.08079-1.58567,31.75415,31.75415,0,0,1,3.31494-2.30941,3.9125,3.9125,0,0,1,3.8568-.06862,5.38809,5.38809,0,0,1,1.78814,3.14247q1.23822,4.1989,2.47641,8.39779A11.87242,11.87242,0,0,0,306.95789,361.68Z"
                transform="translate(-158.10423 -211.22583)"
                fill="#2f2e41"
            />
            <rect
                x="125.25492"
                y="263.52564"
                width="5.19612"
                height="152.30245"
                fill="#3f3d56"
            />
            <rect
                x="280.81827"
                y="192.39483"
                width="5.19612"
                height="223.43326"
                fill="#3f3d56"
            />
            <rect
                x="436.70194"
                y="239.15993"
                width="5.19612"
                height="176.66816"
                fill="#3f3d56"
            />
            <rect
                x="592.58561"
                y="153.42392"
                width="5.19612"
                height="262.40417"
                fill="#3f3d56"
            />
            <rect
                x="748.46927"
                y="213.17932"
                width="5.19612"
                height="202.64877"
                fill="#3f3d56"
            />
            <circle cx="283.73675" cy="195.97628" r="15.89577" fill="#628799" />
            <circle cx="595.50408" cy="154.4073" r="15.89577" fill="#628799" />
            <circle cx="439.62041" cy="240.14332" r="15.89577" fill="#628799" />
            <circle cx="127.85308" cy="263.52587" r="15.89577" fill="#628799" />
            <circle cx="751.38775" cy="211.56465" r="15.89577" fill="#628799" />
            <path
                d="M1020.99638,625.48377H177.10221l-5.29858,2.64929a2.6494,2.6494,0,0,0,2.64929,2.64929h846.54346a2.64929,2.64929,0,0,0,0-5.29858Z"
                transform="translate(-158.10423 -211.22583)"
                fill="#3f3d56"
            />
            <circle cx="15.89577" cy="415.65256" r="15.89577" fill="#3f3d56" />
            <circle cx="127.89577" cy="461.65256" r="15.89577" fill="#e6e6e6" />
            <circle cx="283.89577" cy="461.65256" r="15.89577" fill="#e6e6e6" />
            <circle cx="439.89577" cy="461.65256" r="15.89577" fill="#e6e6e6" />
            <circle cx="594.89577" cy="461.65256" r="15.89577" fill="#e6e6e6" />
            <circle cx="750.89577" cy="461.65256" r="15.89577" fill="#e6e6e6" />
            <circle cx="867.89577" cy="415.65256" r="15.89577" fill="#3f3d56" />
        </svg>
    );
};
export default StakeholdersIllustrator;
