import {useState} from "react";

const useDownloadFileFromS3 = (progress) => {
    let isConfigUpdate = false;

    async function downloadFileFromS3(credential, cd, fileKey) {
        try {
            if (!window.AWS) {
                return
            }
            if (!isConfigUpdate) {
                window.AWS.config.update(({region: credential.region}));
                isConfigUpdate = true;
            }
            const s3 = new window.AWS.S3({
                credentials: new window.AWS.Credentials({
                    apiVersion: 'latest',
                    accessKeyId: credential.accessKeyId,
                    dirname: 'test',
                    secretAccessKey: credential.secretAccessKey,
                    signatureVersion: credential.signatureVersion,
                    region: credential.region,
                    Bucket: credential.Bucket
                })
            });
            return await s3.getObject({
                Bucket: credential.Bucket,
                Key: fileKey,
            }).on('httpDownloadProgress', (progress) => {
                cd(getUploadingProgress(progress.loaded, progress.total));

            }).promise();
        } catch (error) {
            // setIsUploading(false)
            console.log(error)
        }
    }

    function getUploadingProgress(uploadSize, totalSize) {
        let uploadProgress = (uploadSize / totalSize) * 100;
        return Number(uploadProgress.toFixed(0));
    }

    const s3FileDownloader = (fileKey, fileTitle) => {
        let credentialRequest = {
            accessKeyId: process.env.REACT_APP_AWS_ACCESS_ID,
            secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
            signatureVersion: process.env.REACT_APP_AWS_VERSION,
            region: process.env.REACT_APP_AWS_REGION,
            Bucket: process.env.REACT_APP_AWS_BUCKET_NAME
        };

        downloadFileFromS3(credentialRequest, progress, fileKey).then((data) => {
            let blob = new Blob([data.Body], {type: data.ContentType});
            const linkElement = document.createElement('a');
            linkElement.href = window.URL.createObjectURL(blob);
            linkElement.download = fileTitle ?? 'unnamed-file.ext';
            linkElement.click();
            linkElement.remove()
        }).catch((err) => {
            console.log('error', err)
        })
    }

    return {s3FileDownloader};
}
export default useDownloadFileFromS3;

