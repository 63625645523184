import StakeholderProfile from "pages/general/stakeholder";
import { Route, Routes } from "react-router-dom";
import { permissions, RequirePermission } from "provider/permission";
import Overview from "pages/general/stakeholder/tabs/overview/overview";
import ContactPerson from "pages/general/stakeholder/tabs/contact-personal/contact-person";
import RelationshipHolder from "pages/general/stakeholder/tabs/relationship-holder/relationship-holder";
import GoalsAndConcern from "pages/general/stakeholder/tabs/goals-and-concern";
import StakeholderEngagement from "pages/general/stakeholder/tabs/engagement";
import Commitment from "pages/general/stakeholder/tabs/Commitment";

const StakeholderProfileRoutes = () => {
    return (
        <RequirePermission permission={[permissions.READ_STAKEHOLDERS]}>
            <Routes>
                <Route element={<StakeholderProfile />}>
                    <Route path={"/"} element={<Overview />} />
                    <Route path={"/overview"} element={<Overview />} />
                    <Route
                        path={"/:stakeholderName/overview"}
                        element={<Overview />}
                    />

                    <Route
                        path={"/:stakeholderName/contact-personal"}
                        element={<ContactPerson />}
                    />
                    <Route
                        path={"/contact-personal"}
                        element={<ContactPerson />}
                    />

                    <Route
                        path={"/:stakeholderName/relationship-holder"}
                        element={<RelationshipHolder />}
                    />
                    <Route
                        path={"/relationship-holder"}
                        element={<RelationshipHolder />}
                    />

                    <Route
                        path={"/:stakeholderName/goals-and-concern"}
                        element={<GoalsAndConcern />}
                    />
                    <Route
                        path={"/goals-and-concern"}
                        element={<GoalsAndConcern />}
                    />

                    <Route
                        path={"/:stakeholderName/engagements"}
                        element={<StakeholderEngagement />}
                    />
                    <Route
                        path={"/engagements"}
                        element={<StakeholderEngagement />}
                    />

                    <Route
                        path={"/:stakeholderName/commitments"}
                        element={<Commitment />}
                    />
                    <Route path={"/commitments"} element={<Commitment />} />
                </Route>
            </Routes>
        </RequirePermission>
    );
};
export default StakeholderProfileRoutes;
