import { ExclamationCircleIcon } from "@heroicons/react/outline";
import { splitOnUpperCase } from "../../utils";
import React from "react";

const Textarea = (
    {
        className,
        rows = 4,
        showResizer = "none",
        onChange: handleChange,
        hasError = false,
        showErrorMessage = true,
        value,
        register = () => {},
        name,
        defaultValue,
        placeholder,
        wrapperClass,
    },
    ...props
) => {
    return (
        <div className={`${wrapperClass}`}>
            <label
                htmlFor={name}
                className={"text-primary/80 capitalize tracking-wider"}
            >
                {splitOnUpperCase(name)}
            </label>
            <div className={"relative pt-2"}>
                <textarea
                    value={value}
                    onChange={handleChange}
                    defaultValue={defaultValue}
                    {...register(name)}
                    style={{ resize: showResizer }}
                    rows={rows}
                    {...props}
                    placeholder={placeholder}
                    className={` ${className} w-full text-gray-500 focus:border-[1.7px] focus:ring-4 enable-transition  placeholder-[#AAB6C5] 
                    focus:outline-none tracking-wider py-3 border-[1.4px] 
                    ${
                        hasError
                            ? "border-red-500 focus:ring-red-500/30 focus:border-red-500"
                            : "border-[#D2D4DA] focus:border-accent focus:ring-[#5D9EE9]/30"
                    }
                     rounded-[5px]
                `}
                ></textarea>
                {hasError ? (
                    <ExclamationCircleIcon
                        className={
                            "absolute right-2 top-0 h-5 w-5 text-red-500 bottom-0 my-auto"
                        }
                    />
                ) : null}
            </div>
            {hasError && showErrorMessage ? (
                <p className={"text-red-500 pt-1 text-sm"}>
                    Please provide valid {name}
                </p>
            ) : null}
        </div>
    );
};
export default Textarea;
