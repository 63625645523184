import FileUpload from "../../../../../../components/file-upload";
import {getRandomKey} from "../../../../../../utils";
import {VideoCameraIcon} from "@heroicons/react/outline";
import React, {useState} from "react";
import UploadedFiles from "../../../../../../components/file-upload/uploaded-files";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFile, faFileAudio, faPlus} from "@fortawesome/free-solid-svg-icons";


const DocumentsUpload = ({documents, setDocuments}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [uploadedDocuments, setUploadedDocuments] = useState([]);

    const handleSubmit = (docs) => {
        setUploadedDocuments(docs);
    }

    const filesTypes = ".doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf, application/vnd.ms-excel";
    const className = {
        bgColor1: "bg-[#a3afcb]/10",
        bgColor2: "bg-transparent"
    }

    return (
        <React.Fragment>
            <FileUpload onClose={setIsOpen} isOpen={isOpen} onSubmit={handleSubmit} fileTypes={filesTypes}/>
            <div className="h-full  ">
                {uploadedDocuments.length ? (
                    <React.Fragment>
                        <button onClick={() => setIsOpen(true)}
                                className={'text-accent pl-2 hover:underline text-sm flex items-center space-x-1'}>
                            <FontAwesomeIcon icon={faPlus}/>
                            <p>Upload files</p>
                        </button>
                        <UploadedFiles
                            isDocumentFile={true}
                            className={className}
                            showFilenameOnHover={true}
                            files={uploadedDocuments}
                            onRemove={setUploadedDocuments}
                            showTitle={false}
                        />
                    </React.Fragment>

                ) : (
                    <div className={'h-52 center flex-col space-y-3'}>
                        <FontAwesomeIcon icon={faFile} className={'h-10 w-10 text-[#AAB6C5]'}/>
                        <button className={'text-accent hover:underline '} onClick={() => setIsOpen(true)}>
                            Upload files
                        </button>
                    </div>
                )}
            </div>
        </React.Fragment>
    )
};


export default DocumentsUpload;

