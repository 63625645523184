import React, { useEffect, useState } from "react";
import { ExclamationCircleIcon } from "@heroicons/react/outline";
import { splitOnUpperCase } from "../../utils";

const Select = React.forwardRef(({ props }, ref) => {
    const {
        name = "",
        register,
        options,
        className,
        defaultValue,
        displayName,
        valueName,
        hasError: errorState = false,
    } = props;
    const [isError, setIsError] = useState(errorState);
    useEffect(() => {
        setIsError(errorState);
    }, [errorState]);

    return (
        <div className={"space-y-2"}>
            <label
                htmlFor={name}
                className={"text-primary/80 capitalize tracking-wider"}
            >
                {splitOnUpperCase(name ?? "")}
            </label>
            <div className={"relative"}>
                <select
                    ref={ref}
                    {...register(name)}
                    name={name}
                    defaultValue={defaultValue ? defaultValue : ""}
                    className={`${className}
                w-full text-gray-500 focus:border-[1.7px] focus:ring-4 enable-transition  placeholder-[#AAB6C5] 
                focus:outline-none focus:ring-0 tracking-wider py-3 border-[1.4px] 
                ${
                    isError
                        ? "border-red-500 focus:ring-red-500/30 focus:border-red-500"
                        : "border-light-gray focus:border-accent focus:ring-[#5D9EE9]/30"
                }
                 rounded-[5px]
             `}
                >
                    <option disabled={true} value="">
                        Choose
                    </option>
                    {options.map((item) => (
                        <option value={valueName ? item[valueName] : item}>
                            {displayName ? item[displayName] : item}
                        </option>
                    ))}
                </select>

                {isError ? (
                    <ExclamationCircleIcon
                        className={
                            "absolute right-8 top-0 h-5 w-5 text-red-500 bottom-0 my-auto"
                        }
                    />
                ) : null}
            </div>
            {isError ? (
                <p className={"text-red-500 pt-1 text-sm"}>
                    Please provide valid {name}
                </p>
            ) : null}
        </div>
    );
});
export default Select;
