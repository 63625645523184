import { useNavigate, useOutletContext } from "react-router-dom";
import { Fragment, useEffect, useState } from "react";

import { UnstableComboboxInput } from "components/combobox";
import Label from "components/form-control/Label";
import Input from "components/form-control/Input";
import FileUpload from "components/file-upload";
import UploadedFiles from "components/file-upload/uploaded-files";
import { useUsers } from "hooks";
import ActionButtons from "../_partials/action-buttons";
import {
    getValueFromLocalStorage,
    saveMultiValueToLocalStorage,
    saveValueToLocalStorage,
} from "hooks/useLocalStorageState";
import { localStorageKeys } from "../../../../../../../../../constants";
import { isEmptyObject } from "../../../../../../../../../utils";
import { useMutation } from "react-query";
import { updateProjectEvent } from "../../../../../../../../../provider/api";

const MeetingBudget = () => {
    const { meetingForm: meetingLSKeys } = localStorageKeys;

    const meetingBudget = getValueFromLocalStorage(
        meetingLSKeys.MEETING_BUDGET,
        {}
    );
    const meetingBasicInfo = getValueFromLocalStorage(
        meetingLSKeys.BASIC_DETAILS,
        {}
    );
    const [isOpen, setIsOpen] = useState(false);
    const [uploadedFiles, setUploadedFiles] = useState(
        meetingBudget?.budgetFiles ?? []
    );
    const [budgetApprovers, setBudgetApprovers] = useState([]);
    const navigate = useNavigate();
    const [setCurrStep, toast] = useOutletContext();
    const [budgetTitle, setBudgetTitle] = useState(
        meetingBudget?.budgetTitle ?? ""
    );
    const [budgetApprover, setBudgetApprover] = useState(
        meetingBudget?.budgetApprover ?? {}
    );

    const [isSubmitted, setIsSubmitted] = useState(false);
    const { users = [], isLoading } = useUsers();
    const isEditing = getValueFromLocalStorage(
        meetingLSKeys.IS_UPDATING_DETAILS
    );

    const buildPayloadAndUpdateEventDetails = () => {
        const payload = {
            _id: meetingBasicInfo._id,
            budgetApprover: budgetApprover._id,
            budgetTitle,
            budgetFiles: uploadedFiles,
        };
        updateEvent(payload);
    };

    const saveBudgetDetailsAndAdvanceToNextStep = async () => {
        const payload = {
            budgetApprover,
            budgetTitle,
            budgetFiles: uploadedFiles,
        };

        setCurrStep(8);
        await saveMultiValueToLocalStorage({
            [meetingLSKeys.MEETING_BUDGET]: payload,
            [meetingLSKeys.CURRENT_STEP]: 8,
        });
        toast.success("Data has been saved successfully");
        navigate("/event-registration/meeting/set-notification");
    };

    const { mutate: updateEvent, isLoading: isUpdatingEvent } = useMutation(
        updateProjectEvent,
        {
            onSuccess: saveBudgetDetailsAndAdvanceToNextStep,
            onError: ({ message }) => toast.error(message),
        }
    );

    const handleSubmit = async (e) => {
        setIsSubmitted(true);
        e.preventDefault();

        if (budgetApprover && budgetTitle && uploadedFiles.length) {
            if (isEditing) {
                return buildPayloadAndUpdateEventDetails();
            }
            await saveBudgetDetailsAndAdvanceToNextStep();
        }
    };

    useEffect(() => {
        if (users.length) {
            // setBudgetApprovers(
            //     users.filter((user) => {
            //         return user?.role?.name === userRoles.HEAD_OF_FINANCE;
            //     })
            // );
            setBudgetApprovers(users);
        }
    }, [users]);

    const handleSubmittedFiles = (files) => {
        setUploadedFiles([...uploadedFiles, ...files]);
    };

    const labelClasses = "text-dark text-[15px] font-medium opacity-100";

    const handleChange = ({ target }) => {
        setBudgetTitle(target.value);
    };

    const goBack = () => {
        navigate("/event-registration/meeting/set-reminder");

        setCurrStep(6);
        saveValueToLocalStorage("meetingFormCurrentStep", 6);
    };

    const comboboxProps = {
        hasError: isSubmitted && isEmptyObject(budgetApprover),
        defaultOption: budgetApprover,
        onChange: setBudgetApprover,
        displayItemName: "fullName",
        className: "border-[1.8px]",
        name: "budget approver",
        isLoading: isLoading,
        options: budgetApprovers,
    };

    return (
        <Fragment>
            <FileUpload
                onClose={setIsOpen}
                isOpen={isOpen}
                onSubmit={handleSubmittedFiles}
            />
            <form onSubmit={handleSubmit} className={"pr-40 space-y-7 "}>
                <div className={" space-y-2 "}>
                    <Label
                        text={"Select budget approver"}
                        className={labelClasses}
                    />
                    <UnstableComboboxInput {...comboboxProps} />
                </div>

                {/* Description */}
                <div className={" space-y-2 "}>
                    <Label text={"Budget title"} className={labelClasses} />
                    <Input
                        defaultValue={meetingBudget?.budgetTitle}
                        onChange={handleChange}
                        name={"budget title"}
                        className={"border-[1.8px]"}
                        isError={isSubmitted && !budgetTitle}
                        placeholder={"Eg: Food budget"}
                    />
                </div>

                {/* upload meeting budget*/}
                <div className={"space-y-4"}>
                    <div>
                        <Label
                            text={"Upload budget files below"}
                            className={labelClasses}
                        />
                        {isSubmitted && !uploadedFiles.length ? (
                            <p className={"text-sm text-red-500"}>
                                Budget files are required
                            </p>
                        ) : null}
                    </div>
                    <UploadedFiles
                        onUpload={() => setIsOpen(true)}
                        files={
                            uploadedFiles.length
                                ? uploadedFiles
                                : meetingBudget.budgetFiles
                        }
                        onRemove={setUploadedFiles}
                    />
                </div>
                <ActionButtons
                    isEditing={isEditing}
                    isLoading={isUpdatingEvent}
                    text={"Next"}
                    onCancel={goBack}
                />
            </form>
        </Fragment>
    );
};
export default MeetingBudget;
