import {TrashIcon} from "@heroicons/react/outline";
import SpinLoader from "../SpinLoader";
import {Fragment, useEffect, useState} from "react";
import {DownloadAltIcon} from "../../svg";
import useDownloadFileFromS3 from "../../hooks/useDownloadFileFromS3";
import useDeleteFromS3Bucket from "../../hooks/useDeleteFromS3Bucket";
import useUploadFileToS3 from "../../hooks/useUploadToS3Bucket";
import {getFileSize, isEmptyObject} from "../../utils";
import {getUploadedFile} from "../../pages/general/engagement/activity/conduct-meeting/grievance/selected-attachments";

const UploadedFileItem = ({
                              file, setUploadedFiles, uploadedFiles, onUploadFinish = () => {
    }
                          }) => {
    const [isDownloading, setIsDownloading] = useState(false);
    const [uploadedFile, setUploadedFile] = useState(null);
    const [progress, setProgress] = useState(0);
    const {name} = file
    const {s3FileDownloader} = useDownloadFileFromS3((progress) => {
        if (parseInt(progress) === 100) {
            setIsDownloading(false);
        }
    })

    const {response = {}, isUploading, upload} = useUploadFileToS3(file, (progress) => {
        setProgress(progress)
    })

    useEffect(() => {
        if (name) upload()
    }, []);

    const {deleteFromS3Bucket} = useDeleteFromS3Bucket()

    useEffect(() => {
        if (!isEmptyObject(response)) {
            const _uploadedFile = getUploadedFile(response, file)
            onUploadFinish(_uploadedFile);
            setUploadedFile(_uploadedFile)
            setUploadedFiles([...uploadedFiles.map((_file) => {
                if (_file.name === _uploadedFile.name) {
                    return _uploadedFile;
                }
                return _file
            })])
        }
    }, [response]);

    const isAlreadyUploaded = () => {
        return uploadedFiles.find((_uploadedFile) => {
            return _uploadedFile.name === name;
        })
    }

    const handleRemove = async () => {
        let fileKey, filename;
        if (uploadedFile) {
            fileKey = uploadedFile.fileKey
            filename = uploadedFile.filename
        } else {
            fileKey = file.fileKey
            filename = file.filename
        }

        if (name) {
            // remove from uploaded List
            setUploadedFiles([...uploadedFiles.filter((_uploadedFile) => {
                return _uploadedFile.name !== name;
            })])
        } else {
            // remove from uploaded List
            setUploadedFiles([...uploadedFiles.filter((_uploadedFile) => {
                return _uploadedFile.filename !== filename;
            })])
        }

        // remove from aws-bucket
        await deleteFromS3Bucket(fileKey)
    }

    const _getFileSize = () => {
        if (name) {
            const [unity, size] = getFileSize(file.size)
            return `${size} ${unity}`;
        }
        return file.fileSize
    }

    const handleDownload = () => {
        let fileKey, title;
        if (uploadedFile) {
            fileKey = uploadedFile.fileKey
            title = uploadedFile.title
        } else {
            fileKey = file.fileKey
            title = file.title
        }
        setIsDownloading(true)
        s3FileDownloader(fileKey, title)
    }


    return (
        <div
            className={"grid text-[15px] gap-x-4 relative hover:bg-black/10 grid-cols-5 py-3 text-[#707070] odd:bg-[#F7F7F7] even:bg-[#F2F2F2]"}>
            <div className={'col-span-2 pl-5'}>
                <p className={"whitespace-nowrap truncate"}>
                    {file.filename || file.title || file.name}

                </p>
            </div>
            <div>
                {_getFileSize()}
            </div>
            <div>
                <button type={"button"} onClick={handleRemove}
                        className={'text-[#FC635E] flex  hover:underline items-center space-x-1'}>
                    <TrashIcon className={'h-5 w-5 '}/>
                    <p>Remove</p>
                </button>
            </div>
            <div className={'pr-5 center'}>
                <button type={"button"} onClick={handleDownload}
                        className={'flex items-center hover:underline space-x-2'}>
                    {isDownloading || isUploading ? (
                        <Fragment>
                            <SpinLoader size={'small'} color={'#1A9EE2'}/>
                            <em> waiting</em>
                        </Fragment>
                    ) : (
                        <Fragment>
                            <em className={'text-[#1A9EE2]'}>Download</em>
                            <DownloadAltIcon className={'h-3 w-3'}/>
                        </Fragment>
                    )}
                </button>
            </div>

            {/* progress bar*/}
            <div
                className={`absolute ${progress === 100 && !isUploading ? 'hidden' : ''} inset-y-0 w-1/2 bg-secondary/20`}
                style={{width: `${progress}%`}}>

            </div>
        </div>
    )
}
export default UploadedFileItem;