import React from "react";

const Loading = () => {
    return (
        <div className={" w-ful h-4/5  flex justify-center items-center "}>
            <div className={"flex space-y-4 items-center flex-col"}>
                <div
                    className={
                        "h-12 w-12 border-accent border-t-transparent border-2 animate-spin rounded-full"
                    }
                ></div>
                <p className={"text-sm text-black/40"}>
                    Loading stakeholder engagements
                </p>
            </div>
        </div>
    );
};
export default Loading;
