import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createContext, useContext, useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import Button from "../../../../../components/Button";
import {
    getValueFromLocalStorage,
    saveValueToLocalStorage,
} from "../../../../../hooks/useLocalStorageState";
import useNotification from "../../../../../hooks/useNotification";
import {
    createCommitments,
    createGrievances,
    updateProjectEvent,
} from "../../../../../provider/api";
import EventProvider from "../../../../../provider/general/event/event-provider";
import { CalenderIcon } from "../../../../../svg/calender";
import Attendance, { NoneRegStakeholder } from "./attendance";
import CommitmentInput from "./commitement";
import StatusBar from "./components/statusbar";
import GrievanceInput from "./grievance";
import MeetingNote from "./meeting-note";
import RightPanel from "./right-panel";

export const ConductMeetingContext = createContext(null);
const ConductMeeting = () => {
    // const {title} = useParams();
    const { setNotification, closeNotification } = useNotification();
    const [isSubmitted, setIsSubmitted] = useState(false);
    const navigate = useNavigate();
    const [percentage, setPercentage] = useState(0);
    const [completedSteps, setCompletedSteps] = useState([]);
    const [isGrievanceSuccessfully, setGrievanceSuccessfully] = useState(false);
    const [isCommitmentSuccessfully, setIsCommitmentSuccessfully] =
        useState(false);
    const [isMeetingSuccessfully, setIsMeetingSuccessfully] = useState(false);
    const [successfullyResponse, setSuccessfullyResponse] = useState({
        meeting: false,
        grievances: false,
        commitments: false,
    });
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setPercentage((completedSteps.length * 100) / 5);
    }, [completedSteps]);

    const { mutate: saveCommitments, isLoading: isSavingCommitment } =
        useMutation(createCommitments, {
            onSuccess: () => {
                setIsCommitmentSuccessfully(true);
            },
            onError: (e) => {
                setIsCommitmentSuccessfully(false);
            },
        });

    const { mutate: updateMeeting, isLoading: isUpdatingMeeting } = useMutation(
        updateProjectEvent,
        {
            onSuccess: () => {
                setIsMeetingSuccessfully(true);
            },
            onError: () => {
                setIsMeetingSuccessfully(false);
            },
        }
    );

    const viewReport = () => {
        navigate(`/engagement/activities/meetings/report/${eventDetails._id}`);
    };

    const { mutate: saveGrievances, isLoading: isSavingGrievance } =
        useMutation(createGrievances, {
            onSuccess: () => {
                setGrievanceSuccessfully(true);
            },
            onError: (e) => {
                setGrievanceSuccessfully(false);
            },
        });

    useEffect(() => {
        if (isSavingCommitment || isSavingGrievance || isUpdatingMeeting) {
            setIsLoading(true);
        } else {
            setIsLoading(false);
        }
    }, [isSavingCommitment, isSavingGrievance, isUpdatingMeeting]);

    const defaultValues = {
        nonRegStakeholder: {
            description: {},
            statistics: {
                total: 0, // total number of all participants (none-registered list
                male: 0, // total number of all males
                female: 0, // total number of all males
                withDisability: 0, // total number of people with disabilities
            },
            documents: [],
        },
        regStakeAttendance: [],
        meetingNote: {},
        noteAttachments: [],
        grievances: [],
        commitments: [],
        images: [],
        documents: [],
        videos: [],
    };
    const eventDetails = getValueFromLocalStorage("activeProjectEvent", {});

    const [conductedInfo, setConductedInfo] = useState(defaultValues);

    const completeMeeting = () => {
        const {
            commitments,
            nonRegStakeholder,
            grievances,
            meetingNote,
            videos,
            images,
            documents,
            noteAttachments,
        } = conductedInfo;
        if (commitments.length) {
            const commitmentPayload = commitments.map((commitment) => {
                return {
                    ...commitment,
                    projectEvent: eventDetails._id,
                    /* project: projectId,
                    title: commitment.title,
                    endDate: commitment.doingDate, */
                    /* startDate: commitment.createdOn,
                    list: commitment.list,
                    description: commitment.description,
                    projectPhase: eventDetails?.engagementPlan?.projectPhase, */
                };
            });
            saveCommitments(commitmentPayload);
        }

        const imageFiles = images.map((image) => {
            return {
                type: image.type,
                title: image.title,
                description: image.description,
                url: image.url,
                fileKey: image.fileKey,
                fileSize: image.fileSize,
            };
        });

        const videoFiles = videos.map((video) => {
            return {
                type: video.type,
                title: video.title,
                url: video.url,
                fileKey: video.fileKey,
                description: video.description,
                fileSize: video.fileSize,
            };
        });

        const documentFiles = documents.map((document) => {
            return {
                type: document.type,
                title: document.title,
                url: document.url,
                description: document.description,
                fileKey: document.fileKey,
                fileSize: document.fileSize,
            };
        });

        if (grievances.length) {
            const grievancePayload = grievances.map((grievance) => {
                return {
                    ...grievance,
                    projectEvent: eventDetails._id,
                    /* project: projectId,
                    time: grievance.createdTime,
                    projectEvent: eventDetails._id,
                    description: grievance.complaints,
                    dateOfIncident: grievance.createdDate,
                    projectRisk: grievance.priorityLevel,
                    department: grievance.department,
                    projectPhase: grievance.phase,
                    nature: grievance.nature,
                    complainer: { fullName: grievance.complainer },
                    attachments: grievance.attachments.map((file) => {
                        return {
                            type: file.type,
                            title: file.title,
                            description: file.description,
                            url: file.url,
                            fileKey: file.fileKey,
                            fileSize: file.fileSize,
                        };
                    }), */
                };
            });
            saveGrievances(grievancePayload);
        }

        const meetingPayload = {
            ...eventDetails, //! This line must be the first in this object
            status: 1,
            completedOn: new Date(),
            attachments: [...imageFiles, ...videoFiles, ...documentFiles],
            notes: JSON.stringify(meetingNote),
            meetingNotes: noteAttachments,
            otherParticipants: {
                description: JSON.stringify(nonRegStakeholder.description),
                ...nonRegStakeholder.statistics,
                attachments: [
                    ...nonRegStakeholder.documents.map((file) => {
                        return {
                            type: file.type,
                            title: file.title,
                            url: file.url,
                            description: file.description,
                            fileKey: file.fileKey,
                            fileSize: file.fileSize,
                        };
                    }),
                ],
            },
        };
        updateMeeting(meetingPayload);
        setIsSubmitted(true);

        // console.log(grievances, commitments);
    };

    useEffect(() => {
        let isValid = false;
        const { commitments, grievances } = conductedInfo;

        if (!commitments.length && !grievances.length) isValid = true;
        else if (
            commitments.length &&
            isCommitmentSuccessfully &&
            grievances.length &&
            isGrievanceSuccessfully
        ) {
            isValid = true;
        } else if (
            commitments.length &&
            isCommitmentSuccessfully &&
            !grievances.length
        )
            isValid = true;
        else if (
            grievances.length &&
            isGrievanceSuccessfully &&
            !commitments.length
        )
            isValid = true;

        if (isValid && isSubmitted) {
            setNotification(
                <p onClick={closeNotification}>
                    Meeting is conducted successfully,
                    <span
                        onClick={viewReport}
                        className={"text-accent pl-2 cursor-pointer"}
                    >
                        view
                    </span>{" "}
                    report
                </p>
            );
            navigate(`/engagement/activities/events/completed`, {
                replace: true,
            });
            saveValueToLocalStorage("activeProjectEvent", null);
        }
    }, [
        isCommitmentSuccessfully,
        isGrievanceSuccessfully,
        isMeetingSuccessfully,
        isSubmitted,
    ]);

    return (
        <EventProvider>
            <ConductMeetingContext.Provider
                value={{
                    conductedInfo,
                    setConductedInfo,
                    setCompletedSteps,
                    completedSteps,
                }}
            >
                <div className="flex w-full h-full">
                    {/* Left panel */}
                    <div className="overflow-y-auto basis-2/3 scrollbar-hide">
                        <div className="bg-[#F9F9F9] p-10 space-y-6 ">
                            <div
                                className={
                                    "space-y-6 top-0 sticky bg-[#F9F9F9] z-50"
                                }
                            >
                                <div className="space-y-0">
                                    <p className="text-2xl font-medium text-[#002F4F] tracking-wide">
                                        {eventDetails?.title}
                                    </p>

                                    <div className="flex items-end justify-between">
                                        <div
                                            className={
                                                "flex space-x-5 text-sm font-normal text-[#838EAB]"
                                            }
                                        >
                                            <p className={"flex space-x-4 "}>
                                                <CalenderIcon
                                                    className={"h-4 w-4"}
                                                    color={"#51BF82"}
                                                />
                                                <span>
                                                    July 10th 2022, 3:15 pm -
                                                    5:00 pm
                                                </span>
                                            </p>
                                            <p>Online Meeting</p>
                                        </div>

                                        <Button
                                            onClick={() => navigate(-1)}
                                            disabled={percentage !== 0}
                                            color="danger"
                                        >
                                            Cancel Meeting
                                        </Button>
                                    </div>
                                </div>
                                <StatusBar percentage={percentage} />
                            </div>
                            <div>
                                <p className="text-[#707070]">
                                    All Participant of any meeting created must
                                    first be one among the stakeholders, contact
                                    persons, relationship holders or any
                                    individual who is registered on the system
                                    such as coordinators.
                                </p>

                                <div className="flex items-center mt-3 space-x-2">
                                    <FontAwesomeIcon
                                        icon={faChevronRight}
                                        className={`h-2.5 transition-transform ease-in-out 
                                        ${"text-[#72AAEB]"}`}
                                    />
                                    <p className="text-[#72AAEB] text-sm">
                                        Learn more ...
                                    </p>
                                </div>
                            </div>

                            <Attendance />
                            <NoneRegStakeholder />
                            <MeetingNote />
                            <GrievanceInput />
                            <CommitmentInput />

                            <Button
                                loading={isLoading}
                                onClick={completeMeeting}
                                disabled={percentage !== 100}
                                className="w-full bg-[#009EE2] py-4"
                            >
                                COMPLETE MEETING
                            </Button>
                        </div>
                    </div>

                    {/* Right panel */}
                    <div className="overflow-y-auto basis-1/3">
                        <RightPanel />
                    </div>
                </div>
            </ConductMeetingContext.Provider>
        </EventProvider>
    );
};

export const useConductMeetingContext = () => useContext(ConductMeetingContext);
export default ConductMeeting;
