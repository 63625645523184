const Loading = () => {
    return (
        <div className={"grid grid-cols-6 space-x-3"}>
            {[...Array(6)].map((stakeholder, idx) => (
                <div
                    className={
                        " bg-white py-6 px-6 space-y-5  flex flex-col " +
                        "items-center rounded-[4px] border-[1px] border-[#AAB6C4]"
                    }
                >
                    <div className={"w-full space-y-4"}>
                        <p
                            className={
                                "h-3 rounded skeleton-loading bg-red-500 w-full"
                            }
                        ></p>
                        <p
                            className={
                                "h-3 skeleton-loading w-10 mx-auto rounded "
                            }
                        ></p>
                        <p
                            className={
                                "h-3 skeleton-loading mx-auto rounded w-4/5"
                            }
                        ></p>
                        <p
                            className={
                                "h-3 skeleton-loading mx-auto rounded w-4/5"
                            }
                        ></p>
                    </div>

                    <p
                        className={
                            "h-8 skeleton-loading mx-auto rounded-full w-full"
                        }
                    ></p>
                </div>
            ))}
        </div>
    );
};
export default Loading;
