import Button from "components/Button"
import {useNavigate} from "react-router-dom";


const AccessDenied = () => {
    const navigate = useNavigate();
    return (
        <section className={'h-full center'}>
            <div className={'text-center space-y-4'}>
                <h2 className={'text-7xl font-semibold '}>403</h2>
                <div>
                    <h3 className={'text-2xl tracking-wide font-semibold text-dark'}>You're not permitted to see
                        this.</h3>
                    <p className={'text-sm text-dark2 tracking-wide'}>The page you're try to access has restricted
                        access. <br/>
                        if you feel this is mistake contact your admin.
                    </p>
                </div>
                <div className={'center'}>
                    <Button onClick={() => navigate('/')} className={'py-2'}>Go Back</Button>
                </div>
            </div>
        </section>
    )
}
export default AccessDenied;