import {useEffect, useState} from "react";
import {SearchIcon} from "@heroicons/react/outline";

const SearchBox = (
    {
        wrapperClass, inputClass,
        searchContext = {},
    }) => {
    const {searchFun: handleSearchResult, searchProperty, data = []} = searchContext;

    const [originData, setOriginData] = useState(data);
    const [filteredData, setFilteredData] = useState(data);

    useEffect(() => {
        handleSearchResult(filteredData);
    }, [filteredData]);

    const handleSearch = ({target}) => {
        console.log("DATA", searchProperty)
        const searchKeyword = target.value.toLowerCase();
        setFilteredData(data.filter((_data) => {
            return _data[searchProperty].toLowerCase().includes(searchKeyword);
        }))


    }

    return (
        <div className={`${wrapperClass}  relative `}>
            <input
                onChange={handleSearch}
                className={` w-full rounded-md tracking-wide outline-none focus:border-accent
                    focus:border-[1.7px] focus:ring-4 h-full enable-transition focus:ring-[#5D9EE9]/30  font-light text-sm pl-8 py-2.5
                ${inputClass}`}
                type="text" placeholder={'Search..'}/>
            <div className={'absolute top-0 left-0 flex items-center pl-2 h-full'}>
                <SearchIcon className={'h-5'}/>
            </div>
        </div>
    )
}
export default SearchBox
