import {useParams} from "react-router-dom";

import Logo from "../../../../../../components/logo";
import WorldIcon from "../../../../../../components/world-icon";
import Attendance from "./attendance";
import MeetingAgendas from "./agenda";
import Commitments from "./commitment";
import Grievances from "./grievances";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload} from "@fortawesome/free-solid-svg-icons";
import {CreateMarkup} from "../../../../../../utils";
import dayjs from "dayjs";
import useGetEventReport from "../../../../../../hooks/useGetEventReport";

const MeetingReport = () => {
    const {eventId} = useParams();
    // setNotification(<p>hello am notification</p>)
    // const event = getEventById(eventId);

    const {eventReport, isFetchingReport} = useGetEventReport(eventId);
    if (isFetchingReport) {
        return <p>loading..</p>
    }

    const {
        participants,
        agendas, notes,
        grievances = [],
        commitments = [],
        otherParticipants = {},
    } = eventReport;

    const {
        individualStakeholder,
        organizationalStakeholder
    } = participants;

    const {attachments} = otherParticipants;
    console.log('REPORT:: ', eventReport)

    const inStakeStatistics = {
        total: individualStakeholder.length,
        male: individualStakeholder.filter(stakeholder => ['Male', 'M'].includes(stakeholder.gender)).length,
        female: individualStakeholder.filter(stakeholder => ['Female', 'F'].includes(stakeholder.gender)).length,
        pwd: individualStakeholder.filter(stakeholder => stakeholder.disability !== "none").length,
    }


    return (

        <div className={'space-y-5'}>
            <div className={'items-between py-2'}>
                <p className={'text-[#002F4F] text-lg font-medium'}>Meeting Report</p>
                <button
                    className={`
                        border-[1.5px] flex items-center
                        tracking-wider space-x-3
                        text-accent border-accent
                        rounded-[3px] px-4 py-[.6rem] text-sm
                    `}
                >
                    <p> DOWNLOAD</p>

                    <FontAwesomeIcon icon={faDownload} className={'h-4 w-4'}/>
                </button>
            </div>
            <div
                className={`
                    p-10 rounded-[5px] bg-white
                     space-y-20 border-[2px] 
                     border-[#F0F0F0] border-t-[4px] 
                     border-t-[#70CCBF]
                `}
            >
                <div className={'grid grid-cols-3'}>
                    <div className={'p-3 pr-10 center border-r-[1.4px] border-r-[#F0F0F0]'}>
                        <div className={'space-y-5'}>
                            <div className={'pb-10 space-y-5 border-b-[2px] border-b-[#F0F0F0]'}>
                                <p className={'text-sm text-[#B8B8B8]'}>Meeting Title</p>
                                <p className={'text-2xl text-secondary font-thin'}>
                                    {eventReport?.title}
                                </p>
                            </div>

                            {eventReport?.description ? (
                                <p
                                    dangerouslySetInnerHTML={CreateMarkup(eventReport?.description)}
                                    className={'text-[#B0B4BA]  font-light text-[13px] text-justify leading-relaxed'}>
                                </p>
                            ) : (
                                <p className={'text-[#B0B4BA]  font-light text-[13px] text-justify leading-relaxed'}>
                                    No description provided
                                </p>
                            )}

                        </div>
                    </div>

                    <div className={'p-3 center border-r-[1.4px] border-r-[#F0F0F0]'}>
                        <div className={'flex items-center flex-col space-y-5'}>
                            <WorldIcon className={'h-6'}/>
                            <Logo className={'h-7'}/>
                            <p className={'text-lg font-light text-[#262626]'}>Meeting Report</p>
                            <div className={'space-y-2 text-center'}>
                                <p className={'text-[#B0B1B3] text-sm'}>Date</p>
                                <p className={'text-sm text-[#ABB1B1] tracking-widest'}>
                                    {dayjs(eventReport?.updatedAt).format('dddd, MMMM DD, YYYY')}
                                </p>
                            </div>

                            <div className={'space-y-2 text-center'}>
                                <p className={'text-[#B0B1B3] text-sm'}>Time</p>
                                <p className={'text-4xl font-light text-[#656766]'}>
                                    {dayjs(eventReport?.updatedAt).format('HH:MM A')}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className={'flex justify-center pl-10'}>
                        <div className={'p-3 w-full space-y-5 flex-col'}>
                            <p className={'text-[#838EAB]  font-light text-sm'}>ATTENDANCE BREAKDOWN</p>

                            <div className={'w-full pb-4 space-y-2 border-b-[1.4px] border-b-[#F0F0F0]'}>
                                <p className={'text-[#ABB1B1] font-light text-[15px]'}>Non Registered Stakeholders</p>
                                <div className={'grid grid-cols-4 w-full gap-y-3 '}>
                                    <div>
                                        <p className={'text-secondary text-lg font-semibold'}>
                                            {otherParticipants?.total || 0}
                                        </p>
                                        <p className={'text-xs font-light text-[#ABB1B1]'}>Total</p>
                                    </div>
                                    <div>
                                        <p className={'text-secondary text-lg font-semibold'}>
                                            {otherParticipants?.withDisability || 0}
                                        </p>
                                        <p className={'text-xs font-light text-[#ABB1B1]'}>PWD</p>
                                    </div>
                                    <div>
                                        <p className={'text-secondary text-lg font-semibold'}>
                                            {otherParticipants?.male || 0}
                                        </p>
                                        <p className={'text-xs text-[#ABB1B1] font-light '}>Male</p>
                                    </div>
                                    <div>
                                        <p className={'text-secondary text-lg font-semibold'}>
                                            {otherParticipants?.female || 0}
                                        </p>
                                        <p className={'text-xs text-[#ABB1B1] font-light'}>Female</p>
                                    </div>
                                </div>
                            </div>


                            <div className={'w-full pb-4 space-y-2 border-b-[1.4px] border-b-[#F0F0F0]'}>
                                <p className={'text-[#ABB1B1] font-light text-[15px]'}>Individual stakeholder</p>
                                <div className={'grid grid-cols-4 w-full gap-y-3 '}>
                                    <div>
                                        <p className={'text-secondary text-lg font-semibold'}>
                                            {inStakeStatistics.total}
                                        </p>
                                        <p className={'text-xs font-light text-[#ABB1B1]'}>Total</p>
                                    </div>
                                    <div>
                                        <p className={'text-secondary text-lg font-semibold'}>
                                            {inStakeStatistics.pwd}
                                        </p>
                                        <p className={'text-xs font-light text-[#ABB1B1]'}>PWD</p>
                                    </div>
                                    <div>
                                        <p className={'text-secondary text-lg font-semibold'}>
                                            {inStakeStatistics.male}
                                        </p>
                                        <p className={'text-xs text-[#ABB1B1] font-light '}>Male</p>
                                    </div>
                                    <div>
                                        <p className={'text-secondary text-lg font-semibold'}>
                                            {inStakeStatistics.female}
                                        </p>
                                        <p className={'text-xs text-[#ABB1B1] font-light'}>Female</p>
                                    </div>
                                </div>
                            </div>

                            <div className={'w-full pb-4 space-y-2 border-b-[1.4px] border-b-[#F0F0F0]'}>
                                <p className={'text-[#ABB1B1] text-[15px] font-light'}>Organisational Stakeholder</p>
                                <div className={'grid grid-cols-4 w-full gap-y-3 '}>
                                    <div>
                                        <p className={'text-secondary text-lg font-semibold'}>
                                            {organizationalStakeholder.length}
                                        </p>
                                        <p className={'text-xs font-light text-[#ABB1B1]'}>Total</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className={'pr-10 space-y-3 px-5'}>
                    <p className={'px-5 py-2 text-[#666666] tracking-wider bg-[#F7F7F7]'}>Meeting Note</p>
                    <p dangerouslySetInnerHTML={CreateMarkup(notes)}
                       className={'text-sm text-[#628799] font-light '}>
                    </p>
                </div>

                <Attendance attendance={{participants, attachments}}/>
                <MeetingAgendas agendas={agendas}/>
                {commitments.length ? (
                    <Commitments commitments={commitments} reportId={eventReport._id}/>
                ) : null}
                {grievances.length ? (
                    <Grievances grievances={grievances}/>
                ) : null}
            </div>
        </div>

    )
}
export default MeetingReport;