import { Link } from "react-router-dom";

const CategoryCard = ({ category = {} }) => {
	return (
		<Link
			to={category.url ?? "/"}
			className={
				"bg-[#F4F9FF] hover:bg-[#ebf4ff] flex space-x-4 rounded-[6px] p-5"
			}
		>
			<div>
				<img
					className={category.className ?? "w-12"}
					src={`/images/${category.image.name}`}
					alt={category.image.altName}
				/>
			</div>
			<div className={"w-full mt-1 text-[#0A0E1C] space-y-2"}>
				<p className={"tracking-wide font-medium"}>{category.name}</p>
				<div className={"space-y-2"}>
					<div
						className={"h-[4.5px] w-full rounded-full"}
						style={{ backgroundColor: category.color }}
					></div>
					<p className={"text-xs"}>{category.totalFiles} Files</p>
				</div>
			</div>
		</Link>
	);
};
export default CategoryCard;
