import { useParams } from "react-router-dom";
import ImageCard from "../image-card";
import { useState, Fragment, useEffect } from "react";
import { useProjectImages } from "../../../hooks";
import { flatten } from "../../../utils";
import ImageViewer from "../../../components/image-viewer/image-viewer";
import FileCard from "../../project-files/overview/recent-files/file-card";

const ImageList = ({ viewMode, searchKeyword }) => {
	const { category: imageCategory } = useParams();
	const { images, isLoading } = useProjectImages();
	const [activeImage, setActiveImage] = useState(null);
	const [viewImage, setViewImage] = useState(false);
	const [showListView, setShowListView] = useState(viewImage);
	const [filteredImages, setFilteredImages] = useState([]);
	const [imageList, setImageList] = useState([]);

	useEffect(() => {
		setShowListView(viewMode);
	}, [viewMode]);

	useEffect(() => {
		setFilteredImages(
			imageList.filter((image) => {
				return image?.title?.toLowerCase().includes(searchKeyword);
			})
		);
	}, [searchKeyword]);

	useEffect(() => {
		if (!isLoading) {
			getImageList();
		}
	}, [isLoading, images]);

	if (isLoading) return <p>loading</p>;
	const {
		attendances = [],
		grievances = [],
		meetingNotes = [],
		meetingFiles = [],
	} = images;

	const getImageList = () => {
		switch (imageCategory) {
			case "all": {
				const allImages = flatten([
					attendances,
					grievances,
					meetingNotes,
					meetingFiles,
				]).map((folder) => {
					return folder?.attachments;
				});
				setImageList(flatten(allImages));
				setFilteredImages(flatten(allImages));
				break;
			}
			case "most-recent": {
				break;
			}
			case "grievances": {
				const grievanceImages = flatten(
					grievances.map((folder) => {
						return folder?.attachments;
					})
				);

				setImageList(flatten(grievanceImages));
				setFilteredImages(flatten(grievanceImages));
				break;
			}
			case "meeting": {
				const images = flatten([meetingNotes, meetingFiles]);
				const meetingImages = flatten(
					images.map((folder) => {
						return folder?.attachments;
					})
				);

				setImageList(flatten(meetingImages));
				setFilteredImages(flatten(meetingImages));
				break;
			}
			case "attendance": {
				const attendanceImages = flatten(
					attendances.map((folder) => {
						return folder?.attachments;
					})
				);
				setImageList(flatten(attendanceImages));
				setFilteredImages(flatten(attendanceImages));
				break;
			}

			default:
				return [];
		}
	};

	const handleClick = (image) => {
		setActiveImage(image);
		setViewImage(true);
	};

	return (
		<Fragment>
			{activeImage ? (
				<ImageViewer
					file={activeImage}
					isOpen={viewImage}
					setIsOpen={setViewImage}
				/>
			) : null}

			{showListView ? (
				<Fragment>
					{filteredImages.map((image) => (
						// <ImageCard imageFile={image} key={image?._id} height={'h-44'} onClick={handleClick}/>
						<FileCard file={image} onOpenFile={handleClick} />
					))}
				</Fragment>
			) : (
				<div className={"grid grid-cols-3 2xl:grid-cols-4 gap-4"}>
					{filteredImages.map((image) => (
						<ImageCard
							imageFile={image}
							key={image?._id}
							height={"h-52"}
							onClick={handleClick}
						/>
					))}
				</div>
			)}
		</Fragment>
	);
};
export default ImageList;
