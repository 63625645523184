import React from "react";
import { Link } from "react-router-dom";
import { baseRotes } from "routes/constants";
import { slugify } from "utils";
import ArrowRight from "../_partials/ArrowRight";

const StakeholderCard = (
	{ stakeholder, isActive, setCurrStakeholder },
	...props
) => {
	return (
		<div
			{...props}
			className={"flex font-light text-[#628799] items-center space-x-3"}
		>
			<div
				className={`
                    h-2 w-2
                    ${stakeholder.type === "Individual" && "bg-[#51BF82] "}
                    ${stakeholder.type === "Organizational" && "bg-[#E8529D]"}
                    ${
						stakeholder.type === "Community" && "bg-[#7B75F3]"
					}   rounded-[2px]`}
			></div>

			<Link
				to={`${baseRotes.STAKEHOLDER_BASE_ROUTE}/${slugify(
					stakeholder.name
				)}/overview`}
				onClick={() => setCurrStakeholder(stakeholder)}
				className={
					"flex group w-full items-center justify-between py-1.5 hover:rounded hover:px-3 hover:shadow-lg " +
					"transition-all ease-in-out cursor-pointer space-x-5 " +
					"hover:bg-white"
				}
			>
				<div
					className={`group-hover:font-medium ${
						isActive ? "font-medium" : ""
					}`}
				>
					{stakeholder.name}
				</div>
				<div
					className={` group-hover:opacity-100 ${
						isActive ? "opacity-100" : "opacity-0"
					} transition-all ease-in-out`}
				>
					<ArrowRight />
				</div>
			</Link>
		</div>
	);
};

export default StakeholderCard;
