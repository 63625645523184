import { ParagraphLoading } from "../../../components/skeleton";

const Loading = () => {
    return (
        <div className={"space-y-4 mt-5"}>
            {[...Array(6)].map((item, index) => (
                <div
                    className={
                        "border grid gap-4 grid-cols-6 rounded-lg bg-white px-8 py-6"
                    }
                >
                    <div className={" flex space-x-2"}>
                        <ParagraphLoading width={"12px"} height={"12px"} />
                        <ParagraphLoading height={"12px"} />
                    </div>

                    <div className={"col-span-5 space-y-4"}>
                        {[...Array(5)].map((item, index) => (
                            <div
                                key={index}
                                className={"gap-4 grid-cols-6 grid"}
                            >
                                <ParagraphLoading height={"12px"} />
                                <ParagraphLoading height={"12px"} />
                                <ParagraphLoading height={"12px"} />
                                <ParagraphLoading
                                    height={"12px"}
                                    className={"col-span-2"}
                                />
                                <ParagraphLoading height={"12px"} />
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
};
export default Loading;
