import { useEffect, useRef } from "react";

import Input from "components/form-control/Input";
import { useChannelContext } from "./channel";
import { getValueFromLocalStorage } from "hooks/useLocalStorageState";

const CommunicationChannel = ({
    data,
    onSelect: setSelectedChannel,
    isSelected,
    setData,
}) => {
    const { name, description, icon, color, requireValue } = data;
    const { isSubmitted, selectedChannel } = useChannelContext();
    const meetingChannel = getValueFromLocalStorage("meetingChannel", {});
    const inputRef = useRef(null);

    const handleChange = ({ target }) => {
        const value = target.value;
        setSelectedChannel({ name, value, channelType: "Online" });
    };

    useEffect(() => {
        if (isSelected) {
            return setSelectedChannel({
                name,
                value: meetingChannel?.channel?.value ?? null,
                channelType: "Online",
            });
        }
        inputRef.current.value = "";
    }, [isSelected]);

    return (
        <div
            onClick={() => {
                setSelectedChannel({ name, value: null });
            }}
            className={` rounded-[6px] border-2  overflow-hidden pr-6 items-between ${
                isSelected ? " border-2 border-secondary " : "border-gray-300"
            }`}
        >
            <div className={"flex items-center"}>
                <div
                    className={`w-14 h-14  center`}
                    style={{ backgroundColor: color }}
                >
                    {icon}
                </div>

                <div className={"pl-4"}>
                    <p className={"text-dark font-medium text-[15px]"}>
                        {name}
                    </p>
                    <p className={"text-[13px] font-light"}>{description}</p>
                </div>
            </div>

            <div hidden={!requireValue}>
                <Input
                    inputRef={inputRef}
                    isError={
                        isSubmitted &&
                        !selectedChannel.value &&
                        selectedChannel.name === name
                    }
                    showErrorMessage={false}
                    defaultValue={
                        meetingChannel?.channel?.name === name
                            ? meetingChannel?.channel?.value
                            : ""
                    }
                    className={"h-8 p-0 px-2 text-sm w-44"}
                    placeholder="Paste Meeting link"
                    onChange={handleChange}
                />
            </div>
        </div>
        // <MediaCard/>
    );
};
export default CommunicationChannel;
