import List from "@editorjs/list";
import LinkTool from "@editorjs/link";
import CheckList from "@editorjs/checklist";
import React, { useEffect, useState } from "react";
import { createReactEditorJS } from "react-editor-js";
import { ExclamationCircleIcon } from "@heroicons/react/outline";

const EditorJS = ({
    onChange,
    defaultValue,
    name,
    isError: error = false,
    uniqueId = "editor-holder",
}) => {
    const EditorJs = createReactEditorJS();
    const instanceRef = React.useRef(null);
    const [errorState, setErrorState] = useState(error);
    const [focus, setFocus] = useState(false);

    const EDITOR_JS_TOOLS = {
        list: List,
        linkTool: LinkTool,
        checklist: CheckList,
    };

    useEffect(() => {
        setErrorState(error);
    }, [error]);

    const handleChanges = async () => {
        const savedData = await instanceRef.current.save();
        console.log(savedData);

        onChange(savedData);
    };

    const toggleFocus = () => {
        setFocus(!focus);
    };

    return (
        // wrapper container
        <div>
            {/* inner wrapper allows showing error icon inside editor */}
            <div className="relative">
                <div
                    onFocus={toggleFocus}
                    onBlur={toggleFocus}
                    className={`w-full ${
                        focus
                            ? errorState
                                ? "border-red-500 ring-red-500/50 ring-4"
                                : "enable-transition border-accent ring-accent/50 ring-4"
                            : errorState
                            ? "border-red-500"
                            : ""
                    }
                         p-1 h-32 relative border-[1.7px] enable-transition rounded-md bg-white scrollbar-hide overflow-x-hidden  overflow-y-auto`}
                >
                    <EditorJs
                        autofocus={errorState}
                        holder={uniqueId}
                        onChange={handleChanges}
                        onInitialize={(instance) =>
                            (instanceRef.current = instance)
                        }
                        tools={EDITOR_JS_TOOLS}
                        defaultValue={defaultValue}
                    >
                        <div id={uniqueId}></div>
                    </EditorJs>
                </div>

                {errorState ? (
                    <ExclamationCircleIcon
                        className={
                            "absolute right-2 top-0 h-5 w-5 text-red-500 bottom-0 my-auto"
                        }
                    />
                ) : null}
            </div>
            {errorState ? (
                <p className={"text-red-500 text-sm"}>
                    Please provide valid {name ? name : "description"}.
                </p>
            ) : null}
        </div>
    );
};

export default EditorJS;
