import * as Yup from "yup";

// Yup.addMethod(Yup.string, "description", function (message) {
//     return this.test("description", message, function (value = "{}") {
//         if (value.trim() === "") {
//             // check if value is an empty string
//             return false; // return false if value is empty
//         }
//         const description = JSON.parse(value);
//
//     });
// });

export const meetingAgendaValidationSchema = Yup.object({
    title: Yup.string().required(),
    meetingType: Yup.string().required(),
    mainAgenda: Yup.string().required(),
});

export const stakeholderValidation = {
    individual: Yup.object({
        scope: Yup.string().required(),
        name: Yup.string().required(),
        phoneNumber: Yup.string()
            .required()
            .matches(/^(\+?255|0)[67]\d{8}$/),
        email: Yup.string().required().email(),
        postalCode: Yup.string().required(),
        role: Yup.string().required(),
        gender: Yup.string().required(),
        position: Yup.string().required(),
        disability: Yup.string().required(),
        organization: Yup.string().required(),
        region: Yup.string().required(),
        ward: Yup.string().required(),
        district: Yup.string().required(),
        street: Yup.string().required(),
    }),
    organizational: Yup.object({
        scope: Yup.string().required(),
        name: Yup.string().required(),
        phoneNumber: Yup.string()
            .required()
            .matches(/^(\+?255|0)[67]\d{8}$/),
        email: Yup.string().required().email(),
        organizationType: Yup.string().required(),
        postalCode: Yup.string().required(),
        role: Yup.string().required(),
    }),
    community: Yup.object({
        scope: Yup.string().required(),
        name: Yup.string().required(),
        phoneNumber: Yup.string()
            .required()
            .matches(/^(\+?255|0)[67]\d{8}$/),
        email: Yup.string().required().email(),
        role: Yup.string().required(),
    }),
};

export const ContactPersonalValidation = Yup.object({
    name: Yup.string().required(),
    gender: Yup.string().required(),
    position: Yup.string().required(),
    disability: Yup.string().required(),
});

export const UserAccountValidation = Yup.object({
    firstName: Yup.string().required(),
    middleName: Yup.string().required(),
    lastName: Yup.string().required(),
    gender: Yup.string().required(),
    phoneNumber: Yup.string()
        .required()
        .matches(/^(\+?255|0)[67]\d{8}$/),
    email: Yup.string().required(),
    role: Yup.string().required(),
});

export const UserAddressValidation = Yup.object({
    country: Yup.string(),
    region: Yup.string(),
    district: Yup.string(),
    ward: Yup.string(),
    street: Yup.string(),
    postalCode: Yup.string(),
    role: Yup.string(),
});

export const RoleFormValidation = Yup.object({
    name: Yup.string().required(),
    description: Yup.string().required(),
});

export const CommitmentFormValidation = Yup.object({
    stakeholder: Yup.string().required(),
    description: Yup.string().required(),
    title: Yup.string().required(),
    department: Yup.string().required(),
    madeFor: Yup.string().required(),
    startingDate: Yup.date().required(),
    endingDate: Yup.date().required(),
});

export const DepartmentFormValidation = Yup.object({
    name: Yup.string().required(),
    headOfDepartment: Yup.string().required(),
    description: Yup.string().required(),
    establishedOn: Yup.string().required(),
});

export const GrievanceNatureFormValidation = Yup.object({
    title: Yup.string().required(),
    description: Yup.string().required(),
    // color: Yup.string().required(),
});

export const UserProfileFormValidation = Yup.object({
    fullName: Yup.string().required(),
    gender: Yup.string().required(),
    location: Yup.string().required(),
    position: Yup.string().required(),
    email: Yup.string().required(),
    phoneNumber: Yup.string()
        .required()
        .matches(/^(\+?255|0)[67]\d{8}$/),
    // color: Yup.string().required(),
});

export const GrievanceFormValidation = Yup.object({
    title: Yup.string().required(),
    complainer: Yup.string().required(),
    email: Yup.string().email().required(),
    complainerAge: Yup.string().required(),
    phoneNumber: Yup.string()
        .required()
        .matches(/^(\+?255|0)[67]\d{8}$/),
    // department: Yup.string().required(),
    createdTime: Yup.string().required(),
    riskLevel: Yup.string().required(),
    estimatedResolveDate: Yup.string().required(),
    // nature: Yup.string().required()
});

export const GVBComplainerFormValidation = Yup.object({
    title: Yup.string().required(),
    complainer: Yup.string().required(),
    complainerAge: Yup.string().required(),
    phoneNumber: Yup.string()
        .required()
        .matches(/^(\+?255|0)[67]\d{8}$/),
    email: Yup.string().email().required(),
    complainerGender: Yup.string().required(),
});

export const GrievanceForm2Validation = Yup.object({
    department: Yup.string().required(),
    time: Yup.string().required(),
    nature: Yup.string().required(),
    projectPhase: Yup.string().required(),
});

export const GBVTheDefendantFormValidation = Yup.object({
    fullName: Yup.string().required(),
    age: Yup.string().required(),
    phoneNumber: Yup.string()
        .required()
        .matches(/^(\+?255|0)[67]\d{8}$/),
    email: Yup.string().email().required(),
    gender: Yup.string().required(),
    gbvType: Yup.string().required(),
});

export const GBVComplaintFormValidation = Yup.object({
    time: Yup.string().required(),
    gbvNature: Yup.string().required(),
    createdTime: Yup.string().required(),
    projectPhase: Yup.string().required(),
    riskLevel: Yup.string().required(),
    estimatedResolveDate: Yup.string().required(),
});

export const ProjectFormSchema = Yup.object({
    name: Yup.string().required(),
    abbreviation: Yup.string().required(),
    budget: Yup.string().required(),
    startDate: Yup.string().required(),
    endDate: Yup.string().required(),
    country: Yup.string().required(),
    continent: Yup.string().required(),
});

export const PIUBasicDetailsFormSchema = Yup.object({
    registeredName: Yup.string().required(),
    abbreviation: Yup.string().required(),
    registrationNumber: Yup.string().matches(/^[0-9A-Za-z-]+$/),
    yearFound: Yup.string().matches(/\b(19|20)\d{2}\b/),
    email: Yup.string().email().required(),
    registeredOn: Yup.string().required(),
    logo: Yup.string().required(),
    primaryColor: Yup.string().required(),
    secondaryColor: Yup.string().required(),
    accentColor: Yup.string().required(),
    phoneNumber: Yup.string()
        .required()
        .matches(/^(\+?255|0)[67]\d{8}$/),
    // TODO:: Update regex for matching website link
    websiteLink: Yup.string().matches(
        /\b((?:https?:\/\/|www\.)\S+)\b/g,
        "Enter correct url!"
    ),
    region: Yup.string().required(),
    ward: Yup.string().required(),
    district: Yup.string().required(),
    street: Yup.string().required(),
});
