import Label from "../../../../../../components/form-control/Label";
import PlaceAutoComplete from "../../../../../../components/PlaceAutoComplete";

const ContactPersonAddress = ({
    defaultAddress,
    onSelect: setSelectedAddress,
}) => {
    const handleClick = (address) => {
        const placeName = address.properties?.display_name;
        setSelectedAddress(placeName);
    };
    return (
        <div className="space-y-2">
            <Label text="Address" htmlFor="Address" />
            <PlaceAutoComplete
                defaultValue={defaultAddress}
                containerClassName="bg-white border-[1.4px] focus:bg-green-500 border-light-gray"
                onClick={handleClick}
                placeholder="Eg: Mwenge, Dar es Salaam - Tanzania"
                suggestionClassName="w-full bg-white border-[1.4px] border-light-gray shadow"
                inputClassName="bg-white text-[#AAB6C5]"
            />
        </div>
    );
};
export default ContactPersonAddress;
