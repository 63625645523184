import ToggleButton from "components/toggle-button";

const ToggleView = ({
    onToggle: handleOnToggle,
    showCalendarView = true,
    checked = false,
}) => {
    return (
        <div
            className={"flex items-center text-[15px] space-x-2 text-[#AAB6C5]"}
        >
            <ToggleButton
                currentState={checked}
                onToggle={handleOnToggle}
                label={`Switch to ${
                    showCalendarView ? "list" : "calendar"
                } view`}
            />
        </div>
    );
};
export default ToggleView;
