import { ChevronDownIcon } from "@heroicons/react/solid";
import useGlobalContext from "hooks/useGlobalContext";
import { Can, permissions } from "provider/permission";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import StakeholderSearchBox from "./search-box";
import StakeholderScope from "./stakeholder-scope";
import { isEmptyObject } from "../../../../utils";

const StakeholderList = ({ getCurrentStakeholder, setCurrStakeholder }) => {
    const {
        externalStakeholder = [],
        internalStakeholder = [],
        stakeholder: stakeholders,
        isLoading,
    } = useGlobalContext();

    const [filteredStakeholderList, setFilteredStakeholderList] = useState(
        stakeholders.length ? stakeholders : []
    );

    const updateStakeholderListAndCurrStakeholderWithScope = () => {
        setFilteredStakeholderList(stakeholders);
        const _currStakeholder = getCurrentStakeholder();

        if (!isEmptyObject(_currStakeholder)) {
            if (_currStakeholder.scope === "External") {
                setIsOpenExtStakeholder(true);
                setIsOpenIntStakeholder(false);
            }
            setCurrStakeholder(_currStakeholder);
        } else {
            setCurrStakeholder(stakeholders[0]);
        }
        // Todo::
        //  If there is no internal stakeholder,
        //  then show the first external stakeholder and open its menu by default, and vice versa.
    };

    useEffect(() => {
        updateStakeholderListAndCurrStakeholderWithScope();
    }, [stakeholders]);

    const [isOpenIntStakeholder, setIsOpenIntStakeholder] = useState(true);
    const [isOpenExtStakeholder, setIsOpenExtStakeholder] = useState(false);
    const [isSearching, setIsSearching] = useState(false);

    // filter list based on search-query
    const onChange = (e) => {
        setIsSearching(true);
        const name = e.target.value.toLowerCase();
        if (name) {
            setFilteredStakeholderList(
                filteredStakeholderList.filter((stakeholder) => {
                    return stakeholder.name.toLowerCase().includes(name);
                })
            );
        } else {
            setIsSearching(false);
            setFilteredStakeholderList(stakeholders);
        }
    };

    return (
        <div
            className={
                "w-[240px] bg-[#F8FAFB] flex flex-col justify-between opacity-100 relative"
            }
        >
            <div className={"search-result pb-2  overflow-hidden"}>
                <StakeholderSearchBox onChange={onChange} />
                {isSearching ? (
                    <div className={"px-3 h-full overflow-auto "}>
                        {filteredStakeholderList.length ? (
                            <StakeholderScope
                                isLoading={isLoading}
                                isActive
                                setCurrStakeholder={setCurrStakeholder}
                                showTitle={false}
                                onClick={() => null}
                                stakeholders={filteredStakeholderList}
                            />
                        ) : (
                            <p
                                className={
                                    "text-center py-3 capitalize text-black/50"
                                }
                            >
                                No result found
                            </p>
                        )}
                    </div>
                ) : (
                    <div className={" h-full overflow-y-auto pb-20"}>
                        {/* Internal Stakeholder */}
                        <StakeholderScope
                            title={"Internal Stakeholder "}
                            isActive={isOpenIntStakeholder}
                            setCurrStakeholder={setCurrStakeholder}
                            isLoading={isLoading}
                            stakeholders={internalStakeholder}
                            onClick={() => {
                                setIsOpenIntStakeholder(true);
                                setIsOpenExtStakeholder(false);
                            }}
                        />
                        {/* External Stakeholder */}
                        {isOpenExtStakeholder && (
                            <StakeholderScope
                                title={"External Stakeholder "}
                                isActive={isOpenExtStakeholder}
                                setCurrStakeholder={setCurrStakeholder}
                                isLoading={isLoading}
                                stakeholders={externalStakeholder}
                                onClick={() => {
                                    setIsOpenIntStakeholder(false);
                                    setIsOpenExtStakeholder(true);
                                }}
                            />
                        )}
                    </div>
                )}
            </div>
            <div className={" "}>
                <div
                    className={`
                        ${isOpenIntStakeholder ? " flex" : " hidden "} 
                        justify-center  border-[#E4E6EA] px-4 sticky 
                        items-center border-y-[1px] py-3
                        `}
                >
                    <div
                        className={
                            "w-full flex text-accent  items-center justify-between"
                        }
                    >
                        <p
                            onClick={() => {
                                setIsOpenIntStakeholder(false);
                                setIsOpenExtStakeholder(true);
                            }}
                            className={
                                "text-[13px] cursor-pointer text-[#B3B3B3]"
                            }
                        >
                            EXTERNAL STAKEHOLDERS
                        </p>
                        <ChevronDownIcon
                            className={`h-5 text-[#B3B3B3] transition ease-in-out w-5 ${
                                isOpenExtStakeholder ? "" : "-rotate-180"
                            } `}
                        />
                    </div>
                </div>

                <Can permission={permissions.CREATE_STAKEHOLDERS}>
                    <div className={"flex justify-center px-4 items-center"}>
                        <div
                            className={
                                "w-full flex text-accent  items-center justify-between py-3"
                            }
                        >
                            <Link
                                to={"/register-stakeholder"}
                                className={"text-[13px] "}
                            >
                                REGISTER A STAKEHOLDER
                            </Link>
                            <ChevronDownIcon className={"h-5 w-5"} />
                        </div>
                    </div>
                </Can>
            </div>
        </div>
    );
};
export default StakeholderList;
