import React from "react";

const Loading = () => {
	return (
		<div className={"p-3 space-y-4"}>
			{[...Array(10).keys()].map((num, idx) => (
				<div
					key={idx}
					className={
						"w-full flex space-x-2 animate-pulse items-center"
					}
				>
					<div className={"h-2 w-2 rounded-full bg-primary/5"}></div>

					<div
						className={"w-[150px] h-8 bg-primary/5 rounded-md"}
					></div>
				</div>
			))}
		</div>
	);
};

export default Loading;
